import {
  Box,
  Radio,
  RadioProps,
  Stack,
  Text,
  createStyles,
  rem,
} from '@mantine/core';
import { ReactNode } from 'react';

type SpendPlanRadioCardProps = Pick<RadioProps, 'value' | 'checked'> & {
  label: string;
  description: string;
  icon?: ReactNode;
};

export const SpendPlanRadioCard = (props: SpendPlanRadioCardProps) => {
  const { label, description, value, checked, icon } = props;
  const { classes } = useSpendPlanRadioCardStyles();

  return (
    <label>
      <Stack spacing={12} className={classes.containerStack}>
        {icon ? icon : null}

        <Box className={classes.flexDynamic} ta="center">
          <Text className={classes.label}>{label}</Text>
          <Text className={classes.description}>{description}</Text>
        </Box>

        <Box className={classes.flexStatic}>
          <Radio checked={checked} value={value} />
        </Box>
      </Stack>
    </label>
  );
};

const useSpendPlanRadioCardStyles = createStyles((theme) => ({
  containerStack: {
    alignItems: 'center',
    backgroundColor: '#fff',
    borderColor: 'transparent',
    borderRadius: theme.radius.sm,
    borderStyle: 'solid',
    borderWidth: 1,
    fontSize: rem(14),
    fontWeight: 400,
    height: '100%',
    padding: `${theme.spacing.sm} ${theme.spacing.xs}`,

    '&:has(:checked)': {
      borderColor: theme.fn.primaryColor(),
    },
  },
  flexDynamic: {
    flexGrow: 1,
    flexShrink: 1,
  },
  flexStatic: {
    flexGrow: 0,
    flexShrink: 0,
  },
  label: {
    color: theme.fn.themeColor(theme.primaryColor, 9),
  },
  description: {
    color: theme.fn.themeColor('neutral', 7),
  },
}));
