import { Svg, Path } from '@react-pdf/renderer';

export const SupportSvg = () => (
  <Svg style={{ width: 16, height: 16 }} viewBox="0 0 16 16">
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.68782 8.31493C10.3472 10.9736 10.9505 7.89782 12.6438 9.58988C14.2762 11.2218 15.2144 11.5488 13.1461 13.6165C12.8871 13.8247 11.2411 16.3295 5.45639 10.5464C-0.328996 4.76267 2.17438 3.11497 2.38264 2.85597C4.4559 0.782569 4.77723 1.72626 6.40965 3.35822C8.1029 5.051 5.02843 5.65628 7.68782 8.31493Z"
      stroke="#071C14"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </Svg>
);

export const EmailSvg = () => (
  <Svg style={{ width: 16, height: 16 }} viewBox="0 0 18 16">
    <Path
      d="M14 10.0629C14 11.9064 12.7701 13.4048 10.9455 13.4003H5.05449C3.22984 13.4048 2 11.9064 2 10.0629V5.94211C2 4.10059 3.22984 2.60027 5.05449 2.60027H10.9455C12.7701 2.60027 14 4.10059 14 5.94211V10.0629Z"
      stroke="#071C14"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      d="M13.7513 4.64909L9.415 8.17507C8.5984 8.82387 7.44106 8.82387 6.62444 8.17507L2.2513 4.64909"
      stroke="#071C14"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      d="M5.79022 7.50259L2.24739 11.3503M13.7526 11.3509L10.2488 7.50313"
      stroke="#071C14"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </Svg>
);
