import { useState } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';

import { useStyles } from '../styles';
import { flexbaseBankingClient } from 'services/flexbase-client';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { TransferForm } from './move-funds-treasury';
import { Button, Text } from '@mantine/core';
import AccountSelection from 'areas/payments/components/account-selection';
import { useNavigate } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import { closeAllModals } from '@mantine/modals';
import { useSetRecoilState } from 'recoil';
import { TreasuryManagementState } from '../treasury-management.state';

type Props = {
  transferForm: TransferForm;
  setStep: (x: number) => void;
};

const TransferReview = ({ setStep, transferForm }: Props) => {
  const { classes, cx } = useStyles();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const setTreasuryState = useSetRecoilState(TreasuryManagementState);

  const { amount, activity } = transferForm;

  const transferFund = async (
    transferAmount: number,
    treasuryActivity: 'withdrawal' | 'deposit',
  ) => {
    setLoading(true);
    try {
      const res = await flexbaseBankingClient.postTreasuryActivity(
        treasuryActivity,
        transferAmount,
      );
      if (res.success) {
        await updateActivities();
        closeAllModals();
        navigate('/accounts/activities');
        showNotification({
          color: 'flexbase-teal',
          title: 'Transfer Initiated!',
          message: 'The transfer is now being processed.',
        });
      }
    } catch (error) {
      setErrorMessage('Error initiating transfer');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateActivities = async () => {
    setLoading(true);
    try {
      const res = await flexbaseBankingClient.getTreasuryActivities();
      setTreasuryState((old) => ({
        ...old,
        activities: res,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={cx(classes.card, classes.modalCard)}>
      <Text className={classes.title}>Review transfer</Text>

      <div className={classes.transferAmount}>
        {formatCurrency(transferForm.amount)}
      </div>

      <div className={classes.labelWrapper} style={{ marginTop: '50px' }}>
        <AccountSelection
          label="Transferring from"
          currentAccount={transferForm.sourceAccount!}
        />
      </div>

      <div className={classes.labelWrapper}>
        <AccountSelection
          label="Transferring to"
          currentAccount={transferForm.destinationAccount!}
        />
      </div>

      {errorMessage && errorMessage !== '' && (
        <div className="error">
          <AiFillCloseCircle color="#F87171" size={16} /> {errorMessage}
        </div>
      )}

      <Button
        variant="filled"
        {...{ loading }}
        styles={{
          root: {
            marginTop: '50px',
            marginBottom: '15px',
          },
        }}
        onClick={() => transferFund(parseFloat(amount), activity)}
        disabled={loading}
      >
        Initiate transfer
      </Button>
      <Button variant="outline" onClick={() => setStep(0)}>
        Go back
      </Button>
      <div className={classes.authorizationSection}>
        By clicking Transfer, I authorize Flex to initiate the transaction
        detailed above.
      </div>
    </div>
  );
};

export default TransferReview;
