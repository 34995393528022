import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Group,
  Loader,
  Menu,
  Text,
} from '@mantine/core';
import { useStyles } from './approvals-styles';
import useModal from '@common/modal/modal-hook';
import ApprovalWorkFlowForm from 'areas/billing/pending-approval/approval-workflow-form/approval-workflow-form';
import { useEffect } from 'react';
import { EditIcon, RowAction, TrashIcon } from '@flexbase-eng/web-components';
import { useGetMercoaToken } from '@queries/use-mercoa';
import { useMatchMedia } from '@utilities/url/window-helpers';

type ApprovalsTest = {
  id: number;
  createdAt: string;
  when: JSX.Element;
  then: JSX.Element;
}[];

const Approvals = () => {
  const { classes, theme } = useStyles();
  const { openRightModal, openFullModal, closeAllModals } = useModal();
  const useMobileView = useMatchMedia(`(max-width: ${theme.breakpoints.md})`);

  const { mutate, data, isPending } = useGetMercoaToken();

  const openModal = () => {
    if (data?.token) {
      if (useMobileView) {
        openFullModal(
          <ApprovalWorkFlowForm
            mercoaToken={data.token}
            closeModal={closeAllModals}
          />,
        );
      } else {
        openRightModal(
          <ApprovalWorkFlowForm
            mercoaToken={data.token}
            closeModal={closeAllModals}
          />,
          theme.fn.themeColor('neutral', 2),
        );
      }
    }
  };

  const approvals: ApprovalsTest = [
    {
      id: 1,
      createdAt: 'Created Feb 6, 2024 by me',
      when: (
        <>
          Payment amount is more than or equal to{' '}
          <Text component="span" fw="bold">
            $10,000.00
          </Text>
          . And is scheduled by{' '}
          <Text component="span" fw="bold">
            any employee
          </Text>
        </>
      ),
      then: (
        <>
          Require approval from{' '}
          <Text component="span" fw="bold">
            Emma Juniper
          </Text>
        </>
      ),
    },
    {
      id: 2,
      createdAt: 'Created Mar 1, 2024 by John',
      when: (
        <>
          Payment amount is less than{' '}
          <Text component="span" fw="bold">
            $500.00
          </Text>
          . And is scheduled by a{' '}
          <Text component="span" fw="bold">
            new employee
          </Text>
        </>
      ),
      then: (
        <>
          Require approval from the{' '}
          <Text component="span" fw="bold">
            finance manager
          </Text>
        </>
      ),
    },
    {
      id: 3,
      createdAt: 'Created Apr 15, 2024 by Sarah',
      when: (
        <>
          Payment amount is between{' '}
          <Text component="span" fw="bold">
            $1,000.00
          </Text>{' '}
          and{' '}
          <Text component="span" fw="bold">
            $5,000.00
          </Text>
          . And is scheduled by a{' '}
          <Text component="span" fw="bold">
            senior employee
          </Text>
        </>
      ),
      then: (
        <>
          Require approval from the{' '}
          <Text component="span" fw="bold">
            department head
          </Text>
        </>
      ),
    },
  ];

  useEffect(() => {
    mutate();
  }, []);

  if (isPending) {
    return (
      <Box className={classes.card} mt="lg">
        <Center bg={theme.colors.contentBackground[2]}>
          <Loader />
        </Center>
      </Box>
    );
  }

  return (
    <Box className={classes.card} mt="lg">
      {approvals.length === 0 ? (
        <Box>
          <Text align="center" fw="500" size="md">
            No approval workflows have been created
          </Text>
          <Text align="center">
            Create your first approval workflow to approve or decline payments
          </Text>
          <Center>
            <Button onClick={openModal} variant="filled" mt="1rem">
              Create An Approval Workflow
            </Button>
          </Center>
        </Box>
      ) : (
        <Box w="100%">
          <Group position="right" mb="lg">
            <Button onClick={openModal} variant="filled">
              Create An Approval Workflow
            </Button>
          </Group>
          {approvals.map((item) => (
            <Box key={item.id}>
              <Flex display="flex" align="center" justify="space-between">
                <Box w="20%">
                  <Text fw="500">Bill pay</Text>
                  <Text color={theme.colors.neutral[7]}>{item.createdAt}</Text>
                </Box>
                <Box w="35%">
                  <Text size="sm" fw="500">
                    When
                  </Text>
                  <Text>{item.when}</Text>
                </Box>
                <Box w="30%">
                  <Text size="sm" fw="500">
                    Then
                  </Text>
                  <Text>{item.then}</Text>
                </Box>
                <Box>
                  <Menu position="bottom-end">
                    <Menu.Target>
                      <Box w={'1.8rem'}>
                        <RowAction className={classes.rowActionIcon} />
                      </Box>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        className={classes.editApprovalOption}
                        icon={<EditIcon width={18} />}
                      >
                        Edit approval
                      </Menu.Item>
                      <Menu.Item
                        className={classes.deleteApprovalOption}
                        icon={<TrashIcon />}
                      >
                        Delete approval
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </Box>
              </Flex>
              <Divider mt="lg" mb="sm" />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Approvals;
