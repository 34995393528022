export const validatePersonalTaxId = (value: string): boolean => {
  const ssnRegex =
    /^((?!666|000\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$|XXX-XX-XXXX)/;
  const itinRegex = /^(9\d{2})-(5\d|6[0-5]|7\d|8[0-8]|9[0-2,4-9])-(\d{4})$/;
  return ssnRegex.test(value) || itinRegex.test(value);
};

export const validateEinTaxId = (value: string): boolean =>
  /^(\d{2}-?\d{7}|XX-XXXXXXX)$/.test(value);

type validatorFn = (val: any) => string | null;

// TODO: Replace validators with higher order fns like this
export const isEin = (error?: string): validatorFn => {
  return (val: string) =>
    validateEinTaxId(val) ? null : error || 'EIN is not valid.';
};
