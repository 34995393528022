import {
  SpendPlanAdminView,
  SpendPlanView,
} from '@flexbase-eng/types/dist/accounting';
import { PropsWithChildren, createContext, useContext } from 'react';

type SpendPlanDetailsContextType = {
  isAdmin: boolean;
  isManager: boolean;
  plan: SpendPlanAdminView | SpendPlanView | undefined;
};

const SpendPlanDetailsContext =
  createContext<SpendPlanDetailsContextType | null>(null);

type SpendPlanDetailsProviderProps = PropsWithChildren<{
  value: SpendPlanDetailsContextType;
}>;

export const useSpendPlanDetailsContext = () => {
  const ctx = useContext(SpendPlanDetailsContext);

  if (!ctx) {
    throw new Error(
      'useSpendPlanDetailsContext must be called within a SpendPlanDetailsProvider',
    );
  }

  return ctx;
};

export const SpendPlanDetailsProvider = ({
  children,
  value,
}: SpendPlanDetailsProviderProps) => {
  return (
    <SpendPlanDetailsContext.Provider value={value}>
      {children}
    </SpendPlanDetailsContext.Provider>
  );
};
