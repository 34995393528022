import { createStyles, rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  '.h2': {
    color: '#000',
    fontSize: 28,
    lineHeight: 34,
  },

  modalContent: {
    marginTop: 10,
    marginBottom: 10,
    fontFamily: 'PP Neue Montreal',
  },
  pp: {
    fontFamily: 'PP Neue Montreal',
  },
  switch: {
    marginTop: 20,
    marginBottom: 20,
  },
  '.title': {
    color: '#000',
    fontSize: 28,
    lineHeight: 34,
  },
  mainContainer: {
    minHeight: '100vh',
    background: theme.fn.themeColor('neutral', 2),
    padding: '0px 32px 32px 32px',
    '@media(max-width: 767px)': {
      padding: 0,
    },
    '.tabs': {
      height: '100%',
      fontWeight: 500,
      '[aria-selected="false"]': {
        borderRadius: '100px',
        '&:hover': {
          background: theme.colors['flexbase-rose'],
        },
      },
      '[aria-selected="true"]': {
        borderRadius: '100px',
        backgroundColor: theme.colors['flexbase-rose'],
      },
      '[role=tabpanel]': {
        padding: '10px 0px',
      },
    },
    table: {
      thead: {
        borderTop: '1px solid #E6E7E9',
        'tr > th': {
          fontWeight: 400,
          fontSize: '12px',
        },
      },
      tbody: {
        'tr:nth-of-type(even)': {
          backgroundColor: 'rgba(230, 231, 233, 0.12)',
        },
      },
    },
  },

  authorizationSection: {
    fontSize: '0.75rem',
    color: theme.fn.themeColor('neutral', 7),
  },

  card: {
    padding: '24px',
    borderRadius: theme.defaultRadius,
    position: 'relative',
    backgroundColor: '#fff',
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
  },

  amountInput: {
    fontSize: rem(48),
    border: 'unset',
    marginTop: rem(48),
    borderBottom: `3px solid ${theme.fn.themeColor(
      'primarySecondarySuccess',
      2,
    )}`,
    textAlign: 'center',
    height: rem(52),
  },

  cardOverride: {
    borderRadius: theme.defaultRadius,
    width: '100%',
  },

  emptyState: {
    height: '60vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',

    '.image-container': {
      display: 'flex',
      marginTop: '50px',
      justifyContent: 'center',
    },

    '> div': {
      width: '30%',
    },

    p: {
      margin: 0,
      fontSize: '14px',
      textAlign: 'center',
      color: theme.fn.themeColor('neutral', 6),
    },
  },

  accountContent: {
    marginTop: '15px',
    p: {
      margin: '0px',
    },
    '.balance': {
      p: {
        color: '#000',
        fontSize: '26px',
        margin: '8px 0px',
      },
    },
    '.account_status': {
      span: {
        color: '#000',
        fontWeight: 500,
        fontSize: '26px',
      },
      p: {
        margin: '15px 0px',
      },
      'a, button': {
        padding: '12px',
        borderRadius: theme.defaultRadius,
        height: 'fit-content',
        backgroundColor: 'unset',
        border: `1px solid ${theme.fn.primaryColor}`,
        span: {
          color: theme.fn.primaryColor(),
          fontSize: '14px',
        },
      },
    },
    '.account_number': {
      display: 'flex',
      marginLeft: '-5px',
      alignItems: 'center',
    },
  },

  alert: {
    position: 'absolute',
    bottom: 20,
    right: 20,
  },

  bankAccountTableMenuItem: { display: 'flex', alignItems: 'center' },
  linkIcon: { width: 20, height: 20 },

  closeModal: {
    display: 'grid',
    justifyContent: 'end',
    alignContent: 'baseline',
    p: {
      margin: '0px',
      color: '#979797',
      textAlign: 'center',
    },
  },

  moveMovementsContent: {
    minHeight: '100vh',
    backgroundColor: theme.fn.themeColor('neutral', 2),
    '.content': {
      display: 'flex',
      marginTop: '20px',
      justifyContent: 'center',
      '.move_funds': {
        marginTop: '1.5rem',
        width: '420px',
        '> span': {
          fontSize: '38px',
          lineHeight: '34px',
          color: theme.fn.primaryColor(),
          fontFamily: 'PP Neue Montreal',
        },
        '.label_wrapper': {
          label: {
            fontWeight: 500,
          },
          marginTop: '30px',
        },
        '.banner_info': {
          display: 'flex',
          padding: '12px',
          color: '#11152A',
          margin: '30px 0px',
          borderRadius: theme.defaultRadius,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.fn.themeColor('tertiary', 0),
          svg: {
            marginRight: '12px',
          },
        },
        '.authorization_section': {
          color: '#11152A',
          marginTop: '30px',
        },
        '.transfer_amount': {
          fontSize: '48px',
          color: '#11152A',
          marginTop: '45px',
          textAlign: 'center',
          height: 'fit-content',
          borderBottom: `3px solid ${theme.fn.themeColor(
            'primarySecondarySuccess',
            2,
          )}`,
        },
        '.accounts_select': {
          marginTop: '30px',
          position: 'relative',
        },
        '.warning_alert': {
          display: 'flex',
          fontSize: '12px',
          marginTop: '15px',
          alignItems: 'center',
          color: theme.fn.primaryColor(),
          svg: {
            marginRight: '5px',
            fill: theme.fn.primaryColor(),
          },
        },
      },
      '.error': {
        display: 'flex',
        padding: '16px',
        fontSize: '14px',
        color: '#991B1B',
        marginTop: '30px',
        borderRadius: theme.defaultRadius,
        alignItems: 'center',
        backgroundColor: '#FEF2F2',
        svg: {
          marginRight: '10px',
        },
      },
    },
  },

  styleTest: {
    '.content': {
      display: 'flex',
      justifyContent: 'center',
      '.external_account': {
        width: '420px',
        height: '50%',
        '.title': {
          fontWeight: 400,
          fontFamily: 'PP Neue Montreal',
          fontSize: '16px',
          color: '#000',
        },
        '.subtitle': {
          fontWeight: 500,
          fontSize: '12px',

          fontFamily: 'PP Neue Montreal',
        },
      },
      '.move_funds': {
        marginTop: '1.5rem',
        '> span': {
          color: '#000',
          lineHeight: '34px',
          fontFamily: 'PP Neue Montreal',
          fontSize: '20px',
          textAlign: 'center',
        },
        '.label_wrapper': {
          label: {
            fontWeight: 500,
          },
          marginTop: '30px',
        },
        '.banner_info': {
          display: 'flex',
          padding: '12px',
          color: '#11152A',
          margin: '30px 0px',
          borderRadius: theme.defaultRadius,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#F8FAFC',
          svg: {
            marginRight: '12px',
          },
        },
        '.authorization_section': {
          color: '#11152A',
          marginTop: '30px',
        },
        '.transfer_amount': {
          color: '#11152A',
          textAlign: 'center',
          height: 'fit-content',
          fontSize: '28px',
          marginTop: '18px',
          borderBottom: `3px solid ${theme.fn.themeColor(
            'primarySecondarySuccess',
            2,
          )}`,
        },
        '.accounts_select': {
          marginTop: '50px',
          position: 'relative',
          gap: '20px',
        },
      },
      '.buttons_container': {
        display: 'grid',
        marginTop: '30px',
        button: {
          height: '48px',
        },
        span: {
          fontWeight: 400,
        },
        alignSelf: 'center',
      },
    },
  },

  modalSuccess: {
    height: '70vh',
    display: 'grid',
    marginTop: '30px',
    maxWidth: '500px',
    alignContent: 'center',
    justifyItems: 'center',
    '@media(max-width: 767px)': {
      margin: '20px',
    },
    p: {
      textAlign: 'center',
      marginBottom: '20px',
    },
    '> span': {
      color: '#000',
      fontWeight: 400,
      fontSize: '34px',
      marginTop: '40px',
      lineHeight: '40px',
      textAlign: 'center',
      fontFamily: 'Redaction',
      '@media(max-width: 767px)': {
        fontSize: '24px',
      },
    },
    '.check_icon': {
      width: '72px',
      height: '72px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '50px',
      justifyContent: 'center',
    },
  },
  moveFundsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    '@media(max-width: 767px)': {
      width: '100%',
      height: '100%',
    },
  },
  addNewButton: {
    marginTop: '10px',
    borderRadius: theme.defaultRadius,
    marginBottom: '13px',
    alignSelf: 'flex-end',
    border: `1px solid ${theme.fn.primaryColor()}`,
    span: {
      fontWeight: 500,
      fontSize: '14px',
      color: theme.fn.primaryColor(),
    },
    '&:hover': {
      background: 'rgba(255, 87, 69, 0.1)',
    },
  },
  titleAndSearchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  costumersInitials: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  statusText: {
    padding: '5px 10px',
  },
  inlineCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
    flexShrink: 0,
  },
  menuItem: {
    width: '200px',
  },
  menuEllipsisContainer: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    height: 'fit-content',
  },

  noBankingAccount: {
    h2: {
      fontWeight: 400,
      fontSize: '56px',
      lineHeight: '66px',
      margin: '24px 0px',
      fontFamily: 'redaction',
    },

    '.banner': {
      fontWeight: 500,
      padding: '10px',
      fontSize: '12px',
      lineHeight: '12px',
      width: 'fit-content',
      borderRadius: theme.defaultRadius,
      color: theme.fn.themeColor('tertiary', 3),
      backgroundColor: theme.fn.themeColor('tertiary', 1),
    },

    '.info': {
      fontWeight: 400,
      fontSize: '32px',
      fontFamily: 'PP Neue Montreal',
    },

    '.card': {
      padding: '20px',
      borderRadius: theme.defaultRadius,
      backgroundColor: '#fff',
      '.icon-container': {
        width: '56px',
        height: '56px',
        display: 'flex',
        padding: '10px',
        alignItems: 'center',
        borderRadius: '30px',
        justifyContent: 'center',
        backgroundColor: '#F6F7F9',
      },
      p: {
        margin: 0,
        fontWeight: 500,
        fontsize: '30px',
        lineHeight: '30px',
      },
      '> div': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  },

  reviewButtonsContainer: {
    display: 'grid',
    position: 'absolute',
    paddingRight: '34px',
    paddingLeft: '34px',
    bottom: 20,
    right: 0,
    left: 0,

    button: {
      height: '48px',
    },
    span: {
      fontWeight: 400,
    },
  },

  // DEBIT CARDS
  debitCardsContent: {
    display: 'flex',
    marginTop: '15px',
    flexDirection: 'column',
    '.badge': {
      height: '32px',
      fontWeight: 500,
      fontSize: '14px',
      padding: '4px 15px',
      textTransform: 'capitalize',
      '@media(max-width: 767px)': {
        fontSize: '10px',
        marginLeft: '10px',
        padding: '0px 10px',
        height: 'fit-content',
      },
    },
    '.search-input-icon': {
      padding: '6px',
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
    },
  },

  modal2FAContent: {
    '> div': {
      background: '#fff',
      borderRadius: theme.defaultRadius,
      '@media(max-width: 767px)': {
        padding: '10px',
      },

      span: {
        fontSize: '20px',
        fontWeight: 500,
        color: theme.fn.primaryColor(),
      },

      '.text': {
        fontWeight: 400,
        fontSize: '14px',
        margin: '0px',
        color: theme.colors.neutral[7],
      },

      '.resend-button': {
        fontSize: '14px',
        color: theme.colors.neutral[7],
        margin: '15px 0px 0px',
        textDecoration: 'underline',
      },

      '.continue-button': {
        marginTop: 20,
        span: {
          color: '#fff',
          fontWeight: 500,
          fontSize: '14px',
        },
      },

      '.close-button': {
        display: 'flex',
        justifyContent: 'flex-end',
      },

      '.label': {
        color: theme.fn.primaryColor(),
        margin: '0px',
        fontWeight: 500,
        fontSize: '14px',
        marginTop: '20px',
      },
    },
  },

  modalFormContent: {
    margin: '0px',
    padding: '40px',
    minWidth: '100%',
    height: '100vh',
    borderRadius: theme.defaultRadius,
    position: 'fixed',
    overflowX: 'auto',
    backgroundColor: theme.colors.neutral[2],
    '.content': {
      display: 'flex',
      marginTop: '20px',
      width: '420px',
      height: 'fit-content',
      justifyContent: 'center',
      '.external_account': {
        width: '420px',
        '@media(max-width: 767px)': {
          width: '100%',
          height: '50%',
        },
        '.title': {
          fontWeight: 400,
          fontSize: '28px',
          fontFamily: 'PP Neue Montreal',
          color: '#000',
          '@media(max-width: 767px)': {
            fontSize: '16px',
          },
        },
        '.subtitle': {
          fontWeight: 500,
          fontSize: '16px',

          fontFamily: 'PP Neue Montreal',
          '@media(max-width: 767px)': {
            fontSize: '12px',
          },
        },
      },
      '.move_funds': {
        marginTop: '1.5rem',
        width: '420px',
        '> span': {
          color: '#000',
          fontSize: '28px',
          lineHeight: '34px',
        },
        '.label_wrapper': {
          label: {
            fontWeight: 500,
          },
          marginTop: '30px',
        },
        '.banner_info': {
          display: 'flex',
          padding: '12px',
          color: '#11152A',
          margin: '30px 0px',
          borderRadius: theme.defaultRadius,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#F8FAFC',
          svg: {
            marginRight: '12px',
          },
        },
        '.authorization_section': {
          color: '#11152A',
          marginTop: '30px',
        },
        '.transfer_amount': {
          fontSize: '48px',
          color: '#11152A',
          marginTop: '45px',
          textAlign: 'center',
          height: 'fit-content',
          borderBottom: `3px solid ${theme.fn.themeColor(
            'primarySecondarySuccess',
            2,
          )}`,
        },
        '@media(max-width: 767px)': {
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          '> span': {
            fontSize: '24px',
          },
        },
      },
      '.error': {
        display: 'flex',
        padding: '16px',
        fontSize: '14px',
        color: '#991B1B',
        marginTop: '30px',
        borderRadius: theme.defaultRadius,
        alignItems: 'center',
        backgroundColor: '#FEF2F2',
        svg: {
          marginRight: '10px',
        },
      },
      '.buttons_container': {
        display: 'grid',
        marginTop: '30px',
        button: {
          height: '48px',
        },
        span: {
          fontWeight: 400,
        },
      },
      '.row-content': {
        display: 'flex',
        marginTop: '30px',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: {
          margin: '0px',
        },
      },
    },
    '.close': {
      display: 'grid',
      justifyContent: 'end',
      alignContent: 'baseline',
      p: {
        margin: '0px',
        color: '#979797',
        textAlign: 'center',
      },
    },
    '@media(max-width: 767px)': {
      padding: '20px',
      '.close-button': {
        width: '100%',
        display: 'flex',
        marginBottom: '15px',
        justifyContent: 'flex-end',
      },
      '.content': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      },
    },
  },

  cardDetails: {
    padding: '60px 0px',
    '@media(max-width: 767px)': {
      padding: '30px 0px',
    },

    p: {
      margin: '0px',
      fontSize: '14px',
    },
    '> div': {
      padding: '0px 60px',
      '@media(max-width: 767px)': {
        padding: '0px 30px',
      },
    },
    '.badge': {
      color: '#fff',
      padding: '12px',
      fontWeight: 500,
      textTransform: 'capitalize',
      span: {
        fontSize: '10px !important',
      },
    },
    '.card-limit': {
      display: 'flex',
      flexDirection: 'column',
      p: {
        fontSize: '12px',
        alignSelf: 'flex-end',
      },
    },
    '.actions': {
      height: '30px',
      display: 'flex',
      minWidth: '100%',
      padding: '0px 10px',
      alignItems: 'center',
      borderRadius: '100px',
      justifyContent: 'center',
      backgroundColor: theme.colors['flexbase-cosmic'],
      p: {
        color: '#fff',
      },
      '@media(max-width: 767px)': {
        gap: '10px',
      },
    },
    '.row-content': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      button: {
        display: 'flex',
        fontSize: '14px',

        alignItems: 'center',
        svg: {
          marginRight: '5px',
        },
      },
      span: {
        fontSize: '14px',
        fontWeight: 600,
      },
      '&:not(:first-of-type)': {
        span: {
          margin: '8px 0px',
        },
      },
      '.edit-button': {
        fontWeight: 500,
        fontSize: '14px',
      },
      '@media(max-width: 767px)': {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        '.edit-button': {
          justifySelf: 'end',
        },
      },
    },
    '.end-content': {
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    '.buttons-container': {
      display: 'flex',
      marginTop: '15px',
      justifyContent: 'space-between',
    },
    '.menu': {
      cursor: 'pointer',
      'div[aria-expanded=true]': {
        color: `${theme.fn.primaryColor()}`,
        svg: {
          fill: `${theme.fn.primaryColor()}`,
        },
      },
      '&:hover': {
        color: `${theme.fn.primaryColor()}`,
        svg: {
          fill: `${theme.fn.primaryColor()}`,
        },
      },
    },
    '.item': {
      fontSize: '14px',

      '&:hover': {
        backgroundColor: 'rgba(255, 87, 69, 0.08)',
      },
    },
    '.transactions-action': {
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        color: `${theme.fn.primaryColor()}`,
        svg: {
          fill: `${theme.fn.primaryColor()}`,
        },
      },
      '@media(max-width: 767px)': {
        justifyContent: 'flex-end',
      },
    },
    '.freeze-action': {
      height: '30px',
      display: 'flex',

      alignItems: 'center',
      '&:hover': {
        color: theme.colors['flexbase-cosmic'],
        svg: {
          fill: theme.colors['flexbase-cosmic'],
        },
      },
    },
  },

  debitCard: {
    padding: '20px',
    margin: '20px 0px',
    borderRadius: theme.defaultRadius,
    border: '1px solid #DFDFDF',
    '.visa-logo': {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'end',
      paddingBottom: '5px',
    },
    '.row-content': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      p: {
        margin: '0px',
      },
    },
    '.badge': {
      padding: '12px',
      textTransform: 'capitalize',
      span: {
        color: '#fff',
        fontWeight: 500,
        fontSize: '10px !important',
      },
    },
    iframe: {
      width: '100%',
      height: '16px',
    },
  },
  buttonRot: {
    borderRadius: theme.defaultRadius,
    borderColor: `${theme.fn.primaryColor()}`,
    height: 40,
    marginTop: 16,
    width: rem(300),
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(255, 87, 69, 0.1)',
    },
  },
  buttonInner: { display: 'flex', alignItems: 'center' },
  buttonLabel: {
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    color: `${theme.fn.primaryColor()}`,
  },

  accountSelectTarget: {
    width: '400px',
    marginBottom: '20px',
  },
  accountSelectList: {
    width: '400px',
    position: 'absolute',
  },
  accountSelectContainer: {
    margin: '0 16px',
  },
  loadingBox: {
    width: '400px',
    height: '82px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    border: `1px solid ${theme.colors.flexbaseGrey[0]}`,
    borderRadius: theme.defaultRadius,
  },
  containerWidgets: {
    '@media(max-width: 767px)': {
      width: '100%',
      display: 'flex',
    },
  },
}));

export const CustomMantineStyles = (primaryColor?: string) => {
  return {
    searchPayments: {
      input: {
        width: 368,
        height: 40,
        opacity: 0.8,
        border: '1px solid #E6E7E9',
        borderRadius: '2px',
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: 500,
        background: 'white',
        fontStyle: 'normal',
        '&:focus': { borderColor: primaryColor },
      },
    },

    debitCard: {
      radioLabel: {
        fontWeight: 500,
        fontSize: '14px',
      },
      radio: {
        border: 'solid 6px #CFD0D2',
        ':checked': { borderColor: primaryColor },
      },
    },
  };
};
