import { Text, View } from '@react-pdf/renderer';
import { stylesStatementPDF } from './styles';
import { DateTime } from 'luxon';

const RiskPDFFooter = () => {
  return (
    <View style={stylesStatementPDF.footerContainer} fixed>
      <View>
        <Text>Flexbase Technologies Inc</Text>
        <Text> 88 SW 7th St Miami, FL, 33130-3691 US</Text>
      </View>
      <View>
        <Text>Generated on {DateTime.now().toFormat('LLL dd, yyyy')}</Text>
        <Text
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} of ${totalPages}`
          }
        />
      </View>
    </View>
  );
};

export default RiskPDFFooter;
