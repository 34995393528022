import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useMediaQuery } from '@mantine/hooks';
import { Button, ButtonProps, Menu } from '@mantine/core'; //useMantineTheme
import { ArrowIcon } from 'assets/svg';
import useModal from '@common/modal/modal-hook';
import AddBill from '../components/add-bill/add-bill';
// import ApprovalWorkFlowForm from '../pending-approval/approval-workflow-form/approval-workflow-form';

type ActionsProps = {
  mercoaToken: string;
} & ButtonProps;

const BillPayActionButton = ({ mercoaToken, ...props }: ActionsProps) => {
  // const theme = useMantineTheme();
  // const navigate = useNavigate();
  const [showActions, setShowActions] = useState(false);
  //const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const {
    //    openFullModal,
    //    openRightModal,
    closeAllModals,
    openTransparentModal,
  } = useModal();

  const handleCreateBill = () => {
    openTransparentModal(
      <AddBill closeModal={closeAllModals} mercoaToken={mercoaToken} />,
    );
  };

  /*
  const handleCreateApproval = () => {
    if (useMobileView) {
      openFullModal(
        <ApprovalWorkFlowForm
          mercoaToken={mercoaToken}
          closeModal={closeAllModals}
        />,
      );
    } else {
      openRightModal(
        <ApprovalWorkFlowForm
          mercoaToken={mercoaToken}
          closeModal={closeAllModals}
        />,
        theme.fn.themeColor('neutral', 2),
      );
    }
  };
  */

  return (
    <Menu
      position="bottom-end"
      onOpen={() => setShowActions(true)}
      onClose={() => setShowActions(false)}
    >
      <Menu.Target>
        <Button
          {...props}
          rightIcon={<ArrowIcon width="16px" />}
          styles={{ rightIcon: { rotate: !showActions ? '180deg' : '' } }}
        >
          Actions
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item onClick={handleCreateBill}>Create new bill</Menu.Item>
        {/*<Menu.Item onClick={handleCreateApproval}>Create approval</Menu.Item>*/}
        {/*<Menu.Item onClick={() => navigate('/settings/approvals')}>
          Manage approvals
        </Menu.Item></Menu>*/}
      </Menu.Dropdown>
    </Menu>
  );
};

export default BillPayActionButton;
