import { createStyles, rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  card: {
    height: rem(200),
    color: theme.fn.primaryColor(),
    backgroundColor: theme.fn.themeColor('neutral', 0),
  },
  alingCenter: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  rowContent: {
    display: 'flex',
    padding: rem(10),
    justifyContent: 'space-between',
  },
  witheCard: {
    gap: 12,
    display: 'flex',
    height: rem(65),
    borderRadius: 8,
    padding: rem(10),
    maxWidth: rem(270),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.fn.themeColor('contentBackground', 2),
  },
  iconContainer: {
    width: rem(32),
    height: rem(32),
    display: 'flex',
    alignItems: 'center',
    borderRadius: '100%',
    justifyContent: 'center',
    backgroundColor: theme.fn.primaryColor(),
  },
  backCard: {
    height: rem(12),
    width: rem(200),
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    backgroundColor: '#798580', // not in theme
  },
  backSecondCard: {
    height: rem(12),
    width: rem(180),
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: '#44544E', // not in theme
  },
}));
