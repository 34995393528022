import { GraceDayEarlyPayConfig } from '../../../../states/onboarding/onboarding-info';
import {
  Anchor,
  Box,
  Flex,
  Group,
  Popover,
  Skeleton,
  Stack,
  Text,
} from '@mantine/core';
import { FiCalendar } from 'react-icons/fi';
import { useGetCompanyEarlyPayProjectedDueAmounts } from '@queries/use-get-company-early-pay-projected-due';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from '../../../../states/application/product-onboarding';
import { DateTime } from 'luxon';
import { formatCurrency } from '@utilities/formatters/format-currency';
import useModal from '@common/modal/modal-hook';
import { EarlyPayScheduleDrawer } from './early-pay-schedule-drawer';
import { useMediaQuery } from '@mantine/hooks';

type Props = { earlyPayConfig: GraceDayEarlyPayConfig };

export const EarlyPayProjectedDueCard = ({ earlyPayConfig }: Props) => {
  const isMobileView = useMediaQuery(`(max-width: 767px`);
  const { company } = useRecoilValue(ApplicationState);
  const { openRightModalNoOpacity, openFullModal } = useModal();
  const { data: projectedDueAmounts, isLoading } =
    useGetCompanyEarlyPayProjectedDueAmounts(company.id);

  if (!projectedDueAmounts || isLoading) {
    return <Skeleton w="100%" h={200} />;
  }

  const firstProjectedDue = projectedDueAmounts.earlyPayProjectedDueAmounts[0];
  const earlyPayDate = DateTime.fromISO(firstProjectedDue.earlyPayDate);
  const dateString = earlyPayDate.toLocaleString({
    month: 'short',
    day: 'numeric',
  });
  const diffDays = Math.floor(earlyPayDate.diffNow('days').days);
  const inDaysString =
    diffDays === 0
      ? 'Today'
      : `In ${diffDays} ${diffDays === 1 ? 'day' : 'days'}`;
  const amount = formatCurrency(firstProjectedDue.projectedDue);

  const getNextRebateDate = (fromDate: DateTime, rebateDate: number) => {
    const baseDate =
      rebateDate <= fromDate.day ? fromDate.plus({ months: 1 }) : fromDate;

    if (rebateDate > (baseDate.daysInMonth ?? 0)) {
      return baseDate.endOf('month');
    } else {
      return baseDate.set({ day: rebateDate });
    }
  };

  const handleViewScheduleClick = () => {
    if (isMobileView) {
      openFullModal(
        <EarlyPayScheduleDrawer
          projectedDueAmounts={projectedDueAmounts.earlyPayProjectedDueAmounts}
        />,
      );
    } else {
      openRightModalNoOpacity(
        <EarlyPayScheduleDrawer
          projectedDueAmounts={projectedDueAmounts.earlyPayProjectedDueAmounts}
        />,
      );
    }
  };

  return (
    <Stack p="1.5rem" w="100%" h={200} bg="neutral.0" spacing="0.5rem">
      <Text fw={500} c="#5F5F5F">
        Next automatic early pay*
      </Text>
      <Group>
        <Flex
          c="promote.5"
          bg="promote.0"
          h="2rem"
          w="2rem"
          align="center"
          justify="center"
          sx={{ borderRadius: '4px' }}
        >
          <FiCalendar />
        </Flex>
        <Box>
          <Text fz={14}>{dateString}</Text>
          <Text fz={12} lh="1">
            {inDaysString}
          </Text>
        </Box>
        <Text ml="auto" fz={26} fw={500}>
          {amount}
        </Text>
      </Group>
      <Text fz={12} c="neutral.7">
        Early pay cash back bonus of {earlyPayConfig.rebatePct}% deposited on{' '}
        {getNextRebateDate(
          earlyPayDate,
          earlyPayConfig.rebateDate,
        ).toLocaleString(DateTime.DATE_SHORT)}
      </Text>
      <Flex mt="auto" justify="space-between" align="center">
        <Anchor onClick={handleViewScheduleClick}>
          <Flex align="center" gap="0.5rem" fz={14}>
            <FiCalendar /> View Schedule
          </Flex>
        </Anchor>
        <Popover withinPortal width={300} withArrow>
          <Popover.Target>
            <Anchor fz={12}>*What&apos;s this?</Anchor>
          </Popover.Target>
          <Popover.Dropdown>
            This represents the &quot;early pay&quot;amount that will be paid
            automatically on {earlyPayDate.toLocaleString(DateTime.DATE_SHORT)}.
            The balance shown is a snapshot of what is currently due on this
            date, and is subject to change if payments are made before the
            scheduled date or if additional credit transactions are made before{' '}
            {earlyPayDate
              .minus({ days: earlyPayConfig.graceDays })
              .toLocaleString(DateTime.DATE_SHORT)}
            .
          </Popover.Dropdown>
        </Popover>
      </Flex>
    </Stack>
  );
};
