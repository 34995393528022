import { SimpleMultiselectFilter } from '@common/filter/simple-multiselect.filter';
import { usePaymentFilters } from '../use-payment-filters';

const FILTER_MULTISELECT_OPTIONS = [
  {
    label: 'Domestic ACH payment',
    filterCriteria: 'Domestic ACH payment',
    key: 'ach',
  },
  {
    label: 'Domestic wire',
    filterCriteria: 'Domestic wire',
    key: 'wire',
  },
  {
    label: 'International wire',
    filterCriteria: 'Outbound ACH for intl wire',
    key: 'outgoingACHinternationalWire',
  },
  {
    label: 'Book',
    filterCriteria: 'Internal transfer',
    key: 'book',
  },
];

export const PaymentTypeFilter = () => {
  const paymentFilters = usePaymentFilters();

  return (
    <SimpleMultiselectFilter
      filterHook={paymentFilters}
      filterKey="type"
      options={FILTER_MULTISELECT_OPTIONS}
      label="Type"
      includesValue={(row) => row.type}
    />
  );
};
