import FlexbaseDefaultHeader from 'components/header/current/flexbase-header';
import FlexbaseMainLayout from 'components/layouts/main';
import { MercoaSession } from '@mercoa/react';
import { useGetMercoaToken } from '@queries/use-mercoa';
import { useEffect } from 'react';
import { Loader, Text, Button, Center } from '@mantine/core';
import GenericError from 'areas/banking/utils/generic-error';
import BillingPage from './billing.page';

const Billing = () => {
  const { mutate, data, isPending, isError, reset } = useGetMercoaToken();

  useEffect(() => {
    mutate();
  }, []);

  if (isPending) {
    return (
      <FlexbaseMainLayout>
        <FlexbaseDefaultHeader title="Bill Pay" />
        <Center h="50vh">
          <Loader />
        </Center>
      </FlexbaseMainLayout>
    );
  }

  if (isError) {
    return (
      <FlexbaseMainLayout>
        <FlexbaseDefaultHeader title="Bill Pay" />
        <GenericError>
          <Text ta="center">
            There was an error getting your bills information
          </Text>
          <Button mt="lg" onClick={reset}>
            Try again
          </Button>
        </GenericError>
      </FlexbaseMainLayout>
    );
  }

  const mercoaToken = data?.token ?? '';

  return (
    <MercoaSession
      token={mercoaToken}
      googleMapsApiKey={import.meta.env.VITE_APP_GOOGLE_API_KEY}
    >
      <FlexbaseMainLayout>
        <FlexbaseDefaultHeader title="Bill Pay" />
        <BillingPage />
      </FlexbaseMainLayout>
    </MercoaSession>
  );
};

export default Billing;
