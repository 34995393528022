import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Menu, createStyles } from '@mantine/core';

import {
  AddFundsIcon,
  PaySomeoneIcon,
  ChevronDownIcon,
  ArrowsHorizontalIcon,
} from 'assets/svg';

type MenuItemWithIconProps = {
  id: string;
  label: string;
  onClick: () => void;
  icon: React.ReactNode;
};

const MenuItemWithIcon = ({
  onClick,
  label,
  icon,
  id,
}: MenuItemWithIconProps) => (
  <Menu.Item icon={icon} onClick={onClick} id={id} data-testid={id}>
    {label}
  </Menu.Item>
);

const BankingActionButton = () => {
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);
  const { classes } = useStyles(opened);

  const menuOptions = [
    {
      path: '/payments/outgoing/recipient',
      icon: <PaySomeoneIcon width={20} />,
      label: 'Send payment',
      id: 'send-payment',
    },
    {
      path: '/banking/add-funds',
      icon: <AddFundsIcon width={20} />,
      label: 'Add or receive funds',
      id: 'add-funds',
    },
    {
      path: '/banking/move-funds',
      icon: <ArrowsHorizontalIcon width={18} />,
      label: 'Transfer between your accounts',
      id: 'transfer-between-accounts',
    },
  ];

  return (
    <Menu
      opened={opened}
      onChange={setOpened}
      position="bottom-start"
      id="banking-button-header"
      data-testid="banking-button-header"
      classNames={{ item: classes.item }}
    >
      <Menu.Target>
        <Button
          size="sm"
          rightIcon={
            <ChevronDownIcon fill="#fff" width={18} className={classes.icon} />
          }
        >
          Move Money
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        {menuOptions.map((option) => (
          <MenuItemWithIcon
            id={option.id}
            key={option.path}
            icon={option.icon}
            label={option.label}
            onClick={() => navigate(option.path)}
          />
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export default BankingActionButton;

const useStyles = createStyles((theme, opened: boolean) => ({
  item: {
    fill: theme.fn.primaryColor(),
    color: theme.fn.primaryColor(),
    '&:hover': {
      fill: theme.fn.themeColor('primarySecondarySuccess', 2),
      color: theme.fn.themeColor('primarySecondarySuccess', 2),
    },
  },

  icon: {
    transition: '0.2s ease-out',
    transform: opened ? 'rotate(-180deg)' : 'rotate(0deg)',
  },
}));
