import { useQuery } from '@tanstack/react-query';
import { flexbaseOnboardingClient } from 'services/flexbase-client';

const QUERY_KEY = 'rebateHistory';

export const useGetRebateHistory = (companyId: string) => {
  return useQuery({
    queryKey: [QUERY_KEY, companyId],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getRebateHistory(companyId);
    },
    meta: {
      errorMessage:
        'We are unable to retrieve the company payments at the moment.',
    },
  });
};
