import { Alert, Box, Stack, Text, createStyles } from '@mantine/core';
import {
  CreateSpendPlanWizard,
  useCreateSpendPlanWizard,
} from '../../create-spend-plan.wizard';
import { useEffect, useState } from 'react';
import { useSpendPlanMobile } from 'areas/spend-plans/utils/use-spend-plan-mobile';
import {
  useCreateSpendPlan,
  useUpdateSpendPlan,
} from '@queries/use-spend-plans';
import { useNavigate } from 'react-router-dom';
import { useGetMe } from '@queries/use-get-me';
import { showNotification } from '@mantine/notifications';
import {
  toCreateSpendPlanPayload,
  toUpdateSpendPlanPayload,
} from '../../utils/map-form-to-payload';
import { SpendPlanAdminView } from '@flexbase-eng/types/dist/accounting';

const STEP_ID = 'review';

const LABELS: Record<
  'create' | 'update',
  {
    errorPrefix: string;
    description: string;
    submitButton: string;
  }
> = {
  create: {
    errorPrefix: `An error occurred while creating spend plan`,
    description: `Once you hit Create Spend Plan, added members will immediately have access to allocate spend to this plan from their physical and/or virtual cards.`,
    submitButton: `Create spend plan`,
  },
  update: {
    errorPrefix: `An error occurred while saving changes`,
    description: `Once you hit Save Changes, added members will immediately have access to allocate spend to this plan from their physical and/or virtual cards.`,
    submitButton: `Save changes`,
  },
};

type SpendPlanReviewStepProps = {
  spendPlan?: SpendPlanAdminView;
};

export const SpendPlanReviewStep = ({
  spendPlan,
}: SpendPlanReviewStepProps) => {
  const navigate = useNavigate();
  const isMobile = useSpendPlanMobile();
  const { data: me } = useGetMe();
  const { classes } = useReviewStepStyles();
  const { scrollToPreviewTop, state } = useCreateSpendPlanWizard();
  const { mutate: createSpendPlan, isPending: isCreating } =
    useCreateSpendPlan();
  const { mutate: updateSpendPlan, isPending: isUpdating } =
    useUpdateSpendPlan();
  const [saveError, setSaveError] = useState<string | null>(null);
  const [disableBtn, setDisableBtn] = useState(false);

  const mode = spendPlan ? 'update' : 'create';
  const labels = LABELS[mode];

  useEffect(() => {
    scrollToPreviewTop();
  }, []);

  const handleCreate = (accountId: string) => {
    const payload = toCreateSpendPlanPayload(state);

    createSpendPlan(
      {
        accountId,
        payload,
      },
      {
        onSuccess: (response) => {
          setDisableBtn(true);
          const spendPlanId = response.createdId;
          setTimeout(() => {
            navigate(`/spend-plans/${spendPlanId}/details`);
            setDisableBtn(false);
            showNotification({
              message: `Spend plan created`,
              color: 'flexbase-teal',
            });
          }, 5000);
        },
        onError: (e) => {
          setSaveError(e.message);
        },
      },
    );
  };

  const handleUpdate = (accountId: string, spendPlanId: string) => {
    const payload = toUpdateSpendPlanPayload(state);

    updateSpendPlan(
      {
        accountId,
        spendPlanId,
        payload,
      },
      {
        onSuccess: () => {
          navigate(`/spend-plans/${spendPlanId}/details`);
          showNotification({
            message: `Spend plan updated`,
            color: 'flexbase-teal',
          });
        },
        onError: (e) => {
          setSaveError(e.message);
        },
      },
    );
  };

  const handleNext = async () => {
    if (!me?.accountId) {
      setSaveError('Missing or invalid account id');
      return;
    }

    setSaveError(null);

    if (mode === 'create') {
      handleCreate(me.accountId);
    } else {
      if (!spendPlan) {
        setSaveError('Missing or invalid spend plan ID');
        return;
      }

      handleUpdate(me.accountId, spendPlan.id);
    }
  };

  return (
    <CreateSpendPlanWizard.Step
      labels={{ next: labels.submitButton }}
      onNext={handleNext}
      nextLoading={isCreating || isUpdating || disableBtn}
    >
      <Stack spacing={'1.5rem'}>
        {saveError ? (
          <Alert color="failure" withCloseButton={false}>
            {labels.errorPrefix}: {saveError}
          </Alert>
        ) : null}

        <Text>{labels.description}</Text>

        {isMobile ? (
          <Box className={classes.mobileContainer}>
            <CreateSpendPlanWizard.Preview />
          </Box>
        ) : null}
      </Stack>
    </CreateSpendPlanWizard.Step>
  );
};

SpendPlanReviewStep.stepId = STEP_ID;

const useReviewStepStyles = createStyles((theme) => ({
  mobileContainer: {
    backgroundColor: '#fff',
    borderColor: theme.colors.neutral[3],
    borderRadius: theme.defaultRadius,
    borderStyle: 'solid',
    borderWidth: 1,
  },
}));
