import { ApplicationConfigBuilder } from './product-onboarding.models';

const configBuilder = new ApplicationConfigBuilder([
  'BANKING',
]).forRequirementArray('requiredBanking');
configBuilder
  .addRoute('change-password', 1, true)
  .withRequiredField('user.changePassword');
configBuilder
  .addRoute('verify-phone', 2, false)
  .withRequiredField('user.phone')
  .withRequiredField('user.cellPhone');
configBuilder
  .addRoute('business-type', 3)
  .withRequiredField('company.legalStructure');
configBuilder
  .addRoute('user-address', 4, false)
  .withRequiredField('user.address');
configBuilder
  .addRoute('verify-identity', 5, false)
  .withRequiredField('user.taxId')
  .withRequiredField('user.birthDate')
  .withRequiredField('user.ficoPullSigned')
  .withRequiredField('user.patriotActSigned');
configBuilder
  .addRoute('job-title', 6, false)
  .withRequiredField('user.jobTitle');
configBuilder
  .addRoute('business-activity', 7)
  .withRequiredField('company.companyName')
  .withRequiredField('company.website')
  .withRequiredField('company.annualRevenue')
  .withRequiredField('company.businessPurpose')
  .withRequiredField('company.category')
  .withRequiredField('company.monthlyExpenditure')
  .withRequiredField('company.businessVertical');
configBuilder
  .addRoute('verify-business', 8)
  .withRequiredField('company.taxId')
  .withRequiredField('company.formationDate')
  .withRequiredField('company.phone')
  .withRequiredField('company.address');
configBuilder
  .addRoute('business-owners', 9)
  .withRequiredField('company.owners');
configBuilder
  .addRoute('control-person', 10)
  .withRequiredField('company.controlPerson');
configBuilder
  .addRoute('summary', 11, false)
  .withRequiredField('user.termsOfServiceSigned')
  .withRequiredField('user.bankingTermsOfServiceSigned');

export const BankingApplicationConfig = configBuilder.build();
