import { atom } from 'recoil';
import {
  ActiveFiltersReturnType,
  createUseFiltersHook,
  FilterFnMap,
} from '@common/filter/filters';
import { VendorTableData } from './vendors';

const BillingVendorsFilterState = atom<FilterFnMap<VendorTableData>>({
  key: 'billing_vendors_filter_state',
  default: {},
});

export function useBillingVendorsFilters(): ActiveFiltersReturnType<VendorTableData> {
  return createUseFiltersHook<VendorTableData>(BillingVendorsFilterState);
}
