import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  badge: {
    padding: theme.spacing.xs,
    textAlign: 'center',
    borderRadius: theme.radius.sm,
    '@media(max-width: 767px)': {
      width: '100%',
    },
    minWidth: '100px',
  },
  card: {
    padding: theme.spacing.xl,
    color: theme.colors.neutral[1],
    borderRadius: theme.defaultRadius,
    position: 'relative',
    backgroundColor: '#fff',
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
    marginTop: theme.spacing.lg,
  },
  containerVendor: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
  },
  rowActionIcon: {
    fill: '#979797',
    cursor: 'pointer',
    rotate: '90deg',
  },
  deleteVendorOption: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.critical[6],
  },
  viewVendorOption: {
    display: 'flex',
    alignItems: 'center',
  },
  containerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));
