import { atom } from 'recoil';
import {
  ActiveFiltersReturnType,
  createUseFiltersHook,
  FilterFnMap,
} from '@common/filter/filters';
import { TablePayment } from '../common/payments-rows';

const PaymentFilterState = atom<FilterFnMap<TablePayment>>({
  key: 'payment_filter_state',
  default: {},
});

export function usePaymentFilters(): ActiveFiltersReturnType<TablePayment> {
  return createUseFiltersHook<TablePayment>(PaymentFilterState);
}
