import {
  SplitClient,
  SplitFactoryProvider,
  useSplitTreatments,
} from '@splitsoftware/splitio-react';
import { ISplitFactoryProps } from '@splitsoftware/splitio-react/types/types';
import {
  CompanyIdState,
  UserIdState,
} from 'areas/onboarding/onboarding-form.state';
import { useRecoilValue } from 'recoil';
import { getEnvironment } from 'utilities/url/window-helpers';

const DEFAULT_SPLIT_KEY = 'anon';

const splitConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: import.meta.env.VITE_APP_SPLIT_FEATURE_FLAG || '',
    key: DEFAULT_SPLIT_KEY,
  },
  urls: {
    sdk: `${import.meta.env.VITE_APP_SPLIT_PROXY_BASE_URL}/api`,
    events: `${import.meta.env.VITE_APP_SPLIT_PROXY_BASE_URL}/api`,
    auth: `${import.meta.env.VITE_APP_SPLIT_PROXY_BASE_URL}/api`,
  },
};

export const SplitProvider = ({ children }: ISplitFactoryProps) => {
  const userId = useRecoilValue(UserIdState);
  const companyId = useRecoilValue(CompanyIdState);
  const splitKey = userId || DEFAULT_SPLIT_KEY;

  const splitAttributes: Record<string, string> = {
    hostEnv: getEnvironment(),
  };

  if (companyId) {
    splitAttributes.companyId = companyId;
  }

  return (
    <SplitFactoryProvider config={splitConfig} attributes={splitAttributes}>
      <SplitClient splitKey={splitKey} attributes={splitAttributes}>
        {children}
      </SplitClient>
    </SplitFactoryProvider>
  );
};

/**
 * Convenience hook to get the treatment value for a single flag.
 *
 * @returns the computed treatment value, or `control` if Split SDK is not ready or flag does not exist.
 */
export const useTreatment = (
  flag: string,
  attributes?: SplitIO.Attributes,
  key?: SplitIO.SplitKey,
  trafficType?: string,
) => {
  const { treatments } = useSplitTreatments({
    names: [flag],
    splitKey: key,
    attributes,
    trafficType,
  });

  return treatments[flag];
};
