import { keyframes } from '@mantine/core';

export const spinCounterClockwise = keyframes({
  from: {
    transform: 'rotate(0deg)',
  },
  to: {
    transform: 'rotate(-179deg)',
  },
});
