import { Group, Image, Text } from '@mantine/core';

import { useStyles } from './styles';
import SketchImage from 'assets/images/transferred-banking.png';

const MoneyTransfers = () => {
  const { classes } = useStyles();

  return (
    <Group p={10} className={classes.card}>
      <div className={classes.alingCenter}>
        <Image src={SketchImage} width={120} />
        <Text ff="redaction" size={20} lh={1} mt={20} ta="center">
          You&apos;ve sent $8,502.60 to Gina Fowles.
        </Text>
        <Text color="neutral.5" size={10} lh={1.5} mt={10} w="90%" ta="center">
          The funds should arrive within 1-2 business day for domestic wires
        </Text>
      </div>
    </Group>
  );
};

export default MoneyTransfers;
