import FlexbaseDefaultHeader from '@common/header/current/flexbase-header';
import FlexbaseMainLayout from '@common/layouts/main';
import { NavTabContainer } from '@common/tabs/nav-tab-container';
import { NavTab } from '@common/tabs/nav-tabs';
import { Box, Button, Stack, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Outlet, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { IsAdmin } from 'states/application/product-onboarding';

const spendPlanTabs: NavTab[] = [
  { label: 'Active', route: '/spend-plans/active' },
  { label: 'Inactive', route: '/spend-plans/inactive' },
];

const CreateSpendPlanButton = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const truncateText = useMediaQuery(
    `(max-width: ${theme.breakpoints.xs})`,
    false,
    { getInitialValueInEffect: false },
  );

  const handleClick = () => {
    navigate('new');
  };

  return (
    <Button
      onClick={handleClick}
      fz={truncateText ? '2rem' : undefined}
      fw={truncateText ? 400 : undefined}
      leftIcon={truncateText ? null : `+`}
      px={truncateText ? 'sm' : undefined}
    >
      {truncateText ? `+` : `Create New Spend Plan`}
    </Button>
  );
};

export const SpendPlans = () => {
  const isAdmin = useRecoilValue(IsAdmin);
  const headerAction = isAdmin ? <CreateSpendPlanButton /> : false;

  return (
    <FlexbaseMainLayout>
      <Stack spacing={0}>
        <FlexbaseDefaultHeader
          title={'Spend plans'}
          primaryAction={headerAction}
        />
        <Box sx={{ flexGrow: 1 }}>
          <NavTabContainer tabs={spendPlanTabs}>
            <Outlet />
          </NavTabContainer>
        </Box>
      </Stack>
    </FlexbaseMainLayout>
  );
};
