import { flexbaseOnboardingClient } from '@services/flexbase-client';
import { TermsOfServiceType } from '@services/flexbase/flexbase-onboarding-client';
import { useQuery } from '@tanstack/react-query';

const QUERY_KEY = 'terms_of_service_docs';

type TermsOfServiceDocsReturnType = Awaited<
  ReturnType<typeof flexbaseOnboardingClient.getTermsOfServiceDocs>
>;
type Props<T> = {
  termsType?: TermsOfServiceType;
  useAuth?: boolean;
  select?: (data: TermsOfServiceDocsReturnType) => T;
};

export const useGetTermsOfServiceDocs = <T = TermsOfServiceDocsReturnType>({
  termsType,
  useAuth,
  select,
}: Props<T> = {}) => {
  return useQuery({
    queryKey: [QUERY_KEY, termsType, useAuth],
    queryFn: () =>
      flexbaseOnboardingClient.getTermsOfServiceDocs(termsType, useAuth),
    select,
    meta: {
      errorMessage: `We're unable to get the terms of service documents at this time.`,
    },
  });
};
