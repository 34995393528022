import {
  Box,
  Button,
  Flex,
  Group,
  SegmentedControl,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { useStyles } from './add-vendor.styles';
import { VscChromeClose } from 'react-icons/vsc';
import FlexbaseInput from '@common/input/flexbase-input';
import { useForm } from '@mantine/form';
import FlexbaseSelect from '@common/select/flexbase-select';
import { US_STATES } from 'states/business/constants';
import GooglePlacesSuggest from '@common/input/google-places-suggest-input';
import { formatZip } from '@utilities/formatters/format-address';
import {
  ValidateRoutingNumber,
  validateRequired,
} from '@flexbase-eng/web-components';
import { flexbaseBankingClient } from '@services/flexbase-client';
import { createCounterpartyMutationParams } from 'areas/payments/components/send-payment/send-payment';
import {
  PayMethod,
  Recipient,
} from 'areas/payments/components/send-payment/payment.states';
import { useCreateCounterparty } from '@queries/use-counterparties';
import { showNotification } from '@mantine/notifications';
import { useLocation } from 'react-router-dom';
import { useMercoaSession } from '@mercoa/react';
import { RightContentModal } from '@common/modal/right-content.modal';
import { useCreateRecipient } from '@queries/use-recipients';
import { CreateRecipientParams } from 'states/recipient/recipient';
import { validateUSBirthDate } from '@utilities/validators/validate-dates';
import FlexPatternFormat from 'areas/payments/components/common/flex-pattern-format';
import { formatDateForApi } from '@utilities/formatters/format-date-input';
import { IndividualOrCompany } from 'areas/payments/components/send-payment/international-payments/util/types';

type Props = {
  closeModal: () => void;
};

const AddVendor = ({ closeModal }: Props) => {
  const { classes, theme } = useStyles();
  const { pathname } = useLocation();
  const { refresh } = useMercoaSession();
  const {
    mutate: createRecipientMutate,
    isPending: isPendingRecipientCreation,
  } = useCreateRecipient();
  const {
    mutate: createCounterparty,
    isPending: isPendingCounterpartyCreation,
  } = useCreateCounterparty();

  const vendorOrRecipient = pathname.includes('/bill-pay/vendors')
    ? 'Vendor'
    : 'Recipient';

  const form = useForm({
    initialValues: {
      name: '',
      type: 'ach' as PayMethod,
      accountType: '',
      nickName: '',
      routingNumber: '',
      accountNumber: '',
      recipientType: 'individual' as IndividualOrCompany,
      address: '',
      addressLine2: '',
      city: '',
      state: '',
      postalCode: '',
      country: 'US',
      firstName: '',
      lastName: '',
      dob: '',
    },
    validate: {
      name: (value) => {
        if (form.values.recipientType === 'company') {
          return validateRequired(value) ? null : 'Please input a name.';
        }
      },
      firstName: (value) => {
        if (form.values.recipientType === 'individual') {
          return validateRequired(value) ? null : 'This field is required';
        }
      },
      lastName: (value) => {
        if (form.values.recipientType === 'individual') {
          return validateRequired(value) ? null : 'This field is required';
        }
      },
      dob: (value) => {
        if (form.values.recipientType === 'individual') {
          return validateUSBirthDate(value)
            ? null
            : 'Please provide a valid date of birth value';
        }
      },
      routingNumber: (value) =>
        ValidateRoutingNumber(value)
          ? null
          : 'Routing number is invalid. Must be 9 digits and match an existing bank routing number.',
      accountNumber: (value) =>
        !!value && value.length <= 17
          ? null
          : 'A valid account number is required.',
      accountType: (value) => {
        // only required for ach
        if (form.values.type === 'ach') {
          return validateRequired(value)
            ? null
            : 'Please select an account type.';
        }
      },
      address: (value) => {
        if (form.values.type === 'wire') {
          return validateRequired(value) ? null : 'Please select an address.';
        }
      },
      city: (value) => {
        if (form.values.type === 'wire') {
          return validateRequired(value)
            ? null
            : 'Please add the city for the address.';
        }
      },
      state: (value) => {
        if (form.values.type === 'wire') {
          return validateRequired(value)
            ? null
            : 'Please select the state for the address.';
        }
      },
      postalCode: (value) => {
        if (form.values.type === 'wire') {
          return validateRequired(value)
            ? null
            : 'Please input a 5 digit postal code.';
        }
      },
      country: (value) => {
        if (form.values.type === 'wire') {
          return validateRequired(value)
            ? null
            : 'Please input a country code.';
        }
      },
    },
    transformValues: (values) => ({
      ...values,
      postalCode: formatZip(values.postalCode),
    }),
  });

  const isIndividualType = form.values.recipientType === 'individual';

  const selectAddress = (item: {
    address: string;
    country: string;
    state: string;
    city: string;
    postalCode: string;
  }) => {
    form.setValues({
      address: item.address.trim(),
      city: item.city,
      state: item.state,
      postalCode: item.postalCode,
      country: item.country,
    });
  };

  const createRecipientAccount = (
    recipientData: Recipient,
    recipientId: string,
  ) => {
    const params = createCounterpartyMutationParams(
      recipientData,
      recipientData.type,
      recipientData.name,
      recipientId,
    );

    createCounterparty(params, {
      onSuccess: () => {
        showNotification({
          title: 'Success!',
          message: `${vendorOrRecipient} has been successfully created`,
          color: 'flexbase-teal',
        });
        refresh();
        closeModal();
      },
      onError: (error) => {
        showNotification({
          color: 'red',
          title: 'Error',
          message: `${error.message}`,
        });
      },
    });
  };

  const createRecipient = async (recipient: Recipient) => {
    const validationResult = form.validate();

    if (validationResult.hasErrors) {
      return null;
    } else {
      try {
        const valRouting = await flexbaseBankingClient.checkRoutingNumberUnit(
          form.values.routingNumber,
        );
        if (!valRouting.success || !valRouting.institution) {
          form.setFieldError(
            'routingNumber',
            'Routing number is not recognized',
          );
          return null;
        }
        switch (form.values.type) {
          case 'ach':
            if (!valRouting.institution.isACHSupported) {
              form.setFieldError(
                'routingNumber',
                'Invalid routing number for ACH payments',
              );
              return null;
            }
            break;
          case 'wire':
            if (!valRouting.institution.isWireSupported) {
              form.setFieldError(
                'routingNumber',
                'Invalid routing number for domestic wire payments',
              );
              return null;
            }
            break;
        }
      } catch (e) {
        form.setFieldError(
          'routingNumber',
          'Routing number could not be validated',
        );
        return null;
      }

      const createRecipientParams: CreateRecipientParams = {
        ...(isIndividualType
          ? {
              name: `${recipient.firstName} ${recipient.lastName}`,
              firstName: recipient.firstName,
              lastName: recipient.lastName,
              type: 'individual',
              dob: formatDateForApi(recipient.dob ?? ''),
            }
          : {
              name: recipient.name,
              type: 'company',
            }),
      };

      createRecipientMutate(createRecipientParams, {
        onSuccess(data) {
          const recipientId = data.recipient.id;
          const recipientName = isIndividualType
            ? `${recipient.firstName} ${recipient.lastName}`
            : recipient.name;
          createRecipientAccount(
            { ...recipient, name: recipientName },
            recipientId,
          );
        },
      });
    }
  };

  const handleSubmit = (values: Recipient) => {
    createRecipient(values);
  };

  return (
    <RightContentModal>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <RightContentModal.Header
          title={
            <Text className={classes.title}>
              Add {vendorOrRecipient.toLowerCase()}
            </Text>
          }
          rightTitle={
            <UnstyledButton variant="outline" onClick={closeModal}>
              <VscChromeClose color={theme.colors.contentBackground[2]} />
            </UnstyledButton>
          }
        />

        <RightContentModal.Body>
          <Text size="sm" fw="500">
            {vendorOrRecipient} type
          </Text>
          <SegmentedControl
            mt="sm"
            fullWidth
            classNames={{
              indicator: classes.select,
              root: classes.selectContainer,
            }}
            data={[
              { value: 'individual', label: 'Person' },
              { value: 'company', label: 'Business' },
            ]}
            {...form.getInputProps('recipientType')}
          />
          {isIndividualType ? (
            <>
              <Flex mt="xl" gap="md">
                <FlexbaseInput
                  w="100%"
                  label="First name"
                  placeholder={`Enter ${vendorOrRecipient.toLowerCase()} name`}
                  {...form.getInputProps('firstName')}
                />
                <FlexbaseInput
                  w="100%"
                  label="Last name"
                  placeholder={`Enter ${vendorOrRecipient.toLowerCase()} name`}
                  {...form.getInputProps('lastName')}
                />
              </Flex>
              <FlexPatternFormat
                mt={'md'}
                label={'Recipient date of birth'}
                description="Format: MM/DD/YYYY"
                placeholder={'Enter date of birth'}
                c={'neutral.8'}
                format="##/##/####"
                mask="_"
                allowEmptyFormatting
                {...form.getInputProps('dob')}
              />
            </>
          ) : (
            <FlexbaseInput
              mt="xl"
              label="Business name"
              placeholder={`Enter ${vendorOrRecipient.toLowerCase()} name`}
              {...form.getInputProps('name')}
            />
          )}
          <Box mt="xl">
            <Text size="sm" fw="500">
              Payment method
            </Text>
            <SegmentedControl
              mt="sm"
              fullWidth
              classNames={{
                indicator: classes.select,
                root: classes.selectContainer,
              }}
              data={[
                {
                  value: 'ach',
                  label: (
                    <>
                      <Text>Domestic ACH</Text>
                      <Text color={theme.colors.neutral[7]}>
                        3 business days • No fee
                      </Text>
                    </>
                  ),
                },
                {
                  value: 'wire',
                  label: (
                    <>
                      <Text>Domestic Wire</Text>
                      <Text color={theme.colors.neutral[7]}>
                        1 business day
                      </Text>
                    </>
                  ),
                },
              ]}
              onChange={(value: string) =>
                form.setFieldValue('type', value as PayMethod)
              }
              value={form.values.type}
            />
          </Box>
          <FlexbaseInput
            mt="xl"
            label={
              <Text>
                Nickname{' '}
                <Text span color={theme.colors.neutral[6]}>
                  (optional)
                </Text>
              </Text>
            }
            {...form.getInputProps('nickName')}
            placeholder="Enter nickname (optional)"
          />
          <Flex gap="md" mt="md">
            <FlexbaseInput
              w="100%"
              label={'Routing number'}
              placeholder="Enter routing number"
              {...form.getInputProps('routingNumber')}
            />
            <FlexbaseInput
              w="100%"
              label={'Account number'}
              placeholder="Enter account number"
              {...form.getInputProps('accountNumber')}
            />
          </Flex>
          {form.values.type === 'ach' ? (
            <FlexbaseSelect
              mt="xl"
              placeholder="Account type"
              label="Account type"
              data={['Checking', 'Savings']}
              {...form.getInputProps('accountType')}
            />
          ) : (
            <>
              <Text mt="lg" size="xl">
                {vendorOrRecipient}’s legal address
              </Text>
              <GooglePlacesSuggest
                label={`${vendorOrRecipient} legal address`}
                {...form.getInputProps('address')}
                onItemSubmit={selectAddress}
                placeholder="Address"
                className={classes.addressInput}
                id="input-search-address"
              />
              <FlexbaseInput
                mt="md"
                label="Apartment, suite, floor (optional)"
                data-testid="address2"
                placeholder="Apt, suite, etc. (optional)"
                {...form.getInputProps('addressLine2')}
              />
              <Flex justify="space-between">
                <FlexbaseInput
                  mt="md"
                  label="City"
                  data-testid="city"
                  placeholder="City"
                  w="60%"
                  {...form.getInputProps('city')}
                />
                <FlexbaseSelect
                  mt="md"
                  label="State"
                  w="40%"
                  ml="md"
                  placeholder="State"
                  data-testid="state"
                  data={US_STATES}
                  searchable
                  maxDropdownHeight={400}
                  nothingFound="No data"
                  filter={(value, item) => {
                    const lowerCaseValue = value.toLowerCase();
                    return (
                      item.label?.toLowerCase().includes(lowerCaseValue) ||
                      item.value.toLowerCase().includes(lowerCaseValue)
                    );
                  }}
                  {...form.getInputProps('state')}
                />
                <FlexbaseInput
                  mt="md"
                  w="24%"
                  ml="md"
                  label="ZIP Code"
                  data-testid="zip"
                  placeholder="Zip Code"
                  {...form.getInputProps('postalCode')}
                />
              </Flex>
              <FlexbaseInput
                mt="md"
                label="Country"
                data-testid="country"
                placeholder="US"
                {...form.getInputProps('country')}
              />
            </>
          )}
          <Box mt="3rem">
            <Group className={classes.buttonGroup}>
              <Button
                variant="outline"
                onClick={() => {
                  closeModal();
                }}
                bg="neutral.0"
              >
                Back
              </Button>
              <Button
                loading={
                  isPendingRecipientCreation || isPendingCounterpartyCreation
                }
                type="submit"
              >
                Save {vendorOrRecipient}
              </Button>
            </Group>
          </Box>
        </RightContentModal.Body>
      </form>
    </RightContentModal>
  );
};

export default AddVendor;
