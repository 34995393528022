import { Alert, AlertProps } from '@mantine/core';
import { RedAlertIcon } from 'assets/svg';

type VGSalertProps = {
  message?: string;
} & Omit<AlertProps, 'children'>;

const VGSerror = ({
  message = 'There was an error loading the form. Please try again later',
  ...props
}: VGSalertProps) => {
  return (
    <Alert {...props} icon={<RedAlertIcon />} color="red">
      {message}
    </Alert>
  );
};

export default VGSerror;
