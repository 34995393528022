import { DateTime, Interval } from 'luxon';
import { useSpendTransactionModalFilters } from '../use-spend-transactions-filter';
import { DateRangeFilter } from '@common/filter/date-range.filter';

export const SpendDateRangeFilter = () => {
  const spendTransactionFilters = useSpendTransactionModalFilters();
  return (
    <DateRangeFilter
      filterHook={spendTransactionFilters}
      filterFn={(startDate, endDate, item) => {
        const dateRange = Interval.fromDateTimes(startDate, endDate);
        return dateRange.contains(DateTime.fromISO(item.date));
      }}
    />
  );
};
