import { useForm } from '@mantine/form';
import FlexPatternFormat from '../../../payments/components/common/flex-pattern-format';
import { MultiStepFormStepWrapper } from '@common/multi-step-form/multi-step-form-step-wrapper';
import {
  formatPhoneForApi,
  formatUSPhoneNumber,
} from '@utilities/formatters/format-phone-number';
import { PhoneNumberValidator } from '@flexbase-eng/web-components';
import { usePrefillMultistepFormContext } from '../../pages/prefill/prefill-multi-step-form.context';
import { useAddFactorsContext } from '../../../../providers/add-factors.context';
import { IsFlexHttpError } from '@services/platform/models/authorize.models';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from '../../../../states/application/product-onboarding';

export const PrefillConfirmPhoneStep = () => {
  const { user } = useRecoilValue(ApplicationState);
  const prefillContext = usePrefillMultistepFormContext();

  const { registerFactor, savePhone } = useAddFactorsContext();

  const phoneForm = useForm({
    initialValues: {
      phone: user.cellPhone ? formatUSPhoneNumber(user.cellPhone) : '',
    },
    validate: {
      phone: PhoneNumberValidator(),
    },
  });

  const handleVerifiedFactor = async (phone: string) => {
    try {
      await savePhone(phone);
      prefillContext.goToNextStep({
        phoneVerified: true,
        loading: false,
      });
    } catch {
      prefillContext.setState({
        loading: false,
        error: 'An error occurred while saving your phone number.',
      });
    }
  };

  const handleFormSubmit = async () => {
    const validationResult = phoneForm.validate();

    if (!validationResult.hasErrors) {
      prefillContext.setState({ loading: true, error: null });
      const formattedForPlatform = `+1${formatPhoneForApi(
        phoneForm.values.phone,
      )}`;
      try {
        const { methodId } = await registerFactor({
          factorType: 'phone',
          value: formattedForPlatform,
        });
        prefillContext.goToNextStep({
          phoneVerified: false,
          phoneVerification: { phoneNumber: phoneForm.values.phone, methodId },
        });
      } catch (e) {
        if (IsFlexHttpError(e) && e.statusCode === 409) {
          handleVerifiedFactor(phoneForm.values.phone);
        } else {
          prefillContext.setState({
            error:
              'Failed to update phone number. Please contact support if the issue continues.',
            loading: false,
          });
        }
      }
    }
  };

  return (
    <MultiStepFormStepWrapper id="edit-phone" onFormSubmit={handleFormSubmit}>
      <FlexPatternFormat
        label="Cellphone"
        inputMode="tel"
        data-sardine-id="cellphone"
        data-testid="input-cellphone"
        placeholder="Enter number"
        format="(###) ###-####"
        {...phoneForm.getInputProps('phone')}
      />
    </MultiStepFormStepWrapper>
  );
};
