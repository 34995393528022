import { SimpleSegmentSelectedFilter } from '@common/filter/simple-segmented.filter';
import { useCreditCardsModalFilters } from '../use-credit-cards-filters';

const STATUS_OPTIONS = [
  {
    label: 'Active',
    filterCriteria: 'active',
    key: 'active',
  },
  {
    label: 'Pending activation',
    filterCriteria: 'issued',
    key: 'pending',
  },
  {
    label: 'Canceled',
    filterCriteria: 'terminated',
    key: 'terminated',
  },
  {
    label: 'Frozen',
    filterCriteria: 'suspended',
    key: 'suspended',
  },
];

export const CardStatusFilter = () => {
  const creditCardsFilters = useCreditCardsModalFilters();

  return (
    <SimpleSegmentSelectedFilter
      label="Status"
      options={STATUS_OPTIONS}
      filterKey="status"
      filterHook={creditCardsFilters}
      includesValue={(row) => row.status}
    />
  );
};
