import { ReactNode } from 'react';
import { Box, BoxProps, Text } from '@mantine/core';
import { useSalesStyles } from '@common/sales/styles';

type Props = { icon: ReactNode; text: ReactNode } & BoxProps;

/**
 * Used for in-app sales pages.
 */
export const FakeNotificationStack = ({ icon, text, ...boxProps }: Props) => {
  const { classes } = useSalesStyles();

  return (
    <Box className={classes.fakeNotificationsContainer} {...boxProps}>
      <div className={classes.topNotification}>
        <div className={classes.topNotificationContentContainer}>
          <div className={classes.topNotificationIconContainer}>{icon}</div>
          <Text c="primarySecondarySuccess.8" fw={500} fz={16}>
            {text}
          </Text>
        </div>
      </div>
      <div className={classes.secondNotification} />
      <div className={classes.thirdNotification} />
    </Box>
  );
};
