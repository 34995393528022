import { Anchor, Box, Stack, StackProps, Text } from '@mantine/core';
import { useGetTermsOfServiceDocs } from '@queries/use-terms-of-service-docs';

type Props = StackProps;

export const CreditBankingFooter = (props: Props) => {
  const { data: terms } = useGetTermsOfServiceDocs({
    termsType: 'flexbase',
    useAuth: false,
  });

  const termsAndConditions = terms?.find(
    (t) => t.label === 'Terms and Conditions',
  );
  const privacyPolicy = terms?.find((t) => t.label === 'Privacy Policy');

  return (
    <Stack spacing="1.5rem" p="2rem" {...props}>
      <Box
        fz={10.5}
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          gap: '4rem',
          [theme.fn.smallerThan('xs')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: theme.spacing.md,
          },
        })}
      >
        <Anchor
          target="_blank"
          c="neutral.0"
          href={termsAndConditions?.url || 'https://home.flex.one/legal'}
        >
          Terms &amp; Conditions
        </Anchor>
        <Anchor
          target="_blank"
          c="neutral.0"
          href={privacyPolicy?.url || 'https://home.flex.one/legal'}
        >
          Privacy Policy
        </Anchor>
        <Anchor c="neutral.0" href="mailto:support@flex.one">
          support@flex.one
        </Anchor>
        <Anchor c="neutral.0" href="tel:+1(415)840-8721">
          (415) 840-8721 ext. 571
        </Anchor>
      </Box>
      <Stack fz={10} spacing="xxs">
        <Text>
          *0% interest applies if the full balance is paid within the 60-day
          grace period. Interest applies when the full balance is not paid
          within the 60-day grace period. See your Flex Commercial Credit
          Cardholder Agreement for details.
        </Text>
        <Text>** See your Flex Cashback Terms and Conditions for details.</Text>
        <Text>
          ***Annual Percentage Yield (APY) of up to 4% is accurate as of
          09/15/23. This is a variable rate and may change after the account is
          opened. See your Flex Deposit Account Terms and Conditions for
          details.
        </Text>
        <Text>
          The Flex Commercial Credit Card (&quot;Flex Credit Card&quot;) is
          issued by Patriot Bank, N.A., pursuant to licenses from Mastercard®
          International Incorporated. The Flex Credit Card can be used
          everywhere Mastercard is accepted. Mastercard is a registered
          trademark of Mastercard International. Flex provides the credit for
          the Flex Credit Card. The Flex Credit Card is not available yet in the
          following states: CA, ND, SD, VT, and NV.
        </Text>
        <Text>
          Flexbase Technologies, Inc. is a financial technology company and is
          not a bank. Banking services provided by Thread Bank; Member FDIC. The
          Flexbase Technologies, Inc. Visa® Debit Card (&quot;Flex Debit
          Card&quot; / &quot;Flex Banking&quot;) is issued by Thread Bank
          pursuant to a license from Visa U.S.A. Inc. and may be used everywhere
          Visa debit cards are accepted.
        </Text>
        <Text>
          ©2023 Flexbase Technologies, Inc., d/b/a Flex, &quot;Flex&quot; and
          the Flex logo are registered trademarks. Flex products may not be
          available to all customers. See the Flex Terms of Service for details.
          Terms are subject to change.
        </Text>
      </Stack>
    </Stack>
  );
};
