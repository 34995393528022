import { MantineTheme } from '@mantine/core';
import { Text, View } from '@react-pdf/renderer';
import { stylesPDF } from './styles';
import { AccInfo } from './use-payment-receipt';
import { MoneyMovement } from '@services/flexbase/banking.model';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';

type Props = {
  recipient: AccInfo;
  theme: MantineTheme;
  payment: MoneyMovement;
};

const AchWireReceipt = ({ theme, payment, recipient }: Props) => {
  const styles = stylesPDF({
    primaryDark: theme.colors.primarySecondarySuccess[8],
    secondaryDark: theme.colors.primarySecondarySuccess[2],
    tertiary: theme.colors.tertiary[2],
    neutral: theme.colors.neutral[2],
    textColor: theme.colors.neutral[6],
  });

  return (
    <>
      <View style={{ ...styles.card, marginTop: 20 }}>
        <Text style={styles.greenText}>Recipient’s bank details</Text>
        {recipient.bank && (
          <View style={styles.containerData}>
            <Text style={styles.text}>Bank</Text>
            <Text style={styles.text}>{recipient.bank}</Text>
          </View>
        )}
        <View style={styles.containerData}>
          <Text style={styles.text}>Routing number</Text>
          <Text style={styles.text}>
            {getPaddedAccountMask(recipient.routingNumber, 4)}
          </Text>
        </View>
        <View style={styles.containerData}>
          <Text style={styles.text}>Account number</Text>
          <Text style={styles.text}>
            {getPaddedAccountMask(recipient.accountNumber, 4)}
          </Text>
        </View>
      </View>

      <View style={{ ...styles.card, marginTop: 20 }}>
        <Text style={styles.greenText}>Memo</Text>
        <Text style={styles.text}>&quot;{payment?.payDescription}&quot;</Text>
      </View>

      <View style={{ ...styles.card, marginTop: 20 }}>
        <Text style={styles.greenText}>Cost breakdown</Text>
        <View style={styles.containerData}>
          <Text style={styles.text}>Recipient name</Text>
          <Text style={styles.text}>
            {recipient?.name ?? payment?.payCtrParty}
          </Text>
        </View>
        <View style={styles.containerData}>
          <Text style={styles.text}>Amount</Text>
          <Text style={styles.text}>{payment?.payAmount}</Text>
        </View>
        <View style={styles.containerData}>
          <Text style={styles.text}>Total paid</Text>
          <Text style={styles.text}>{payment?.payAmount}</Text>
        </View>
      </View>
    </>
  );
};

export default AchWireReceipt;
