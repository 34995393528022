import { DateTime } from 'luxon';

export const formatDateInput = (value: string) => {
  if (!value) {
    return value;
  }

  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (cvLength < 3) {
    return currentValue;
  }
  if (cvLength < 5) {
    return `${currentValue.slice(0, 2)}/${currentValue.slice(2)}`;
  }
  return `${currentValue.slice(0, 2)}/${currentValue.slice(
    2,
    4,
  )}/${currentValue.slice(4, 8)}`;
};

export const formatDateForApi = (date: string): string => {
  if (!date) {
    return date;
  }

  const dateTime = DateTime.fromFormat(date, 'MM/dd/yyyy');

  return dateTime.toFormat('yyyy-MM-dd');
};
