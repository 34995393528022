import { Navigate, Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { IsIntegrationsAuthorized } from 'states/integrations/integrations';

export const SettingsIntegrationsGuard = () => {
  const isAuthorized = useRecoilValue(IsIntegrationsAuthorized);

  if (!isAuthorized) {
    return <Navigate to="/settings" />;
  }

  return <Outlet />;
};
