import { Divider, Modal, ModalProps, Stack } from '@mantine/core';
import InvoiceForm from './invoice.form';

const InvoiceModal = ({ ...props }: ModalProps) => {
  return (
    <Modal
      {...props}
      title="Submit Invoice"
      centered
      styles={{
        header: { fontSize: 20 },
      }}
    >
      <Divider />
      <Stack>
        <InvoiceForm />
      </Stack>
    </Modal>
  );
};

export default InvoiceModal;
