import GradientLineChart from '@common/charts/gradient-line-chart';
import DetailPageLayout from '@common/layouts/detail-page/detail-page-layout';
import { NavTabContainer } from '@common/tabs/nav-tab-container';
import { SkeletonLoading } from '@flexbase-eng/web-components';
import { Box, Text } from '@mantine/core';
import { useGetBankingTransactions } from '@queries/use-banking-transactions';
import { useGetDepositAccount } from '@queries/use-deposit-account';
import { useGetDepositAccountHistory } from '@queries/use-deposit-account-history';
import { DateTime } from 'luxon';
import { Outlet, useParams } from 'react-router-dom';
import AccountDetails from './account-details/account-details';
import { useFilteredDates } from './account-details/hooks';
import MoneyInOutDetails from './account-details/money-in-out-details';
import AccountDetailsTitleBar from './account-details/account-details-title-bar';
import TransactionsSummary from './components/transactions-summary/transactions-summary';
import { DepositAccount } from './move-funds/move-funds.model';
import { useChartFilter } from './utils/chart-filter';
import GenericError from './utils/generic-error';

const basePath = 'banking/account-details';

const getTabs = (accountId: string, account?: DepositAccount) => [
  {
    label: 'Transactions',
    route: `/${basePath}/${accountId}/transactions`,
  },
  {
    label: 'Payments',
    route: `/${basePath}/${accountId}/payments`,
  },
  {
    label: 'Checks',
    route: `/${basePath}/${accountId}/checks`,
  },
  {
    label: 'Debit cards',
    route: `/${basePath}/${accountId}/debit-cards`,
  },
  {
    label: 'Statements',
    route: `/${basePath}/${accountId}/statements`,
  },
  {
    label: 'Account information',
    route: `/${basePath}/${accountId}/account-information`,
    stateData: account,
  },
];

const getAccountHistoryParams = (
  before?: string,
  after?: string,
  createdAt?: string,
) => {
  if (before && after) {
    return {
      after,
      before,
    };
  } else if (createdAt) {
    return {
      after: DateTime.fromSQL(createdAt).toISODate()!,
      before: DateTime.now().toISODate(),
    };
  } else {
    return {};
  }
};

const BankAccountDetails = () => {
  const { id: accountId } = useParams();
  const { before, after } = useFilteredDates();
  const { chartFilterValue, handleChartMouseMove, handleChartMouseLeave } =
    useChartFilter();

  const {
    data: depositAccountData,
    isError: isDepositAccountError,
    isLoading: isLoadingDepositAccount,
  } = useGetDepositAccount(accountId || '');
  const account = depositAccountData?.account;

  const accountHistoryParams = getAccountHistoryParams(
    before,
    after,
    account?.createdAt,
  );

  const { data: depositAccountHistoryData } = useGetDepositAccountHistory(
    accountId!,
    accountHistoryParams,
  );

  const chronologicalBalancesAndDates = depositAccountHistoryData?.balances
    .map((item) => ({
      name: DateTime.fromSQL(item.date).toFormat('LLL dd'),
      date: item.date,
      value: item.balance / 100,
    }))
    .reverse();

  const transactionDateParams = before &&
    after && {
      fromDate: after,
      toDate: before,
    };

  const { data: transactionsData } = useGetBankingTransactions({
    depositAccountId: accountId,
    ...transactionDateParams,
  });

  const tabs = getTabs(accountId ?? '', account);

  if (isDepositAccountError) {
    return (
      <DetailPageLayout
        linkTitle="All bank accounts"
        linkPath="/banking/accounts"
      >
        <GenericError>
          <Text>
            We are unable to retrieve your bank account information at the
            moment.
          </Text>
        </GenericError>
      </DetailPageLayout>
    );
  }

  return (
    <DetailPageLayout
      linkTitle="All bank accounts"
      linkPath="/banking/accounts"
    >
      <AccountDetailsTitleBar
        companyId={account?.companyId ?? ''}
        depositId={accountId ?? ''}
        isClosedAccount={account?.status === 'Closed'}
        title={account?.nickName ?? ''}
      />
      <TransactionsSummary
        isReserveAccount={false}
        chartFilterValue={chartFilterValue}
        accountDetailsComponent={
          <AccountDetails
            currentBalance={account?.balance ?? 0}
            accountNumber={account?.accountNumber ?? ''}
            routingNumber={account?.routingNumber ?? ''}
          />
        }
        chart={
          <GradientLineChart
            data={chronologicalBalancesAndDates}
            onMouseMove={handleChartMouseMove}
            onMouseLeave={handleChartMouseLeave}
          />
        }
        modalContent={
          <MoneyInOutDetails
            accountNumber={account?.accountNumber ?? ''}
            accountId={accountId ?? ''}
          />
        }
        transactions={transactionsData?.transactions ?? []}
      />
      {isLoadingDepositAccount ? (
        <Box mt="md">
          <SkeletonLoading withTabs />
        </Box>
      ) : (
        <NavTabContainer tabs={tabs}>
          <Outlet />
        </NavTabContainer>
      )}
    </DetailPageLayout>
  );
};

export default BankAccountDetails;
