import { DateTime, Interval } from 'luxon';

export const sortDate = (
  a: { createdAt: string },
  b: { createdAt: string },
  ascending = true,
) => {
  const multiplier = ascending ? 1 : -1;
  return (
    multiplier *
    (DateTime.fromSQL(a.createdAt).toMillis() -
      DateTime.fromSQL(b.createdAt).toMillis())
  );
};

export const getCurrentWeek = () => {
  const today = DateTime.now();
  const sunday = today.startOf('week').minus({ days: 1 });
  const saturday = today.endOf('week').minus({ days: 1 });
  return { saturday, sunday };
};

export const getMinMaxDates = (dates: string[], format: string) => {
  const arrayDates: any = dates.map((currentDay) =>
    DateTime.fromJSDate(new Date(currentDay)),
  );

  const maxDate = DateTime.max(...arrayDates).toFormat(format);
  const minDate = DateTime.min(...arrayDates).toFormat(format);

  return { maxDate, minDate };
};

export type StatementDates = {
  statements: ({ date: string; target: string } | any)[];
  totalYears: string[];
};

export const generateStatementDates = (
  startingDateSqlFormat: string,
  typeRequested?: 'invoices' | 'statements' | 'both',
): StatementDates => {
  const companyCreatedDate = DateTime.fromSQL(startingDateSqlFormat);
  const intervalStartDate =
    companyCreatedDate.day < 16
      ? companyCreatedDate.startOf('month')
      : companyCreatedDate.set({ day: 16 });
  const intervalEndDate = DateTime.now();

  const statementInterval = Interval.fromDateTimes(
    intervalStartDate,
    intervalEndDate,
  );

  // Need to put this side effect here to make the code work.
  let totalYears = statementInterval
    .splitBy({ year: 1 })
    .filter((i) => i.isValid)
    .map((i) => i.start!.toFormat('yyyy'));

  // If the last year has not been completed and it's not the current year, add the following year
  if (
    intervalEndDate.diff(intervalStartDate, 'years').years >
      totalYears.length - 1 &&
    intervalEndDate.year !== companyCreatedDate.year
  ) {
    totalYears.push(intervalEndDate.toFormat('yyyy'));
  }

  totalYears = [...new Set(totalYears)];

  // stupid? yes. works? also yes.
  const statementDates = statementInterval
    .splitBy({ day: 1 })
    .map((i) => i.start)
    .filter((i) => i !== null && i.isValid)
    .filter((d) => {
      const day = d!.day;
      const endOfMonth = d!.endOf('month').day;

      // If this is a statement period day, return it.
      if (day === 1 || day === 15 || day === 16 || day === endOfMonth) {
        return true;
      }
    });

  const startDay = intervalStartDate.get('day');

  const statementMap: string[][] = [];

  // Reduce the array into a key/value map of start and end dates.
  statementDates.reduce((prev, curr) => {
    if (curr && prev) {
      if (curr.day === 1) {
        statementMap.push([
          `${prev.toFormat('MMMM')}`,
          prev.startOf('month').toISODate(),
          prev.endOf('month').toISODate(),
          'monthly',
        ]);
      }
      if (curr.day === 1 || curr.day === 16 || curr.day === startDay) {
        return curr;
      }
      statementMap.push([
        `${prev.toFormat('MMMM')} ${curr.day}`,
        prev.toISODate(),
        curr.toISODate(),
        'biweekly',
      ]);
    }
    return curr;
  });

  // Format the dates into what the table is expecting.
  const statements = statementMap
    .map(([label, start, end, type]) => {
      const startDate = DateTime.fromISO(start);
      const endDate = DateTime.fromISO(end);
      if (typeRequested === 'invoices' && type === 'biweekly') {
        return {
          date: label,
          target: endDate.toFormat('yyyy-MM-dd'),
          after: startDate.toFormat('yyyy-MM-dd'),
          before: endDate.toFormat('yyyy-MM-dd'),
          type,
        };
      } else if (typeRequested === 'statements' && type === 'monthly') {
        return {
          date: label,
          target: endDate.toFormat('yyyy-MM-dd'),
          after: startDate.toFormat('yyyy-MM-dd'),
          before: endDate.toFormat('yyyy-MM-dd'),
          type,
        };
      } else if (typeRequested === 'both') {
        return {
          date: label,
          target: endDate.toFormat('yyyy-MM-dd'),
          after: startDate.toFormat('yyyy-MM-dd'),
          before: endDate.toFormat('yyyy-MM-dd'),
          type,
        };
      }
      return;
    })
    .filter((statement) => !!statement);

  return { statements, totalYears };
};

export const getMonthDayYear = (date: string) => {
  const dateObj = DateTime.fromSQL(date);
  return dateObj.toFormat('LLL d, yyyy');
};

export const isFuturePayment = (date: string) =>
  DateTime.fromISO(date) > DateTime.now();

export const getCurrentIsoDate = () => DateTime.now().toISODate();

export const isWeekend = (date: Date): boolean => {
  return DateTime.fromJSDate(date).isWeekend;
};

const calculateSpecificWeekdayOfMonth = (
  year: number,
  month: number,
  targetWeekday: number,
  targetWeek: number,
): string | null => {
  const startDate = DateTime.local(year, month, 1);
  let date = startDate;
  let count = 0;

  while (count < targetWeek) {
    if (date.weekday === targetWeekday) {
      count++;
      if (count === targetWeek) {
        break;
      }
    }
    date = date.plus({ days: 1 });
    if (date.month !== month) {
      return null;
    }
  }

  return date.toISODate();
};

const birthdayMartinLutherKing = (year: number): string | null =>
  calculateSpecificWeekdayOfMonth(year, 1, 1, 3);
const washingtonBirthday = (year: number): string | null =>
  calculateSpecificWeekdayOfMonth(year, 2, 1, 3);
const memorialDay = (year: number): string | null =>
  calculateSpecificWeekdayOfMonth(year, 5, 1, 4);
const laborDay = (year: number): string | null =>
  calculateSpecificWeekdayOfMonth(year, 9, 1, 1);
const columbusDay = (year: number): string | null =>
  calculateSpecificWeekdayOfMonth(year, 10, 1, 2);
const thanksgivingDay = (year: number): string | null =>
  calculateSpecificWeekdayOfMonth(year, 11, 4, 4);

export const usFederalHolidays = (): Set<string> => {
  const currentYear = DateTime.now().year;
  const maxYear = DateTime.now().plus({ years: 2 }).year;

  const holidays: (string | null)[] = [];

  for (let year = currentYear; year <= maxYear; year++) {
    holidays.push(
      `${year}-01-01`, // New Year's Day
      birthdayMartinLutherKing(year),
      washingtonBirthday(year),
      memorialDay(year),
      `${year}-06-19`, // Juneteenth National Independence Day
      `${year}-07-04`, // Independence Day
      laborDay(year),
      columbusDay(year),
      `${year}-11-11`, // Veterans Day
      thanksgivingDay(year),
      `${year}-12-25`, // Christmas Day
    );
  }

  return new Set(holidays.filter((date) => date !== null) as string[]);
};

export const isHoliday = (date: Date): boolean => {
  const formattedDate = date.toISOString().split('T')[0];
  return usFederalHolidays().has(formattedDate);
};
