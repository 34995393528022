import { Container, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { CardByUser } from 'services/flexbase/banking.model';

type Props = {
  card: CardByUser;
};

const BillingAddress = ({ card }: Props) => {
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const { address } = card;
  const { street, street2, city, state, postalCode } = address;

  return (
    <Container p={0} mx={useMobileView ? 20 : 50}>
      <Text mb={8} size={14}>
        Billing address
      </Text>
      <Text size={20}>{street}</Text>
      <Text size={20}>{street2}</Text>
      <Text size={20}>
        {city}, {state} {postalCode}
      </Text>
    </Container>
  );
};

export default BillingAddress;
