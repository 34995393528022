import { Box, Text } from '@mantine/core';
import { AvailableCurrencies } from '../send-payment/international-payments/util/types';

export const tooltipPaymentInfo = (currency: AvailableCurrencies) => (
  <Box>
    <Text>
      This is the best estimate we have on the USD to {currency} exchange rate.
    </Text>{' '}
    <Text>
      The recipient amount is fixed, and the total USD needed to send that
      amount will be finalized when the payment is processed.
    </Text>{' '}
    We partner with CurrencyCloud to provide competitive exchange rates for most
    currencies.
  </Box>
);
