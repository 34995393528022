import { createStyles } from '@mantine/core';

export const useSpendPlanLimitsStyles = createStyles((theme) => ({
  container: {
    backgroundColor: theme.fn.themeColor('contentBackground', 2),
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
    borderRadius: theme.defaultRadius,
    marginTop: theme.spacing.lg,
    padding: theme.spacing.xl,
  },
}));
