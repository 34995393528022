import { filterDirectionToLabel } from '@common/filter/amount-filter';
import { formatCurrency } from '@flexbase-eng/web-components';
import { useCreditTransactionModalFilters } from '../use-credit-transaction-filters';
import { QuantityFilter } from '@common/filter/quantity-filter';

export const CreditTransactionsAmountFilter = () => {
  const { addFilter, getFilterByKey } = useCreditTransactionModalFilters();
  return (
    <QuantityFilter
      value={
        getFilterByKey('amount')?.filterValue || {
          amount: '',
          direction: 'equal',
        }
      }
      onChange={(val) =>
        addFilter('amount', {
          label: `Amount - ${filterDirectionToLabel(
            val.direction,
          )} ${formatCurrency(val.amount)}`,
          key: 'amount',
          fn: (row) => {
            const amountNum = Math.abs(+row.amount);
            const filterNum = +val.amount;
            switch (val.direction) {
              case 'greaterThan':
                return amountNum > filterNum;
              case 'lessThan':
                return amountNum < filterNum;
              case 'equal':
              default:
                return amountNum === filterNum;
            }
          },
          filterValue: val,
          showChip: true,
        })
      }
    />
  );
};
