import { Anchor, Box, Group, Text } from '@mantine/core';
import { RegistrationForm } from './registration.form';
import { CheckmarkIcon, FlexIconNoPadding } from '../../../../assets/svg';
import { Link } from 'react-router-dom';
import { useRegistrationStyles } from './use-registration-styles';
import { useRecoilValue } from 'recoil';
import { RegistrationProductsState } from '../../../../states/application/product-onboarding';
import { CreditBankingFooter } from '@common/footer/credit-banking.footer';
import { FlexbaseFooter } from '@common/footer/flexbase-footer';

export const RegistrationPage = () => {
  const { classes } = useRegistrationStyles();
  const registeringForProducts = useRecoilValue(RegistrationProductsState);

  const isBankingOnly = registeringForProducts.every((p) => p === 'BANKING');

  return (
    <Box className={classes.page}>
      <Box className={classes.leftContent}>
        <Group position="apart" align="center" w="100%">
          <FlexIconNoPadding width="7rem" />
          <Text>
            Existing customer?{' '}
            <Anchor
              component={Link}
              to="/login"
              className={classes.link}
              data-testid="button-login"
            >
              Log in
            </Anchor>
          </Text>
        </Group>
        <Box className={classes.formContainer}>
          <RegistrationForm />
        </Box>
      </Box>
      <Box className={classes.rightContent}>
        <Box className={classes.centerGraphics}>
          <Box className={classes.fakeNotificationGraphic}>
            <Box className={classes.fakeNotificationContent}>
              <CheckmarkIcon className={classes.fakeNotificationIcon} />
            </Box>
            <Box>
              <Text c="primarySecondarySuccess.8" fz={28} fw={500}>
                {isBankingOnly
                  ? 'Banking account created'
                  : 'Virtual card issued'}
              </Text>
              <Text fz={18} fw={500} c="neutral.6">
                {isBankingOnly
                  ? 'Terravive is now banking with Flex'
                  : "Sofia's card is ready for immediate use"}
              </Text>
            </Box>
          </Box>
          <Box className={classes.fakeNotificationFakeArrow} />
        </Box>
        {!isBankingOnly && (
          <img
            src="/images/sofia-garcia-card.png"
            alt="Flex credit card with your name on it"
          />
        )}
      </Box>
      {/* TODO: Update to use a banking specific footer when copy available. */}
      {isBankingOnly ? (
        <FlexbaseFooter classNames={{ footer: classes.footer }} />
      ) : (
        <CreditBankingFooter className={classes.footer} />
      )}
    </Box>
  );
};
