import { SimpleSegmentSelectedFilter } from '@common/filter/simple-segmented.filter';
import { useCreditTransactionModalFilters } from '../use-credit-transaction-filters';

const FILTER_TYPE_OPTIONS = [
  {
    label: 'Card purchase',
    filterCriteria: 'Card Purchase',
    key: 'purchase',
  },
  {
    label: 'Payment',
    filterCriteria: 'Payment',
    key: 'payment',
  },

  {
    label: 'Interest',
    filterCriteria: 'Interest',
    key: 'interest',
  },
  {
    label: 'Fee',
    filterCriteria: 'Fee',
    key: 'fee',
  },
  {
    label: 'Refunds/Credits',
    filterCriteria: 'Credit',
    key: 'credit',
  },
];

export const CreditTypeFilter = () => {
  const creditTransactionFilters = useCreditTransactionModalFilters();

  return (
    <SimpleSegmentSelectedFilter
      filterHook={creditTransactionFilters}
      filterKey="type"
      options={FILTER_TYPE_OPTIONS}
      label="Type"
      includesValue={(row) => {
        if (row.status === 'settled' && parseInt(row.amount) < 0) {
          return 'Credit';
        }
        return row.type;
      }}
    />
  );
};
