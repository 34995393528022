import { uniq } from 'underscore';
import {
  OnboardingCompany,
  OnboardingStatusResponse,
  OnboardingUser,
} from 'states/onboarding/onboarding-info';
import { OptedProduct } from './product-onboarding';
import { AuthenticationFactor } from '@flexbase-eng/types/dist/identity';

/**
 * This type is primarily to provide autocomplete and type safety for onboarding routes.
 */
export type OnboardingRouteNames =
  | 'product-select'
  | 'change-password'
  | 'verify-phone'
  | 'user-address'
  | 'verify-identity'
  | 'additional-identity'
  | 'intended-countries'
  | 'business-officers'
  | 'job-title'
  | 'business-type'
  | 'business-activity'
  | 'verify-business'
  | 'business-owners'
  | 'control-person'
  | 'summary'
  | 'verify-bank'
  | 'doc-upload-id'
  | 'doc-upload-tax'
  | 'doc-upload-csa'
  | 'treasury-allocation';

/**
 * This type is purely used to provide autocomplete for required fields, and to prevent
 * errors caused by typos like `user.birthdate` instead of `user.birthDate`
 */
export type ApplicationRequiredFields =
  | 'user.changePassword'
  | 'user.taxId'
  | 'user.identification'
  | 'user.birthDate'
  | 'user.phone'
  | 'user.firstName'
  | 'user.lastName'
  | 'user.cellPhone'
  | 'user.address'
  | 'user.termsOfServiceSigned'
  | 'user.plaidConnection'
  | 'user.creditTermsOfServiceSigned'
  | 'company.intendedCountries'
  | 'company.companyName'
  | 'company.taxId'
  | 'company.naicsCode'
  | 'company.formationDate'
  | 'company.legalStructure'
  | 'company.category'
  | 'company.website'
  | 'company.annualRevenue'
  | 'company.monthlyExpenditure'
  | 'company.businessPurpose'
  | 'company.reinvestTreasuryInterest'
  | 'company.phone'
  | 'company.address'
  | 'company.owners'
  | 'company.officers'
  | 'company.controlPerson'
  | 'company.upload.identityDoc'
  | 'company.upload.taxpayerIDDoc'
  | 'company.upload.conductBusinessDoc'
  | 'company.financialInstitutions'
  | 'user.jobTitle'
  | 'user.treasuryTermsOfServiceSigned'
  | 'user.bankingTermsOfServiceSigned'
  | 'user.internationalPaymentsTermsOfServiceSigned'
  | 'company.primaryPlaidAccount'
  | 'user.stripeConnection'
  | 'company.businessVertical'
  | 'user.personalGuarantySigned'
  | 'company.personalGuarantySigned'
  | 'user.patriotActSigned'
  | 'user.ficoPullSigned';
type RouteOrder = {
  name: OnboardingRouteNames;
  order: number;
  adminOnly: boolean;
};
type RequiredFieldToRouteMap = Record<string, RouteOrder>;
type RouteNameToRouteMap = Record<string, RouteOrder>;
type Officer = {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
};

export type ProductStatus = {
  appStatus?: string;
  status:
    | 'unused'
    | 'pending'
    | 'incomplete'
    | 'awaitingDocuments'
    | 'approved'
    | 'denied'
    | 'unqualified'
    | 'restricted'
    | 'ready';
  createdAt?: string;
  creditLimit?: string;
  admName?: string;
  admNumber?: number;
  reason?: string;
  invalidOwners?: any[];
  optedInAt?: string;
  invalidOfficers?: Officer[];
};
export type ApplicationStatus = {
  credit: ProductStatus;
  banking: ProductStatus;
  internationalPayments: ProductStatus;
  treasury: ProductStatus;
};
export type ProductOnboardingStatusResponse = OnboardingStatusResponse & {
  requiredCredit: string[];
  requiredBanking: string[];
  requiredInternationalPayments: string[];
  requiredTreasury: string[];
  completedOnboarding?: string;
  productStatus: ApplicationStatus;
};
export type _ApplicationState = {
  company: OnboardingCompany;
  user: OnboardingUser;
  completedOnboarding: string;
  requiredStripeCredit: string[];
  requiredTreasury: string[];
  requiredCredit: string[];
  requiredBanking: string[];
  requiredInternationalPayments: string[];
  requiredProperties: string[];
  productStatus: ApplicationStatus;
  optedProducts: OptedProduct[];
  excludedProducts: OptedProduct[];
  userType: 'admin' | 'other';
  userIsApplicant: boolean;
  personId: string;
  accountId: string;
  businessId: string;
  factors: AuthenticationFactor[];
};
type RequirementArrayPropertyName = Extract<
  keyof _ApplicationState,
  | 'requiredTreasury'
  | 'requiredBanking'
  | 'requiredInternationalPayments'
  | 'requiredCredit'
  | 'requiredStripeCredit'
>;

export class OnboardingApplicationConfig {
  private readonly _products: OptedProduct[];
  private readonly _routes: OnboardingRouteNames[];
  private readonly _reqPropRoute: RequiredFieldToRouteMap;
  private readonly _requirementArrayNames: RequirementArrayPropertyName[];
  private readonly _routeNameMap: RouteNameToRouteMap;

  constructor(
    products: OptedProduct[],
    map: RequiredFieldToRouteMap,
    requirementArrayName: RequirementArrayPropertyName[],
    routeNameMap: RouteNameToRouteMap,
    private readonly _endRoute?: string,
  ) {
    this._products = products;
    this._routes = uniq(
      Object.values(map)
        .sort((first, second) => first.order - second.order)
        .map((r) => r.name),
    );
    this._reqPropRoute = map;
    this._requirementArrayNames = requirementArrayName;
    this._routeNameMap = routeNameMap;
  }

  getNextRouteFromStatus(
    status: _ApplicationState,
  ): OnboardingRouteNames | 'end' | string {
    const requiredFields = uniq(
      this._requirementArrayNames.flatMap((name) => status[name]),
    );

    if (requiredFields.length === 0) {
      return this._endRoute || 'end';
    }

    const orderedSteps = requiredFields
      .map((r) => this._reqPropRoute[r])
      .filter((r) => !!r)
      .sort((firstValue, secondValue) => firstValue.order - secondValue.order);
    return orderedSteps[0].name;
  }

  getNextRouteFromCurrentRoute(
    route: OnboardingRouteNames,
  ): OnboardingRouteNames {
    const currentIndex = this._routes.indexOf(route);
    if (currentIndex < 0) {
      return 'oops' as OnboardingRouteNames;
    } else if (currentIndex === this._routes.length - 1) {
      return route;
    } else {
      return this._routes[currentIndex + 1];
    }
  }

  getPreviousRoute(
    route: OnboardingRouteNames,
    userIsAdmin: boolean,
  ): OnboardingRouteNames {
    const currentIndex = this._routes.indexOf(route);
    if (currentIndex < 0) {
      return 'oops' as OnboardingRouteNames;
    } else if (currentIndex === 0) {
      return route;
    } else {
      const previousRoute = this._routes[currentIndex - 1];
      const foundRoute = this._routeNameMap[previousRoute];
      if (userIsAdmin) {
        return previousRoute;
      } else if (userIsAdmin === foundRoute.adminOnly) {
        return previousRoute;
      } else {
        return this.getPreviousRoute(previousRoute, userIsAdmin);
      }
    }
  }

  get routes(): OnboardingRouteNames[] {
    return this._routes;
  }

  get products(): OptedProduct[] {
    return this._products;
  }

  get endRoute(): string {
    return this._endRoute ?? '';
  }
}

export class ApplicationConfigBuilder {
  private currentRoute?: RouteOrder;
  private readonly requiredFieldsMap: Record<string, RouteOrder>;
  private readonly routeNamesMap: RouteNameToRouteMap;
  private requirementArrayNames: RequirementArrayPropertyName[];
  private endRoute: string;

  constructor(private readonly forProducts: OptedProduct[]) {
    this.requiredFieldsMap = {};
    this.routeNamesMap = {};
    this.requirementArrayNames = [];
    this.endRoute = '';
    return this;
  }

  forRequirementArray(name: RequirementArrayPropertyName) {
    this.requirementArrayNames.push(name);
    return this;
  }

  addRoute(routeName: OnboardingRouteNames, order: number, adminOnly = true) {
    const routeOrder = { name: routeName, order, adminOnly };
    this.currentRoute = routeOrder;
    this.routeNamesMap[routeName] = routeOrder;
    return this;
  }

  withRequiredField(field: ApplicationRequiredFields) {
    if (!this.currentRoute) {
      throw new Error(
        'Application config error. Route name and order must be configured before mapping a required field to it.',
      );
    }
    this.requiredFieldsMap[field] = {
      name: this.currentRoute.name,
      order: this.currentRoute.order,
      adminOnly: this.currentRoute.adminOnly,
    };
    return this;
  }

  addEndRoute(route: string) {
    this.endRoute = route;
  }

  build(): OnboardingApplicationConfig {
    if (!this.requirementArrayNames.length) {
      throw new Error(
        'You must configure the property name of at least one requirements array',
      );
    }

    return new OnboardingApplicationConfig(
      this.forProducts,
      this.requiredFieldsMap,
      this.requirementArrayNames,
      this.routeNamesMap,
      this.endRoute,
    );
  }
}
