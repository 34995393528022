import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { LoadingOverlay, useMantineTheme } from '@mantine/core';
import { Document, Page, PDFViewer, Text, View } from '@react-pdf/renderer';

import { stylesPDF } from './styles';
import AchWireReceipt from './ach-wire-receipt';
import IntlWireReceipt from './intl-wire-receipt';
import HeaderPDF from 'components/pdf-viewer/header';
import { formatStatusPDF } from '../payments-table/utils';
import { MoneyMovement } from '@services/flexbase/banking.model';
import usePaymentReceipt, { AccInfo } from './use-payment-receipt';
import { useGetDepositAccount } from '@queries/use-deposit-account';
import { formatCurrency } from '@utilities/formatters/format-currency';

const PaymentReceiptPDF = () => {
  const theme = useMantineTheme();
  const { paymentId } = useParams();
  const {
    recipient,
    paymentInfo,
    intlPayment,
    beneficiary,
    currencyConversionRateData,
    isError,
    isLoading,
  } = usePaymentReceipt(paymentId || '');

  // Getting the account number from the account where the money is outgoing
  const {
    data: depositAccountData,
    isError: isDepositAccountError,
    isLoading: isLoadingDepositAccount,
  } = useGetDepositAccount(paymentInfo?.depositId || '');
  const depositAccountNumber =
    depositAccountData?.account?.accountNumber?.slice(-4);

  const rateAmount = currencyConversionRateData?.rate ?? 1;
  const isIntlPayment = paymentInfo?.type === 'internationalWire';
  const usdAmount = paymentInfo?.payAmount?.replace(/\$/g, '');

  const createdAt = DateTime.fromSQL(paymentInfo?.createdAt || '').toFormat(
    'LLL dd, yyyy',
  );
  const paymentDate = DateTime.fromSQL(
    paymentInfo?.scheduledFor || '',
  ).toFormat('LLL dd, yyyy');
  const dateToDisplay = paymentInfo?.scheduledFor ? paymentDate : createdAt;

  const styles = stylesPDF({
    primaryDark: theme.colors.primarySecondarySuccess[8],
    secondaryDark: theme.colors.primarySecondarySuccess[2],
    tertiary: theme.colors.tertiary[2],
    neutral: theme.colors.neutral[2],
    textColor: theme.colors.neutral[6],
  });

  const getPaymentType = (payment: MoneyMovement) => {
    switch (payment.type) {
      case 'ach':
        return 'ACH credit';

      case 'wire':
        return 'Domestic wire';

      case 'book':
        return 'Internal transfer';

      case 'internationalWire':
        return 'International wire';
    }
  };

  const paymentStatus =
    paymentInfo?.internationalPaymentId && paymentInfo?.status === 'Sent'
      ? intlPayment?.status
      : paymentInfo?.status;

  if (isLoading || isLoadingDepositAccount) {
    return <LoadingOverlay visible />;
  }

  return (
    <PDFViewer style={{ width: '100%', height: '100vh' }}>
      <Document
        title={`Flex-payment-receipt-${dateToDisplay}-${paymentInfo?.id?.slice(
          -4,
        )}.pdf`}
      >
        <Page size="A4">
          <View fixed>
            <HeaderPDF {...{ theme }} />
          </View>

          <View style={styles.containerHeader}>
            <View>
              <Text style={styles.textHeader}>Flexbase Technologies, Inc.</Text>
              <Text style={styles.textHeader}>
                Checking ••{depositAccountNumber}
              </Text>
            </View>
          </View>

          {isError || isDepositAccountError ? (
            <View style={{ ...styles.card, marginTop: 20 }}>
              <Text style={styles.paymentAmount}>
                We encountered an error while retrieving payment information.
              </Text>
            </View>
          ) : (
            <>
              <View style={styles.statusContainer}>
                <View>
                  <Text style={styles.grayText}>{dateToDisplay}</Text>
                  <Text style={styles.bigGrayText}>Your receipt from Flex</Text>
                </View>
                <Text style={styles.grayText}>
                  Status: {formatStatusPDF(paymentStatus ?? 'queued')}
                </Text>
              </View>

              <View style={styles.containerPage}>
                <View style={{ ...styles.card, marginTop: 20 }}>
                  <View style={styles.transferDetails}>
                    <Text style={{ ...styles.text, fontStyle: 'thin' }}>
                      {paymentInfo ? getPaymentType(paymentInfo) : ''}
                    </Text>
                    <Text style={styles.paymentAmount}>
                      {formatCurrency(usdAmount!)}
                    </Text>
                    <Text style={{ ...styles.text, fontStyle: 'thin' }}>
                      to {paymentInfo?.payCtrParty}
                    </Text>
                  </View>
                </View>

                {isIntlPayment && intlPayment ? (
                  <View style={{ height: '70%' }}>
                    <IntlWireReceipt
                      theme={theme}
                      payment={intlPayment}
                      usdAmount={usdAmount!}
                      rateAmount={rateAmount}
                      beneficiary={beneficiary}
                      paymentStatus={paymentStatus ?? 'queued'}
                    />
                  </View>
                ) : (
                  <AchWireReceipt
                    theme={theme}
                    payment={paymentInfo!}
                    recipient={recipient as AccInfo}
                  />
                )}
              </View>
            </>
          )}

          <View style={styles.footerContainer} fixed>
            <View style={styles.containerData}>
              <View>
                <Text>Flexbase Technologies Inc</Text>
                <Text>88 SW 7th St Miami, FL, 33130-3691 US</Text>
              </View>
              <View style={{ alignItems: 'flex-end', flexDirection: 'column' }}>
                <Text>Transaction ID: {paymentInfo?.id}</Text>
                <Text>Email us at support@flex.one</Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default PaymentReceiptPDF;
