import { Grid } from '@mantine/core';
import { InvoiceMetrics } from '@mercoa/react';
import BillsChart from './bills-chart';
import BillsToPay from './bills-to-pay';
import { useBillPayStyles } from 'areas/billing/billing.styles';
import { filterBills, getBillsDates, mappedBillsDates } from './utils';

const BillsMetrics = () => (
  <InvoiceMetrics
    returnByDate="DUE_DATE"
    statuses={['APPROVED', 'SCHEDULED', 'PENDING', 'DRAFT', 'NEW']}
  >
    {({ metrics }) => {
      const { classes } = useBillPayStyles();

      if (!metrics) {
        return <div />;
      }

      /**
       * Dates are returned as an object, so we need to convert this on an Array and then we can filter as we needed
       *
       * returned data:
       * 'APPROVED': {
       *  averageAmount: 8010,
       *  currency: 'USD',
       *  dates: {
       *    '2024-07-17': {
       *      averageAmount: 8010,
       *      currency: 'USD',
       *      date: Tue Jul 16 2024 19:00:00 GMT-0500 (Colombia Standard Time),
       *      totalAmount: 8010,
       *      totalCount: 1,
       *    },
       *  totalAmount: 8010,
       *  totalCount: 1,
       *  },
       * 'SCHEDULED': {...}
       */
      const bills = Object.entries(metrics).map(([status, data]) => ({
        status,
        ...data,
      }));

      const billsToPay = getBillsDates(
        bills.filter((bill) => ['APPROVED', 'SCHEDULED'].includes(bill.status)),
      );

      const upcomingBills = getBillsDates(bills);

      return (
        <Grid grow mx={0}>
          <Grid.Col xs={12} md={5} mr="md" my="lg" className={classes.card}>
            <BillsToPay bills={mappedBillsDates(billsToPay)} />
          </Grid.Col>
          <Grid.Col xs={12} md={5} my="lg" className={classes.card}>
            <BillsChart
              bills={filterBills(
                mappedBillsDates(upcomingBills),
                'dueInTheNext30Days',
              )}
            />
          </Grid.Col>
        </Grid>
      );
    }}
  </InvoiceMetrics>
);

export default BillsMetrics;
