import { forwardRef, useMemo, useRef, useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { Box, Select, SelectItemProps, SelectProps } from '@mantine/core';

type Props = SelectProps &
  React.RefAttributes<HTMLInputElement> & {
    keepDropdownOpenOnSelect?: boolean;
  };

const FlexbaseSelect = ({
  classNames,
  keepDropdownOpenOnSelect,
  ...props
}: Props) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const onChangeRef = useRef(props.onChange);
  onChangeRef.current = props.onChange;

  /**
   * As of our current Mantine version (6.0.21), item selection uses the onMouseDown handler under the hood.
   * onMouseDown handles closing the dropdown and propagating the value up to the consumer's onChange handler
   *
   * To prevent the dropdown from closing, we provide a custom itemComponent and override the onMouseDown handler and call onChange manually
   */
  const ItemPersistDropdown = useMemo(
    () =>
      forwardRef<HTMLDivElement, SelectItemProps>(function f(
        { label, value, ...rest },
        _ref,
      ) {
        const handleMouseDown = () => {
          onChangeRef.current?.(typeof value === 'undefined' ? null : value);
        };

        // Check if a different custom component was provided, but still override onMouseDown
        const Item = props.itemComponent;

        return Item ? (
          <Item
            label={label}
            value={value}
            {...rest}
            onMouseDown={handleMouseDown}
          />
        ) : (
          <Box
            sx={(theme) => ({
              fontSize: theme.fontSizes.sm,
              paddingLeft: theme.spacing.sm,
              paddingRight: theme.spacing.sm,
              paddingTop: theme.spacing.xs,
              paddingBottom: theme.spacing.xs,

              ...theme.fn.hover({
                cursor: 'pointer',
                backgroundColor: theme.fn.themeColor(
                  'primarySecondarySuccess',
                  0,
                ),
              }),
            })}
            {...rest}
            onMouseDown={handleMouseDown}
          >
            {label}
          </Box>
        );
      }),
    [props.itemComponent],
  );

  return (
    <Select
      {...props}
      classNames={{ ...classNames }}
      rightSection={
        props.rightSection !== false ? (
          menuOpen ? (
            <BsChevronUp />
          ) : (
            <BsChevronDown />
          )
        ) : (
          false
        )
      }
      onDropdownOpen={() => setMenuOpen(true)}
      onDropdownClose={() => setMenuOpen(false)}
      itemComponent={keepDropdownOpenOnSelect ? ItemPersistDropdown : undefined}
    />
  );
};

export default FlexbaseSelect;
