import FlexIconLink from '@common/icons/flex-icon-link';
import { CloseIcon } from '@flexbase-eng/web-components';
import { Box, Group, UnstyledButton } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { ReactNode } from 'react';
import { useKeyPress } from 'utilities/custom-hooks';
import { useStyles } from './styles';
import UploadBill from './upload-document/upload-bill';
import { MercoaSession } from '@mercoa/react';
import { InvoiceResponse } from '@mercoa/javascript/api';

type Props = {
  content: ReactNode;
  closeButton: ReactNode;
  onClose: () => void;
};

const ConditionalWrapper = ({ content, closeButton, onClose }: Props) => {
  const { classes, theme } = useStyles();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  useKeyPress('Escape', onClose);

  if (useMobileView) {
    return (
      <Box w="100%" p={'1rem'}>
        <Group className={classes.containerMobile}>
          <FlexIconLink width={90} />
          {closeButton}
        </Group>
        <Box className={classes.boxContainer}>{content}</Box>
      </Box>
    );
  } else {
    return (
      <Box w="100%">
        <Group style={{ justifyContent: 'space-between' }}>
          <FlexIconLink width={90} />
          <Box>{closeButton}</Box>
        </Group>
        <Box className={classes.boxContainer}>{content}</Box>
      </Box>
    );
  }
};

type AddBillProps = {
  mercoaToken: string;
  closeModal: () => void;
  invoice?: InvoiceResponse;
};

const AddBill = ({ mercoaToken, invoice, closeModal }: AddBillProps) => {
  const { classes } = useStyles();

  return (
    <MercoaSession
      token={mercoaToken}
      googleMapsApiKey={import.meta.env.VITE_APP_GOOGLE_API_KEY}
    >
      <Group className={classes.container} position="apart">
        <ConditionalWrapper
          onClose={closeModal}
          content={<UploadBill invoice={invoice} closeModal={closeModal} />}
          closeButton={
            <UnstyledButton
              onClick={closeModal}
              className={classes.closeButton}
            >
              <CloseIcon />
            </UnstyledButton>
          }
        />
      </Group>
    </MercoaSession>
  );
};

export default AddBill;
