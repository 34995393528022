import { EntityInboxEmail } from '@mercoa/react';
import { Box, Button, Flex, Text } from '@mantine/core';
import AddBill from '../add-bill/add-bill';
import useModal from '@common/modal/modal-hook';
import { useBillPayStyles } from '../../billing.styles';
import { EmptyBillTableIcon, PlusSignIcon } from 'assets/svg';

type Props = {
  mercoaToken: string;
  headingText: string;
  withActions?: boolean;
  actionButtonText?: string;
};

const EmptyBillTable = ({
  mercoaToken,
  headingText,
  actionButtonText,
  withActions = true,
}: Props) => {
  const { classes } = useBillPayStyles();
  const { openTransparentModal, closeAllModals } = useModal();

  return (
    <Flex justify="center" direction="column" align="center" p="xl">
      <EmptyBillTableIcon />
      <Text c="primarySecondarySuccess.8" fw={500} mt="xl">
        {headingText}
      </Text>
      {withActions && (
        <>
          <Text c="primarySecondarySuccess.8" size="sm">
            Add a bill or have them sent to your Flex account by forwarding them
            to the email below.
          </Text>
          <Box className={classes.emailContainerEmptyState} mt="xs">
            <EntityInboxEmail layout="left" />
          </Box>
          <Button
            mt="xl"
            color="primarySecondarySuccess.6"
            onClick={() =>
              openTransparentModal(
                <AddBill
                  closeModal={closeAllModals}
                  mercoaToken={mercoaToken}
                />,
              )
            }
            leftIcon={<PlusSignIcon width={10} />}
          >
            {actionButtonText ?? `Add Bill`}
          </Button>
        </>
      )}
    </Flex>
  );
};

export default EmptyBillTable;
