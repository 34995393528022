import { useRecoilState, useRecoilValue } from 'recoil';
import { Stack, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import flexbaseClient, {
  flexbaseOnboardingClient,
} from 'services/flexbase-client';
import ParentCenteredLoader from 'components/loading/parent-centered-loader';
import { useConfirmAndReviewStyles } from './review-and-pay.styles';
import CardPaymentAmount from './card-payment-amount';
import CardPaymentReview from './card-payment-review';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { Analytics } from 'services/analytics/analytics';
import { ApplicationState } from 'states/application/product-onboarding';
import {
  depositAccountsQuery,
  paymentAccountQuery,
} from 'areas/payments/components/send-payment/payment.states';
import type {
  DepositAccount,
  PlaidAccount,
} from 'areas/banking/move-funds/move-funds.model';
import { useExternalAccounts } from '@utilities/custom-hooks/use-external-accounts';
import { AddAccount } from './add-account';

type Props = {
  closeModal: () => void;
  onSuccess: (
    paymentId: string,
    paymentAmount: number,
    paymentAccount: DepositAccount | PlaidAccount,
  ) => void;
  onError: () => void;
};

const ReviewAndPay = ({ closeModal, onSuccess, onError }: Props) => {
  const { company } = useRecoilValue(ApplicationState);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [minimumDue, setMinimumDue] = useState(0);
  const [reviewing, setReviewing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [delinquentAmount, setDelinquentAmount] = useState(0);
  const [frozen, setFrozen] = useState(false);
  const [interestDue, setInterestDue] = useState(0);
  const [maxPayment, setMaxPayment] = useState(0);
  const [minPayment, setMinPayment] = useState(0);
  const [comeCurrentPayment, setComeCurrentPayment] = useState(0);
  const [token, setToken] = useState<string | null>(null);
  const depositAccounts = useRecoilValue(depositAccountsQuery);
  const { data: plaidAccounts, refetch } = useExternalAccounts();
  const [paymentAccount, setPaymentAccount] =
    useRecoilState(paymentAccountQuery);

  const getBalance = async () => {
    setLoading(true);
    const balance = await flexbaseOnboardingClient.getMinimumDue(company.id);

    if (balance.success) {
      setCurrentBalance(balance.currentBalance);

      const interest = balance.interestDue ?? 0;
      const isDelinquent = balance.frozen;
      if (isDelinquent) {
        setFrozen(true);
      }
      const comeCurrentAmount = balance.comeCurrentPayment ?? 0;
      setPaymentAmount(
        isDelinquent ? balance.maximumAllowedPayment : balance.currentBalance,
      );

      setMinimumDue(balance.minimumDue > 0 ? balance.minimumDue : 0);
      setDelinquentAmount(balance.delinquentAmount ?? 0);
      setInterestDue(interest);
      setMaxPayment(balance.maximumAllowedPayment ?? 0);
      setMinPayment(
        isDelinquent ? interest : balance.minimumAllowedPayment ?? 0,
      );
      setComeCurrentPayment(isDelinquent ? comeCurrentAmount : 0);
    } else {
      onError();
    }

    setLoading(false);
  };

  const onConfirmClick = async () => {
    setLoading(true);

    try {
      const method =
        paymentAccount?.plaidOrDeposit === 'plaid'
          ? 'makePaymentPlaid'
          : 'makePaymentUnit';

      const paymentResult = await flexbaseOnboardingClient[method](
        paymentAmount,
        // SAFETY: paymentAccount *must* exist by this point
        // (see the assertion in the "stack" variable below)
        paymentAccount!.id,
      );
      if (
        paymentResult.success &&
        paymentResult.cardPayment.status !== 'failed'
      ) {
        Analytics.track('Credit Payment Submitted', { paymentAmount });
        onSuccess(paymentResult.cardPayment.id, paymentAmount, paymentAccount!);
      } else {
        onError();
      }
    } catch (e) {
      onError();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBalance();
  }, []);

  useEffect(() => {
    const unlinked = plaidAccounts.some(
      (account) => account.active && account.unlinked,
    );
    if (!loading && unlinked && !token) {
      flexbaseClient
        .getPlaidLinkToken()
        .then((plaidLinkToken) => setToken(plaidLinkToken));
    }
  }, [loading, plaidAccounts]);

  const { classes } = useConfirmAndReviewStyles();

  const paymentAccounts = [
    ...depositAccounts.filter((a) => a.status === 'Open'),
    ...plaidAccounts.reduce((accounts, account) => {
      if (
        !account.unlinked &&
        !accounts.some(({ last4 }) => account.last4 === last4)
      ) {
        accounts.push(account);
      }
      return accounts;
    }, [] as PlaidAccount[]),
  ];

  const handleAddAccountSuccess = (accounts: PlaidAccount[]) => {
    refetch();
    setPaymentAccount(accounts[0]); // Famous last words but this should always have at least one entry, otherwise the fn is never called
  };

  const stack = paymentAccount ? (
    <>
      <Text className={classes.title}>
        {frozen ? (
          <>
            Pay now to unfreeze <br /> your account
          </>
        ) : (
          'Make a credit payment'
        )}
      </Text>
      {reviewing ? (
        <CardPaymentReview
          paymentAccount={paymentAccount}
          paymentAmount={formatCurrency(paymentAmount)}
          onConfirmClick={onConfirmClick}
          onGoBackClick={() => setReviewing(false)}
        />
      ) : (
        <CardPaymentAmount
          delinquentAmount={delinquentAmount}
          frozen={frozen}
          interestDue={interestDue}
          paymentAmount={paymentAmount}
          currentBalance={currentBalance}
          minimumDue={minimumDue}
          maxPaymentAmount={maxPayment}
          minPaymentAmount={minPayment}
          comeCurrentAmount={comeCurrentPayment}
          closeModal={closeModal}
          onReviewClick={() => setReviewing(true)}
          paymentAccounts={paymentAccounts}
          currentAccount={paymentAccount}
          onPaymentAmountChange={setPaymentAmount}
          onPaymentAccountChange={setPaymentAccount}
        />
      )}
    </>
  ) : (
    <AddAccount
      onPlaidAccountLinked={handleAddAccountSuccess}
      setLoading={setLoading}
    />
  );

  return (
    <>
      <ParentCenteredLoader visible={loading} />
      {!loading && <Stack>{stack}</Stack>}
    </>
  );
};

export default ReviewAndPay;
