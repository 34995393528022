import { Button, ButtonProps, createStyles } from '@mantine/core';
import { ButtonHTMLAttributes } from 'react';

type DisconnectButtonProps = ButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement>;

export const DisconnectButton = ({
  children,
  ...props
}: DisconnectButtonProps) => {
  const { classes } = useStyles();

  return (
    <Button {...props} variant="subtle" className={classes.disconnect}>
      {children}
    </Button>
  );
};

const useStyles = createStyles((theme) => ({
  disconnect: {
    color: theme.fn.themeColor('critical.6'),
  },
}));
