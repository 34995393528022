import { ReactNode } from 'react';
import { Badge, BadgeProps, createStyles } from '@mantine/core';

type Props = {
  children: ReactNode;
  variant?: 'default' | 'warning' | 'success' | 'error' | 'secondary' | string;
} & Omit<BadgeProps, 'variant'>;

export const FilledBadge = ({
  children,
  variant = 'default',
  classNames,
  ...badgeProps
}: Props) => {
  const { classes, cx } = useStyles();
  return (
    <Badge
      classNames={{
        ...classNames,
        root: cx(
          variant === 'success' && classes.successRoot,
          variant === 'warning' && classes.warningRoot,
          variant === 'secondary' && classes.secondaryRoot,
          variant === 'error' && classes.errorRoot,
          classNames?.root,
        ),
        inner: cx(
          variant === 'success' && classes.successInner,
          variant === 'warning' && classes.warningInner,
          variant === 'secondary' && classes.secondaryInner,
          variant === 'error' && classes.errorInner,
          classNames?.inner,
        ),
      }}
      {...badgeProps}
    >
      {children}
    </Badge>
  );
};

const useStyles = createStyles((theme) => ({
  root: {
    borderRadius: 4,
    backgroundColor: theme.fn.themeColor('neutral', 1),
  },
  inner: {
    color: theme.fn.themeColor('neutral', 7),
    fontWeight: 400,
    fontSize: 12,
    textTransform: 'none',
  },
  successRoot: {
    backgroundColor: theme.fn.themeColor('primarySecondarySuccess', 0),
  },
  successInner: {
    color: theme.fn.themeColor('primarySecondarySuccess', 6),
  },
  warningRoot: {
    backgroundColor: theme.fn.themeColor('tertiary', 0),
  },
  warningInner: {
    color: theme.fn.themeColor('tertiary', 6),
  },
  errorRoot: { backgroundColor: theme.fn.themeColor('critical', 0) },
  errorInner: { color: theme.fn.themeColor('critical', 6) },
  secondaryRoot: {
    backgroundColor: theme.fn.themeColor('promote', 1),
  },
  secondaryInner: {
    color: theme.fn.themeColor('promote', 7),
  },
}));
