import {
  AmountFilter,
  filterDirectionToLabel,
} from '@common/filter/amount-filter';
import { formatCurrency } from '@flexbase-eng/web-components';
import { useBankingAccountsFilters } from '../use-banking-filters';

export const BankingAccountsBalanceFilter = () => {
  const { addFilter, getFilterByKey } = useBankingAccountsFilters();
  return (
    <AmountFilter
      value={
        getFilterByKey('balance')?.filterValue || {
          amount: '',
          direction: 'equal',
        }
      }
      onChange={(val) =>
        addFilter('balance', {
          label: `Balance - ${filterDirectionToLabel(
            val.direction,
          )} ${formatCurrency(val.amount)}`,
          key: 'balance',
          fn: (row) => {
            const balanceNum = Math.abs(+row.balance);
            const filterNum = +val.amount;
            switch (val.direction) {
              case 'greaterThan':
                return balanceNum > filterNum;
              case 'lessThan':
                return balanceNum < filterNum;
              case 'equal':
              default:
                return balanceNum === filterNum;
            }
          },
          filterValue: val,
          showChip: true,
        })
      }
    />
  );
};
