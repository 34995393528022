import { ReactNode } from 'react';
import { Box, BoxProps, Text } from '@mantine/core';
import { useSalesStyles } from '@common/sales/styles';

type Props = {
  flavorText: string;
  title: string;
  children: ReactNode;
} & BoxProps;

export const SalesBox = ({
  flavorText,
  title,
  children,
  ...boxProps
}: Props) => {
  const { classes } = useSalesStyles();
  return (
    <Box className={classes.noAccountSalesBox} {...boxProps}>
      <div className={classes.flavorTextContainer}>
        <div className={classes.flavorTextBullet} />
        <Text fz={12}>{flavorText}</Text>
      </div>
      <Text fz={32} ff="Redaction" lh={1} ta="center" mt="md">
        {title}
      </Text>
      {children}
    </Box>
  );
};
