import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ga4Helpers } from './helpers';
import { useRouteSectionContext } from 'components/routes/route-context';

const useGA4 = () => {
  const location = useLocation();
  const { referrer, setReferrer } = useRouteSectionContext();
  const route = window.location.pathname;
  useEffect(() => {
    if (referrer !== route) {
      trackGA4PageView(route);
    }
    // Update the referrer state
    setReferrer(route);
  }, [location]);

  function trackGA4PageView(givenRoute: string) {
    ga4Helpers.pageView(givenRoute);
  }
};

export default useGA4;
