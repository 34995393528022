import { useState } from 'react';
import { rem } from '@mantine/core';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';

import {
  BankingOnlyModalDismissedState,
  CompanySelector,
  ProductOnboardingBtnLoaderState,
} from 'states/application/product-onboarding';
import useModal from 'components/modal/modal-hook';
import { useLogout } from 'services/logout/logout-hook';
import BankingModal from './components/offer-banking-modal';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { without } from 'underscore';
import { ProductApplicationRoutes } from './onboarding.constants';
import { useRouteSectionContext } from '@common/routes/route-context';

const useShowBankingModal = () => {
  const logout = useLogout();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const { openSizedCenterModal, closeAllModals } = useModal();
  const setLoading = useSetRecoilState(ProductOnboardingBtnLoaderState);
  const setContinueWithBankingOnly = useSetRecoilState(
    BankingOnlyModalDismissedState,
  );
  const { optedProducts } = useRecoilValue(CompanySelector);
  const { setSectionAndNavigate } = useRouteSectionContext();

  const applyForBanking = async (
    _navigateToNextStep: (
      isTransientStep?: boolean,
      iscontinueWithBankingOnly?: boolean,
    ) => void,
  ) => {
    setLoading(true);
    try {
      setContinueWithBankingOnly(true);
      setSectionAndNavigate(
        'application',
        ProductApplicationRoutes.BANKING_ONLY,
      );
      // navigateToNextStep(false, true);
      closeAllModals();
    } catch (err) {
      console.error('Error creating banking application', err);
      setError('Something went wrong. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const cancelApplication = async () => {
    try {
      await flexbaseOnboardingClient.updateCompany({
        // removing 'BANKING' from optedProducts and with appStatus.reason:'unqualified',
        // we can check that the user canceled the application (for now)
        optedProducts: without(optedProducts, 'BANKING'),
      });
      showNotification({
        message: 'The application was canceled',
        color: 'flexbase-teal',
      });
      await logout({
        onLogout: () => navigate('/register'),
      });
      closeAllModals();
    } catch (err) {
      console.error('Error canceling application', err);
    }
  };

  return (
    unqualifiedReason: 'unserved-state' | 'sole-prop',
    navigateToNextStep: (
      isTransientStep?: boolean,
      iscontinueWithBankingOnly?: boolean,
    ) => void,
  ) => {
    openSizedCenterModal(
      <BankingModal
        errorMessage={error}
        reason={unqualifiedReason}
        onApplyForBanking={() => applyForBanking(navigateToNextStep)}
        onCancelApplication={cancelApplication}
      />,
      {
        margin: '0px',
        width: rem(500),
      },
    );
  };
};

export default useShowBankingModal;
