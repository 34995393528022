import { useGetBeneficiaries } from '@queries/use-beneficiaries';
import { Counterparty } from '@services/flexbase/banking.model';
import { isInternationalPayMethod } from 'areas/payments/components/send-payment/international-payments/util';
import { BeneficiaryResponse } from 'areas/payments/components/send-payment/international-payments/util/types';
import { PayMethod } from 'areas/payments/components/send-payment/payment.states';
import { ParsedAccount } from 'areas/payments/pages/recipient-details/types';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'states/application/product-onboarding';
import { Recipient } from 'states/recipient/recipient';

export type ParsedBeneficiary = {
  type: PayMethod;
  accountNumber: string;
  bank: string;
  nickName: string;
  recipientId: string;
} & BeneficiaryResponse;

export type BeneficiaryOrCounterparty = Counterparty | ParsedBeneficiary;
export type BeneficiaryOrRecipient = Recipient | ParsedBeneficiary;

export const isParsedBeneficiary = (
  item: ParsedAccount,
): item is ParsedBeneficiary => {
  return 'type' in item && isInternationalPayMethod(item.type);
};

export const parseBeneficiary = (
  b: BeneficiaryResponse,
): ParsedBeneficiary => ({
  type: b.currency === 'USD' ? 'internationalWireUSD' : 'internationalWire',
  accountNumber: b.ccResponse.accountNumber,
  bank: b.ccResponse.bankName,
  ...b,
});

export const useBeneficiaries = () => {
  const parseBeneficiaries = (
    beneficiaries: BeneficiaryResponse[],
  ): ParsedBeneficiary[] => {
    return beneficiaries.map(parseBeneficiary);
  };

  const approved = 'approved';
  const { company, productStatus } = useRecoilValue(ApplicationState);
  const isApprovedForInternationalWiresProduct =
    !!company.id &&
    productStatus.internationalPayments.appStatus === approved &&
    productStatus.internationalPayments.status === approved;

  const { data, isLoading, error, refetch } = useGetBeneficiaries(
    {
      companyId: company.id,
      status: 'Approved',
    },
    isApprovedForInternationalWiresProduct,
  );

  return {
    data: data ? parseBeneficiaries(data.beneficiaries) : undefined,
    isLoading,
    error,
    refetch,
  };
};
