import ModalCenter from '@common/modal/modal-center';
import ModalRight from '@common/modal/modal-right';
import { MantineProvider } from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { Fonts } from 'global';
import { PropsWithChildren } from 'react';
import { FlexbaseTheme } from 'theme';

/**
 * All our Mantine providers in one place.
 */
export const MantineProviders = ({ children }: PropsWithChildren) => {
  return (
    <MantineProvider theme={FlexbaseTheme} withGlobalStyles withNormalizeCSS>
      <DatesProvider settings={{ firstDayOfWeek: 0 }}>
        <ModalsProvider modals={{ center: ModalCenter, right: ModalRight }}>
          <Fonts />
          <Notifications position="bottom-left" />
          {children}
        </ModalsProvider>
      </DatesProvider>
    </MantineProvider>
  );
};
