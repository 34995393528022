import { createStyles } from '@mantine/core';

const ICON_SIZE = '32px';

export const useStyles = createStyles((theme) => ({
  baseContainer: {
    maxWidth: '1307.5px',
    margin: 'auto',
  },
  widgetContainer: {
    flexDirection: 'row',
    backgroundColor: theme.fn.themeColor('neutral', 0),
    padding: '1.5rem',
    '@media(max-width: 767px)': {
      width: '100%',
      padding: theme.spacing.md,
    },
  },
  storeName: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    wordWrap: 'normal',
    maxWidth: 220,
  },
  storeIcon: {
    fontSize: '20px',
    color: theme.fn.primaryColor(),
    '> div:first-of-type': {
      color: `${theme.fn.primaryColor()} !important`,
      backgroundColor: `${theme.colors.neutral[2]} !important`, // TODO change this shit
      fontWeight: 500,
      fontFamily: 'PP Neue Montreal',
    },
    marginRight: '10px',
    height: ICON_SIZE,
    width: ICON_SIZE,
  },
  storeLogo: {
    borderRadius: '34px',
    width: ICON_SIZE,
    height: ICON_SIZE,
    objectFit: 'contain',
    objectPosition: 'center',
    marginRight: '10px',
  },
  date: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#5F5F5F',
    lineHeight: '21px',
  },
  status: {
    padding: '4px 16px 4px 16px',
    background: '#EEEEF3',
    fontSize: '14px',
    fontWeight: 500,
    width: '105px',
    height: '32px',
    lineHeight: '24px',
    textAlign: 'center',
    borderRadius: '100px',
    color: 'black',
  },
  transactionRow: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing.xs,
    gap: theme.spacing.xs,
    width: '100%',
  },
  transactionYearRow: {
    backgroundColor: theme.fn.themeColor('neutral', 0),
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing.xs,
    gap: theme.spacing.xs,
    borderRadius: theme.defaultRadius,
    height: '76px',
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  },
  showMore: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 4px',
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    cursor: 'pointer',
    height: '50px',
  },
  closeIcon: {
    color: '#979797',
    cursor: 'pointer',
  },
}));
