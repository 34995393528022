import { useMediaQuery } from '@mantine/hooks';
import { Alert, Button, Image } from '@mantine/core';
import { CardByUser } from 'services/flexbase/banking.model';
import GetPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import { RedAlertIcon } from 'assets/svg';
import { useVGScollect } from '@utilities/custom-hooks/vgs-collect';
import VGSerror from 'areas/banking/components/vgs-error/vgs-error';

type Props = {
  card: CardByUser;
  loading: boolean;
  errorMessage: string;
  onNextClick: (f: any) => void;
};

const InputDebitCardData = ({
  card,
  loading,
  errorMessage,
  onNextClick,
}: Props) => {
  const useMobileView = useMediaQuery('(max-width: 767px)');

  const { form, errors, validateForm, errorOnInitForm } =
    useVGScollect('activateCard');
  const handleSubmit = () => {
    const hasErrors = validateForm();
    if (!hasErrors) {
      onNextClick(form);
    }
  };

  return (
    <div className="input-data">
      <span className="title">
        Activate debit card {GetPaddedAccountMask(card.cardNumber, 3)}
      </span>
      {errorOnInitForm ? (
        <VGSerror my={20} withCloseButton={false} />
      ) : (
        <>
          <p>You’ll find this data on the back of the card.</p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="group">
              <label>
                <span>Expiration date</span>
                <div id="cc-expiration-date" className="field" />
              </label>
              <p>{errors.validationDateMessage}</p>
              <label>
                <span>CVV</span>
                <div id="cc-cvv2" className="field" />
              </label>
              <p>{errors.validationCvvMessage}</p>
            </div>
          </div>
        </>
      )}

      <div className="image-container">
        <Image
          width={useMobileView ? 300 : 350}
          src="/images/back-debit-card.png"
        />
      </div>

      {errorMessage && errorMessage !== '' && (
        <Alert mt={20} icon={<RedAlertIcon />} color="red">
          {errorMessage}
        </Alert>
      )}

      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          mt="lg"
          type="submit"
          {...{ loading }}
          variant="light"
          onClick={handleSubmit}
        >
          Activate card
        </Button>
      </div>
    </div>
  );
};

export default InputDebitCardData;
