import {
  IntlPaymentStatus,
  MoneyMovementStatus,
} from '@services/flexbase/banking.model';

export const formatStatus = (
  status: MoneyMovementStatus | IntlPaymentStatus,
): string => {
  const paymentStatus = status.toLowerCase();

  switch (paymentStatus) {
    case 'queued':
      return 'Queued';
    case 'pending':
    case 'pendingreview':
    case 'ready_to_send':
      return 'Pending';
    case 'clearing':
      return 'Clearing';
    case 'canceled':
      return 'Canceled';
    case 'sent':
    case 'completed':
      return 'Completed';
    case 'awaitingapproval':
      return 'Approval Needed';
    case 'awaitingconfirmation':
      return '2FA Required';
    case 'scheduled':
      return 'Scheduled';
    case 'rejected':
    case 'failed':
      return 'Failed';
    case 'returned':
      return 'Returned';
    default:
      return 'Initiated';
  }
};

// requested that wire confirmation pdfs have amended wording for Pending to be Created
export const formatStatusPDF = (
  status: MoneyMovementStatus | IntlPaymentStatus,
): string => {
  const paymentStatus = status.toLowerCase();

  switch (paymentStatus) {
    case 'queued':
      return 'Queued';
    case 'pending':
    case 'pendingreview':
    case 'ready_to_send':
      return 'Created';
    case 'clearing':
      return 'Clearing';
    case 'canceled':
      return 'Canceled';
    case 'sent':
    case 'completed':
      return 'Completed';
    case 'awaitingapproval':
      return 'Approval Needed';
    case 'awaitingconfirmation':
      return '2FA Required';
    case 'scheduled':
      return 'Scheduled';
    case 'rejected':
    case 'failed':
      return 'Failed';
    case 'returned':
      return 'Returned';
    default:
      return 'Initiated';
  }
};
