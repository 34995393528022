import { Box, rem, Text } from '@mantine/core';
import { getTextWidth } from '@utilities/text/get-text-width';

type Props = { firstName: string; lastName: string };
/**
 * This component shows a credit card with the user's first name and last name on it.
 * Text automatically resizes to fit without breaking the frame.
 *
 * @param firstName
 * @param lastName
 * @constructor
 */
export const CreditCardGraphic = ({ firstName, lastName }: Props) => {
  let cardNameTextSize = 32;

  while (
    getTextWidth(firstName, `400 ${rem(cardNameTextSize)} redaction`) > 314 ||
    getTextWidth(lastName, `400 ${rem(cardNameTextSize)} redaction`) > 314
  ) {
    cardNameTextSize--;
  }

  return (
    <Box
      w={346.399}
      h={218.874}
      sx={{
        backgroundImage: 'url(/svg/credit-card-blank.svg)',
      }}
      px="md"
      py="xxl"
    >
      <Text
        ff="Redaction"
        fz={cardNameTextSize}
        lts={-0.328}
        lh="90%"
        c="#ECECEC"
      >
        {firstName}
        <div>{lastName}</div>
      </Text>
    </Box>
  );
};
