import {
  SpendPlanAdminView,
  SpendPlanView,
} from '@flexbase-eng/types/dist/accounting';

export function isSpendPlanAdminView(
  plan?: SpendPlanAdminView | SpendPlanView,
): plan is SpendPlanAdminView {
  return !!plan && 'managers' in plan;
}
