import Select from 'components/select/flexbase-select';

type Props = {
  yearSelected: string;
  listOfYears: string[];
  onYearChange: (year: string) => void;
};

const StatementsYearSelection = ({
  listOfYears,
  yearSelected,
  onYearChange,
}: Props) => (
  <Select
    data={listOfYears}
    value={yearSelected}
    onChange={onYearChange}
    placeholder="Filter by year"
  />
);

export default StatementsYearSelection;
