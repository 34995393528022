import { useState } from 'react';
import { showNotification } from '@mantine/notifications';
import {
  Box,
  rem,
  Text,
  Alert,
  Group,
  Button,
  createStyles,
} from '@mantine/core';
import { RedAlertIcon } from 'assets/svg';
import { useVGScollect } from '@utilities/custom-hooks/vgs-collect';
import VGSerror from 'areas/banking/components/vgs-error/vgs-error';

type PINprops = {
  bearerToken: string;
  unitcoCardId: string;
  onCancel: () => void;
  onPINupdated: () => void;
};

const UpdatePIN = ({
  bearerToken,
  unitcoCardId,
  onCancel,
  onPINupdated,
}: PINprops) => {
  const { classes } = useUpdatePINstyles();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { form, errors, validateForm, errorOnInitForm } =
    useVGScollect('updatePIN');

  const setPin = async () => {
    const hasErrors = validateForm();
    if (!hasErrors && form) {
      setIsLoading(true);
      form.submit(
        `/cards/${unitcoCardId}/secure-data/pin`,
        {
          // This converts the dot-separated field name strings into a JSON object
          mapDotToObject: 'true',
          method: 'PUT',
          headers: {
            'Content-Type': 'application/vnd.api+json',
            Authorization: `Bearer ${bearerToken}`,
          },
        },
        (status, data) => {
          setIsLoading(false);
          if (status === 200) {
            onPINupdated();
            showNotification({
              color: 'flexbase-teal',
              message: 'Your PIN has been changed!',
            });
          } else {
            setErrorMessage(
              data.errors[0].title ?? 'Something went wrong. Try again later.',
            );
          }
        },
      );
    }
  };

  if (errorOnInitForm) {
    return <VGSerror my={20} mx={50} withCloseButton={false} />;
  }

  return (
    <Box className={classes.pinContainer}>
      <Text mb="sm" size="sm">
        Set your new PIN
      </Text>
      <Text size="sm">Old PIN</Text>
      <div id="cc-old-pin" className={classes.frameInput} />
      {errors.validationOldPinMessage && (
        <Text size="xs" color="critical.4">
          {errors.validationOldPinMessage}
        </Text>
      )}

      <Text size="sm" mt="md">
        New PIN
      </Text>
      <div id="cc-new-pin" className={classes.frameInput} />

      {errors.validationNewPinMessage && (
        <Text size="xs" color="critical.4">
          {errors.validationNewPinMessage}
        </Text>
      )}

      {errorMessage && (
        <Alert
          mt={20}
          icon={<RedAlertIcon />}
          color="red"
          withCloseButton={false}
        >
          {errorMessage}
        </Alert>
      )}
      <Group mt="lg" position="right">
        <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="light"
          type="submit"
          loading={isLoading}
          onClick={setPin}
        >
          Save
        </Button>
      </Group>
    </Box>
  );
};

export default UpdatePIN;

export const useUpdatePINstyles = createStyles((theme) => ({
  pinContainer: {
    margin: `${rem(20)} ${rem(50)}`,
    iframe: {
      width: '100%',
      height: '100%',
    },
    '@media(max-width: 767px)': {
      marginLeft: rem(20),
      marginRight: rem(20),
    },
  },
  frameInput: {
    height: rem(40),
    fontWeight: 500,
    fontSize: rem(14),
    lineHeight: rem(20),
    paddingLeft: theme.spacing.xs,
    borderRadius: theme.defaultRadius,
    background: theme.fn.themeColor('neutral', 0),
    border: `1px solid ${theme.fn.themeColor('flexbaseGrey', 1)}`,
  },
}));
