import { DateTime, Interval } from 'luxon';
import { DateRangeFilter } from '@common/filter/date-range.filter';
import { useCheckDepositsFilters } from './use-check-deposits-filters';

export const CheckDepositsDateRangeFilter = () => {
  const paymentFilters = useCheckDepositsFilters();

  return (
    <DateRangeFilter
      filterHook={paymentFilters}
      filterFn={(startDate, endDate, item) => {
        const dateRange = Interval.fromDateTimes(startDate, endDate);
        return dateRange.contains(DateTime.fromSQL(item.createdAt));
      }}
    />
  );
};
