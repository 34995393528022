const BASE_ROUTE = '/application';

export const ProductApplicationRoutes = {
  CREDIT_ONLY: `${BASE_ROUTE}/credit`,
  BANKING_ONLY: `${BASE_ROUTE}/banking`,
  INTL_PAYMENTS_ONLY: `${BASE_ROUTE}/international-payments`,
  COMBINED_CREDIT_BANKING: `${BASE_ROUTE}`,
};

export const PLATFORM_CLIENT_ID = 'ff45466a-5c46-42a8-905c-96a403563b43';

export enum ProductOnboardingRoutes {
  CHANGE_PASSWORD = 'change-password',
  MULTI_PRODUCT_SELECT = 'product-select',
  VERIFY_PHONE = 'verify-phone',
  PERSONAL_ADDRESS = 'user-address',
  VERIFY_IDENTITY = 'verify-identity',
  BUSINESS_TYPE = 'business-type',
  BUSINESS_ACTIVITY = 'business-activity',
  VERIFY_BUSINESS = 'verify-business',
  BENEFICIAL_OWNERS = 'business-owners',
  CONTROL_PERSON = 'control-person',
  VERIFY_BANK = 'verify-bank',
  SUMMARY = 'summary',
  END = 'end',
  TREASURY_CASH = 'treasury-info',
  DOC_UPLOAD = 'doc-upload',
}
