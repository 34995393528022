import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import TransactionDetailRow from './transaction-detail-row';
import { CloseIcon } from 'assets/svg';
import { useStyles } from '../credit-transactions.styles';
import CreditTransactionHeaderSm from '../credit-transactions-header/credit-transaction-header-sm';
import {
  CreditTransactionsTableRow,
  formatTableDataForCsv,
} from '../credit-transactions-table-helpers';
import { downloadCSV } from '@utilities/file-io/csv/csv-export';
import { FlexbaseTable } from '@common/table';
import { TableColumn } from 'react-data-table-component';
import { Space } from '@mantine/core';
import { useSyncedTransactionsQuery } from '@queries/use-integrations';
import { mapTransactionStatusToBusinessStatus } from '@services/flexbase/flexbase-onboarding-client';
import { useSyncTransactionExpenses } from '../utils/use-sync-transaction-expenses';
import { useCreditTransactionsBulkActions } from '../utils/use-credit-transactions-bulk-actions';
import { useDisclosure } from '@mantine/hooks';
import {
  useActiveExpenseLink,
  useSyncExpenseAllowed,
} from '@utilities/integrations/accounting';

type Props = {
  year: number;
  transactions: CreditTransactionsTableRow[];
  onClose?: () => void;
  onRowClick: (row: CreditTransactionsTableRow) => void;
};

const TransactionsByYear = ({
  year,
  transactions,
  onClose,
  onRowClick,
}: Props) => {
  const { classes } = useStyles();
  const [filterText, setFilterText] = useState('');
  const [clearSelectedRowsFlag, { toggle: clearSelectedRows }] =
    useDisclosure();
  const isSyncExpenseAllowed = useSyncExpenseAllowed();
  const { expenseLink, connectionId = '' } = useActiveExpenseLink();

  const syncedTransactionsQueryIds = useMemo(
    () =>
      transactions
        .filter((tx) => mapTransactionStatusToBusinessStatus(tx) === 'Settled')
        .map((tx) => tx.id) ?? [],
    [transactions],
  );

  const { data: syncedTransactions, refetch: refetchSyncedTransactions } =
    useSyncedTransactionsQuery({
      enabled: isSyncExpenseAllowed && !!expenseLink?.enabledExpenses,
      connectionId,
      transactionIds: syncedTransactionsQueryIds,
    });

  const { syncTransactions, isPending: isSyncTransactionsPending } =
    useSyncTransactionExpenses({
      connectionId,
      syncedExpenses: syncedTransactions,
    });

  const handleSyncTransactions: (
    ...args: Parameters<typeof syncTransactions>
  ) => Promise<void> = async (transactionsToSync, options) => {
    await syncTransactions(transactionsToSync, options);
    await refetchSyncedTransactions();
  };

  const columns: TableColumn<CreditTransactionsTableRow>[] = [
    {
      compact: true,
      cell: (row) => (
        <TransactionDetailRow
          transaction={row}
          onClick={onRowClick}
          showBottomBorder={false}
        />
      ),
    },
  ];

  const { bulkActionsConfig } = useCreditTransactionsBulkActions({
    expenseLink,
    syncLoading: isSyncTransactionsPending,
    syncedExpenses: syncedTransactions,
    onBulkCategorizeConfirm: ({ transactionsToSync, options }) => {
      return handleSyncTransactions(transactionsToSync, options);
    },
    onDownloadCsvClick: (state) => {
      return handleDownloadCsvClick(state.selectedRows);
    },
  });

  const filteredTransactions = useMemo(() => {
    return transactions.filter((t) => {
      const transactionDate = DateTime.fromSQL(t.date);
      return (
        transactionDate.year === year &&
        t.toFrom.toLowerCase().includes(filterText.toLowerCase())
      );
    });
  }, [transactions, filterText]);

  useEffect(() => {
    clearSelectedRows();
  }, [filteredTransactions]);

  const handleDownloadCsvClick = (
    selectedRows?: CreditTransactionsTableRow[],
  ) => {
    const transactionsToDownload = selectedRows?.length
      ? selectedRows
      : filteredTransactions;
    const csvData = formatTableDataForCsv(transactionsToDownload);

    if (selectedRows?.length) {
      // selected rows comes out in filo order, so we need to reverse it
      csvData.reverse();
    }

    downloadCSV({
      data: csvData,
      fileName: `Transactions-${year}`,
    });
  };

  return (
    <div
      style={{
        padding: '16px',
        backgroundColor: '#FAFAFA',
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '32px',
        }}
      >
        <div className={classes.text} style={{ fontSize: '20px' }}>
          Transactions in {year}
        </div>
        {onClose && (
          <div className={classes.closeIcon} onClick={() => onClose()}>
            <CloseIcon />
          </div>
        )}
      </div>

      <CreditTransactionHeaderSm
        onFilter={(e) => setFilterText(e.target.value)}
        onDownloadCSVClick={() => handleDownloadCsvClick()}
        filterText={filterText}
      />

      <Space my={'xs'} />

      <FlexbaseTable
        data={filteredTransactions}
        columns={columns}
        bulkActionsConfig={bulkActionsConfig}
        selectableRows
        clearSelectedRows={clearSelectedRowsFlag}
      />
    </div>
  );
};

export default TransactionsByYear;
