import { List, Stack, Text } from '@mantine/core';

type Props = { errors: string[] };

export const PasswordError = ({ errors }: Props) => {
  return (
    <Stack spacing="xs">
      <Text fz={14}>
        <Text component="span" fw={600}>
          You must use a different password.
        </Text>{' '}
        We identified several issues with the password you attempted to use.
        Please read the following suggestions and adjust accordingly.
      </Text>
      <List fz={14}>
        {errors.map((e, i) => (
          <List.Item key={`error-${i}`}>{e}</List.Item>
        ))}
      </List>
    </Stack>
  );
};
