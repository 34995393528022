import { PropsWithChildren, useEffect } from 'react';
import { CreateSpendPlanContext } from './create-spend-plan.context';
import {
  DetailsStepFormInitialValues,
  useDetailsForm,
} from './steps/details/details-step.context';
import {
  SpendControlsFormInitialValues,
  useSpendControlsForm,
} from './steps/spend-controls/spend-controls-step.context';
import {
  TeamMembersFormInitialValues,
  useTeamMembersForm,
} from './steps/team-members/team-members-step.context';
import {
  RestrictionsStepFormInitialValues,
  useRestrictionsForm,
} from './steps/restrictions/restrictions-step.context';
import { useCreateSpendPlanWizard } from './create-spend-plan.wizard';
import { SpendPlanDetailsStep } from './steps/details/details-step';
import { SpendPlanSpendControlsStep } from './steps/spend-controls/spend-controls-step';
import { SpendPlansTeamMembersStep } from './steps/team-members/team-members-step';
import { SpendPlanRestrictionsStep } from './steps/restrictions/restrictions-step';
import { SpendPlanAdminView } from '@flexbase-eng/types/dist/accounting';
import { mapSpendPlanToFormState } from '../utils/map-spend-plan-to-form-state';

type CreateSpendPlanProviderProps = PropsWithChildren<{
  spendPlan?: SpendPlanAdminView;
}>;

export const CreateSpendPlanProvider = ({
  children,
  spendPlan,
}: CreateSpendPlanProviderProps) => {
  const { setState, onStepChange } = useCreateSpendPlanWizard();

  const initialFormState = spendPlan
    ? mapSpendPlanToFormState(spendPlan)
    : undefined;

  const detailsForm = useDetailsForm({
    initialValues: initialFormState ?? DetailsStepFormInitialValues,
  });
  const spendControlsForm = useSpendControlsForm({
    initialValues: initialFormState ?? SpendControlsFormInitialValues,
  });
  const teamMembersForm = useTeamMembersForm({
    initialValues: initialFormState ?? TeamMembersFormInitialValues,
  });
  const restrictionsForm = useRestrictionsForm({
    initialValues: initialFormState ?? RestrictionsStepFormInitialValues,
  });

  const validateAll = () => {
    detailsForm.validate();
    spendControlsForm.validate();
    teamMembersForm.validate();
    restrictionsForm.validate();
  };

  const isValidAll = () => {
    return (
      detailsForm.isValid() &&
      spendControlsForm.isValid() &&
      teamMembersForm.isValid() &&
      restrictionsForm.isValid()
    );
  };

  const getInvalidSteps = () => {
    const invalidSteps = [
      !detailsForm.isValid() ? SpendPlanDetailsStep.stepId : '',
      !spendControlsForm.isValid() ? SpendPlanSpendControlsStep.stepId : '',
      !teamMembersForm.isValid() ? SpendPlansTeamMembersStep.stepId : '',
      !restrictionsForm.isValid() ? SpendPlanRestrictionsStep.stepId : '',
    ].filter(Boolean);

    return invalidSteps;
  };

  onStepChange((fromStep) => {
    switch (fromStep.id) {
      case SpendPlanDetailsStep.stepId:
        detailsForm.validate();
        return detailsForm.isValid();
      case SpendPlanSpendControlsStep.stepId:
        spendControlsForm.validate();
        return spendControlsForm.isValid();
      case SpendPlansTeamMembersStep.stepId:
        teamMembersForm.validate();
        return teamMembersForm.isValid();
      case SpendPlanRestrictionsStep.stepId:
        restrictionsForm.validate();
        return restrictionsForm.isValid();
      default:
        return true;
    }
  });

  useEffect(() => {
    setState(detailsForm.values);
  }, [detailsForm.values]);

  useEffect(() => {
    setState(spendControlsForm.values);
  }, [spendControlsForm.values]);

  useEffect(() => {
    setState(teamMembersForm.values);
  }, [teamMembersForm.values]);

  useEffect(() => {
    setState(restrictionsForm.values);
  }, [restrictionsForm.values]);

  return (
    <CreateSpendPlanContext.Provider
      value={{
        detailsForm,
        spendControlsForm,
        teamMembersForm,
        restrictionsForm,
        validateAll,
        isValidAll,
        getInvalidSteps,
      }}
    >
      {children}
    </CreateSpendPlanContext.Provider>
  );
};
