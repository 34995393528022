import { Button, Flex, Image, Text, TextInput } from '@mantine/core';
import { DatePickerInput as _DatePickerInput } from '@mantine/dates';
import { Dropzone, FileWithPath, PDF_MIME_TYPE } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { useStyles } from '../styles';

// workaround for existing bug in @mantine v6: https://github.com/mantinedev/mantine/issues/5401
const DatePickerInput = _DatePickerInput as any;

const InvoiceUpload = ({
  onUpload,
}: {
  onUpload: (file: FileWithPath[]) => void;
}) => {
  const { classes } = useStyles();
  return (
    <Dropzone
      onDrop={onUpload}
      maxSize={3 * 1024 ** 2}
      accept={PDF_MIME_TYPE}
      my={30}
      classNames={{
        root: classes.dropZone,
      }}
    >
      <div className={classes.dropZonePlaceholderContainer}>
        <Image
          width={32}
          height={32}
          alt="upload an invoice"
          src="/images/upload-cloud.svg"
          className={classes.dropZonePlaceholderImage}
        />
        <Text className={classes.dropZonePlaceholder}>
          Drag and drop or browse to add an invoice.
        </Text>
      </div>
    </Dropzone>
  );
};

type FormValues = {
  invoiceNumber: string;
  invoiceDate: string;
  invoiceDueDate: string;
  invoiceFile: File | null;
};

const InvoiceForm = () => {
  const form = useForm<FormValues>({
    initialValues: {
      invoiceNumber: '',
      invoiceDate: '',
      invoiceDueDate: '',
      invoiceFile: null,
    },
    /*
    TODO: Add validation:
      // no invoice number duplicates
    */
  });

  const invoiceFields = [
    {
      label: 'Invoice Number',
      value: 'invoiceNumber',
      type: 'text',
    },
    {
      label: 'Invoice Date',
      value: 'invoiceDate',
      type: 'date',
    },
    {
      label: 'Due Date',
      value: 'invoiceDueDate',
      type: 'date',
    },
  ];

  const submitInvoice = async () => {
    try {
      showNotification({
        color: 'flexbase-teal',
        title: 'Success',
        message: 'Successfully added a new invoice!',
      });
    } catch (error) {
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'We were unable to add a new invoice at this time.',
      });
    }
  };

  const handleUpload = (file: FileWithPath[]) => {
    form.setFieldValue('invoiceFile', file[0]);
  };

  return (
    <form onSubmit={form.onSubmit(submitInvoice)}>
      {invoiceFields.map(({ label, value, type }) => {
        const commonProps = { mt: 30, label };
        if (type === 'date') {
          return (
            <DatePickerInput
              {...commonProps}
              key={value}
              placeholder="Choose Date"
              onChange={(val: Date) => {
                form.setFieldValue(value, val);
              }}
            />
          );
        } else if (type === 'text') {
          return (
            <TextInput
              {...commonProps}
              {...form.getInputProps(value)}
              key={value}
            />
          );
        }
      })}
      <InvoiceUpload onUpload={handleUpload} />
      <Flex justify="flex-end" gap={20}>
        <Button variant="light" type="submit">
          Submit
        </Button>
      </Flex>
    </form>
  );
};

export default InvoiceForm;
