import { Box, Button, Center, Loader, Text } from '@mantine/core';
import { useGetBeneficiaryRequirements } from '@queries/use-beneficiaries';
import { forwardRef } from 'react';
import BeneficiaryRequirementsForm from './beneficiary-requirements-form';
import {
  BeneficiaryRequirementsFormRef,
  InternationalRecipient,
} from './util/types';
import { BeneficiaryRequirements as BeneficiaryRequirementsType } from '@services/flexbase/banking.model';

const removeDuplicateFields = (fields?: BeneficiaryRequirementsType) => {
  if (!fields) return {};

  for (const field in fields) {
    if (field === 'country') {
      delete fields[field as keyof BeneficiaryRequirementsType];
    }
  }

  return fields;
};

type Props = {
  formValues: InternationalRecipient;
  newRecipient: InternationalRecipient;
};

const BeneficiaryRequirements = forwardRef<
  BeneficiaryRequirementsFormRef,
  Props
>(({ formValues, newRecipient }, ref) => {
  const { data, isLoading, isError, error, refetch } =
    useGetBeneficiaryRequirements({
      currency: formValues.currency,
      countryOfRecipientsBank: formValues.countryOfRecipientsBank,
      countryOfRecipient: formValues.countryOfRecipient,
      entityType: formValues.recipientType,
    });

  if (isLoading) {
    return (
      <Center my="1.5rem">
        <Loader variant="dots" color="primarySecondarySuccess.2" />
      </Center>
    );
  }

  if (isError) {
    const errorMessage: string = JSON.parse(error.message).error;

    return (
      <Center my="1.5rem">
        <Text>{errorMessage}</Text>
        {!errorMessage.includes('Failed to generate') && (
          <Button
            variant="subtle"
            compact
            onClick={() => {
              refetch();
            }}
          >
            Retry
          </Button>
        )}
      </Center>
    );
  }

  if (data?.fields) {
    return (
      <Box my="1rem">
        <BeneficiaryRequirementsForm
          fields={removeDuplicateFields(data.fields)}
          ref={ref}
          newRecipient={newRecipient}
          isIndividualRecipient={formValues.recipientType === 'individual'}
          recipientCountryCode={formValues.countryOfRecipient}
        />
      </Box>
    );
  }

  return false;
});

BeneficiaryRequirements.displayName = 'BeneficiaryRequirements';

export default BeneficiaryRequirements;
