import { atom } from 'recoil';

export type PlaidBankingComponentProps = {
  onSuccess(): Promise<void>;
  onError(): void;
  setLoading(loading: boolean): void;
  receivedRedirectUri?: string;
};

export type PlaidDetails = {
  oauth: boolean;
  callbackUri: string;
};

export const PlaidState = atom<PlaidDetails>({
  key: 'plaid-state',
  default: { oauth: false, callbackUri: '' },
});
