import { Text, createStyles } from '@mantine/core';
import { UploadIcon } from 'assets/svg';

const DropzoneChildren = () => {
  const { classes, theme } = useStyles();

  return (
    <div className={classes.dropZonePlaceholderContainer}>
      <UploadIcon
        width={20}
        height={20}
        color={theme.fn.themeColor('neutral', 5)}
      />
      <Text c="neutral.7" className={classes.dropZonePlaceholder}>
        Drag and drop file, or&nbsp;<span>browse</span>&nbsp;to add your
        document
      </Text>
      <Text size="xs" c="neutral.6">
        .pdf, .jpg, .png
      </Text>
    </div>
  );
};

export default DropzoneChildren;

const useStyles = createStyles((theme) => ({
  dropZonePlaceholderContainer: {
    gap: '8px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  dropZonePlaceholder: {
    '> span': {
      display: 'inline',
      color: theme.fn.themeColor('primarySecondarySuccess', 2),
    },
  },
}));
