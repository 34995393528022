import { Group, Stack, useMantineTheme } from '@mantine/core';
import { useCreateSpendPlanContext } from '../../create-spend-plan.context';
import { PreviewRow } from '../../components/preview-row';
import { PreviewLabel } from '../../components/preview-label';
import { useCreateSpendPlanWizard } from '../../create-spend-plan.wizard';

const numFmt = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'symbol',
});

export const SpendPlanSpendControlsStepPreview = () => {
  const theme = useMantineTheme();
  const { spendControlsForm: form } = useCreateSpendPlanContext();
  const { state } = useCreateSpendPlanWizard();

  const isOneTimePlan = state.recurring === 'onetime';

  const getCurrentPeriodString = () => {
    return state.currentLimit
      ? numFmt.format(state.currentLimit)
      : isOneTimePlan
      ? `Spend plan limit not set`
      : `Current period spend limit not set`;
  };

  const getFuturePeriodString = () => {
    if (state.repeatCurrentLimit) {
      return state.currentLimit
        ? numFmt.format(state.currentLimit)
        : `Future period spend limit not set`;
    }

    return state.futureLimit
      ? numFmt.format(state.futureLimit)
      : `Future period spend limit not set`;
  };

  return (
    <Stack spacing={24}>
      {form.values ? (
        <PreviewRow>
          <PreviewLabel
            fz={20}
            fw={500}
            lh="32px"
            color={theme.fn.themeColor('primarySecondarySuccess', 8)}
          >
            Available funds for each period
          </PreviewLabel>

          <Group spacing="24" align="start">
            <Stack spacing={4} sx={{ flexBasis: '50%' }}>
              <PreviewLabel>
                {isOneTimePlan ? 'Spend limit' : 'Current period spend limit'}
              </PreviewLabel>

              <PreviewLabel italic={!state.currentLimit}>
                {getCurrentPeriodString()}
              </PreviewLabel>
            </Stack>

            {!isOneTimePlan ? (
              <Stack spacing={4} sx={{ flexBasis: '50%' }}>
                <PreviewLabel>Future period spend limit</PreviewLabel>

                <PreviewLabel
                  italic={
                    state.repeatCurrentLimit
                      ? !state.currentLimit
                      : !state.futureLimit
                  }
                >
                  {getFuturePeriodString()}
                </PreviewLabel>
              </Stack>
            ) : null}
          </Group>
        </PreviewRow>
      ) : null}
    </Stack>
  );
};
