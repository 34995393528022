import { useRecoilValue } from 'recoil';
import { ApplicationState } from '../../../states/application/product-onboarding';
import { useRouteSectionContext } from '@common/routes/route-context';
import { usePageBackground } from '../../../providers/background-provider';
import { ProductApplicationRoutes } from '../../onboarding-v2/onboarding.constants';
import { Button } from '@mantine/core';
import { ArrowRight } from '../../../assets/svg';
import { SalesPageContent } from '@common/sales/sales-page.content';
import { CreditCardGraphic } from './credit-card-graphic';
import { CreditSalesBoxes } from './credit-sales-boxes';

export const CreditContinuePage = () => {
  const { user } = useRecoilValue(ApplicationState);
  const { setSectionAndNavigate } = useRouteSectionContext();
  usePageBackground(
    (t) =>
      `url("/images/page-top-frame.png") top/contain no-repeat ${t.fn.themeColor(
        'primarySecondarySuccess',
        7,
      )}`,
  );

  const handleContinueClick = () => {
    setSectionAndNavigate('application', ProductApplicationRoutes.CREDIT_ONLY);
  };

  const actionButton = (
    <Button
      onClick={handleContinueClick}
      variant="outline"
      rightIcon={<ArrowRight />}
      c="primarySecondarySuccess.8"
      sx={(theme) => ({
        backgroundColor: theme.fn.themeColor('neutral', 0),
        '&:not([data-disabled])': theme.fn.hover({
          backgroundColor: theme.fn.themeColor('neutral', 0),
        }),
      })}
    >
      Continue Application
    </Button>
  );

  return (
    <SalesPageContent
      topGraphic={
        <CreditCardGraphic
          firstName={user.firstName}
          lastName={user.lastName}
        />
      }
      salesCards={CreditSalesBoxes}
      title="Continue your Flex Credit Card application"
      subtitle="Simplified banking, payments, and expense management for the savvy business owner."
      actionButton={actionButton}
    />
  );
};
