import { PropsWithChildren, ReactNode } from 'react';
import { Box, Radio, createStyles } from '@mantine/core';
import { useId } from '@mantine/hooks';

type Props = {
  onSelect: () => void;
  isSelected: boolean;
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  rightContent?: ReactNode;
  className?: string;
  disabled?: boolean;
  id?: string;
  name?: string;
};

const RadioCard = ({
  onSelect,
  isSelected,
  disabled,
  title,
  subtitle,
  rightContent,
  children,
  className = '',
  id,
  name,
}: PropsWithChildren<Props>) => {
  const { classes, cx } = useStyles();
  const radioId = useId();

  const handleChange = () => {
    onSelect();
  };

  return (
    <Box
      component="label"
      id={id}
      htmlFor={radioId}
      className={cx(
        classes.radioCard,
        disabled ? classes.disabled : classes.enabled,
        className,
      )}
    >
      <div className={classes.iconColumn}>
        <Radio
          id={radioId}
          name={name}
          onChange={handleChange}
          checked={isSelected}
          disabled={disabled}
        />
      </div>

      {children ? (
        children
      ) : (
        <>
          <div className={classes.content}>
            <div className={classes.contentTitle}>{title}</div>
            <div className={classes.contentSubtitle}>{subtitle}</div>
          </div>

          {rightContent ? <div>{rightContent}</div> : null}
        </>
      )}
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  radioCard: {
    borderColor: theme.fn.themeColor('flexbaseGrey', 1),
    borderRadius: '8px',
    borderStyle: 'solid',
    borderWidth: 1,
    display: 'flex',
    alignItems: 'center',
    minHeight: '100px',
    padding: '12px 20px',
    '&:has(:checked)': {
      backgroundColor: theme.fn.themeColor('primarySecondarySuccess', 0),
    },
  },
  enabled: {
    cursor: 'pointer',
    ...theme.fn.hover({
      backgroundColor: theme.fn.themeColor('primarySecondarySuccess', 0),
    }),
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: 0.5,
  },
  iconColumn: {
    flexBasis: 48,
    flexGrow: 0,
    flexShrink: 0,
  },
  content: {
    color: theme.fn.themeColor('blackish', 0),
    fontFamily: theme.fontFamily,
    flexGrow: 1,
    flexBasis: 168,
  },
  contentTitle: {
    fontSize: '1rem',
    fontWeight: 500,
  },
  contentSubtitle: {
    fontSize: '.75rem',
    fontWeight: 400,
  },
}));

export default RadioCard;
