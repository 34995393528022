import { useMemo } from 'react';
import { uniq } from 'underscore';
import { MultiSelect } from '@mantine/core';
import { useBankingTransactionsFilters } from '../use-banking-transactions-filters';
import { useGetDepositAccounts } from '@queries/use-deposit-accounts';
import { useGetBankingTransactions } from '@queries/use-banking-transactions';
import GetPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';

export const BankingTransactionsAccountFilter = () => {
  const { addFilter, removeFilter, getFilterByKey } =
    useBankingTransactionsFilters();
  const { data } = useGetBankingTransactions();
  const { data: depositAccounts } = useGetDepositAccounts();

  const accountName = useMemo(() => {
    if (data && depositAccounts) {
      return uniq(
        data.transactions
          .map((transaction) => {
            const bankingAccount = depositAccounts?.accounts.find(
              (account) => account.id === transaction.depositAccountId,
            );

            const accountInfo = bankingAccount
              ? `${
                  bankingAccount.nickName || bankingAccount.name || 'Flex'
                } ${GetPaddedAccountMask(bankingAccount.accountNumber, 3)}`
              : 'Deposit Account';

            return accountInfo;
          })
          .filter((t) => !!t),
      );
    }
    return [];
  }, [data, depositAccounts]);

  const handleChange = (val: string[]) => {
    if (val.length > 0) {
      addFilter('accountName', {
        key: 'accountName',
        filterValue: val,
        fn: (row) => val.includes(row.account),
        label: `Name: ${val.join(', ')}`,
        showChip: true,
      });
    } else {
      removeFilter('accountName');
    }
  };

  return (
    <MultiSelect
      data={accountName}
      placeholder="Account name"
      searchable
      nothingFound="Nothing found"
      value={getFilterByKey('accountName')?.filterValue || []}
      onChange={handleChange}
    />
  );
};
