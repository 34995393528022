import { atom } from 'recoil';
import IAddress from './address';
import { PromoCode } from 'states/onboarding/onboarding-info';

export type UserRole =
  | 'ADMIN'
  | 'EMPLOYEE'
  | 'ACCOUNTANT'
  | 'NO-LOGINS'
  | 'PLAID-STALE'
  | 'SERVICER'
  | 'OWNER'
  | 'COMPTROLLER'
  | 'OFFICER';

export type UserInfo = {
  id: string;
  firstName: string;
  lastName: string;
  initials: string;
  phone: string;
  picUrl: string | null;
  jobTitle: string;
  address: IAddress;
  email: string;
  cellPhone: string;
  roles: UserRole[];
  promoCode?: PromoCode;
};

export const UserInfoState = atom<UserInfo>({
  key: 'user-info',
  default: {
    id: '',
    firstName: '',
    lastName: '',
    initials: '',
    phone: '',
    picUrl: null,
    address: {
      state: '',
      city: '',
      country: '',
      line2: '',
      postalCode: '',
      line1: '',
    },
    promoCode: {
      id: '',
      userId: '',
      code: '',
      level: '',
      validFrom: '',
      validUntil: '',
      title: '',
      description: '',
      uwReject: false,
    },
    jobTitle: '',
    email: '',
    cellPhone: '',
    roles: [],
  },
});
