import { Box, Button, Group, Stack, Text } from '@mantine/core';
import { useRecoilState } from 'recoil';
import {
  ApplicationState,
  getProductOnboardingStatus,
} from '../../../states/application/product-onboarding';
import {
  onError,
  usePlaidBankingComponent,
} from '@common/banking/plaid-banking-component';
import { useState } from 'react';
import { notifications } from '@mantine/notifications';
import { ActionItemCard } from './action-item-card';
import {
  FinancialInstitution,
  OnboardingCompany,
} from '../../../states/onboarding/onboarding-info';
import { ChainLinkIcon } from '../../../assets/svg';
import getPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';
import { useSelfServiceStyles } from '@common/self-service-dashboard/self-service-dashboard.styles';
import { FilledBadge } from '@common/alerts/filled-badge';
import { flexbaseOnboardingClient } from '@services/flexbase-client';
import { logger } from '@services/logging';

export const LinkAccountsItem = () => {
  const [loading, setLoading] = useState(false);
  const [allConnectedLoading, setAllConnectedLoading] = useState(false);
  const [{ company, user }, setStatus] = useRecoilState(ApplicationState);

  const handleAllAccountsConnectedClick = async () => {
    try {
      setAllConnectedLoading(true);
      const response = await flexbaseOnboardingClient.updateUser({
        id: user.id,
        allPlaidAccountsLinked: true,
      });
      setStatus((prev) => ({ ...prev, user: { ...response } }));
    } catch (e) {
      logger.error('link-accounts.item.tsx', e);
    } finally {
      setAllConnectedLoading(false);
    }
  };

  const onPlaidLinkSuccess = async () => {
    setLoading(true);
    try {
      const newStatus = await getProductOnboardingStatus();
      setStatus(newStatus);
    } catch (e) {
      notifications.show({
        title: 'Account List Refresh Failed',
        message:
          'We could not load the updated list of accounts. This does not mean the account was not linked. Please refresh the page.',
      });
    } finally {
      setLoading(false);
    }
  };

  const { open } = usePlaidBankingComponent({
    onSuccess: onPlaidLinkSuccess,
    onError,
    setLoading,
  });

  const useAdditionalCopy = company.financialInstitutions.length > 0;

  return (
    <ActionItemCard
      title={`Connect ${useAdditionalCopy ? 'additional ' : ''}bank accounts`}
      subtitle={`Please connect ${
        useAdditionalCopy ? 'additional ' : ''
      }bank accounts via Plaid so we can accurately assess the appropriate credit line for your business.`}
    >
      <Stack mt="xl" spacing="sm">
        {company.financialInstitutions
          .filter((f) => !f.unlinked)
          .map((f) => (
            <LinkedAccountListItem account={f} company={company} key={f.id} />
          ))}
      </Stack>
      <Group mt="xl">
        <Button
          variant="light"
          onClick={() => open()}
          loading={loading}
          sx={(theme) => ({
            backgroundColor: theme.fn.themeColor('primarySecondarySuccess', 6),
            '&:not([data-disabled])': theme.fn.hover({
              backgroundColor: theme.fn.themeColor(
                'primarySecondarySuccess',
                6,
              ),
            }),
          })}
        >
          Connect {useAdditionalCopy ? 'another' : 'an'} account
        </Button>
        <Button
          variant="outline"
          onClick={handleAllAccountsConnectedClick}
          loading={allConnectedLoading}
        >
          I have connected all of my business accounts
        </Button>
      </Group>
    </ActionItemCard>
  );
};

type LinkedAccountListItemProps = {
  account: FinancialInstitution;
  company: OnboardingCompany;
};

const LinkedAccountListItem = ({
  account,
  company,
}: LinkedAccountListItemProps) => {
  const { classes, cx } = useSelfServiceStyles();
  const isPrimary = account.id === company.primaryPlaidAccount;

  return (
    <Box
      className={cx(
        classes.linkedAccountListItem,
        isPrimary && classes.primaryAccount,
      )}
    >
      <ChainLinkIcon />
      <Box>
        <Text
          fz={16}
          fw={600}
          c="neutral.9"
        >{`${account.bankName} / ${account.accountName}`}</Text>
        <Text fz={14} c="neutral.6">
          {getPaddedAccountMask(account.last4, 4)}
        </Text>
      </Box>
      {isPrimary && (
        <FilledBadge variant="secondary" ml="auto">
          Primary account
        </FilledBadge>
      )}
    </Box>
  );
};
