import flexbaseClient, {
  flexbaseBankingClient,
} from '@services/flexbase-client';
import { useMutation, useQuery } from '@tanstack/react-query';

const QUERY_KEY = 'unitToken';

export const useGetUnitToken = () => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      return await flexbaseClient.getUnitcoToken();
    },
    meta: {
      errorMessage: 'We are unable to retrieve unit token at the moment.',
    },
  });
};

type UpdateTokenMutationArgs = {
  verificationCode: string;
  verificationToken: string;
};

export const useCreateUnitCoToken = () => {
  return useMutation({
    mutationFn: async ({
      verificationCode,
      verificationToken,
    }: UpdateTokenMutationArgs) => {
      return await flexbaseClient.createUnitCoToken({
        verificationCode,
        verificationToken,
        scope: 'transactions cards cards-write cards-sensitive-write',
      });
    },
  });
};

export const useCreateVGSCustToken = () => {
  return useMutation({
    mutationFn: async ({
      verificationCode,
      verificationToken,
    }: UpdateTokenMutationArgs) => {
      return await flexbaseBankingClient.createVGSCustToken({
        verificationCode,
        verificationToken,
      });
    },
  });
};
