import NavbarWeb from './navbar';
import NavbarMobile from './navbar-mobile';
import { Outlet } from 'react-router-dom';
import {
  AppShell,
  Burger,
  Center,
  Header,
  MediaQuery,
  Navbar,
  useMantineTheme,
} from '@mantine/core';
import { useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import useStyles from './navbar.styles';
import UserActionButtons from 'components/header/user-action-buttons/user-action-buttons';
import FlexIconLink from '@common/icons/flex-icon-link';
import { useBackgroundContext } from '../../providers/background-provider';
import FlexAlert from '@common/alerts/flex-alert';
import {
  ApplicationState,
  IsFullAdmin,
} from 'states/application/product-onboarding';
import { useRecoilValue } from 'recoil';

const NavbarAppShell = () => {
  const theme = useMantineTheme();
  const { background } = useBackgroundContext();

  const { classes } = useStyles({});
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [navbarOpen, setNavbarOpen] = useState(false);
  const isFullAdmin = useRecoilValue(IsFullAdmin);
  const { productStatus } = useRecoilValue(ApplicationState);

  return useMobileView ? (
    <AppShell
      styles={{
        main: {
          paddingTop: '60px',
        },
      }}
      bg={background}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={
        <Navbar
          p="md"
          hiddenBreakpoint="sm"
          width={{ sm: 200, lg: 90 }}
          style={{
            padding: '0px',
            background: 'transparent',
            zIndex: 199,
            width: 'auto',
            marginTop: '-10px',
          }}
        >
          <NavbarMobile setNavbarOpened={setNavbarOpen} {...{ navbarOpen }} />
        </Navbar>
      }
      header={
        <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
          <Header
            height={70}
            style={{
              position: 'fixed',
              width: '100%',
              height: '60px',
              backgroundColor: navbarOpen
                ? theme.fn.themeColor('primarySecondarySuccess', 8)
                : theme.fn.themeColor('neutral', 0),
              borderWidth: '0px',
              zIndex: 10,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                position: 'relative',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '8px 16px',
              }}
            >
              <Burger
                opened={navbarOpen}
                onClick={() => setNavbarOpen((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
              {navbarOpen ? (
                <Center className={classes.avatar}>
                  <FlexIconLink
                    color={theme.fn.themeColor('primarySecondarySuccess', 1)}
                    style={{ width: '100%' }}
                  />
                </Center>
              ) : (
                <Center className={classes.avatar}>
                  <FlexIconLink style={{ width: '100%' }} />
                </Center>
              )}

              <div
                style={{
                  visibility: navbarOpen ? 'hidden' : 'visible',
                  marginTop: '3px',
                }}
              >
                <UserActionButtons />
              </div>
            </div>
          </Header>
        </MediaQuery>
      }
    >
      <Outlet />
    </AppShell>
  ) : (
    <>
      <FlexAlert
        type="yellow"
        message="Additional documents are required to enable international payments"
        visible={
          isFullAdmin &&
          productStatus.internationalPayments.status === 'awaitingDocuments'
        }
        button={{
          url: 'banking/actions-required',
          text: 'Add Documents',
        }}
      />
      <NavbarWeb>
        <Outlet />
      </NavbarWeb>
    </>
  );
};

export default NavbarAppShell;
