import { Box, Tooltip, useMantineTheme } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { useState } from 'react';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';

type AccountDetailCellProps = {
  value: string;
};

export const AccountDetailCell = ({ value }: AccountDetailCellProps) => {
  const clipboard = useClipboard();
  const theme = useMantineTheme();
  const [displayValue, setDisplayValue] = useState(false);

  return (
    <Box>
      {displayValue ? (
        <Tooltip
          label={clipboard.copied ? 'Copied' : 'Click to copy'}
          withArrow
          data-testid="account-tooltip-icon"
        >
          <Box display="flex">
            <button
              style={{ color: theme.colors.blackish[0] }}
              onClick={() => clipboard.copy(value)}
            >
              {value}
            </button>
            <button onClick={() => setDisplayValue(false)}>
              <IoEyeOffOutline color={theme.colors.blackish[2]} size={16} />
            </button>
          </Box>
        </Tooltip>
      ) : (
        <Box display="flex">
          <Box>•••••{value?.slice(-4)}</Box>
          <button onClick={() => setDisplayValue(true)}>
            <IoEyeOutline color={theme.colors.blackish[2]} size={16} />
          </button>
        </Box>
      )}
    </Box>
  );
};
