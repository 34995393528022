import { Box, Divider, Text, createStyles } from '@mantine/core';
import {
  formatLabel,
  getCountryCodeByName,
  getPaymentPurposeLabel,
  isInternationalWire,
  requiresPaymentPurposeCodes,
} from '../util';
import {
  AvailableCurrencies,
  InternationalPayMethod,
  InternationalRecipient,
  PaymentPurposeFormValues,
} from '../util/types';
import { useGetCurrencyConversionRate } from '@queries/use-international-payments';
import { formatCurrency } from '@utilities/formatters/format-currency';
import { ReactNode } from 'react';

type Props = {
  exchangeRate?: number;
  amount: number;
  recipient: InternationalRecipient;
  currency?: AvailableCurrencies;
  method: InternationalPayMethod;
  paymentPurpose: PaymentPurposeFormValues;
  country: string;
  fromAccount: ReactNode;
};

type ExchangeDetail = {
  label: string;
  value: ReactNode | string;
  visible: boolean;
  groupName: string;
};

type GroupedDetails = Record<string, ExchangeDetail[]>;

const IntlPaymentCardDetails = ({
  amount,
  method,
  currency = 'USD',
  recipient,
  paymentPurpose,
  country,
  fromAccount,
}: Props) => {
  const { classes } = useIntlPaymentsCardStyles();
  const { data } = useGetCurrencyConversionRate({
    toCurrency: currency,
    amount: amount || undefined,
  });
  const isForeignCurrencyWire = isInternationalWire(method);
  const hiddenFields = ['type', 'currency'];

  const order: Record<string, string[]> = {
    'Payment information': [
      'Name',
      'Rate',
      'You send',
      'Payment purpose',
      'Source of funds',
      'Account holder',
      'From account',
    ],
    'Recipient information': [
      'Recipient type',
      'Country of recipient',
      'Country of recipients bank',
      'Date of birth',
      'Address',
      'Account number',
      'BIC/SWIFT',
    ],
  };

  const exchangeDetails = [
    {
      label: 'Rate',
      value: `1 USD = ${data?.rate ?? '...'} ${currency}`,
      visible: isForeignCurrencyWire,
      groupName: 'Payment information',
    },
    {
      label: 'You send',
      value: `${formatCurrency(amount, currency)} ${currency}`,
      visible: isForeignCurrencyWire,
      groupName: 'Payment information',
    },
    {
      label: 'Payment Purpose',
      value: paymentPurpose.purpose,
      visible: true,
      groupName: 'Payment information',
    },
    {
      label: 'Source of funds',
      value: paymentPurpose.source,
      visible: true,
      groupName: 'Payment information',
    },
  ];

  if (
    currency &&
    requiresPaymentPurposeCodes(country, currency) &&
    paymentPurpose.code &&
    paymentPurpose.code.length > 0
  ) {
    exchangeDetails.push({
      label: 'Payment purpose type',
      value: getPaymentPurposeLabel(country, paymentPurpose.code)!,
      visible: true,
      groupName: 'Payment information',
    });
  }

  Object.entries(recipient).forEach(([key, val]) => {
    if (val && !hiddenFields.includes(key)) {
      const formattedVal = val.charAt(0).toUpperCase() + val.slice(1);
      const groupNameRecipientInfo =
        formatLabel(key).includes('Recipient type') ||
        formatLabel(key).includes('Country of recipient') ||
        formatLabel(key).includes('Country of recipients bank') ||
        formatLabel(key).includes('Dob') ||
        formatLabel(key).includes('Address') ||
        formatLabel(key).includes('Account number') ||
        formatLabel(key).includes('BIC/SWIFT');

      exchangeDetails.push({
        label: formatLabel(key),
        value: key.startsWith('country')
          ? getCountryCodeByName(val) || formattedVal
          : formattedVal,
        visible: true,
        groupName: groupNameRecipientInfo
          ? 'Recipient information'
          : 'Payment information',
      });
    }
  });

  const groupedDetails: GroupedDetails = {
    'Payment information': [],
    'Recipient information': [],
  };

  exchangeDetails.forEach((detail) => {
    const groupName = detail.groupName;
    groupedDetails[groupName].push(detail);
  });

  exchangeDetails.reduce((acc: GroupedDetails, detail) => {
    acc[detail.groupName] = acc[detail.groupName] || [];

    if (
      detail.label !== 'First name' &&
      detail.label !== 'Last name' &&
      detail.label !== 'Name' &&
      detail.label !== 'Dob' &&
      detail.label !== 'Address' &&
      detail.label !== 'City'
    ) {
      acc[detail.groupName].push(detail);
    }

    return acc;
  }, {});

  const accountName = exchangeDetails.find((detail) => detail.label === 'Name');
  const firstNameDetail = exchangeDetails.find(
    (detail) => detail.label === 'First name',
  );
  const lastNameDetail = exchangeDetails.find(
    (detail) => detail.label === 'Last name',
  );
  const dob = exchangeDetails.find((detail) => detail.label === 'Dob');
  const addressLabels = ['Address', 'City', 'Country of recipient', 'Postcode'];

  const addressDetails = addressLabels.map((label) => {
    const detail = exchangeDetails.find((item) => item.label === label);
    return detail ? detail.value : '';
  });

  const filteredAddressDetails = addressDetails.filter(
    (value) => value.trim() !== '',
  );

  const paymentInformationDetails = [
    {
      label: 'Name',
      value:
        firstNameDetail && lastNameDetail
          ? `${firstNameDetail.value} ${lastNameDetail.value}`
          : '',
      visible: true,
      groupName: 'Payment information',
    },
    {
      label: 'Account name',
      value: accountName ? accountName.value : '',
      visible: true,
      groupName: 'Payment information',
    },
    {
      label: 'From account',
      value: fromAccount || '',
      visible: true,
      groupName: 'Payment information',
    },
  ];

  const recipientInformationDetails = [
    {
      label: 'Date of birth',
      value: dob ? dob.value : '',
      visible: true,
      groupName: 'Recipient information',
    },
    {
      label: 'Address',
      value:
        filteredAddressDetails.length > 0
          ? filteredAddressDetails.join(', ')
          : '',
      visible: true,
      groupName: 'Recipient information',
    },
  ];

  groupedDetails['Payment information'].push(
    ...paymentInformationDetails.filter((detail) => detail.value !== ''),
  );
  groupedDetails['Recipient information'].push(
    ...recipientInformationDetails.filter((detail) => detail.value !== ''),
  );

  Object.keys(groupedDetails).forEach((groupName) => {
    const details = groupedDetails[groupName];
    const orderedDetails: ExchangeDetail[] = [];
    order[groupName].forEach((label) => {
      const detail = details.find((item) => item.label === label);
      if (detail) {
        orderedDetails.push(detail);
      }
    });
    groupedDetails[groupName] = orderedDetails;
  });

  return (
    <>
      <Divider mt="md" mb={'md'} />
      {Object.entries(groupedDetails)
        .sort(([groupNameA], [groupNameB]) => {
          if (groupNameA === 'Recipient information') return -1;
          if (groupNameB === 'Recipient information') return 1;
          return 0;
        })
        .map(([groupName, details]) =>
          groupName === 'Recipient information' &&
          details.length === 0 ? null : (
            <Box key={groupName}>
              <Text
                size="sm"
                fw="600"
                mb={groupName === 'Recipient information' ? '0.5rem' : '1rem'}
              >
                {groupName}
              </Text>
              {details
                .filter((column) => column.visible)
                .map((column) => (
                  <Box key={column.label} className={classes.containerDetails}>
                    <Text size="sm">{column.label}</Text>
                    <Text
                      size="sm"
                      data-testid={column.label
                        .replaceAll(' ', '-')
                        .toLocaleLowerCase()}
                    >
                      {column.value}
                    </Text>
                  </Box>
                ))}
            </Box>
          ),
        )}
      <Divider mt={'md'} mb={'md'} />
    </>
  );
};

export default IntlPaymentCardDetails;

const useIntlPaymentsCardStyles = createStyles(() => ({
  containerDetails: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridAutoRows: 'minmax(32px, auto)',
  },
}));
