import { DateTime, Interval } from 'luxon';
import { useCreditCardsModalFilters } from '../use-credit-cards-filters';
import { DateRangeFilter } from '@common/filter/date-range.filter';

export const CreditCardsDateRangeFilter = () => {
  const creditCardsFilters = useCreditCardsModalFilters();

  return (
    <DateRangeFilter
      filterHook={creditCardsFilters}
      filterFn={(startDate, endDate, item) => {
        const dateRange = Interval.fromDateTimes(startDate, endDate);
        return dateRange.contains(DateTime.fromSQL(item.asOf));
      }}
    />
  );
};
