import { MultiSelect } from '@mantine/core';
import { uniq } from 'underscore';
import { usePaymentFilters } from '../use-payment-filters';
import { useGetBankingPayments } from '@queries/use-payments';
import { useMemo } from 'react';
import { useGetDepositAccounts } from '@queries/use-deposit-accounts';
import { formatDepositAccountDetails } from '@utilities/formatters/format-strings';

export const PaymentAccountNameFilter = () => {
  const { data: depositAccounts } = useGetDepositAccounts();
  const { data } = useGetBankingPayments();

  const accountName = useMemo(() => {
    if (data && depositAccounts) {
      return uniq(
        data
          .map((payment) => {
            const depositAccountName =
              payment.payDirection === 'Debit'
                ? payment.payCtrParty
                : formatDepositAccountDetails(
                    depositAccounts.accounts,
                    payment.depositId,
                  );
            return depositAccountName;
          })
          .filter((t) => !!t),
      );
    }
    return [];
  }, [data, depositAccounts]);

  const handleChange = (val: string[]) => {
    if (val.length > 0) {
      addFilter('name', {
        key: 'name',
        filterValue: val,
        fn: (row) => val.includes(row.accountName),
        label: `Name: ${val.join(', ')}`,
        showChip: true,
      });
    } else {
      removeFilter('name');
    }
  };

  const { addFilter, removeFilter, getFilterByKey } = usePaymentFilters();

  return (
    <MultiSelect
      data={accountName}
      placeholder="Account name"
      searchable
      nothingFound="Nothing found"
      value={getFilterByKey('name')?.filterValue || []}
      onChange={handleChange}
    />
  );
};
