import { flexbaseOnboardingClient } from '@services/flexbase-client';
import { queryOptions, useQuery } from '@tanstack/react-query';

const getAlertParamsQueryOptions = () =>
  queryOptions({
    queryKey: ['alert_params'],
    queryFn: async () => await flexbaseOnboardingClient.getAlertParams(),
    staleTime: Infinity,
  });

export const useAlertParams = () => {
  return useQuery(getAlertParamsQueryOptions());
};
