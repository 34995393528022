import { useSpendTransactionModalFilters } from '../use-spend-transactions-filter';
import { SpendTransactionsTableRow } from 'areas/spend-plans/details/tabs/transactions/spend-transaction-table-helpers';
import { SwitchFilter } from '@common/filter/switch-filter';

export const SpendAttachmentsFilter = () => {
  const { addFilter, getFilterByKey } = useSpendTransactionModalFilters();

  const onOptionChange = (selectedKey: string, selectedValue: boolean) => {
    let label = '';
    let filterFn: (row: SpendTransactionsTableRow) => boolean = () => true;

    switch (selectedKey) {
      case 'receipt':
        label = selectedValue ? 'Has receipt' : 'No receipt';
        filterFn = (row) => (selectedValue ? !!row.docId : !row.docId);
        break;
    }

    addFilter(selectedKey, {
      label: label,
      filterValue: selectedValue,
      key: selectedKey,
      fn: filterFn,
      showChip: true,
    });
  };

  return (
    <SwitchFilter
      options={[{ label: 'Has receipt', key: 'receipt' }]}
      value={{
        receipt: getFilterByKey('receipt')?.filterValue,
      }}
      onOptionChange={onOptionChange}
    />
  );
};
