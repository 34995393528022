import { useEffect, useState } from 'react';

import TransferReview from './transfer-review';
import TransferDetails from './transfer-details';
import useModal from 'components/modal/modal-hook';
import { useNavigate } from 'react-router-dom';
import FullScreenModalContainer from 'components/modal/full-screen-modal-container';
import { useStyles } from '../styles';
import { useMediaQuery } from '@mantine/hooks';
import Stepper from 'components/stepper/stepper';
import { AccountProps } from 'areas/banking/move-funds/move-funds.model';

export type TransferForm = {
  amount: string;
  activity: 'withdrawal' | 'deposit';
  sourceAccount?: AccountProps;
  destinationAccount?: AccountProps;
};

const MoveTreasuryFunds = () => {
  const { classes } = useStyles();
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const [transferForm, setTransferForm] = useState<TransferForm>({
    amount: '',
    activity: 'withdrawal',
    sourceAccount: undefined,
    destinationAccount: undefined,
  });
  const { openFullModal, closeAllModals } = useModal();

  const closeModal = () => {
    closeAllModals();
    navigate('/accounts/activities');
  };

  let currentStep: any;
  switch (step) {
    case 1:
      currentStep = (
        <TransferReview
          {...{
            setStep,
            transferForm,
          }}
        />
      );
      break;

    default:
      currentStep = (
        <TransferDetails
          {...{
            setStep,
            closeModal,
            transferForm,
            setTransferForm,
          }}
        />
      );
      break;
  }

  useEffect(() => {
    openFullModal(
      <FullScreenModalContainer closeModal={closeModal} unrestrictedContent>
        <>
          {!isMobile && (
            <div className={classes.stepperContainer}>
              <Stepper
                activeStep={step}
                stepsData={['Transfer funds details', 'Review']}
              />
            </div>
          )}
          <div style={{ margin: isMobile ? 'auto' : 'none' }}>
            {currentStep}
          </div>
        </>
      </FullScreenModalContainer>,
    );
  }, [step, isMobile]);

  return false;
};

export default MoveTreasuryFunds;
