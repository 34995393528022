import {
  CreateSpendPlan,
  CreateSpendPlanFrequencyEnum,
  SpendPlanMember,
  UpdateSpendPlan,
  UpdateSpendPlanFrequencyEnum,
} from '@flexbase-eng/types/dist/accounting';
import { SpendPlanMemberFields } from '../steps/team-members/team-members-step.context';
import { SpendPlanFormState } from '../create-spend-plan.wizard';

function memberFieldsToSpendPlanMember(
  member: SpendPlanMemberFields,
  state: SpendPlanFormState,
): SpendPlanMember {
  const isOneTimePlan = state.frequency === 'onetime';

  const initialLimit = member.fullFundsAccess
    ? undefined
    : member.currentAssigned;
  const limit = isOneTimePlan
    ? 0
    : member.repeatFundsAccess
    ? initialLimit
    : member.assigned;

  const memberPayload = {
    personId: member.id,
    fullFundsAccess: member.fullFundsAccess,
    repeatFundsAccess: member.repeatFundsAccess,
    initialLimit: initialLimit,
    limit: limit,
  };

  const role = member.isManager ? 'Manager' : 'Member';
  const name = `${member.firstName} ${member.lastName}`;

  if (
    typeof memberPayload.initialLimit !== 'number' &&
    typeof memberPayload.initialLimit !== 'undefined'
  ) {
    throw new Error(`${role} ${name} has an invalid assigned limit.`);
  } else if (
    typeof memberPayload.limit !== 'number' &&
    typeof memberPayload.initialLimit !== 'undefined'
  ) {
    throw new Error(`${role} ${name} has an invalid assigned future limit.`);
  }

  return memberPayload;
}

export function toCreateSpendPlanPayload(
  state: SpendPlanFormState,
): CreateSpendPlan {
  // basic details - map directly from form
  const name = state.name;
  const description = state.description;

  let frequency: CreateSpendPlanFrequencyEnum;

  // if user selected 'recurring', use the selected frequency, else it's onetime
  if (state.recurring === 'recurring') {
    frequency = state.frequency;
  } else {
    frequency = CreateSpendPlanFrequencyEnum.Onetime;
  }

  if (typeof state.currentLimit !== 'number') {
    throw new Error('Missing or invalid limit');
  }

  let limit = state.currentLimit;
  const initialLimit = state.currentLimit;

  // check if the user wants to repeat the limit for the current period or use
  // a different one once the current period ends
  if (state.repeatCurrentLimit) {
    limit = state.currentLimit;
  } else {
    if (typeof state.futureLimit !== 'number') {
      throw new Error('Missing or invalid future limit');
    }

    limit = state.futureLimit;
  }

  // undefined = plan doesn't expire
  const expirationDate = state.expirationDate?.toISOString();

  const toSpendPlanMember = (m: SpendPlanMemberFields) =>
    memberFieldsToSpendPlanMember(m, state);

  // grab the managers
  const managers = state.members
    .filter((m) => m.isManager)
    .map(toSpendPlanMember);

  // grab the regular members
  const members = state.members
    .filter((m) => !m.isManager)
    .map(toSpendPlanMember);

  // categories -- map directly from form
  const allowedRutterCategories = [...state.accountingCategories];
  const blockedRutterCategories: string[] = []; // not supported at the moment
  const allowedMccs =
    state.merchantCategoryRestrictionType === 'whitelist'
      ? [...state.merchantCategoryWhitelist]
      : [];
  const blockedMccs =
    state.merchantCategoryRestrictionType === 'blacklist'
      ? [...state.merchantCategoryBlacklist]
      : [];

  // set threshold based on the receipt policy
  // NOTE: receipt policy will not be implemented for first rollout
  const receiptsRequired = false;
  const receiptRequiredThreshold: number | undefined = undefined;

  const payload = {
    name,
    description,
    frequency,
    limit,
    initialLimit,
    expirationDate,
    managers,
    members,
    allowedRutterCategories,
    blockedRutterCategories,
    allowedMccs,
    blockedMccs,
    receiptsRequired,
    receiptRequiredThreshold,
  };

  return payload;
}

export function toUpdateSpendPlanPayload(
  state: SpendPlanFormState,
): UpdateSpendPlan {
  const { frequency, ...updatePayload } = toCreateSpendPlanPayload(state);

  return {
    ...updatePayload,
    frequency: frequency as unknown as UpdateSpendPlanFrequencyEnum,
  };
}
