import { formatCurrency } from '@flexbase-eng/web-components';
import { Box, SegmentedControl, Text, useMantineTheme } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import { AccountProps } from 'areas/banking/move-funds/move-funds.model';
import { useConfirmAndReviewStyles } from 'areas/credit/repayment/review-and-pay.styles';
import AccountBox from 'components/account-box/account-box';
import { useState } from 'react';
import { capitalizeOnlyFirstLetter } from 'utilities/formatters/format-strings';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import AccountIcon from './acount-icon';

type SegmentedControlValues = 'all' | 'flex' | 'external';

type AccountSegmentedControlProps = {
  onChange: (value: SegmentedControlValues) => void;
  data: { label: string; value: SegmentedControlValues }[];
};

const AccountSegmentedControl = ({
  onChange,
  data,
}: AccountSegmentedControlProps) => {
  const theme = useMantineTheme();
  return (
    <SegmentedControl
      data={data}
      size="xs"
      onChange={onChange}
      radius={4}
      color="neutral.1"
      my={16}
      ml={16}
      styles={{
        root: {
          backgroundColor: 'transparent',
        },
        label: {
          border: `solid 1px ${theme.colors.neutral[3]}`,
        },
        control: {
          marginRight: '1rem',
        },
        controlActive: {
          label: {
            '&[data-active]': {
              color: theme.colors.blackish[1],
            },
            '&[data-active]:hover': {
              color: theme.colors.blackish[1],
            },
          },
        },
      }}
    />
  );
};

const accountSubheaderText = (acct: AccountProps) => {
  switch (acct.plaidOrDeposit) {
    case 'deposit': {
      const flexbaseAccount = acct;
      return `${formatCurrency(
        flexbaseAccount.balance / 100,
      )} / ${capitalizeOnlyFirstLetter(
        flexbaseAccount.accountType,
      )} ${getPaddedAccountMask(flexbaseAccount.accountNumber, 4)}`;
    }
    case 'plaid': {
      const plaidAccount = acct;
      return (
        <>
          {plaidAccount.available &&
            `${formatCurrency(plaidAccount.available)} / `}
          {capitalizeOnlyFirstLetter(plaidAccount.accountType || '')}{' '}
          {getPaddedAccountMask(plaidAccount.last4 ?? plaidAccount.account, 4)}
        </>
      );
    }
    case 'admDeposit': {
      const admAccount = acct;
      return `${admAccount.subName} ${getPaddedAccountMask(
        admAccount.accountNumber,
        4,
      )}`;
    }
  }
};

const getName = (acct: AccountProps) => {
  switch (acct.plaidOrDeposit) {
    case 'deposit': {
      return acct.nickName ?? 'Flex';
    }
    case 'plaid': {
      return acct.bankName ?? acct.accountName;
    }
    case 'admDeposit': {
      return acct.name;
    }
  }
};

const filterAccountsBySegmentedControlValue = (
  value: SegmentedControlValues,
  accounts?: AccountProps[],
) => {
  if (!accounts) {
    return {
      all: [],
      flex: [],
      external: [],
    }[value];
  }

  return {
    all: accounts,
    flex: accounts.filter((acct) => acct.plaidOrDeposit === 'deposit'),
    external: accounts.filter((acct) => acct.plaidOrDeposit !== 'deposit'),
  }[value];
};

type Props = {
  currentAccount: AccountProps;
  accounts?: AccountProps[];
  onAccountChange?: (account: AccountProps) => void;
  label?: string;
  classNames?: Partial<{ list: string; target: string }>;
  showAccountFilters?: boolean;
  onLinkingCompleted?: () => void;
  disabled?: boolean;
};

const AccountSelection = ({
  currentAccount,
  accounts,
  onAccountChange,
  label,
  classNames,
  showAccountFilters = false,
  onLinkingCompleted,
  disabled = false,
}: Props) => {
  const { classes, cx } = useConfirmAndReviewStyles();
  const [showSelect, setShowSelect] = useState(false);
  const [segmentedControlValue, setSegmentedControlValue] =
    useState<SegmentedControlValues>('all');
  const ref = useClickOutside(() => {
    resetDropdown();
  });

  const visibleAccounts = accounts?.filter(
    (acct) => acct.id !== currentAccount.id,
  );
  const filteredAccounts = filterAccountsBySegmentedControlValue(
    segmentedControlValue,
    visibleAccounts,
  );

  const resetDropdown = () => {
    setSegmentedControlValue('all');
    setShowSelect(false);
  };

  const handleSegmentedControlChange = (val: SegmentedControlValues) => {
    setSegmentedControlValue(val);
  };

  const handleDropdownClick = () => {
    if (disabled) {
      return;
    }
    if (accounts) {
      setShowSelect((prev) => {
        if (accounts.length > 1) {
          return !prev;
        }
        return prev;
      });
      setSegmentedControlValue('all');
    }
    setShowSelect(!showSelect);
  };

  return (
    <Box ref={ref} className={classNames?.target} pos="relative">
      {label && <Text className={classes.inputTitle}>{label}</Text>}
      <AccountBox
        headerText={getName(currentAccount)}
        subheaderText={accountSubheaderText(currentAccount) || ''}
        onClick={handleDropdownClick}
        showArrow={accounts && accounts.length > 1}
        rotateArrow={showSelect}
        isListItem={false}
        showBorder={true}
        icon={
          <AccountIcon
            externalName={
              currentAccount.plaidOrDeposit === 'plaid'
                ? getName(currentAccount)
                : null
            }
            data-testid={'accounts-list'}
          />
        }
        onLinkingCompleted={onLinkingCompleted}
        isAccUnlinked={
          currentAccount.plaidOrDeposit === 'plaid' && currentAccount.unlinked
        }
        plaidAccount={
          currentAccount.plaidOrDeposit === 'plaid' ? currentAccount : undefined
        }
        style={{
          opacity: disabled ? 0.5 : 1,
          cursor: disabled || !showSelect ? 'default' : 'pointer',
        }}
      />

      {showSelect && accounts && accounts.length > 1 && (
        <Box className={cx(classes.selectList, classNames?.list)}>
          {showAccountFilters && (
            <AccountSegmentedControl
              data={[
                { label: 'All accounts', value: 'all' },
                { label: 'Flex accounts', value: 'flex' },
                { label: 'External accounts', value: 'external' },
              ]}
              onChange={handleSegmentedControlChange}
            />
          )}

          {filteredAccounts.map((acct, i) => (
            <Box key={acct.id} data-testid={`accounts-list-${i}`}>
              <AccountBox
                headerText={getName(acct)}
                subheaderText={accountSubheaderText(acct) || ''}
                onClick={() => {
                  if (onAccountChange) {
                    onAccountChange(acct);
                    resetDropdown();
                  }
                }}
                showArrow={false}
                rotateArrow={false}
                isListItem={true}
                icon={
                  <AccountIcon
                    externalName={
                      acct.plaidOrDeposit === 'plaid' ? getName(acct) : null
                    }
                    data-testid={'accounts-list'}
                  />
                }
                isAccUnlinked={acct.plaidOrDeposit === 'plaid' && acct.unlinked}
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default AccountSelection;
