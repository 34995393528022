import { useForm } from '@mantine/form';
import { useState, useRef, useEffect } from 'react';
import { Alert, Button, Group, Select, TextInput, Box } from '@mantine/core';
import { RedAlertIcon } from 'assets/svg';
import { useStyles } from './debit-card-details.styles';
import { Limits } from 'services/flexbase/banking.model';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { checkATMWithdrawalLimits, checkSpendingLimits } from '../util';
import type { Tier } from 'areas/banking/move-funds/move-funds.model';
import { formatNumberStringWithDecimals } from 'utilities/formatters/format-strings';
import TwoFactorAuthSection from '../two-factor/two-factor-auth-section';
import { useUpdateDebitCard } from '@queries/use-debit-cards';

type Props = {
  cardData: any;
  setCardData: (x: any) => void;
  setEditCard: (x: boolean) => void;
  accountTier: Tier;
  atmWithdrawalLimit: number;
};

const UpdateDebitCard = ({
  cardData,
  setCardData,
  setEditCard,
  accountTier,
  atmWithdrawalLimit,
}: Props) => {
  const { classes } = useStyles();
  const buttonRef = useRef<HTMLDivElement>(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [updateCusToken, setUpdateCusToken] = useState(false);
  const { mutate, isPending, isError, error } = useUpdateDebitCard();
  const errorMessages = [
    'a Customer Token is required',
    'Bearer token is invalid or expired',
  ];
  const needsUpdateToken = (errorMsg: string) =>
    errorMessages.some((message) => errorMsg.includes(message));

  const { limit, cardName, limitType } = cardData;
  const form = useForm({
    initialValues: {
      amount: limit,
      cardName,
      limitType,
      withdrawalLimit: atmWithdrawalLimit,
    },
  });

  const onSpendingLimitInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    form.setFieldValue(
      'amount',
      Number(formatNumberStringWithDecimals(e.currentTarget.value)),
    );
    setHasChanges(true);
  };

  const onAtmWithdrawalInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    form.setFieldValue(
      'withdrawalLimit',
      Number(formatNumberStringWithDecimals(e.currentTarget.value)),
    );
    setHasChanges(true);
  };

  const getDebitCardLimits = (values: typeof form.values) => {
    const { limitType: currentLimitType, amount, withdrawalLimit } = values;
    const cardLimits: Limits = {};

    if (withdrawalLimit !== atmWithdrawalLimit) {
      cardLimits.dailyWithdrawal = withdrawalLimit;
    }

    switch (currentLimitType) {
      case 'daily':
        cardLimits.dailyPurchase = amount;
        break;
      case 'monthly':
        cardLimits.monthlyPurchase = amount;
        break;
      default:
        break;
    }

    return cardLimits;
  };

  const updateCard = async () => {
    if (!hasChanges) {
      setEditCard(false);
    }
    const newCardName = [null, ''].includes(form.values.cardName)
      ? null
      : form.values.cardName;
    mutate(
      {
        id: cardData.id,
        cardName: newCardName,
        limits: getDebitCardLimits(form.values),
      },
      {
        onSuccess: (data) => {
          const { card } = data;
          setCardData({
            ...card,
            limit: form.values.amount,
            cardName: form.values.cardName,
            limitType: form.values.limitType,
          });
          setHasChanges(false);
          setEditCard(false);
        },
        onError: (err) => {
          console.error('Unable to update the card info', err);
          const { error: errorMsg = '' } = JSON.parse(err.message);
          if (needsUpdateToken(errorMsg)) {
            setUpdateCusToken(true);
          }
        },
      },
    );
  };

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div className={classes.padding}>
      <TextInput
        label="Card name"
        placeholder="Card name"
        value={form.values.cardName}
        onChange={(event) => {
          form.setFieldValue('cardName', event.currentTarget.value);
          setHasChanges(true);
        }}
      />
      {!cardData.cardSubtype.includes('Virtual') && (
        <TextInput
          mt="sm"
          value={formatCurrency(form.values.withdrawalLimit || '0')}
          onChange={onAtmWithdrawalInputChange}
          label={'Daily ATM withdrawal limit'}
          error={checkATMWithdrawalLimits(
            form.values.withdrawalLimit,
            accountTier,
          )}
        />
      )}
      <Select
        mt="sm"
        label="Spending limit type"
        value={form.values.limitType}
        data={[
          { label: 'Daily', value: 'daily' },
          { label: 'Monthly', value: 'monthly' },
          { value: 'unlimited', label: 'Unlimited' },
        ]}
        onChange={(value) => {
          form.setFieldValue('limitType', value);
          setHasChanges(true);
        }}
      />

      {form.values.limitType !== 'unlimited' && (
        <TextInput
          mt="sm"
          value={formatCurrency(form.values.amount || '0')}
          onChange={onSpendingLimitInputChange}
          label={`${
            form.values.limitType === 'daily' ? 'Daily' : 'Monthly'
          } spending limit`}
          error={checkSpendingLimits(
            form.values.amount,
            form.values.limitType,
            accountTier,
          )}
        />
      )}
      {/* do not display error message if we ask for 2FA */}
      {isError && !needsUpdateToken(error.message) && (
        <Alert color="red" mt="lg" icon={<RedAlertIcon />}>
          Unable to update the card. Please, try it again.
        </Alert>
      )}

      {updateCusToken && (
        <Box mt="sm">
          <TwoFactorAuthSection onSuccess={updateCard} />
        </Box>
      )}

      <Group mt="lg" position="right" ref={buttonRef}>
        <Button variant="outline" onClick={() => setEditCard(false)}>
          Cancel
        </Button>
        <Button variant="light" loading={isPending} onClick={updateCard}>
          Save
        </Button>
      </Group>
    </div>
  );
};

export default UpdateDebitCard;
