import { WizardStepConfig } from '@common/wizard/wizard.types';
import MappingsStep from './mappings-step';
import { IntegrationAccountMappingWizard } from './integration-mappings.wizard';
import { useNavigate } from 'react-router-dom';

const steps: WizardStepConfig<unknown>[] = [
  {
    id: 'Edit mappings',
    title: 'Edit mappings',
    element: <MappingsStep />,
  },
];

const IntegrationMappings = () => {
  const navigate = useNavigate();
  const handleCancelClick = () => {
    navigate('/settings/integrations');
  };

  return (
    <IntegrationAccountMappingWizard
      steps={steps}
      initialState={null}
      onCancel={handleCancelClick}
      progressBar={false}
    />
  );
};

export default IntegrationMappings;
