import { useStyles } from 'components/styles';
import { Box } from '@mantine/core';

type Props = {
  children: React.ReactNode;
};
const FlexbaseMainLayout = (props: Props) => {
  const { classes } = useStyles();
  return <Box className={classes.mainContainer}>{props.children}</Box>;
};

export default FlexbaseMainLayout;
