import { Box } from '@mantine/core';
import { useBillPayStyles } from '../billing.styles';
import { PayablesTable } from '@mercoa/react';
import { useBillingScheduledFilters } from './use-billing-scheduled-filters';
import { useOutletContext } from 'react-router-dom';
import { MercoaContext } from 'states/mercoa/mercoa';
import BillPayTable from '../components/bill-table/bill-pay-table';
import { InvoiceResponse } from '@mercoa/javascript/api';

const Scheduled = () => {
  const { classes } = useBillPayStyles();
  const context = useOutletContext<MercoaContext>();

  const customTable = (invoices: InvoiceResponse[], dataLoaded: boolean) => {
    return (
      <BillPayTable
        invoices={invoices}
        tableType="SCHEDULED"
        dataLoaded={dataLoaded}
        mercoaToken={context.mercoaToken}
        actionButtonText="Schedule a bill"
        useFilters={useBillingScheduledFilters()}
        headingText="No bills have been scheduled"
      />
    );
  };

  return (
    <Box className={classes.card} mb="lg">
      <PayablesTable statuses={['SCHEDULED']}>
        {({ invoices, dataLoaded }) => customTable(invoices, dataLoaded)}
      </PayablesTable>
    </Box>
  );
};

export default Scheduled;
