import { useRecoilValue } from 'recoil';
import { ApplicationState } from '../../states/application/product-onboarding';
import { useMemo } from 'react';

export type UseHasTermsReturnType = {
  hasBankingTerms: boolean;
  hasInternationalPaymentsTerms: boolean;
  hasTreasuryTerms: boolean;
  hasCreditTerms: boolean;
  hasFlexbaseTerms: boolean;
  hasPersonalGuaranty: boolean;
  hasFicoPull: boolean;
  hasPatriotAct: boolean;
  hasActiveBanking: boolean;
  hasActiveTreasury: boolean;
  hasActiveCredit: boolean;
  // Does the user have any required terms for an active product?
  hasTermsForActiveProduct: boolean;
  hasActiveIntnlPayments: boolean;
};

/**
 * A hook to tell us what terms a user needs to agree to, their active products, and if they have required terms for any active product
 * @returns {UseHasTermsReturnType}
 */
export const useHasTerms = (): UseHasTermsReturnType => {
  const { requiredProperties, productStatus } =
    useRecoilValue(ApplicationState);

  return useMemo(() => {
    const hasFlexbaseTerms = requiredProperties.includes(
      'user.termsOfServiceSigned',
    );
    const hasBankingTerms = requiredProperties.includes(
      'user.bankingTermsOfServiceSigned',
    );
    const hasInternationalPaymentsTerms = requiredProperties.includes(
      'user.internationalPaymentsTermsOfServiceSigned',
    );
    const hasTreasuryTerms = requiredProperties.includes(
      'user.treasuryTermsOfServiceSigned',
    );
    const hasCreditTerms = requiredProperties.includes(
      'user.creditTermsOfServiceSigned',
    );
    const hasPersonalGuaranty = requiredProperties.includes(
      'user.personalGuarantySigned',
    );
    const hasFicoPull = requiredProperties.includes('user.ficoPullSigned');
    const hasPatriotAct = requiredProperties.includes('user.patriotActSigned');

    // Consider splitting these out into a separate state since they may be useful elsewhere
    const hasActiveBanking = productStatus.banking.appStatus === 'approved';
    const hasActiveTreasury = !!productStatus.treasury.admNumber;
    const hasActiveCredit =
      !!productStatus.credit.creditLimit &&
      parseFloat(productStatus.credit.creditLimit) > 0;
    const hasActiveIntnlPayments =
      productStatus.internationalPayments.status === 'approved';

    const hasTermsForActiveProduct =
      hasFlexbaseTerms ||
      (hasActiveBanking && hasBankingTerms) ||
      (hasActiveIntnlPayments && hasInternationalPaymentsTerms) ||
      (hasActiveCredit &&
        (hasCreditTerms ||
          hasPersonalGuaranty ||
          hasFicoPull ||
          hasPatriotAct)) ||
      (hasActiveTreasury && hasTreasuryTerms);

    return {
      hasBankingTerms,
      hasInternationalPaymentsTerms,
      hasFlexbaseTerms,
      hasTreasuryTerms,
      hasCreditTerms,
      hasPersonalGuaranty,
      hasPatriotAct,
      hasFicoPull,
      hasActiveBanking,
      hasActiveCredit,
      hasActiveTreasury,
      hasTermsForActiveProduct,
      hasActiveIntnlPayments,
    };
  }, [requiredProperties]);
};
