import { Text, View } from '@react-pdf/renderer';
import { stylesStatementPDF } from './styles';
import { CompanyResponse } from 'services/flexbase/company.model';

type AddressProps = {
  companyDetails: Omit<CompanyResponse, 'id' | 'createdAt' | 'doingBusinessAs'>;
};

const AddressHeader = ({ companyDetails }: AddressProps) => {
  return (
    <View style={stylesStatementPDF.containerHeader}>
      <View>
        <Text
          style={{
            ...stylesStatementPDF.text,
            color: '#010202',
            fontStyle: 'bold',
            alignSelf: 'flex-end',
          }}
        >
          {companyDetails?.companyName || ''}
        </Text>
        <Text
          style={{
            ...stylesStatementPDF.text,
            color: '#010202',
            marginTop: 2,
            alignSelf: 'flex-end',
          }}
        >
          {companyDetails?.address.line1}, {companyDetails?.address.line2}
        </Text>
        <Text
          style={{
            ...stylesStatementPDF.text,
            color: '#010202',
            marginTop: 2,
            alignSelf: 'flex-end',
          }}
        >
          {companyDetails?.address.city}, {companyDetails?.address.state},{' '}
          {companyDetails?.address.postalCode}
        </Text>
      </View>
    </View>
  );
};

export default AddressHeader;
