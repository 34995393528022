import { TableColumn } from 'react-data-table-component';
import { InvoiceResponse } from '@mercoa/javascript/api';
import {
  BillStatusBadge,
  VendorContainer,
  BillApprovalBadge,
} from './table-components';
import AddBill from '../add-bill/add-bill';
import { FlexbaseTable } from '@common/table';
import BillPayHeader from '../bill-pay-header';
import EmptyBillTable from './empty-bill-table';
import useModal from '@common/modal/modal-hook';
import { mappedInvoices, sortDate } from './utils';
import SkeletonLoading from '@common/loading/skeleton-loading';
import { ActiveFiltersReturnType } from '@common/filter/filters';
import { InvoiceTableData } from '@services/flexbase/billing.model';
import { formatCurrency } from '@utilities/formatters/format-currency';

type TableType = 'ALL' | 'UNPAID' | 'ACTION_REQUIRED' | 'SCHEDULED' | 'PAID';

type BillPayTableProps = {
  dataLoaded: boolean;
  mercoaToken: string;
  headingText: string;
  tableType?: TableType;
  withActions?: boolean;
  actionButtonText?: string;
  invoices: InvoiceResponse[];
  useFilters: ActiveFiltersReturnType<InvoiceTableData>;
};

const getColumns = (tableType: TableType): TableColumn<InvoiceTableData>[] => {
  return [
    {
      name: 'Vendor',
      cell: (invoice: InvoiceTableData) => <VendorContainer {...{ invoice }} />,
      selector: (invoice: InvoiceTableData) => invoice.vendor,
      sortable: true,
      grow: 2,
    },
    {
      name: 'Amount',
      format: (invoice: InvoiceTableData) =>
        formatCurrency(invoice.amount ?? 0),
      selector: (invoice: InvoiceTableData) => invoice.amount,
      sortable: true,
    },
    {
      name: 'Creation date',
      selector: (invoice: InvoiceTableData) => invoice.createdAt,
      sortFunction: (a: InvoiceTableData, b: InvoiceTableData) =>
        sortDate(a.createdAt, b.createdAt),
      sortable: true,
    },
    {
      name: 'Scheduled date',
      selector: (invoice: InvoiceTableData) => invoice?.scheduleDate,
      sortFunction: (a: InvoiceTableData, b: InvoiceTableData) =>
        sortDate(a.scheduleDate, b.scheduleDate),
      sortable: true,
      omit: tableType !== 'SCHEDULED',
    },
    {
      name: 'Due date',
      selector: (invoice: InvoiceTableData) => invoice.dueDate,
      sortFunction: (a: InvoiceTableData, b: InvoiceTableData) =>
        sortDate(a.dueDate, b.dueDate),
      sortable: true,
    },
    {
      name: 'Paid date',
      selector: (invoice) => invoice.paidDate,
      sortFunction: (a: InvoiceTableData, b: InvoiceTableData) =>
        sortDate(a.paidDate, b.paidDate),
      sortable: true,
      omit: tableType !== 'PAID',
    },
    {
      name: 'Invoice status',
      cell: (invoice: InvoiceTableData) => <BillStatusBadge {...{ invoice }} />,
      selector: (invoice: InvoiceTableData) => invoice.invoiceStatus,
      sortable: true,
    },
    {
      name: 'Approval',
      cell: (invoice: InvoiceTableData) => (
        <BillApprovalBadge {...{ invoice }} />
      ),
      selector: (invoice: InvoiceTableData) => invoice.status,
      sortable: true,
    },
  ];
};

const BillPayTable = ({
  invoices,
  dataLoaded,
  useFilters,
  withActions,
  mercoaToken,
  headingText,
  actionButtonText,
  tableType = 'ALL',
}: BillPayTableProps) => {
  const columns = getColumns(tableType);
  const { openTransparentModal, closeAllModals } = useModal();

  const filteredInvoices = mappedInvoices(invoices).filter((invoice) =>
    useFilters.activeFiltersArray.every((filter) => filter.fn(invoice)),
  );

  const handleClickRow = (invoice: InvoiceTableData) => {
    openTransparentModal(
      <AddBill
        invoice={invoice.invoice}
        mercoaToken={mercoaToken}
        closeModal={closeAllModals}
      />,
    );
  };

  if (!dataLoaded) {
    return <SkeletonLoading />;
  }

  if (invoices.length === 0) {
    return (
      <EmptyBillTable
        {...{ headingText, mercoaToken, withActions, actionButtonText }}
      />
    );
  }

  return (
    <>
      <BillPayHeader
        mercoaToken={mercoaToken}
        addFilter={useFilters.addFilter}
        removeFilter={useFilters.removeFilter}
        searchValue={useFilters.getFilterByKey('search')?.filterValue || ''}
      />
      <FlexbaseTable
        columns={columns}
        data={filteredInvoices}
        onRowClicked={handleClickRow}
      />
    </>
  );
};

export default BillPayTable;
