import { ReactNode } from 'react';
import OnboardingTitleGroup from './onboarding-title-group';
import OnboardingButtonGroup from './onboarding-button-group';
import { Box, Loader, ScrollArea } from '@mantine/core';
import { useOnboardingStyles } from '../onboarding.styles';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  ProductNavStack,
  ProductOnboardingBtnLoaderState,
} from '../../../states/application/product-onboarding';
import { RightArrow } from '../../../assets/svg';
import { useNavigate } from 'react-router-dom';
import { last } from 'underscore';
import { useMediaQuery } from '@mantine/hooks';

type Props = {
  children: ReactNode;
  title: string;
  subtitle?: string | ReactNode;
  showNext?: boolean;
  showBack?: boolean;
  onNextClick?: () => void;
  onBackClick?: () => void;
  nextLabel?: ReactNode;
  backLabel?: ReactNode;
  stepId: string;
  error?: ReactNode;
  infoText?: ReactNode;
  showContinueSpinner?: boolean;
  image?: ReactNode;
  bottomDisclosureText?: string | ReactNode;
  disableNext?: boolean;
  disableBack?: boolean;
  disableAll?: boolean;
  width?: string | number;
};

const OnboardingStep = ({
  children,
  onNextClick,
  onBackClick,
  title,
  subtitle = '',
  showBack = true,
  showNext = true,
  nextLabel = 'Continue',
  backLabel = 'Back',
  stepId,
  error,
  infoText,
  showContinueSpinner = false,
  image,
  bottomDisclosureText,
  disableAll = false,
  disableBack = false,
  disableNext = false,
  width = 525,
}: Props) => {
  const loading = useRecoilValue(ProductOnboardingBtnLoaderState);
  const [navStack, setNavStack] = useRecoilState(ProductNavStack);
  const navigate = useNavigate();
  const { classes, cx } = useOnboardingStyles();

  const useFullWidthStep = useMediaQuery('(max-width: 767px)');

  const skipToCurrentStep = () => {
    const lastStep = last(navStack);
    setNavStack([]);
    // This truthy check is here because of TypeScript. If this function is able to be clicked, there will always be a value in navStack.
    if (lastStep) {
      navigate(`../${lastStep}`);
      window.scroll(0, 0);
    }
  };

  return (
    <Box w={useFullWidthStep ? '100%' : width}>
      <OnboardingTitleGroup
        titleText={title}
        subtitleText={subtitle}
        image={image}
      />
      <div
        className={cx(
          classes.stepContentContainer,
          error && classes.stepContentContainerError,
        )}
      >
        {children}
      </div>
      {infoText && (
        <div className={cx(classes.stepText, classes.stepInfoText)}>
          {infoText}
        </div>
      )}
      {error && (
        <div className={cx(classes.stepText, classes.stepErrorText)}>
          {error}
        </div>
      )}
      <OnboardingButtonGroup
        onNextClick={onNextClick}
        onBackClick={onBackClick}
        showBackButton={showBack}
        showNextButton={showNext}
        backButtonLabel={backLabel}
        nextButtonLabel={
          <>
            {(showContinueSpinner || loading) && (
              <Loader
                color="white"
                size="sm"
                style={{ position: 'absolute' }}
              />
            )}
            <Box c={showContinueSpinner || loading ? 'transparent' : undefined}>
              {nextLabel}
            </Box>
          </>
        }
        disableAll={showContinueSpinner || loading || disableAll}
        disableNext={disableNext}
        disableBack={disableBack}
      />
      {navStack.length > 0 && (
        <div
          className={cx(
            classes.flavorText,
            classes.rightAlign,
            classes.pointer,
            classes.actionOpacity,
          )}
          onClick={skipToCurrentStep}
        >
          Skip to current step
          <RightArrow height={8} />
        </div>
      )}
      {bottomDisclosureText && stepId !== 'verify-phone' ? (
        <ScrollArea
          h={118}
          className={cx(
            classes.applicationStepBottomDisclosureScroll,
            classes.scrollDisclsoureBackground,
          )}
        >
          {bottomDisclosureText}
        </ScrollArea>
      ) : (
        <div className={cx(classes.applicationStepBottomDisclosure)}>
          {bottomDisclosureText}
        </div>
      )}
    </Box>
  );
};

export default OnboardingStep;
