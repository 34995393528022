import { Alert, Divider, Progress, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { RedAlertIcon } from 'assets/svg';
import { useEffect, useState } from 'react';
import { CardByUser, DepositAccount } from 'services/flexbase/banking.model';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { capitalizeOnlyFirstLetter } from 'utilities/formatters/format-strings';
import Actions from './actions';
import BillingAddress from './billing-address';
import DebitCard from './debit-card';
import { useStyles } from './debit-card-details.styles';
import TerminatedDebitCard from './terminated-debit-card';
import UpdateDebitCard from './update-debit-card';
import { getATMSpendLimitByTier, getTier } from '../util';
import { ModalHeaderBar } from '@common/modal/modal-header-bar';
import { useGetDepositAccounts } from 'queries/use-deposit-accounts';

type Props = {
  card: CardByUser;
};

const DebitCardDetails = ({ card }: Props) => {
  const [error, setError] = useState(false);
  const { classes, theme } = useStyles();
  const [editCard, setEditCard] = useState(false);
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [cardStatus, setCardStatus] = useState(card.status);
  const { data } = useGetDepositAccounts();
  const depositAccounts = data?.accounts || [];

  const limitType =
    card?.expensesTypes && Object.keys(card?.expensesTypes).length > 0
      ? card.expensesTypes.dailyPurchase
        ? 'daily'
        : 'monthly'
      : 'unlimited';

  const cardLimit =
    card.expensesTypes?.dailyPurchase ??
    card.expensesTypes?.monthlyPurchase ??
    0;

  const [cardData, setCardData] = useState({
    ...card,
    limit: cardLimit,
    limitType: limitType,
  });

  const displayPercent = Math.round(
    (card.monthToDateSpends?.mtdSpend * 100) / cardData.limit,
  );
  const currentDailyWithdrawalLimit =
    cardData.expensesTypes?.dailyWithdrawal ??
    getATMSpendLimitByTier(depositAccounts, card.depositAccountId);

  const getDepositAccountName = (
    accts: DepositAccount[],
    currentId: string,
  ) => {
    const { nickName, name } = accts.find(({ id }) => id === currentId) ?? {};
    return nickName || name || '';
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (error) {
        setError(false);
      }
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [error]);

  if (cardStatus === 'terminated') {
    return (
      <TerminatedDebitCard
        card={card}
        cardStatus={cardStatus}
        limit={cardData.limit}
        limitType={limitType}
      />
    );
  }

  const getCurrentLimit = () => {
    switch (cardData.limitType) {
      case 'daily':
        return cardData.expensesTypes?.dailyPurchase ?? 0;
      case 'monthly':
        return cardData.expensesTypes?.monthlyPurchase ?? 0;
      default:
        return 0;
    }
  };

  const footerData = [
    {
      title: 'Account',
      value: getDepositAccountName(depositAccounts, card.depositAccountId),
      isVisible: true,
    },
    {
      title: 'Card type',
      value: card.cardSubtype.includes('Virtual') ? 'Virtual' : 'Physical',
      isVisible: true,
    },
    {
      title: 'Daily ATM withdrawal limit',
      value: formatCurrency(currentDailyWithdrawalLimit),
      isVisible: !card.cardSubtype.includes('Virtual'),
    },
    {
      title:
        cardData.limitType !== 'unlimited'
          ? `${capitalizeOnlyFirstLetter(cardData.limitType)} spending limit`
          : 'Spending limit',
      value:
        cardData.limitType !== 'unlimited'
          ? formatCurrency(getCurrentLimit())
          : 'Unlimited',
      isVisible: true,
    },
  ];

  return (
    <div className={classes.cardDetails}>
      <div style={{ opacity: editCard ? 0.5 : 1 }}>
        <ModalHeaderBar
          title={cardData.cardName || null}
          rightTitle={formatCurrency(card.monthToDateSpends?.mtdSpend)}
          subtitle={card.holder}
          rightSubtitle="Spent this month"
        />

        {cardData.limitType !== 'unlimited' && (
          <div className={classes.padding}>
            <Progress
              h={5}
              my={20}
              value={displayPercent}
              color={theme.fn.themeColor('primarySecondarySuccess', 2)}
              bg={theme.colors.contentBackground[0]}
            />
            <div className={classes.rowContent}>
              <Text
                color="primarySecondarySuccess.8"
                size={14}
                fw={500}
                align="right"
              >
                {formatCurrency(cardData.limit)} {cardData.limitType} spending
                limit
              </Text>
            </div>
          </div>
        )}

        <DebitCard {...{ card, cardStatus }} />
      </div>

      {editCard ? (
        <>
          <Divider className={classes.divider} />
          <UpdateDebitCard
            {...{
              cardData,
              setCardData,
              setEditCard,
            }}
            accountTier={getTier(depositAccounts, card.depositAccountId)}
            atmWithdrawalLimit={currentDailyWithdrawalLimit}
          />
        </>
      ) : (
        <>
          <Actions
            {...{
              card,
              setError,
              cardStatus,
              setEditCard,
              setCardStatus,
            }}
          />

          {card.address && (
            <>
              <Divider className={classes.divider} />
              <BillingAddress card={card} />
            </>
          )}

          <Divider className={classes.divider} />
          <div className={classes.padding}>
            {footerData.map(
              ({ title, value, isVisible }) =>
                isVisible && (
                  <div
                    className={`${classes.rowContent} ${classes.footerItem}`}
                    key={title}
                  >
                    <Text size={14}>{title}</Text>
                    <Text size={14}>{value}</Text>
                  </div>
                ),
            )}
          </div>
        </>
      )}

      {error && (
        <Alert
          color="red"
          mt={20}
          mx={useMobileView ? 20 : 50}
          icon={<RedAlertIcon />}
        >
          Unable to update card status. Please try again.
        </Alert>
      )}
    </div>
  );
};

export default DebitCardDetails;
