import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  baseContainer: {
    width: '100%',
    height: '100%',
    paddingLeft: 32,
    paddingRight: 32,
    '@media(max-width: 767px)': {
      padding: 0,
    },
  },
  container: {
    backgroundColor: 'white',
    padding: '1.5rem',
    borderRadius: 2,
  },
  widgetContainer: {
    borderRadius: '0.5rem',
    flexDirection: 'row',
    backgroundColor: 'white',
    '@media(max-width: 767px)': {
      width: '100%',
    },
  },
  who: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    wordWrap: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '14px',
  },
  whoIcon: {
    fontSize: '20px',
    color: theme.fn.themeColor('primarySecondarySuccess', 8),
    '> div:first-of-type': {
      color: `${theme.fn.primaryColor()} !important`,
      backgroundColor: '#F6F3EE !important',
      fontWeight: 500,
      fontFamily: 'PP Neue Montreal',
    },
    marginRight: '10px',
  },
  pendingInvitations: {
    color: theme.fn.primaryColor(),
    fontSize: '14px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '16px',
    background: '#FFF',
    height: '40px',
    border: `1px solid ${theme.fn.primaryColor()}`,
    borderRadius: '8px',
    '&:hover': {
      background: 'rgba(255, 87, 69, 0.08);',
      color: theme.fn.primaryColor(),
    },
    padding: '12px 12px 10px 12px',
    marginRight: 10,
  },
  invitePeople: {
    color: theme.fn.primaryColor(),
    fontSize: '14px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '16px',
    background: '#FFF',
    width: 'auto',
    height: '40px',
    border: `1px solid ${theme.fn.primaryColor()}`,
    borderRadius: '8px',
    '&:hover': {
      background: 'rgba(255, 87, 69, 0.08);',
      color: theme.fn.primaryColor(),
    },
    padding: '12px 12px 12px 12px',
  },
  dropdown: {
    position: 'fixed',
    '@media(max-width: 767px)': {
      position: 'absolute',
    },
  },
  statusColumn: {
    padding: '1px 12px 1px 12px',
    textAlign: 'center',
    borderRadius: '25px',
    backgroundColor: theme.colors.flexbaseGrey[0],
    fontWeight: 500,
    color: 'black',
    '@media(max-width: 767px)': {
      width: '100%',
    },
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  redStatusColumn: {
    padding: '1px 12px 1px 12px',
    textAlign: 'center',
    borderRadius: '25px',
    backgroundColor: theme.colors.critical[1],
    fontWeight: 500,
    color: 'black',
    '@media(max-width: 767px)': {
      width: '100%',
    },
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  icon: {
    transition: '0.2s ease-out',
    transform: 'rotate(-180deg)',
  },
  customSelect: {
    border: `1px solid ${theme.colors.flexbaseGrey[0]}`,
    width: '90%',
    padding: 8,
    borderRadius: 2,
    fontWeight: 500,
    fontSize: 14,
    fontFamily: 'PP Neue Montreal',
  },
  selectOption: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export const customMantineStyles = (primaryColor?: string) => {
  return {
    input: {
      color: '#5F5F5F',
      height: '40px',
      border: '1px solid #E6E7E9',
      borderRadius: '8px',
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 500,
      background: 'white',
      '&:focus': { borderColor: primaryColor },
    },
    label: {
      color: '#4B4B4B',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '19px',
    },
    item: {
      fontSize: 14,
      fontWeight: 500,
      color: '#5F5F5F',
      '&:hover': {
        border: 'none',
        background: 'rgba(255, 87, 69, 0.08);',
      },
    },
    selected: {
      fontSize: 14,
      fontWeight: 400,
      color: '#5F5F5F',
      background: 'rgba(255, 87, 69, 0.08);',
    },
    dropdown: {
      borderRadius: 8,
    },
  };
};
