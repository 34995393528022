import { SimpleMultiselectFilter } from '@common/filter/simple-multiselect.filter';
import { usePaymentFilters } from '../use-payment-filters';

const STATUS_MULTISELECT_OPTIONS = [
  {
    label: 'Pending',
    filterCriteria: ['Pending'],
    key: 'pending',
  },
  { label: 'Clearing', filterCriteria: 'Clearing', key: 'clearing' },
  {
    label: 'Canceled',
    filterCriteria: ['Canceled'],
    key: 'canceled',
  },
  {
    label: 'Completed',
    filterCriteria: ['Completed'],
    key: 'completed',
  },
  {
    label: 'Approval Needed',
    filterCriteria: ['Approval Needed'],
    key: 'approvalNeeded',
  },
  {
    label: '2FA Required',
    filterCriteria: ['2FA Required'],
    key: 'awaitingconfirmation',
  },
  {
    label: 'Scheduled',
    filterCriteria: ['Scheduled'],
    key: 'scheduled',
  },
  {
    label: 'Failed',
    filterCriteria: 'Failed',
    key: 'failed',
  },
  {
    label: 'Returned',
    filterCriteria: ['Returned'],
    key: 'returned',
  },
];

export const PaymentStatusFilter = () => {
  const paymentFilters = usePaymentFilters();

  return (
    <SimpleMultiselectFilter
      filterHook={paymentFilters}
      filterKey="status"
      options={STATUS_MULTISELECT_OPTIONS}
      label="Status"
      includesValue={(row) => row.status}
    />
  );
};
