import { createStyles, rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  optionPaymentBox: {
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
    borderRadius: theme.defaultRadius,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '70px',
    padding: '20px',
  },
  enabled: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.fn.themeColor('neutral', 2),
    },
  },
  disclaimer: {
    marginTop: theme.spacing.md,
    fontWeight: 400,
    fontSize: rem(12),
    color: theme.colors.primarySecondarySuccess[8],
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: 0.5,
  },
  optionText: {
    color: theme.fn.primaryColor(),
    fontSize: '16px',
    fontWeight: 500,
  },
  optionInfoContainer: {
    color: theme.fn.primaryColor(),
    fontWeight: 400,
    display: 'flex',
    fontSize: 14,
  },
  optionSpeed: {
    color: theme.fn.primaryColor(),
    fontWeight: 400,
    fontSize: 12,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  priceText: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.fn.themeColor('primarySecondarySuccess', 1),
  },
  lightText: {
    color: theme.fn.themeColor('neutral', 0),
  },
  circle: {
    width: '24px',
    height: '24px',
    display: 'flex',
    borderRadius: '100%',
    alignContent: 'center',
    justifyContent: 'center',
    color: theme.fn.primaryColor(),
    backgroundColor: theme.fn.themeColor('neutral', 0),
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
  },
  recipientRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px',
    border: `1px solid ${theme.colors.flexbaseGrey[0]}`,
    borderRadius: '2px',
    cursor: 'pointer',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: theme.fn.themeColor('neutral', 2),
    },
  },
  containerIconName: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    color: '#5F5F5F',
    fontSize: '14px',
    fontWeight: 400,
    marginLeft: '0.5rem',
  },
  selectList: {
    zIndex: 1,
    position: 'absolute',
    maxHeight: '250px',
    width: '356px',
    overflow: 'scroll',
    marginTop: 4,
    backgroundColor: 'white',
    boxShadow: theme.shadows.sm,
    borderRadius: theme.defaultRadius,
  },
  containerIcon: {
    borderRadius: '2px',
    div: {
      backgroundColor: 'transparent',
    },
  },
  newAccount: {
    marginTop: '0.5rem',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19px',
    cursor: 'pointer',
    color: theme.fn.themeColor('primarySecondarySuccess', 2),
  },
}));
