import {
  FlexbaseTableBulkActionsConfig,
  FlexbaseTableSelectedState,
} from '@common/table/flexbase-table.models';
import {
  ExpenseLink,
  ExpensesStatusEnum,
  SyncedExpenses,
  Transactions,
} from '@flexbase-eng/types/dist/accounting';
import { DownloadIcon, ShuffleIcon } from 'assets/svg';
import { CreditTransactionsTableRow } from '../credit-transactions-table-helpers';
import { modals } from '@mantine/modals';
import { BulkCategorizeTransactions } from '../../bulk-categorize-transactions/bulk-categorize-transactions';
import { useSyncExpenseAllowed } from '@utilities/integrations/accounting';

type SyncTransactionsResult = {
  success: boolean;
  error?: Error;
  data: { transactionId: string; success: boolean }[];
};

type Props<T> = {
  expenseLink: ExpenseLink | undefined;
  syncLoading: boolean;
  syncedExpenses: SyncedExpenses | undefined;
  onBulkCategorizeConfirm: (args: {
    transactionsToSync: Transactions[];
    options?: {
      successMessage?: ({
        results,
      }: {
        results: SyncTransactionsResult[];
      }) => string;
      partialSuccessMessage?: ({
        results,
      }: {
        results: SyncTransactionsResult[];
      }) => string;
    };
  }) => Promise<void>;
  onDownloadCsvClick: (state: FlexbaseTableSelectedState<T>) => void;
};

export function useCreditTransactionsBulkActions({
  expenseLink,
  syncLoading,
  syncedExpenses,
  onBulkCategorizeConfirm,
  onDownloadCsvClick,
}: Props<CreditTransactionsTableRow>) {
  const isSyncExpenseAllowed = useSyncExpenseAllowed();

  const bulkActionsConfig: FlexbaseTableBulkActionsConfig<CreditTransactionsTableRow> =
    {
      label: {
        singular: 'transaction',
        plural: 'transactions',
      },
      actions: [
        {
          key: 'categorize',
          hide: !isSyncExpenseAllowed || !expenseLink?.enabledExpenses,
          selectionFilter: (row) =>
            !row.syncedExpense?.lock &&
            !!syncedExpenses?.expenses.find(
              (e) =>
                e.transactionId === row.id &&
                e.status !== ExpensesStatusEnum.Queued,
            ),
          label: (state) => {
            return state.selectedCount
              ? `Categorize (${state.selectedCount})`
              : `Categorize`;
          },
          icon: <ShuffleIcon width={20} />,
          loading: syncLoading,
          disabled: (state) => !state.selectedCount,
          onClick: (state) => {
            if (!expenseLink) {
              return;
            }

            const count = state.selectedCount;
            const getLabel = (c: number) =>
              c === 1 ? 'transaction' : 'transactions';

            const closer = {
              disabled: false,
              handleClose: () => !closer.disabled && modals.closeAll(),
            };

            modals.open({
              title: `Categorize ${count} ${getLabel(count)}`,
              closeButtonProps: {
                onClick: closer.handleClose,
              },
              closeOnClickOutside: false,
              closeOnEscape: false,
              children: (
                <BulkCategorizeTransactions
                  count={count}
                  expenseLink={expenseLink}
                  onClose={closer.handleClose}
                  onConfirm={async ({ categoryId }) => {
                    closer.disabled = true;
                    const transactionsToSync =
                      state.selectedRows.map<Transactions>((row) => ({
                        transactionId: row.id,
                        accountId: categoryId,
                      }));

                    await onBulkCategorizeConfirm({
                      transactionsToSync,
                      options: {
                        successMessage: ({ results }) => {
                          const successTotal = results
                            .filter((r) => r.success)
                            .reduce((sum, r) => sum + r.data.length, 0);
                          const label = getLabel(successTotal);
                          return `${successTotal} ${label} categorized.`;
                        },
                        partialSuccessMessage: ({ results }) => {
                          const successTotal = results
                            .filter((r) => r.success)
                            .reduce((sum, r) => sum + r.data.length, 0);
                          const failureTotal = results
                            .filter((r) => !r.success)
                            .reduce((sum, r) => sum + r.data.length, 0);
                          return `${successTotal} ${getLabel(
                            successTotal,
                          )} categorized, ${failureTotal} ${getLabel(
                            failureTotal,
                          )} failed. Please try again later.`;
                        },
                      },
                    });
                    closer.disabled = false;
                  }}
                />
              ),
              styles: {
                content: {
                  // mantine modals force overflow: auto to handle tall modals,
                  // since we know this is a short modal, we can override
                  overflow: 'visible !important',
                },
              },
            });
          },
        },
        {
          key: 'download',
          label: 'Download selected',
          icon: <DownloadIcon width={20} />,
          onClick: (state) => {
            onDownloadCsvClick(state);
          },
        },
      ],
    };

  return {
    bulkActionsConfig,
  };
}
