import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'states/application/product-onboarding';

const ProductNavigator = () => {
  const { productStatus } = useRecoilValue(ApplicationState);
  if (
    productStatus.credit.status === 'unused' ||
    productStatus.credit.status === 'denied' ||
    productStatus.credit.status === 'unqualified' ||
    productStatus.credit.status === 'restricted'
  ) {
    return <Navigate to="/banking" />;
  } else {
    return <Navigate to="/cards" />;
  }
};

export default ProductNavigator;
