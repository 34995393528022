/**
 * This function uses HTML5 measureText function to calculate the width (in px) of a given text string with a given font style
 * @param text The text that you want to calculate the width for.
 * @param font Font string used in calculations. Ex: 2rem | 400 2rem | 400 2rem redaction
 */
export function getTextWidth(text: string, font: string) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (context) {
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }

  return -1;
}
