import { useRecoilValue } from 'recoil';
import {
  ApplicationState,
  IsAdmin,
  IsFullAdmin,
} from 'states/application/product-onboarding';
import { useIntlPaymentsFeatureFlag } from 'utilities/feature-flags';

export const useShowInternationalPaymentsCTA = () => {
  const internationalPaymentsFeatureFlagIsOn = useIntlPaymentsFeatureFlag();
  const isFullAdmin = useRecoilValue(IsFullAdmin);
  const isLimitedAdmin = useRecoilValue(IsAdmin);

  const { productStatus } = useRecoilValue(ApplicationState);
  const individualStatus = productStatus.internationalPayments.status;
  const intlWireStatus = productStatus.internationalPayments.appStatus;

  const isLimitedAdminThatNeedsToCompleteApplication =
    isLimitedAdmin &&
    intlWireStatus === 'approved' &&
    individualStatus === 'incomplete';

  return (
    internationalPaymentsFeatureFlagIsOn &&
    (isFullAdmin || isLimitedAdminThatNeedsToCompleteApplication)
  );
};
