import { useState, useMemo } from 'react';
import { Text, Box, Button, Select } from '@mantine/core';
import { Employees } from 'states/onboarding/onboarding-info';
import { modals } from '@mantine/modals';
import { useStyles } from './overview.styles';
import {
  ApplicationState,
  getProductOnboardingStatus,
} from 'states/application/product-onboarding';
import { useSetRecoilState } from 'recoil';
import { useUpdateControlPersonFn } from '@queries/use-company';

type ControlPerson = {
  employees: Employees[] | undefined;
  isLoading: boolean;
};

const ControlPersonMenu = ({ employees }: ControlPerson) => {
  const { mutate, isPending } = useUpdateControlPersonFn();
  const { classes } = useStyles();
  const setApplicationState = useSetRecoilState(ApplicationState);

  const [controlPersonId, setControlPersonId] = useState('');

  const dataResult =
    useMemo(
      () =>
        employees
          ?.filter((item) => !item.promoCodeId && item.roles?.includes('ADMIN'))
          .map((item) => {
            return {
              value: item.id || '',
              label: `${item.firstName} ${item.lastName}`,
            };
          }),
      [employees],
    ) || [];

  const onConfirm = () => {
    mutate(controlPersonId, {
      onSuccess: async () => {
        const status = await getProductOnboardingStatus(false);
        setApplicationState(status);
        modals.closeAll();
      },
    });
  };

  return (
    <Box>
      <Text size="sm" mb="xl">
        Please select one individual with significant responsibility for
        managing the legal entity applying for this account, such as a: Chief
        Executive Officer, Chief Financial Officer, Chief Operating Officer,
        Managing Member, General Partner, President, or an individual who
        regularly performs similar functions.
      </Text>

      <Select
        mt="sm"
        label="Select control person"
        data={dataResult}
        onChange={(value: string) => {
          setControlPersonId(value);
        }}
      />
      <Button
        classNames={{ root: classes.controlPersonBtnModal }}
        disabled={isPending || !controlPersonId}
        loading={isPending}
        onClick={onConfirm}
      >
        Confirm
      </Button>
    </Box>
  );
};

export default ControlPersonMenu;
