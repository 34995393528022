import { showNotification } from '@mantine/notifications';
import { platformClient } from '@services/platform/platform-client';
import { useMutation } from '@tanstack/react-query';

type CreateExpenseReceiptArgs = {
  connectionId: string;
  invoiceId: string;
  file: File;
};

export function useCreateExpenseReceipt() {
  return useMutation({
    mutationFn: (props: CreateExpenseReceiptArgs) => {
      return platformClient.createExpenseReceipt(props);
    },
    onError: (error) => {
      showNotification({
        color: 'red',
        title: 'Error uploading receipt',
        message: `${error.message}`,
      });
    },
  });
}
