import { platformClient } from '@services/platform/platform-client';
import {
  PLATFORM_CLIENT_ID,
  ProductApplicationRoutes,
} from '../../onboarding.constants';
import { formatPhoneForApi } from '@flexbase-eng/web-components';
import { flexbaseOnboardingClient } from '@services/flexbase-client';
import { ReactNode, useState } from 'react';
import { UseFormReturnType } from '@mantine/form';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  PromoCodeState,
  RegistrationProductsState,
} from '../../../../states/application/product-onboarding';
import { PlatformAccountState } from '../../../../states/account/account.state';
import { useAuthToken } from '../../../../states/auth/auth-token';
import { useRouteSectionContext } from '@common/routes/route-context';
import { formatDateForApi } from '@utilities/formatters/format-date-input';
// import { FullStory } from '@fullstory/browser';
// import { getEnvironment } from 'utilities/url/window-helpers';
import { platformAuthClient } from '@services/platform/platform-auth-client';
import {
  IsFlexHttpError,
  IsSuccessResponse,
} from '@services/platform/models/authorize.models';
import { PasswordError } from '../../../login/password-error';

export type RegistrationFormReturnType = {
  firstName?: string;
  lastName?: string;
  email: string;
  cellphone: string;
  password: string;
  confirmPassword?: string;
  is18?: boolean;
  termsOfServiceSigned: boolean;
  birthday?: string;
};

export const useRegistration = (
  form: UseFormReturnType<RegistrationFormReturnType>,
  options?: Partial<{ usePrefillApplication: boolean }>,
) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ReactNode>('');
  const { externalId } = useRecoilValue(PromoCodeState);
  const [platformAccount, setPlatformAccount] =
    useRecoilState(PlatformAccountState);
  const product = useRecoilValue(RegistrationProductsState);
  const { signIn } = useAuthToken();
  const { setSectionAndNavigate } = useRouteSectionContext();
  const { code } = useRecoilValue(PromoCodeState);
  const isBankingOnly = product.every((p) => p === 'BANKING');
  const isCreditOnly = product.every((p) => p === 'CREDIT');
  // const hostEnv = getEnvironment(window.location.host);

  const registerNewUser = async () => {
    const validationResult = form.validate();

    if (!validationResult.hasErrors) {
      setLoading(true);
      const formValues = form.values;
      try {
        let personId: string;
        let accountId: string;

        if (!platformAccount) {
          const createAccountResponse = await platformAuthClient.createAccount({
            userName: formValues.email,
            password: formValues.password,
            client_id: PLATFORM_CLIENT_ID,
            name:
              formValues.firstName && formValues.lastName
                ? `${formValues.firstName} ${formValues.lastName}`
                : formValues.email,
            ...(code && { promoCode: code }),
          });

          personId = createAccountResponse.body!.personId;
          accountId = createAccountResponse.body!.accountId;

          setPlatformAccount({
            accountId: accountId,
            personId: personId,
            businessId: '',
          });
        } else {
          accountId = platformAccount.accountId;
          personId = platformAccount.personId;
        }

        const loginAttempt = await platformAuthClient.requestTokenByPassword(
          formValues.email,
          formValues.password,
        );

        if (IsSuccessResponse(loginAttempt) && loginAttempt.body) {
          signIn(loginAttempt.body, {
            email: formValues.email,
            remember: true,
          });
        } else {
          throw new Error('Token request failed');
        }

        let businessId: string;

        if (platformAccount?.businessId) {
          businessId = platformAccount.businessId;
        } else {
          const business = await platformClient.createBusiness(accountId, {
            active: true,
            name: 'My Company',
          });
          businessId = business.id;
          setPlatformAccount((prev) => {
            if (prev) {
              return { ...prev, businessId };
            }
          });
        }

        // full story
        /*
        if (hostEnv === 'production') {
          
          try {
            FullStory('setIdentityAsync', {
              uid: personId,
              properties: {
                companyId: businessId,
                accountId,
                email: formValues?.email,
              },
            });
          } catch (err) {
            console.error(`unable to load full story. ${err}`);
          }
        }
        */

        const hasPersonUpdate =
          !!formValues.birthday ||
          !!formValues.firstName ||
          !!formValues.lastName;

        await Promise.all([
          platformClient.createPersonPhone(accountId, personId, {
            value: '+1' + formatPhoneForApi(formValues.cellphone),
            isPrimary: true,
            type: 'mobile',
            active: true,
          }),
          hasPersonUpdate
            ? platformClient.updatePerson(accountId, personId, {
                givenName: formValues.firstName,
                familyName: formValues.lastName,
                ...(formValues.birthday && {
                  dateOfBirth: formatDateForApi(formValues.birthday),
                }),
              })
            : Promise.resolve(),
          flexbaseOnboardingClient.updateCompany({
            id: businessId,
            optedProducts: product,
            ...(externalId ? { trinetId: externalId } : {}),
          }),
          flexbaseOnboardingClient.updateUser({
            id: personId,
            termsOfServiceSigned: formValues.termsOfServiceSigned,
          }),
        ]);

        let applicationUrl: string;

        if (options?.usePrefillApplication) {
          applicationUrl = '/prefill';
        } else if (isBankingOnly) {
          applicationUrl = ProductApplicationRoutes.BANKING_ONLY;
        } else if (isCreditOnly) {
          applicationUrl = ProductApplicationRoutes.CREDIT_ONLY;
        } else {
          applicationUrl = ProductApplicationRoutes.COMBINED_CREDIT_BANKING;
        }

        setSectionAndNavigate('application', applicationUrl);
      } catch (e) {
        if (IsFlexHttpError(e)) {
          if (e.statusCode === 409) {
            setErrorMessage('An account with this username already exists.');
          } else if (e.errors?.promoCode) {
            setErrorMessage(
              'Invalid referral URL. Referral codes are case sensitive. Please ensure that the URL exactly matches the one provided.',
            );
          } else if (e.errors?.password) {
            const errors = e.errors.password;

            if (Array.isArray(errors)) {
              setErrorMessage(<PasswordError errors={errors} />);
            } else {
              setErrorMessage(
                'This password you attempted to use is invalid. Please use another password.',
              );
            }
          }
        } else {
          setErrorMessage('An error occurred while creating your account.');
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return { loading, errorMessage, registerNewUser, setErrorMessage };
};
