import { useQuery } from '@tanstack/react-query';
import { flexbaseBankingClient } from 'services/flexbase-client';
import { BankingParameters } from '@services/flexbase/banking.model';

const QUERY_KEY = 'checkDeposits';

export const useGetCheckDeposits = (params?: BankingParameters) => {
  return useQuery({
    queryKey: [QUERY_KEY, 'list', params],
    queryFn: async () => {
      const result = await flexbaseBankingClient.getCheckDepositsList(params);
      return result.checkDeposits ?? [];
    },
    meta: {
      errorMessage: 'Unable to retrieve check deposits at this time.',
    },
  });
};

export const useGetCheckDepositInfo = (id: string) => {
  return useQuery({
    queryKey: [QUERY_KEY, 'details', id],
    queryFn: async () => {
      const result = await flexbaseBankingClient.getCheckDepositData(id);
      return result.checkDeposit;
    },
    meta: {
      errorMessage: 'Unable to retrieve the check information at this time.',
    },
    enabled: !!id,
  });
};

export const useGetCheckDepositHistory = (id: string) => {
  return useQuery({
    queryKey: [QUERY_KEY, 'history', id],
    queryFn: async () => {
      return await flexbaseBankingClient.getCheckDepositHistory(id);
    },
    meta: {
      errorMessage:
        'Unable to retrieve the check history information at this time.',
    },
    enabled: !!id,
  });
};

export const useGetCheckDepositImage = (id: string, side: 'front' | 'back') => {
  return useQuery({
    queryKey: [QUERY_KEY, 'details', id, 'image', side],
    queryFn: async () => {
      return flexbaseBankingClient.getCheckDepositImage(id, side);
    },
    meta: {
      errorMessage: `Unable to retrieve the ${side} image of the check at this time.`,
    },
    enabled: !!id,
  });
};
