import { useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Avatar,
  Badge,
  Box,
  Flex,
  Loader,
  Progress,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useStyles } from '../styles';
import useModal from 'components/modal/modal-hook';
import FlexbaseTable from 'components/table/flexbase-table';
import { getInitialsOfNames } from 'utilities/extensions/object';
import { formatCurrency } from 'utilities/formatters/format-currency';
import CardDetails from './card-details/card-details';
import { IoMdInfinite } from 'react-icons/io';
import { FaCheck } from 'react-icons/fa';
import { useCreditCards } from '@queries/use-credit-cards';
import { TableColumn } from 'react-data-table-component';
import { capitalizeOnlyFirstLetter } from '@utilities/formatters/format-strings';
import getPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';
import { CreditCardsTableHeader } from './credit-cards-header/credit-cards-table-header';
import { useCreditCardsFilters } from './credit-cards-header/use-credit-cards-filters';
import { CreditCard } from '@services/flexbase/card.model';
import { useRecoilValue } from 'recoil';
import {
  ApplicationState,
  IsAccountant,
} from '../../../states/application/product-onboarding';
import { UserIdState } from '../../onboarding/onboarding-form.state';
import { useDisclosure } from '@mantine/hooks';
import { useGetSpendPlansByCardId } from '@queries/use-spend-plans';
import { useSpendPlansFeatureFlag } from '@utilities/feature-flags';
import DebitCardDetails from 'areas/banking/debit-cards/debit-card-details/debit-card-details';
import { useGetDebitCards } from '@queries/use-debit-cards';
import { CardTableRow } from './card-table-row.model';

const CardsTable = () => {
  const isAccountant = useRecoilValue(IsAccountant);
  const userId = useRecoilValue(UserIdState);
  const theme = useMantineTheme();
  const modal = useModal();
  const closeModal = modal.closeAllModals;
  const { classes } = useStyles();
  const [message, setMessage] = useState('');

  const { getFilterByKey, activeFiltersArray } = useCreditCardsFilters();
  const [cardId, setCardId] = useState('');

  const [clearSelectedRowsFlag, { toggle: clearSelectedRows }] =
    useDisclosure();
  // The API endpoint takes in a status, but it only works for a single status
  // at a time, while the UI is expected to filter on multiple statuses.
  const {
    data: cardsData,
    isLoading,
    isSuccess,
    refetch,
  } = useCreditCards(true, getFilterByKey('search')?.filterValue);

  const spendPlanFlagEnabled = useSpendPlansFeatureFlag();
  const { accountId } = useRecoilValue(ApplicationState);
  const { data: spendPlansByCardId = [], isLoading: spendPlanLoading } =
    useGetSpendPlansByCardId({
      enabled: spendPlanFlagEnabled,
      accountId: accountId,
      cardId: cardId,
    });
  const { data: debitCardsData } = useGetDebitCards();

  const companyCards: CardTableRow[] = useMemo(() => {
    if (!isSuccess) return [];
    // TODO - fix this to amountSpendThisPeriod once API provides support for it.

    return (cardsData ?? [])
      .map((cc) => {
        const amountSpentThisInterval = cc.toDateSpend?.toDateSpend ?? 0;
        const amountSpentThisMonth = cc.monthToDateSpends?.mtdSpend ?? 0;
        const utilization = cc.expensesTypes?.amount
          ? Math.round(
              (amountSpentThisInterval / cc.expensesTypes.amount) * 100,
            )
          : 0;

        return {
          ...cc,
          amountSpentThisMonth: amountSpentThisMonth,
          utilization,
        };
      })
      .filter((cc) => activeFiltersArray.every((filter) => filter.fn(cc)));
  }, [cardsData, activeFiltersArray]);

  const onCloseModal = () => {
    closeModal();
  };

  const addCompanyCard = () => {
    refetch();
  };

  const detailView = (card: CreditCard) => {
    if (
      card.cardSubtype === 'businessVirtualCreditCard' ||
      card.cardSubtype === 'businessPhysicalCreditCard'
    ) {
      const chargeCard = debitCardsData?.find((c) => c.id === card.id);
      if (chargeCard) {
        return <DebitCardDetails card={chargeCard} />;
      }
    }
    return <CardDetails passedCard={{ ...card }} />;
  };
  const onRowClicked = async (card: CreditCard) => {
    const openModal = () => {
      screen.width <= 767
        ? modal.openFullModal(detailView(card))
        : modal.openRightModalNoOpacity(detailView(card), {
            closeModal: () => {
              onCloseModal();
            },
          });
    };
    if (isAccountant) {
      if (card.userId === userId) {
        openModal();
      }
    } else {
      openModal();
    }
  };

  const cardStatus: any = {
    active: 'Active',
    suspended: 'Frozen',
    issued: 'Pending',
    requested: 'Pending',
    terminated: 'Canceled',
  };

  const badgeColor: any = {
    issued: '#fff',
    requested: '#fff',
    active: '#EEEEF3',
    terminated: '#EEEEF3',
    suspended: 'rgba(48, 44, 255, 0.1)',
  };

  const columns: TableColumn<CardTableRow>[] = [
    {
      name: 'hidden_date',
      selector: (row) => row.asOf,
      omit: true,
    },
    {
      name: 'Cardholder',
      cell: (row) => (
        <>
          {screen.width > 767 && (
            <Avatar radius="34px" className={classes.cardAvatar}>
              {getInitialsOfNames(row.holder)}
            </Avatar>
          )}
          <div className={classes.holderField}>{row.holder}</div>
          <Badge
            className={classes.badge}
            styles={{
              root: {
                border:
                  row.status === 'issued' || row.status === 'requested'
                    ? '1px solid #EEEEF3'
                    : 'unset',
                backgroundColor: badgeColor[row.status],
              },
              inner: { color: '#000 !important' },
            }}
          >
            {cardStatus[row.status]}
          </Badge>
        </>
      ),
      selector: (row) => row.holder,
      sortable: true,
      width: '350px',
    },
    {
      name: 'Card Name',
      selector: (row) => row.cardName,
      sortable: true,
    },
    {
      name: 'Credit Card',
      cell: (row) => (
        <Text>
          {row.status !== 'issued' && row.status !== 'requested' && row.last4
            ? getPaddedAccountMask(row.last4, 4)
            : ''}
        </Text>
      ),
      selector: (row) => row.last4,
      sortable: true,
    },
    {
      name: 'Spent This Month',
      cell: (row) => <div>{formatCurrency(row.amountSpentThisMonth ?? 0)}</div>,
      selector: (row) => row.amountSpentThisMonth ?? 0,
      sortable: true,
    },
    {
      name: 'Type',
      selector: (row) => capitalizeOnlyFirstLetter(row.cardType),
      sortable: true,
    },
    {
      name: 'Card Utilization',
      cell: (card) => {
        const renderUtilization = (value: number) => (
          <Flex w="100%" display="flex" align="center">
            <Progress
              style={{ width: '75%' }}
              value={value}
              color={theme.fn.primaryColor()}
            />
            <Box className={classes.utilizationField}>{value}%</Box>
          </Flex>
        );

        const renderLoader = () => (
          <Flex justify="center" w="100%">
            <Loader size="sm" />
          </Flex>
        );

        const renderInfiniteIcon = () => <IoMdInfinite size={30} />;

        const renderClickToView = () => (
          <Text onClick={() => setCardId(card.id)}>
            Click to view utilization
          </Text>
        );

        let utilization;

        switch (true) {
          case !!card.expensesTypes?.interval:
            utilization = renderUtilization(card.utilization || 0);
            break;
          case card.id === cardId:
            if (spendPlansByCardId.length > 0) {
              const utilizationValue =
                (spendPlansByCardId[0].spent / spendPlansByCardId[0].limit) *
                  100 || 0;
              utilization = renderUtilization(utilizationValue);
            } else if (spendPlanLoading) {
              utilization = renderLoader();
            } else {
              utilization = renderInfiniteIcon();
            }
            break;
          default:
            utilization = renderClickToView();
            break;
        }

        return utilization;
      },
      selector: (row) => row.utilization ?? 0,
      sortable: true,
    },
  ];

  useEffect(() => {
    // Table doesn't update the selection if data changes
    // so just clear the selection
    clearSelectedRows();
  }, [companyCards]);

  useEffect(() => {
    if (message !== '') {
      setTimeout(() => {
        setMessage('');
      }, 5000);
    }
  }, [message]);

  return (
    <Box
      sx={{
        maxWidth: '1307.5px',
        margin: 'auto',
      }}
    >
      <div className={classes.cardsContainer} data-testid="cards-table">
        {message && (
          <div className={classes.containerMessage}>
            <Alert icon={<FaCheck />} color={'green'}>
              <Text color="black">{message}</Text>
            </Alert>
          </div>
        )}
        <CreditCardsTableHeader
          cardsData={cardsData || []}
          onCardCreated={addCompanyCard}
        />
        <FlexbaseTable
          data={companyCards}
          columns={columns}
          onRowClicked={onRowClicked}
          defaultSortAsc={false}
          defaultSortFieldId="1"
          isFetchingData={isLoading}
          clearSelectedRows={clearSelectedRowsFlag}
        />
      </div>
    </Box>
  );
};

export default CardsTable;
