import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { upperFirst } from '@mantine/hooks';

export const useLocationAwareDocumentTitle = () => {
  const location = useLocation();

  useEffect(() => {
    const splitUrl = location.pathname
      .split('/')
      .filter((p) => !!p)
      .map((p) =>
        p.includes('-')
          ? p
              .split('-')
              .filter((s) => !!s)
              .map(upperFirst)
              .join(' ')
          : upperFirst(p),
      );
    document.title =
      splitUrl.length === 0
        ? 'Flex | Finance Super App'
        : `Flex | ${splitUrl.join(' - ')}`;
  }, [location]);
};
