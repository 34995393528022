import { useEffect } from 'react';
import { PlaidLinkOnSuccess, usePlaidLink } from 'react-plaid-link';

type LinkProps = {
  token?: string;
  onSuccess: PlaidLinkOnSuccess;
};

const PlaidLink = ({ token, onSuccess }: LinkProps) => {
  const { ready, open } = usePlaidLink({
    onSuccess: onSuccess,
    token: token ?? null,
  });

  useEffect(() => {
    if (open && ready) {
      open();
    }
  }, [open, ready]);

  return null;
};

export default PlaidLink;
