import { useState } from 'react';
import { CategoricalChartState } from 'recharts/types/chart/types';

export const useChartFilter = () => {
  const [chartFilterValue, setChartFilterValue] = useState('');

  const handleChartMouseMove = (params: CategoricalChartState) => {
    if (params.activePayload?.length) {
      setChartFilterValue(params.activePayload[0].payload.date);
    }
  };

  const handleChartMouseLeave = () => {
    setChartFilterValue('');
  };

  return {
    chartFilterValue,
    handleChartMouseMove,
    handleChartMouseLeave,
  };
};
