import { Group, Text, createStyles, rem } from '@mantine/core';
import { CloseIcon } from 'assets/svg';

type SpendPlanChipProps = {
  label: string;
  disabled?: boolean;
  onClick: () => void;
};

export type SpendPlanChipListItem = {
  label: string;
  value: string;
  disabled?: boolean;
  onClick: () => void;
};

type SpendPlanChipListProps = {
  items: SpendPlanChipListItem[];
};

export const SpendPlanChip = ({
  label,
  disabled,
  onClick,
}: SpendPlanChipProps) => {
  const { classes } = useSpendPlanChipStyles({ disabled });

  const handleClick = () => {
    if (disabled) {
      return;
    }

    onClick();
  };

  return (
    <Group spacing={4} onClick={handleClick} className={classes.chip} noWrap>
      <Text truncate>{label}</Text>
      <CloseIcon height={16} width={16} />
    </Group>
  );
};

export const SpendPlanChipList = ({ items }: SpendPlanChipListProps) => {
  return (
    <Group spacing={8}>
      {items.map((item) => (
        <SpendPlanChip
          key={item.value}
          label={item.label}
          disabled={item.disabled}
          onClick={item.onClick}
        />
      ))}
    </Group>
  );
};

const useSpendPlanChipStyles = createStyles<string, { disabled?: boolean }>(
  (theme, { disabled }) => ({
    chip: {
      alignItems: 'center',
      backgroundColor: theme.fn.themeColor('neutral', 2),
      borderColor: theme.fn.themeColor('neutral', 3),
      borderRadius: theme.defaultRadius,
      borderStyle: 'solid',
      borderWidth: 1,
      color: theme.fn.primaryColor(),
      cursor: disabled ? 'default' : 'pointer',
      fontSize: rem(14),
      lineHeight: 1,
      overflow: 'hidden',
      padding: `${theme.spacing.xxs} ${theme.spacing.xs}`,
    },
  }),
);
