import { AddRecipientWizard } from '../../add-recipient-wizard';

export const IntlPaymentDetailsStep = () => {
  return (
    <AddRecipientWizard.Step>
      <h1>all beneficiary requirements here</h1>
    </AddRecipientWizard.Step>
  );
};

IntlPaymentDetailsStep.stepId = 'intl-payment-details-step';
