import { InvoiceMetricsResponse } from '@mercoa/javascript/api';
import { DateTime } from 'luxon';

type FilterDays =
  | 'overdue'
  | 'dueInTheNext7Days'
  | 'dueInMoreThan7Days'
  | 'dueInTheNext30Days';

export type Bill = {
  totalAmount: number;
  totalCount: number;
  dueDate: DateTime<true> | DateTime<false>;
};

export const mappedBillsDates = (
  billsDates: Record<string, { totalAmount: number; totalCount: number }>,
) =>
  Object.entries(billsDates).map(([date, value]) => ({
    dueDate: DateTime.fromJSDate(new Date(date)),
    totalAmount: value.totalAmount,
    totalCount: value.totalCount,
  })) ?? [];

export const getFilterDays = (filterStatement: FilterDays) => {
  const filters = {
    overdue: (bill: Bill) => bill.dueDate.diffNow('days').days <= 0,
    dueInTheNext7Days: (bill: Bill) =>
      bill.dueDate.diffNow('days').days > 0 &&
      bill.dueDate.diffNow('days').days <= 7,
    dueInMoreThan7Days: (bill: Bill) => bill.dueDate.diffNow('days').days > 7,
    dueInTheNext30Days: (bill: Bill) =>
      bill.dueDate.diffNow('days').days > 0 &&
      bill.dueDate.diffNow('days').days <= 30,
  };
  return filters[filterStatement];
};

export const filterBills = (bills: Bill[], filterStatement: FilterDays) =>
  bills.filter(getFilterDays(filterStatement));

export const totalAmountBills = (bills: { totalAmount: number }[]) =>
  bills.reduce((acc, current) => acc + current.totalAmount, 0);

export const totalCountBills = (bills: { totalCount: number }[]) =>
  bills.reduce((acc, current) => acc + current.totalCount, 0);

export const getBillsDates = (bills: InvoiceMetricsResponse[]) =>
  bills
    .flatMap((bill) => Object.entries(bill.dates ?? {}))
    .reduce(
      (
        acc: Record<string, { totalAmount: number; totalCount: number }>,
        [key, value],
      ) => {
        if (acc[key]) {
          acc[key].totalAmount += value.totalAmount;
          acc[key].totalCount += value.totalCount;
        } else {
          acc[key] = {
            totalAmount: value.totalAmount,
            totalCount: value.totalCount,
          };
        }
        return acc;
      },
      {},
    );
