import { showNotification } from '@mantine/notifications';
import { flexbaseBankingClient } from '@services/flexbase-client';
import { UpdateDepositAccountNicknameResponse } from '@services/flexbase/banking.model';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const DEPOSIT_ACCOUNT_QUERY_KEY = 'bankingDepositAccount';

export const useGetDepositAccount = (id: string) => {
  return useQuery({
    queryKey: [DEPOSIT_ACCOUNT_QUERY_KEY, id],
    queryFn: async () => {
      return await flexbaseBankingClient.getDepositAccount(id);
    },
    meta: {
      errorMessage: 'Unable to retrieve banking account at this time.',
    },
    enabled: !!id,
  });
};

type NicknameDetails = { accountId: string; nickname: string };

export const useUpdateDepositAccountNickname = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ accountId, nickname }: NicknameDetails) => {
      return await flexbaseBankingClient.editDepositNickname(
        accountId,
        nickname,
      );
    },
    onSuccess: async (data: UpdateDepositAccountNicknameResponse) => {
      await queryClient.invalidateQueries({
        queryKey: [DEPOSIT_ACCOUNT_QUERY_KEY, data.depositAccount.id],
      });

      showNotification({
        color: 'flexbase-teal',
        title: 'Success',
        message: 'Account nickname updated successfully!',
      });
    },
    onError: (error: string) => {
      showNotification({
        color: 'red',
        title: 'Error',
        message: `An error occurred while updating the account nickname. Error: ${error}`,
      });
    },
  });
};

export const useWireInstructions = (accountId: string) => {
  return useQuery({
    queryKey: ['wireinstructions', accountId],
    queryFn: async () => {
      return await flexbaseBankingClient.getDomesticWireInstructions(accountId);
    },
    meta: {
      errorMessage:
        'Unable to retrieve wire data to this account at this time.',
    },
    enabled: !!accountId,
  });
};
