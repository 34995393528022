import { createContext, ReactNode, useContext, useState } from 'react';
import { platformAuthClient } from '@services/platform/platform-auth-client';
import {
  AuthenticationTokenModel,
  PlatformResponse,
} from '@services/platform/models/authorize.models';
import { useNavigate } from 'react-router-dom';

export const MAGIC_LINK_CODE_VERIFIER_KEY = 'code_verifier';

type UseMagicLinkReturnType = {
  token: string;
  tokenType: string;
  setToken: (token: string) => void;
  setTokenType: (token: string) => void;
  handleMagicLinkCallback: (
    token: string | null,
    tokenType: string | null,
  ) => void;
  updatePassword: (
    password: string,
  ) => Promise<PlatformResponse<AuthenticationTokenModel>>;
};

const MagicLinkContext = createContext<UseMagicLinkReturnType | null>(null);

export const MagicLinkContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const navigate = useNavigate();
  const [token, setToken] = useState('');
  const [tokenType, setTokenType] = useState('');
  const [codeVerifier, setCodeVerifier] = useState('');

  const handleMagicLinkCallback = (
    queryToken: string | null,
    queryTokenType: string | null,
  ) => {
    let link: string;

    const _codeVerifier = localStorage?.getItem(MAGIC_LINK_CODE_VERIFIER_KEY);

    if (
      !queryToken ||
      !queryTokenType ||
      (queryTokenType === 'reset_password' && !_codeVerifier)
    ) {
      const error = !_codeVerifier ? 'same-device' : 'invalid';
      link = `/link/error?type=${error}`;
    } else {
      setToken(queryToken);
      setTokenType(queryTokenType);
      setCodeVerifier(_codeVerifier ?? '');

      switch (queryTokenType) {
        case 'reset_password':
          link = 'reset-password';
          break;
        case 'login':
          link = 'login';
          break;
        case 'invite':
          link = 'invite';
          break;
        default:
          link = '/link/error';
      }
    }
    navigate(link, { replace: true });
  };

  const updatePassword = async (newPassword: string) => {
    const result = await platformAuthClient.updatePassword(
      token,
      newPassword,
      codeVerifier,
    );
    localStorage?.removeItem(MAGIC_LINK_CODE_VERIFIER_KEY);
    return result;
  };

  return (
    <MagicLinkContext.Provider
      value={{
        token,
        setToken,
        tokenType,
        setTokenType,
        handleMagicLinkCallback,
        updatePassword,
      }}
    >
      {children}
    </MagicLinkContext.Provider>
  );
};

export const useMagicLinkContext = () => {
  const context = useContext(MagicLinkContext);

  if (context === null) {
    throw new Error(
      'useMagicLinkContext must be used within MagicLinkProvider',
    );
  }

  return context;
};
