import { TextInput, TextInputProps } from '@mantine/core';
import { PatternFormat, PatternFormatProps } from 'react-number-format';

type Props = Omit<TextInputProps, 'type' | 'value' | 'defaultValue'> & {
  value?: string | number;
  mask?: string;
  format: string;
  allowEmptyFormatting?: boolean;
} & PatternFormatProps;

const FlexPatternFormat = (props: Props) => {
  return <PatternFormat {...props} customInput={TextInput} />;
};

export default FlexPatternFormat;
