import {
  Accordion,
  Group,
  SegmentedControl,
  Text,
  TextInput,
} from '@mantine/core';
import { Counterparty } from '@services/flexbase/banking.model';
import {
  BeneficiaryOrCounterparty,
  isParsedBeneficiary,
} from '@utilities/custom-hooks/use-beneficiaries';
import { AchIcon } from 'assets/svg';
import { State } from 'country-state-city';
import { PropsWithChildren } from 'react';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import { CcResponse } from '../send-payment/international-payments/util/types';
import { PayMethod } from '../send-payment/payment.states';

const commonTextInputProps = {
  size: 'md',
  style: { marginTop: 10 },
  readOnly: true,
};

const notAvailable = 'N/A';

const TextInputFieldCollection = ({
  fields,
}: {
  fields: { label: string; placeholder: string; value: string }[];
}) => (
  <>
    {fields.map((f) => (
      <TextInput
        {...commonTextInputProps}
        key={f.label}
        label={f.label}
        placeholder={f.placeholder}
        value={f.value}
      />
    ))}
  </>
);

type BeneficiaryPanelContentProps = {
  beneficiary: CcResponse;
};

const WireInternationalPanelContent = ({
  beneficiary,
}: BeneficiaryPanelContentProps) => {
  const state = State.getStateByCodeAndCountry(
    beneficiary.beneficiaryStateOrProvince,
    beneficiary.beneficiaryCountry,
  )?.name;

  const fields = [
    {
      label: 'Account Number',
      placeholder: 'bank account number',
      value: beneficiary.accountNumber,
    },
    {
      label: 'Address',
      placeholder: 'street address',
      value: beneficiary.beneficiaryAddress[0],
    },
    {
      label: 'City',
      placeholder: 'city',
      value: beneficiary.beneficiaryCity,
    },
    {
      label: 'State',
      placeholder: 'state or province',
      value: state ?? notAvailable,
    },
    {
      label: 'Postal Code',
      placeholder: '6 digit postal code',
      value: beneficiary.beneficiaryPostcode,
    },
  ];

  return <TextInputFieldCollection fields={fields} />;
};

type CounterpartyPanelContentProps = {
  counterparty: Counterparty;
  recipientType?: string;
};

const WirePanelContent = ({ counterparty }: CounterpartyPanelContentProps) => {
  const fields = [
    {
      label: 'Routing number',
      placeholder: '9 digit routing number',
      value: counterparty.routingNumber,
    },
    {
      label: 'Account Number',
      placeholder: 'bank account number',
      value: counterparty.accountNumber,
    },
    {
      label: 'Address',
      placeholder: 'street address',
      value: counterparty.address?.address ?? notAvailable,
    },
    {
      label: 'City',
      placeholder: 'city',
      value: counterparty.address?.city ?? notAvailable,
    },
    {
      label: 'State',
      placeholder: 'two digit state',
      value: counterparty.address?.state ?? notAvailable,
    },
    {
      label: 'Postal Code',
      placeholder: '6 digit postal code',
      value: counterparty.address?.postalCode ?? notAvailable,
    },
  ];

  return <TextInputFieldCollection fields={fields} />;
};

const AchPanelContent = ({
  counterparty,
  recipientType,
}: CounterpartyPanelContentProps) => {
  const recipientTypeOptions: Record<string, string> = {
    individual: 'Person',
    company: 'Business',
  };

  const selectedRecipientType = recipientType
    ? recipientTypeOptions[recipientType]
    : '';

  return (
    <>
      <Text>Recipient type</Text>
      <SegmentedControl
        data={[
          { label: 'Person', value: 'Person' },
          { label: 'Business', value: 'Business' },
        ]}
        style={{ marginTop: 10 }}
        radius={8}
        transitionDuration={800}
        transitionTimingFunction="linear"
        value={selectedRecipientType}
      />
      <TextInput
        {...commonTextInputProps}
        label={'Routing number'}
        placeholder="9 digit routing number"
        value={counterparty.routingNumber}
      />
      <TextInput
        {...commonTextInputProps}
        label="Account Number"
        placeholder="bank account number"
        value={counterparty.accountNumber}
      />
    </>
  );
};

type AccordianLabelProps = {
  label: string;
  description: string;
};

const AccordianLabel = ({ label, description }: AccordianLabelProps) => (
  <Group noWrap>
    <AchIcon></AchIcon>
    <div>
      <Text>{label}</Text>
      <Text size="sm" color="neutral.6" weight={400}>
        {description}
      </Text>
    </div>
  </Group>
);

type CounterpartyAccordionProps = {
  labelName: string;
  description: string;
} & PropsWithChildren;

const CounterpartyAccordion = ({
  labelName,
  description,
  children,
}: CounterpartyAccordionProps) => {
  return (
    <Accordion multiple>
      <Accordion.Item value={labelName} data-testid="name">
        <Accordion.Control>
          <AccordianLabel
            label={labelName}
            description={description}
            data-testid="label"
          />
        </Accordion.Control>
        <Accordion.Panel>{children}</Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

type Props = {
  counterparties: BeneficiaryOrCounterparty[];
  type: PayMethod;
  recipientType: string;
};

const convertTypeToLabel = (type: PayMethod) => {
  switch (type) {
    case 'internationalWire':
      return 'Intl Wire FX';
    case 'internationalWireUSD':
      return 'Intl Wire USD';
    case 'book':
      return 'Internal transfer';
    case 'ach':
      return 'ACH';
    case 'wire':
      return 'Domestic Wire';
  }
};

const CounterpartyAccountDetails = ({
  counterparties,
  type,
  recipientType,
}: Props) => {
  return (
    <>
      {counterparties.map((counterparty, i) => {
        const name = counterparty.bank || counterparty.name;
        const mask = counterparty.accountNumber
          ? getPaddedAccountMask(counterparty.accountNumber, 2)
          : ''; //some int'l wire beneficiaries may not have an accountNumber attached )
        const counterpartylabelName = `${name} ${mask}  (${convertTypeToLabel(
          type,
        )})`;
        const counterpartyKey = `${counterpartylabelName}-${i}`;
        const description = isParsedBeneficiary(counterparty)
          ? counterparty.currency
          : counterparty.accountType!;

        return (
          <CounterpartyAccordion
            key={counterpartyKey}
            labelName={counterpartylabelName}
            description={description}
            data-testid="accordian-top"
          >
            {isParsedBeneficiary(counterparty) ? (
              <WireInternationalPanelContent
                beneficiary={counterparty.ccResponse}
              />
            ) : type === 'ach' ? (
              <AchPanelContent
                counterparty={counterparty}
                recipientType={recipientType}
              />
            ) : type === 'wire' ? (
              <WirePanelContent counterparty={counterparty} />
            ) : (
              false
            )}
          </CounterpartyAccordion>
        );
      })}
    </>
  );
};

export default CounterpartyAccountDetails;
