import { createStyles, rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  card: {
    alignItems: 'flex-start',
    backgroundColor: theme.fn.themeColor('neutral', 0),
    borderColor: theme.fn.themeColor('neutral', 3),
    borderRadius: theme.defaultRadius,
    borderStyle: 'solid',
    borderWidth: 1,
    color: theme.fn.themeColor('neutral', 6),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    fontFamily: theme.fontFamily,
    gap: theme.spacing.xl,
    height: '100%',
    padding: theme.spacing.xl,
    width: '100%',
    minHeight: 104,
  },
  cardBody: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    fontFamily: theme.fontFamily,
    gap: theme.spacing.md,
    height: '100%',
    width: '100%',
    minHeight: 104,
  },
  cardContent: {
    flexGrow: 1,
    height: '100%',
  },
  cardTitleRow: {
    alignItems: 'center',
    marginBottom: theme.spacing.md,
  },
  cardTitle: {
    alignItems: 'center',
    color: theme.fn.themeColor('primarySecondarySuccess', 8),
    display: 'flex',
    flexGrow: 1,
    fontFamily: theme.fontFamily,
    fontSize: '1.25rem',
    fontWeight: 400,
    lineHeight: '2rem',
  },
  cardTag: {
    borderRadius: 4,
    flexGrow: 0,
    fontSize: rem(12),
    fontWeight: 400,
    lineHeight: rem(16),
    padding: `${rem(2)} ${rem(6)}`,
    whiteSpace: 'nowrap',
  },
  cardTagNeutral: {
    backgroundColor: theme.fn.themeColor('flexbaseGrey', 0),
    color: theme.fn.themeColor('neutral', 7),
  },
  cardTagSuccess: {
    backgroundColor: theme.fn.themeColor('primarySecondarySuccess', 0),
    color: theme.fn.themeColor('primarySecondarySuccess', 6),
  },
  cardDescriptionRow: {
    flexGrow: 1,
  },
  cardDescription: {
    fontSize: 14,
    fontWeight: 50,
  },
  disabled: {
    opacity: '50%',
    pointerEvents: 'none',
  },
  confirmDisconnect: {
    background: theme.fn.themeColor('critical', 2),
    borderColor: theme.fn.themeColor('critical', 2),
    '&:not([data-disabled])': theme.fn.hover({
      backgroundColor: theme.fn.themeColor('critical', 2),
    }),
  },
}));
