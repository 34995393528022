import { Text, Badge, useMantineTheme } from '@mantine/core';

const CheckDepositStatusBadge = ({ status }: { status: string }) => {
  const theme = useMantineTheme();
  let backgroundColor, textColor;

  switch (status) {
    case 'Deposited':
      textColor = theme.fn.themeColor('primarySecondarySuccess', 6);
      backgroundColor = theme.fn.themeColor('primarySecondarySuccess', 0);
      break;
    case 'Canceled':
    case 'Rejected':
    case 'Returned':
      textColor = theme.fn.themeColor('critical', 6);
      backgroundColor = theme.fn.themeColor('critical', 0);
      break;
    default:
      textColor = theme.fn.themeColor('neutral', 7);
      backgroundColor = theme.fn.themeColor('neutral', 1);
  }

  return (
    <Badge bg={backgroundColor}>
      <Text color={textColor}>{status} </Text>
    </Badge>
  );
};

export default CheckDepositStatusBadge;
