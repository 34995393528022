import { atom } from 'recoil';
import {
  ActiveFiltersReturnType,
  createUseFiltersHook,
  FilterFnMap,
} from '@common/filter/filters';
import { InvoiceTableData } from '@services/flexbase/billing.model';

const BillingUnpaidFilterState = atom<FilterFnMap<InvoiceTableData>>({
  key: 'billing_unpaid_filter_state',
  default: {},
});

export function useBillingUnpaidFilters(): ActiveFiltersReturnType<InvoiceTableData> {
  return createUseFiltersHook<InvoiceTableData>(BillingUnpaidFilterState);
}
