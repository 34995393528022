import { useQuery } from '@tanstack/react-query';
import { flexbaseOnboardingClient } from '@services/flexbase-client';

const QUERY_KEY = ['user_fico'];

export const useFicoScore = (userId: string) => {
  return useQuery({
    queryKey: [QUERY_KEY, userId],
    queryFn: () => flexbaseOnboardingClient.getFicoScore(userId),
  });
};
