import CreditPaymentCard from '../../components/credit-payment/credit-payment';
import AutoPay from '../../components/auto-pay-settings/auto-pay';
import PaymentsTable from '../../components/payments-table/payments-table';
import { useStyles } from './credit-payment.styles';
import { Tabs } from '@mantine/core';
import RebateHistoryTable from 'areas/credit/components/rebate-history-table/rebate-history-table';
import { useGetTenat } from 'queries/use-tenant';
import ScheduleChart from './schedule-chart';

const CreditPaymentTab = () => {
  const { classes } = useStyles();
  const { data } = useGetTenat();

  return (
    <div className={classes.container}>
      <div className={classes.scheduleAndWidgetContainer}>
        <div className={classes.widgetContainer}>
          <CreditPaymentCard credit={true} />
          <AutoPay />
        </div>
        <div
          className={classes.scheduleContainer}
          style={{ overflow: 'hidden' }}
        >
          <ScheduleChart />
        </div>
      </div>
      <div>
        {data?.earlypay.dates ? (
          <Tabs defaultValue="payment-history" style={{ marginBottom: '15px' }}>
            <Tabs.List>
              <Tabs.Tab value="payment-history">Payment history</Tabs.Tab>
              <Tabs.Tab value="rebate-history">Rebate history</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="payment-history">
              <PaymentsTable />
            </Tabs.Panel>

            <Tabs.Panel value="rebate-history">
              <RebateHistoryTable />
            </Tabs.Panel>
          </Tabs>
        ) : (
          <PaymentsTable />
        )}
      </div>
    </div>
  );
};

export default CreditPaymentTab;
