import FlexbaseSelect from '@common/select/flexbase-select';
import {
  Box,
  Button,
  ButtonProps,
  Flex,
  SelectProps,
  SpacingValue,
  Stack,
  Switch,
  SwitchProps,
  SystemProp,
  Text,
  rem,
} from '@mantine/core';
import { ComponentPropsWithoutRef, PropsWithChildren, ReactNode } from 'react';
import { useSettingsStyles } from './integration-settings.styles';
import { useIntegrationSettingsMobile } from '../utils/use-integration-settings-mobile';

type SettingsRowBaseProps<T> = PropsWithChildren<
  {
    label: string;
    labelSize?: SystemProp<SpacingValue>;
    description?: string;
    disabled?: boolean;
  } & T
>;

type SettingsRowProps = SettingsRowBaseProps<{
  controlElement?: ReactNode;
  controlDirection?: 'row' | 'column';
}>;

export const SettingsRow = (props: SettingsRowProps) => {
  const {
    children,
    controlDirection = 'row',
    label,
    labelSize = '1rem',
    description,
    disabled,
    controlElement,
  } = props;
  const { classes } = useSettingsStyles({ controlDirection, disabled });

  return (
    <Stack>
      <Flex className={classes.flexContainer}>
        <Stack className={classes.settingsContent}>
          <Text fz={labelSize} className={classes.settingsLabel}>
            {label}
          </Text>

          {description ? (
            <Text className={classes.settingsDescription}>{description}</Text>
          ) : null}
        </Stack>

        {controlElement ? (
          <Box className={classes.settingsControl}>{controlElement}</Box>
        ) : null}
      </Flex>

      {children ? <Stack pl="2rem">{children}</Stack> : null}
    </Stack>
  );
};

type SettingsRowSwitchProps = SettingsRowBaseProps<{
  switchProps?: SwitchProps;
}>;

SettingsRow.Switch = function SwitchRow({
  switchProps,
  ...rest
}: SettingsRowSwitchProps) {
  const controlElement = <Switch disabled={rest.disabled} {...switchProps} />;

  return (
    <SettingsRow {...rest} label={rest.label} controlElement={controlElement} />
  );
};

type SettingsRowSelectListProps = SettingsRowBaseProps<{
  selectProps: SelectProps;
}>;

SettingsRow.SelectList = function SelectListRow({
  selectProps,
  ...rest
}: SettingsRowSelectListProps) {
  const isMobile = useIntegrationSettingsMobile();
  const controlElement = (
    <FlexbaseSelect disabled={rest.disabled} {...selectProps} />
  );
  const controlDirection = isMobile ? 'column' : 'row';
  const labelSize = rem(14);

  return (
    <SettingsRow
      {...rest}
      labelSize={labelSize}
      controlDirection={controlDirection}
      controlElement={controlElement}
    />
  );
};

type SettingsRowButtonProps = SettingsRowBaseProps<{
  buttonLabel?: string;
  buttonProps?: ButtonProps & ComponentPropsWithoutRef<'button'>;
}>;

SettingsRow.Button = function ButtonRow({
  buttonLabel,
  buttonProps,
  ...rest
}: SettingsRowButtonProps) {
  const controlElement = (
    <Button disabled={rest.disabled} {...buttonProps}>
      {buttonLabel}
    </Button>
  );

  return <SettingsRow {...rest} controlElement={controlElement} />;
};
