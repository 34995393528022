import { atom } from 'recoil';

/**
 * This state represents the current selected account and the corresponding person id & business id, and has
 * a list of all available account ids.
 */
// export const AccountIdState = atom<{
//   selectedAccountId?: string;
//   personId?: string;
//   businessId?: string;
//   availableAccountsIds: string[];
// }>({
//   key: 'platform_account_state',
//   default: { availableAccountsIds: [] },
// });

// The above is meant for multi-account usage
export const PlatformAccountState = atom<
  { accountId: string; personId: string; businessId: string } | undefined
>({
  key: 'platform_account_state',
  default: undefined,
});
