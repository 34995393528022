import { CounterpartyRequest } from '@services/flexbase/banking.model';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { flexbaseBankingClient } from 'services/flexbase-client';

const QUERY_KEY = 'counterparties';
const COUNTERPARTY_QUERY_KEY = 'counterpartyInfo';

export const useGetCounterparties = () => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      return await flexbaseBankingClient.getCounterPartyList();
    },
    meta: {
      errorMessage: 'Unable to retrieve counterparties list.',
    },
  });
};

export const useGetCounterpartyInfo = (id: string) => {
  return useQuery({
    queryKey: [COUNTERPARTY_QUERY_KEY, id],
    queryFn: async () => {
      const counterparty = await flexbaseBankingClient.getCounterParty(id);
      return counterparty.counterparty;
    },
    meta: {
      errorMessage: 'Unable to retrieve counterparty data.',
    },
    enabled: !!id,
  });
};

const createCounterpartyMutation = async (params: CounterpartyRequest) => {
  return await flexbaseBankingClient.createCounterparty(params);
};

export const useCreateCounterparty = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createCounterpartyMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY],
      });
    },
  });
};
