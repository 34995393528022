import { useMediaQuery } from '@mantine/hooks';
import { Box, Button, Group, TextInput } from '@mantine/core';
import { useStyles } from './banking-transactions-header.styles';
import { useState } from 'react';
import {
  ArrowIcon,
  CalendarIcon,
  CloseIcon,
  DollarCircleIcon,
  SearchIcon,
  TeamsIcon,
} from 'assets/svg';
import { useBankingTransactionsFilters } from '../use-banking-transactions-filters';
import { ExpandingFilterBar } from '@common/filter/expanding-filter-bar';
import { BankingTransactionsAccountFilter } from '../filters/banking-account-filter';
import { Transaction } from '@services/flexbase/banking.model';
import { BankingTransactionsAmountFilter } from '../filters/banking-transactions-amount-filter';
import { BankingTransactionsDateRangeFilter } from '../filters/banking-transactions-date-range.filter';

export type Props = {
  filterText: string;
  accountId?: string;
  transactions: Transaction[];
  setFilterText: (e: any) => void;
  downloadCSV: (array: any) => void;
};

const BankingTransactionsHeader = (props: Props) => {
  const { classes, theme } = useStyles();
  const [showFilters, setShowFilters] = useState(true);
  const { removeFilter, getFilterByKey, activeFiltersArray, addFilter } =
    useBankingTransactionsFilters();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  const filters = [
    {
      key: 'date',
      header: 'Date',
      icon: <CalendarIcon />,
      component: <BankingTransactionsDateRangeFilter />,
      visible: true,
    },
    {
      key: 'amount',
      header: 'Amount',
      icon: <DollarCircleIcon />,
      component: <BankingTransactionsAmountFilter />,
      visible: true,
    },
    {
      key: 'accountName',
      header: 'Account name',
      icon: <TeamsIcon />,
      component: <BankingTransactionsAccountFilter />,
      visible: !props.accountId,
    },
  ];

  const handleSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }

    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (transaction) => {
        const normalizedFilterText = value.toLowerCase().trim();

        const strValues = Object.values(transaction).filter(
          (v) => !!v && typeof v === 'string',
        ) as string[];

        return strValues
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  return (
    <div className={classes.baseContainer}>
      <Group position="apart">
        <TextInput
          w={500}
          icon={<SearchIcon />}
          value={getFilterByKey('search')?.filterValue || ''}
          placeholder="Search transactions"
          onChange={(e) => handleSearchChange(e.target.value)}
          rightSection={
            props.filterText.length > 0 && (
              <CloseIcon
                width={10}
                onClick={() => handleSearchChange('')}
                style={{
                  cursor: 'pointer',
                }}
              />
            )
          }
        />
        <Box sx={() => ({ display: 'flex', gap: theme.spacing.md })}>
          <Button
            variant="outline"
            rightIcon={<ArrowIcon />}
            onClick={() => setShowFilters((prev) => !prev)}
            styles={{
              rightIcon: { rotate: !showFilters ? '180deg' : '' },
            }}
          >
            {`${showFilters ? 'Collapse' : 'Expand'} filters`}
          </Button>
          <Button
            variant="light"
            w={useMobileView ? 'auto' : 152}
            onClick={props.downloadCSV}
          >
            Download CSV
          </Button>
        </Box>
      </Group>
      <Box mt="1rem">
        <ExpandingFilterBar
          showFilters={showFilters}
          filters={filters.filter((f) => f.visible)}
          activeFilters={activeFiltersArray}
          onRemoveActiveFilterClick={removeFilter}
        />
      </Box>
    </div>
  );
};

export default BankingTransactionsHeader;
