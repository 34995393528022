//TODO: incomplete, make more generic/take in custom filters other than radio selection
import {
  Button,
  createStyles,
  Group,
  Popover,
  Radio,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useState } from 'react';
import { FilterIcon } from 'assets/svg';
import { BsChevronRight } from 'react-icons/bs';

export type FilterOption = {
  title: string;
  column: string;
  options: {
    label: string;
    value: string;
  }[];
};

export type FilterProps = {
  filters: FilterOption[];
  initial: {
    column: string;
    value: string;
  };
  isOpen: boolean;
  searchCallback: (column: string, value: string) => void;
  filterCallback: (isOpen: boolean) => void;
};

const useStyles = createStyles((theme) => ({
  parentContainer: {
    width: '589px',
    '@media(max-width: 767px)': {
      width: '450px',
    },
  },
  childContainer: {
    padding: '0 24px 0 21px',
  },
  popoverDropdown: {
    boxShadow: 'none',
    border: `1px solid ${theme.fn.primaryColor()}`,
    color: '#5F5F5F',
    borderRadius: '8px',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
  },
  navbar: {
    width: 190,
    background: '#FCFCFC',
    border: 0,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '10px',
    gap: '8px',
    '@media(max-width: 767px)': {
      width: '140px',
    },
  },
  navbarLabel: {
    padding: 10,
    width: 170,
    borderRadius: 8,
    fontWeight: 500,
    fontSize: 16,
    color: '#4B4B4B',
    '@media(max-width: 767px)': {
      width: '120px',
    },
  },
  navbarButton: {
    padding: 10,
    width: 170,
    height: 40,
    background: 'none',
    border: 0,
    borderRadius: 8,
    '&:hover': {
      background: 'rgba(255, 87, 69, 0.08)',
    },
    '&:focus': {
      outline: 0,
    },
    '@media(max-width: 767px)': {
      width: '140px',
    },
  },
  navbarButtonSelected: {
    backgroundColor: 'rgba(255, 87, 69, 0.08)',
  },
}));

const CustomMantineStyles = {
  radio: {
    radio: {
      borderColor: '#ebf0f7 !important',
      backgroundColor: '#f9fbfe !important',
    },
    icon: { color: '#1ec98b' },
    label: { color: '#5F5F5F', fontSize: '14px', fontWeight: 500 },
  },
};

// TODO: Refactor use current common component standards and styling
export const TableFilter = ({
  filters,
  initial,
  isOpen,
  searchCallback,
  filterCallback,
}: FilterProps) => {
  const theme = useMantineTheme();
  const { classes, cx } = useStyles();
  const [selectedColumn, setSelectedColumn] = useState(initial.column);

  return (
    <Popover
      opened={isOpen}
      onClose={() => filterCallback(false)}
      zIndex={1}
      position={'bottom-end'}
      radius={8}
      transitionProps={{ transition: 'pop-top-right', duration: 500 }}
      classNames={{ dropdown: classes.popoverDropdown }}
    >
      <Popover.Target>
        <div
          onClick={() => {
            filterCallback(false);
          }}
          style={{ cursor: 'pointer', marginTop: '8px', marginRight: '7.5px' }}
        >
          <FilterIcon
            style={{
              paddingRight: '6px',
              position: 'relative',
              zIndex: 2,
            }}
            fill={isOpen ? theme.fn.primaryColor() : '#979797'}
            width={23}
            height={23}
          />
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <Group align="start" noWrap className={classes.parentContainer}>
          <div className={classes.navbar}>
            {filters.map((filter) => (
              // TODO: Replace with NavLink when Mantine upgrade
              <Button
                key={filter.column}
                className={cx(
                  classes.navbarButton,
                  selectedColumn === filter.column &&
                    classes.navbarButtonSelected,
                )}
                onClick={() => {
                  setSelectedColumn(filter.column);
                }}
                rightIcon={
                  <BsChevronRight
                    style={{
                      fill:
                        selectedColumn === filter.column
                          ? theme.fn.primaryColor()
                          : 'none',
                      padding: 0,
                    }}
                    width={20}
                    height={20}
                  />
                }
                styles={{
                  inner: {
                    justifyContent: 'space-between',
                  },
                }}
              >
                <Text className={classes.navbarLabel}>{filter.title}</Text>
              </Button>
            ))}
          </div>
          <div className={classes.childContainer}>
            <Radio.Group
              size="sm"
              onChange={(value) => searchCallback(selectedColumn, value)}
              value={initial.column === selectedColumn ? initial.value : ''}
              styles={{ ...CustomMantineStyles.radio }}
            >
              <Stack spacing="sm">
                {filters
                  .find((filter) => filter.column === selectedColumn)
                  ?.options.map((option) => (
                    <Radio
                      label={option.label}
                      value={option.value}
                      key={option.value}
                    />
                  ))}
                <Radio label="All" value="" />
              </Stack>
            </Radio.Group>
          </div>
        </Group>
      </Popover.Dropdown>
    </Popover>
  );
};

export default TableFilter;
