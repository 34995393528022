import { Box, Divider, Flex, Text, rem, useMantineTheme } from '@mantine/core';
import {
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { Bill, totalCountBills } from './utils';
import { formatCurrency } from '@utilities/formatters/format-currency';

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload) {
    return (
      <Box
        p="sm"
        fw={500}
        w={rem(240)}
        bg="neutral.0"
        c="primarySecondarySuccess.8"
        sx={(theme) => ({
          borderRadius: rem(12),
          border: `1px solid ${theme.fn.themeColor('neutral', 2)}`,
        })}
      >
        <Flex justify="space-between">
          <Text size="sm">{label}</Text>
          <Text size="sm">{payload[0]?.value} bills</Text>
        </Flex>
        <Divider my="xs" />
        <Flex justify="space-between">
          <Text size="sm">Total</Text>
          <Text size="sm">
            {formatCurrency(payload[0]?.payload?.totalAmount)}
          </Text>
        </Flex>
      </Box>
    );
  }

  return null;
};

const BillsChart = ({ bills }: { bills: Bill[] }) => {
  const theme = useMantineTheme();

  const billsData = bills
    .map((bill) => ({
      ...bill,
      dueDate: bill.dueDate.toFormat('LLL dd'),
    }))
    .reverse();

  return (
    <Flex direction="column" justify="space-between" mah={rem(248)}>
      <Box>
        <Text c="primarySecondarySuccess.8" mb="md">
          Upcoming bills ({totalCountBills(bills)})
        </Text>
      </Box>

      <ResponsiveContainer width="100%" height={150}>
        <BarChart
          barGap={6}
          width={580}
          height={152}
          barSize={40}
          data={billsData}
          margin={{ left: -30 }}
        >
          <XAxis
            tickLine={false}
            dataKey="dueDate"
            fontSize={theme.spacing.sm}
          />

          <YAxis
            tickLine={false}
            axisLine={false}
            allowDecimals={false}
            fontSize={theme.spacing.sm}
          />

          <Tooltip content={<CustomTooltip />} cursor={false} />

          <CartesianGrid
            stroke={theme.fn.themeColor('neutral', 1)}
            vertical={false}
          />

          <Bar dataKey="totalCount" fill={theme.fn.themeColor('tertiary', 2)} />
        </BarChart>
      </ResponsiveContainer>
    </Flex>
  );
};

export default BillsChart;
