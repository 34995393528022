import FlexbaseConfetti from 'components/confetti/flexbase-confetti';
import { useState } from 'react';
import ReviewAndPay from './review-and-pay';
import PaymentSuccess from './payment-success';
import PaymentError from './payment-error';
import FullScreenModalContainer from 'components/modal/full-screen-modal-container';
import {
  DepositAccount,
  PlaidAccount,
} from 'areas/banking/move-funds/move-funds.model';

type Props = { closeModal: () => void };
const CardRepaymentModal = ({ closeModal }: Props) => {
  const [step, setStep] = useState<'review' | 'success' | 'error'>('review');
  const [amountPaid, setAmountPaid] = useState(0);
  const [paymentId, setPaymentId] = useState('');
  const [paymentAccount, setPaymentAccount] = useState<
    PlaidAccount | DepositAccount | undefined
  >();

  const getContent = () => {
    switch (step) {
      case 'review':
        return (
          <ReviewAndPay
            closeModal={closeModal}
            onSuccess={(id, paymentAmount, paymentAcct) => {
              setAmountPaid(paymentAmount);
              setPaymentId(id);
              setPaymentAccount(paymentAcct);
              setStep('success');
            }}
            onError={() => setStep('error')}
          />
        );
      case 'success':
        return (
          <PaymentSuccess
            paymentAccount={paymentAccount}
            paymentAmount={amountPaid}
            paymentId={paymentId}
            onCloseClick={closeModal}
            setStep={setStep}
          />
        );
      case 'error':
        return (
          <PaymentError
            onClose={closeModal}
            onTryAgain={() => {
              setPaymentId('');
              setAmountPaid(0);
              setPaymentAccount(undefined);
              setStep('review');
            }}
          />
        );
    }
  };

  return (
    <>
      {step === 'success' && <FlexbaseConfetti />}
      <FullScreenModalContainer
        closeModal={closeModal}
        unrestrictedContent={step === 'success'}
      >
        {getContent()}
      </FullScreenModalContainer>
    </>
  );
};

export default CardRepaymentModal;
