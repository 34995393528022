import { Button, Group, Text } from '@mantine/core';
import FlexbaseDefaultHeader from 'components/header/current/flexbase-header';
import FlexbaseMainLayout from 'components/layouts/main';
import {
  FlexbaseTable,
  TableContainerWithSearchFilter,
} from 'components/table';
import { TableColumn } from 'react-data-table-component';
import { useStyles } from './styles';
import { useDisclosure } from '@mantine/hooks';
import InvoiceModal from './components/submit-invoice.modal';
import { isProdEnv } from 'utilities/environments';

type InvoiceTableData = {
  id: string;
  dateSubmitted: string;
  status: string;
};

const InvoicesTable = ({ data }: { data: InvoiceTableData[] }) => {
  const tableHeaderData = [
    { name: 'Id', selector: 'id' },
    { name: 'Date Submitted', selector: 'dateSubmitted' },
    { name: 'Status', selector: 'status' },
  ];

  const columns: TableColumn<InvoiceTableData>[] = tableHeaderData.map(
    ({ name, selector }) => ({
      name,
      selector: (row: InvoiceTableData) =>
        row[selector as keyof InvoiceTableData],
    }),
  );

  return (
    <TableContainerWithSearchFilter title="Invoices">
      <FlexbaseTable
        columns={columns}
        data={data}
        pagination={false}
        noDataComponent={
          <Text size={24} fw={500} mt="lg">
            No invoices yet
          </Text>
        }
      />
    </TableContainerWithSearchFilter>
  );
};

const sampleInvoiceData = [
  {
    id: '1234',
    dateSubmitted: '1/1/2021',
    status: 'Pending',
  },
  {
    id: '5678',
    dateSubmitted: '1/2/2021',
    status: 'Pending',
  },
  {
    id: '9012',
    dateSubmitted: '1/3/2021',
    status: 'Pending',
  },
];

const InvoiceFactoringDashboard = () => {
  const isProd = isProdEnv();
  if (isProd) return null;

  const { classes } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <FlexbaseMainLayout>
      <InvoiceModal opened={opened} onClose={close} />
      <FlexbaseDefaultHeader title={'Invoice Factoring'} />
      <div className={classes.card}>
        <Group position="right">
          <Button onClick={open} variant="light">
            Submit Invoice
          </Button>
        </Group>
        <InvoicesTable data={sampleInvoiceData} />
      </div>
    </FlexbaseMainLayout>
  );
};
export default InvoiceFactoringDashboard;
