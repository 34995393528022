import { ActionItemCard } from '@common/self-service-dashboard/action-items/action-item-card';
import { Anchor, Button, Text } from '@mantine/core';
import { useSelfServiceStyles } from '@common/self-service-dashboard/self-service-dashboard.styles';

export const FrozenCreditItem = () => {
  const { classes } = useSelfServiceStyles();
  return (
    <ActionItemCard
      title="Unfreeze your credit"
      subtitle={
        <Text>
          You must unfreeze your credit with Equifax by calling 1-888-298-0045.
          Once you have done so, please email{' '}
          <Anchor
            href="mailto:support@flex.one"
            onClick={(e) => e.stopPropagation()}
            sx={{ textDecoration: 'underline' }}
          >
            support@flex.one
          </Anchor>{' '}
          to let us know.
        </Text>
      }
    >
      <Button
        component="a"
        className={classes.primaryButton6}
        href="mailto:support@flex.one"
      >
        Email support
      </Button>
    </ActionItemCard>
  );
};
