import { flexbaseOnboardingClient } from '@services/flexbase-client';
import { useMutation } from '@tanstack/react-query';
import { showNotification } from '@mantine/notifications';
import { logger } from '@sentry/utils';

export type UpdateAutoPayInput = {
  utilizationPct: number;
  payBalancePct: number;
  resetDefault: boolean;
};
const mutationUpdateAutoPay = async ({
  utilizationPct,
  payBalancePct,
  resetDefault,
}: UpdateAutoPayInput) => {
  return await flexbaseOnboardingClient.updateCompany({
    autopay: {
      when: resetDefault ? 'due' : 'utilization',
      ...(!resetDefault && { utilizationPct }),
      ...(!resetDefault && { payBalancePct }),
    },
  });
};

export const useUpdateAutoPay = () => {
  return useMutation({
    mutationFn: mutationUpdateAutoPay,
    onSuccess: () => {
      showNotification({
        title: 'Success',
        message: 'Updated auto pay',
      });
    },
    onError: (error) => {
      showNotification({
        color: 'red',
        title: 'Error',
        message: `${error}`,
      });
      logger.error('Unable to update auto pay', error);
    },
  });
};
