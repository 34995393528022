import { ReactNode } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { createStyles, Group, Stack, useMantineTheme } from '@mantine/core';
import LoginImage from 'assets/images/login-image.png';
import FlexIconLink from '@common/icons/flex-icon-link';

type AuthPageContainerProps = {
  children: ReactNode;
};

const AuthPageContainer = ({ children }: AuthPageContainerProps) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const useMobileView = useMediaQuery('(max-width: 768px)');

  return (
    <div className={classes.container}>
      <FlexIconLink color={theme.colors.neutral[2]} width={110} />
      <Group position="apart" mt={useMobileView ? 20 : 50}>
        <Stack
          sx={(currentTheme) => ({
            position: 'relative',
            zIndex: 100,
            borderRadius: 10,
            [currentTheme.fn.largerThan('sm')]: {
              background: 'rgba(7, 28, 20, 0.7)',
              padding: currentTheme.spacing.md,
              width: `calc(22.5rem + 32px)`,
            },
            [currentTheme.fn.smallerThan('sm')]: {
              width: '100%',
            },
          })}
        >
          {children}
        </Stack>
        {!useMobileView && (
          <div className={classes.imageContainer}>
            <img
              src={LoginImage}
              style={{ height: 660 }}
              alt="credit card image"
            />
          </div>
        )}
      </Group>
    </div>
  );
};

export default AuthPageContainer;

const useStyles = createStyles((theme) => ({
  container: {
    padding: '80px',
    minHeight: '100vh',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(/images/page-top-frame.png)`,
    backgroundColor: theme.colors.primarySecondarySuccess[8],
    '@media (max-width: 768px)': {
      padding: '20px',
    },
  },
  imageContainer: {
    right: '0',
    position: 'absolute',
    zIndex: 1,
  },
}));
