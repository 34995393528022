import { Box, Radio, Stack, Text } from '@mantine/core';
import { useLoginContext } from './use-login';
import { MultiStepFormStepWrapper } from '@common/multi-step-form/multi-step-form-step-wrapper';
import { useState } from 'react';
import { useLoginStyles } from './login.styles';
import { FiMessageCircle, FiSmartphone } from 'react-icons/fi';

export const ChooseFactor = () => {
  const { classes } = useLoginStyles();
  const {
    state: { factorList, selectedFactor },
    setSelectedFactor,
    setState,
  } = useLoginContext();
  const [stagedFactor, setStagedFactor] = useState(selectedFactor);

  const handleFactorSelect = () => {
    if (!stagedFactor) {
      setState({ error: 'You must select a factor to continue' });
    } else {
      setSelectedFactor(stagedFactor);
    }
  };

  return (
    <MultiStepFormStepWrapper
      id={'choose-factor'}
      onFormSubmit={handleFactorSelect}
    >
      <Stack>
        {factorList.map((f) => (
          <Box
            key={f.methodId}
            className={classes.factorRadioBox}
            onClick={() => setStagedFactor(f)}
          >
            <Radio checked={stagedFactor?.methodId === f.methodId} />
            {f.method === 'sms' ? (
              <FiMessageCircle fontSize={20} />
            ) : (
              <FiSmartphone fontSize={20} />
            )}
            <Box>
              <Text fw={600} c="primarySecondarySuccess.9">
                {f.method === 'sms' ? 'Text' : 'Authenticator app'}
              </Text>
              <Text fz={14} c="neutral.6">
                {f.method === 'sms'
                  ? `Send SMS code to ${f.value}`
                  : `Use your authentication app and enter code`}
              </Text>
            </Box>
          </Box>
        ))}
      </Stack>
    </MultiStepFormStepWrapper>
  );
};
