export const AdminFullAllow = [
  {
    id: 1,
    description: 'Open bank accounts',
  },
  {
    id: 2,
    description: 'Invite and manage users',
  },
  {
    id: 3,
    description: 'Manage debit and credit cards',
  },
  {
    id: 4,
    description: 'Transfer funds',
  },
  {
    id: 5,
    description: 'Make credit card payments',
  },
  {
    id: 6,
    description: 'Send domestic ACH/wires',
  },
  {
    id: 7,
    description: 'Update settings',
  },
  {
    id: 8,
    description: 'Manage integrations',
  },
];

export const AdminLimitedAllow = [
  {
    id: 1,
    description: 'Open bank accounts',
  },
  {
    id: 2,
    description: 'Create and manage users',
  },
  {
    id: 3,
    description: 'Issue and manage credit cards',
  },
  {
    id: 4,
    description: 'Make credit card payments',
  },
  {
    id: 5,
    description: 'Submit ACH/Wire payment requests',
  },
  {
    id: 6,
    description: 'Update settings',
  },
  {
    id: 7,
    description: 'Manage integrations',
  },
];

export const EmployeeAllow = [
  {
    id: 1,
    description: 'View transactions on credit cards issued to them',
  },
  {
    id: 2,
    description: 'Freeze or unfreeze their cards',
  },
  {
    id: 3,
    description: 'Dispute transactions',
  },
  {
    id: 4,
    description: 'Upload receipts and add memos',
  },
];

export const BookkeeperAllow = [
  {
    id: 1,
    description: 'View all account transactions',
  },
  {
    id: 2,
    description: 'Download PDF and CSV statements',
  },
  {
    id: 3,
    description: 'View memos',
  },
  {
    id: 4,
    description: 'Download receipts',
  },
];
