import { Box } from '@mantine/core';
import { useBillPayStyles } from '../billing.styles';
import { PayablesTable } from '@mercoa/react';
import { useBillingPaidFilters } from './use-billing-paid-filters';
import { useOutletContext } from 'react-router-dom';
import { MercoaContext } from 'states/mercoa/mercoa';
import BillPayTable from '../components/bill-table/bill-pay-table';
import { InvoiceResponse } from '@mercoa/javascript/api';

const Paid = () => {
  const { classes } = useBillPayStyles();
  const context = useOutletContext<MercoaContext>();

  const customTable = (invoices: InvoiceResponse[], dataLoaded: boolean) => {
    return (
      <BillPayTable
        tableType="PAID"
        invoices={invoices}
        dataLoaded={dataLoaded}
        mercoaToken={context.mercoaToken}
        useFilters={useBillingPaidFilters()}
        headingText="You haven’t paid any bills yet"
      />
    );
  };

  return (
    <Box className={classes.card} mb="xl">
      <PayablesTable statuses={['PAID', 'PENDING']}>
        {({ invoices, dataLoaded }) => customTable(invoices, dataLoaded)}
      </PayablesTable>
    </Box>
  );
};

export default Paid;
