import { StyleSheet, Font } from '@react-pdf/renderer';
import RedactionRegular from 'assets/fonts/Redaction-Regular.otf';
import PPNeueMontrealBold from 'assets/fonts/PPNeueMontreal-Bold.otf';
import PPNeueMontrealMedium from 'assets/fonts/PPNeueMontreal-Medium.otf';
import PPNeueMontrealRegular from 'assets/fonts/PPNeueMontreal-Regular.otf';

type Colors = {
  neutral?: string;
  textColor?: string;
  primaryDark?: string;
  borderRadius?: number;
};

export const useStyles = ({
  neutral,
  textColor,
  primaryDark,
  borderRadius,
}: Colors) => {
  Font.register({
    family: 'Redaction',
    fonts: [
      { src: RedactionRegular }, // font-style: normal, font-weight: normal
    ],
  });

  Font.register({
    family: 'PP Neue Montreal',
    fonts: [
      { src: PPNeueMontrealMedium }, // font-style: normal, font-weight: normal
      { src: PPNeueMontrealBold, fontStyle: 'bold' },
      { src: PPNeueMontrealRegular, fontStyle: 'thin' },
    ],
  });

  return StyleSheet.create({
    headerRow: {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
    },
    containerHeader: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    textHeader: {
      fontSize: 10,
      color: primaryDark,
      alignSelf: 'flex-end',
      fontFamily: 'PP Neue Montreal',
    },
    text: {
      fontFamily: 'PP Neue Montreal',
      fontSize: '14px',
      color: primaryDark,
      fontStyle: 'thin',
    },
    cardReceiptContainer: {
      borderRadius,
      border: `1px solid ${neutral}`,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px 20px',
      marginTop: 20,
      height: '263px',
    },

    cardSentFromContainer: {
      borderRadius,
      border: `1px solid ${neutral}`,
      justifyContent: 'center',
      padding: '20px',
      marginTop: 20,
      height: '136px',
    },

    cardPaymentBreakdownContainer: {
      borderRadius,
      border: `1px solid ${neutral}`,
      justifyContent: 'center',
      padding: '5px 15px',
      marginTop: 20,
      height: '146px',
    },

    footerContainer: {
      backgroundColor: neutral,
      fontSize: '10px',
      color: textColor,
      padding: '15px 20px',
      flexDirection: 'row',
      height: '7vh',
      justifyContent: 'space-between',
      position: 'absolute',
      width: '100%',
      bottom: 0,
      fontFamily: 'Helvetica',
    },
  });
};
