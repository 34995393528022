import { CheckStatus } from '@services/flexbase/banking.model';

export const formatCheckStatus = (status: CheckStatus) => {
  switch (status) {
    case 'Sent':
      return 'Deposited';

    case 'Pending':
    case 'PendingReview':
    case 'Clearing':
      return 'Processing';

    case 'AwaitingImages':
    case 'AwaitingBackImage':
    case 'AwaitingFrontImage':
      return 'Awaiting Images';

    default:
      return status;
  }
};
