import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  container: {
    padding: '50px',
    minHeight: '100vh',
    alignItems: 'start',
    backgroundColor: theme.fn.themeColor('neutral', 2),
    width: '100%',
    '@media(max-width: 767px)': {
      padding: 0,
    },
  },
  closeButton: {
    display: 'flex',
    color: theme.fn.themeColor('neutral', 5),
    alignItems: 'center',
    flexDirection: 'column',
  },
  containerMobile: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  boxContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
