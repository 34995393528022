import { useStyles } from './auto-pay.styles';
import {
  Button,
  Checkbox,
  CSSObject,
  Divider,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import FullScreenModalContainer from '@common/modal/full-screen-modal-container';
import { useMediaQuery } from '@mantine/hooks';
import { formatCurrency } from '@utilities/formatters/format-currency';
import { useForm } from '@mantine/form';
import { positiveNumberUpTo } from '@utilities/validators/validate-numbers';
import { useUpdateAutoPay } from '@queries/use-auto-pay';
import { useRecoilState } from 'recoil';
import { ApplicationState } from '../../../../states/application/product-onboarding';
import FlexNumberInput from '../../../payments/components/common/flex-number-input';
import { useState } from 'react';

type Props = {
  creditLimit: number;
  closeModal: () => void;
};

// For some dumbass reason, mantine NumberInput values are numbers or empty strings
type AutoPaySettingsFormValues = {
  utilizationPct: number | '';
  payBalancePct: number | '';
  resetDefault: boolean;
};

const AutoPaySettings = ({ closeModal, creditLimit }: Props) => {
  const { classes } = useStyles();
  const [{ company }, setCompany] = useRecoilState(ApplicationState);
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [utilError, setUtilError] = useState(false);
  const [payError, setPayError] = useState(false);
  const autoPayForm = useForm<AutoPaySettingsFormValues>({
    initialValues: {
      utilizationPct: company?.autopay?.utilizationPct ?? '',
      payBalancePct: company?.autopay?.payBalancePct ?? '',
      resetDefault: company?.autopay?.when === 'due',
    },
    validate: {
      utilizationPct: (v) => {
        return positiveNumberUpTo(v, 100) ? null : '';
      },
      payBalancePct: (v) => {
        return positiveNumberUpTo(v, 100) ? null : '';
      },
    },
  });
  const theme = useMantineTheme();
  const { mutate, isPending } = useUpdateAutoPay();

  const rootTextInput: CSSObject = {
    display: 'inline-block',
    width: '50px',
    height: '40px',
  };

  const saveAutoPaySettings = async () => {
    const errors = autoPayForm.validate();
    setUtilError(Object.hasOwn(errors.errors, 'utilizationPct'));
    setPayError(Object.hasOwn(errors.errors, 'payBalancePct'));
    /*
    Manually set the errors because getInputProps somehow forces the validation error message and NumberInput is just
    highlighted red when it's a boolean instead of a string; The validation error message does not look right when the
    input is tiny like it is here because it becomes super tall due to width constraints
     */
    if (autoPayForm.values.resetDefault || !errors.hasErrors) {
      mutate(
        {
          utilizationPct: autoPayForm.values.utilizationPct as number,
          payBalancePct: autoPayForm.values.payBalancePct as number,
          resetDefault: autoPayForm.values.resetDefault,
        },
        {
          onSuccess: (data) => {
            setCompany((prev) => ({
              ...prev,
              company: { ...prev.company, autopay: data.autopay },
            }));
            closeModal();
          },
        },
      );
    }
  };

  // We only want these estimates to show up when customers type in legitimate values, otherwise it's blank.
  const calculatedUtil =
    autoPayForm.values?.utilizationPct && autoPayForm.values.utilizationPct > 0
      ? `(${formatCurrency(
          (autoPayForm.values.utilizationPct / 100) * creditLimit,
        )})`
      : '';

  return (
    <FullScreenModalContainer
      closeModal={closeModal}
      unrestrictedContent={true}
    >
      <LoadingOverlay visible={isPending} />
      <div
        className={isMobile ? classes.innerContentMobile : classes.innerContent}
      >
        <Text className={classes.editAutoPayTitle}>Edit auto pay settings</Text>
        <Text className={classes.editAutoPaySubtitle}>
          Configure how you want to auto pay based on credit utilization.
        </Text>
        <div></div>
        <Stack className={classes.editAutoPayCalculations}>
          <Text>
            When credit utilization reaches{' '}
            <FlexNumberInput
              maxLength={3}
              variant="default"
              radius={8}
              pattern="[0-9]*"
              data-testid="amount"
              decimalScale={0}
              thousandSeparator=","
              styles={{
                label: { color: 'black' },
                input: {
                  '&:focus': { borderColor: theme.fn.primaryColor() },
                },
                root: rootTextInput,
              }}
              onValueChange={(value) => {
                autoPayForm.setFieldValue(
                  'utilizationPct',
                  value.floatValue ?? 0,
                );
              }}
              value={autoPayForm.values.utilizationPct}
              error={utilError}
              disabled={autoPayForm.values.resetDefault}
            />{' '}
            % {calculatedUtil},
          </Text>

          <Text style={{ lineHeight: isMobile ? 2 : '2.5rem' }}>
            schedule an auto payment for{' '}
            <FlexNumberInput
              maxLength={3}
              variant="default"
              radius={8}
              pattern="[0-9]*"
              data-testid="amount"
              decimalScale={0}
              thousandSeparator=","
              styles={{
                input: {
                  '&:focus': { borderColor: theme.fn.primaryColor() },
                },
                root: rootTextInput,
              }}
              onValueChange={(value) => {
                autoPayForm.setFieldValue(
                  'payBalancePct',
                  value.floatValue ?? 0,
                );
              }}
              value={autoPayForm.values.payBalancePct}
              error={payError}
              disabled={autoPayForm.values.resetDefault}
            />{' '}
            % of your outstanding credit balance.
          </Text>
        </Stack>
        <Divider
          className={classes.borderLine}
          styles={{ label: { fontSize: '14px' } }}
          my="md"
          label="or"
          labelPosition="center"
        />

        <Checkbox
          {...autoPayForm.getInputProps('resetDefault', { type: 'checkbox' })}
          mt="md"
          styles={{
            label: {
              fontFamily: 'PP Neue Montreal',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
            },
          }}
          label="Default option: schedule minimum required payment on due date"
        />
        {(utilError || payError) && (
          <Text className={classes.errorText}>
            Please enter a number between 1 and 100
          </Text>
        )}
        <Group
          position="right"
          align="center"
          spacing="sm"
          className={classes.buttonsDiv}
        >
          <Button variant="outline" onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button variant="light" onClick={saveAutoPaySettings}>
            Save Changes
          </Button>
        </Group>
      </div>
    </FullScreenModalContainer>
  );
};

export default AutoPaySettings;
