import { StyleSheet, Font } from '@react-pdf/renderer';
import { defaultStylesPDF } from 'components/pdf-viewer/styles';
import RedactionRegular from 'assets/fonts/Redaction-Regular.otf';
import PPNeueMontrealBold from 'assets/fonts/PPNeueMontreal-Bold.otf';
import PPNeueMontrealMedium from 'assets/fonts/PPNeueMontreal-Medium.otf';
import PPNeueMontrealRegular from 'assets/fonts/PPNeueMontreal-Regular.otf';

type Colors = {
  neutral?: string;
  textColor?: string;
  primaryDark?: string;
  borderRadius?: number;
  secondaryDark?: string;
};

export const stylesPDF = ({
  neutral,
  textColor,
  primaryDark,
  secondaryDark,
}: Colors) => {
  const defaultStyles = defaultStylesPDF({
    primaryDark,
    neutral,
    textColor,
    secondaryDark,
  });
  Font.register({
    family: 'Redaction',
    fonts: [
      { src: RedactionRegular }, // font-style: normal, font-weight: normal
    ],
  });

  Font.register({
    family: 'PP Neue Montreal',
    fonts: [
      { src: PPNeueMontrealMedium }, // font-style: normal, font-weight: normal
      { src: PPNeueMontrealBold, fontStyle: 'bold' },
      { src: PPNeueMontrealRegular, fontStyle: 'thin' },
    ],
  });

  return StyleSheet.create({
    ...defaultStyles,

    subText: {
      fontSize: 14,
      fontWeight: 400,
      color: primaryDark,
      fontFamily: 'Helvetica',
    },

    text: {
      width: '40%',
      fontSize: 14,
      color: textColor,
      margin: '8px 0px',
      fontFamily: 'Helvetica',
    },

    textInfo: {
      width: '60%',
      fontSize: 14,
      margin: '8px 0px',
      color: primaryDark,
      fontFamily: 'Helvetica',
    },

    containerPage: {
      marginTop: 28,
      width: '100%',
      padding: '0px 25px',
    },

    containerData: {
      flexDirection: 'row',
    },

    smallText: {
      fontSize: '8px',
      fontWeight: 400,
      color: primaryDark,
      fontFamily: 'Helvetica',
    },

    greenText: {
      fontSize: 16,
      marginBottom: 10,
      color: secondaryDark,
      fontFamily: 'Helvetica',
    },

    smallGreenText: {
      fontSize: 10,
      color: secondaryDark,
      fontFamily: 'Helvetica',
    },
  });
};
