import { Box, BoxProps, createStyles, Text } from '@mantine/core';
import { CheckBlack } from '../../assets/svg';
import { ReactNode } from 'react';

export const RadioSelectCard = ({
  selected,
  onClick,
  title,
  description,
  id,
  boxProps,
}: {
  selected: boolean;
  onClick: () => void;
  title: string;
  description: ReactNode;
  id?: string;
  boxProps?: BoxProps;
}) => {
  const { classes } = useStyles(selected);
  return (
    <Box
      className={classes.radioCard}
      onClick={() => onClick()}
      id={`radio-select-card-${id}`}
      data-testid={`radio-select-card-${id}`}
      {...boxProps}
    >
      <Box className={classes.radioIcon}>{selected && <CheckBlack />}</Box>
      <div>
        <Text fz={16} fw={500} opacity={selected ? 1 : 0.7}>
          {title}
        </Text>
        <Text fz={14} opacity={selected ? 1 : 0.7}>
          {description}
        </Text>
      </div>
    </Box>
  );
};

const useStyles = createStyles((theme, selected: boolean) => ({
  radioCard: {
    width: 340,
    height: 106,
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.fn.themeColor('neutral', 3)}`,
    padding: `${theme.spacing.md} ${theme.spacing.lg}`,
    display: 'flex',
    gap: theme.spacing.md,
    alignItems: 'center',
    cursor: 'pointer',
    '&:active': {
      transform: 'translate(-2px)',
    },
    color: selected
      ? theme.fn.themeColor('neutral', 2)
      : theme.fn.themeColor('primarySecondarySuccess', 8),
    ...(selected && {
      backgroundColor: theme.fn.themeColor('primarySecondarySuccess', 8),
    }),
    [theme.fn.smallerThan('sm')]: {
      width: '100%',
    },
  },
  radioIcon: {
    height: 24,
    width: 24,
    minHeight: 24,
    minWidth: 24,
    borderRadius: '100%',
    border: `1px solid ${theme.fn.themeColor('neutral', 3)}`,
    backgroundColor: selected ? theme.fn.themeColor('neutral', 0) : '',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
