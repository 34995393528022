import { Alert, Button, Group } from '@mantine/core';
import { RiFireLine } from 'react-icons/ri';

import { useStyles } from '../styles';
import { PaymentForm } from 'states/banking/payments';
import { AUTHORIZATIONS } from './move-funds.model';
import { formatCurrency } from 'utilities/formatters/format-currency';
import AccountSelection from 'areas/payments/components/account-selection';

type Props = {
  loading: boolean;
  form: PaymentForm;
  onContinue: () => void;
  onBackClick: () => void;
};

const TransferReview = ({ form, loading, onContinue, onBackClick }: Props) => {
  const { classes } = useStyles();
  const authorization =
    form.fromAccount?.plaidOrDeposit === 'plaid'
      ? AUTHORIZATIONS.achDebit.contents
      : AUTHORIZATIONS.default.contents;

  return (
    <div className={classes.card}>
      <div className="move_funds">
        <span>Transfer between accounts </span>

        <div className="transfer_amount" data-testid="amount">
          {formatCurrency(form.amount)}
        </div>

        <div
          className="label_wrapper"
          style={{ marginTop: '50px' }}
          data-testid="transfer-from"
        >
          <AccountSelection
            label="Transferring from"
            currentAccount={form.fromAccount!}
          />
        </div>

        <div className="label_wrapper" data-testid="transfer-to">
          <AccountSelection
            label="Transferring to"
            currentAccount={form.toAccount!}
          />
        </div>

        <Alert
          icon={<RiFireLine />}
          color="info"
          my="lg"
          withCloseButton={false}
        >
          {form.fromAccount?.plaidOrDeposit === 'deposit' &&
          form.toAccount?.plaidOrDeposit === 'deposit'
            ? 'This transfer will take just a few moments.'
            : 'This transfer will take 3 business days.'}
        </Alert>

        <div className={classes.authorizationSection} data-testid="disclaimer">
          {authorization}
        </div>
        <Group position="apart" mt={20}>
          <Button
            variant="outline"
            onClick={onBackClick}
            data-testid={'button-back'}
          >
            Back
          </Button>
          <Button
            variant="light"
            styles={{
              root: {
                marginBottom: '15px',
              },
            }}
            loading={loading}
            onClick={onContinue}
            data-testid={'button-next'}
          >
            Transfer
          </Button>
        </Group>
      </div>
    </div>
  );
};

export default TransferReview;
