import FlexbaseTable from 'components/table/flexbase-table';
import { useStyles } from '../../styles';
import { Text, useMantineTheme } from '@mantine/core';
import { liteTableStyles } from 'components/styles';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { TreasuryActivity } from 'services/flexbase/banking.model';
import StatusCell from 'components/table/cells/status-cell';
import { capitalizeOnlyFirstLetter } from 'utilities/formatters/format-strings';
import { getLocaleDate } from 'utilities/formatters/format-date-string';

type Props = {
  activities: TreasuryActivity[];
  loading?: boolean;
};

const TreasuryActivitiesTable = ({ activities, loading }: Props) => {
  const theme = useMantineTheme();
  const { classes, cx } = useStyles();

  const columns = [
    {
      name: 'Request Date',
      format: (row: TreasuryActivity) => getLocaleDate(row.createdAt),
      selector: (row: TreasuryActivity) => row.createdAt,
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row: TreasuryActivity) => row.byUser,
      sortable: true,
    },
    {
      name: 'Amount',
      sortable: true,
      format: (row: TreasuryActivity) => formatCurrency(row.amount),
      selector: (row: TreasuryActivity) => row.amount,
    },
    {
      name: 'Activity',
      sortable: true,
      selector: (row: TreasuryActivity) => row.activity,
      format: (row: TreasuryActivity) =>
        capitalizeOnlyFirstLetter(row.activity),
    },
    {
      name: 'Status',
      sortable: true,
      selector: (row: TreasuryActivity) => row.status,
      cell: (row: TreasuryActivity) => {
        if (!row.status) return;
        let color;
        switch (row.status.toLowerCase()) {
          case 'succeeded':
            color = '#E9F9F2';
            break;
        }
        return (
          <StatusCell
            status={capitalizeOnlyFirstLetter(row.status)}
            backgroundColor={color}
          />
        );
      },
      compact: true,
      grow: 1,
    },
    {
      name: 'Posted Date',
      format: (row: TreasuryActivity) => getLocaleDate(row.datePosted),
      selector: (row: TreasuryActivity) => row.datePosted,
      sortable: true,
      compact: true,
      maxWidth: '110px',
    },
  ];

  return (
    <FlexbaseTable
      defaultSortAsc={false}
      defaultSortFieldId="1"
      customStyles={liteTableStyles(theme.fn.primaryColor())}
      striped={true}
      columns={columns}
      data={activities}
      isFetchingData={loading}
      noDataComponent={
        <Text
          className={cx(classes.pp, classes.emptyTableComp)}
          weight={500}
          size={14}
          color="#000000"
        >
          No activities
        </Text>
      }
      pagination={activities && activities?.length > 10}
    />
  );
};

export default TreasuryActivitiesTable;
