import ValidatorFn from './validator-fn';

/**
 * Returns true if the value is truthy or false if falsy
 * @param value
 */
export const validateRequired = <T>(value: T) => {
  return !!value;
};

export function RequiredFieldValidator<T>(errorMessage?: string) {
  const _errorMessage = errorMessage || 'This field is required';
  return (val: T) => (validateRequired(val) ? null : _errorMessage);
}

export const OptionalFieldValidator = (
  errorMessage: string,
  validateFn: (value: string) => boolean,
  validateValue: string,
) => {
  if (validateValue !== '') {
    const result = validateFn(validateValue);

    if (!result) {
      return errorMessage;
    }
  }
};

export function ConditionalFieldValidator<T>(
  condition: boolean,
  validateFn?: ValidatorFn<T>,
): ValidatorFn<T> {
  if (condition) {
    if (validateFn) {
      return validateFn;
    }
    return RequiredFieldValidator();
  } else {
    return () => null;
  }
}
