import flexbaseClient from '@services/flexbase-client';
import { useQuery } from '@tanstack/react-query';

const QUERY_KEY = 'companyBalance';

export const useGetCompanyBalance = (companyId: string) => {
  return useQuery({
    queryKey: [QUERY_KEY, companyId],
    queryFn: async () => {
      return await flexbaseClient.getCompanyBalance(companyId);
    },
    meta: {
      errorMessage: 'Unable to get company balance',
    },
  });
};
