import { DateTime } from 'luxon';
import { InvoiceResponse } from '@mercoa/javascript/api';
import { InvoiceTableData } from '@services/flexbase/billing.model';
import { capitalizeOnlyFirstLetter } from '@flexbase-eng/web-components';

export const sortDate = (a: string, b: string) => {
  return (
    DateTime.fromFormat(b, 'LLL dd, yyyy').toMillis() -
    DateTime.fromFormat(a, 'LLL dd, yyyy').toMillis()
  );
};

export const getDate = (date: Date) => DateTime.fromJSDate(new Date(date));
export const getDaysDiff = (dueDate: DateTime) => dueDate.diffNow('days').days;

export const formatStatus = (invoice: InvoiceResponse) => {
  const invoiceStatus = invoice.status;
  const dueDate = invoice.dueDate ? getDate(invoice.dueDate) : null;

  let dueDateStatus = '';
  if (dueDate) {
    const daysDiff = getDaysDiff(dueDate);
    switch (true) {
      case daysDiff <= 0:
        dueDateStatus = 'Overdue';
        break;
      case daysDiff === 0:
        dueDateStatus = 'Due Today';
        break;
      case daysDiff > 0 && daysDiff <= 7:
        dueDateStatus = `Due in ${Math.round(daysDiff)} day${
          Math.round(daysDiff) !== 1 ? 's' : ''
        }`;
        break;
      default:
        dueDateStatus = 'Due in 7+ days';
    }
  }

  let paymentStatus = '';
  if (invoiceStatus === 'DRAFT' && !invoice.hasDocuments) {
    paymentStatus = 'Incomplete draft';
  } else if (invoiceStatus === 'DRAFT') {
    paymentStatus = 'Draft complete';
  } else if (invoiceStatus === 'NEW') {
    paymentStatus = 'Pending review';
  } else if (
    ['APPROVED', 'SCHEDULED'].includes(invoiceStatus) &&
    invoice.dueDate
  ) {
    paymentStatus = dueDateStatus;
  } else if (invoiceStatus === 'PENDING') {
    paymentStatus = 'Payment processing';
  } else {
    paymentStatus = capitalizeOnlyFirstLetter(invoice.status);
  }

  return paymentStatus;
};

export const mappedInvoices = (
  invoices: InvoiceResponse[],
): InvoiceTableData[] => {
  return invoices
    .map((invoice) => {
      const createdDate = getDate(invoice.createdAt);
      const dueDate = invoice.dueDate && getDate(invoice.dueDate);
      const paidDate =
        invoice.settlementDate && getDate(invoice.settlementDate);
      const scheduleDate =
        invoice.deductionDate && getDate(invoice.deductionDate);

      const status = ['DRAFT', 'NEW'].includes(invoice.status)
        ? 'Needs approval'
        : 'Approved';

      return {
        status,
        invoice,
        id: invoice.id,
        amount: invoice.amount,
        vendor: invoice.vendor?.name ?? '',
        hasDocuments: invoice.hasDocuments,
        invoiceStatus: formatStatus(invoice),
        createdAt: createdDate.toFormat('LLL dd, yyyy'),
        dueDate: dueDate ? dueDate.toFormat('LLL dd, yyyy') : '',
        paidDate: paidDate ? paidDate.toFormat('LLL dd, yyyy') : '',
        scheduleDate: scheduleDate ? scheduleDate.toFormat('LLL dd, yyyy') : '',
      } as InvoiceTableData;
    })
    .sort((a, b) => sortDate(a.createdAt, b.createdAt));
};
