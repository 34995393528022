import { createStyles } from '@mantine/core';

export const useStyle = createStyles((theme) => ({
  inputsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media(max-width: 767px)': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  defaultControlPerson: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '10px 20px 10px 20px',
    alignItems: 'center',
  },
  addControlPersonOption: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  plusContainer: {
    backgroundColor: '#ECF2EF',
    padding: '10px 12px 10px 12px',
    borderRadius: '100%',
    marginRight: '10px',
  },
  addNewControlPersonBtn: {
    backgroundColor: theme.fn.themeColor('neutral', 0),
    '&:not([data-disabled])': theme.fn.hover({
      backgroundColor: theme.fn.themeColor('neutral', 0),
    }),
  },
}));
