import { Flex } from '@mantine/core';
import { useSecurityCenterStyles } from '../security-center.styles';

export const Tag = ({ children }: { children: string }) => {
  const { classes } = useSecurityCenterStyles();

  return (
    <Flex justify="center" align="center" className={classes.infoTag}>
      {children}
    </Flex>
  );
};
