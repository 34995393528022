import { MantineTheme } from '@mantine/core';
import { Path, Svg } from '@react-pdf/renderer';

type Props = {
  theme: MantineTheme;
};

const FlexLogoPDF = ({ theme }: Props) => {
  return (
    <Svg style={{ width: 120, height: 42 }} viewBox="0 0 120 42">
      <Path
        d="M7.08551 16.7945L8.99153 11.3505L9.09504 11.0534H11.9776C14.1998 11.0534 14.6394 12.354 14.6394 13.8246H15.0118L17.2111 7.14043H16.8386C15.9301 8.50176 14.6538 9.71625 12.4269 9.71625H9.55801L12.312 1.81251H18.1397C21.8373 1.81251 22.3198 3.32125 22.3198 4.68755H22.7046L24.3213 0H6.23466V0.345947C7.74923 0.603819 8.71846 2.35375 8.06495 4.23211L3.72701 16.6875C3.08614 18.3443 1.13591 20.2253 0 20.4534V20.7993H9.04101V20.4534C7.93442 20.0964 6.46107 18.7188 7.08551 16.7945Z"
        fill={theme.colors.primarySecondarySuccess[8]}
      />
      <Path
        d="M53.5798 20.4945C53.1306 20.2668 52.0498 19.1911 51.0497 16.9366C50.3532 15.4857 49.6193 14.0499 48.9867 12.5687L49.8148 11.3802C51.4121 9.09228 52.2256 8.02934 53.0625 7.85106V9.00042C53.4836 9.13391 53.91 9.13111 54.2056 9.12916C54.239 9.12894 54.2707 9.12873 54.3006 9.12873C55.4448 9.12873 55.9482 8.41561 55.8871 7.58364C55.8238 6.72195 55.0914 6.09798 54.0758 6.06826C52.8662 6.06826 51.8172 7.04206 50.3049 9.1517L48.555 11.6033C47.735 9.76929 46.8785 7.95394 46.0972 6.10184H42.0383V6.44779C43.1228 6.88042 44.0235 8.59173 44.7605 10.2214C45.3379 11.6195 45.9054 13.0215 46.5067 14.411L45.3844 16.0452C43.9772 18.0948 42.9736 19.0165 42.1367 19.1948V18.1251C41.6681 17.9766 41.1929 17.9171 40.8986 17.9171C39.7544 17.9171 39.251 18.6303 39.3121 19.4622C39.3564 20.0648 39.7279 20.5512 40.2946 20.7993C40.5383 20.906 40.8181 20.9687 41.1234 20.9776C41.4747 20.9776 41.8277 20.925 42.188 20.7993C43.0683 20.4923 44.1269 19.3408 44.8943 18.2737L46.965 15.4061C47.797 17.2125 49.5216 20.73 49.5566 20.7993H53.5798V20.4945Z"
        fill={theme.colors.primarySecondarySuccess[8]}
      />
      <Path
        d="M22.9701 17.8917C22.3528 19.7492 23.7197 19.2697 25.6383 18.1245C25.6638 18.3008 25.6961 18.4739 25.7352 18.6435C23.965 19.9062 22.3287 20.9776 20.8688 20.9776C19.5128 20.9776 19.1781 20.0964 20.139 17.412L26.0309 0H29.0243L22.9701 17.8917Z"
        fill={theme.colors.primarySecondarySuccess[8]}
      />
      <Path
        fillRule="evenodd"
        d="M38.7555 17.3319C37.5351 19.8403 35.4891 20.9403 33.0876 20.998C29.8748 21.0752 27.5512 18.9177 27.2541 15.8556C26.8257 11.4391 30.1407 5.5807 34.986 5.5807C37.927 5.5807 39.305 6.84791 39.3429 8.46793L39.3429 8.46839C39.3851 10.2904 37.0645 11.717 32.4928 14.5275L32.4927 14.5276C31.8825 14.9027 31.2323 15.3024 30.5422 15.731C30.5895 16.0522 30.6448 16.3505 30.7125 16.6259C31.1623 18.2655 32.1196 19.162 34.5303 19.104C36.0555 19.0674 37.3288 18.2238 38.5308 16.8668L38.7555 17.3319ZM36.6191 9.32119C36.6004 8.5208 36.0254 7.7991 35.0952 7.82147C31.8174 7.90028 30.1838 12.1798 30.4292 15.0202C30.6546 14.8685 30.9356 14.688 31.2526 14.4843C33.245 13.2043 36.6586 11.0114 36.6191 9.32119Z"
        fill={theme.colors.primarySecondarySuccess[8]}
      />
    </Svg>
  );
};

export default FlexLogoPDF;
