import { createStyles, rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  titleLarge: {
    fontFamily: 'PP Neue Montreal',
    fontWeight: 400,
    fontSize: '48px',
  },
  titleSmall: {
    fontSize: '24px',
    fontFamily: 'PP Neue Montreal',
  },
  sectionTitle: {
    color: theme.fn.primaryColor(),
    fontWeight: 500,
    fontSize: '14px',
  },
  marginTop: {
    marginTop: 30,
  },
  notesSection: {
    height: 42,
    display: 'flex',
    fontWeight: 400,
    fontSize: '14px',
    padding: '0px 20px',
    alignItems: 'center',
    borderRadius: theme.defaultRadius,
    color: theme.fn.themeColor('neutral', 6),
    backgroundColor: theme.fn.themeColor('neutral', 1),
  },
  text: {
    fontWeight: 400,
    fontSize: rem(12),
    color: theme.fn.themeColor('primarySecondarySuccess', 8),
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 0',
    gap: '16px',
  },
  sectionContent: {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
  },
  disclaimerText: {
    margin: '24px 0',
  },
  box: {
    padding: '24px 0 !important',
  },
  filesContainer: {
    minHeight: '50px',
    display: 'column',
    justifyContent: 'center',
  },
  file: {
    width: '100%',
    height: '40px',
    margin: '8px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px',
    fontSize: '12px',
    fontWeight: 500,
    color: theme.fn.themeColor('neutral', 6),
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
  },
  deleteFile: {
    all: 'unset',
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  control: {
    marginTop: 20,
    padding: '0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    padding: '0',
  },
  containerDetails: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridAutoRows: 'minmax(40px, auto)',
  },
  badge: {
    padding: `0 ${theme.spacing.xs}`,
    borderRadius: theme.defaultRadius,
    backgroundColor: theme.fn.themeColor('tertiary', 1),
  },
}));
