import { createStyles, MantineTheme } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  title: {
    fontSize: '16px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '19px',
    color: '#4B4B4B',
    fontFamily: 'PP Neue Montreal',
  },
  container: {
    padding: 12,
    backgroundColor: 'white',
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
    maxWidth: '1365px',
    margin: 'auto',
    marginTop: '20px',
  },
  header: {
    borderBottom: 'solid',
    borderBottomColor: '#E6E7E9',
    borderBottomWidth: 0.5,
    paddingBottom: 16,
    paddingTop: 12,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      flexDirection: 'column',
      gap: 8,
      display: 'flex',
      alignItems: 'flex-start',
    },
  },
  statusColumn: {
    padding: 5,
    textAlign: 'center',
    borderRadius: '25px',
    '@media(max-width: 767px)': {
      width: '100%',
    },
    minWidth: '100px',
  },
  errorMessage: {
    height: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    span: {
      display: 'flex',
      fontWeight: 600,
      marginTop: '32px',
      justifyContent: 'center',
    },

    p: {
      color: '#6F7181',
      fontSize: '14px',
      textAlign: 'center',
    },
  },
}));

export const CustomMantineStyles = (theme: MantineTheme) => {
  return {
    searchPaymentHistory: {
      input: {
        color: '#5F5F5F',
        height: 40,
        opacity: 0.8,
        border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
        borderRadius: theme.defaultRadius,
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: 500,
        background: 'white',
        fontStyle: 'normal',
        '&:focus': { borderColor: theme.fn.primaryColor() },
      },
    },
  };
};
