import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  ScrollArea,
  ScrollAreaProps,
  Stack,
  Text,
} from '@mantine/core';
import { BankIcon } from 'assets/svg';
import AccountBox from 'components/account-box/account-box';
import FlexbaseSelect from 'components/select/flexbase-select';
import { forwardRef, ReactNode, useEffect, useRef, useState } from 'react';
import {
  useAddRecipientFlowFeatureFlag,
  useIntlPaymentsFeatureFlag,
} from 'utilities/feature-flags';
import GetPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import PaymentStep from '../payment-step';
import BeneficiaryRequirements from './international-payments/beneficiary-requirements';
import InternationalPaymentAccountForm from './international-payments/international-payment-account-form';
import {
  isInternationalPayMethod,
  isInternationalWire,
  supportedCurrenciesData,
} from './international-payments/util';
import {
  BeneficiaryRequirementsFormRef,
  InternationalPaymentRecipientFormRef,
  InternationalRecipient,
  InternationalRecipientForm,
} from './international-payments/util/types';
import PaymentAccountForm, {
  PaymentRecipientFormRef,
} from './payment-account-form';
import { useStyles } from './payment-method.styles';
import {
  PayMethod,
  Recipient,
  counterpartyInitial,
  recipientInitial,
} from './payment.states';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'states/application/product-onboarding';
import FlagIcon from '@common/icons/flag-icon';
import { ParsedAccount } from 'areas/payments/pages/recipient-details/types';
import { Recipient as NewRecipientType } from 'states/recipient/recipient';
import { createSearchParams, useNavigate } from 'react-router-dom';
import useModal from '@common/modal/modal-hook';

const useSendToAddRecipientFlow = (recipientId?: string) => {
  const { closeAllModals } = useModal();
  const navigate = useNavigate();

  const sendToAddRecipientFlow = () => {
    closeAllModals();

    const searchParams = recipientId
      ? createSearchParams({ recipientId })
      : null;

    navigate({
      pathname: '/recipients/new',
      search: searchParams ? `?${searchParams}` : '',
    });

    return;
  };

  return sendToAddRecipientFlow;
};

const SendToRecipientFlowButton = ({
  recipientId,
}: {
  recipientId?: string;
}) => {
  const sendToAddRecipientFlow = useSendToAddRecipientFlow(recipientId);
  return (
    <Button
      variant="outline"
      leftIcon="+"
      h="auto"
      p={16}
      w={'100%'}
      onClick={sendToAddRecipientFlow}
    >
      Add payment method
    </Button>
  );
};

type DropdownWithActionProps = { recipientId?: string } & ScrollAreaProps;

const DropdownWithAction = forwardRef<HTMLDivElement, DropdownWithActionProps>(
  function f(props, ref) {
    const { recipientId, ...rest } = props;
    return (
      <Stack spacing={0} w={'100%'}>
        <ScrollArea ref={ref} {...rest} />
        <SendToRecipientFlowButton recipientId={recipientId} />
      </Stack>
    );
  },
);

type PayMethodOption = {
  value: PayMethod;
  label: string;
};

const paymentMethodOptions: PayMethodOption[] = [
  { value: 'ach', label: 'Domestic ACH' },
  { value: 'wire', label: 'Domestic Wire' },
  { value: 'book', label: 'Internal transfer' },
  { value: 'internationalWireUSD', label: 'International wires - USD' },
  {
    value: 'internationalWire',
    label: 'International wires - Foreign exchange',
  },
];

const Icon = ({ method, flag }: { method: PayMethod; flag?: string }) => {
  return (
    <Avatar
      sx={(theme) => ({
        border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
      })}
    >
      {isInternationalPayMethod(method) && flag ? (
        <FlagIcon flagNationCode={flag} width="22px" />
      ) : (
        <BankIcon />
      )}
    </Avatar>
  );
};

const SubheaderText = ({
  method,
  currency,
  accountNumber,
  routingNumber,
  currencySymbol,
}: {
  method: PayMethod;
  currency?: string;
  accountNumber: string;
  routingNumber?: string;
  currencySymbol?: string;
}) => {
  const conditionalRouterText = routingNumber
    ? `Routing ${GetPaddedAccountMask(routingNumber, 2)}`
    : '';

  return (
    <Flex justify="space-between" gap="xs">
      <Text>{accountNumber ? GetPaddedAccountMask(accountNumber, 2) : ''}</Text>
      {isInternationalPayMethod(method) && currency !== 'USD' ? (
        <Badge bg="pink">
          {currencySymbol}
          {currency}
        </Badge>
      ) : (
        <Text>{conditionalRouterText}</Text>
      )}
    </Flex>
  );
};

type Props = {
  selectedRecipient?: NewRecipientType;
  internationalAccountForm: InternationalRecipientForm;
  newRecipient: Recipient | InternationalRecipient;
  onNextClick: (recipient?: Recipient | InternationalRecipient) => void;
  onBackClick: () => void;
  recipientName: string;
  setFilter: (value: string) => void;
  defaultMethod?: PayMethod;
  method: PayMethod;
  setMethod: (value: PayMethod) => void;
  setRecipient: (value: Recipient | InternationalRecipient) => void;
  isNewRecipient: boolean;
  setIsNewRecipient: (value: boolean) => void;
  accountDetails: ParsedAccount;
  setAccountDetails: (value: ParsedAccount) => void;
  footer?: ReactNode;
  isPending: boolean;
  filterAccounts: () => ParsedAccount[];
};

const PaymentMethod = ({
  selectedRecipient,
  internationalAccountForm,
  newRecipient,
  onNextClick,
  onBackClick,
  recipientName,
  setFilter,
  defaultMethod,
  method,
  setMethod,
  setRecipient,
  isNewRecipient,
  setIsNewRecipient,
  accountDetails,
  setAccountDetails,
  footer,
  isPending,
  filterAccounts,
}: Props) => {
  const { classes, theme } = useStyles();
  const recipientId = selectedRecipient?.id;
  const sendToAddRecipientFlow = useSendToAddRecipientFlow(recipientId);
  const hasAddRecipientFlowFeatureFlag = useAddRecipientFlowFeatureFlag();
  const formRef = useRef<PaymentRecipientFormRef>(null);
  const internationalPaymentRecipientFormRef =
    useRef<InternationalPaymentRecipientFormRef>(null);
  const beneficiaryRequirementsFormRef =
    useRef<BeneficiaryRequirementsFormRef>(null);

  const filteredAccounts = filterAccounts();
  const hasAtLeastOneFilteredRecipient = filteredAccounts.length > 0;
  const hasMoreThanOneFilteredRecipient = filteredAccounts.length > 1;
  const isBookAccountType = accountDetails?.type === 'book';

  const [showSelect, setShowSelect] = useState(false);

  const derivedHeaderText = (c: ParsedAccount) =>
    c?.nickName || c?.bank || c?.name;

  const getCurrencySymbol = (currency: string) =>
    supportedCurrenciesData.find((cc) => cc.value === currency)?.symbol;

  const { productStatus } = useRecoilValue(ApplicationState);

  const hasActiveIntnlPayments =
    productStatus.internationalPayments.status === 'approved';

  const validateAndSubmitInternationalForms = () => {
    const initialFormRes =
      internationalPaymentRecipientFormRef?.current?.submitForm();
    const dynamicFormRes =
      beneficiaryRequirementsFormRef?.current?.submitForm();

    if (initialFormRes && dynamicFormRes) {
      if (initialFormRes.recipientType === 'company') {
        delete dynamicFormRes.firstName;
        delete dynamicFormRes.lastName;
        delete dynamicFormRes.dob;
      } else if (initialFormRes.recipientType === 'individual') {
        delete dynamicFormRes.companyName;
      }

      const newInternationalRecipient = {
        ...initialFormRes,
        ...dynamicFormRes,
      };

      setRecipient(newInternationalRecipient);
      onNextClick(newInternationalRecipient);
    }
  };

  const onNext = async () => {
    // if adding a new counterparty, add form will be shown.
    // submit the form results
    if (!hasAtLeastOneFilteredRecipient) {
      if (isInternationalPayMethod(method)) {
        validateAndSubmitInternationalForms();
        return;
      }

      const res = await formRef?.current?.submitForm(); // response is null if validation fails
      if (res) {
        setRecipient(res);
        onNextClick(res);
      }
    } else {
      // move to the next step
      onNextClick();
    }
  };

  const onBack = () => {
    if (isNewRecipient && !hasAtLeastOneFilteredRecipient) {
      setIsNewRecipient(false);
      setFilter('active_recipient_method');
    }
    onBackClick();
  };

  const handleNewRecipient = () => {
    setFilter('new_recipient');
    setAccountDetails(counterpartyInitial);
  };

  const handleAccountDetails = () => {
    if (
      accountDetails &&
      filteredAccounts.some((item) => item.id === accountDetails.id)
    ) {
      const result = filteredAccounts.find(
        (item) => item.id === accountDetails.id,
      );
      if (result) {
        setAccountDetails(result);
        setMethod(result.type);
      }
    } else if (hasAtLeastOneFilteredRecipient) {
      setAccountDetails(filteredAccounts[0]);
    } else {
      setAccountDetails(counterpartyInitial);
    }
  };

  useEffect(() => {
    if (defaultMethod) {
      setMethod(defaultMethod);
    }
  }, []);

  useEffect(() => {
    if (isNewRecipient) {
      handleNewRecipient();
    } else {
      handleAccountDetails();
    }
  }, [method, isNewRecipient, hasAtLeastOneFilteredRecipient]);

  const intlPaymentsFeatureFlagIsOn = useIntlPaymentsFeatureFlag();

  const existingPaymentMethodsForRecipient = new Set(
    filteredAccounts.map((acct) => acct.type),
  );
  const availableOptions = paymentMethodOptions
    .filter((option) => {
      if (!hasAddRecipientFlowFeatureFlag) {
        return true;
      }
      return existingPaymentMethodsForRecipient.has(option.value);
    })
    .filter((option) => {
      if (isInternationalPayMethod(option.value)) {
        return intlPaymentsFeatureFlagIsOn && hasActiveIntnlPayments;
      } else if (option.value === 'book') {
        return !!filteredAccounts.filter(
          (recipient) => recipient.type === 'book',
        ).length;
      }
      return true;
    });

  const handleMethodSelect = (value: PayMethod) => {
    setMethod(value);

    const commonValues = {
      name: recipientName,
      recipientType: selectedRecipient?.type ?? 'individual',
    };

    if (!isInternationalWire(value)) {
      internationalAccountForm.setValues({
        ...internationalAccountForm.values,
        ...commonValues,
        currency: 'USD',
      });
    } else {
      internationalAccountForm.setValues({
        ...commonValues,
      });
    }
  };

  const handleSendToDifferentAccount = () => {
    if (hasAddRecipientFlowFeatureFlag) {
      sendToAddRecipientFlow();
      return;
    }

    setIsNewRecipient(true);
    handleMethodSelect(method);
    setRecipient(recipientInitial);
  };

  const DropdownComponent = forwardRef<HTMLDivElement, DropdownWithActionProps>(
    (props, ref) => (
      <DropdownWithAction {...props} ref={ref} recipientId={recipientId} />
    ),
  );
  DropdownComponent.displayName = 'DropdownWithAction';

  return (
    <PaymentStep
      titleText={
        isNewRecipient
          ? `Complete payment details for ${recipientName}`
          : `Payment details for ${recipientName}`
      }
      onNextClick={onNext}
      onBackClick={onBack}
      footer={footer}
      loading={isPending}
    >
      <Text size="sm" my="md" fw="500">
        Payment information
      </Text>
      {availableOptions.length > 0 ? (
        <>
          <FlexbaseSelect
            value={method}
            data={availableOptions}
            id="method"
            label="Method"
            onChange={handleMethodSelect}
            dropdownComponent={
              hasAddRecipientFlowFeatureFlag ? DropdownComponent : null
            }
          />
          {method === 'ach' ? (
            <Text color={theme.fn.themeColor('neutral', 7)} size={10}>
              3 business days • No fee
            </Text>
          ) : null}
          {method === 'wire' && (
            <Text color={theme.fn.themeColor('neutral', 7)} size={10}>
              1 business day
            </Text>
          )}
        </>
      ) : (
        <SendToRecipientFlowButton recipientId={recipientId} />
      )}

      {!hasAtLeastOneFilteredRecipient &&
        (isInternationalPayMethod(method) ? (
          <>
            <InternationalPaymentAccountForm
              ref={internationalPaymentRecipientFormRef}
              form={internationalAccountForm}
              method={method}
              isNewRecipient={isNewRecipient}
            />
            <BeneficiaryRequirements
              formValues={internationalAccountForm.values}
              ref={beneficiaryRequirementsFormRef}
              newRecipient={newRecipient as InternationalRecipient}
            />
          </>
        ) : (
          <PaymentAccountForm
            ref={formRef}
            method={method}
            isNewRecipient={isNewRecipient}
            recipientName={recipientName}
            selectedRecipient={selectedRecipient}
          />
        ))}

      {!isNewRecipient && hasAtLeastOneFilteredRecipient ? (
        <Box mt="1rem" mb="1rem">
          <div className={classes.optionInfoContainer}>
            Recipient bank details
          </div>
          <AccountBox
            headerText={derivedHeaderText(accountDetails)}
            subheaderText={
              accountDetails &&
              !isBookAccountType && (
                <SubheaderText
                  method={method}
                  currency={accountDetails.currency}
                  accountNumber={accountDetails.accountNumber}
                  routingNumber={accountDetails?.routingNumber}
                  currencySymbol={
                    accountDetails.currency &&
                    getCurrencySymbol(accountDetails.currency)
                  }
                />
              )
            }
            onClick={() =>
              setShowSelect((prev) => {
                if (hasMoreThanOneFilteredRecipient) {
                  return !prev;
                }
                return prev;
              })
            }
            classNames={{ containerClassName: classes.containerIcon }}
            showArrow={hasMoreThanOneFilteredRecipient}
            rotateArrow={showSelect}
            showBorder
            isListItem={false}
            icon={<Icon method={method} flag={accountDetails?.country} />}
          />
          {showSelect && hasAtLeastOneFilteredRecipient ? (
            <div className={classes.selectList}>
              {filteredAccounts
                .filter((c) => c.id !== accountDetails.id)
                .map((c) => (
                  <AccountBox
                    headerText={derivedHeaderText(c)}
                    subheaderText={
                      !isBookAccountType && (
                        <SubheaderText
                          method={method}
                          currency={c.currency}
                          accountNumber={c.accountNumber}
                          routingNumber={c.routingNumber}
                          currencySymbol={
                            c.currency && getCurrencySymbol(c.currency)
                          }
                        />
                      )
                    }
                    onClick={() => {
                      setAccountDetails(c);
                      setShowSelect(false);
                    }}
                    classNames={{
                      containerClassName: classes.containerIcon,
                    }}
                    showArrow={false}
                    rotateArrow={false}
                    isListItem={true}
                    icon={<Icon method={method} flag={c?.country} />}
                    key={c.id}
                    data-testid={`accounts-list-${c.name}`}
                  />
                ))}
            </div>
          ) : null}
          <div
            className={classes.newAccount}
            onClick={handleSendToDifferentAccount}
          >
            Send to a different account
          </div>
        </Box>
      ) : null}
    </PaymentStep>
  );
};

export default PaymentMethod;
