import OnboardingEndScreen from '../../../components/onboarding-end-screen';
import DeclineGraphic from 'assets/images/decline-state.png';
import { useNavigate } from 'react-router-dom';
import { useAuthToken } from '../../../../../states/auth/auth-token';

const DeclineShortCircuit = () => {
  const { logout } = useAuthToken();
  const navigate = useNavigate();

  const closeApp = () => {
    logout();
  };

  return (
    <OnboardingEndScreen
      title="Sorry, we currently don’t offer this credit card in your state."
      description={
        'We will be in touch when that changes, and in the meantime we have added you to the early access list for Flex Banking!'
      }
      graphic={DeclineGraphic}
      primaryButtonText="Close application"
      onPrimaryButtonClick={() => closeApp()}
      secondaryButtonText="Edit application"
      onSecondaryButtonClick={() => {
        navigate(-1);
      }}
    />
  );
};
export default DeclineShortCircuit;
