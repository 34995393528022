import { useSpendPlansFeatureFlag } from '@utilities/feature-flags';
import { Navigate, Outlet } from 'react-router-dom';

export const SpendPlansGuard = () => {
  const isFeatureFlagEnabled = useSpendPlansFeatureFlag();

  if (!isFeatureFlagEnabled) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};
