import { Alert, Checkbox, Container, Text, createStyles } from '@mantine/core';
import PaymentStep from '../payment-step';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { FbIcon } from 'assets/svg';
import { GiAlarmClock } from 'react-icons/gi';

type Props = {
  onPaymentRequestReviewNext: () => void;
  backToPaymentRequestDetails: () => void;
};

const RequestReview = ({
  onPaymentRequestReviewNext,
  backToPaymentRequestDetails,
}: Props) => {
  const { classes } = useStyles();
  return (
    <PaymentStep
      titleText="Payment Request Review"
      showNextButton={true}
      showBackButton={true}
      backButtonLabel="Back"
      nextButtonLabel="Create payment link"
      onBackClick={backToPaymentRequestDetails}
      onNextClick={onPaymentRequestReviewNext}
    >
      <Container p="0" mt="1rem">
        <Text size="xl">{formatCurrency(1000)}</Text>
        <Text mt="1rem">to Charles Bonito (charles@flexbase.com)</Text>
      </Container>
      <Alert mt="1.5rem" p="lg" className={classes.alert}>
        Due Mar 24, 2023
      </Alert>
      <Container p="0" mt="1rem">
        <Text size="sm">Destination Account</Text>
        <Alert mt="1vh" className={classes.alert}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FbIcon />
            <div style={{ marginLeft: '1vh' }}>
              <Text style={{ fontWeight: 'bold' }}>Choice Checking</Text>
              <Text>Checking ••1734</Text>
            </div>
          </div>
        </Alert>
      </Container>
      <Container p="0" mt="1rem">
        <Text size="sm">Company name to show on request</Text>
        <Alert
          mt="1vh"
          className={classes.alert}
          style={{
            background: 'white',
          }}
        >
          <Text style={{ fontWeight: '400' }}>Flex Demo, Incorporated</Text>
        </Alert>
      </Container>
      <Alert mt="2rem" className={classes.alert}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <GiAlarmClock size={18} />
          Payment link expires on September 20, 2023
        </div>
      </Alert>
      <Checkbox
        mt="1rem"
        mb="1rem"
        label="Send email to charles@flexbase.com"
      />
    </PaymentStep>
  );
};

const useStyles = createStyles({
  alert: {
    border: '1px solid #e4e5fc',
    borderRadius: 8,
    background: '#f9f9fb',
  },
});

export default RequestReview;
