import { DateTime } from 'luxon';
import { RecoilState, useRecoilState } from 'recoil';
import { useCallback, useMemo } from 'react';

export type OptionalDateTime = DateTime | null;
export type DateRangeFilterType = {
  option: string;
  startDate: OptionalDateTime;
  endDate: OptionalDateTime;
};
export type FilterFn<T> = (item: T) => boolean;

/**
 * This is the main basis for filters.
 * The key is used to keep track of it.
 * The label is a readable version of the key.
 * The filterFn will be applied to an array of data to filter it.
 * Extras are there for any other data that needs to be kept track of in state,
 * or for edge cases. (For example, all filters for the credit transactions are
 * UI filters except for the date filter in the credit transactions table, which
 * must dispatch an API call).
 */
export type FilterData<T> = {
  key: string;
  label: string;
  fn: FilterFn<T>;
  filterValue?: any;
  showChip: boolean;
};
export type FilterFnMap<T> = Record<string, FilterData<T>>;

/**
 * A table filter hook should return this value.
 *
 * activeFilters: This is a map of all currently active filters (active being those filters that should be applied to the full list of data to filter it down). This should generally be a Recoil vstate
 * setActiveFilters: A way to set the entire activeFilters property as defined above
 * removeFilter: A function that will remove one filter from the map by key
 * addFilter: A function to add one filter to the map
 * activeFiltersArray: A function that returns an array of the map entries. This is available so places that need to iterate can do so easily
 */
export type ActiveFiltersReturnType<T> = {
  activeFilters: FilterFnMap<T>;
  setActiveFilters: (map: FilterFnMap<T>) => void;
  removeFilter: (key: string) => void;
  addFilter: (key: string, filter: FilterData<T>) => void;
  activeFiltersArray: FilterData<T>[];
  getFilterByKey: (key: string) => FilterData<T> | undefined;
  removeAllFilters: () => void;
  applyAllFilters: (filters: FilterFnMap<T>) => void;
};

/**
 * This is a convenience function provided to remove a value from the filter map. This should generally be used in
 * the removeFilter implementation for a specific filter hook
 * @param key
 * @param filterMap
 */
export function filterMapWithout<T>(
  key: string,
  filterMap: FilterFnMap<T>,
): FilterFnMap<T> {
  return Object.entries(filterMap)
    .filter(([_key]) => _key !== key)
    .reduce<FilterFnMap<T>>((prev, [_key, fn]) => {
      prev[_key] = fn;
      return prev;
    }, {});
}

export function createUseFiltersHook<T>(
  recoilState: RecoilState<FilterFnMap<T>>,
): ActiveFiltersReturnType<T> {
  const [activeFilters, setActiveFilters] = useRecoilState(recoilState);

  const addFilter = (key: string, filterFn: FilterData<T>) => {
    setActiveFilters((prev) => ({ ...prev, [key]: filterFn }));
  };

  const removeFilter = (key: string) => {
    const _filters = filterMapWithout(key, activeFilters);
    setActiveFilters(_filters);
  };

  const activeFiltersArray = useMemo(() => {
    return Object.values(activeFilters);
  }, [activeFilters]);

  const getFilterByKey = useCallback(
    (key: string) => {
      return activeFilters[key];
    },
    [activeFilters],
  );
  const removeAllFilters = () => {
    setActiveFilters({});
  };

  const applyAllFilters = (filters: FilterFnMap<T>) => {
    setActiveFilters(filters);
  };

  return {
    activeFilters,
    setActiveFilters,
    addFilter,
    removeFilter,
    activeFiltersArray,
    getFilterByKey,
    removeAllFilters,
    applyAllFilters,
  };
}
