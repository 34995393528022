import { Group, TextInput } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { SearchIcon } from 'assets/svg';
import { PropsWithChildren, useState } from 'react';
import ExternalAccountsTable from './external-accounts-table';
import { useStyles } from './external-accounts.styles';

type Props = PropsWithChildren & {
  tableData: any;
  primaryButton: JSX.Element;
};

const ExternalAccountsContent = ({
  tableData,
  primaryButton,
  children,
}: Props) => {
  const { classes } = useStyles();
  const [filterText, setFilterText] = useState('');
  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <div className={classes.container}>
      {children}
      <Group
        position="apart"
        style={{ flexDirection: isMobile ? 'column' : 'initial' }}
      >
        <TextInput
          w={isMobile ? '100%' : 500}
          icon={<SearchIcon />}
          placeholder="Search external accounts"
          onChange={(e) => setFilterText(e.target.value)}
        />
        {primaryButton}
      </Group>
      <div className={classes.tableContainer}>
        <ExternalAccountsTable searchText={filterText} accounts={tableData} />
      </div>
    </div>
  );
};

export default ExternalAccountsContent;
