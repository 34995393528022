import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

export const PendingUrlState = atom<string>({
  key: 'PendingUrlState', //pending url
  default: '',
  effects: [
    recoilPersist({
      key: 'flexbase_pending_url',
      storage: localStorage,
    }).persistAtom,
  ],
});
