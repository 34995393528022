import { filterDirectionToLabel } from '@common/filter/amount-filter';
import { formatCurrency } from '@flexbase-eng/web-components';
import { QuantityFilter } from '@common/filter/quantity-filter';
import { useSpendCardModalFilters } from '../use-spend-cards-filter';

export const AmountAssignedFilter = () => {
  const { addFilter, getFilterByKey } = useSpendCardModalFilters();
  return (
    <QuantityFilter
      value={
        getFilterByKey('remainingPtd')?.filterValue || {
          amount: '',
          direction: 'equal',
        }
      }
      onChange={(val) => {
        if (!val.amount) return;
        addFilter('remainingPtd', {
          label: `Amount assigned - ${filterDirectionToLabel(
            val.direction,
          )} ${formatCurrency(val.amount)}`,
          key: 'remainingPtd',
          fn: (row) => {
            const amountNum = +row.remainingPtd;
            const filterNum = +val.amount;
            switch (val.direction) {
              case 'greaterThan':
                return amountNum > filterNum;
              case 'lessThan':
                return amountNum < filterNum;
              case 'equal':
              default:
                return amountNum === filterNum;
            }
          },
          filterValue: val,
          showChip: true,
        });
      }}
    />
  );
};
