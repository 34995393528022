import { FormEvent, ReactNode } from 'react';

type Props = { id: string; onFormSubmit: () => void; children: ReactNode };

export const MultiStepFormStepWrapper = ({
  id,
  onFormSubmit,
  children,
}: Props) => {
  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    onFormSubmit();
  };

  return (
    <form id={id} onSubmit={handleFormSubmit}>
      {children}
    </form>
  );
};
