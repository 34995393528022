import { Box, Grid, Text } from '@mantine/core';
import { PropsWithChildren } from 'react';

type Props = {
  headline: string;
  subtitle: string;
} & PropsWithChildren;

const SummaryDetailLayout = ({ headline, subtitle, children }: Props) => {
  return (
    <Box>
      <Text size="sm" c="neutral.7">
        {subtitle}
      </Text>
      <Grid justify="space-between" align="center" mr="md">
        <Grid.Col xs={12} sm={7} lg={8} xl={9}>
          <Text size={32} weight={500}>
            {headline}
          </Text>
        </Grid.Col>
        <Grid.Col xs={12} sm={5} lg={4} xl={3}>
          {children}
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default SummaryDetailLayout;
