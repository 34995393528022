import { CheckmarkIcon, ClockIcon } from '../../../assets/svg';
import { Box } from '@mantine/core';
import { SalesBox } from '@common/sales/sales-box';
import { FakeNotificationStack } from '@common/sales/fake-notification-stack';

export const CreditSalesBoxes = [
  <SalesBox
    flavorText="0% Interest For 60 Days"
    title="Designed for more cash flow"
    key="sales-box-1"
  >
    <FakeNotificationStack
      mt={92}
      icon={<ClockIcon />}
      text="Your bill is due in 60 days"
    />
  </SalesBox>,
  <SalesBox
    flavorText="Credit Limits"
    title="Credit limits that grow with your business"
    key="sales-box-2"
  >
    <Box mt="xl">
      <img src="/images/sales-chart.png" alt="Sales Chart" />
    </Box>
  </SalesBox>,
  <SalesBox
    flavorText="Expense Management"
    title="Effortlessly manage all your expenses"
    style={{
      paddingBottom: 0,
    }}
    key="sales-box-3"
  >
    <img
      src="/images/iphone-14-app-graphic.png"
      alt="Flex app on iPhone 14"
      style={{ height: 228, marginTop: 'auto' }}
    />
  </SalesBox>,
  <SalesBox
    flavorText="Issue Cards"
    title="Individual employee cards, at no extra cost"
    key="sales-box-4"
  >
    <FakeNotificationStack
      mt={92}
      icon={<CheckmarkIcon />}
      text="Virtual card issued"
    />
  </SalesBox>,
];
