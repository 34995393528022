// Don't git blame me I just moved this stuff mostly

import { SortFunction } from 'react-data-table-component';
import { getLocaleMMDDYYYY } from '@utilities/formatters/format-date-string';
import { formatCurrency } from '@utilities/formatters/format-currency';
import {
  CreditAuthInfo,
  mapTransactionStatusToBusinessStatus,
} from '@services/flexbase/flexbase-onboarding-client';
import { Expenses } from '@flexbase-eng/types/dist/accounting';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseSelector<T extends Record<string, any>>(
  row: T,
  selector: string,
): T {
  return selector.split('.').reduce((acc, part) => {
    // O(n2) when querying for an array (e.g. items[0].name)
    // Likely, the object depth will be reasonable enough that performance is not a concern
    const arr = part.match(/[^\]\\[.]+/g);
    if (arr && arr.length > 1) {
      for (let i = 0; i < arr.length; i++) {
        return acc[arr[i]][arr[i + 1]];
      }
    }

    return acc[part];
  }, row);
}

export type CreditTransactionsTableRow = {
  keyField: string;
  id: string;
  date: string;
  purchaseDate?: string;
  settledAt?: string;
  type: string;
  paymentType?: string;
  name: string;
  toFrom: string;
  amount: string;
  status: string;
  description: string;
  docId: string;
  logoUrl: string;
  cardName?: string;
  cardType?: string;
  storeCity?: string;
  storeState?: string;
  storePostalCode?: string;
  storeCategory?: string;
  storeId?: string;
  sourceLast4: string;
  sourceType?: string;
  sourceAccountName?: string;
  creditOrDebit: string;

  /**
   * Server side synced expense data
   */
  syncedExpense?: Expenses;

  /**
   * Client side current value
   */
  selectedCategory?: {
    id: string;
    name: string | undefined;
    status: 'loading' | undefined;
  };
  authInfo?: CreditAuthInfo;
  spendName?: string;
};

export const creditSorter: SortFunction<CreditTransactionsTableRow> = (
  rows,
  selector,
  direction,
) => {
  return rows.slice(0).sort((a, b) => {
    let aValue;
    let bValue;
    if (typeof selector === 'string') {
      aValue = parseSelector(a, selector);
      bValue = parseSelector(b, selector);
    } else {
      aValue = selector(a);
      bValue = selector(b);
    }
    if (direction === 'asc') {
      if (aValue < bValue) {
        return -1;
      }
      if (aValue > bValue) {
        return 1;
      }
    }
    if (direction === 'desc') {
      if (aValue > bValue) {
        return -1;
      }
      if (aValue < bValue) {
        return 1;
      }
    }
    return 0;
  });
};

type CreditTransactionsCsvCustomRow = {
  custom: boolean;
  endingBalance?: string;
  beginningBalance?: string;
};
type CreditTransactionsCsvData =
  | {
      CreatedDate: string;
      SettledDate: string;
      Name: string;
      Transaction: string;
      Amount: string;
      Type: string;
      CreditOrDebit: string;
      SourceLast4: string;
      Status: string;
    }
  | CreditTransactionsCsvCustomRow;

export function formatTableDataForCsv(
  rows: CreditTransactionsTableRow[],
): CreditTransactionsCsvData[] {
  return rows.map((data) => {
    const amountAsNumber = Number(data.amount);

    return {
      CreatedDate: data.purchaseDate
        ? getLocaleMMDDYYYY(data.purchaseDate)
        : getLocaleMMDDYYYY(data.date ?? ''),
      SettledDate: getLocaleMMDDYYYY(data.settledAt ?? ''),
      Name: data.name,
      Transaction: data.toFrom,
      Amount:
        amountAsNumber < 0
          ? `- ${formatCurrency(Math.abs(amountAsNumber))}`
          : formatCurrency(data.amount),
      Type: data.type,
      Memo: `${data.description || ''}`,
      CreditOrDebit: data.creditOrDebit,
      SourceLast4: data.sourceLast4,
      Status: mapTransactionStatusToBusinessStatus(data),
    };
  });
}
