import { useForm } from '@mantine/form';
import {
  ConditionalFieldValidator,
  RequiredFieldValidator,
} from '@utilities/validators/validate-required';
import { useCreateSpendPlanWizard } from '../../create-spend-plan.wizard';

export type SpendControlsStepFormValues = {
  currentLimit: number | undefined;
  repeatCurrentLimit: boolean;
  futureLimit: number | undefined;
};

export const SpendControlsFormInitialValues: SpendControlsStepFormValues = {
  currentLimit: undefined,
  repeatCurrentLimit: true,
  futureLimit: undefined,
};

const SpendControlsFormKeys = Object.keys(
  SpendControlsFormInitialValues,
) as (keyof SpendControlsStepFormValues)[];

type UseSpendControlsFormProps = {
  initialValues: SpendControlsStepFormValues;
};

export const useSpendControlsForm = ({
  initialValues,
}: UseSpendControlsFormProps) => {
  const { state } = useCreateSpendPlanWizard();
  const isOneTimePlan = state.recurring === 'onetime';

  // ensure the form doesn't contain unrelated values
  initialValues = SpendControlsFormKeys.reduce<SpendControlsStepFormValues>(
    (acc, k) => {
      return {
        ...acc,
        [k]: initialValues[k],
      };
    },
    { ...SpendControlsFormInitialValues },
  );

  return useForm<SpendControlsStepFormValues>({
    initialValues,
    validate: {
      currentLimit: RequiredFieldValidator(),
      futureLimit: (value, formValues) =>
        ConditionalFieldValidator(
          !isOneTimePlan && !formValues.repeatCurrentLimit,
        )(value),
    },
  });
};
