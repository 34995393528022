import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  baseContainer: {
    width: '100%',
  },
  widgetContainer: {
    borderRadius: theme.defaultRadius,
    flexDirection: 'row',
    backgroundColor: 'white',
    padding: '1.5rem',
    '@media(max-width: 767px)': {
      width: '100%',
    },
  },
  storeName: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    wordWrap: 'normal',
  },
  storeIcon: {
    height: '32px',
    width: '32px',
    color: `${theme.fn.primaryColor()}`,
    '> div:first-of-type': {
      color: `${theme.fn.primaryColor()} !important`,
      backgroundColor: '#FFEEEC !important',
    },
    marginRight: '10px',
  },
  date: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#5F5F5F',
    lineHeight: '21px',
  },
  badge: {
    color: '#000',
    fontWeight: 500,
    fontSize: '14px',
    marginTop: '25px',
    padding: '15px 20px',
    backgroundColor: '#F5F6F8',
    textTransform: 'capitalize',
  },
  amount: {
    color: theme.fn.themeColor('neutral', 9),
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.313rem',
  },
  errorMessage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
