import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  container: {
    display: 'inline-block',
    overflow: 'auto',
    '@media(max-width: 767px)': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
  },
  issueContainer: {},
  title: {
    color: '#000000',
    fontFamily: 'PP Neue Montreal',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 28,
    lineHeight: '34px',
  },
  inputHint: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '15px',
    color: '#4B4B4B',
    marginTop: 4,
    marginLeft: 4,
  },
  optionPaymentBox: {
    display: 'grid',
    margin: '10px 0px',
    gridTemplateColumns: '40px auto',
    alignItems: 'center',
    border: '1px solid #E6E7E9',
    borderRadius: '8px',
    padding: '10px 15px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(241, 241, 241, 0.3)',
      cursor: 'pointer',
    },
  },
  optionText: {
    color: theme.colors.blackish[1],
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 500,
  },
  optionInfoContainer: {
    color: theme.colors.blackish[0],
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
  },
  rolesLabel: {
    marginTop: '20px',
    color: theme.colors.primarySecondarySuccess[8],
    fontSize: '20px',
    fontWeight: 400,
  },
  rolesDescription: {
    color: theme.colors.primarySecondarySuccess[8],
    fontSize: '14px',
    marginBottom: '15px',
  },
  link: {
    color: theme.colors.primarySecondarySuccess[2],
    alignItems: 'center',
    display: 'inline-flex',
  },
  inputContainer: {
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
  },
}));
