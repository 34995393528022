import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  input: {
    fontFamily: 'PP Neue Montreal',
    fontSize: '48px',
    textAlign: 'center',
    height: 'fit-content',
    border: 'unset',
    borderRadius: 'unset',
    display: 'flex',
    '@media(max-width: 767px)': {
      fontSize: '2rem',
      marginTop: '1rem',
    },
    borderBottom: `3px solid ${theme.fn.themeColor(
      'primarySecondarySuccess',
      2,
    )}`,
  },
  inputRoot: { width: 'fit-content' },
  icon: { opacity: 0.8 },
  sectionHeader: {
    fontWeight: 500,
    fontSize: '14px',
    color: theme.fn.themeColor('primarySecondarySuccess', 8),
    marginTop: '1rem',
    marginBottom: '8px',
  },
  selectList: {
    position: 'absolute',
    maxHeight: '250px',
    width: '356px',
    overflow: 'scroll',
    marginTop: 4,
    backgroundColor: theme.fn.themeColor('neutral', 0),
    zIndex: 1,
    boxShadow: theme.shadows.sm,
    borderRadius: theme.defaultRadius,
  },
  textArea: {
    height: '152px',
  },
  filesContainer: {
    minHeight: '50px',
    display: 'column',
    justifyContent: 'center',
  },
  file: {
    width: '100%',
    height: '40px',
    margin: '8px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px',
    fontSize: '12px',
    fontWeight: 500,
    color: theme.fn.themeColor('neutral', 6),
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
  },
  deleteFile: {
    all: 'unset',
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  dropZone: {
    marginBottom: '1rem',
    gap: 10,
    padding: 12,
    width: '100%',
    fontSize: 14,
    fontWeight: 400,
    display: 'flex',
    justifyContent: 'center',
    color: theme.fn.themeColor('neutral', 6),
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
    '&:hover': {
      backgroundColor: theme.fn.themeColor('neutral', 2),
    },
  },
}));
