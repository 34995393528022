import { Box, Center, Input, Loader, Text } from '@mantine/core';
import { MultiStepFormStepWrapper } from '@common/multi-step-form/multi-step-form-step-wrapper';
import { RadioSelectList } from '@common/radio/radio-select-list';
import { useForm } from '@mantine/form';
import { RequiredFieldValidator } from '@utilities/validators/validate-required';
import { OnboardingCompany } from '../../../../states/onboarding/onboarding-info';
import { flexbaseOnboardingClient } from '@services/flexbase-client';
import { usePrefillMultistepFormContext } from '../../pages/prefill/prefill-multi-step-form.context';
import { useOnboardingStatusContext } from '../../../../providers/onboarding-status-context';
import { usePlatformPrefillContext } from '../../../../providers/platform-prefill.context';
import { useEffect } from 'react';

export const PrefillBusinessStep = () => {
  const { setState, goToNextStep } = usePrefillMultistepFormContext();
  const {
    businessPrefillData: businessPrefillResult,
    businessPrefillLoading: loadingPrefill,
  } = usePlatformPrefillContext();
  const {
    status: { company },
  } = useOnboardingStatusContext();
  const businessForm = useForm({
    initialValues: {
      businessName: '',
    },
    validate: {
      businessName: RequiredFieldValidator(),
    },
  });

  const noPrefillResults =
    !businessPrefillResult || businessPrefillResult.businesses.length === 0;

  useEffect(() => {
    if (!loadingPrefill && noPrefillResults) {
      goToNextStep({ businessPrefillAvailable: false });
    }
  }, [noPrefillResults, loadingPrefill]);

  if (noPrefillResults) {
    if (loadingPrefill) {
      return (
        <Center h="5rem">
          <Loader variant="dots" />
        </Center>
      );
    }

    return false;
  }

  const handleFormSubmit = async () => {
    const validationResult = businessForm.validate();
    if (!validationResult.hasErrors) {
      setState({ error: null });
      if (businessForm.values.businessName === 'none') {
        goToNextStep();
      } else {
        const foundBusiness = businessPrefillResult.businesses.find(
          (b) => b.business.name === businessForm.values.businessName,
        );
        const foundBusinessAddress = foundBusiness?.addresses[0];
        const request: Partial<OnboardingCompany> = {
          id: company.id,
          taxId: foundBusiness?.business.taxId,
          companyName: foundBusiness?.business.name,
          doingBusinessAs: foundBusiness?.business.dba,
          formationDate: foundBusiness?.business.formationDate,
          formationState: foundBusiness?.business.formationLocation,
          ...(foundBusinessAddress && {
            address: {
              line1: foundBusinessAddress.street,
              city: foundBusinessAddress.locality,
              state: foundBusinessAddress.region,
              postalCode: foundBusinessAddress.postalCode,
            },
          }),
        };
        try {
          setState({ loading: true });
          await flexbaseOnboardingClient.updateCompany(request);
          goToNextStep();
        } catch {
          setState({
            error:
              'An error occurred while trying to update your business information',
          });
        } finally {
          setState({ loading: false });
        }
      }
    }
  };

  return (
    <MultiStepFormStepWrapper id="business" onFormSubmit={handleFormSubmit}>
      <Text data-testid="business-prefill-description">
        We may be able to prefill some of your business information. Please
        select the business that you want to continue with. You will be able to
        edit these values later.
      </Text>
      <Input.Wrapper
        label={`Select your business`}
        mt="xl"
        {...businessForm.getInputProps('businessName')}
      >
        <RadioSelectList
          selected={businessForm.values.businessName}
          onRadioCardClick={(val) => {
            businessForm.setFieldValue('businessName', val);
          }}
          radioOptions={businessPrefillResult.businesses
            .map((b) => ({
              title: `${b.business.name}${
                b.business.dba ? ` d/b/a ${b.business.dba}` : ''
              }`,
              description: (
                <Box>
                  {b.addresses?.[0] && (
                    <Box>
                      <Text>{b.addresses[0].street}</Text>
                      <Text>
                        {b.addresses[0].locality}, {b.addresses[0].region}{' '}
                        {b.addresses[0].postalCode}
                      </Text>
                    </Box>
                  )}
                </Box>
              ),
              value: b.business.name,
            }))
            .concat([
              {
                title: 'Add a different business',
                description: <>I will manually enter my business information</>,
                value: 'none',
              },
            ])}
          radioCardProps={{ w: '100%' }}
        />
      </Input.Wrapper>
    </MultiStepFormStepWrapper>
  );
};
