import { createStyles, Stepper } from '@mantine/core';
import { StepBaseIcon, StepCompleteIcon, StepTodoIcon } from 'assets/svg';

type StepperProps = {
  activeStep: number;
  stepsData: string[];
};

const FlexbaseStepper = ({ activeStep, stepsData }: StepperProps) => {
  const { classes } = useStyles();
  return (
    <Stepper
      iconSize={20}
      active={activeStep}
      classNames={classes}
      orientation={screen.width <= 767 ? 'horizontal' : 'vertical'}
      progressIcon={<StepTodoIcon />}
      completedIcon={<StepCompleteIcon />}
    >
      {stepsData.map((step, i) => (
        <Stepper.Step key={i} label={step} icon={<StepBaseIcon />} />
      ))}
    </Stepper>
  );
};

export default FlexbaseStepper;

const useStyles = createStyles((theme) => ({
  separator: {
    borderRadius: theme.defaultRadius,
    backgroundColor: theme.colors.neutral[3],
  },

  separatorActive: {
    backgroundColor: theme.colors.primarySecondarySuccess[2],
  },

  stepIcon: {
    borderColor: 'transparent',
    backgroundColor: theme.colors.neutral[3],
    borderWidth: 0,
    color: theme.colors.primarySecondarySuccess[2],
  },

  stepCompletedIcon: {
    borderColor: 'transparent',
    backgroundColor: theme.colors.neutral[3],
    borderWidth: 0,
    borderRadius: '12px',
  },

  stepLabel: {
    fontSize: '16px',
    fontWeight: 500,
    color: theme.fn.primaryColor(),
    marginTop: '4px',
    '@media(max-width: 767px)': {
      fontSize: '12px',
    },
  },

  root: {
    marginTop: '35px',
    '@media(max-width: 767px)': {
      marginTop: 0,
    },
  },

  verticalSeparator: {
    left: 'calc(1.25rem / 2.3)',
    borderLeftColor: theme.colors.neutral[3],
  },
}));
