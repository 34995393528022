import { useNavigate } from 'react-router-dom';
import { useRouteSectionContext } from '@common/routes/route-context';
import { AspectRatio, Badge, Box, Button, Image, Text } from '@mantine/core';
import { useUpdateCompany } from '@queries/use-company';
import Globe from 'assets/images/globe.png';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'states/application/product-onboarding';
import { useCardCTAStyles } from './styles';
import { ProductApplicationRoutes } from '../../onboarding-v2/onboarding.constants';

type PageCopyKey =
  | 'unused'
  | 'pending'
  | 'approved'
  | 'incomplete'
  | 'awaitingDocuments'
  | 'pendingOfficers'
  | 'ready'
  | 'unqualified'
  | 'unqualifiedVertical';

const IntlWiresCTA = () => {
  const navigate = useNavigate();
  const { classes, theme } = useCardCTAStyles();
  const { setSectionAndNavigate } = useRouteSectionContext();
  const { mutate: updateCompany, isPending } = useUpdateCompany();
  const { company, productStatus } = useRecoilValue(ApplicationState);

  const intlWireStatus = productStatus.internationalPayments.appStatus;
  const isUnusedStatus = intlWireStatus === 'unused';
  const isAwaitingDocumentsStatus = intlWireStatus === 'awaitingDocuments';

  const redirectToApplication = () => {
    if (!company.optedProducts.includes('INTERNATIONAL_PAYMENTS')) {
      updateCompany(
        {
          id: company.id,
          optedProducts: [...company.optedProducts, 'INTERNATIONAL_PAYMENTS'],
        },
        {
          onSuccess: () => {
            setSectionAndNavigate(
              'application',
              ProductApplicationRoutes.INTL_PAYMENTS_ONLY,
            );
          },
        },
      );
    } else {
      setSectionAndNavigate(
        'application',
        ProductApplicationRoutes.INTL_PAYMENTS_ONLY,
      );
    }
  };

  const intlWiresCopy: Record<
    PageCopyKey,
    { subtitle: string; button?: string; badge?: string; onClick?: () => void }
  > = {
    unused: {
      subtitle:
        'Add international wire capability to your Flex banking accounts by completing a quick application.',
      button: 'Enable',
      onClick() {
        redirectToApplication();
      },
    },
    incomplete: {
      subtitle:
        'Add international wire capability to your Flex banking accounts by continuing your application.',
      button: 'Continue application',
      onClick() {
        redirectToApplication();
      },
    },
    ready: {
      subtitle: 'Your application is ready for submission. Submit now!',
      button: 'Submit application',
      onClick() {
        redirectToApplication();
      },
    },
    pendingOfficers: {
      subtitle:
        'Enable sending payments internationally by completing a quick application.',
      button: 'Continue application',
      badge: 'Pending officers',
      onClick() {
        navigate('/banking/actions-required');
      },
    },
    pending: {
      subtitle: 'Your application is under review with the Flex team!',
      badge: 'Pending review',
    },
    awaitingDocuments: {
      subtitle:
        'Enable sending payments internationally by completing a quick application.',
      badge: 'Documents required',
      button: 'Upload documents',
      onClick() {
        navigate('/banking/actions-required');
      },
    },
    approved: {
      subtitle:
        'Send international payments from your Flex banking account(s).',
      button: 'Send wire',
      onClick() {
        navigate('/payments/outgoing/recipient');
      },
    },
    unqualified: {
      subtitle:
        'Sorry, at this time you are not qualified for international wires.',
    },
    unqualifiedVertical: {
      subtitle: `Sorry, at this time we do not support your business vertical for international wires.`,
    },
  };

  let content = (intlWireStatus as PageCopyKey) || 'unused';
  if (productStatus.internationalPayments.invalidOfficers?.length) {
    content = 'pendingOfficers';
    /** internationalPayments.appStatus could be 'approved' but
     * internationalPayments.status could be 'incomplete' if some
     * new requirements need to be provided
     */
  } else if (productStatus.internationalPayments.status === 'incomplete') {
    content = 'incomplete';
  } else if (productStatus.internationalPayments.appStatus === 'unqualified') {
    content = productStatus.internationalPayments.reason
      ?.toLowerCase()
      .includes('business vertical')
      ? 'unqualifiedVertical'
      : 'unqualified';
  }

  return (
    <Box className={classes.card}>
      <Box className={classes.contentCard}>
        {intlWiresCopy[content].badge && (
          <Badge
            styles={{
              inner: {
                fontSize: theme.spacing.md,
                color: theme.fn.themeColor(
                  `${isAwaitingDocumentsStatus ? 'critical' : 'tertiary'}`,
                  6,
                ),
              },
              root: {
                background: theme.fn.themeColor(
                  `${isAwaitingDocumentsStatus ? 'critical' : 'tertiary'}`,
                  0,
                ),
              },
            }}
          >
            {intlWiresCopy[content].badge}
          </Badge>
        )}
        <Box my="sm" data-testid="international-wires-cta">
          <Text size="md" color="primarySecondarySuccess.8">
            International wires
          </Text>
          <Text size="sm" color="neutral.7" data-testid="cta-description">
            {intlWiresCopy[content].subtitle}
          </Text>
        </Box>
        {intlWiresCopy[content].button && (
          <Button
            loading={isPending}
            onClick={intlWiresCopy[content].onClick}
            variant={isUnusedStatus ? 'default' : 'outline'}
            c={isUnusedStatus ? 'neutral.0' : 'primarySecondarySuccess.8'}
            sx={() => ({
              backgroundColor: isUnusedStatus
                ? theme.fn.themeColor('primarySecondarySuccess', 6)
                : 'unset',
              '&:not([data-disabled])': theme.fn.hover({
                backgroundColor: isUnusedStatus
                  ? theme.fn.themeColor('primarySecondarySuccess', 6)
                  : 'unset',
              }),
            })}
            data-testid="cta-button"
          >
            {intlWiresCopy[content].button}
          </Button>
        )}
      </Box>
      <AspectRatio ratio={1} w={250} h={150}>
        <Image src={Globe} />
      </AspectRatio>
    </Box>
  );
};

export default IntlWiresCTA;
