import FlexbaseDefaultHeader from 'components/header/current/flexbase-header';
import { NavTab } from 'components/tabs/nav-tabs';
import { Outlet, useNavigate } from 'react-router-dom';
import FlexbaseMainLayout from 'components/layouts/main';
import { ApplicationState } from 'states/application/product-onboarding';
import { useRecoilValue } from 'recoil';
import { NavTabContainer } from '@common/tabs/nav-tab-container';
import Banking from 'areas/banking/banking';
import { useGetBankingPayments } from '@queries/use-payments';
import { Badge, useMantineTheme } from '@mantine/core';
import { UserInfoState } from 'states/user/user-info';

const PendingApprovalCount = () => {
  const theme = useMantineTheme();
  const { isLoading, data, isError } = useGetBankingPayments();
  const user = useRecoilValue(UserInfoState);

  if (isError) {
    return null;
  }

  const count =
    data?.filter(
      (payment) =>
        payment.status === 'AwaitingApproval' ||
        (payment.status === 'AwaitingConfirmation' &&
          (payment.approvedBy === user.id || payment.userId === user.id)),
    ).length ?? 0;

  const getConditionalStyle = (style: string) =>
    isLoading ? 'transparent' : style;

  const badgeStyles = {
    root: {
      backgroundColor: getConditionalStyle(
        theme.fn.themeColor('primarySecondarySuccess', 3),
      ),
      border: getConditionalStyle('inherit'),
    },
    inner: {
      color: getConditionalStyle('#fff'),
    },
  };

  return <Badge styles={badgeStyles}>{count}</Badge>;
};

const PaymentDashboard = () => {
  const { productStatus, excludedProducts } = useRecoilValue(ApplicationState);
  const navigate = useNavigate();
  const tabs: NavTab[] = [
    { label: 'Activity', route: '/payments/outgoing' },
    {
      label: `Action required`,
      route: '/payments/approvals',
      count: <PendingApprovalCount />,
    },
    {
      label: 'Scheduled',
      route: '/payments/scheduled',
    },
    {
      label: 'Recipients',
      route: '/payments/recipients',
    },
  ];

  if (excludedProducts.includes('BANKING')) {
    if (!excludedProducts.includes('CREDIT')) {
      navigate('/cards', { replace: true });
    }
    return false;
  }

  return productStatus.banking.appStatus !== 'approved' ? (
    <Banking />
  ) : (
    <FlexbaseMainLayout>
      <FlexbaseDefaultHeader title={'Payments'} />
      <NavTabContainer tabs={tabs}>
        <Outlet />
      </NavTabContainer>
    </FlexbaseMainLayout>
  );
};

export default PaymentDashboard;
