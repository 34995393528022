import {
  Button,
  createStyles,
  Image,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { DownloadIcon } from 'assets/svg';
import SuccessTransfer from 'assets/images/transferred-banking.png';
import {
  DepositAccount,
  PlaidAccount,
} from 'areas/banking/move-funds/move-funds.model';
import { useMediaQuery } from '@mantine/hooks';

const useStyles = createStyles((theme) => ({
  successContainer: {
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    height: '100vh',
  },
  checkCircle: {
    background: 'rgba(39, 194, 129, 0.1)',
    width: 72,
    height: 72,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkmark: {
    color: '#27C281',
    fontSize: 40,
  },
  infoText: {
    lineHeight: '40px', // In this case using a number does not set it to px..
    textAlign: 'center',
    width: '624px',
    fontSize: '36px',
    fontStyle: 'normal',
    fontWeight: 400,
    marginTop: theme.spacing.lg,
  },
  infoTextMobile: {
    lineHeight: '40px', // In this case using a number does not set it to px..
    textAlign: 'center',
    width: '350px',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    marginTop: theme.spacing.lg,
  },
  buttons: {
    borderRadius: theme.defaultRadius,
    width: '100%',
    marginTop: theme.spacing.xs,
    color: theme.colors.primarySecondarySuccess[2],
  },
  downloadButton: {
    background: '#FFF',
    borderRadius: '8px',
    width: '100%',
    border: `1px solid ${theme.fn.primaryColor()}`,
    padding: '10px 5px',
    color: theme.fn.primaryColor(),
    '&:hover': {
      backgroundColor: 'rgba(255, 87, 69, 0.1)',
    },
  },
  downloadReceiptText: {
    color: theme.fn.primaryColor(),
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineheight: '16px',
  },
  description: {
    fontSize: 14,
    color: '#5F5F5F',
    textAlign: 'center',
    margin: '6px auto 0px auto',
    width: '408px',
  },
  descriptionMobile: {
    fontSize: 14,
    color: '#5F5F5F',
    textAlign: 'center',
    margin: '6px auto 0px auto',
    width: '350px',
  },
  receiptButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  receiptButtonColor: {
    color: theme.colors.primarySecondarySuccess[2],
  },
  bottomButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing.lg,
  },
}));

type Props = {
  paymentId: string;
  paymentAmount: number;
  paymentAccount?: PlaidAccount | DepositAccount;
  onCloseClick: () => void;
  setStep: React.Dispatch<React.SetStateAction<'review' | 'success' | 'error'>>;
};

const PaymentSuccess = ({
  paymentId,
  onCloseClick,
  paymentAmount,
  paymentAccount,
  setStep,
}: Props) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery('(max-width: 767px)');

  const { classes } = useStyles();
  const message =
    paymentAccount?.plaidOrDeposit === 'deposit'
      ? 'Your payment is being processed!'
      : 'Processing of your payment may take up to 4 business days. Upon successful settlement, your credit balance will be updated.';

  return (
    <div className={classes.successContainer}>
      <Image src={SuccessTransfer} width={260} height={142} />
      <Text
        ff="Redaction"
        className={isMobile ? classes.infoTextMobile : classes.infoText}
        data-testid="successful-payment-text"
      >
        Success! {formatCurrency(paymentAmount)} has been paid towards your Flex
        credit card.
      </Text>

      <div className={classes.buttons}>
        <div className={classes.receiptButton}>
          <Button
            variant="subtle"
            size="lg"
            onClick={() =>
              window.open(`/payment/card-receipt/${paymentId}`, '_blank')
            }
          >
            <div className={classes.receiptButton}>
              <Text
                ml="0.5vh"
                className={classes.receiptButtonColor}
                mr={10}
                color="primarySecondarySuccess.2"
              >
                Download receipt
              </Text>
              <DownloadIcon
                color="primarySecondarySuccess.2"
                width={20}
                height={20}
                className={classes.receiptButtonColor}
              />
            </div>
          </Button>
        </div>

        <Text
          className={isMobile ? classes.descriptionMobile : classes.description}
        >
          {message}
        </Text>
        <div className={classes.bottomButtons}>
          <Button
            variant="outline"
            mt="md"
            size="lg"
            onClick={() => onCloseClick()}
            data-testid="go-back-to-credit-button"
            mr={theme.spacing.lg}
            w={150}
          >
            Back to credit
          </Button>
          <Button
            variant="light"
            mt="1rem"
            size="lg"
            onClick={() => setStep('review')}
            data-testid="make-another-payment-button"
            w={200}
          >
            Make another payment
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
