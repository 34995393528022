import { SimpleSegmentSelectedFilter } from '@common/filter/simple-segmented.filter';
import { useCreditCardsModalFilters } from '../use-credit-cards-filters';

const TYPE_CARD_OPTIONS = [
  {
    label: 'Virtual',
    filterCriteria: 'VIRTUAL',
    key: 'virtual',
  },
  {
    label: 'Physical',
    filterCriteria: 'PHYSICAL',
    key: 'physical',
  },
];
export const CardTypeFilter = () => {
  const creditCardsFilters = useCreditCardsModalFilters();

  return (
    <SimpleSegmentSelectedFilter
      filterHook={creditCardsFilters}
      filterKey="type"
      options={TYPE_CARD_OPTIONS}
      label="Type"
      includesValue={(row) => row.cardType}
    />
  );
};
