import {
  useBalancesPlaidCompanyAccounts,
  usePlaidCompanyAccounts,
} from '@queries/use-plaid-company-accounts';
import { PlaidAccount } from 'areas/banking/move-funds/move-funds.model';

export const useExternalAccounts = () => {
  const {
    data: externalAccountsData,
    isLoading: isLoadingExternalAccounts,
    isError: isErrorExternalAccounts,
    refetch: refetchPlaidCompanyAccounts,
  } = usePlaidCompanyAccounts();

  const externalAccounts = externalAccountsData?.accounts ?? [];
  const isUnlinkedAccount = externalAccounts.some((acc) => acc.unlinked);
  const isEnabled = !!externalAccounts.length && !isUnlinkedAccount;

  const {
    data: balancesData,
    isLoading: isLoadingBalancesData,
    isError: isErrorBalancesData,
  } = useBalancesPlaidCompanyAccounts(isEnabled, true);

  const balances = balancesData?.balances ?? [];

  // If the balances query isn't executed, just return all the external accounts
  const mappedExternalAccounts: PlaidAccount[] =
    externalAccounts.map((account) => {
      const availableBalance = balances.find(
        (acc) => acc.accountId === account.accountId,
      )?.available;

      return {
        ...account,
        plaidOrDeposit: 'plaid',
        available: availableBalance,
      };
    }) ?? [];

  return {
    data: mappedExternalAccounts,
    isLoading: isLoadingExternalAccounts || isLoadingBalancesData,
    isError: isErrorBalancesData || isErrorExternalAccounts,
    refetch: refetchPlaidCompanyAccounts,
  };
};
