import { DateTime } from 'luxon';

export const paymentFormatStatus = (status: string, date: string) => {
  const hasFuturePostDate =
    DateTime.fromSQL(date).diffNow('milliseconds').milliseconds > 0;

  switch (status) {
    case 'succeeded':
      return hasFuturePostDate ? 'Pending' : 'Posted';
    case 'failed':
      return 'Failed';
    case 'pending':
      return 'Pending';
    case 'initiated':
      return 'Created';
    case 'canceled':
      return 'Canceled';
    case 'chargeback':
      return 'Chargeback';
    default:
      return 'Created';
  }
};
