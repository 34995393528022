import { useEffect } from 'react';
import { Box } from '@mantine/core';
import { PayablesTable } from '@mercoa/react';
import { useOutletContext } from 'react-router-dom';
import { InvoiceResponse } from '@mercoa/javascript/api';
import { useBillPayStyles } from '../billing.styles';
import { MercoaContext } from 'states/mercoa/mercoa';
import BillsMetrics from './bills-metrics/bills-metrics';
import BillPayTable from '../components/bill-table/bill-pay-table';
import { useBillingDashboardFilters } from './use-billing-dashboard-filters';

type CustomTableProps = {
  count: number;
  hasNext: boolean;
  dataLoaded: boolean;
  mercoaToken: string;
  invoices: InvoiceResponse[];
  setResultsPerPage: (count: number) => void;
};

const CustomTable = ({
  count,
  hasNext,
  invoices,
  dataLoaded,
  mercoaToken,
  setResultsPerPage,
}: CustomTableProps) => {
  useEffect(() => {
    setResultsPerPage(count);
  }, [hasNext]);

  return (
    <BillPayTable
      invoices={invoices}
      dataLoaded={dataLoaded}
      mercoaToken={mercoaToken}
      useFilters={useBillingDashboardFilters()}
      headingText="There are no bills to display"
    />
  );
};

const BillDashboard = () => {
  const { classes } = useBillPayStyles();
  const { mercoaToken } = useOutletContext<MercoaContext>();

  return (
    <>
      <BillsMetrics />
      <Box className={classes.card} mb="lg">
        <PayablesTable
          statuses={[
            'DRAFT',
            'NEW',
            'APPROVED',
            'SCHEDULED',
            'PENDING',
            'PAID',
            'ARCHIVED',
            'REFUSED',
            'CANCELED',
            'FAILED',
          ]}
        >
          {({ invoices, dataLoaded, count, hasNext, setResultsPerPage }) => (
            <CustomTable
              count={count}
              hasNext={hasNext}
              invoices={invoices}
              dataLoaded={dataLoaded}
              mercoaToken={mercoaToken}
              setResultsPerPage={setResultsPerPage}
            />
          )}
        </PayablesTable>
      </Box>
    </>
  );
};

export default BillDashboard;
