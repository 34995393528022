import {
  AmountFilter,
  AmountFilterValue,
  filterDirectionToLabel,
} from '@common/filter/amount-filter';
import { formatCurrency } from '@flexbase-eng/web-components';
import { usePaymentFilters } from '../use-payment-filters';

export const PaymentAmountFilter = () => {
  const { addFilter, getFilterByKey } = usePaymentFilters();

  const handleChange = (val: AmountFilterValue) => {
    addFilter('amount', {
      label: `Amount - ${filterDirectionToLabel(
        val.direction,
      )} ${formatCurrency(val.amount)}`,
      key: 'amount',
      fn: (row) => {
        const amountNum = parseFloat(row.amount.replace(/[^\d.]/g, ''));
        const filterNum = +val.amount;
        switch (val.direction) {
          case 'greaterThan':
            return amountNum > filterNum;
          case 'lessThan':
            return amountNum < filterNum;
          case 'equal':
          default:
            return amountNum === filterNum;
        }
      },
      filterValue: val,
      showChip: true,
    });
  };

  return (
    <AmountFilter
      value={
        getFilterByKey('amount')?.filterValue || {
          amount: '',
          direction: 'equal',
        }
      }
      onChange={handleChange}
    />
  );
};
