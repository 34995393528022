import { atom } from 'recoil';
import {
  ActiveFiltersReturnType,
  createUseFiltersHook,
  FilterFnMap,
} from '@common/filter/filters';
import { CardTableRow } from '../card-table-row.model';

const CreditCardsFilterState = atom<FilterFnMap<CardTableRow>>({
  key: 'credit_cards_filter_state',
  default: {},
});

const CreditCardsFilterStateModal = atom<FilterFnMap<CardTableRow>>({
  key: 'credit_cards_filter_state_modal',
  default: {},
});

export function useCreditCardsFilters(): ActiveFiltersReturnType<CardTableRow> {
  return createUseFiltersHook(CreditCardsFilterState);
}

export function useCreditCardsModalFilters(): ActiveFiltersReturnType<CardTableRow> {
  return createUseFiltersHook<CardTableRow>(CreditCardsFilterStateModal);
}
