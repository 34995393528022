import { useEffect } from 'react';
import { useQueryParams } from '@utilities/url/query-param';
import { LoadingOverlay } from '@mantine/core';
import { useMagicLinkContext } from './use-magic-link';

export const CallbackPage = () => {
  const queryParams = useQueryParams();
  const token = queryParams.get('token');
  const tokenType = queryParams.get('token_type');
  const { handleMagicLinkCallback } = useMagicLinkContext();

  useEffect(() => {
    handleMagicLinkCallback(token, tokenType);
  }, [tokenType, token]);

  return <LoadingOverlay visible={true} />;
};
