import { createStyles, rem } from '@mantine/core';

export const useSecurityCenterStyles = createStyles((theme) => ({
  sectionBox: {
    padding: theme.spacing.xl,
    backgroundColor: theme.fn.themeColor('neutral', 0),
    border: `1px solid #C5C2BE`, // This color is not in theme...
    borderRadius: theme.defaultRadius,
  },
  factorSelectBox: {
    border: `1px solid ${theme.fn.themeColor('neutral', 3)}`,
    borderRadius: '0.5rem',
    cursor: 'pointer',
    backgroundColor: theme.fn.themeColor('neutral', 0),
    width: '100%',
    height: '5.5rem',
    padding: '0 1.25rem',
  },
  factorSelectBoxDisabled: {
    opacity: 0.5,
    cursor: 'inherit',
    pointerEvents: 'none',
  },
  infoTag: {
    backgroundColor: theme.fn.themeColor('promote', 1),
    padding: '0 6px',
    border: `1px solid ${theme.fn.themeColor('promote', 3)}`,
    borderRadius: '0.25rem',
    color: theme.fn.themeColor('promote', 7),
    fontSize: rem(12),
  },
  formSectionBox: {
    padding: theme.spacing.xl,
    backgroundColor: theme.fn.themeColor('neutral', 0),
    border: `1px solid #C5C2BE`, // This color is not in theme...
    borderRadius: theme.defaultRadius,
  },
}));
