import { SearchIcon } from '@flexbase-eng/web-components';
import { Group, TextInput } from '@mantine/core';

type Props = {
  searchValue?: string;
  onSearchChange?: (val: string) => void;
  saveChangesButton?: JSX.Element;
};

export const SpendPlanLimitsTableHeader = ({
  searchValue,
  onSearchChange,
  saveChangesButton,
}: Props) => {
  return (
    <Group mb="md" position="apart">
      <TextInput
        value={searchValue}
        placeholder="Search team limits"
        sx={(theme) => ({
          width: 368,
          [theme.fn.smallerThan('md')]: {
            width: '100%',
          },
        })}
        icon={<SearchIcon width={20} height={20} />}
        onChange={(e) => onSearchChange?.(e.target.value)}
      />

      {saveChangesButton}
    </Group>
  );
};
