import { Divider, Stack } from '@mantine/core';
import { FiMessageCircle, FiSmartphone } from 'react-icons/fi';
import { useFactorSetupMultistepFormContext } from '../pages/factor-setup/factor-setup-multi-step-form.context';
import { Tag } from './info-tag';
import { FactorBox } from './factor-type-box';

export const ChooseNewFactor = () => {
  const { goToNextStep } = useFactorSetupMultistepFormContext();

  const handleFactorClick = (type: 'otp' | 'totp') => {
    goToNextStep({ flow: type });
  };

  return (
    <Stack spacing="xl">
      <FactorBox
        title="SMS"
        subtitle="Use your phone to generate a one-time code"
        icon={<FiMessageCircle fontSize={20} />}
        onFactorBoxClick={() => handleFactorClick('otp')}
      />
      <Divider label="Or use an authenticator app" labelPosition="center" />
      <FactorBox
        title="Authenticator app"
        subtitle="Use an authenticator app to generate a one-time code"
        icon={<FiSmartphone fontSize={20} />}
        onFactorBoxClick={() => handleFactorClick('totp')}
        tag={<Tag>Coming soon</Tag>}
        disabled
      />
    </Stack>
  );
};
