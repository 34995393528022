import { MultiSelect } from '@mantine/core';
import { uniq } from 'underscore';
import { usePaymentFilters } from '../use-payment-filters';
import { useGetBankingPayments } from '@queries/use-payments';
import { useMemo } from 'react';
import { useGetDepositAccounts } from '@queries/use-deposit-accounts';

export const PaymentRecipientFilter = () => {
  const { data: depositAccounts } = useGetDepositAccounts();
  const { data } = useGetBankingPayments();

  const recipient = useMemo(() => {
    if (data && depositAccounts) {
      return uniq(
        data
          .map((payment) => {
            const who =
              payment.payDirection === 'Debit'
                ? depositAccounts?.accounts.find(
                    (account) => account.id === payment.depositId,
                  )?.name ?? 'Deposit Account'
                : payment.payCtrParty;
            return who;
          })
          .filter((t) => !!t),
      );
    }
    return [];
  }, [data, depositAccounts]);

  const { addFilter, removeFilter, getFilterByKey } = usePaymentFilters();

  return (
    <MultiSelect
      data={recipient}
      placeholder="Name"
      searchable
      nothingFound="Nothing found"
      value={getFilterByKey('name')?.filterValue || []}
      onChange={(val) => {
        if (val.length > 0) {
          addFilter('name', {
            key: 'name',
            filterValue: val,
            fn: (row) => val.includes(row.recipient),
            label: `Name: ${val.join(', ')}`,
            showChip: true,
          });
        } else {
          removeFilter('name');
        }
      }}
    />
  );
};
