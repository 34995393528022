import { createStyles, rem } from '@mantine/core';

type Props = {
  credit?: boolean;
};

export const useStyles = createStyles((theme, { credit }: Props) => ({
  baseContainer: {
    display: 'flex',
    flexDirection: 'row',
    '@media(max-width: 767px)': {
      flexDirection: 'column',
      margin: '0px 0px 18px 0px',
    },
  },
  creditPaymentContainer: {
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
    display: 'flex',
    backgroundColor: theme.fn.themeColor('neutral', 0),
    height: '200px',
    width: '100%',
    minWidth: '420px',
    maxWidth: 'initial',
    flexDirection: 'column',
    padding: '1.5rem',
    '@media(max-width: 767px)': {
      width: '100%',
      minWidth: 'unset',
      height: 'fit-content', // For whatever reason, mobile 200px is ignored?
    },
    position: 'relative',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  amountDueLabel: {
    fontSize: '16px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '19px',
    color: '#5F5F5F',
  },
  amountDue: {
    flexGrow: 1,
    fontSize: '26px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '31px',
    color: theme.fn.themeColor('primarySecondarySuccess', 8),
    marginBottom: '34px',
    fontFamily: 'PP Neue Montreal',
  },
  currentBalance: {
    fontSize: rem(14),
    fontWeight: 400,
    color: theme.fn.themeColor('neutral', 6),
  },
  daysTilDueContainer: {
    background: theme.colors.tertiary[1],
    width: '119px',
    height: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.defaultRadius,
  },
  aprContainer: {
    background: theme.colors.neutral[1],
    width: '83px',
    height: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.defaultRadius,
    marginRight: theme.spacing.sm,
  },
  daysTilDue: {
    fontSize: '14px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '16px',
    color: 'rgba(7, 28, 20, 0.80)',
  },
  actionButtonsContainer: {
    marginTop: 'auto',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: credit ? 'normal' : 'space-between',
  },
  payEarly: {
    height: '40px',
    width: '100px',
    padding: '12px 20px 12px 20px',
  },
  payEarlyText: {
    color: 'rgb(252,239,237)',
    fontSize: '14px',
    fontWeight: 500,
    fontStyle: 'medium',
    lineHeight: '16px',
  },
  autopay: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '155px',
  },
  autopayLabel: {
    padding: '3px',
  },
  autopayLabelText: {
    color: '#5F5F5F',
    fontSize: '16px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'end',
  },
  autopaySchedule: {
    color: '#979797',
    fontSize: '14px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '16px',
  },
  learnAboutRewards: {
    background: 'white',
    width: '171px',
    height: '40px',
    border: '1px solid #979797',
    borderRadius: '8px',
    marginLeft: '10px',
    color: '#5F5F5F',
    fontSize: '14px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '16px',
    '&:hover': {
      border: 'none',
      background: 'lightgray',
    },
  },
  learnAboutRewardsText: {
    color: '#5F5F5F',
    fontSize: '13.6px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '16px',
  },
  billDate: {
    color: theme.colors.neutral[6],
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    marginTop: theme.spacing.xs,
  },
}));
