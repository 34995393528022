/**
 * Typescript helper to validate that a value is a string.
 *
 * @example
 * function doThing(value: string | number) {
 *   // typescript error
 *   console.log(value.toLowerCase());
 *
 *   if (isString(value)) {
 *     // no error
 *     console.log(value.toLowerCase());
 *   }
 * }
 */
export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

/**
 * Typescript helper to validate that a value is a truthy string.
 *
 * @example
 * function doThing(value: string | number) {
 *   // typescript error
 *   console.log(value.toLowerCase());
 *
 *   if (isTruthyString(value)) {
 *     // no error
 *     console.log(value.toLowerCase());
 *   }
 * }
 */
export function isTruthyString(value: unknown): value is string {
  return !!value && isString(value);
}
