import {
  Alert,
  Box,
  Button,
  Checkbox,
  PasswordInput,
  rem,
  Text,
  TextInput,
} from '@mantine/core';
import { TermsOfServiceCheckboxPrompt } from '@common/terms/prompt';
import { useStartScreenStyles } from '../styles';
import { useForm } from '@mantine/form';
import PasswordStrengthPopover from '../../../../login/password-strength-popover';
import {
  EmailValidator,
  formatUSPhoneNumber,
  NewPasswordValidator,
  PhoneNumberValidator,
  RequiredFieldValidator,
} from '@flexbase-eng/web-components';
import { useRecoilValue } from 'recoil';
import { RegistrationProductsState } from '../../../../../states/application/product-onboarding';
import { ReactNode } from 'react';
import AuthLoad from '../../../../../components/login/auth-loader';
import { ErrorCircle, RedAlertIcon } from '../../../../../assets/svg';
import { useQueryParams } from '@utilities/url/query-param';
import {
  RegistrationFormReturnType,
  useRegistration,
} from '../use-registration';
import { useMatchMedia } from '@utilities/url/window-helpers';
import { useMaintenanceFlag } from '@utilities/feature-flags';
import { REGISTRATION_COPY } from '../../../pages/registration/registration.copy';

export const CreditBankingForm = () => {
  const isMaintenance = useMaintenanceFlag();
  const { classes } = useStartScreenStyles();
  const product = useRecoilValue(RegistrationProductsState);
  const queryParams = useQueryParams();

  const isBankingOnly = product.every((p) => p === 'BANKING');

  const form = useForm<RegistrationFormReturnType>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: queryParams.get('email') || '',
      cellphone: '',
      password: '',
      confirmPassword: '',
      is18: false,
      termsOfServiceSigned: false,
    },
    validate: {
      firstName: RequiredFieldValidator(),
      lastName: RequiredFieldValidator(),
      email: EmailValidator(),
      cellphone: PhoneNumberValidator(),
      password: NewPasswordValidator(),
      confirmPassword: (val, formValues) =>
        val && val === formValues.password ? null : 'Passwords must match',
      is18: RequiredFieldValidator(),
      termsOfServiceSigned: RequiredFieldValidator(),
    },
  });

  const { loading, errorMessage, registerNewUser, setErrorMessage } =
    useRegistration(form);

  const isMobile = useMatchMedia('(max-width: 767px)');

  return (
    <Box
      sx={(theme) => ({
        width: 524,
        marginBottom: theme.spacing.lg,
        [theme.fn.smallerThan(1281)]: {
          width: '100%',
          padding: `0 ${theme.spacing.lg}`,
          marginBottom: theme.spacing.lg,
        },
        [theme.fn.smallerThan('sm')]: {
          width: '100%',
          padding: theme.spacing.md,
        },
        position: 'relative',
      })}
    >
      {isMaintenance && (
        <Alert my="lg" withCloseButton={false}>
          New applications are disabled while we perform routine maintenance.
          Maintenance is expected to last until Jul 16, 11:30 AM EST
        </Alert>
      )}
      {loading && <AuthLoad />}
      <Text className={classes.creditBankingTitle}>
        {isBankingOnly
          ? REGISTRATION_COPY.banking.title
          : REGISTRATION_COPY.credit.title}
      </Text>
      {isBankingOnly ? (
        <Text className={classes.creditBankingSubtitle} mt="xs">
          {REGISTRATION_COPY.banking.subtitle}
        </Text>
      ) : (
        <Text className={classes.creditBankingSubtitle} mt="xs">
          {REGISTRATION_COPY.credit.subtitle}
        </Text>
      )}

      <Box
        mt="sm"
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '1rem',
          [theme.fn.smallerThan('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          },
        })}
      >
        <TextInput
          label="Legal first name"
          placeholder="First name"
          classNames={{
            root: classes.nameInputs,
            input: classes.input,
            label: classes.label,
          }}
          {...form.getInputProps('firstName')}
          id="input-first-name"
          error={getErrorNodeOrUndefined(form.errors.firstName)}
        />
        <TextInput
          label="Legal last name"
          placeholder="Last name"
          classNames={{
            root: classes.nameInputs,
            input: classes.input,
            label: classes.label,
          }}
          {...form.getInputProps('lastName')}
          id="input-last-name"
          error={getErrorNodeOrUndefined(form.errors.lastName)}
        />
        <TextInput
          label="Business email"
          placeholder="Enter business email"
          classNames={{
            root: classes.nameInputs,
            input: classes.input,
            label: classes.label,
          }}
          inputMode="email"
          {...form.getInputProps('email')}
          id="input-email"
          error={getErrorNodeOrUndefined(form.errors.email)}
        />
        <TextInput
          label="Cell phone"
          placeholder="Enter phone number"
          classNames={{
            root: classes.nameInputs,
            input: classes.input,
            label: classes.label,
          }}
          inputMode="tel"
          {...form.getInputProps('cellphone')}
          id="input-phone-number"
          data-testid="input-phone-number"
          onChange={(e) =>
            form.setFieldValue('cellphone', formatUSPhoneNumber(e.target.value))
          }
          error={getErrorNodeOrUndefined(form.errors.cellphone)}
        />
        <PasswordStrengthPopover
          inputValue={form.values.password}
          position={`${isMobile ? 'top' : 'bottom'}-start`}
        >
          <PasswordInput
            label="Create password"
            placeholder="Password"
            classNames={{
              root: classes.nameInputs,
              input: classes.input,
              label: classes.label,
            }}
            {...form.getInputProps('password')}
            id="input-password"
            data-testid="input-password"
            error={getErrorNodeOrUndefined(form.errors.password)}
          />
        </PasswordStrengthPopover>
        <PasswordInput
          label="Confirm password"
          placeholder="Password"
          classNames={{
            root: classes.nameInputs,
            input: classes.input,
            label: classes.label,
          }}
          {...form.getInputProps('confirmPassword')}
          id="input-confirm-password"
          data-testid="input-confirm-password"
          error={getErrorNodeOrUndefined(form.errors.confirmPassword)}
        />
        <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
          <Checkbox
            id="checkbox-legal-age"
            label={<Text size="xs">I am at least 18 years old</Text>}
            classNames={{ label: classes.label }}
            {...form.getInputProps('is18', { type: 'checkbox' })}
            color="primarySecondarySuccess.2"
            error={getErrorNodeOrUndefined(form.errors.is18)}
          />
          <TermsOfServiceCheckboxPrompt
            type="flexbase"
            size="sm"
            mt={15}
            className={classes.checkboxBody}
            classNames={{
              body: classes.checkboxBody,
              label: classes.label,
            }}
            color="primarySecondarySuccess.2"
            {...form.getInputProps('termsOfServiceSigned', {
              type: 'checkbox',
            })}
            error={getErrorNodeOrUndefined(form.errors.termsOfServiceSigned)}
          />
        </div>
        {errorMessage && (
          <Alert
            icon={<RedAlertIcon />}
            className={classes.alert}
            my="lg"
            onClose={() => setErrorMessage('')}
          >
            {errorMessage}
          </Alert>
        )}
        <Button
          variant="light"
          style={{ gridColumnStart: '1', gridColumnEnd: '3' }}
          onClick={() => registerNewUser()}
          id="button-register-submit"
          disabled={isMaintenance}
        >
          Start Application
        </Button>
      </Box>
    </Box>
  );
};

const InputError = ({ error }: { error: ReactNode }) => {
  return (
    <Box
      sx={(theme) => ({
        color: theme.fn.themeColor('neutral', 2),
        svg: { color: theme.fn.themeColor('critical', 2), minWidth: rem(12) },
        display: 'flex',
        alignItems: 'center',
        gap: rem(4),
      })}
    >
      <ErrorCircle />
      <span>{error}</span>
    </Box>
  );
};

function getErrorNodeOrUndefined(
  error: ReactNode | null,
): ReactNode | undefined {
  return error ? <InputError error={error} /> : undefined;
}
