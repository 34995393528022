import { atom } from 'recoil';
import {
  ActiveFiltersReturnType,
  createUseFiltersHook,
  FilterFnMap,
} from '@common/filter/filters';
import { TableTransactionsData } from './banking-transactions';

const BankingTransactionsFilterState = atom<FilterFnMap<TableTransactionsData>>(
  {
    key: 'banking_transactions_filter_state',
    default: {},
  },
);

export function useBankingTransactionsFilters(): ActiveFiltersReturnType<TableTransactionsData> {
  return createUseFiltersHook<TableTransactionsData>(
    BankingTransactionsFilterState,
  );
}
