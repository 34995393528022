import { Box, Text, useMantineTheme } from '@mantine/core';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { CategoricalChartState } from 'recharts/types/chart/types';

const noopNull = () => null;

const XAxisTick = (props: any) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#7B7A77" //neutral.6
        fontSize={'.75rem'}
      >
        {payload.value}
      </text>
    </g>
  );
};

type Data = {
  name: string;
  date: string;
  value: number;
};

type Props = {
  data: Data[];
  noDataFoundText?: string;
  onMouseMove: (params: CategoricalChartState) => void;
  onMouseLeave: () => void;
};

const GradientLineChart = ({
  data,
  noDataFoundText,
  onMouseMove,
  onMouseLeave,
}: Partial<Props>) => {
  const theme = useMantineTheme();
  if (!data) return false;

  if (data.length === 0)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 200,
          width: '100%',
        }}
      >
        <Text size="sm" ta="center" c="neutral.7" fs="italic">
          {noDataFoundText ?? 'No transactions were found during this time'}
        </Text>
      </Box>
    );

  return (
    <ResponsiveContainer height={200} width={'100%'}>
      <AreaChart
        data={data}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      >
        <defs>
          <linearGradient id="valueGradient" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="10%"
              stopColor={theme.colors.positive[0]}
              stopOpacity={0.8}
            />
            <stop
              offset="95"
              stopColor={theme.colors.positive[0]}
              stopOpacity={0}
            />
          </linearGradient>
        </defs>

        <Tooltip
          content={noopNull}
          cursor={{
            stroke: theme.colors.primarySecondarySuccess[2],
            strokeWidth: 2,
          }}
        />
        <XAxis
          dataKey="name"
          tickLine={false}
          axisLine={false}
          tick={<XAxisTick />}
        />
        <Area
          type="monotone"
          dataKey="value"
          stroke={theme.colors.primarySecondarySuccess[2]}
          fillOpacity={1}
          fill="url(#valueGradient)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default GradientLineChart;
