import OnboardingStep from '../components/onboarding-step';
import { useState } from 'react';
import { useOnboardingStyles } from '../onboarding.styles';
import { useRecoilState } from 'recoil';
import { ApplicationState } from '../../../states/application/product-onboarding';
import { PlaidLogo2, RegularPlus } from 'assets/svg';
import { Box, Popover, Text, UnstyledButton } from '@mantine/core';
import { usePlaidBankingComponent } from 'components/banking/plaid-banking-component';
import YellowBanner from '../components/yellow-banner';
import { PlaidLinkAccountBox } from '@common/account-box/plaid-link-account-box';
import { useApplicationFlowContext } from '../onboarding-hooks';

const VerifyBank = () => {
  const { classes, cx } = useOnboardingStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const {
    goBack,
    navigateToNextProductStep,
    refreshProductOnboardingStatus,
    createOrUpdateCompany,
  } = useApplicationFlowContext();
  const [{ company, requiredProperties }, setProductOnboardingState] =
    useRecoilState(ApplicationState);
  const [plaidLinked, setPlaidLinked] = useState(
    !requiredProperties.includes('user.plaidConnection'),
  );
  const [primaryAccountSet, setPrimaryAccountSet] = useState(
    !company.primaryPlaidAccount,
  );
  const { open, ready } = usePlaidBankingComponent({
    onSuccess: () => onLinkSuccess(),
    onError: () => setError('Unable to link bank account'),
    setLoading,
  });

  const onLinkSuccess = async () => {
    setError('');
    setLoading(true);
    setPlaidLinked(true);
    try {
      const status = await refreshProductOnboardingStatus();
      if (!status.company?.financialInstitutions?.length) {
        setError(
          'We were unable to correctly configure your external accounts.',
        );
      } else if (status.company.financialInstitutions.length === 1) {
        setPrimaryAccount(status.company.financialInstitutions[0].id);
      }
    } catch (e) {
      setError('Unable to refresh company data.');
    } finally {
      setLoading(false);
    }
  };

  const onContinue = async () => {
    if (plaidLinked && primaryAccountSet) {
      navigateToNextProductStep();
    } else if (!plaidLinked) {
      setError('You must connect at least one bank account to continue.');
    } else if (!primaryAccountSet) {
      setError('Please designate a primary account');
    }
  };

  const onBack = () => {
    goBack();
  };

  const isPrimaryAccount = (accountId?: string): boolean => {
    return (
      !!company.primaryPlaidAccount &&
      !!accountId &&
      company.primaryPlaidAccount === accountId
    );
  };

  const setPrimaryAccount = async (accountId: string) => {
    setError('');
    const result = await createOrUpdateCompany({
      primaryPlaidAccount: accountId,
    });
    if (!result.success) {
      setError('Unable to set primary account');
    } else {
      setProductOnboardingState((prev) => ({
        ...prev,
        company: { ...prev.company, ...result.company },
      }));
      setPrimaryAccountSet(true);
    }
  };

  const onLinkBankClick = () => {
    if (ready) {
      setError('');
      open();
    }
  };

  const isTreasury = company.optedProducts.includes('TREASURY');

  const subtitle = isTreasury
    ? `Please link all institutions that you bank with so your treasury account will have full FDIC insurance.`
    : `Securely connect your business bank accounts so we can give you the maximum credit limit to grow your business.`;
  return (
    <OnboardingStep
      title="Bank Verification"
      stepId="verify-bank"
      subtitle={subtitle}
      image={
        <Box mb="xl">
          <PlaidLogo2 />
        </Box>
      }
      showContinueSpinner={loading}
      error={error}
      onNextClick={onContinue}
      onBackClick={onBack}
      bottomDisclosureText={
        'Flex uses Plaid Inc. (“Plaid”) to gather your data from financial institutions for bank account and fraud verification. By using the site, you grant Flex and Plaid the right, power, and authority to act on your behalf to access and transmit your personal and financial information from your relevant financial institution. You agree to your personal and financial information being transferred, stored, and processed by Plaid in accordance with the Plaid end user privacy policy.'
      }
    >
      {company.financialInstitutions?.map((f, i) => (
        <PlaidLinkAccountBox
          account={f}
          isPrimaryAccount={isPrimaryAccount(f.id)}
          onSetAsPrimaryClick={setPrimaryAccount}
          key={i}
        />
      ))}
      {company.financialInstitutions.length > 0 ? (
        <UnstyledButton
          variant="subtle"
          onClick={() => onLinkBankClick()}
          className={cx(!ready && classes.disabled)}
        >
          <Text ta="right" fz="sm" c="something.2">
            + Connect another account
          </Text>
        </UnstyledButton>
      ) : (
        <Box
          mt="16px"
          mb="sm"
          w="100%"
          onClick={() => onLinkBankClick()}
          className={classes.addButton}
          id="button-link-bank-account"
        >
          <RegularPlus /> Connect a bank account
        </Box>
      )}
      {!company.financialInstitutions?.length && (
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
        >
          <Popover width={350} position="bottom-end" withArrow shadow="md">
            <Popover.Target>
              <UnstyledButton variant="subtle">
                <Text ta="right" fz="sm" td="underline" c="something.2">
                  What is this?
                </Text>
              </UnstyledButton>
            </Popover.Target>
            <Popover.Dropdown>
              <Text size="sm">
                By connecting your bank account, you&apos;re allowing Flex to
                view your account balance history. We&apos;ll use this
                information solely for the purpose of evaluating your
                creditworthiness and will not share it with third parties.
              </Text>
            </Popover.Dropdown>
          </Popover>
        </div>
      )}
      <YellowBanner text="Connect all business bank accounts to maximize your initial credit limit for the Flex credit card. Account balances significantly impact your limit." />
      <div className={cx(classes.flavorText)}>
        {isTreasury
          ? 'Please designate a primary bank account that will be used for all deposits and withdrawals from your treasury account.'
          : ``}
      </div>
    </OnboardingStep>
  );
};

export default VerifyBank;
