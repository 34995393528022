import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  baseContainer: {
    display: 'flex',
    flexDirection: 'row',
    '@media(max-width: 767px)': {
      flexDirection: 'column',
    },
  },
  autoPayContainer: {
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.fn.themeColor('neutral', 0),
    minHeight: '229px',
    height: '100%',
    width: '100%',
    maxWidth: '620px',
    position: 'relative',
  },
  autoPaySettings: {
    borderColor: '#c8d6e5',
    width: '100%',
    padding: '1.5rem',
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '30px',
    color: '#5F5F5F',
  },
  grayTitle: {
    fontSize: '14px',
    fontWeight: 400,
    fontStyle: 'normal',
    color: theme.colors.neutral[6],
  },
  cashBackText: {
    fontSize: '14px',
    color: '#979797',
  },
  bankBalance: {
    fontSize: '12px',
    color: '#979797',
  },
  dateAmountDue: {
    fontSize: '26px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '31px',
    color: 'black',
    marginBottom: '30px',
  },
  unlinkedPlaidAccount: {
    fontSize: '14px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '31px',
    color: theme.fn.primaryColor(),
    marginBottom: '30px',
  },
  accountContainer: {
    width: 580,
    height: 79,
    marginTop: 8,
    marginBottom: 13,
    '@media(max-width: 767px)': {
      width: '100%',
    },
  },
  innerContent: {
    backgroundColor: '#FFFFFF',
    minHeight: 390,
    width: 480,
    margin: 'auto',
    padding: theme.spacing.xl,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'start',
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
  },
  innerContentMobile: {
    backgroundColor: '#FFFFFF',
    height: '530px',
    width: '300px',
    margin: 'auto',
    padding: theme.spacing.md,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'start',
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
  },
  editAutoPayContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  editAutoPayCalculations: {
    padding: theme.spacing.xl,
    width: '100%',
  },
  borderLine: {
    margin: `-${theme.spacing.sm} auto 0 auto !important`,
    width: '90%',
  },
  separationBorder: {
    width: '100%',
    color: theme.colors.neutral[3],
    marginTop: '-20px',
  },
  buttonsDiv: {
    width: '100%',
    marginTop: '16px',
  },
  editAutoPayTitle: {
    fontFamily: 'PP Neue Montreal',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '32px',
    textAlign: 'left',
    '@media(max-width: 767px)': {
      margin: '1vh',
    },
  },
  editAutoPaySubtitle: {
    fontFamily: 'PP Neue Montreal',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    color: theme.colors.neutral[7],
    marginTop: theme.spacing.xl,
  },
  editAutoPayCheckbox: {
    width: '100%',
  },
  editAutoPayCheckboxText: {
    fontFamily: 'PP Neue Montreal',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
  },
  errorText: {
    fontFamily: 'PP Neue Montreal',
    fontSize: '14px',
    marginTop: theme.spacing.md,
    fontStyle: 'normal',
    fontWeight: 400,
    color: theme.colors.failure[1],
  },
}));
