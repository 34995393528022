type PollingCallback = (args: { stop: () => void }) => void | Promise<void>;

const globalPollingState: Record<string, number | undefined> = {};

/**
 * Calls the given callback after every interval. awaits the callback before starting the next polling loop to prevent cases where the callback takes longer than the interval.
 *
 * Handle exceptions inside the callback, if an exception is thrown we swallow it and start the next polling loop.
 */
const startPolling = (
  key: string,
  callback: PollingCallback,
  interval: number,
) => {
  const existingId = globalPollingState[key];

  if (existingId) {
    clearInterval(existingId);
  }

  startNextInterval(true);

  async function startNextInterval(isInitial = false) {
    if (!isInitial) {
      const isActive = !!globalPollingState[key];

      if (!isActive) {
        return;
      }
    }

    // specifically using window.setTimeout because TS
    globalPollingState[key] = window.setTimeout(async () => {
      try {
        await callback({ stop: () => stopPolling(key) });
      } catch {
        // swallow
      } finally {
        startNextInterval();
      }
    }, interval);
  }
};

const hasExistingPoll = (key: string): boolean => {
  return !!globalPollingState[key];
};

const stopPolling = (key: string) => {
  clearTimeout(globalPollingState[key]);
  delete globalPollingState[key];
};

const clearAllPolls = () => {
  Object.keys(globalPollingState).forEach(stopPolling);
};

const pollingModule = {
  startPolling,
  stopPolling,
  clearAllPolls,
  hasExistingPoll,
};

/**
 * Returns methods to start and stop global polls. Basically a fancy setInterval that doesn't rely on component state or hooks.
 */
export const usePolling = () => {
  return pollingModule;
};

export default pollingModule;
