import { ConfiguredMiddleware } from 'wretch';
import { KEY_TOKEN_STORAGE } from '../states/auth/auth-token';
import { refreshAndStoreTokenFromUser } from '@utilities/auth/refresh-token';

export const TokenMiddleware: ConfiguredMiddleware =
  (next) => async (url, opts) => {
    const token = localStorage?.getItem(KEY_TOKEN_STORAGE);

    const requestOptions = {
      ...opts,
      headers: {
        ...(opts.headers || {}),
        ...(!!token && { Authorization: `Bearer ${token}` }),
      },
    };

    return next(url, requestOptions);
  };

export const RefreshTokenMiddleware: ConfiguredMiddleware =
  (next) => async (url, opts) => {
    await refreshAndStoreTokenFromUser();
    return next(url, opts);
  };
