import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Text, TextInput, useMantineTheme } from '@mantine/core';
import { formatCurrency } from 'utilities/formatters/format-currency';
import flexbaseClient from 'services/flexbase-client';
import { CustomMantineStyles, useStyles } from './payments-table.styles';
import { sortList } from 'utilities/extensions/sort-lists';
import { SearchIcon } from 'assets/svg';
import FlexbaseTable from 'components/table/flexbase-table';
import { useMediaQuery } from '@mantine/hooks';
import { paymentFormatStatus } from 'utilities/formatters/payment-format-status';
import { formatApiStrings } from 'utilities/formatters/format-api-strings';
import { getMonthDayYear, sortDate } from 'utilities/dates/dates';

type Payment = {
  amount: string;
  status: string;
  datePosted: string;
  createdAt: string;
  origin: string;
  id: string;
};

const PaymentsTable = () => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [inputText, setInputText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState<Payment[]>();

  const inputHandler = (e: any) => {
    const lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  const getCompanyPayments = async () => {
    try {
      setLoading(true);
      const {
        success,
        payments: coPayments,
        error,
      } = await flexbaseClient.getCompanyPayments();
      // Make sure the latest invoices come first
      sortList(coPayments!, {
        sortType: 'desc',
        sortBy: 'createdAt',
      });
      if (success) {
        setPayments(coPayments);
      } else if (error) {
        setErrorMessage(error as string);
      } else {
        setErrorMessage(
          'We are unable to retrieve the company payments at the moment.',
        );
      }
    } catch (error) {
      console.error('Unable to get the company payments', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompanyPayments();
  }, []);

  const columns = [
    {
      name: 'Date created',
      selector: (row: { createdAt: string }) => row.createdAt,
      format: (row: { createdAt: string }) => getMonthDayYear(row.createdAt),
      sortable: true,
      sortFunction: sortDate,
    },
    {
      name: 'Date posted',
      selector: (row: { datePosted: string }) => {
        const parsedDate = new Date(row.datePosted).toISOString();
        return parsedDate;
      },
      format: (row: { datePosted: string }) => row.datePosted,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: (row: { amount: string }) => Number(row.amount),
      sortable: true,
      format: (row: { amount: string }) => formatCurrency(row.amount),
    },
    {
      name: 'Payment type',
      selector: (row: { type: string }) => {
        if (row.type.toLowerCase() === 'auto') {
          return 'Auto Pay';
        }
        if (row.type.toLowerCase() === 'manual') {
          return 'One Time';
        }
        return formatApiStrings(row.type);
      },
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row: { status: string }) => row.status,
      cell: (row: { status: any }) => {
        let statusColor = '';

        switch (row.status) {
          case 'Posted':
            statusColor = '#E9F9F2';
            break;
          case 'Pending':
          case 'Created':
            statusColor = '#EEEEF3';
            break;
          case 'Chargeback':
          case 'Canceled':
          case 'Failed':
            statusColor = '#F9D9DF';
            break;
          default:
            statusColor = '#E4D2A2';
        }
        return (
          <div
            className={classes.statusColumn}
            style={{
              backgroundColor: statusColor,
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            {formatApiStrings(row.status)}
          </div>
        );
      },
      sortable: true,
    },
  ];

  const paymentsArray = payments?.filter(
    (item) => item.origin === 'manual' || item.origin === 'autopay',
  );

  const tableData = paymentsArray?.map((payment) => {
    return {
      id: payment.id,
      datePosted: DateTime.fromSQL(payment.datePosted).toFormat('LLL d, yyyy'),
      dateSubmitted: DateTime.fromSQL(payment.createdAt).toFormat(
        'LLL dd, yyyy',
      ),
      createdAt: payment.createdAt,
      amount: payment.amount.replace(/(-)/, ''),
      type: formatApiStrings(payment.origin),
      status: paymentFormatStatus(payment.status, payment.datePosted),
    };
  });

  const filteredData =
    tableData?.filter((payment) => {
      if (inputText === '') {
        return payment;
      } else {
        return (
          payment.datePosted.toLowerCase().includes(inputText) ||
          payment.dateSubmitted.toLowerCase().includes(inputText) ||
          payment.amount.toLowerCase().includes(inputText) ||
          payment.status.toLowerCase().includes(inputText) ||
          payment.type.toLowerCase().includes(inputText)
        );
      }
    }) ?? [];

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Text className={classes.title}> </Text>
        <TextInput
          w={useMobileView ? '100%' : 368}
          styles={{
            input: CustomMantineStyles(theme).searchPaymentHistory.input,
          }}
          placeholder="Search payment history"
          onChange={inputHandler}
          icon={<SearchIcon width={20} height={20} />}
        />
      </div>
      <FlexbaseTable
        columns={columns}
        data={filteredData}
        pagination={filteredData && filteredData?.length > 8}
        isFetchingData={loading}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default PaymentsTable;
