import { useCreateRecipient } from '@queries/use-recipients';
import { useNavigate } from 'react-router-dom';
import { isEqual } from 'underscore';
import { recipientDetailsFormInitialValues } from './add-recipient';
import { AddRecipientState } from './add-recipient-wizard';
import { RecipientDetailsStepFormValues } from './steps/recipient-details/recipient-details-step';

const shouldCreateRecipient = (
  values: RecipientDetailsStepFormValues,
): values is RecipientDetailsStepFormValues => {
  return !isEqual(values, recipientDetailsFormInitialValues);
};

export const useAddRecipientSubmit = (state: AddRecipientState) => {
  const { mutate: createRecipient, isPending: isPendingRecipientCreation } =
    useCreateRecipient();
  const navigate = useNavigate();

  const submitRecipient = () => {
    /*
      TODO: consider 'payment details' step - after a recipient is created AND we have the associated payment details filled out,
      then we should call either the /counterparties or /beneficiaries endpoint with the associated recipientID to create the counterparty or beneficiary
    */
    if (shouldCreateRecipient(state.recipientDetailsFormValues)) {
      createRecipient(
        {
          ...state.recipientDetailsFormValues,
        },
        {
          onSuccess: () => {
            navigate(-1);
          },
        },
      );
    }
  };

  const isPending = isPendingRecipientCreation;

  return {
    submitRecipient,
    isPending,
  };
};
