import { useEffect, useState } from 'react';
import { useStyles } from './styles';
import useModal from 'components/modal/modal-hook';
import flexbaseClient, {
  flexbaseOnboardingClient,
} from 'services/flexbase-client';
import {
  CompanyDocument,
  ShortCompanyDocument,
} from 'services/flexbase/flexbase-onboarding-client';
import { Employee } from 'states/employees/employee';
import FlexbaseMainLayout from 'components/layouts/main';
import FlexbaseDefaultHeader from 'components/header/current/flexbase-header';
import DocumentUploadModal from './document-upload-modal';
import { getLocaleDate } from 'utilities/formatters/format-date-string';
import { IoTrashOutline } from 'react-icons/io5';
import { PlusSignIcon } from 'assets/svg';
import { IoMdCheckmark } from 'react-icons/io';
import { showNotification } from '@mantine/notifications';
import { FaExclamationCircle } from 'react-icons/fa';
import { setFilterColumn } from 'utilities/filter/filter-column';
import {
  FilterOption,
  FlexbaseTable,
  TableContainerWithSearchFilter,
} from 'components/table';
import { Button, LoadingOverlay } from '@mantine/core';
import StatusCell from 'components/table/cells/status-cell';
import RightJustifiedRow from 'components/layouts/right-justified-row';

export const predefinedDocTypes = ['Tax', 'Contract', 'Statement'];

const DocumentManagement = () => {
  const { classes } = useStyles();
  const { openTransparentModal, closeAllModals } = useModal();
  const [companyDocs, setCompanyDocs] = useState<CompanyDocument[]>([]);
  const [allUsers, setAllUsers] = useState<Employee[]>([]);
  const [loading, setLoading] = useState(false);
  const [filteredColumn, setFilteredColumn] = useState('type');
  const [filteredValue, setFilteredValue] = useState('');
  const [searchDoc, setSearchDoc] = useState('');

  const getAllUsers = async () => {
    setLoading(true);
    try {
      const companyUsers = await flexbaseClient.getEmployees();
      if (companyUsers && companyUsers.length > 0) {
        setAllUsers(companyUsers);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const getDocuments = async () => {
    setLoading(true);
    try {
      const response = await flexbaseOnboardingClient.getDocuments();
      if (response.success) {
        const newCompanyDocs = (response.companyDocs ?? []).filter(
          (item) => !item.droppedAt,
        );
        setCompanyDocs(newCompanyDocs);
      } else {
        throw 'Unsuccessful';
      }
    } catch (e) {
      console.error('Failed to load company documents', e);
    } finally {
      setLoading(false);
    }
  };

  const dropDocument = (companyDocId: string) => {
    const response = flexbaseOnboardingClient.deleteDocument(companyDocId);
    response
      .then((res) => {
        if (res.success) {
          const docs = [...companyDocs];
          const index = docs.findIndex((doc) => doc.id === companyDocId);
          docs.splice(index, 1);
          setCompanyDocs(docs);
          showNotification({
            title: 'Dropped',
            message: `${res.companyDoc.metadata.sourceName} successfully dropped!`,
            color: 'flexbase-teal',
            icon: <IoMdCheckmark />,
          });
        } else {
          throw 'Failed to drop file';
        }
      })
      .catch((e) => {
        console.error('Failed to drop file', e);
        showNotification({
          title: 'Drop failed',
          message: `Failed to drop file.`,
          color: 'red',
          icon: <FaExclamationCircle />,
        });
      });
  };

  const downloadDocument = async (docId: string, fileName: string) => {
    try {
      const blob = await flexbaseOnboardingClient.downloadDocument(docId);
      if (blob) {
        const file = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = file;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        throw 'Error retrieving file';
      }
    } catch (e) {
      console.error('Download Failed', e);
      showNotification({
        title: 'Download failed',
        message: `Failed to retireve file.`,
        color: 'red',
        icon: <FaExclamationCircle />,
      });
    }
  };

  useEffect(() => {
    getAllUsers();
    getDocuments();
  }, []);

  const allTypes = predefinedDocTypes.concat(
    companyDocs
      .map((doc) => doc.type)
      .filter(
        (v, i, a) =>
          !!v && a.indexOf(v) === i && !predefinedDocTypes.includes(v),
      ),
  );

  const filteredItems = companyDocs.filter(
    (item) =>
      (setFilterColumn(item.metadata.sourceName, searchDoc) ||
        setFilterColumn(item.description, searchDoc) ||
        setFilterColumn(item.type, searchDoc)) &&
      (!filteredValue ||
        setFilterColumn(
          item[filteredColumn as keyof ShortCompanyDocument],
          filteredValue,
        )),
  );

  const setFilter = (column: string, value: string) => {
    setFilteredColumn(column);
    setFilteredValue(value);
  };

  const handleModalClose = (uploaded?: boolean) => {
    closeAllModals();
    if (uploaded) {
      getDocuments();
    }
  };

  const columns = [
    {
      name: 'hidden_date',
      selector: (row: CompanyDocument) => row.uploadedAt,
      omit: true,
    },
    {
      name: 'File name',
      selector: (row: CompanyDocument) => row.metadata.sourceName,
      sortable: true,
    },
    {
      name: 'Description',
      selector: (row: CompanyDocument) => row.description,
      sortable: true,
      grow: 1,
      maxWidth: '300px',
    },
    {
      name: 'Type',
      selector: (row: CompanyDocument) => row.type,
      cell: (row: CompanyDocument) => {
        if (!row.type) return;
        let color = '';
        switch (row.type.toLowerCase()) {
          case 'contract':
            color = '#E9F9F2';
            break;
          case 'tax':
            color = '#E4D2A2';
            break;
          case 'statement':
            color = '#F9D9DF';
            break;
          default:
            color = '#EEEEF3';
        }
        return <StatusCell status={row.type} backgroundColor={color} />;
      },
      sortable: true,
      compact: true,
      grow: 1,
    },
    {
      name: 'Uploaded By',
      selector: (row: CompanyDocument) => row.userId,
      format: (row: CompanyDocument) => {
        const foundUser = allUsers.find((user) => user.id === row.userId);
        return foundUser
          ? foundUser.firstName + ' ' + foundUser.lastName
          : 'Unknown';
      },
      sortable: true,
      compact: true,
      maxWidth: '160px',
    },
    {
      name: 'Upload Date',
      format: (row: CompanyDocument) => getLocaleDate(row.uploadedAt),
      selector: (row: CompanyDocument) => row.uploadedAt,
      sortable: true,
      compact: true,
      maxWidth: '110px',
    },
    {
      name: 'Document Date',
      selector: (row: CompanyDocument) => row.docDate,
      cell: (row: CompanyDocument) => <div>{getLocaleDate(row.docDate)}</div>,
      sortable: true,
      compact: true,
      center: true,
      maxWidth: '110px',
    },
    {
      name: '',
      cell: (row: CompanyDocument) => (
        <div>
          <button
            className={classes.actionButton}
            onClick={() => {
              dropDocument(row.id);
            }}
          >
            <IoTrashOutline size={18} color="#D80027" />
          </button>
        </div>
      ),
      sortable: true,
      compact: true,
      center: true,
      maxWidth: '110px',
    },
  ];

  const filterOptions: FilterOption[] = [
    {
      title: 'Type',
      column: 'type',
      options: allTypes.map((type) => ({ value: type, label: type })),
    },
  ];

  return (
    <FlexbaseMainLayout>
      <FlexbaseDefaultHeader title={'Document Management'} />
      <RightJustifiedRow>
        <Button
          variant="light"
          onClick={() => {
            openTransparentModal(
              <DocumentUploadModal
                types={allTypes}
                closeModal={handleModalClose}
              />,
            );
          }}
          leftIcon={<PlusSignIcon width={12} height={12} />}
        >
          Upload document
        </Button>
      </RightJustifiedRow>
      <LoadingOverlay visible={loading} />
      <TableContainerWithSearchFilter
        title="Documents"
        searchPlaceholder="Search documents"
        filterProps={{
          filters: filterOptions,
          initial: { column: filteredColumn, value: filteredValue },
          searchCallback: setFilter,
        }}
        searchProps={{ value: searchDoc, onChange: setSearchDoc }}
      >
        <FlexbaseTable
          data={filteredItems}
          columns={columns}
          defaultSortAsc={false}
          defaultSortFieldId="1"
          pagination={filteredItems && filteredItems?.length > 10}
          isFetchingData={loading}
          onRowClicked={(row: CompanyDocument) => {
            downloadDocument(row.docId, row.metadata.sourceName);
          }}
        />
      </TableContainerWithSearchFilter>
    </FlexbaseMainLayout>
  );
};

export default DocumentManagement;
