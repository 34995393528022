import FlexbaseInput from '@common/input/flexbase-input';
import { SearchIcon } from '../../../../assets/svg';
import { PiEyeSlashThin, PiEyeThin } from 'react-icons/pi';

import { FlexbaseTable } from '@common/table';
import {
  Box,
  Button,
  Group,
  Select,
  Text,
  useMantineTheme,
} from '@mantine/core';
import TabTableLayout from '@common/layouts/tab-table-layout';
import { useMediaQuery } from '@mantine/hooks';
import { useRecoilValue } from 'recoil';
import {
  useExpenseLinksByConnection,
  useUpdateChartOfAccountsByCategoryId,
} from '@queries/use-integrations';
import { TableColumn } from 'react-data-table-component';
import { Category } from '@flexbase-eng/types/dist/accounting';
import { useMappingFilters } from './mapping-filters';
import { isTruthyString } from '@utilities/validators/validate-string';
import { useStyles } from './styles';
import { useEffect, useState } from 'react';
import { useQueryParams } from '@utilities/url/query-param';
import { isEqual } from 'underscore';
import { IsIntegrationsAuthorized } from 'states/integrations/integrations';

const mappingsStep = () => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const queryParams = useQueryParams();
  const isAuthorized = useRecoilValue(IsIntegrationsAuthorized);
  const [selectedRows, setSelectedRows] = useState<Category[]>([]);
  const { data, isLoading } = useExpenseLinksByConnection(
    queryParams.get('connectionId')!,
    isAuthorized,
  );
  const { activeFiltersArray, removeFilter, addFilter } = useMappingFilters();
  const { mutate, isPending } = useUpdateChartOfAccountsByCategoryId();
  // This is used as a working copy of data that gets mutated before the user clicks 'save' and bulk updates with platform
  const [tableData, setTableData] = useState<Category[]>();
  // This is what is shown in the actual table and is just a subset of tableData
  const [filteredTableData, setFilteredTableData] = useState<Category[]>();
  const [visibilityFilter, setVisibilityFilter] = useState('All');

  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    // If the filters change or rows get modified, update the working copy so that it gets reflected on the table
    const filteredData =
      tableData?.filter((t) => activeFiltersArray.every((f) => f.fn(t))) ?? [];
    setFilteredTableData(filteredData);
  }, [activeFiltersArray, tableData]);

  const handleSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }

    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (category) => {
        const normalizedFilterText = value.toLowerCase().trim();
        return Object.values(category)
          .filter(isTruthyString)
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  const handleDisplayNameChange = (value: string, id: string) => {
    const newTableData = tableData?.map((category) => {
      if (category.id === id) {
        return { ...category, ...{ displayName: value } };
      }
      return category;
    });
    setTableData(newTableData);
  };

  const isVisible = (category: Category) => {
    return category.visible === true || category.visible === undefined;
  };

  const handleVisibilityFilterChange = (value: string) => {
    addFilter('visibility', {
      key: 'visibility',
      filterValue: value,
      label: `Is ${value}`,
      showChip: false,
      fn: (category) => {
        if (value === 'All') {
          return true;
        } else if (value === 'Visible') {
          // All categories will be default visible, but set to undefined in platform
          return isVisible(category);
        } else {
          // Invisible -> Some may be undefined, which should still be considered visible
          return !isVisible(category);
        }
      },
    });
    setVisibilityFilter(value);
    setSelectedRows([]);
  };

  const handleCategoryVisibilityChange = (row: Category) => {
    const newTableData = tableData?.map((category) => {
      if (category.id === row.id) {
        return { ...category, ...{ visible: !isVisible(category) } };
      }
      return category;
    });
    setTableData(newTableData);
  };

  const handleChangeVisibility = (visibility: boolean) => {
    setTableData(
      tableData?.map((category) => {
        return { ...category, ...{ visible: visibility } };
      }),
    );
  };

  const handleSave = async () => {
    mutate({
      // Compare the mappings and only send the dirty ones to platform - sending clean ones breaks it
      categories: tableData!
        .filter((category) => {
          const clean = data?.find((cat) => cat.id === category.id);
          return !isEqual(clean, category);
        })
        .map((category) => {
          return {
            id: category.id,
            displayName: category.displayName,
            visible: category.visible,
          };
        }),
      connectionId: queryParams.get('connectionId')!,
    });
  };

  const columns: TableColumn<Category>[] = [
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Name in Flex',
      selector: (row) => row.displayName ?? '',
      cell: (row) => (
        <FlexbaseInput
          w={isMobile ? '100%' : '320px'}
          onChange={(e) => handleDisplayNameChange(e.target.value, row.id)}
          defaultValue={row.displayName}
        />
      ),
      sortable: true,
    },
    {
      name: 'Visible in Flex?',
      selector: (row) => row.visible ?? true,
      cell: (row) => {
        return (
          <Box
            className={classes.iconColumn}
            sx={() => ({
              cursor: 'default',
            })}
            key={row.id}
          >
            {isVisible(row) ? (
              <Group>
                <Text className={classes.iconText}>Visible </Text>
                <PiEyeThin
                  className={classes.icon}
                  style={{ color: theme.colors.neutral[5] }}
                  onClick={() => handleCategoryVisibilityChange(row)}
                />
              </Group>
            ) : (
              <Group>
                <Text className={classes.iconText}>Hidden </Text>
                <PiEyeSlashThin
                  className={classes.icon}
                  onClick={() => handleCategoryVisibilityChange(row)}
                />
              </Group>
            )}
          </Box>
        );
      },
      sortable: true,
    },
  ];

  return (
    <TabTableLayout
      leftHeaderContent={
        <Group>
          <FlexbaseInput
            w={isMobile ? '100%' : '260px'}
            placeholder="Search categories"
            onChange={(e) => handleSearchChange(e.target.value)}
            defaultValue={undefined}
            icon={<SearchIcon width={20} height={20} />}
          />
          <Select
            w="105px"
            classNames={{
              input: classes.noBorder,
              wrapper: classes.select,
              rightSection: visibilityFilter === 'All' ? classes.leftIcon : '',
            }}
            value={visibilityFilter}
            data={[
              { value: 'All', label: 'All' },
              { value: 'Visible', label: 'Visible' },
              { value: 'Hidden', label: 'Hidden' },
            ]}
            onChange={handleVisibilityFilterChange}
          />
        </Group>
      }
      borderBottomSolid={false}
      rightHeaderContent={
        <Group>
          <Button
            className={classes.darkGreenButton}
            ml={'0.5rem'}
            variant="light"
            fullWidth={false}
            disabled={!isAuthorized}
            onClick={handleSave}
            data-testid={'save-mappings'}
          >
            Save Mapping
          </Button>
          <Button
            className={classes.greenButton}
            ml={'0.5rem'}
            variant="outline"
            fullWidth={false}
            leftIcon={<PiEyeThin className={classes.icon} />}
            disabled={!isAuthorized}
            onClick={() => handleChangeVisibility(true)}
            data-testid={'toggle-all-on'}
          >
            Make all visible
          </Button>
          <Button
            className={classes.greenButton}
            ml={'0.5rem'}
            variant="outline"
            fullWidth={false}
            leftIcon={<PiEyeSlashThin className={classes.icon} />}
            disabled={!isAuthorized}
            onClick={() => handleChangeVisibility(false)}
            data-testid={'toggle-all-off'}
          >
            Make all hidden
          </Button>
        </Group>
      }
      table={
        <FlexbaseTable
          columns={columns}
          selectableRows
          onSelectedRowsChange={({ selectedRows: selected }) =>
            setSelectedRows(selected)
          }
          data={filteredTableData ?? []}
          isFetchingData={isLoading}
          noDataComponent={
            <Text size={24} fw={500} mt="lg">
              No accounts available
            </Text>
          }
          bulkActionsConfig={{
            actions: [
              {
                key: 'make-visible',
                label: 'Show in Flex',
                loading: isPending,
                icon: <PiEyeThin />,
                onClick: () => {
                  if (selectedRows.length) {
                    const modifiedIds = selectedRows.map(
                      (selected) => selected.id,
                    );
                    setTableData(
                      tableData?.map((category) => {
                        if (modifiedIds.includes(category.id)) {
                          return { ...category, ...{ visible: true } };
                        }
                        return category;
                      }),
                    );
                  }
                },
              },
              {
                key: 'make-invisible',
                label: 'Hide in Flex',
                loading: isPending,
                icon: <PiEyeSlashThin />,
                onClick: () => {
                  if (selectedRows.length) {
                    const modifiedIds = selectedRows.map(
                      (selected) => selected.id,
                    );
                    setTableData(
                      tableData?.map((category) => {
                        if (modifiedIds.includes(category.id)) {
                          return { ...category, ...{ visible: false } };
                        }
                        return category;
                      }),
                    );
                  }
                },
              },
            ],
          }}
        />
      }
    />
  );
};

export default mappingsStep;
