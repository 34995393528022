import { UseFormReturnType } from '@mantine/form';
import { createContext, useContext } from 'react';
import { DetailsStepFormValues } from './steps/details/details-step.context';
import { SpendControlsStepFormValues } from './steps/spend-controls/spend-controls-step.context';
import { TeamMembersStepFormValues } from './steps/team-members/team-members-step.context';
import { RestrictionsStepFormValues } from './steps/restrictions/restrictions-step.context';

export type CreateSpendPlanContextType = {
  detailsForm: UseFormReturnType<DetailsStepFormValues>;
  spendControlsForm: UseFormReturnType<SpendControlsStepFormValues>;
  teamMembersForm: UseFormReturnType<TeamMembersStepFormValues>;
  restrictionsForm: UseFormReturnType<RestrictionsStepFormValues>;
  validateAll: () => void;
  isValidAll: () => boolean;
  getInvalidSteps: () => string[];
};

export const CreateSpendPlanContext =
  createContext<CreateSpendPlanContextType | null>(null);

export const useCreateSpendPlanContext = () => {
  const ctx = useContext(CreateSpendPlanContext);

  if (!ctx) {
    throw new Error(
      'useCreateSpendPlanContext must be called within a CreateSpendPlanContext',
    );
  }

  return ctx;
};
