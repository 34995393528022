import { Text } from '@mantine/core';
import { PropsWithChildren } from 'react';

export const TeamMembersTableHeader = ({ children }: PropsWithChildren) => {
  return (
    <Text color="primarySecondarySuccess.8" fw={600}>
      {children}
    </Text>
  );
};
