import { createStyles } from '@mantine/core';
import RadioCard from 'components/input/radio-card';

type Option = {
  value: string;
  label: string;
};

type Props = {
  options: Option[];
  selected?: string;
  onChange: (selectedOption: Option) => void;
};

const OnboardingRadioSelect = ({ options, onChange, selected }: Props) => {
  const { classes, cx } = useStyles();
  return (
    <>
      {options.map((opt, index) => (
        <RadioCard
          key={opt.value}
          onSelect={() => onChange(opt)}
          isSelected={selected === opt.value}
          title={opt.label}
          className={cx(
            classes.radioCard,
            index > 0 && classes.radioCardSpacing,
          )}
          id={`radio-button-${opt.value.toLowerCase().replace(' ', '-')}`}
        />
      ))}
    </>
  );
};

const useStyles = createStyles({
  radioCard: {
    minHeight: '70px',
    gridTemplateColumns: '48px 1fr',
  },
  radioCardSpacing: {
    marginTop: '16px',
  },
});

export default OnboardingRadioSelect;
