import {
  Box,
  Group,
  Stack,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { AvatarLogoCell } from '@common/table';
import { CloseIcon, CrownIcon } from 'assets/svg';
import { MouseEventHandler } from 'react';

type AvatarTableCellProps = {
  name: string;
};

export const AvatarTableCell = ({ name }: AvatarTableCellProps) => {
  return (
    <Box h={32} w={32}>
      <AvatarLogoCell placeholderName={name} withLabel={false} />
    </Box>
  );
};

type MemberDetailsCellProps = {
  name: string;
  email: string;
  isManager?: boolean;
};

export const MemberDetailsTableCell = ({
  name,
  email,
  isManager = false,
}: MemberDetailsCellProps) => {
  const theme = useMantineTheme();
  const fullName = name.trim();

  return (
    <Stack spacing={0} py={8} w="100%">
      <Tooltip label={fullName}>
        <Group spacing={4} noWrap>
          <Text
            fz={14}
            color={theme.fn.themeColor('neutral', 9)}
            truncate="end"
          >
            {fullName}
          </Text>

          {isManager ? <CrownIcon /> : false}
        </Group>
      </Tooltip>

      {email ? (
        <Tooltip label={email}>
          <Text color={theme.fn.themeColor('neutral', 6)} truncate>
            {email}
          </Text>
        </Tooltip>
      ) : null}
    </Stack>
  );
};

export const ActionTableCell = ({
  onClick,
}: {
  onClick: MouseEventHandler<SVGSVGElement>;
}) => {
  return <CloseIcon height={16} width={16} onClick={onClick} />;
};
