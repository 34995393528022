import { RightContentModal } from '@common/modal/right-content.modal';
import { Box, Button, Flex, Grid, Text, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { useGetMe } from '@queries/use-get-me';
import { useRequestFunds } from '@queries/use-spend-plans';
import { formatCurrency } from '@utilities/formatters/format-currency';
import FlexNumberInput from 'areas/payments/components/common/flex-number-input';
import { VscChromeClose } from 'react-icons/vsc';
import { useStyles } from '../styles';
import {
  SpendPlanAdminView,
  SpendPlanView,
} from '@flexbase-eng/types/dist/accounting';

type Props = {
  closeModal: () => void;
  data?: SpendPlanAdminView | SpendPlanView;
  spendPlanId?: string;
};

const RequestFundsModal = ({ closeModal, data, spendPlanId }: Props) => {
  const { classes } = useStyles();
  const { data: me } = useGetMe();
  const requestFundsForm = useForm({
    initialValues: {
      amountRequested: 0,
      reason: '',
    },
    validate: {
      amountRequested: (val) =>
        val > 0 ? null : 'Amount must be greater than 0',
      reason: (val) =>
        val !== '' ? null : 'A note is required to request funds',
    },
  });

  const { mutate: requestFunds } = useRequestFunds();

  const handleRequestFunds = requestFundsForm.onSubmit(() => {
    requestFunds(
      {
        accountId: me?.accountId || '',
        spendPlanId: spendPlanId || '',
        body: {
          amount: requestFundsForm.values.amountRequested,
          description: requestFundsForm.values.reason,
        },
      },
      {
        onSuccess: () => {
          closeModal();
          showNotification({
            message: `Funds requested`,
            color: 'flexbase-teal',
          });
        },
        onError: () => {
          closeModal();
          showNotification({
            title: `Something went wrong`,
            message: 'Unable to send the request',
            color: 'failure',
          });
        },
      },
    );
  });

  return (
    <RightContentModal>
      <Flex
        bg="primarySecondarySuccess.7"
        p="32px"
        justify="space-between"
        align="center"
      >
        <Text fz="40px" fw={400} lh="56px" c="neutral.0">
          Request funds
        </Text>
        <button
          className={classes.closeButton}
          onClick={() => {
            closeModal();
          }}
        >
          <VscChromeClose size={34} color="#FFFF" />
        </button>
      </Flex>
      <RightContentModal.Body>
        <Grid>
          <Grid.Col span={4}>
            <Text fz="14px" c="neutral.7">
              Your limit this period
            </Text>
            <Text fz="16px" c="primarySecondarySuccess.8">
              {formatCurrency(data?.limit || 0)}
            </Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text fz="14px" c="neutral.7">
              Spent
            </Text>
            <Text fz="16px" c="primarySecondarySuccess.8">
              {formatCurrency(data?.spent || 0)}
            </Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text fz="14px" c="neutral.7">
              Available funds
            </Text>
            <Text fz="16px" c="primarySecondarySuccess.8">
              {formatCurrency(data?.remaining || 0)}
            </Text>
          </Grid.Col>
        </Grid>
        <Box mt="40px">
          <Text mb="4px" fz="14px" c="primarySecondarySuccess.8">
            How much are you requesting?
          </Text>
          <FlexNumberInput
            variant="default"
            icon={<>$</>}
            pattern="[0-9]*"
            placeholder="0.00"
            thousandSeparator=","
            onValueChange={(value) => {
              requestFundsForm.setFieldValue(
                'amountRequested',
                value.floatValue ?? 0,
              );
            }}
            value={requestFundsForm.values.amountRequested}
            error={requestFundsForm.errors.amountRequested}
          />
        </Box>
        <Box mt="40px">
          <Text mb="4px" fz="14px" c="primarySecondarySuccess.8">
            Reason for requesting additional funds
          </Text>
          <Textarea
            placeholder="Enter reason"
            size="6rem"
            classNames={{ input: classes.memoInput }}
            {...requestFundsForm.getInputProps('reason')}
            minRows={5}
          />
          <Text fz="10px" c="neutral.6" mt="4px">
            {' '}
            This message will be attached to the funds request submitted to the
            Spend Plan managers.{' '}
          </Text>
        </Box>
        <Flex mt="40px" justify="flex-end">
          <Button variant="outline" mr="24px" onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={() => handleRequestFunds()}> Request funds </Button>
        </Flex>
      </RightContentModal.Body>
    </RightContentModal>
  );
};

export default RequestFundsModal;
