import {
  PAYMENT_STEP_VALUES,
  PaymentMethodStepValue,
} from '../../add-recipient';
import {
  AddRecipientWizard,
  useAddRecipientWizard,
} from '../../add-recipient-wizard';
import { useAddRecipientSubmit } from '../../use-add-recipient-submit';

// In forthcoming work, this hook will be used to conditionally render the appropriate payment details form components (ACH/Wire for creating a counterparty, int'l for getting beneficiary requirements for the last step)
const useConditionalPaymentDetailsForm = (
  paymentMethod: PaymentMethodStepValue,
) => {
  const { domesticACH, domesticWire, internationalWireUSD, internationalWire } =
    PAYMENT_STEP_VALUES;

  switch (paymentMethod) {
    case domesticACH:
      return PAYMENT_STEP_VALUES.domesticACH;
    case domesticWire:
      return PAYMENT_STEP_VALUES.domesticWire;
    case internationalWireUSD:
      return PAYMENT_STEP_VALUES.internationalWireUSD;
    case internationalWire:
      return PAYMENT_STEP_VALUES.internationalWire;
    default:
      return 'unknown payment method';
  }
};

export const PaymentDetailsStep = () => {
  const { goToNextStep, state, hasNextStep } = useAddRecipientWizard();
  const form = useConditionalPaymentDetailsForm(state.paymentMethod);
  const { submitRecipient, isPending } = useAddRecipientSubmit(state);

  const handleNext = () => {
    if (hasNextStep) {
      goToNextStep();
    } else {
      submitRecipient();
    }
  };

  return (
    <AddRecipientWizard.Step onNext={handleNext} nextLoading={isPending}>
      <h1>use {form} form and hook for payment details</h1>
    </AddRecipientWizard.Step>
  );
};

PaymentDetailsStep.stepId = 'payment-details-step';
