import { Box, Button, Text, Tooltip } from '@mantine/core';
import { useStyles } from '../styles';
import { IntegrationCardItem } from '../list-integrations';
import { ExpenseLink } from '@flexbase-eng/types/dist/accounting';
import { DisconnectButton } from './disconnect-button';
import { useIntegrationsContext } from '../integrations.context';
import { IntegrationCard } from './integration-card';
import { Link } from 'react-router-dom';
import {
  isIntegrationLinkConnected,
  isQuickbooks,
  isQuickbooksBankFeeds,
  isQuickbooksExpenses,
} from '@utilities/integrations';

type UseAccountingCardReturnType = {
  notReadyDescription: string;
  connectDisabled: boolean;
  manageRoute: string;
};

/**
 * Specific logic for Quickbooks accounting integrations.
 */
const useQuickbooksIntegrationCard = (
  integration: IntegrationCardItem,
  link?: ExpenseLink,
): UseAccountingCardReturnType => {
  const { accountingLinks, connectDisabled } = useIntegrationsContext();

  let notReadyDescription = `Once your ${integration.title} integration has successfully connected, you will be able to categorize and sync your transactions. To enable auto-sync or auto-categorization, return here to adjust your settings.`;
  let manageRoute = `/settings/integrations/accounting/${
    link?.connectionId || ''
  }`;

  if (isQuickbooksBankFeeds(integration)) {
    const qboExpenses = accountingLinks.find(isQuickbooksExpenses);

    if (qboExpenses) {
      manageRoute = `/settings/integrations/accounting/${qboExpenses.connectionId}`;
    }

    notReadyDescription = `Once your ${integration.title} integration has successfully connected, you will be able to integrate your Flex line of credit and banking accounts to QBO bank feeds.`;
  }

  return {
    notReadyDescription,
    connectDisabled:
      connectDisabled || accountingLinks.some((l) => !isQuickbooks(l)),
    manageRoute,
  };
};

/**
 * Default logic for accounting integrations.
 */
const useAccountingIntegrationCard = (
  integration: IntegrationCardItem,
  link?: ExpenseLink,
): UseAccountingCardReturnType => {
  const { accountingLinks, intuitFeedsLink, connectDisabled } =
    useIntegrationsContext();
  const { ...quickBooks } = useQuickbooksIntegrationCard(integration, link);

  if (isQuickbooks(integration)) {
    return { ...quickBooks };
  }

  const accountingAlreadyConnected = [...accountingLinks, intuitFeedsLink].some(
    isIntegrationLinkConnected,
  );

  return {
    notReadyDescription: `Once your ${integration.title} integration has successfully connected, you will be able to categorize and sync your transactions. To enable auto-sync or auto-categorization, return here to adjust your settings.`,
    connectDisabled: connectDisabled || accountingAlreadyConnected,
    manageRoute: `/settings/integrations/accounting/${
      link?.connectionId ?? ''
    }`,
  };
};

type AccountingIntegrationCardProps = {
  integration: IntegrationCardItem;
  link: ExpenseLink | undefined;
};

/**
 * Integration card with specific logic for accounting integrations.
 */
export const AccountingIntegrationCard = ({
  integration,
  link,
}: AccountingIntegrationCardProps) => {
  const { classes } = useStyles();
  const {
    connectIntegration,
    disconnectIntegration,
    isDisconnecting,
    disconnectDisabled,
  } = useIntegrationsContext();
  const { manageRoute, notReadyDescription, connectDisabled } =
    useAccountingIntegrationCard(integration, link);

  const platform = integration.platform || '';

  const handleDisconnect = (definedLink: ExpenseLink) => {
    disconnectIntegration(integration, definedLink);
  };

  return (
    <IntegrationCard integration={integration} link={link}>
      {isIntegrationLinkConnected(link) ? (
        link.isReady ? (
          <Box>
            <Box className={classes.cardDescriptionRow} mb="xl">
              <Text className={classes.cardDescription}>
                Your {integration.title} integration is configured and ready to
                use.
              </Text>
            </Box>

            <Box>
              <Button variant="outline" component={Link} to={manageRoute}>
                Manage
              </Button>
            </Box>
          </Box>
        ) : (
          <>
            <Box className={classes.cardDescriptionRow} mb="xl">
              <Text className={classes.cardDescription}>
                {notReadyDescription}
              </Text>
            </Box>

            <Box>
              <DisconnectButton
                onClick={() => handleDisconnect(link)}
                disabled={disconnectDisabled}
                loading={isDisconnecting}
              >
                Disconnect
              </DisconnectButton>
            </Box>
          </>
        )
      ) : (
        <Box>
          <Box className={classes.cardDescriptionRow} mb="xl">
            <Text className={classes.cardDescription}>
              {integration.content}
            </Text>
          </Box>

          {platform ? (
            <Tooltip
              label="Only 1 accounting platform (Quickbooks, NetSuite, Xero) allowed at this time."
              disabled={!connectDisabled}
            >
              <Box sx={{ width: 'fit-content' }}>
                <Button
                  variant="outline"
                  onClick={() => connectIntegration(platform)}
                  disabled={connectDisabled}
                >
                  Connect
                </Button>
              </Box>
            </Tooltip>
          ) : (
            <Box>
              <Button disabled>Coming soon!</Button>
            </Box>
          )}
        </Box>
      )}
    </IntegrationCard>
  );
};
