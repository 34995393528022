import { Text, View } from '@react-pdf/renderer';
import { stylesStatementPDF } from './styles';
import { AccountSnapshot, BankSnapshot } from '../../treasury-management.state';

type ViewStatementDetailsProps = {
  banks: Record<string, BankSnapshot>;
};

const calcAccountTypesString = (accts: AccountSnapshot[]) => {
  let response = '';
  for (const acct of accts) {
    response = response + acct.accountType + ' (' + acct.accountMask + ')\n';
  }
  return response.substring(0, response.length - 1);
};

const calcPercentTotalAvailable = (
  accts: Record<string, BankSnapshot>,
  currentBank: BankSnapshot,
) => {
  let total = 0;
  Object.keys(accts).map((k) => {
    total = total + accts[k].availableBalance;
  });
  return ((currentBank.availableBalance / total) * 100).toFixed(2).toString();
};

const ViewStatementDetails = ({ banks }: ViewStatementDetailsProps) => {
  return (
    <View
      style={{
        ...stylesStatementPDF.cardTable,
        marginTop: 20,
        height: 'auto',
      }}
    >
      <View style={stylesStatementPDF.tableContainer}>
        <View style={{ width: '30%' }}>
          <Text style={stylesStatementPDF.text}>Bank Name</Text>
        </View>
        <View style={{ width: '25%' }}>
          <Text style={stylesStatementPDF.text}>Account Types</Text>
        </View>
        <View style={{ width: '15%' }}>
          <Text style={stylesStatementPDF.text}>% Insured</Text>
        </View>
        <View style={{ width: '15%' }}>
          <Text style={stylesStatementPDF.text}>% Uninsured</Text>
        </View>
        <View style={{ width: '15%' }}>
          <Text style={stylesStatementPDF.text}>% of Total Cash</Text>
        </View>
      </View>
      <View
        style={{
          border: '0.8px solid #EAEAEA',
          width: '100%',
          marginTop: 10,
        }}
      />
      <>
        {Object.keys(banks).map((k, v) => {
          return (
            <View key={v}>
              <View
                style={{
                  ...stylesStatementPDF.tableContainer,
                  marginTop: 10,
                }}
              >
                <View style={{ width: '30%' }}>
                  <Text style={stylesStatementPDF.text}>{k.toString()}</Text>
                </View>
                <View style={{ width: '25%' }}>
                  <Text
                    style={{
                      ...stylesStatementPDF.text,
                      color: 'black',
                    }}
                  >
                    {calcAccountTypesString(banks[k].accounts)}
                  </Text>
                </View>
                <View style={{ width: '15%' }}>
                  <Text
                    style={{
                      ...stylesStatementPDF.text,
                      color: 'black',
                    }}
                  >
                    {banks[k].availableBalance > 250000
                      ? (
                          (1 -
                            (banks[k].availableBalance - 250000) /
                              banks[k].availableBalance) *
                          100
                        ).toFixed(2)
                      : '100.00'}
                  </Text>
                </View>
                <View style={{ width: '15%' }}>
                  <Text
                    style={{
                      ...stylesStatementPDF.text,
                      color: 'black',
                    }}
                  >
                    {banks[k].availableBalance > 250000
                      ? (
                          ((banks[k].availableBalance - 250000) /
                            banks[k].availableBalance) *
                          100
                        ).toFixed(2)
                      : '0.00'}
                  </Text>
                </View>
                <View style={{ width: '15%' }}>
                  <Text
                    style={{
                      ...stylesStatementPDF.text,
                      color: 'black',
                    }}
                  >
                    {calcPercentTotalAvailable(banks, banks[k])}
                  </Text>
                </View>
              </View>
            </View>
          );
        })}
      </>
    </View>
  );
};

export default ViewStatementDetails;
