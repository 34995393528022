import { useStyles } from './styles';
import {
  InvoiceComments,
  PayableActions,
  PayableApprovers,
  PayableCounterpartySearch,
  PayableDetails,
  PayableDocument,
  PayableFees,
  PayableForm,
  PayableFormHeader,
  PayableLineItems,
  PayableMetadata,
  PayableOverview,
  PayablePaymentDestination,
  PayablePaymentSource,
} from '@mercoa/react';
import { Box, Button, Divider, Flex, Text, Tooltip } from '@mantine/core';
import '@mercoa/react/dist/style.css';
import { InvoiceFeesResponse, InvoiceResponse } from '@mercoa/javascript/api';
import { formatCurrency } from '@utilities/formatters/format-currency';
import { BsInfoCircle } from 'react-icons/bs';
import { useState } from 'react';

type Props = {
  invoice?: InvoiceResponse;
  closeModal: () => void;
};

const FeesSection = ({
  invoice,
  fees,
}: {
  invoice?: InvoiceResponse;
  fees?: InvoiceFeesResponse;
}) => {
  if (!invoice || !fees) {
    return;
  }

  const feePercent = 2.5; // the fee percent is static for MVP launch
  const invoiceAmount = invoice.amount ?? 0.0;
  const totalFee = fees.sourcePlatformMarkupFee ?? 0.0;
  const totalAmount = invoiceAmount + totalFee;
  const shouldShowTooltip = totalFee > 0;

  return (
    <>
      <Flex pl="xl" justify="space-between">
        <Text>Invoice Amount</Text>
        <Text>{formatCurrency(invoiceAmount)}</Text>
      </Flex>
      <Flex pl="xl" justify="space-between">
        <Tooltip
          multiline
          width={370}
          color="neutral.0"
          c="primarySecondarySuccess.7"
          disabled={!shouldShowTooltip}
          sx={(theme) => ({
            borderRadius: theme.defaultRadius,
            border: `1px solid ${theme.fn.themeColor('neutral', 3)}`,
          })}
          label={`This fee is charged separately from the amount paid to vendors. This credit card transaction carries a ${feePercent}% processing fee.`}
        >
          <Flex align="center" gap="xs">
            <Text>Payment Fees</Text>
            {shouldShowTooltip && <BsInfoCircle />}
          </Flex>
        </Tooltip>
        <Text>{formatCurrency(totalFee)}</Text>
      </Flex>
      <Divider mb="xs" />
      <Flex pl="xl" justify="space-between">
        <Text>Total Payment</Text>
        <Text>{formatCurrency(totalAmount)}</Text>
      </Flex>
    </>
  );
};

const ActionButtons = ({
  actionButtons,
  isSaving,
}: {
  actionButtons: JSX.Element[];
  isSaving: boolean;
}) => {
  const [loadingButton, setLoadingButton] = useState<string | null>(null);

  const handleButtonClick = (label: string, handleOnClick: () => void) => {
    setLoadingButton(label);
    handleOnClick();
  };

  return (
    <Flex gap="sm" justify="flex-end" sx={() => ({ gridColumn: '1/-1' })}>
      {actionButtons
        .map(({ props: button }) => {
          const label = button.children;
          const handleOnClick = button.onClick;
          const isButtonDisabled = button.disabled;
          const isRejectButton = label === 'Reject';
          const isLoading = isSaving && loadingButton === label;
          const noAllowedButtons = [
            'Archive Invoice',
            'Retry Payment',
          ].includes(label);
          const outlineButtonStatus = [
            'Cancel Payment',
            'Delete Invoice',
            'Waiting for approval',
          ].includes(label);
          const variantButton = outlineButtonStatus ? 'outline' : 'default';

          // I don't know why Mercoa module has some buttons like this
          // if we don't separate this scenario, two buttons are displaying
          // one inside of the other
          const innerProps = button.children?.props;
          if (innerProps) {
            return (
              <Button
                type="submit"
                key={innerProps.children}
                disabled={innerProps.disabled}
                loading={isSaving && loadingButton === innerProps.children}
                onClick={() =>
                  handleButtonClick(innerProps.children, innerProps.onClick)
                }
              >
                {innerProps.children}
              </Button>
            );
          }

          return (
            <Button
              key={label}
              type="submit"
              loading={isLoading}
              variant={variantButton}
              disabled={isButtonDisabled}
              display={noAllowedButtons ? 'none' : 'initial'}
              onClick={() => handleButtonClick(label, handleOnClick)}
              sx={(theme) => ({
                ...(isRejectButton && {
                  backgroundColor: theme.fn.themeColor('critical.4'),
                  border: `1px solid ${theme.fn.themeColor('critical.4')}`,
                  '&:not([data-disabled])': {
                    '&:hover': {
                      backgroundColor: theme.fn.themeColor('critical.5'),
                      border: `1px solid ${theme.fn.themeColor('critical.5')}`,
                    },
                  },
                }),
                '&[data-disabled]': {
                  border: `1px solid ${theme.fn.themeColor('neutral.5')}`,
                },
              })}
            >
              {label}
            </Button>
          );
        })
        .reverse()}
    </Flex>
  );
};

const UploadBill = ({ invoice, closeModal }: Props) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      <PayableDetails
        heightOffset={200}
        invoice={invoice}
        onUpdate={(currentInvoice) => {
          if (!currentInvoice) {
            closeModal();
          }
        }}
      >
        {(props) => [
          <PayableDocument {...props} key="pdf-content" />,
          <PayableForm {...props} key="invoice-form">
            {({ setSelectedVendor, selectedVendor }) => (
              <>
                <Box className={classes.payableFormHeader}>
                  <PayableFormHeader />
                </Box>
                <Divider w="65vh" />
                <Box className={classes.payableCounterpartySearch}>
                  <PayableCounterpartySearch
                    onSelect={setSelectedVendor}
                    counterparty={selectedVendor}
                  />
                </Box>
                <Divider w="65vh" />
                <Box className={classes.payableOverview}>
                  <PayableOverview />
                </Box>
                <Box className={classes.payableItems}>
                  <PayableLineItems />
                </Box>
                <PayableMetadata />
                <Divider w="65vh" />
                <Box className={classes.payablePaymentSource}>
                  <PayablePaymentSource />
                </Box>
                <Divider w="65vh" />
                <Box className={classes.payablePaymentDestination}>
                  <PayablePaymentDestination />
                </Box>
                <Divider w="65vh" />
                <PayableFees>
                  {({ fees }) => (
                    <Box mt="xl" style={{ gridColumn: '2/4' }}>
                      <FeesSection invoice={props.invoice} fees={fees} />
                    </Box>
                  )}
                </PayableFees>
                <Box className={classes.payableApprovers}>
                  <PayableApprovers />
                </Box>
                <Divider w="65vh" />
                <Box className={classes.invoiceComments}>
                  <InvoiceComments />
                </Box>
                <PayableActions>
                  {({ buttons, isSaving }) => (
                    <ActionButtons
                      actionButtons={buttons ?? []}
                      isSaving={isSaving}
                    />
                  )}
                </PayableActions>
              </>
            )}
          </PayableForm>,
        ]}
      </PayableDetails>
    </Box>
  );
};

export default UploadBill;
