import { atom } from 'recoil';
import {
  ActiveFiltersReturnType,
  createUseFiltersHook,
  FilterFnMap,
} from '@common/filter/filters';
import { InvoiceTableData } from '@services/flexbase/billing.model';

const BillingPaidFilterState = atom<FilterFnMap<InvoiceTableData>>({
  key: 'billing_paid_filter_state',
  default: {},
});

export function useBillingPaidFilters(): ActiveFiltersReturnType<InvoiceTableData> {
  return createUseFiltersHook<InvoiceTableData>(BillingPaidFilterState);
}
