import { useForm } from '@mantine/form';
import { validateRequired } from '@utilities/validators/validate-required';
import { isInternationalWire } from '../util';
import {
  AvailableCurrencies,
  IndividualOrCompany,
  InternationalPayMethod,
  InternationalRecipient,
} from '../util/types';

const getInitialInternationalRecipientFormValues = (
  recipientName: string,
  recipientId: string,
  method: InternationalPayMethod,
) => ({
  name: recipientName,
  recipientId,
  nickName: '',
  accountHolder: '',
  recipientType: 'individual' as IndividualOrCompany,
  type: method,
  currency: isInternationalWire(method)
    ? (undefined as unknown as AvailableCurrencies)
    : 'USD',
  countryOfRecipientsBank: '',
  countryOfRecipient: '',
});

const validateField = (value: string | undefined, valueType: string) => {
  return validateRequired(value) ? null : `Please select a ${valueType}.`;
};

export const useInternationalRecipientForm = (
  recipientName: string,
  recipientId: string,
  method: InternationalPayMethod,
) => {
  const form = useForm<InternationalRecipient>({
    initialValues: getInitialInternationalRecipientFormValues(
      recipientName,
      recipientId,
      method,
    ),
    validate: {
      name: (val) => validateField(val, 'nickname'),
      accountHolder: (val) => validateField(val, 'account holder'),
      currency: (val) => validateField(val, 'currency'),
      countryOfRecipientsBank: (val) => validateField(val, 'country'),
      countryOfRecipient: (val) => validateField(val, 'country'),
    },
  });

  return form;
};
