import { MultiStepFormStepWrapper } from '@common/multi-step-form/multi-step-form-step-wrapper';
import { useForm } from '@mantine/form';
import { DateTime } from 'luxon';
import FlexPatternFormat from '../../../payments/components/common/flex-pattern-format';
import { validateUSBirthDate } from '@utilities/validators/validate-dates';
import { useGetMe, useUpdatePlatformPersonMutation } from '@queries/use-get-me';
import { formatDateForApi } from '@utilities/formatters/format-date-input';
import { usePrefillMultistepFormContext } from '../../pages/prefill/prefill-multi-step-form.context';

export const PrefillConfirmBirthdayStep = () => {
  const { setState, goToNextStep } = usePrefillMultistepFormContext();
  const { data: person } = useGetMe();
  const { mutate: updatePerson } = useUpdatePlatformPersonMutation();

  const birthdayForm = useForm({
    initialValues: {
      dateOfBirth: person?.dateOfBirth
        ? DateTime.fromISO(person.dateOfBirth, { zone: 'utc' }).toFormat(
            'MM/dd/yyyy',
          )
        : '',
    },
    validate: {
      dateOfBirth: (val) =>
        validateUSBirthDate(val)
          ? null
          : 'Must be at least 18 in format MM/DD/YYYY.',
    },
  });

  const handleFormSubmit = async () => {
    const validationResult = birthdayForm.validate();
    if (!validationResult.hasErrors) {
      setState({ loading: true, error: '' });
      updatePerson(
        {
          accountId: person!.accountId,
          personId: person!.id,
          update: {
            dateOfBirth: formatDateForApi(birthdayForm.values.dateOfBirth),
          },
        },
        {
          onSuccess: () => {
            goToNextStep({
              dateOfBirthProvided: true,
              loading: false,
            });
          },
          onError: () => {
            setState({
              error: 'Date of birth could not be updated',
              loading: false,
            });
          },
        },
      );
    }
  };

  return (
    <MultiStepFormStepWrapper id="birthday" onFormSubmit={handleFormSubmit}>
      <FlexPatternFormat
        format="##/##/####"
        inputMode="numeric"
        data-sardine-id="input-birthdate"
        {...birthdayForm.getInputProps('dateOfBirth')}
        label="Birthday"
        placeholder="mm/dd/yyyy"
        id="input-birthdate"
      />
    </MultiStepFormStepWrapper>
  );
};
