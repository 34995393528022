import { Button, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { ArrowIcon, Arrow } from 'assets/svg';
import { Dispatch, SetStateAction } from 'react';
import {
  createSearchParams,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Recipient } from 'states/recipient/recipient';
import { UserInfoState } from 'states/user/user-info';

type Props = {
  showFilters: boolean;
  setShowFilters: Dispatch<SetStateAction<boolean>>;
};

type AccountInfoContext = {
  recipient: Recipient;
};

const PaymentHeader = ({ showFilters, setShowFilters }: Props) => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const context = useOutletContext<AccountInfoContext>();
  const user = useRecoilValue(UserInfoState);
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const isRecipientDetailsPage =
    window.location.pathname.includes('/recipient-details');

  const handleClickNavigate = () => {
    if (isRecipientDetailsPage) {
      navigate({
        pathname: '/payments/outgoing/recipient',
        search: createSearchParams({
          recipient: context.recipient.name,
          recipientId: context.recipient.id,
        }).toString(),
      });
    } else {
      navigate('recipient');
    }
  };

  return (
    <>
      <Button
        variant="outline"
        rightIcon={<ArrowIcon />}
        onClick={() => setShowFilters((prev) => !prev)}
        styles={{
          rightIcon: { rotate: !showFilters ? '180deg' : '' },
        }}
      >
        {`${showFilters ? 'Collapse' : 'Expand'} filters`}
      </Button>
      <Button
        ml={'0.5rem'}
        variant="light"
        fullWidth={useMobileView}
        leftIcon={<Arrow />}
        disabled={!user.roles.includes('ADMIN')}
        onClick={handleClickNavigate}
        data-testid={'send-payment'}
      >
        Send a payment
      </Button>
    </>
  );
};

export default PaymentHeader;
