import { useLoginFormContext } from './login-form.context';
import AuthLoader from '@common/login/auth-loader';
import { ChevronDownIcon, RedAlertIcon } from '../../assets/svg';
import { Alert, Box, Text, Title } from '@mantine/core';
import { MultiStepFormSubmitButton } from '@common/multi-step-form/multi-step-form-submit-button';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useLoginStyles } from './login.styles';
import { useAuthToken } from '../../states/auth/auth-token';

export const LoginFormContainer = () => {
  const { classes } = useLoginStyles();
  const navigate = useNavigate();
  const { state, goToStep, currentStep, hasPreviousStep, goToPreviousStep } =
    useLoginFormContext();
  const { clearToken } = useAuthToken();
  const [showBuildClickCount, setShowBuildClickCount] = useState(0);

  const handleBackClick = () => {
    if (hasPreviousStep) {
      clearToken();
      // TODO: This is a stopgap since the conditional rendering of the choose factor screen will not properly work (yet)
      if (currentStep?.metadata.id === '2FA' && state.factorList.length < 2) {
        goToStep('password-login', { error: null, loading: false });
      } else {
        goToPreviousStep({ error: null, loading: false });
      }
    } else {
      navigate('/register');
    }
  };

  return (
    <>
      {state.loading && <AuthLoader />}
      <Box>
        <div onClick={handleBackClick} className={classes.goBackSection}>
          <ChevronDownIcon />
          Back
        </div>

        <Title
          onClick={() => setShowBuildClickCount((prev) => prev + 1)}
          className={classes.title}
        >
          {currentStep?.metadata.title}
        </Title>

        <Box my={30}>{currentStep?.element}</Box>

        {state.error && (
          <Alert
            icon={<RedAlertIcon />}
            className={classes.alert}
            my="lg"
            withCloseButton={false}
          >
            {state.error}
          </Alert>
        )}

        <MultiStepFormSubmitButton
          fullWidth
          variant="light"
          data-testid={currentStep?.metadata.testId}
          form={currentStep?.metadata.id ?? 'no'}
        >
          {currentStep?.metadata.nextButtonText ?? 'Continue'}
        </MultiStepFormSubmitButton>

        {showBuildClickCount > 4 && (
          <Text mt="md" c="primarySecondarySuccess.2" inherit component="span">
            Build ID: {import.meta.env.VITE_APP_GITHUB_SHA || 'LOCAL'}
          </Text>
        )}
      </Box>
    </>
  );
};
