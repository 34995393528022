import { createStyles, rem } from '@mantine/core';

export const detailsCardStyles = createStyles((theme) => ({
  cardDetails: {
    backgroundColor: theme.fn.themeColor('neutral', 2),
    minHeight: '100vh',
    color: theme.fn.themeColor('primarySecondarySuccess', 8),
  },
  contentContainer: {
    padding: `${theme.spacing.xl} ${rem(32)}`,
    [theme.fn.smallerThan('sm')]: {
      overflowX: 'hidden',
      padding: theme.spacing.sm,
    },
  },
  endContent: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  whiteOutlineButton: {
    backgroundColor: theme.fn.themeColor('neutral', 0),
    '&:not([data-disabled])': theme.fn.hover({
      backgroundColor: theme.fn.themeColor('neutral', 0),
    }),
  },
  last4Input: {
    fontSize: rem(48),
    color: theme.fn.themeColor('primarySecondarySuccess', 8),
    textAlign: 'center',
    height: 'fit-content',
    borderBottom: `3px solid ${theme.fn.primaryColor()}`,
    '&:focus': {
      borderBottom: `3px solid ${theme.fn.primaryColor()}`,
    },
    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(48),
    },
  },
  multiselect: {
    span: {
      fontSize: '12px',
    },
  },
}));
