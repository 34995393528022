import { LoadingOverlay, useMantineTheme } from '@mantine/core';
import {
  useGetDomesticWireInstructions,
  useGetInternationalWireInstructions,
} from '@queries/use-wire-instructions';
import { Document } from '@react-pdf/renderer';
import { useHasIntlWires } from '@utilities/custom-hooks/use-has-intl-wires';
import { useParams } from 'react-router-dom';
import WireInstructionDoc from './wire-instruction-doc';
import PDFWireInstructionsViewer from './wire-instructions-pdf';

const getGenericError = (error: boolean) => {
  return error ? 'Unable to load wire instructions for this account.' : '';
};

const WireInstructionsViewer = () => {
  const theme = useMantineTheme();
  const { accountId } = useParams();
  const {
    data: domesticWireData,
    isLoading: isLoadingDomesticWire,
    isError: isDomesticWireError,
  } = useGetDomesticWireInstructions(accountId);

  const hasIntlWires = useHasIntlWires();
  const {
    data: intlWireData,
    isLoading: isLoadingIntlWire,
    isError: isIntlWireError,
  } = useGetInternationalWireInstructions(hasIntlWires, accountId);

  const domesticError = getGenericError(isDomesticWireError);
  const intlError = getGenericError(isIntlWireError);

  const name =
    domesticWireData?.beneficiaryName || intlWireData?.beneficiaryName || '';
  const accountNumber =
    domesticWireData?.beneficiaryAccountNumber ||
    intlWireData?.beneficiaryAccountNumber ||
    '';
  const title = `${name}_Wire-Details-Checking-${accountNumber?.slice(-4)}`;

  const isLoading = isLoadingDomesticWire || isLoadingIntlWire;

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  return (
    <PDFWireInstructionsViewer
      document={
        <Document title={title}>
          <WireInstructionDoc
            theme={theme}
            error={domesticError}
            wireInstructions={domesticWireData}
          />
          {hasIntlWires && (
            <WireInstructionDoc
              theme={theme}
              error={intlError}
              wireInstructions={intlWireData}
            />
          )}
        </Document>
      }
    />
  );
};

export default WireInstructionsViewer;
