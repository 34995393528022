import { Avatar, Badge, Group, Text, useMantineTheme } from '@mantine/core';
import { getInitialsOfNames } from '@utilities/extensions/object';
import { InvoiceTableData } from '@services/flexbase/billing.model';

export const VendorContainer = ({ invoice }: { invoice: InvoiceTableData }) => {
  if (invoice.vendor) {
    return (
      <Group>
        <Avatar radius="xl" w={45} h={45}>
          {getInitialsOfNames(invoice.vendor)}
        </Avatar>
        <Text>{invoice.vendor}</Text>
      </Group>
    );
  }
};

export const BillApprovalBadge = ({
  invoice,
}: {
  invoice: InvoiceTableData;
}) => {
  const theme = useMantineTheme();

  const backgroundColor =
    invoice.status === 'Needs approval'
      ? theme.fn.themeColor('tertiary', 0)
      : theme.fn.themeColor('primarySecondarySuccess', 0);

  const textColor =
    invoice.status === 'Needs approval'
      ? theme.fn.themeColor('tertiary', 6)
      : theme.fn.themeColor('primarySecondarySuccess', 6);

  return (
    <Badge bg={backgroundColor} c={textColor}>
      {invoice.status}
    </Badge>
  );
};

export const BillStatusBadge = ({ invoice }: { invoice: InvoiceTableData }) => {
  const theme = useMantineTheme();

  const invoiceStatus = invoice.invoiceStatus;
  let backgroundColor;
  let textColor;

  switch (invoiceStatus) {
    case 'Overdue':
      backgroundColor = theme.fn.themeColor('critical', 0);
      textColor = theme.fn.themeColor('critical', 6);
      break;
    case 'Due Today':
      backgroundColor = theme.fn.themeColor('tertiary', 0);
      textColor = theme.fn.themeColor('tertiary', 6);
      break;

    case 'Paid':
      backgroundColor = theme.fn.themeColor('primarySecondarySuccess', 0);
      textColor = theme.fn.themeColor('primarySecondarySuccess', 6);
      break;

    default:
      backgroundColor = theme.fn.themeColor('neutral', 1);
      textColor = theme.fn.themeColor('neutral', 7);
  }

  return (
    <Badge bg={backgroundColor} c={textColor}>
      {invoice.invoiceStatus}
    </Badge>
  );
};
