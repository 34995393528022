export const setErrorMessageIssuingCard = (error: string) => {
  let errorMessage = '';
  switch (true) {
    case error.includes('limit reached for Company'):
      errorMessage =
        'Your company has reached the maximum limit for virtual/physical cards.';
      break;

    case error.includes('Terms of Service'):
      errorMessage =
        'To issue the card, the user must have accepted the Terms of Service.';
      break;
    case error.includes('a Customer Token is required'):
      errorMessage = 'Please enter the 6 digit SMS sent to verify your request';
      break;
    case error.includes('Bearer token is invalid or expired'):
      errorMessage = 'Bearer token is invalid or expired.';
      break;

    default:
      errorMessage =
        'Unable to issue card. Please contact support or try again later.';
      break;
  }
  return errorMessage;
};
