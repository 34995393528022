import {
  CriteriaMultiSelectOption,
  MultiSelectFilter,
} from '@common/filter/multi-select-filter';
import { ActiveFiltersReturnType } from '@common/filter/filters';

type Props<T> = {
  label: string;
  options: CriteriaMultiSelectOption[];
  filterKey: string;
  filterHook: ActiveFiltersReturnType<T>;
  includesValue: (item: T) => string;
};

/**
 * For when you just want to pass in some options and match on a string. Example usage
 * in card-type.filter.tsx
 * @param options List of multiselect options and match criteria
 * @param filterKey The key for this filter
 * @param filterHook The filter hook for this filter
 * @param includesValue A function to unwrap an item and get a string value to do `includes` with
 * @param label Label
 * @constructor
 */
export function SimpleMultiselectFilter<T>({
  options,
  filterKey,
  filterHook,
  includesValue,
  label,
}: Props<T>) {
  const { getFilterByKey, addFilter, removeFilter } = filterHook;
  return (
    <MultiSelectFilter
      options={options}
      selectedOptions={getFilterByKey(filterKey)?.filterValue || []}
      onChange={(values) => {
        if (values.length > 0) {
          addFilter(filterKey, {
            label: `${label}: ${values.map((v) => v.label).join(', ')}`,
            filterValue: values,
            key: filterKey,
            fn: (row) =>
              values
                .map((v) => v.filterCriteria)
                .flat()
                .includes(includesValue(row)),
            showChip: true,
          });
        } else {
          removeFilter(filterKey);
        }
      }}
    />
  );
}
