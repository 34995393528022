import { useApplicationFlowContext } from '../onboarding-hooks';
import { LoadingOverlay } from '@mantine/core';
import { useEffect } from 'react';

/**
 * This component routes a user to the first step that they need to provide in the product application that they are applying for
 */
export const ApplicationFlowAutoNavigator = () => {
  const { navigateToNextProductStep } = useApplicationFlowContext();

  useEffect(() => {
    navigateToNextProductStep();
  }, []);

  return <LoadingOverlay visible={true} />;
};
