import {
  useGetCheckDepositHistory,
  useGetCheckDepositImage,
} from '@queries/use-checks-deposits';
import { formatCheckStatus } from '@utilities/formatters/format-checks-status';

export const useGetCheckDepositImages = (id: string) => {
  const {
    data: frontImage,
    isLoading: isLoadingFrontImage,
    isError: isErrorFrontImage,
    refetch: refetchFrontImage,
  } = useGetCheckDepositImage(id, 'front');
  const {
    data: backImage,
    isLoading: isLoadingBackImage,
    isError: isErrorBackImage,
    refetch: refetchBackImage,
  } = useGetCheckDepositImage(id, 'back');

  const checkImages: { side: 'front' | 'back'; url: string }[] = [];

  const createURL = (data: ArrayBuffer) => {
    return URL.createObjectURL(new Blob([data]));
  };

  if (frontImage) {
    checkImages.push({
      side: 'front',
      url: createURL(frontImage),
    });
  }

  if (backImage) {
    checkImages.push({
      side: 'back',
      url: createURL(backImage),
    });
  }

  const refetch = () => {
    refetchFrontImage();
    refetchBackImage();
  };

  return {
    checkImages,
    isLoading: isLoadingFrontImage || isLoadingBackImage,
    isError: isErrorFrontImage || isErrorBackImage,
    refetch,
  };
};

/**
 * hook to track the status of the check.
 * First, the data is mapped and formatted the status using the formatCheckStatus() function.
 * Then, merged repeated statuses into a single value by taking the first status date.
 * The tracker will now only show three values: 'initiated', 'processing', and 'deposited' or a negative status.
 */
export const useCheckDepositTracker = (id: string) => {
  const { data, isLoading, isError, refetch } = useGetCheckDepositHistory(id);
  const checkHistoryData = data?.history ?? [];

  const checkHistoryStatus = checkHistoryData
    .map((val) => ({
      asOf: val.asOf,
      version: val.version,
      status: formatCheckStatus(val.status),
    }))
    .sort((a, b) => a.version - b.version);

  const checkTracker = checkHistoryStatus.reduce(
    (acc: Record<string, string>, current) => {
      const statusKey =
        current.status === 'Awaiting Images'
          ? 'initiated'
          : current.status.toLowerCase();
      if (!acc[statusKey] || acc[statusKey] > current.asOf) {
        acc[statusKey] = current.asOf;
      }
      return acc;
    },
    {},
  );

  return {
    checkTracker,
    isLoading,
    isError,
    refetch,
  };
};
