import { useState } from 'react';
import { Box, Group, Text } from '@mantine/core';
import {
  getYears,
  filterStatementsByYear,
} from 'areas/banking/statements/utils';
import { statementsStyles } from '../styles';
import { StatementInfo } from '@services/flexbase/statements.model';
import ChargeCardsStatementsTable from './charge-cards-statements-table';
import StatementsYearSelection from 'areas/banking/statements/statements-year-selection';

const ChargeCardsStatements = ({
  statements,
}: {
  statements: StatementInfo[];
}) => {
  const [year, setYear] = useState('');
  const { classes } = statementsStyles();
  const listOfYears = getYears(statements);
  const chosenYear = year ? year : listOfYears[0];

  const handleYearChange = (yearChoosen: string) => {
    setYear(yearChoosen);
  };

  const statementsByYear = filterStatementsByYear(
    chosenYear,
    statements,
  ).reverse();

  return (
    <Box className={classes.container}>
      <Group position="apart" mb="xl">
        <Text c="black" size="lg">
          Charge Cards statements
        </Text>
        <StatementsYearSelection
          yearSelected={chosenYear}
          listOfYears={listOfYears}
          onYearChange={handleYearChange}
        />
      </Group>
      <ChargeCardsStatementsTable statements={statementsByYear} />
    </Box>
  );
};

export default ChargeCardsStatements;
