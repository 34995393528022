import { createStyles, TextInput, useMantineTheme } from '@mantine/core';
import { ReactNode, useState } from 'react';
import TableFilter, { FilterProps } from './table-filter';
import { FilterIcon, SearchIcon } from 'assets/svg';

const useStyles = createStyles(() => ({
  tableContainer: {
    background: 'white',
    borderRadius: '8px',
    padding: '24px',
  },

  tableTitleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '24px',
    fontFamily: 'PP Neue Montreal',
  },

  tableTitle: {
    color: '#5F5F5F',
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'PP Neue Montreal',
  },

  searchInput: {
    color: 'black',
    display: 'flex',
    height: '40px',
    alignItems: 'center',
    '@media(max-width: 767px)': {
      marginLeft: '2vh',
    },
  },
}));

export const CustomMantineStyles = (primaryColor?: string) => {
  return {
    search: {
      input: {
        width: '100%',
        height: '40px',
        opacity: 0.8,
        border: '1px solid #E6E7E9',
        borderRadius: '8px',
        fontSize: '14px',
        background: 'white',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '16px',
        color: ' #5F5F5F',
        '&:focus': { borderColor: primaryColor },
      },
    },
  };
};

type Props = {
  children: ReactNode;
  title: string | ReactNode;
  searchPlaceholder?: string;
  filterProps?: Omit<FilterProps, 'isOpen' | 'filterCallback'>;
  searchProps?: {
    value: string;
    onChange: (value: string) => void;
  };
};

// TODO: Refactor and give this and table-filter.tsx some love.
//  Prop complexity should be reduced,
//  Search and filter should work in a much more easy/intuitive way,
//  and should take in classnames to allow for custom styling
export const TableContainerWithSearchFilter = ({
  children,
  title,
  searchPlaceholder,
  filterProps,
  searchProps,
}: Props) => {
  const theme = useMantineTheme();
  const [filterOpen, setFilterOpen] = useState(false);

  const { classes } = useStyles();

  return (
    <div className={classes.tableContainer}>
      <div className={classes.tableTitleRow}>
        <div className={classes.tableTitle}>{title}</div>
        {searchProps && (
          <div className={classes.searchInput}>
            {filterOpen && filterProps ? (
              <TableFilter
                filters={filterProps.filters}
                initial={filterProps.initial}
                isOpen={filterOpen}
                searchCallback={filterProps.searchCallback}
                filterCallback={setFilterOpen}
              />
            ) : (
              <TextInput
                radius={8}
                color="red"
                styles={{
                  input: CustomMantineStyles(theme.fn.primaryColor()).search
                    .input,
                }}
                icon={<SearchIcon width={20} height={20} fill="#5F5F5F" />}
                value={searchProps.value}
                onChange={(e) => searchProps.onChange(e.target.value)}
                placeholder={searchPlaceholder || 'Search'}
                type="search"
                rightSection={
                  filterProps &&
                  screen.width > 767 && (
                    <div
                      onClick={() => setFilterOpen(true)}
                      style={{ cursor: 'pointer' }}
                    >
                      <FilterIcon
                        style={{ paddingRight: '8px', marginTop: 8 }}
                        fill={
                          filterProps.initial.value
                            ? theme.fn.primaryColor()
                            : '#5F5F5F'
                        }
                        width={'25px'}
                        height={'25px'}
                      />
                    </div>
                  )
                }
              />
            )}
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

export default TableContainerWithSearchFilter;
