import { useRecoilValue } from 'recoil';
import { Box, Group } from '@mantine/core';
import { statementsStyles } from './styles';
import { useGetStatements } from '@queries/use-statements';
import SkeletonLoading from '@common/loading/skeleton-loading';
import CreditStatements from './credit-statements/credit-statements';
import { ApplicationState } from 'states/application/product-onboarding';
import ChargeCardsStatements from './charge-cards-statements/charge-cards-statements';

const Statements = () => {
  const { classes } = statementsStyles();

  // this table will only be visible for the companies with charge card accounts.
  const { accountId } = useRecoilValue(ApplicationState);
  const { data, isLoading } = useGetStatements(accountId, 'credit');
  const statementsList = data ?? [];

  if (isLoading) {
    return (
      <Box className={classes.container}>
        <SkeletonLoading />
      </Box>
    );
  }

  return (
    <Group position="apart" grow align="stretch">
      <CreditStatements />
      {statementsList.length && (
        <ChargeCardsStatements statements={statementsList} />
      )}
    </Group>
  );
};

export default Statements;
