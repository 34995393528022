import { MultiSelect } from '@mantine/core';
import { useMemo } from 'react';
import { uniq } from 'underscore';
import { useSpendTransactionModalFilters } from '../use-spend-transactions-filter';
import { TransactionData } from '@services/flexbase/spend-plans.model';

type Props = { transactions: TransactionData[] };

export const SpendNameFilter = ({ transactions }: Props) => {
  const who = useMemo(() => {
    if (transactions) {
      return uniq(transactions.map((t) => t.who).filter((t) => !!t));
    }
    return [];
  }, [transactions]);

  const { addFilter, removeFilter, getFilterByKey } =
    useSpendTransactionModalFilters();

  return (
    <MultiSelect
      data={who}
      placeholder="Name"
      searchable
      nothingFound="Nothing found"
      value={getFilterByKey('name')?.filterValue || []}
      onChange={(val) => {
        if (val.length > 0) {
          addFilter('name', {
            key: 'name',
            filterValue: val,
            fn: (row) => val.includes(row.who),
            label: `Name: ${val.join(', ')}`,
            showChip: true,
          });
        } else {
          removeFilter('name');
        }
      }}
    />
  );
};
