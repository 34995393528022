import { formatCurrency } from '@flexbase-eng/web-components';
import FlexbaseTable from 'components/table/flexbase-table';

import { useStyles } from '../../styles';
import { Text } from '@mantine/core';
import { AvatarLogoCell } from 'components/table';

type AccountRow = {
  name: string;
  availableBalance: string | number;
  logoUrl?: string;
};

type Props = {
  accounts: AccountRow[];
  loading: boolean;
};

const AccountDiversificationTable = ({ accounts, loading }: Props) => {
  const { classes, cx } = useStyles();

  const columns = [
    {
      name: 'Account',
      sortable: true,
      selector: (row: AccountRow) => row.name,
      minWidth: '400px',
      grow: 3,
      cell: (row: AccountRow) => (
        <AvatarLogoCell
          placeholderName={row.name}
          logoUrl={row.logoUrl}
          withLabel={true}
        />
      ),
    },
    {
      name: 'Available balance',
      sortable: true,
      format: (row: AccountRow) => formatCurrency(row.availableBalance),
      selector: (row: AccountRow) => row.availableBalance,
      grow: 1,
    },
  ];

  return (
    <FlexbaseTable
      striped={true}
      columns={columns}
      data={accounts}
      isFetchingData={loading}
      noDataComponent={
        <Text
          className={cx(classes.pp, classes.emptyTableComp)}
          weight={500}
          size={14}
          color="#000000"
        >
          This data is being processed and will be available shortly
        </Text>
      }
    />
  );
};

export default AccountDiversificationTable;
