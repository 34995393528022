import { Box, BoxProps } from '@mantine/core';
import { PropsWithChildren } from 'react';

type IndentProps = PropsWithChildren<
  {
    visible: boolean;
  } & BoxProps
>;

export const Indent = ({ children, visible, ...rest }: IndentProps) => {
  return visible ? (
    <Box pl={28} {...rest}>
      {children}
    </Box>
  ) : null;
};
