import { Alert, Box, Button, Group, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  capitalizeOnlyFirstLetter,
  RequiredFieldValidator,
} from '@flexbase-eng/web-components';
import { useState } from 'react';
import { detailsCardStyles } from './styles';
import { CreditCard } from '@services/flexbase/card.model';
import { useUpdateCreditCardStatusMutation } from '@queries/use-credit-cards';

export type CardStatusUpdateValue = 'active' | 'suspended' | 'terminated';

type Props = {
  card: CreditCard;
  newStatus: CardStatusUpdateValue;
  prettyStatusLabel: string;
  onStatusUpdated: (card: CreditCard) => void;
  onCancel: () => void;
  connectionId: string;
};

export const CardConfirmStatusUpdate = ({
  card,
  newStatus,
  prettyStatusLabel,
  onStatusUpdated,
  onCancel,
}: Props) => {
  const { classes } = detailsCardStyles();
  const form = useForm({
    initialValues: { last4: '' },
    validate: { last4: RequiredFieldValidator() },
  });

  const { mutate, isPending } = useUpdateCreditCardStatusMutation();

  const [error, setError] = useState('');

  const updateCardStatus = async () => {
    setError('');
    const validationResult = form.validate();
    if (!validationResult.hasErrors) {
      mutate(
        { cardId: card.id, status: newStatus, last4: form.values.last4 },
        {
          onSuccess: (response) => {
            onStatusUpdated(response);
          },
          onError: () => {
            setError(
              `An error occurred while trying to ${prettyStatusLabel} card.`,
            );
          },
        },
      );
    }
  };

  return (
    <Box>
      <TextInput
        maxLength={4}
        placeholder="0000"
        variant="unstyled"
        c="primarySecondarySuccess.8"
        classNames={{ input: classes.last4Input }}
        {...form.getInputProps('last4')}
      />
      <Text mt="md">Confirm last 4 digits of card to {prettyStatusLabel}</Text>
      {error && (
        <Alert mt="md" onClose={() => setError('')}>
          {error}
        </Alert>
      )}
      <Group sx={{ justifyContent: 'space-between' }} mt="md">
        <Button
          variant="outline"
          onClick={onCancel}
          disabled={isPending}
          className={classes.whiteOutlineButton}
        >
          Back
        </Button>
        <Button onClick={updateCardStatus} loading={isPending}>
          {capitalizeOnlyFirstLetter(prettyStatusLabel)}
        </Button>
      </Group>
    </Box>
  );
};
