import { createMultiStepFormDynamicContext } from '../../providers/multi-step-form-provider';
import { AuthenticationFactor } from '@flexbase-eng/types/dist/identity';
import { PKCE } from '@services/platform/models/authorize.models';

export type LoginFormState = {
  loading: boolean;
  error: string | null;
  otpStatus: 'sent' | 're-sent';
  email: string;
  password: string;
  factorList: AuthenticationFactor[];
  selectedFactor: AuthenticationFactor | null;
  rememberUser: boolean;
  pkce: PKCE | null;
};

export const [LoginFormProvider, useLoginFormContext] =
  createMultiStepFormDynamicContext<LoginFormState>();
