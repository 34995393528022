import {
  Text,
  Group,
  Stack,
  Avatar,
  Anchor,
  Button,
  Divider,
  Popover,
  CloseButton,
  Box,
} from '@mantine/core';
import { DateTime } from 'luxon';
import { useMediaQuery } from '@mantine/hooks';

import { useStyles } from './styles';
import useModal from 'components/modal/modal-hook';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { useEffect, useReducer, useState } from 'react';
import flexbaseClient, {
  flexbaseBankingClient,
} from 'services/flexbase-client';
import TwoFactorAuth from 'components/auth/two-factor-auth';
import {
  EyeOpenIcon,
  TransparentCenteredLoader,
} from '@flexbase-eng/web-components';
import GetPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import { showNotification } from '@mantine/notifications';

type TransactionDetailsProps = {
  transaction: any;
};

type StateProps = {
  bearerToken: string | null;
  needsTwoFactor: boolean;
  errorMsg: string;
  loading: boolean;
  retry: boolean;
};

const initialState = {
  bearerToken: null,
  needsTwoFactor: false,
  errorMsg: '',
  loading: false,
  retry: false,
};

const actionTypes = {
  SET_BEARER_TOKEN: 'SET_BEARER_TOKEN',
  SET_NEEDS_TWO_FACTOR: 'SET_NEEDS_TWO_FACTOR',
  SET_ERROR_MSG: 'SET_ERROR_MSG',
  SET_LOADING: 'SET_LOADING',
  SET_RETRY: 'SET_RETRY',
};

const reducer = (state: StateProps, action: any) => {
  switch (action.type) {
    case actionTypes.SET_BEARER_TOKEN:
      return { ...state, bearerToken: action.payload };
    case actionTypes.SET_NEEDS_TWO_FACTOR:
      return { ...state, needsTwoFactor: action.payload };
    case actionTypes.SET_ERROR_MSG:
      return { ...state, errorMsg: action.payload };
    case actionTypes.SET_LOADING:
      return { ...state, loading: action.payload };
    case actionTypes.SET_RETRY:
      return { ...state, retry: action.payload };
    default:
      return state;
  }
};

declare const VGSShow: any;

const TransactionDetails = ({ transaction }: TransactionDetailsProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { closeAllModals } = useModal();
  const { classes, theme } = useStyles();
  const [last4Numbers, setLast4Numbers] = useState('');
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [recipientName, setRecipientName] = useState('');
  const useMobileView = useMediaQuery('(max-width: 755px)');
  const createdAt = DateTime.fromSQL(transaction.createdAt);
  const formattedDate = `${createdAt.toFormat('DDD')} at ${createdAt.toFormat(
    't',
  )}`;
  const displayDisputeButton = [
    'ACH received',
    'Debit card purchase',
    'Debit card transaction',
  ].includes(transaction.type);

  const cardId = transaction.cardId || '';
  const paymentId = transaction.transactionDetail.paymentId;

  const getDebitCards = async () => {
    try {
      setLoading(true);
      const result = await flexbaseBankingClient.getDebitCards(false);
      if (result.success && result.cards) {
        const cardData = result.cards.find((card) => {
          if (card.ucCardId.startsWith('ucdc_')) {
            return card.ucCardId.slice(5) === cardId;
          }
        });
        if (cardData?.cardNumber) {
          setLast4Numbers(GetPaddedAccountMask(cardData.cardNumber, 4));
        }
      }
    } catch (error) {
      console.error('Unable to get company debit cards', error);
      showNotification({
        title: 'Error',
        message: 'An error occurred while getting the card number',
        color: 'failure',
      });
    } finally {
      setLoading(false);
    }
  };

  const show = VGSShow.create(import.meta.env.VITE_APP_UNIT_VAULT_ID);
  const customerToken = state.bearerToken;

  const cardNumberIframe = show.request({
    name: 'data-text',
    method: 'GET',
    path: `/cards/${cardId}/secure-data/pan`,
    headers: {
      Authorization: `Bearer ${customerToken}`,
    },
    htmlWrapper: 'text',
    jsonPathSelector: 'data.attributes.pan',
    serializers: [
      show.SERIALIZERS.replace(
        '(\\d{4})(\\d{4})(\\d{4})(\\d{4})',
        '$1 $2 $3 $4',
      ),
    ],
  });

  const getUnitToken = async () => {
    dispatch({ type: actionTypes.SET_RETRY, payload: true });
    dispatch({ type: actionTypes.SET_ERROR_MSG, payload: '' });
    dispatch({ type: actionTypes.SET_BEARER_TOKEN, payload: null });

    try {
      dispatch({ type: actionTypes.SET_LOADING, payload: true });
      dispatch({ type: actionTypes.SET_NEEDS_TWO_FACTOR, payload: true });

      const unitToken = await flexbaseClient.getUnitcoToken();
      if (unitToken.success && unitToken.attributes) {
        setToken(unitToken.attributes.verificationToken); // Set the token separately
      }
    } catch (error) {
      console.error('Error updating customer token', error);
    } finally {
      dispatch({ type: actionTypes.SET_LOADING, payload: false });
    }
  };

  const handleOnChange = async (code: string) => {
    const errorMessage = 'Invalid code. Please try again or re-send the code';
    dispatch({ type: actionTypes.SET_ERROR_MSG, payload: '' });

    try {
      if (code.length === 6) {
        dispatch({ type: actionTypes.SET_LOADING, payload: true });

        const tokenResponse = await flexbaseBankingClient.createVGSCustToken({
          verificationCode: code,
          verificationToken: token, // Use the token from useState
        });

        if (tokenResponse.success) {
          dispatch({
            type: actionTypes.SET_BEARER_TOKEN,
            payload: tokenResponse.accessToken,
          });
          dispatch({ type: actionTypes.SET_NEEDS_TWO_FACTOR, payload: false });
        } else {
          dispatch({ type: actionTypes.SET_ERROR_MSG, payload: errorMessage });
          dispatch({ type: actionTypes.SET_RETRY, payload: true });
        }
      }
    } catch (error) {
      console.error('Error updating customer token', error);
      dispatch({ type: actionTypes.SET_RETRY, payload: true });
      dispatch({ type: actionTypes.SET_ERROR_MSG, payload: errorMessage });
    } finally {
      dispatch({ type: actionTypes.SET_LOADING, payload: false });
    }
  };

  const getPaymentInfo = async (id: string) => {
    try {
      setLoading(true);
      const response = await flexbaseBankingClient.getMoneyMovement(id);
      if (response.success) {
        // the "recipient" of a Money Movement initiated through the API
        // is the Counterparty in the case of a Credit, and is the Company
        // that initiated the Money Movement through the API when the direction is Debit.
        const recipient =
          response.payment.payDirection === 'Credit'
            ? response.payment.payCtrParty
            : transaction.companyName;

        setRecipientName(recipient);
      }
    } catch (error) {
      console.error('Error getting payment info', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (state.bearerToken) {
      cardNumberIframe.render('#cardNumber', {
        color: '#071C14',
        'font-family': '"Inter", sans-serif',
        'font-size': '1.25rem',
      });
    }
  }, [state.bearerToken]);

  useEffect(() => {
    if (cardId) {
      getDebitCards();
    }
  }, [cardId]);

  useEffect(() => {
    if (paymentId) {
      getPaymentInfo(paymentId);
    }
  }, [paymentId]);

  if (loading) return <TransparentCenteredLoader visible={true} />;

  return (
    <div className={classes.transactionDetails}>
      <div className={classes.header}>
        <div style={{ display: 'flex', gap: 14 }}>
          <Avatar mt={10}>{transaction.initials}</Avatar>
          <div id="text-header">
            <Text size={useMobileView ? 20 : 36}>
              {formatCurrency(transaction.amount)}
            </Text>
            <Text size={useMobileView ? 10 : 14}>{formattedDate}</Text>
          </div>
        </div>
        <div>
          <CloseButton
            color="neutral.0"
            variant="transparent"
            onClick={closeAllModals}
            size={useMobileView ? 20 : 30}
          />
        </div>
      </div>

      <Divider mb={24} />

      <div>
        <Group spacing="xl" grow mx={useMobileView ? 20 : 50} align="initial">
          {recipientName && (
            <div>
              <Text size={useMobileView ? 12 : 14}>Recipient</Text>
              <Text size={useMobileView ? 15 : 20}>{recipientName}</Text>
            </div>
          )}
          <div>
            <Text size={useMobileView ? 12 : 14}>Account name</Text>
            <Text size={useMobileView ? 15 : 20}>{transaction.account}</Text>
          </div>
        </Group>
        <Group grow mx={useMobileView ? 20 : 50} my={30} align="initial">
          <div>
            <Text size={useMobileView ? 12 : 14}>Type</Text>
            <Text size={useMobileView ? 15 : 20}>{transaction.type}</Text>
          </div>
          {cardId && (
            <div>
              <Text size={useMobileView ? 12 : 14}>Card Number</Text>
              {state.bearerToken ? (
                <div className={classes.containerCardNumber}>
                  <Box mb="-8rem" id="cardNumber"></Box>
                </div>
              ) : (
                <div>
                  {last4Numbers}{' '}
                  <EyeOpenIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      getUnitToken();
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </Group>

        <Divider my={20} />

        <Stack mx={useMobileView ? 20 : 50} style={{ gap: 2 }}>
          <Text size={useMobileView ? 12 : 14}>Memo</Text>
          <Text size={useMobileView ? 15 : 20}>{transaction.toFrom}</Text>
        </Stack>

        <Divider my={20} />

        <Stack mx={useMobileView ? 20 : 50} style={{ gap: 2 }}>
          <Text size={useMobileView ? 10 : 14}>Transaction ID</Text>
          <Text>#{transaction.id}</Text>
        </Stack>
      </div>
      {displayDisputeButton && (
        <Stack mx={useMobileView ? 20 : 50} my={30} align="flex-start">
          <Popover
            width={useMobileView ? '100%' : 250}
            withArrow
            arrowPosition="side"
            shadow="md"
          >
            <Popover.Target>
              <Button w={useMobileView ? '100%' : 'auto'}>
                Dispute Transaction
              </Button>
            </Popover.Target>
            <Popover.Dropdown>
              {transaction.type === 'ACH Received' ? (
                <Text size={useMobileView ? 12 : 14}>
                  Send the signed copy of the{' '}
                  <Anchor
                    color={theme.fn.primaryColor()}
                    href="https://docs.google.com/document/d/1qoSVbvCPqrASp2jQoehAg7ryHGVLEGDb/edit?pli=1"
                    target="_blank"
                  >
                    ACH debit dispute
                  </Anchor>{' '}
                  to the following email address:{' '}
                  <Anchor
                    color="black"
                    href="mailto:support@flex.one"
                    target="_blank"
                    fw={600}
                  >
                    support@flex.one
                  </Anchor>
                </Text>
              ) : (
                <Text size={useMobileView ? 12 : 14}>
                  If you want to dispute this transaction, please contact the
                  toll-free number <strong>18338521519</strong>
                </Text>
              )}
            </Popover.Dropdown>
          </Popover>
        </Stack>
      )}
      {token && state.needsTwoFactor && (
        <div className={classes.twoFactorContainer}>
          <TwoFactorAuth
            hasRetry={state.retry}
            errorMsg={state.errorMsg}
            onChange={handleOnChange}
            onResendCode={getUnitToken}
            loading={state.loading}
            label="Verification required"
          />
        </div>
      )}
    </div>
  );
};

export default TransactionDetails;
