import {
  AmountFilter,
  AmountFilterValue,
  filterDirectionToLabel,
} from '@common/filter/amount-filter';
import { formatCurrency } from '@flexbase-eng/web-components';
import { useCheckDepositsFilters } from './use-check-deposits-filters';

export const CheckDepositsAmountFilter = () => {
  const { addFilter, getFilterByKey } = useCheckDepositsFilters();

  const handleChange = (val: AmountFilterValue) => {
    addFilter('amount', {
      label: `Amount - ${filterDirectionToLabel(
        val.direction,
      )} ${formatCurrency(val.amount)}`,
      key: 'amount',
      fn: (row) => {
        const filterNum = +val.amount;
        switch (val.direction) {
          case 'greaterThan':
            return row.amount > filterNum;
          case 'lessThan':
            return row.amount < filterNum;
          case 'equal':
          default:
            return row.amount === filterNum;
        }
      },
      filterValue: val,
      showChip: true,
    });
  };

  return (
    <AmountFilter
      value={
        getFilterByKey('amount')?.filterValue || {
          amount: '',
          direction: 'equal',
        }
      }
      onChange={handleChange}
    />
  );
};
