import FlexbaseTable from 'components/table/flexbase-table';

import { useStyles, useStyles as useSharedStyles } from '../../styles';

import { Button, Text } from '@mantine/core';
import { TrophyIcon } from 'assets/svg';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { useNavigate } from 'react-router-dom';
import { AvatarLogoCell } from 'components/table';

type BankRow = {
  name: string;
  flexbasePartner: boolean;
  website: string;
  logoUrl: string;
  emailId?: string;
};

type Props = {
  banks: BankRow[];
  loading: boolean;
};

const BankDiversificationOptionsTable = ({ banks, loading }: Props) => {
  const { classes } = useStyles();
  const { classes: sharedClasses } = useSharedStyles();
  const navigate = useNavigate();

  const referToPartner = async (id: string) => {
    try {
      await flexbaseOnboardingClient.referToPartner(id);
    } catch (e) {
      console.error(e);
    }
  };

  const columns = [
    {
      name: 'Bank Name',
      sortable: true,
      selector: (row: BankRow) => row.name,
      minWidth: '400px',
      grow: 2,
      cell: (row: BankRow) => (
        <AvatarLogoCell
          placeholderName={row.name}
          logoUrl={row.logoUrl}
          withLabel={true}
        />
      ),
    },
    {
      name: 'Reserve Ratio',
      sortable: true,
      cell: () => false,
    },
    {
      name: 'Risk Rating',
      sortable: true,
      cell: () => false,
    },
    {
      name: 'Flex Partner',
      sortable: true,
      selector: (row: BankRow) => row.flexbasePartner,
      cell: (row: BankRow) =>
        row.flexbasePartner && (
          <div className={classes.partnerPill}>
            <TrophyIcon />
            <Text>Flex Partner</Text>
          </div>
        ),
    },
    {
      cell: (row: BankRow) =>
        row.flexbasePartner && row.emailId ? (
          <Button
            variant="outline"
            size="md"
            classNames={{
              root: sharedClasses.buttonRoot,
              label: sharedClasses.buttonLabel,
              inner: sharedClasses.buttonInner,
            }}
            onClick={() => {
              referToPartner(row.emailId!);
            }}
          >
            Get in touch
          </Button>
        ) : (
          <Button
            variant="outline"
            size="md"
            classNames={{
              root: sharedClasses.buttonRoot,
              label: sharedClasses.buttonLabel,
              inner: sharedClasses.buttonInner,
            }}
            onClick={() => {
              navigate(row.website);
            }}
          >
            Learn more
          </Button>
        ),
      center: true,
    },
  ];

  return (
    <FlexbaseTable
      striped={true}
      columns={columns}
      data={banks}
      isFetchingData={loading}
    />
  );
};

export default BankDiversificationOptionsTable;
