import { Route, Routes } from 'react-router';
import { getEnvironment } from 'utilities/url/window-helpers';
import OnboardingStepperPage2 from 'areas/onboarding-v2/pages/onboarding-stepper.page';
import LegalPage from 'areas/legal/legal.page';
import CompanySettings from 'areas/company-settings/company-settings';
import NavbarAppShell from './components/navbar/navbar-app-shell';
import Banking from 'areas/banking/banking';
import { LoadingOverlay } from '@mantine/core';
import { useSetRecoilState } from 'recoil';
import PasswordResetPage from 'areas/login/password-reset.page';
import MagicLinkPage from 'areas/login/magic-link.page';
import Credit from 'areas/credit/credit';
import Overview from 'areas/credit/tabs/overview/overview';
import Cards from 'areas/cards/credit-cards';
import CreditPaymentTab from 'areas/credit/tabs/credit-payment/credit-payment';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import Statements from 'areas/cards/statements';
import OnboardedRoutes from './components/auth/onboarded-routes';
import Notifications from 'areas/company-settings/notifications/notifications';
import ChangePasswordPage from 'areas/login/change-password.page';
import Integrations from 'areas/company-settings/integrations/integrations';
import TeamMembers from 'areas/team-members/team-members';
import ConfirmationReceipt from 'areas/credit/repayment/confirmation-receipt-pdf';
import UserSettingsList from 'areas/company-settings/personal-profile/user-settings-list';
import CompanySettingsList from 'areas/company-settings/company-settings-list';
import PaymentDashboard from 'areas/payments/pages/dashboard';
import OutgoingPayments from 'areas/payments/pages/outgoing-payments';
import Recipients from 'areas/payments/pages/recipients';
import IncomingPayments from 'areas/payments/pages/incoming-payments';
import SendPaymentPage from 'areas/payments/components/send-payment/send-payment.page';
import DocumentManagement from 'areas/document-management/document-management';
import PaymentRequestPDF from 'areas/payments/components/payment-request-pdf/payment-request-pdf';
import {
  ProductApplicationRoutes,
  ProductOnboardingRoutes,
} from 'areas/onboarding-v2/onboarding.constants';
import EndPage from 'areas/onboarding-v2/pages/end.page';
import CashManagement from 'areas/cash-management';
import PDFRiskStatementViewer from 'areas/cash-management/statements/pdf-statement/pdf-statement-viewer';
import TreasuryDashboard from 'areas/cash-management/dashboard/treasury-dashboard';
import TreasuryManagementTabContainer from 'areas/cash-management/treasury-management-tab-container';
import FlexTreasury from 'areas/cash-management/flex-treasury/flex-treasury';
import OpenBankAccount from 'areas/cash-management/open-bank-account/open-bank-account';
import Loadable from './components/loading/loadable';
import MoveTreasuryFunds from 'areas/cash-management/move-funds/move-funds-treasury';
import MoveFundsActivities from 'areas/cash-management/move-funds/move-funds-activities';
import { useEffect, useState } from 'react';
import UserAgreements from 'areas/company-settings/terms-of-service/user-agreements';
import ErrorEndScreen from 'areas/onboarding-v2/pages/end-states/error-end-screen';
import DeclineShortCircuit from 'areas/onboarding-v2/pages/end-states/declined/declined-short-circuit';
import {
  ApplicationState,
  getProductOnboardingStatus,
} from './states/application/product-onboarding';
import DeclinedBadFico from './areas/onboarding-v2/pages/end-states/declined/declined-bad-fico';
import DeclinedSoleProp from './areas/onboarding-v2/pages/end-states/declined/declined-sp';
import PDFPaymentReceiptViewer from 'areas/payments/components/payment-receipt/payment-receipt-pdf';
import { useAuthToken } from './states/auth/auth-token';
import { useRouteSectionContext } from '@common/routes/route-context';
import AddFunds from 'areas/banking/add-funds/add-funds';
import { ReferralRoute } from '@common/routes/referral-route';
import useAnalyticsPageView from 'services/analytics/use-analytics-page-view';
import useGA4 from 'services/ga4/use-ga';
import { analytics } from 'app';
import ProductNavigator from 'components/product-navigator/product-navigator';
import InvoiceFactoringDashboard from 'areas/invoice-factoring/dashboard';
import { isProdEnv } from 'utilities/environments';
import PendingPayments from 'areas/payments/pages/pending-payments';
import SchedulePayments from 'areas/payments/pages/schedule-payments';
import InvalidPromoCodeShortCircuit from 'areas/onboarding-v2/pages/end-states/declined/invalid-referral-code';
import { InternationPaymentApplicationSuccessPage } from './areas/onboarding-v2/pages/international-payment-application-success.page';
import BillingDashboard from 'areas/billing/dashboard/dashboard';
import Billing from 'areas/billing/billing';
import { ApplicationFlowProvider } from './areas/onboarding-v2/onboarding-hooks';
import ProductApplicationErrorBoundary from './areas/onboarding-v2/pages/onboarding-host.page';
import { BankingApplicationConfig } from './states/application/banking-application.config';
import { ApplicationFlowAutoNavigator } from './areas/onboarding-v2/steps/application-flow-auto-navigator';
import { CreditApplicationConfig } from './states/application/credit-application.config';
import { AllProductsApplicationConfig } from './states/application/all-application.config';
import { InternationalPaymentsApplicationConfig } from './states/application/international-payments-application.config';
import Transactions from 'areas/spend-plans/details/tabs/transactions/transactions';
import SpendCards from 'areas/spend-plans/details/tabs/cards/cards';
import { SpendPlanLimits } from 'areas/spend-plans/details/tabs/limits/spend-plan-limits';
import { SpendPlans } from 'areas/spend-plans/spend-plans';
import SpendPlansActive from 'areas/spend-plans/tabs/active';
import SpendPlansInactive from 'areas/spend-plans/tabs/inactive';
import SelfServiceDashboardIntlPayments from 'areas/international-payments/self-service-dashboard-intl-payments';
import { CreateSpendPlan } from 'areas/spend-plans/create/create-spend-plan';
import { useLocationAwareDocumentTitle } from '@utilities/url/use-location-aware-document-title';
import BankAccountDetails from 'areas/banking/bank-account-details';
import BankingStatements from 'areas/banking/statements/statements';
import IntegrationMappings from './areas/company-settings/integrations/mappings/integration-mappings';
import DebitCards from 'areas/banking/debit-cards/debit-cards';
import BankingTransactions from 'areas/banking/banking-transactions/banking-transactions';
import PaymentsTable from 'areas/payments/components/payments-table/payments-table';
import AccountInformation from 'areas/banking/account-details/account-information';
import WireInstructionsViewer from 'areas/banking/components/wire-instructions/wire-instructions-viewer';
import StartScreen from './areas/onboarding-v2/steps/start-screen/start-screen';
import { PrefillPage } from './areas/onboarding-v2/pages/prefill/prefill.page';
import { PrefillConfirmPhoneStep } from './areas/onboarding-v2/steps/prefill/prefill-confirm-phone.step';
import { PrefillVerifyPhoneStep } from './areas/onboarding-v2/steps/prefill/prefill-verify-phone.step';
import { PrefillConfirmBirthdayStep } from './areas/onboarding-v2/steps/prefill/prefill-confirm-birthday.step';
import { PrefillPersonStep } from './areas/onboarding-v2/steps/prefill/prefill-person.step';
import { PrefillBusinessStep } from './areas/onboarding-v2/steps/prefill/prefill-business.step';
import { ContinueWithApplicationStep } from './areas/onboarding-v2/steps/prefill/continue-with-application.step';
import { PlatformPersonProvider } from './providers/platform-person.context';
import { OnboardingStatusProvider } from './providers/onboarding-status-context';
import { PlatformPrefillProvider } from './providers/platform-prefill.context';
import Vendors from 'areas/billing/vendors/vendors';
import { SettingsIntegrationsGuard } from '@utilities/route-guards/settings-integrations-guard';
import { SpendPlansGuard } from '@utilities/route-guards/spend-plans-guard';
import Paid from 'areas/billing/paid/paid';
import SpendPlanDetailsView from './areas/spend-plans/details/spend-plan-details';
import PendingApproval from 'areas/billing/pending-approval/pending-approval';
import CheckDeposits from 'areas/banking/check-deposits/check-deposits';
import Approvals from 'areas/company-settings/approvals/approvals';
import { LoginPage } from './areas/login/login.page';
import { usePolling } from '@utilities/polling/polling';
import { refreshAndStoreTokenFromUser } from '@utilities/auth/refresh-token';
import { AddFactorsProvider } from './providers/add-factors.context';
import ReserveAccountDetails from 'areas/banking/reserve-account/reserve-account-details';
import { AddAuthenticationFactorPage } from './areas/security-center/pages/factor-setup/add-authentication-factor.page';
import RecipientDetails from 'areas/payments/pages/recipient-details/recipient-details';
import RecipientAccountInformation from 'areas/payments/pages/recipient-details/recipient-account-information';
import ReserveAccountInformation from 'areas/banking/reserve-account/account-information';
import Scheduled from 'areas/billing/scheduled/scheduled';
import Unpaid from 'areas/billing/unpaid/unpaid';
import { EditSpendPlan } from 'areas/spend-plans/edit/edit-spend-plan';
import { CallbackPage } from './areas/login/callback.page';
import { InvalidMagicLinkPage } from './areas/login/invalid-magic-link.page';
import { MagicLinkContextProvider } from './areas/login/use-magic-link';
import { LinkLogin } from './areas/login/link-login';
import MoveFunds from 'areas/banking/move-funds/move-funds';
import { platformClient } from '@services/platform/platform-client';
import RecipientInternationalAccounts from 'areas/payments/pages/recipient-details/recipient-international-accounts';
import {
  AccountingIntegrationDetails,
  AdsIntegrationDetails,
  CommerceIntegrationDetails,
} from 'areas/company-settings/integrations/details/integration-details';
import AddRecipient from 'areas/payments/pages/add-recipient/add-recipient';

const OnboardingRequiredRoutes = () => {
  const isProd = isProdEnv();

  return (
    <Route element={<OnboardedRoutes />}>
      <Route
        path="/accounts/risk-statement"
        element={
          <Loadable>
            <PDFRiskStatementViewer />
          </Loadable>
        }
      />
      <Route
        path="/banking/wire-instructions/:accountId"
        element={<WireInstructionsViewer />}
      />
      <Route
        path="/payment/receipt/:paymentId"
        element={<PDFPaymentReceiptViewer />}
      />
      <Route
        path="/payment/card-receipt/:paymentId"
        element={<ConfirmationReceipt />}
      />
      <Route path="/payment-request-pdf" element={<PaymentRequestPDF />} />
      <Route path="/banking/add-funds" element={<AddFunds />} />
      <Route path="/banking/move-funds" element={<MoveFunds />} />
      <Route element={<NavbarAppShell />}>
        <Route path="/" element={<ProductNavigator />} />
        <Route path="/payments" element={<PaymentDashboard />}>
          <Route index element={<Navigate to="outgoing" />} />
          <Route path="outgoing" element={<OutgoingPayments />} />
          <Route path="outgoing/recipient" element={<SendPaymentPage />} />
          <Route path="outgoing/:paymentId" element={<OutgoingPayments />} />
          <Route path="incoming" element={<IncomingPayments />} />
          <Route path="approvals" element={<PendingPayments />} />
          <Route path="approvals/:id" element={<PendingPayments />} />
          <Route path="recipients" element={<Recipients />} />
          <Route path="scheduled" element={<SchedulePayments />} />
          <Route path="scheduled/:id" element={<SchedulePayments />} />
        </Route>
        <Route
          path="recipient-details/:recipientId"
          element={<RecipientDetails />}
        >
          <Route index element={<Navigate to="payments" replace />} />
          <Route path="payments" element={<OutgoingPayments />} />
          <Route path="transactions" element={<BankingTransactions />} />
          <Route
            path="account-information"
            element={<RecipientAccountInformation />}
          />
          <Route
            path="international-accounts"
            element={<RecipientInternationalAccounts />}
          />
        </Route>
        <Route
          path="/spend-plans"
          element={
            <PlatformPersonProvider>
              <SpendPlansGuard />
            </PlatformPersonProvider>
          }
        >
          <Route element={<SpendPlans />}>
            <Route index element={<Navigate to="active" replace />} />
            <Route path="active" element={<SpendPlansActive />} />
            <Route path="inactive" element={<SpendPlansInactive />} />
          </Route>
          <Route path=":id/details" element={<SpendPlanDetailsView />}>
            <Route index element={<Navigate to="limits" replace />} />
            <Route path="limits" element={<SpendPlanLimits />} />
            <Route path="cards" element={<SpendCards />} />
            <Route path="transactions" element={<Transactions />} />
          </Route>
        </Route>
        <Route
          path="/invoice-factoring"
          element={
            isProd ? (
              <Navigate to={'/cards/overview'} />
            ) : (
              <InvoiceFactoringDashboard />
            )
          }
        />
        <Route path="/cards" element={<Credit />}>
          <Route index element={<Navigate to="/cards/overview" />} />
          <Route path="overview" element={<Overview />} />
          <Route path="details" element={<Cards />} />
          <Route path="pay" element={<CreditPaymentTab />} />
          <Route path="statements" element={<Statements />} />
        </Route>
        <Route path="/documents" element={<DocumentManagement />} />
        <Route path="/team" element={<TeamMembers />} />
        <Route path="/accounts" element={<CashManagement />}>
          <Route index element={<Navigate to={'/accounts/dashboard'} />} />
          <Route path="dashboard" element={<TreasuryDashboard />} />
          <Route element={<TreasuryManagementTabContainer />}>
            <Route path="treasury" element={<FlexTreasury />} />
            <Route path="open-account" element={<OpenBankAccount />} />
            <Route path="documents" element={<Statements />} />
            <Route path="move-funds" element={<MoveTreasuryFunds />} />
            <Route path="activities" element={<MoveFundsActivities />} />
          </Route>
          <Route path="*" element={<Navigate to={'/accounts/dashboard'} />} />
        </Route>
        <Route path="/banking">
          <Route index element={<Navigate to={'/banking/accounts'} />} />
          <Route path=":tab" element={<Banking />} />
          <Route
            path="actions-required"
            element={<SelfServiceDashboardIntlPayments />}
          />
          <Route path="account-details/:id" element={<BankAccountDetails />}>
            <Route index element={<Navigate to="transactions" replace />} />
            <Route path="transactions" element={<BankingTransactions />} />
            <Route path="statements" element={<BankingStatements />} />
            <Route path="payments" element={<PaymentsTable />} />
            <Route path="payments/:paymentId" element={<PaymentsTable />} />
            <Route path="payments/recipient" element={<SendPaymentPage />} />
            <Route path="debit-cards" element={<DebitCards />} />
            <Route path="checks" element={<CheckDeposits />} />
            <Route
              path="account-information"
              element={<AccountInformation />}
            />
          </Route>
          <Route path="reserve-account/:id" element={<ReserveAccountDetails />}>
            <Route
              index
              element={<Navigate to="account-information" replace />}
            />
            <Route
              path="account-information"
              element={<ReserveAccountInformation />}
            />
          </Route>
        </Route>
        <Route path="/settings">
          {/* This integrations route is intentionally outside the <CompanySettings /> layout route because we don't want it nested in the tabs */}
          <Route path="integrations" element={<SettingsIntegrationsGuard />}>
            <Route path="mappings" element={<IntegrationMappings />} />
          </Route>
          <Route element={<CompanySettings />}>
            <Route index element={<Navigate to="/settings/profile" />} />
            <Route path="company-profile" element={<CompanySettingsList />} />
            <Route path="profile" element={<UserSettingsList />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="approvals" element={<Approvals />} />
            <Route path="integrations" element={<SettingsIntegrationsGuard />}>
              <Route index element={<Integrations />} />
              <Route
                path="accounting/:connectionId"
                element={<AccountingIntegrationDetails />}
              />
              <Route
                path="ads/:connectionId"
                element={<AdsIntegrationDetails />}
              />
              <Route
                path="ecommerce/:connectionId"
                element={<CommerceIntegrationDetails />}
              />
            </Route>
            <Route path="user-agreements" element={<UserAgreements />} />
          </Route>
        </Route>
        <Route path="/bill-pay" element={<Billing />}>
          <Route index element={<Navigate to={'/bill-pay/dashboard'} />} />
          <Route path="dashboard" element={<BillingDashboard />} />
          <Route path="vendors" element={<Vendors />} />
          <Route path="paid" element={<Paid />} />
          <Route path="pending-approval" element={<PendingApproval />} />
          <Route path="scheduled" element={<Scheduled />} />
          <Route path="unpaid" element={<Unpaid />} />
        </Route>
      </Route>
      {/* These routes intentionally live outside of the app shell */}
      <Route path="recipients/new" element={<AddRecipient />} />
      <Route path="/spend-plans" element={<SpendPlansGuard />}>
        <Route path="new" element={<CreateSpendPlan />} />
        <Route
          path=":id/edit"
          element={
            <PlatformPersonProvider>
              <EditSpendPlan />
            </PlatformPersonProvider>
          }
        />
      </Route>
      <Route
        path="/security/factors"
        element={
          <PlatformPersonProvider>
            <AddFactorsProvider>
              <AddAuthenticationFactorPage />
            </AddFactorsProvider>
          </PlatformPersonProvider>
        }
      />
      <Route path="*" element={<Navigate to={'/'} replace={true} />} />
    </Route>
  );
};

const ApplicationRoutes = () => {
  return (
    <>
      <Route index element={<ApplicationFlowAutoNavigator />} />
      <Route path=":step" element={<OnboardingStepperPage2 />} />
      {/* NOTE: These end states are outside the <EndPage/> because that currently runs a bunch of logic to finish an app*/}
      <Route
        path={`${ProductOnboardingRoutes.END}/declined-short-circuit`}
        element={<DeclineShortCircuit />}
      />
      <Route
        path={`${ProductOnboardingRoutes.END}/declined-fs`}
        element={<DeclinedBadFico />}
      />
      <Route
        path={`${ProductOnboardingRoutes.END}/declined-sp`}
        element={<DeclinedSoleProp />}
      />
      <Route path={`${ProductOnboardingRoutes.END}`} element={<EndPage />} />
      <Route
        path="international-payments-success"
        element={<InternationPaymentApplicationSuccessPage />}
      />
    </>
  );
};

const AuthorizationRequiredRoutes = () => {
  return (
    <Route element={<ProductApplicationErrorBoundary />}>
      <Route
        path="*"
        element={
          <Navigate
            to={ProductApplicationRoutes.COMBINED_CREDIT_BANKING}
            replace={true}
          />
        }
      />
      <Route
        path="/prefill"
        element={
          <PlatformPrefillProvider>
            <OnboardingStatusProvider>
              <AddFactorsProvider>
                <PrefillPage />
              </AddFactorsProvider>
            </OnboardingStatusProvider>
          </PlatformPrefillProvider>
        }
      >
        <Route path="phone" element={<PrefillConfirmPhoneStep />} />
        <Route path="verify-phone" element={<PrefillVerifyPhoneStep />} />
        <Route path="birthday" element={<PrefillConfirmBirthdayStep />} />
        <Route path="identity" element={<PrefillPersonStep />} />
        <Route path="business" element={<PrefillBusinessStep />} />
        <Route path="continue" element={<ContinueWithApplicationStep />} />
      </Route>
      <Route
        path="/onboarding"
        element={
          <Navigate
            to={ProductApplicationRoutes.COMBINED_CREDIT_BANKING}
            replace={true}
          />
        }
      />
      <Route
        path={ProductApplicationRoutes.COMBINED_CREDIT_BANKING}
        element={
          <ApplicationFlowProvider config={AllProductsApplicationConfig} />
        }
      >
        {ApplicationRoutes()}
      </Route>
      <Route
        path={ProductApplicationRoutes.BANKING_ONLY}
        element={<ApplicationFlowProvider config={BankingApplicationConfig} />}
      >
        {ApplicationRoutes()}
      </Route>
      <Route
        path={ProductApplicationRoutes.CREDIT_ONLY}
        element={<ApplicationFlowProvider config={CreditApplicationConfig} />}
      >
        {ApplicationRoutes()}
      </Route>
      <Route
        path={ProductApplicationRoutes.INTL_PAYMENTS_ONLY}
        element={
          <ApplicationFlowProvider
            config={InternationalPaymentsApplicationConfig}
          />
        }
      >
        {ApplicationRoutes()}
      </Route>
    </Route>
  );
};

const NoAuthRoutes = (noAuthUrl: string) => {
  return (
    <>
      <Route path="/register" element={<StartScreen />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/error" element={<ErrorEndScreen />} />
      <Route path="/forgot-password" element={<MagicLinkPage />} />
      <Route path="/change-password" element={<ChangePasswordPage />} />
      <Route path="/" element={<Navigate to={noAuthUrl} replace={true} />} />
      <Route path="*" element={<Navigate to={noAuthUrl} replace={true} />} />
      <Route path="/declined-short-circuit" element={<DeclineShortCircuit />} />
      <Route path="/referral/:code" element={<ReferralRoute />} />
      <Route
        path="/referral/missing"
        element={<InvalidPromoCodeShortCircuit />}
      />
      <Route
        path="/link"
        element={
          <MagicLinkContextProvider>
            <Outlet />
          </MagicLinkContextProvider>
        }
      >
        <Route path="callback" element={<CallbackPage />} />
        <Route path="reset-password" element={<PasswordResetPage />} />
        <Route path="magic-login" element={<PasswordResetPage />} />
        <Route path="login" element={<LinkLogin />} />
        <Route path="invite" element={<LinkLogin />} />
      </Route>
      <Route path="/link/error" element={<InvalidMagicLinkPage />} />
    </>
  );
};

function FlexbaseRoutes() {
  // analytics - DONT REMOVE
  const hostEnv = getEnvironment();
  useAnalyticsPageView(hostEnv);
  useGA4();
  useLocationAwareDocumentTitle();
  const [searchParams] = useSearchParams();
  const segmentAnon = searchParams.get('segment_anon');
  if (segmentAnon) {
    analytics.setAnonymousId(segmentAnon).catch((err) => {
      console.error(`segment anon error ${err}`);
    });
  }
  const [loading, setLoading] = useState(true);
  const setUserAndCompanyInfo = useSetRecoilState(ApplicationState);
  const { logout, clearToken, userIsAuthorized, user } = useAuthToken();
  const { showRoutesFor, setShowRoutesFor } = useRouteSectionContext();

  const { startPolling, hasExistingPoll } = usePolling();

  const loadState = async () => {
    if (userIsAuthorized) {
      try {
        const status = await getProductOnboardingStatus(false);
        setUserAndCompanyInfo(status);
        if (status.completedOnboarding) {
          // The following line is purely to see if MFA is valid. It is under the above if check because if a user has not completed onboarding, the MFA check may always fail depending on where they got in the application
          await platformClient.getMe();
          setShowRoutesFor('main');
        } else {
          setShowRoutesFor('application');
        }
      } catch (e) {
        setShowRoutesFor('auth');
        logout();
      } finally {
        setLoading(false);
      }
    } else {
      clearToken();
      setShowRoutesFor('auth');
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    loadState();
  }, []);

  useEffect(() => {
    if (
      showRoutesFor !== 'auth' &&
      userIsAuthorized &&
      !hasExistingPoll('REFRESH_TOKEN')
    ) {
      startPolling(
        'REFRESH_TOKEN',
        async () => {
          // TODO We should likely do this from use-auth-token hook so that auth state values remain fresh
          const result = await refreshAndStoreTokenFromUser(true);
          if (!result) {
            logout();
          }
        },
        60 * 1000,
      );
    }
  }, [userIsAuthorized, showRoutesFor]);

  return (
    <div>
      <LoadingOverlay visible={loading} style={{ position: 'fixed' }} />
      {!loading && (
        <Routes>
          {showRoutesFor === 'main' && OnboardingRequiredRoutes()}
          {showRoutesFor === 'application' && AuthorizationRequiredRoutes()}
          {showRoutesFor === 'auth' &&
            NoAuthRoutes(
              user.remember || sessionStorage.getItem('logout')
                ? '/login'
                : '/register',
            )}
          <Route path="/legal" element={<LegalPage />} />
        </Routes>
      )}
    </div>
  );
}

export default FlexbaseRoutes;
