import { Image, Text, createStyles } from '@mantine/core';
import WatchBAnking from 'assets/images/watch-banking.png';
import { PropsWithChildren } from 'react';

export const useGenericErrorStyles = createStyles((theme) => ({
  emptyState: {
    height: '60vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',

    '.image-container': {
      display: 'flex',
      marginTop: '50px',
      justifyContent: 'center',
    },

    p: {
      margin: 0,
      fontSize: '14px',
      textAlign: 'center',
      color: theme.fn.themeColor('neutral', 6),
    },
  },
}));

const GenericError = ({ children }: PropsWithChildren) => {
  const { classes } = useGenericErrorStyles();
  return (
    <div className={classes.emptyState}>
      <Image
        width={200}
        src={WatchBAnking}
        alt="Display info error"
        className="image-container"
      />
      <Text ff="redaction" size={36} align="center" mt={30}>
        Sorry about that...
      </Text>
      {children}
    </div>
  );
};

export default GenericError;
