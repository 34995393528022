import getPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';
import { MultiSelect } from '@mantine/core';
import { useSpendTransactionModalFilters } from '../use-spend-transactions-filter';
import { TransactionData } from '@services/flexbase/spend-plans.model';

type Props = { transactions: TransactionData[] };

export const SpendCardsFilter = ({ transactions }: Props) => {
  const { addFilter, getFilterByKey, removeFilter } =
    useSpendTransactionModalFilters();

  const cards = transactions
    ?.filter((c) => !!c.last4)
    .reduce((uniqueCards, c) => {
      if (!uniqueCards.has(c.last4)) {
        const last4 = c.last4.slice(-4);
        uniqueCards.set(c.last4, {
          label: getPaddedAccountMask(last4, 4),
          value: last4,
        });
      }
      return uniqueCards;
    }, new Map())
    .values();

  const uniqueCardsArray = Array.from(cards);

  return (
    <MultiSelect
      data={uniqueCardsArray}
      placeholder="Cards"
      searchable
      nothingFound="Nothing found"
      value={getFilterByKey('cards')?.filterValue || []}
      onChange={(val) => {
        if (val.length > 0) {
          addFilter('cards', {
            key: 'cards',
            filterValue: val,
            fn: (row) => val.includes(row.last4.slice(-4)),
            label: `Cards: ${val
              .map((last4) => getPaddedAccountMask(last4, 4))
              .join(', ')}`,
            showChip: true,
          });
        } else {
          removeFilter('cards');
        }
      }}
    />
  );
};
