import { Checkbox, Group } from '@mantine/core';

export type CriteriaMultiSelectOption = {
  label: string;
  filterCriteria: string | string[];
  key: string;
};

type Props = {
  options: CriteriaMultiSelectOption[];
  selectedOptions: CriteriaMultiSelectOption[];
  onChange: (selected: CriteriaMultiSelectOption[]) => void;
};

/**
 * This filter is intended to be used where the filter criteria for a given option can match one or more
 * string criteria. For example, the credit filter has the option for a transaction status of "settled"
 * which can match "settled" or "succeeded". This filter provides a list of checkboxes that a user can select
 * and a default "All" option that will appear checked when there are no selected options.
 * @param options
 * @param selectedOptions
 * @param onChange
 * @constructor
 */
export const MultiSelectFilter = ({
  options,
  selectedOptions,
  onChange,
}: Props) => {
  return (
    <Group>
      <Checkbox
        checked={selectedOptions.length === 0}
        label="All"
        onChange={() => {
          if (selectedOptions.length > 0) {
            onChange([]);
          }
        }}
      />
      <Checkbox.Group
        value={selectedOptions.map((o) => o.key)}
        onChange={(keys) =>
          onChange(options.filter((o) => keys.includes(o.key)))
        }
      >
        <Group>
          {options.map((o) => (
            <Checkbox value={o.key} label={o.label} key={o.key} />
          ))}
        </Group>
      </Checkbox.Group>
    </Group>
  );
};
