import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  activateDebitCard: {
    margin: '0px',
    padding: '40px',
    minHeight: '100vh',
    borderRadius: theme.defaultRadius,
    backgroundColor: theme.colors.neutral[2],
    '.close': {
      display: 'grid',
      justifyContent: 'end',
      alignContent: 'baseline',
      p: {
        margin: '0px',

        textAlign: 'center',
      },
    },
    '.content': {
      width: '420px',
      display: 'flex',
      marginTop: '20px',
      justifyContent: 'center',
    },
    iframe: {
      width: '100%',
      height: '100%',
    },
    '.input-data': {
      width: '420px',
      '.title': {
        color: '#000',
        fontWeight: 400,
        fontSize: '28px',
        lineHeight: '33px',
      },
      p: {
        fontSize: '14px',
      },

      '.group': {
        width: '350px',
        padding: '20px',
        margin: '20px 0px',
        borderRadius: theme.defaultRadius,
        border: `1px solid ${theme.fn.themeColor('neutral', 2)}`,
        p: {
          margin: '0px',
          fontSize: '12px',
          color: theme.fn.themeColor('critical', 2),
        },
      },

      label: {
        height: '50px',
        display: 'flex',
        lineHeight: '50px',
        position: 'relative',
        flexDirection: 'row',
        borderBottom: `1px solid ${theme.fn.themeColor('neutral', 9)}`,
      },

      'label > span': {
        width: '40%',
        color: '#000',
        fontSize: '14px',
        textAlign: 'right',
        marginRight: '30px',
      },

      '.field': {
        flex: 1,
        cursor: 'text',

        fontWeight: 300,
      },

      '.response-container': {
        paddingTop: '30px',
        maxWidth: '480px',
      },

      '.image-container': {
        display: 'flex',
        margin: '20px 0px',
        justifyContent: 'center',
      },
    },
    '.error': {
      display: 'flex',
      padding: '16px',
      fontSize: '14px',
      color: '#991B1B',
      margin: '30px',
      borderRadius: theme.defaultRadius,
      alignItems: 'center',
      backgroundColor: '#FEF2F2',
      svg: {
        marginRight: '10px',
      },
    },
    '.set-pin': {
      width: '420px',
      '.valiation-message': {
        fontWeight: 400,
        fontSize: '14px',
        textAlign: 'center',
        color: theme.colors.critical[1],
      },
      '.title': {
        color: '#000',
        fontWeight: 400,
        fontSize: '28px',
        lineHeight: '33px',
        fontFamily: 'PP Neue Montreal',
      },
      '.field': {
        color: '#000',
        width: '170px',
        height: '60px',
        fontWeight: 400,
        fontSize: '48px',
        marginTop: '30px',
        borderBottom: `3px solid ${theme.fn.themeColor(
          'primarySecondarySuccess',
          2,
        )}`,
      },
    },
    '.error-step': {
      span: {
        fontWeight: 400,
        fontSize: '28px',
        color: '#000',
      },
      button: {
        display: 'flex',
        fontSize: '12px',

        marginTop: '24px',
        alignItems: 'center',
        svg: {
          marginLeft: '12px',
        },
      },
    },
    '@media(max-width: 767px)': {
      padding: '20px',
      '.content': {
        width: '100%',
        display: 'block',
      },
      '.close-button': {
        width: '100%',
        display: 'flex',
        marginBottom: '15px',
        justifyContent: 'flex-end',
      },
      '.input-data': {
        '.title': {
          fontSize: '24px',
        },
        p: {
          margin: '0px',
        },
      },
      '.set-pin': {
        width: 'auto',
        '.title': {
          fontSize: '24px',
        },
      },
    },
  },

  card: {
    padding: '34px',
    borderRadius: theme.defaultRadius,
    position: 'relative',
    backgroundColor: '#fff',
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
  },
}));
