import { useBankingAccountsFilters } from '../use-banking-filters';
import { SimpleMultiselectFilter } from '@common/filter/simple-multiselect.filter';

const STATUS_MULTISELECT_OPTIONS = [
  {
    label: 'Open',
    filterCriteria: ['Open'],
    key: 'Open',
  },
  {
    label: 'Closed',
    filterCriteria: ['Closed'],
    key: 'Closed',
  },
];

export const BankingAccountsStatusFilter = () => {
  const creditTransactionFilters = useBankingAccountsFilters();

  return (
    <SimpleMultiselectFilter
      filterHook={creditTransactionFilters}
      filterKey="status"
      options={STATUS_MULTISELECT_OPTIONS}
      label="Status"
      includesValue={(row) => row.status}
    />
  );
};
