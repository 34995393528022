import { Box, Flex } from '@mantine/core';

type Props = {
  spentAmount: number;
  assignedAmount?: number;
  remainingAmount: number;
  limit: number;
  color: string;
};

const SpendProgressBar = ({
  spentAmount,
  assignedAmount,
  limit,
  color,
  remainingAmount,
}: Props) => {
  const adjustedSpend = Math.min(spentAmount, limit);
  const adjustedAssigned =
    spentAmount >= limit
      ? 0
      : Math.min(assignedAmount || 0, limit - adjustedSpend);

  const spendPercentage = (adjustedSpend / limit) * 100;
  const assignedPercentage = (adjustedAssigned / limit) * 100;
  const remainingPercentage = (remainingAmount / limit) * 100;

  return (
    <Flex
      h="48px"
      direction="row"
      p={0}
      mb="xl"
      mt="xl"
      sx={() => ({
        border: `1px solid ${color}`,
        borderRadius: '2px',
      })}
    >
      <Box h="100%" w={`${spendPercentage}%`} bg={color} />
      <Box
        h="100%"
        w={`${assignedPercentage}%`}
        sx={{
          backgroundImage: `radial-gradient(circle at 1px 1px, ${color} 1px, transparent 1px)`,
          backgroundSize: '4px 4px',
        }}
      ></Box>
      <Box w={`${remainingPercentage}%`} />
    </Flex>
  );
};

export default SpendProgressBar;
