import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';

type Props = {
  document: JSX.Element;
};

const PDFWireInstructionsViewer = ({ document }: Props) => {
  return (
    <>
      <PDFDownloadLink document={document} />
      <PDFViewer style={{ width: '100%', height: '100vh' }}>
        {document}
      </PDFViewer>
    </>
  );
};

export default PDFWireInstructionsViewer;
