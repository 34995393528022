import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  headerRow1: {
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 35,
    backgroundColor: theme.fn.themeColor('primarySecondarySuccess', 8),
    '> div:last-of-type': {
      marginRight: '-11px',
    },
    flexWrap: 'nowrap',
  },
  title: {
    height: '2.75rem',
    color: theme.fn.themeColor('neutral', 0),
    fontFamily: 'PP Neue Montreal',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '2rem',
    '@media(max-width: 767px)': {
      fontSize: '1.43rem',
    },
  },
  closeButton: {
    display: 'flex',
    width: '2.12rem',
    boxSizing: 'content-box',
    flexDirection: 'column',
    color: '#979797',
    cursor: 'pointer',
    borderRadius: '8px',
    alignItems: 'center',
    backgroundColor: 'unset',
    border: '1px solid transparent',
    '&:focus': {
      outline: 'none',
    },
    svg: {
      width: '2.12rem',
      height: '2.12rem',
    },
    '@media(max-width: 767px)': {
      display: 'flow',
      svg: {
        width: '1.62rem',
        height: '1.62rem',
      },
    },
  },
  select: {
    background: theme.colors.neutral[2],
    borderRadius: theme.radius.md,
  },
  selectContainer: {
    background: theme.colors.contentBackground[2],
    borderRadius: theme.radius.md,
  },
  btnOptions: {
    backgroundColor: theme.colors.contentBackground[2],
    color: 'black',
    ':hover': {
      color: theme.colors.contentBackground[2],
    },
  },
  btnSelected: {
    backgroundColor: theme.colors.primarySecondarySuccess[6],
    color: theme.colors.contentBackground[2],
    ':hover': {
      backgroundColor: theme.colors.contentBackground[2],
    },
  },
  addressInput: { marginTop: theme.spacing.md },
  containerButton: {
    bottom: '1rem',
    width: '100%',
  },
  buttonGroup: {
    justifyContent: 'space-between',
    padding: '1rem',
  },
}));
