// https://github.com/luisanton-io/recoil-nexus

import { RecoilValue, RecoilState, useRecoilCallback } from 'recoil';

type Nexus = {
  get?: <T>(atom: RecoilValue<T>) => T;
  getPromise?: <T>(atom: RecoilValue<T>) => Promise<T>;
  set?: <T>(
    atom: RecoilState<T>,
    valOrUpdater: ((currVal: T) => T) | T,
  ) => void;
  reset?: (atom: RecoilState<any>) => void;
};

const nexus: Nexus = {};

export default function RecoilNexus() {
  nexus.get = useRecoilCallback<[atom: RecoilValue<any>], any>(
    ({ snapshot }) =>
      function <T>(atom: RecoilValue<T>) {
        return snapshot.getLoadable(atom).contents;
      },
    [],
  );

  nexus.getPromise = useRecoilCallback<[atom: RecoilValue<any>], Promise<any>>(
    ({ snapshot }) =>
      function <T>(atom: RecoilValue<T>) {
        return snapshot.getPromise(atom);
      },
    [],
  );

  //nexus.set = useRecoilCallback(({ set }) => set, [])
  useRecoilCallback(({ set }) => {
    nexus.set = set;
    return async () => undefined;
  })();

  nexus.reset = useRecoilCallback(({ reset }) => reset, []);

  return null;
}
