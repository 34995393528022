import { FlexbaseTable } from '@common/table';
import { TableColumn } from 'react-data-table-component';
import { formatCurrency } from '@flexbase-eng/web-components';
import { Avatar } from '@mantine/core';
import { CheckBlackWithoutCircle, CrownIcon, FlexIconShort } from 'assets/svg';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { useStyles } from './styles';
import SpendCardsTableHeader from './spend-cards-header/spend-cards-table-header';
import {
  useGetAdminSpendPlan,
  useGetSpendPlan,
  useGetSpendPlanCards,
} from '@queries/use-spend-plans';
import { useGetMe } from '@queries/use-get-me';
import { useParams } from 'react-router-dom';
import { useSpendCardFilters } from './spend-cards-header/use-spend-cards-filter';
import { useEffect, useMemo } from 'react';
import { SpendPlanCardsResponse } from '@services/flexbase/spend-plans.model';
import { useRecoilValue } from 'recoil';
import { IsAdmin } from 'states/application/product-onboarding';
import { useGetUsers } from '@queries/use-users';
import useModal from '@common/modal/modal-hook';
import { useCreditCards } from '@queries/use-credit-cards';
import { CreditCard } from '@services/flexbase/card.model';
import CardDetails from 'areas/cards/credit-cards/card-details/card-details';

const SpendCards = () => {
  const { classes, theme } = useStyles();
  const isMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const modal = useModal();
  const closeModal = modal.closeAllModals;
  const { id: spendPlanId = '' } = useParams();

  const { data: personInfo } = useGetMe();
  const accountId = personInfo?.accountId || '';
  const {
    data = [],
    isSuccess,
    isLoading,
  } = useGetSpendPlanCards({
    accountId: accountId,
    spendPlanId: spendPlanId,
  });

  const isAdmin = useRecoilValue(IsAdmin);
  const { data: users = [] } = useGetUsers();
  const { activeFiltersArray } = useSpendCardFilters();

  const { data: spendPlan } = useGetSpendPlan({
    accountId: personInfo?.accountId || '',
    spendPlanId: spendPlanId || '',
    isAdmin,
  });

  const { data: adminSpendPlan } = useGetAdminSpendPlan({
    accountId: personInfo?.accountId || '',
    spendPlanId: spendPlanId || '',
    isAdmin,
  });
  const { data: cardsData = [] } = useCreditCards();

  const plan = useMemo(() => {
    if (isAdmin) {
      return adminSpendPlan;
    } else {
      return spendPlan;
    }
  }, [adminSpendPlan, spendPlan]);

  const [clearSelectedRowsFlag, { toggle: clearSelectedRows }] =
    useDisclosure();

  const filteredItems = useMemo<SpendPlanCardsResponse[]>(() => {
    if (!isSuccess || isLoading) {
      return [];
    }

    const usersMap = new Map(users.map((user) => [user.id, user]));

    const combinedData = data.map((item) => ({
      ...item,
      firstName: usersMap.get(item.personId)?.firstName || '',
      lastName: usersMap.get(item.personId)?.lastName || '',
    }));

    return combinedData.filter((t) => activeFiltersArray.every((f) => f.fn(t)));
  }, [data, activeFiltersArray, isLoading, users]);

  const onCloseModal = () => {
    closeModal();
  };

  const openModal = (spendCard: CreditCard) => {
    isMobileView
      ? modal.openFullModal(<CardDetails passedCard={spendCard} />)
      : modal.openRightModalNoOpacity(<CardDetails passedCard={spendCard} />, {
          closeModal: () => {
            onCloseModal();
          },
        });
  };

  const onRowClicked = (card: SpendPlanCardsResponse) => {
    const spendCard = cardsData.find((item) => item.id === card.cardId)!;
    openModal(spendCard);
  };

  useEffect(() => {
    // Table doesn't update the selection if data changes
    // so just clear the selection
    clearSelectedRows();
  }, [filteredItems]);

  const columns: TableColumn<SpendPlanCardsResponse>[] = [
    {
      cell: () => (
        <Avatar radius="xl" mr={15} w={45} h={45}>
          <FlexIconShort />
        </Avatar>
      ),
      hide: 767,
      maxWidth: '0',
    },
    {
      name: 'Cardholder',
      cell: (row) => (
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {row.firstName} {row.lastName}{' '}
            {row.isPlanManager && (
              <CrownIcon style={{ marginLeft: '0.5rem' }} />
            )}{' '}
          </div>
          <div>{row.email}</div>
        </div>
      ),
      minWidth: isMobileView ? '270px' : '',
      grow: 2,
      sortable: true,
      selector: (row) => `${row.firstName} ${row.lastName}`,
    },
    {
      name: 'Card name',
      selector: (row) => row.cardName,
      sortable: true,
    },
    {
      name: 'Assigned (remaining)',
      selector: (row) => row.remainingPtd,
      format: (row) => formatCurrency(row.remainingPtd),
      sortable: true,
    },
    {
      name: 'Spent',
      selector: (row) => row.spent,
      format: (row) => formatCurrency(row.spent),
      sortable: true,
    },
    {
      name: 'Repeat funds',
      cell: (row) => (
        <> {row.repeatFundsAccess && <CheckBlackWithoutCircle />} </>
      ),
      center: true,
      sortable: true,
      omit: plan?.frequency === 'onetime' ? true : false,
      selector: (row) => row.repeatFundsAccess,
    },
    {
      name: 'Future periods',
      omit: plan?.frequency === 'onetime' ? true : false,
      selector: (row) => formatCurrency(row.limit),
      sortable: true,
    },
  ];

  return (
    <div
      className={classes.card}
      style={{ marginTop: theme.spacing.lg }}
      data-testid="account-info"
    >
      <SpendCardsTableHeader data={data} />
      <FlexbaseTable
        striped={false}
        columns={columns}
        data={filteredItems}
        clearSelectedRows={clearSelectedRowsFlag}
        onRowClicked={onRowClicked}
      />
    </div>
  );
};

export default SpendCards;
