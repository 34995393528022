import { Tabs } from '@mantine/core';

type NotificationTab = {
  name: string;
  value: string;
  content: string | JSX.Element;
};

type Props = {
  tabs: NotificationTab[];
};

const NotificationsTab = ({ tabs }: Props) => {
  return (
    <div>
      <Tabs defaultValue="account" style={{ marginTop: '25px' }}>
        <Tabs.List>
          {tabs.map((t, i) => {
            return (
              <Tabs.Tab key={`${i}-${t.value}`} value={t.value}>
                {t.name}
              </Tabs.Tab>
            );
          })}
        </Tabs.List>

        {tabs.map((t, i) => {
          return (
            <Tabs.Panel key={`${i}-${t.value}`} value={t.value}>
              {t.content}
            </Tabs.Panel>
          );
        })}
      </Tabs>
    </div>
  );
};

export default NotificationsTab;
