import TabTableLayout from '@common/layouts/tab-table-layout';
import { Button, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { PlusSignIcon, SearchIcon } from 'assets/svg';
import FlexbaseInput from 'components/input/flexbase-input';
import useModal from 'components/modal/modal-hook';
import { FlexbaseTable } from 'components/table';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { UserInfoState } from 'states/user/user-info';
import RecipientActions from '../components/recipients/recipient-actions';
import ViewRecipientModal from '../components/recipients/view-recipient';
import {
  useAddRecipientFlowFeatureFlag,
  useRecipientDetailsFeatureFlag,
} from '@utilities/feature-flags';
import AddVendor from 'areas/billing/vendors/add-vendor/add-vendor';
import { useGetAllRecipients } from '@queries/use-recipients';
import { Recipient } from 'states/recipient/recipient';

const Recipients = () => {
  const { openRightModal, openModal, openFullModal, closeAllModals } =
    useModal();
  const theme = useMantineTheme();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const [filterText, setFilterText] = useState('');
  const user = useRecoilValue(UserInfoState);
  const navigate = useNavigate();
  const {
    data,
    isLoading: isLoadingCounterparties,
    refetch,
  } = useGetAllRecipients();
  const isFeatureFlagEnabled = useAddRecipientFlowFeatureFlag();

  const recipientDetailsFeatureFlagIsOn = useRecipientDetailsFeatureFlag();
  const recipients = data?.recipients ?? [];
  const filteredRecipients =
    recipients.filter((recipient) => recipient.status === 'active') ?? [];

  const filteredTableData = useMemo(() => {
    const normalizedFilterText = filterText.trim().toLowerCase();

    return filteredRecipients
      .filter((d) => d.name.toLowerCase().includes(normalizedFilterText))
      .sort((a, b) => b.paymentsCount - a.paymentsCount);
  }, [filterText, filteredRecipients]);

  const getLastUsedValue = (row: Recipient) => {
    const isValidCheck = (dateT: string | null) => {
      if (!dateT) return null;

      return DateTime.fromSQL(dateT).isValid ? DateTime.fromSQL(dateT) : null;
    };

    const lastUSed = isValidCheck(row.lastUsedAt);

    return lastUSed;
  };

  const getLastPaidValueSelector = (row: Recipient) => {
    const lastUsedValue = getLastUsedValue(row);
    return lastUsedValue?.toString() ?? '';
  };

  const formatLastPaidValue = (row: Recipient) => {
    const lastUsedValue = getLastUsedValue(row);
    return lastUsedValue?.toFormat('LLL d, yyyy').toString() ?? '-';
  };

  const columns: TableColumn<Recipient>[] = [
    {
      name: 'Name',
      sortable: true,
      sortFunction: (a, b) => a?.name.localeCompare(b?.name),
      selector: (row) => row.name,
      grow: 2,
    },
    {
      name: 'Last paid',
      selector: getLastPaidValueSelector,
      format: formatLastPaidValue,
      sortable: true,
      style: {
        fontSize: useMobileView ? '12px' : '14px',
      },
      grow: 2,
    },
    {
      name: 'Actions',
      cell: (row) => <RecipientActions refetch={refetch} recipient={row} />,
      compact: true,
    },
  ];

  const handleAddRecipientModal = () => {
    if (isFeatureFlagEnabled) {
      navigate('/recipients/new');
      return;
    }

    if (useMobileView) {
      openFullModal(<AddVendor closeModal={closeAllModals} />);
    } else {
      openRightModal(
        <AddVendor closeModal={closeAllModals} />,
        theme.fn.themeColor('neutral', 2),
      );
    }
  };

  const handleRowClicked = (row: Recipient) => {
    if (recipientDetailsFeatureFlagIsOn) {
      const recipientId = row.id;
      navigate(`/recipient-details/${recipientId}`);
    } else {
      openModal(row.name, <ViewRecipientModal recipient={row} />);
    }
  };

  return (
    <TabTableLayout
      leftHeaderContent={
        <FlexbaseInput
          w={useMobileView ? '100%' : '300px'}
          placeholder="Search recipients"
          onChange={(e) => setFilterText(e.target.value)}
          icon={<SearchIcon width={20} height={20} />}
        />
      }
      rightHeaderContent={
        user.roles.includes('ADMIN') && (
          <Button
            onClick={handleAddRecipientModal}
            data-testid={'add-recipient'}
            variant="light"
          >
            <PlusSignIcon width={12} height={12} style={{ marginRight: 5 }} />
            Add Recipient
          </Button>
        )
      }
      table={
        <FlexbaseTable
          columns={columns}
          data={filteredTableData}
          pagination={filteredTableData && filteredTableData.length > 8}
          onRowClicked={handleRowClicked}
          isFetchingData={isLoadingCounterparties}
        />
      }
    />
  );
};

export default Recipients;
