import { createStyles, rem } from '@mantine/core';

export const useSettingsStyles = createStyles<
  string,
  {
    controlDirection: 'row' | 'column';
    disabled?: boolean;
  }
>((theme, { controlDirection, disabled }) => ({
  flexContainer: {
    flexDirection: controlDirection,
    gap: rem(controlDirection === 'column' ? 10 : theme.spacing.xl),
  },
  settingsContent: {
    gap: theme.spacing.xxs,
    justifyContent: 'center',
    flexGrow: 1,
    ...(disabled ? { opacity: 0.5 } : {}),
  },
  settingsControl: {
    flexGrow: 0,
  },
  settingsLabel: {
    lineHeight: '1.5',
    color: theme.fn.themeColor('primarySecondarySuccess', 8),
  },
  settingsDescription: {
    fontSize: rem(14),
    lineHeight: 1.4,
    color: theme.fn.themeColor('neutral', 6),
  },
}));
