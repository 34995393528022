import useModal from './modal-hook';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { UserInfoState } from 'states/user/user-info';
import { UserCompanyState } from 'states/company/company';
import PhoneForm from 'components/forms/phone-form';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { showNotification } from '@mantine/notifications';
import { LoadingState } from 'areas/onboarding/onboarding-form.state';
import NameForm from 'components/forms/name-form';
import TitleForm from 'components/forms/title-form';
import AddressForm from 'components/forms/address-form';
import IAddress from 'states/user/address';
import WebsiteForm from 'components/forms/website-form';

const useEditModals = () => {
  const modals = useModal();
  const [user, setUser] = useRecoilState(UserInfoState);
  const [company, setCompany] = useRecoilState(UserCompanyState);
  const setLoading = useSetRecoilState(LoadingState);

  const tryNetworkCall = async (
    potentiallyBad: () => Promise<void>,
    successMessage: string,
    errorMessage: string,
  ) => {
    setLoading(true);
    try {
      await potentiallyBad();
      modals.closeAllModals();
      showNotification({
        title: 'Success!',
        message: successMessage,
        color: 'flexbase-teal',
      });
    } catch (error) {
      showNotification({
        color: 'red',
        title: 'Error',
        message: errorMessage,
      });
    } finally {
      setLoading(false);
    }
  };

  const openEditUserPhoneModal = () => {
    const onSubmit = (phone: string) =>
      tryNetworkCall(
        async () => {
          await flexbaseOnboardingClient.updateUser({
            id: user.id,
            cellPhone: phone,
          });
          setUser((prev) => ({ ...prev, cellPhone: phone }));
        },
        'Phone number updated',
        'Phone number could not be updated',
      );

    modals.openModal(
      'Edit phone number',
      <PhoneForm
        onClose={modals.closeAllModals}
        onSubmit={onSubmit}
        currentValue={user.cellPhone}
      />,
    );
  };

  const openEditNameModal = () => {
    const onSubmit = (firstName: string, lastName: string) => {
      tryNetworkCall(
        async () => {
          await flexbaseOnboardingClient.updateUser({
            id: user.id,
            firstName,
            lastName,
          });
          setUser((prev) => ({ ...prev, firstName, lastName }));
        },
        'Name was updated',
        'Name could not be updated',
      );
    };

    modals.openModal(
      'Edit name',
      <NameForm
        onClose={modals.closeAllModals}
        onSubmit={onSubmit}
        currentFirstName={user.firstName}
        currentLastName={user.lastName}
      />,
    );
  };

  const openEditTitleModal = () => {
    const onSubmit = (jobTitle: string) => {
      tryNetworkCall(
        async () => {
          await flexbaseOnboardingClient.updateUser({ id: user.id, jobTitle });
          setUser((prev) => ({ ...prev, jobTitle }));
        },
        'Title successfully updated',
        'Title could not be updated',
      );
    };
    modals.openModal(
      'Edit job title',
      <TitleForm
        onClose={modals.closeAllModals}
        onSubmit={onSubmit}
        currentTitle={user.jobTitle}
      />,
    );
  };

  const openEditUserAddressModal = () => {
    const onSubmit = (address: IAddress) => {
      tryNetworkCall(
        async () => {
          await flexbaseOnboardingClient.updateUser({ id: user.id, address });
          setUser((prev) => ({ ...prev, address: { ...address } }));
        },
        'Address successfully updated',
        'Address could not be updated',
      );
    };
    modals.openModal(
      'Edit address',
      <AddressForm
        onClose={modals.closeAllModals}
        onSubmit={onSubmit}
        currentAddress={user.address}
      />,
    );
  };

  const openEditCompanyAddressModal = () => {
    const currentCompanyAddress: IAddress = {
      line1: company.address,
      line2: company.addressLine2 || '',
      city: company.city,
      state: company.state,
      postalCode: company.postalCode,
      country: company.country,
    };

    const onSubmit = (address: IAddress) => {
      tryNetworkCall(
        async () => {
          await flexbaseOnboardingClient.updateCompany({
            id: company.id,
            address,
          });
          setCompany((prev) => ({
            ...prev,
            address: address.line1,
            addressLine2: address.line2 || '',
            city: address.city,
            state: address.state,
            postalCode: address.postalCode,
            country: address.country,
          }));
        },
        'Company address successfully updated',
        'Company address could not be updated',
      );
    };
    modals.openModal(
      'Edit company address',
      <AddressForm
        onClose={modals.closeAllModals}
        onSubmit={onSubmit}
        currentAddress={currentCompanyAddress}
      />,
    );
  };

  const openEditCompanyWebsiteModal = () => {
    const onSubmit = (website: string) => {
      tryNetworkCall(
        async () => {
          await flexbaseOnboardingClient.updateCompany({
            id: company.id,
            website,
          });
          setCompany((prev) => ({ ...prev, websiteUrl: website }));
        },
        'Company website successfully updated',
        'Company website could not be updated',
      );
    };

    modals.openModal(
      'Edit company website',
      <WebsiteForm
        onClose={modals.closeAllModals}
        onSubmit={onSubmit}
        currentWebsite={company.websiteUrl}
      />,
    );
  };

  return {
    openEditUserPhoneModal,
    openEditNameModal,
    openEditTitleModal,
    openEditUserAddressModal,
    openEditCompanyAddressModal,
    openEditCompanyWebsiteModal,
  };
};

export default useEditModals;
