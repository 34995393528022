export const roleDescriptions = {
  adminLimited:
    'All admin functionality except issuing debit cards and transferring funds within Flex Banking. Limited access Admins can submit requests to send payments from Flex Banking.',
  adminFull:
    'Full account access; open bank accounts, invite and manage users, manage debit and Credit cards, make payments on credit card. Can transfer funds, send domestic ACH/wires, and approve requests to transfer funds and send domestic ACH/wires, update all user/role settings, manage integrations.',
  employee:
    'Can view transactions on credit cards issued to them, can freeze or unfreeze their cards, dispute transactions, upload receipts and add memos.',
  bookKeeper:
    'Can view all account transactions, download statements, view memos, download receipts. Cannot make any transfers or transactions.',
};
