import { Image, Text, View } from '@react-pdf/renderer';
import { stylesStatementPDF } from './styles';
import Divider from 'assets/images/divider.png';
import { EmailSvg, SupportSvg } from 'components/pdf-viewer/icons';

type StatementHeaderProps = {
  totalCapital: string;
  uninsuredCapitalValue: string;
  uninsuredCapitalPercent: string;
};

const StatementHeader = ({
  totalCapital,
  uninsuredCapitalValue,
  uninsuredCapitalPercent,
}: StatementHeaderProps) => {
  return (
    <View style={stylesStatementPDF.cardStatementContainer}>
      <View>
        <Text style={stylesStatementPDF.text}>Available Capital</Text>
        <Text
          style={{
            ...stylesStatementPDF.text,
            fontSize: '12px',
            color: '#010202',
            fontStyle: 'thin',
          }}
        >
          {totalCapital}
        </Text>
      </View>
      <View style={{ marginLeft: 15 }}>
        <Text style={stylesStatementPDF.text}>Uninsured Capital</Text>
        <Text
          style={{
            ...stylesStatementPDF.text,
            fontSize: '12px',
            color: '#010202',
            fontStyle: 'thin',
          }}
        >
          {uninsuredCapitalValue} / {uninsuredCapitalPercent}
        </Text>
      </View>
      <View style={{ marginLeft: 0 }}>
        <Image src={Divider} style={{ width: 1, height: 30 }} />
      </View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <SupportSvg />
        <Text
          style={{
            ...stylesStatementPDF.text,
            marginLeft: 5,
            fontSize: '9px',
          }}
        >
          Live chat at www.flex.one
        </Text>
      </View>

      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <EmailSvg />
        <Text
          style={{
            ...stylesStatementPDF.text,
            marginLeft: 5,
            fontSize: '9px',
          }}
        >
          Email support@flex.one
        </Text>
      </View>
    </View>
  );
};

export default StatementHeader;
