import { useQuery } from '@tanstack/react-query';
import { flexbaseBankingClient } from 'services/flexbase-client';
import { TransactionParams } from '@services/flexbase/banking.model';

const QUERY_KEY = 'bankingTransactions';

export const useGetBankingTransactions = (params: TransactionParams = {}) => {
  return useQuery({
    queryKey: [QUERY_KEY, params],
    queryFn: async () => {
      return await flexbaseBankingClient.getTransactions(params);
    },
    meta: {
      errorMessage: 'Unable to retrieve banking accounts at this time.',
    },
  });
};
