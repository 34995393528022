import { Alert, AlertProps, Button, Group, Text } from '@mantine/core';

export type NotificationAlertProps = AlertProps & {
  action?: {
    label: string;
    onClick: (...args: unknown[]) => void;
  };
};

export const NotificationAlert = ({
  children,
  action,
  ...alertProps
}: NotificationAlertProps) => {
  return (
    <Alert {...alertProps}>
      <Group noWrap>
        <Text>{children}</Text>

        {action ? (
          <Button onClick={action.onClick}>{action.label}</Button>
        ) : null}
      </Group>
    </Alert>
  );
};
