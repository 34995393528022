import { Avatar } from '@mantine/core';
import { FlexIconShort } from 'assets/svg';
import { getInitialsOfNames } from 'utilities/extensions/object';

type Props = {
  externalName?: string | null;
};

const AccountIcon = ({ externalName, ...props }: Props) =>
  externalName ? (
    <Avatar
      {...props}
      style={{
        borderColor: 'transparent',
        backgroundColor: 'transparent',
      }}
    >
      {getInitialsOfNames(externalName)!.toUpperCase()}
    </Avatar>
  ) : (
    <FlexIconShort />
  );

export default AccountIcon;
