import AccountSelection from 'areas/payments/components/account-selection';
import type {
  AccountProps,
  DepositAccount,
} from '../move-funds/move-funds.model';
import { useStyles } from '../styles';

type Props = {
  onChange: (account: DepositAccount) => void;
  selectedAccount: DepositAccount;
  accounts: DepositAccount[] | undefined;
};

const StatementsAccountSelection = ({
  onChange,
  selectedAccount,
  accounts,
}: Props) => {
  const { classes } = useStyles();

  const handleAccountChange = (acct: AccountProps) => {
    const foundAccount = accounts?.find((a) => a.id === acct.id);
    onChange(foundAccount as DepositAccount);
  };

  return (
    <AccountSelection
      currentAccount={selectedAccount}
      accounts={accounts}
      onAccountChange={handleAccountChange}
      classNames={{
        target: classes.accountSelectTarget,
        list: classes.accountSelectList,
      }}
    />
  );
};

export default StatementsAccountSelection;
