import { Box, Button, Text } from '@mantine/core';
import { ArrowRight } from '../../../assets/svg';
import { useSalesStyles } from '@common/sales/styles';
import { ReactNode } from 'react';

type Props = {
  title: ReactNode;
  subtitle: ReactNode;
  onApplyAgainClick: () => void;
  containerClassname?: string;
};

export const CreditApplyAgainCta = ({
  title,
  subtitle,
  onApplyAgainClick,
  containerClassname,
}: Props) => {
  const { classes, cx } = useSalesStyles();

  return (
    <Box className={cx(classes.callToActionContainer, containerClassname)}>
      <Box c="neutral.0">
        <Text fz={20}>{title}</Text>
        <Text fz={14}>{subtitle}</Text>
      </Box>
      <Button
        onClick={onApplyAgainClick}
        variant="outline"
        rightIcon={<ArrowRight />}
        c="primarySecondarySuccess.8"
        sx={(theme) => ({
          backgroundColor: theme.fn.themeColor('neutral', 0),
          '&:not([data-disabled])': theme.fn.hover({
            backgroundColor: theme.fn.themeColor('neutral', 0),
          }),
        })}
      >
        Apply Again
      </Button>
    </Box>
  );
};
