import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  container: {
    background: '#ffffff',
    borderRadius: theme.defaultRadius,
    padding: 24,
    marginTop: '1rem',
  },
  containerAlert: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  containerWarnMessage: {
    display: 'flex',
    alignItems: 'center',
  },
  btnReconnect: {
    borderColor: theme.colors.tertiary[4],
    color: 'black',
    fontWeight: 500,
    borderRadius: 4,
  },
  titleText: {
    fontFamily: 'PP Neue Montreal',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '19px',
    color: '#5f5f5f',
  },
  tableContainer: {
    marginTop: 24,
  },
  tableCell: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentText: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '21px',
    color: '#5f5f5f',
    '@media (max-width: 767px)': {
      fontSize: 12,
    },
  },
  smallContentText: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '21px',
    color: '#5f5f5f',
  },
  menuEllipsisContainer: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    height: 'fit-content',
  },
}));
