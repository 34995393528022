import {
  AdsLink,
  CommerceLink,
  ExpenseLink,
} from '@flexbase-eng/types/dist/accounting';

export type IntegrationLink = ExpenseLink | AdsLink | CommerceLink;

export type AccountingPlatforms = 'NETSUITE' | 'QUICKBOOKS' | 'XERO';

export type FeedsPlatforms = 'INTUIT_BANK_FEEDS';

export type AdsPlatforms = 'FACEBOOK';

export type CommercePlatforms = 'AMAZON' | 'SHOPIFY';

export type IntegrationPlatform =
  | AccountingPlatforms
  | FeedsPlatforms
  | AdsPlatforms
  | CommercePlatforms;

export const INTEGRATION_PLATFORM: Record<IntegrationPlatform, string> = {
  NETSUITE: 'NETSUITE',
  QUICKBOOKS: 'QUICKBOOKS',
  XERO: 'XERO',
  INTUIT_BANK_FEEDS: 'INTUIT_BANK_FEEDS',
  FACEBOOK: 'FACEBOOK',
  AMAZON: 'AMAZON',
  SHOPIFY: 'SHOPIFY',
} satisfies Record<IntegrationPlatform, IntegrationPlatform>;

export type IntegrationCategory =
  | 'accounting'
  | 'commerce'
  | 'ads'
  | 'payments';
