import { Box } from '@mantine/core';
import { PayablesTable } from '@mercoa/react';
import { useOutletContext } from 'react-router-dom';
import { useBillPayStyles } from '../billing.styles';
import { MercoaContext } from 'states/mercoa/mercoa';
import { InvoiceResponse } from '@mercoa/javascript/api';
import BillPayTable from '../components/bill-table/bill-pay-table';
import { useBillingPendingApprovalFilters } from './use-pending-approval';

const PendingApproval = () => {
  const { classes } = useBillPayStyles();
  const context = useOutletContext<MercoaContext>();

  const customTable = (invoices: InvoiceResponse[], dataLoaded: boolean) => {
    return (
      <BillPayTable
        withActions={false}
        invoices={invoices}
        dataLoaded={dataLoaded}
        mercoaToken={context.mercoaToken}
        useFilters={useBillingPendingApprovalFilters()}
        headingText="No bills require action at this time"
      />
    );
  };

  return (
    <Box className={classes.card} mb="lg">
      <PayablesTable statuses={['NEW', 'FAILED']}>
        {({ invoices, dataLoaded }) => customTable(invoices, dataLoaded)}
      </PayablesTable>
    </Box>
  );
};

export default PendingApproval;
