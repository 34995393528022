import { StatusCard } from './status-card';
import { ActionItemList } from './action-items/action-item-list';
import { OnboardingOverviewResponse } from '../../states/onboarding/onboarding-info';
import { Stack } from '@mantine/core';
import { _ApplicationState } from '../../states/application/product-onboarding.models';
import { ActionItemNames } from './calculate-action-items';

type Props = {
  overview: OnboardingOverviewResponse;
  status: _ApplicationState;
  actionItems: ActionItemNames[];
};

export const SelfServiceDashboard = ({
  overview,
  status,
  actionItems,
}: Props) => {
  return (
    <Stack spacing="xl" align="center">
      <StatusCard totalActionItems={actionItems.length} />
      <ActionItemList
        overview={overview}
        status={status}
        actionItems={actionItems}
      />
    </Stack>
  );
};
