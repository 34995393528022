import { Box, Flex, Progress, Text, useMantineTheme } from '@mantine/core';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { useStyles } from './credit-limit.styles';
import CardRepaymentModal from '../../repayment/card-repayment-modal';
import { useRecoilValue } from 'recoil';
import {
  CompanySelector,
  IsAccountant,
} from '../../../../states/application/product-onboarding';
import { useCreditBalance } from 'queries/use-credit-balance';
import useModal from 'components/modal/modal-hook';
import { CompanyCreditBalance } from 'states/company/company-credit-balance';
import { useGetChargeCardAccounts } from '@queries/use-charge-card-accounts';
import { ChargeCardAccount } from '@services/flexbase/banking.model';

type Props = {
  hasPendingPayment: boolean;
  onClick?: () => void;
};

/**
 *
 * @param hasDelinquentAmount
 * @param hasPendingPayment
 * @param onClick Will only be called if the user does not have a delinquent amount.
 * @constructor
 */
const CreditLimit = ({ hasPendingPayment, onClick }: Props) => {
  const theme = useMantineTheme();
  const { id, frozenReason } = useRecoilValue(CompanySelector);
  const allowedReasons = [
    'plaidDropped',
    'accountDelinquent',
    'deniedBanking',
    'byServicer',
    'unknown',
  ];

  const hasDelinquentAmount =
    frozenReason && allowedReasons.includes(frozenReason);

  const { classes } = useStyles({ hasDelinquentAmount, hasOnClick: !!onClick });
  const { openTransparentModal, closeAllModals } = useModal();
  const isAccountant = useRecoilValue(IsAccountant);

  const openPaymentModal = () => {
    openTransparentModal(<CardRepaymentModal closeModal={closeAllModals} />);
  };

  const onCardClick = () => {
    if (onClick && !frozenReason) {
      onClick();
    }
  };

  const { data } = useCreditBalance(id);
  const { data: chargeAccountsData } = useGetChargeCardAccounts();
  const chargeAccountsOpened =
    chargeAccountsData?.accounts.filter((acc) => acc.status === 'Open') ?? [];

  const getCompanyCredit = (
    chargeAccounts: ChargeCardAccount[],
    creditBalanceData?: CompanyCreditBalance,
  ) => {
    if (!creditBalanceData) {
      return { utilization: 0, available: 0, total: 0, spent: 0, hold: 0 };
    }

    // charge accounts numbers
    // the current maximum amount the customer can be further loaned
    const chargeAccountsAvailable =
      chargeAccounts.reduce((acc, curr) => acc + Number(curr.available), 0) /
      100;
    // the amount of authorizations pending
    const chargeAccountsHold =
      chargeAccounts.reduce((acc, curr) => acc + Number(curr.hold), 0) / 100;
    // the amount the customer currently owes
    const chargeAccountsBalance =
      chargeAccounts.reduce((acc, curr) => acc + Number(curr.balance), 0) / 100;
    const totalLineOfCredit =
      chargeAccountsBalance + chargeAccountsHold + chargeAccountsAvailable;

    // Lithic balances + Unit balances
    const total = creditBalanceData.creditLimit + totalLineOfCredit;
    const available =
      creditBalanceData.availableLimit + chargeAccountsAvailable;
    // 'currentBalance' includes pending and settled invoices/transactions
    const lithicBalance =
      creditBalanceData.currentBalance - creditBalanceData.pendingInvoices;
    const spent = lithicBalance + chargeAccountsBalance;
    const hold = creditBalanceData.pendingInvoices + chargeAccountsHold;

    const utilization =
      total > 0 ? Math.ceil((1 - available / total) * 100) : 100;

    return {
      utilization,
      available,
      total,
      spent,
      hold,
    };
  };

  const companyCredit = getCompanyCredit(chargeAccountsOpened, data);

  return (
    <div className={classes.creditContainer} onClick={onCardClick}>
      {frozenReason &&
      frozenReason !== 'accountDelinquent' &&
      frozenReason !== 'plaidUnlinked' ? (
        <div className={classes.frozen}>
          <div className={classes.unfreezePlaidBtn}>Frozen</div>
        </div>
      ) : null}
      {frozenReason === 'accountDelinquent' && (
        <div className={classes.frozen}>
          <div className={classes.unfreezeButton}>
            Frozen -{' '}
            <button
              type="button"
              className={classes.payNow}
              disabled={isAccountant}
              onClick={() => openPaymentModal()}
            >
              {hasPendingPayment ? 'Payment is pending' : 'Pay now to unfreeze'}
            </button>
          </div>
        </div>
      )}
      <div className={classes.totalBalanceContainer}>
        <div>
          <Text className={classes.availableCreditLabel}>Available credit</Text>
          <div className={classes.availableCreditAmt}>
            {formatCurrency(companyCredit.available)}
          </div>
        </div>
        <div>
          <Progress
            size="md"
            value={companyCredit.utilization}
            color={theme.fn.primaryColor()}
          />
          <Flex align="center" justify="space-between" mt="md">
            <Box>
              <Text className={classes.statusBarText}>
                {formatCurrency(companyCredit.spent.toString())} spent
              </Text>
              <Text size="xs" c="neutral.5">
                {formatCurrency(companyCredit.hold)} on hold
              </Text>
            </Box>
            <Text className={classes.statusBarText}>
              of {formatCurrency(companyCredit.total.toString())} limit
            </Text>
          </Flex>
        </div>
      </div>
    </div>
  );
};

export default CreditLimit;
