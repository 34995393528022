import { useEffect, useMemo, useState } from 'react';
import { Avatar, Badge, Grid, Group, Tooltip, Text } from '@mantine/core';
import { useClipboard, useMediaQuery } from '@mantine/hooks';
import MakePaymentWidget from 'areas/cash-management/components/make-payment.widget';
import { DownloadIcon, FlexIconShort } from 'assets/svg';
import SkeletonLoading from 'components/loading/skeleton-loading';
import FlexbaseTable from 'components/table/flexbase-table';
import { useGetDepositAccounts } from 'queries/use-deposit-accounts';
import { TableColumn } from 'react-data-table-component';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
import { useRecoilValue } from 'recoil';
import { formatCurrency } from 'utilities/formatters/format-currency';
import ProgressPercent from '../components/progress-percent/progress-percent';
import { useStyles } from '../styles';
import GenericError from '../utils/generic-error';
import RecentTransactionsWidget from './recent-transactions-widget';
import { downloadWireInstructions } from './utilities';
import { useBankingAccountsFilters } from './use-banking-filters';
import BankingAccountsHeader from './banking-accounts-header';
import ControlPersonMenu from 'areas/credit/tabs/overview/control-person-menu';
import { modals } from '@mantine/modals';
import { useGetUsers } from '@queries/use-users';
import { ApplicationState } from 'states/application/product-onboarding';
import IntlWiresCTA from './international-wires-cta';
import { useShowInternationalPaymentsCTA } from 'areas/payments/components/send-payment/international-payments/hooks/use-show-cta';
import { useNavigate } from 'react-router-dom';
import { useGetReserveAccountsList } from '@queries/user-reserve-account';

export type AccountTableData = {
  id: string;
  nickName: string;
  balance: number;
  status: string;
  isReserveAccount: boolean;
  // reserve accounts won't have these values
  accountNumber?: string;
  routingNumber?: string;
};

const sortAccounts = (data: AccountTableData[]) => {
  return data.slice().sort((a, b) => {
    if (a?.status === 'Open' && b?.status === 'Open') {
      return b.balance - a.balance;
    } else if (a?.status === 'Open') {
      return -1;
    } else if (b?.status === 'Open') {
      return 1;
    }
    return 0;
  });
};

const BankingAccount = () => {
  const navigate = useNavigate();
  const { classes, theme } = useStyles();
  const clipboard = useClipboard();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const { data, isSuccess, isLoading, isError } = useGetDepositAccounts();

  const { activeFiltersArray } = useBankingAccountsFilters();
  const { data: employees, isLoading: loadingEmployees } = useGetUsers();
  const { user, company } = useRecoilValue(ApplicationState);
  const showInternationalPaymentsCTA = useShowInternationalPaymentsCTA();

  const {
    data: reserveAccountsData,
    isError: isReserveAccountsError,
    isLoading: isLoadingReserveAccounts,
  } = useGetReserveAccountsList();

  const reserveAccounts = reserveAccountsData?.reserveAccounts ?? [];
  const reserveAccountBalance = reserveAccounts.reduce(
    (acc, curr) => acc + Number(curr.balance),
    0,
  );
  const conditionalRowStyles = [
    {
      when: (row: AccountTableData) => row.isReserveAccount,
      style: {
        backgroundColor: '#F6F3EE',
        '&:hover': {
          cursor: 'auto',
        },
      },
    },
  ];

  const filteredAccounts = useMemo<AccountTableData[]>(() => {
    if (isSuccess) {
      const sortedDepositAccounts = sortAccounts(
        data?.accounts.map((acc) => {
          return {
            id: acc.id,
            balance: acc.balance / 100,
            nickName: acc.nickName,
            status: acc.status,
            accountNumber: acc.accountNumber,
            routingNumber: acc.routingNumber,
            isReserveAccount: false,
          };
        }),
      );
      const sortedReserveAccounts = sortAccounts(
        reserveAccounts.map((acc) => {
          return {
            id: acc.id,
            balance: acc.balance,
            nickName: 'Reserve Account',
            status: acc.status,
            isReserveAccount: true,
          };
        }),
      );

      const accountsList = [...sortedReserveAccounts, ...sortedDepositAccounts];

      return accountsList.filter((t) =>
        activeFiltersArray.every((f) => f.fn(t)),
      );
    }

    return [];
  }, [data, activeFiltersArray, isSuccess, reserveAccountsData]);

  const columns: TableColumn<AccountTableData>[] = [
    {
      name: 'Account',
      cell: (row) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar radius="xl" mr={15} w={45} h={45}>
            <FlexIconShort />
          </Avatar>
          {row.nickName}
        </div>
      ),
      grow: 4,
      sortable: true,
      selector: (row) => row.nickName,
    },
    {
      name: 'ACH/Wire Routing',
      cell: (row) => !row.isReserveAccount && row.routingNumber,
      grow: 2,
    },
    {
      name: 'Account number',
      cell: (row) => {
        const [displayAccountNumber, setDisplayAccountNumber] = useState(false);
        if (!row.isReserveAccount) {
          return (
            <div>
              {displayAccountNumber ? (
                <Tooltip
                  label={clipboard.copied ? 'Copied' : 'Click to copy'}
                  withArrow
                  data-testid="account-tooltip-icon"
                >
                  <div style={{ display: 'flex' }}>
                    <button
                      style={{ color: '#5F5F5F' }}
                      onClick={() => clipboard.copy(row.accountNumber)}
                    >
                      {row.accountNumber}
                    </button>
                    <button onClick={() => setDisplayAccountNumber(false)}>
                      <IoEyeOffOutline color="#85868D" size={16} />
                    </button>
                  </div>
                </Tooltip>
              ) : (
                <div style={{ display: 'flex' }}>
                  <div>•••••{row.accountNumber?.slice(-4)}</div>

                  <button onClick={() => setDisplayAccountNumber(true)}>
                    <IoEyeOutline color="#85868D" size={16} />
                  </button>
                </div>
              )}
            </div>
          );
        }
      },
      grow: 3,
    },
    {
      name: 'Balance',
      format: (row) => formatCurrency(row.balance),
      selector: (row) => row.balance,
      sortable: true,
      grow: 2,
    },
    {
      name: 'Wire details',
      cell: (row) => {
        if (row.isReserveAccount) {
          return;
        }
        return (
          <Group spacing="xs" onClick={() => downloadWireInstructions(row.id)}>
            <DownloadIcon
              color={theme.fn.themeColor('primarySecondarySuccess', 2)}
              width={15}
              height={15}
              style={{ marginLeft: 0 }}
            />
            Download
          </Group>
        );
      },
      grow: 2,
    },
    {
      name: 'Status',
      sortable: true,
      selector: (row) => row.status,
      cell: (row) => <Badge>{row.status}</Badge>,
      grow: 1,
    },
  ];

  const columnsSm: TableColumn<AccountTableData>[] = [
    {
      name: 'Name',
      cell: (row) => row.nickName,
      selector: (row) => row.nickName,
      sortable: true,
      grow: 2,
    },
    {
      name: 'Balance',
      format: (row) => formatCurrency(row.balance),
      selector: (row) => row.balance,
      sortable: true,
    },
    {
      name: 'Status',
      sortable: true,
      selector: (row) => row.status,
      cell: (row) => <Badge>{row.status}</Badge>,
    },
  ];

  const AccountSummaryCard = () => {
    return (
      <ProgressPercent
        currentAmount={bankInfo.balance / 100}
        title="Active banking balance"
        totalAvailableBalance={(bankInfo.balance / 100) * 2}
        classNames={{ card: classes.cardOverride }}
      >
        {reserveAccountBalance > 0 && (
          <>
            <Text size={16} color="#5F5F5F" weight={500} mt={10}>
              Reserve balance
            </Text>
            <Text size={26} color="#000000" weight={500} className={classes.pp}>
              {formatCurrency(reserveAccountBalance)}
            </Text>
          </>
        )}
      </ProgressPercent>
    );
  };

  // temp cals for tiers
  const bankAccountsInfo = () => {
    let balance = 0;

    data?.accounts.forEach((account) => {
      balance += account.balance;
    });
    return {
      balance,
    };
  };
  const bankInfo = bankAccountsInfo();

  const openModal = () =>
    modals.open({
      title: 'Please select a control person',
      children: (
        <ControlPersonMenu employees={employees} isLoading={loadingEmployees} />
      ),
      size: 'lg',
      closeOnClickOutside: false,
      closeOnEscape: false,
      withCloseButton: false,
    });

  useEffect(() => {
    const filterUser = employees?.filter((item) => item.promoCodeId);
    if (
      filterUser?.length === 0 &&
      user.roles?.includes('ADMIN') &&
      !company.controlPerson
    ) {
      openModal();
    }
  }, [employees]);

  const handleRowClick = (row: AccountTableData) => {
    if (!row.isReserveAccount) {
      navigate(`/banking/account-details/${row.id}/transactions`);
    }
  };

  if (isError ?? isReserveAccountsError) {
    return (
      <GenericError>
        <p>We are unable to retrieve the account information at the moment.</p>
      </GenericError>
    );
  }

  if (isLoading ?? isLoadingReserveAccounts) {
    return <SkeletonLoading />;
  }

  return (
    <>
      <Grid>
        <Grid.Col xs={12} sm={8} md={6} lg={4}>
          <div className={classes.containerWidgets}>
            <AccountSummaryCard />
          </div>
        </Grid.Col>
        <Grid.Col xs={12} sm={8} md={6} lg={4}>
          <div className={classes.containerWidgets}>
            <RecentTransactionsWidget />
          </div>
        </Grid.Col>
        <Grid.Col xs={12} sm={8} md={6} lg={4}>
          <div className={classes.containerWidgets}>
            {showInternationalPaymentsCTA ? (
              <IntlWiresCTA />
            ) : (
              <MakePaymentWidget />
            )}
          </div>
        </Grid.Col>
      </Grid>
      <div
        className={classes.card}
        style={{ marginTop: theme.spacing.lg }}
        data-testid="account-info"
      >
        <BankingAccountsHeader />
        <FlexbaseTable
          onRowClicked={handleRowClick}
          striped={false}
          columns={useMobileView ? columnsSm : columns}
          data={filteredAccounts}
          isFetchingData={isLoading}
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
    </>
  );
};

export default BankingAccount;
