import { createStyles } from '@mantine/core';

export const accountDetailsStyles = createStyles((theme) => ({
  container: {
    padding: theme.spacing.xl,
    marginBottom: theme.spacing.md,
    borderRadius: theme.defaultRadius,
    backgroundColor: theme.fn.themeColor('neutral', 0),
    border: `1px solid ${theme.fn.themeColor('neutral', 3)}`,
  },
}));
