import { Fragment, useEffect, useState } from 'react';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import CreditLimit from '../../components/credit-limit/credit-limit';
import CreditPaymentCard from '../../components/credit-payment/credit-payment';
import CreditTransactions from '../../components/credit-transactions/credit-transactions';
import AnnualSpendChart from './annual-spend.chart';
import SpentWeek from 'areas/credit/components/spent-this-week/spent-this-week';
import LastTransaction from 'areas/credit/components/last-transaction/last-transaction';
import { useStyles } from './overview.styles';
import { useRecoilValue } from 'recoil';
import { modals } from '@mantine/modals';

import {
  ApplicationState,
  CompanySelector,
} from '../../../../states/application/product-onboarding';
import { CreditActionAlertBar } from '../../components/credit-action-alert-bar/credit-action-alert-bar';
import ControlPersonMenu from './control-person-menu';
import { useGetUsers } from '@queries/use-users';
import {
  Employees,
  isGraceDayEarlyPayConfig,
} from 'states/onboarding/onboarding-info';
import { useMediaQuery } from '@mantine/hooks';
import CreditTransactionsSm from '../../components/credit-transactions/credit-transactions-sm/credit-transactions-sm';
import { EarlyPayProjectedDueCard } from '../../components/early-pay-card/early-pay-projected-due-card';
import { useEarlyPayProjectedDueFlag } from '@utilities/feature-flags';

const Overview = () => {
  const { classes, cx } = useStyles();
  const [hasMadePayment, setHasMadePayment] = useState(false);
  const { user, company } = useRecoilValue(ApplicationState);
  const { id } = useRecoilValue(CompanySelector);
  const { data, isLoading } = useGetUsers();
  const earlyPayFeatureFlag = useEarlyPayProjectedDueFlag();
  const isMobile = useMediaQuery('(max-width: 767px)');

  const openModal = (employees: Employees[] | undefined, loading: boolean) =>
    modals.open({
      title: 'Please select a control person',
      children: <ControlPersonMenu employees={employees} isLoading={loading} />,
      closeOnClickOutside: false,
      closeOnEscape: false,
      withCloseButton: false,
    });

  useEffect(() => {
    const getCompanyBalance = async () => {
      const result = await flexbaseOnboardingClient.getMinimumDue(`${id}`);
      setHasMadePayment(result.paymentMadeThisCycle);
    };
    getCompanyBalance();
  }, []);

  useEffect(() => {
    const filterUser = data?.filter((item) => item.promoCodeId);
    if (
      filterUser?.length === 0 &&
      user.roles?.includes('ADMIN') &&
      !company.controlPerson
    ) {
      openModal(data, isLoading);
    }
  }, [data]);

  return (
    <Fragment>
      <CreditActionAlertBar />
      <div className={classes.creditContainer}>
        {user.roles.some(
          (r) => r === 'ADMIN' || r === 'ACCOUNTANT' || r === 'OWNER',
        ) ? (
          <div className={classes.widgetContainer}>
            <div className={classes.widget}>
              <CreditLimit hasPendingPayment={hasMadePayment} />
            </div>
            <div className={cx(classes.monaySpentContainer, classes.widget)}>
              {isGraceDayEarlyPayConfig(company.earlypay) &&
              earlyPayFeatureFlag ? (
                <EarlyPayProjectedDueCard earlyPayConfig={company.earlypay} />
              ) : (
                <AnnualSpendChart />
              )}
            </div>
            <div className={classes.widget}>
              <CreditPaymentCard disablePayNowBtn={false} />
            </div>
          </div>
        ) : (
          <>
            <LastTransaction />
            <SpentWeek />
          </>
        )}
      </div>
      {isMobile ? <CreditTransactionsSm /> : <CreditTransactions />}
    </Fragment>
  );
};

export default Overview;
