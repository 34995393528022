import * as Sentry from '@sentry/react';
import { ConsoleLogger, Logger } from '@flexbase/logger';

class SentryLogger implements Logger {
  error(message: unknown, ...optionalParams: unknown[]): void {
    if (message instanceof Error) {
      Sentry.captureException(message, {
        level: 'error',
        extra: { optionalParams },
      });
    } else {
      Sentry.captureMessage(`${message}`, {
        level: 'error',
        extra: { optionalParams },
      });
    }
  }
  warn(message: unknown, ...optionalParams: unknown[]): void {
    Sentry.captureMessage(`${message}`, {
      level: 'warning',
      extra: { optionalParams },
    });
  }
  info(message: unknown, ...optionalParams: unknown[]): void {
    Sentry.captureMessage(`${message}`, {
      level: 'info',
      extra: { optionalParams },
    });
  }
  debug(message: unknown, ...optionalParams: unknown[]): void {
    Sentry.captureMessage(`${message}`, {
      level: 'debug',
      extra: { optionalParams },
    });
  }
  trace(message: unknown, ...optionalParams: unknown[]): void {
    console.trace(message, optionalParams); // eslint-disable-line
  }
}

export const logger = import.meta?.env?.DEV
  ? new ConsoleLogger()
  : new SentryLogger();
