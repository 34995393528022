export const getPlatform = () => {
  const userAgent = navigator.userAgent;

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  if (/iPhone/.test(userAgent)) {
    return 'iOS';
  }

  return '';
};
