import Country from 'country-state-city/lib/country';
import {
  AvailableCurrencies,
  CountriesRequiringPotentialPaymentPurposeCodes,
  InternationalPayMethod,
  InternationalRecipient,
  CurrencySelectProps,
  InternationalWire,
  InternationalWireUSD,
  AvailableCountries,
} from './types';
import { Recipient } from '../../payment.states';
import { PaymentStatusDetails } from '../../payment-confirmation';

//values and labels for international payments have been derived through this provided link: https://support.currencycloud.com/hc/en-gb/articles/360017430000-Payment-Purpose-Codes
export const paymentPurposeCodesByCountry = {
  CN: [
    { value: 'CTF', label: 'Cross-border capital transfer' },
    { value: 'GOD', label: 'Cross-border goods trade' },
    { value: 'STR', label: 'Cross-border service transfer' },
    { value: 'RMT', label: 'Cross-border individual remittance' },
    { value: 'OTF', label: 'Other transfers' },
  ],
  AE: [
    { value: 'ACM', label: 'Agency Commission' },
    { value: 'AES', label: 'Advance payment against EOS' },
    {
      value: 'AFA',
      label:
        'Receipts or payments from personal residents bank account or deposits abroad',
    },
    {
      value: 'AFL',
      label:
        'Receipts or payments from personal non-resident bank account in the UAE',
    },
    { value: 'ALW', label: 'Allowances' },
    { value: 'ATS', label: 'Air transport' },
    { value: 'BON', label: 'Bonus' },
    { value: 'CCP', label: 'Corporate Card Payment' },
    {
      value: 'CEA',
      label:
        'Equity and investment fund shares for the establishment of new company from residents abroad, equity of merger or acquisition of companies abroad from residents and participation to capital increase of related companies abroad',
    },
    {
      value: 'CEL',
      label:
        'Equity and investment fund shares for the establishment of new company in the UAE from non-residents, equity of merger or acquisition of companies in the UAE from non-residents and participation to capital increase of related companies from non-residents in the UAE',
    },
    { value: 'CHC', label: 'Charitable Contributions' },
    { value: 'CIN', label: 'Commercial Investments' },
    { value: 'COM', label: 'Commission' },
    { value: 'COP', label: 'Compensation' },
    { value: 'CRP', label: 'Credit Card Payments' },
    {
      value: 'DCP',
      label: 'Pre-Paid Reloadable & Personalized Debit Card Payments',
    },
    { value: 'DIV', label: 'Dividend Payouts' },
    {
      value: 'DLA',
      label:
        'Purchases and sales of foreign debt securities in not related companies – More than a year',
    },
    {
      value: 'DLF',
      label: 'Debt instruments intragroup loans, deposits foreign',
    },
    {
      value: 'DLL',
      label:
        'Purchases and sales of securities issued by residents in not related companies – More than a year',
    },
    { value: 'DOE', label: 'Dividends on equity not intra group' },
    {
      value: 'DSA',
      label:
        'Purchases and sales of foreign debt securities in not related companies – Less than a year',
    },
    { value: 'DSF', label: 'Debt instruments intragroup foreign securities' },
    {
      value: 'DSL',
      label:
        'Purchases and sales of securities issued by residents in not related companies – Less than a year',
    },
    { value: 'EDU', label: 'Educational Support' },
    { value: 'EMI', label: 'Equated Monthly Instalments' },
    { value: 'EOS', label: 'End of Service' },
    { value: 'FAM', label: 'Family Support' },
    { value: 'FDA', label: 'Financial derivatives foreign' },
    { value: 'FDL', label: 'Financial derivatives in the UAE' },
    { value: 'FIA', label: 'Investment fund shares foreign' },
    { value: 'FIL', label: 'Investment fund shares in the UAE' },
    { value: 'FIS', label: 'Financial services' },
    {
      value: 'FSA',
      label:
        'Equity other than investment fund shares in not related companies abroad',
    },
    {
      value: 'FSL',
      label:
        'Equity other than investment fund shares in not related companies in the UAE',
    },
    { value: 'GDE', label: 'Goods Sold' },
    { value: 'GDI', label: 'Goods bought' },
    {
      value: 'GMS',
      label: 'Processing repair and maintenance services on goods',
    },
    { value: 'GOS', label: 'Government goods and services embassies etc' },
    {
      value: 'GRI',
      label: 'Government related income taxes tariffs capital transfers etc',
    },
    { value: 'IFS', label: 'Information services' },
    { value: 'IGD', label: 'Intra group dividends' },
    { value: 'IGT', label: 'Inter group transfer' },
    { value: 'IID', label: 'Intra group interest on debt' },
    { value: 'INS', label: 'Insurance services' },
    { value: 'IOD', label: 'Income on deposits' },
    { value: 'IOL', label: 'Income on loans' },
    {
      value: 'IPC',
      label: 'Charges for the use of intellectual property royalties',
    },
    { value: 'IPO', label: 'IPO subscriptions' },
    { value: 'IRP', label: 'Interest rate swap payments' },
    { value: 'IRW', label: 'Interest rate unwind payments' },
    { value: 'ISH', label: 'Income on investment funds share' },
    { value: 'ISL', label: 'Interest on securities more than a year' },
    { value: 'ISS', label: 'Interest on securities less than a year' },
    { value: 'ITS', label: 'Computer services' },
    { value: 'LAS', label: 'Leave salary' },
    {
      value: 'LDL',
      label: 'Debt instruments intragroup loans, deposits in the UAE',
    },
    {
      value: 'LDS',
      label: 'Debt instruments intragroup securities in the UAE',
    },
    { value: 'LEA', label: 'Leasing abroad' },
    { value: 'LEL', label: 'Leasing in the UAE' },
    { value: 'LIP', label: 'Loan interest payments' },
    {
      value: 'LLA',
      label:
        'Loans – Drawings or Repayments on loans extended to nonresidents – Long-term',
    },
    {
      value: 'LLL',
      label:
        'Loans – Drawings or Repayments on foreign loans extended to residents – Long-term',
    },
    { value: 'LNC', label: 'Loan charges' },
    { value: 'LND', label: 'Loan disbursements' },
    {
      value: 'MCR',
      label:
        'Monetary Claim Reimbursements Medical Insurance or Auto Insurance etc.',
    },
    { value: 'MWI', label: 'Mobile wallet cash in' },
    { value: 'MWO', label: 'Mobile wallet cash out' },
    { value: 'MWP', label: 'Mobile wallet payments' },
    { value: 'OAT', label: 'Own account transfer' },
    { value: 'OTS', label: 'Other modes of transport' },
    { value: 'OVT', label: 'Overtime' },
    { value: 'PEN', label: 'Pension' },
    { value: 'PIN', label: 'Personal Investments' },
    { value: 'PIP', label: 'Profits on Islamic products' },
    { value: 'PMS', label: 'Professional and management consulting services' },
    { value: 'POR', label: 'Refunds or Reversals on IPO subscriptions' },
    { value: 'POS', label: 'POS Merchant Settlement' },
    { value: 'PPA', label: 'Purchase of real estate abroad from residents' },
    {
      value: 'PPL',
      label: 'Purchase of real estate in the UAE from non-residents',
    },
    { value: 'PRP', label: 'Profit rate swap payments' },
    { value: 'PRR', label: 'Profits or rents on real estate' },
    {
      value: 'PRS',
      label: 'Personal cultural audio visual and recreational services',
    },
    { value: 'PRW', label: 'Profit rate unwind payments' },
    { value: 'RDS', label: 'Research and development services' },
    { value: 'RFS', label: 'Repos on foreign securities' },
    { value: 'RLS', label: 'Repos on securities issued by residents' },
    { value: 'RNT', label: 'Rent payments' },
    { value: 'SAA', label: 'Salary advance' },
    { value: 'SAL', label: 'Salary' },
    { value: 'SCO', label: 'Construction' },
    {
      value: 'SLA',
      label:
        'Loans – Drawings or Repayments on loans extended to nonresidents – Short-term',
    },
    {
      value: 'SLL',
      label:
        'Loans – Drawings or Repayments on foreign loans extended to residents – Short-term',
    },
    { value: 'STR', label: 'Travel' },
    { value: 'STS', label: 'Sea transport' },
    { value: 'SVI', label: 'Stored value card cash-in' },
    { value: 'SVO', label: 'Stored value card cash-out' },
    { value: 'SVP', label: 'Stored value card payments' },
    { value: 'TCP', label: 'Trade credits and advances payable' },
    { value: 'TCR', label: 'Trade credits and advances receivable' },
    { value: 'TCS', label: 'Telecommunication services' },
    { value: 'TKT', label: 'Tickets' },
    {
      value: 'TOF',
      label: 'Transfer of funds between persons Normal and Juridical',
    },
    {
      value: 'TTS',
      label: 'Technical trade-related and other business services',
    },
    { value: 'UTL', label: 'Utility Bill Payments' },
  ],
  BH: [
    { value: 'GDE', label: 'Goods sold (Exports in fob value)' },
    { value: 'GDI', label: 'Goods bought (Imports in cif value)' },
    { value: 'STS', label: 'Sea Transport' },
    { value: 'ATS', label: 'Air transport' },
    {
      value: 'OTS',
      label:
        'OTS Other methods of transport (including Postal and courier services)',
    },
    { value: 'STR', label: 'Travel' },
    {
      value: 'GMS',
      label: 'Processing repair and maintenance services on goods',
    },
    { value: 'SCO', label: 'Construction' },
    { value: 'INS', label: 'Insurance Services' },
    { value: 'FIS', label: 'Financial Services' },
    {
      value: 'IPC',
      label: 'Charges for the use of intellectual property royalties',
    },
    { value: 'TCS', label: 'Telecommunications services' },
    { value: 'ITS', label: 'Computer services' },
    { value: 'IFS', label: 'Information services' },
    { value: 'RDS', label: 'Research and development services' },
    { value: 'PMS', label: 'Professional and management consulting services' },
    {
      value: 'TTS',
      label: 'Technical, trade- related and other business services',
    },
    {
      value: 'PRS',
      label: 'Personal, cultural, audiovisual and recreational services',
    },
    { value: 'IGD', label: 'Dividends intragroup' },
    { value: 'IID', label: 'Interest on debt intragroup' },
    { value: 'PIP', label: 'Profits on Islamic products' },
    { value: 'PRR', label: 'Profits or rents on real estate' },
    { value: 'DOE', label: 'Dividends on equity not Intragroup' },
    { value: 'ISH', label: 'Income on investment funds shares' },
    { value: 'ISL', label: 'Interest on securities more than a year' },
    { value: 'ISS', label: 'Interest on securities less than a year' },
    { value: 'IOL', label: 'Income on loans' },
    { value: 'IOD', label: 'Income on deposits' },
    { value: 'GOS', label: 'Government goods and services embassies etc' },
    {
      value: 'GRI',
      label: 'Government related income taxes, tariffs, capital transfers, etc',
    },
    { value: 'CHC', label: 'Charitable Contributions (Charity and Aid)' },
    { value: 'FAM', label: 'Family Support (Workers’ remittances)' },
    { value: 'SAL', label: 'Salary (Compensation of employees)' },
    { value: 'PPA', label: 'Purchase of real estate abroad from residents' },
    {
      value: 'PPL',
      label: 'Purchase of real estate in Bahrain from non-residents',
    },
    {
      value: 'CEA',
      label:
        'Equity and Investment fund shares for the establishment of new company from residents abroad, equity of merger or acquisition of companies abroad from residents and participation to capital increase of related companies abroad',
    },
    { value: 'DSF', label: 'Debt instruments Intragroup foreign securities' },
    { value: 'REL', label: 'Reverse equity share in Bahrain' },
    { value: 'RDL', label: 'Reverse debt instruments in Bahrain' },
    {
      value: 'FSA',
      label:
        'Equity other than investment fund shares in not related companies abroad',
    },
    { value: 'FIA', label: 'Investment fund shares foreign' },
    {
      value: 'DSA',
      label:
        'Purchases and sales of foreign debt securities in not related companies- Less than a year',
    },
    {
      value: 'DLA',
      label:
        'Purchases and sales of foreign debt securities in not related companies- More than a year',
    },
    { value: 'FDA', label: 'Financial derivatives foreign' },
    {
      value: 'DLF',
      label:
        'Debt Instruments Intragroup loans, deposits foreign (above 10% share)',
    },
    {
      value: 'AFA',
      label:
        'Receipts or payments from personal residents bank account or deposits abroad',
    },
    {
      value: 'SLA',
      label:
        'Loans- Drawings or Repayments on loans extended to nonresidents- Short-term',
    },
    {
      value: 'LLA',
      label:
        'Loans- Drawings or Repayments on loans extended to nonresidents- Long-term',
    },
    { value: 'LEA', label: 'Leasing abroad' },
    { value: 'RFS', label: 'Repos on foreign securities' },
    { value: 'TCR', label: 'Trade credits and advances receivable' },
    {
      value: 'CEL',
      label:
        'Equity and Investment fund shares for the establishment of new company in Bahrain from non-residents, equity of merger or acquisition of companies in Bahrain from non-residents and participation to capital increase of related companies from non-residents in Bahrain',
    },
    {
      value: 'LDS',
      label: 'Debt instruments intragroup securities in Bahrain',
    },
    { value: 'REA', label: 'Reverse equity share abroad' },
    { value: 'RDA', label: 'Reverse debt instruments abroad' },
    {
      value: 'FSL',
      label:
        'Equity other than investment fund shares in not related companies in Bahrain',
    },
    { value: 'FIL', label: 'Investment fund shares in Bahrain' },
    {
      value: 'DSL',
      label:
        'Purchases and sales of securities issued by residents in not related companies- Less than a year',
    },
    {
      value: 'DLL',
      label:
        'Purchases and sales of securities issued by residents in not related companies- More than a year',
    },
    { value: 'FDL', label: 'Financial derivatives in Bahrain' },
    {
      value: 'LDL',
      label:
        'Debt Instruments Intragroup loans, deposits in Bahrain (above 10% share)',
    },
    {
      value: 'AFL',
      label:
        'Receipts or payments from personal nonresidents bank account in Bahrain',
    },
    {
      value: 'SLL',
      label:
        'Loans- Drawings or Repayments on foreign loans extended to residents- Short-term',
    },
    {
      value: 'LLL',
      label:
        'Loans- Drawings or Repayments on foreign loans extended to residents- Long-term',
    },
    { value: 'LEL', label: 'Leasing in Bahrain' },
    { value: 'RLS', label: 'Repos on securities issued by residents' },
    { value: 'TCP', label: 'Trade credits and advances payable' },
  ],
};

export const getPaymentPurposeLabel = (
  country: CountriesRequiringPotentialPaymentPurposeCodes,
  code: string,
) => {
  return paymentPurposeCodesByCountry[country].find(
    (c: { value: string; label: string }) => c.value === code,
  )?.label;
};

export const supportedCurrenciesData: CurrencySelectProps[] = [
  {
    value: 'CAD',
    label: 'Canadian Dollar',
    code: 'CA',
    symbol: '$',
  },
  {
    value: 'CNY',
    label: 'Chinese Yuan',
    code: 'CN',
    symbol: '¥',
  },
  { value: 'EUR', label: 'Euro', code: 'EU', symbol: '€' },
  {
    value: 'INR',
    label: 'Indian Rupee',
    code: 'IN',
    symbol: '₹',
  },
  {
    value: 'MXN',
    label: 'Mexican Peso',
    code: 'MX',
    symbol: '$',
  },
  {
    value: 'SAR',
    label: 'Saudi Riyal',
    code: 'SA',
    symbol: '﷼',
  },
  {
    value: 'AED',
    label: 'United Arab Emirates Dirham',
    code: 'AE',
    symbol: 'د.إ',
  },
  {
    value: 'GBP',
    label: 'UK Pound Sterling',
    code: 'GB',
    symbol: '£',
  },
];

export const getCurrencyFlag = (currency: AvailableCurrencies) => {
  return supportedCurrenciesData.find((c) => c.value === currency)?.code ?? '';
};

export const getCurrencyFlagWithCountry = (
  country: AvailableCountries | string,
) => {
  return supportedCurrenciesData.find((c) => c.code === country)?.code ?? '';
};

export const requiresPaymentPurposeCodes = (
  country: string,
  currency: AvailableCurrencies,
): country is CountriesRequiringPotentialPaymentPurposeCodes => {
  return (
    (country === 'CN' && currency === 'CNY') ||
    country === 'AE' ||
    country === 'BH'
  );
};

export const isInternationalPayMethod = (
  method: string,
): method is InternationalPayMethod => {
  return method === 'internationalWireUSD' || method === 'internationalWire';
};

export const isInternationalWireUSD = (
  method: string,
): method is InternationalWireUSD => {
  return method === 'internationalWireUSD';
};

export const isInternationalWire = (
  method: string,
): method is InternationalWire => {
  return method === 'internationalWire';
};

export const isInternationalRecipient = (
  recipient: any,
): recipient is InternationalRecipient => {
  return (
    recipient.currency &&
    recipient.countryOfRecipientsBank &&
    recipient.countryOfRecipient
  );
};

export const internationalWireDisclaimer =
  'Payment services are provided by The Currency Cloud Limited. Registered in England No. 06323311. Registered Office: The Steward Building 1st Floor, 12 Steward Street London E1 6FQ. The Currency Cloud Limited is authorised by the Financial Conduct Authority under the Electronic Money Regulations 2011 for the issuing of electronic money (FRN: 900199). In the US Currency Cloud operates in partnership with CFSB. CFSB fully owns the bank program and services are provided by The Currency Cloud Inc.';

const CC_RESTRICTED_CITIES = ['kherson', 'luhansk', 'donetsk', 'zaporizhzhia'];

export const isRestrictedCity = (city: string) => {
  return CC_RESTRICTED_CITIES.includes(city.toLowerCase());
};

// derived from https://support.currencycloud.com/hc/en-gb/articles/360017656199-Restricted-Countries (+ Russia)
const CC_RESTRICTED_COUNTRY_CODES = [
  'BY',
  'CU',
  'IR',
  'KZ ',
  'LY',
  'MM',
  'KP',
  'SS',
  'SD',
  'SY',
  'VE',
  'RU',
];

export const countrySelectOptions = Country.getAllCountries()
  .map((c) => ({
    label: c.name,
    value: c.isoCode,
  }))
  .filter((c) => !CC_RESTRICTED_COUNTRY_CODES.includes(c.value));

export const getCountryCodeByName = (name: string) => {
  return countrySelectOptions.find((c) => c.label === name)?.value;
};

export const formatCamelCasedValue = (field: string) => {
  return field
    .split('')
    .map((char) => {
      return char === char.toUpperCase() ? ` ${char.toLowerCase()}` : char;
    })
    .join('');
};

const parsedFields: Record<string, string> = {
  bicSwift: 'BIC/SWIFT',
  acctNumber: 'Account number',
};

export const formatLabel = (field: string) => {
  const formattedLabel = formatCamelCasedValue(field);
  if (parsedFields[field]) {
    return parsedFields[field];
  }
  return formattedLabel[0].toUpperCase() + formattedLabel.slice(1);
};

export const tempRecipientValueConditionalFix = (
  recipient: Recipient | InternationalRecipient,
  recipientProperty: keyof Recipient | keyof InternationalRecipient,
) => {
  return isInternationalRecipient(recipient)
    ? ''
    : recipient[recipientProperty as keyof Recipient]!;
};

export const getInternationalWireCurrency = (
  details: PaymentStatusDetails,
): AvailableCurrencies => {
  if ('currency' in details.recipient) {
    return details.recipient.currency;
  } else if ('currency' in details.counterparty) {
    return details.counterparty.currency as AvailableCurrencies;
  }
  return 'USD';
};
