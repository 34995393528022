import { Box, Group, Stack, Text } from '@mantine/core';
import { useStyles } from './approval-workflow-form.styles';
import { VscChromeClose } from 'react-icons/vsc';
import { ApprovalPolicies, MercoaSession } from '@mercoa/react';
import '@mercoa/react/dist/style.css';

type Props = {
  closeModal: () => void;
  mercoaToken: string;
};

const ApprovalWorkFlowForm = ({ closeModal, mercoaToken }: Props) => {
  const { classes, theme } = useStyles();

  return (
    <Box>
      <Group className={classes.headerRow1}>
        <Group spacing="md">
          <Stack spacing="xs">
            <Group align="end" spacing="lg">
              <Text className={classes.title}>Create an approval workflow</Text>
            </Group>
          </Stack>
        </Group>
        <button className={classes.closeButton} onClick={closeModal}>
          <VscChromeClose color={theme.colors.contentBackground[2]} />
        </button>
      </Group>

      <Box className={classes.container}>
        <Box className={classes.card}>
          <MercoaSession
            token={mercoaToken}
            googleMapsApiKey={import.meta.env.VITE_APP_GOOGLE_API_KEY}
          >
            <ApprovalPolicies />
          </MercoaSession>
        </Box>
      </Box>
    </Box>
  );
};

export default ApprovalWorkFlowForm;
