import FlexbaseSelect from '@common/select/flexbase-select';
import {
  Button,
  Divider,
  ScrollArea,
  ScrollAreaProps,
  SelectProps,
  Stack,
} from '@mantine/core';
import { forwardRef, useCallback, useRef } from 'react';

type MemberSelectProps = Omit<
  SelectProps,
  'placeholder' | 'dropdownComponent' | 'value' | 'searchable'
> & {
  onAddAll?: () => void;
};

export const TeamMemberSelect = ({ onAddAll, ...rest }: MemberSelectProps) => {
  const onAddAllRef = useRef(onAddAll);
  onAddAllRef.current = onAddAll;

  const hasMembers = useRef(rest.data.length > 0);
  hasMembers.current = rest.data.length > 0;

  const DropdownWithAction = useCallback(
    forwardRef<HTMLDivElement, ScrollAreaProps>(function f(props, ref) {
      if (!hasMembers.current) {
        return <ScrollArea ref={ref} {...props} />;
      }

      return (
        <Stack spacing={0} w={'100%'}>
          <ScrollArea ref={ref} {...props} />

          <Divider />

          <Button
            variant="subtle"
            leftIcon="+"
            h="auto"
            p={16}
            onClick={onAddAllRef.current}
          >
            Add All Team Members
          </Button>
        </Stack>
      );
    }),
    [],
  );

  return (
    <FlexbaseSelect
      {...rest}
      placeholder="Search or select"
      nothingFound="All members assigned"
      dropdownComponent={DropdownWithAction}
      value={null}
      keepDropdownOpenOnSelect
      searchable
    />
  );
};
