import { WizardStepConfig } from '@common/wizard/wizard.types';
import { LoadingOverlay } from '@mantine/core';
import { useGetRecipient } from '@queries/use-recipients';
import { useAddRecipientFlowFeatureFlag } from '@utilities/feature-flags';
import { useQueryParams } from '@utilities/url/query-param';
import { IndividualOrCompany } from 'areas/payments/components/send-payment/international-payments/util/types';
import { Navigate, useNavigate } from 'react-router-dom';
import { AddRecipientState, AddRecipientWizard } from './add-recipient-wizard';
import { PaymentDetailsStep } from './steps/payment-details/payment-details-step';
import { PaymentTypeStep } from './steps/payment-type/payment-type-step';
import {
  RecipientDetailsStep,
  RecipientDetailsStepFormValues,
} from './steps/recipient-details/recipient-details-step';
import { IntlPaymentDetailsStep } from './steps/payment-details/intl-payment-details-step';

export const recipientDetailsFormInitialValues: RecipientDetailsStepFormValues =
  {
    name: '',
    firstName: '',
    lastName: '',
    type: 'company' as IndividualOrCompany,
  };

export type PaymentMethodStepValue =
  | 'domesticACH'
  | 'domesticWire'
  | 'internationalWireUSD'
  | 'internationalWire';

export const PAYMENT_STEP_VALUES: Record<string, PaymentMethodStepValue> = {
  domesticACH: 'domesticACH',
  domesticWire: 'domesticWire',
  internationalWireUSD: 'internationalWireUSD',
  internationalWire: 'internationalWire',
};

const useGetSteps = () => {
  const query = useQueryParams();
  const recipientIdQueryParam = query.get('recipientId');
  const { data: recipientData, isLoading } = useGetRecipient(
    recipientIdQueryParam || '',
  );

  const steps: WizardStepConfig<AddRecipientState>[] = [
    {
      id: RecipientDetailsStep.stepId,
      title: 'Enter recipient details',
      element: <RecipientDetailsStep />,
    },
    {
      id: PaymentTypeStep.stepId,
      title: 'Select recipient payment type',
      element: <PaymentTypeStep />,
    },
    {
      id: PaymentDetailsStep.stepId,
      title: 'Enter recipient payment details',
      element: <PaymentDetailsStep />,
    },
    {
      id: IntlPaymentDetailsStep.stepId,
      title: 'Enter international payment details',
      element: <IntlPaymentDetailsStep />,
      condition: (state) => state.showIntlPaymentStep,
    },
  ];

  const parsedSteps = !recipientData?.recipient
    ? steps
    : steps.filter((step) => step.id !== RecipientDetailsStep.stepId);

  return {
    steps: parsedSteps,
    isLoadingRecipientData: isLoading,
  };
};

const initialState = {
  paymentMethod: PAYMENT_STEP_VALUES.domesticACH,
  recipientDetailsFormValues: recipientDetailsFormInitialValues,
  showIntlPaymentStep: false,
};

const AddRecipient = () => {
  const isFeatureFlagEnabled = useAddRecipientFlowFeatureFlag();
  const navigate = useNavigate();
  const { steps, isLoadingRecipientData } = useGetSteps();

  const handleCancelClick = () => {
    navigate(-1);
  };

  if (!isFeatureFlagEnabled) {
    return <Navigate to="/" />;
  }

  if (isLoadingRecipientData) {
    return <LoadingOverlay visible />;
  }

  return (
    <AddRecipientWizard
      steps={steps}
      initialState={initialState}
      onCancel={handleCancelClick}
      wrapper={({ children }) => children}
    />
  );
};

export default AddRecipient;
