import { useStyles } from '../styles';
import { Box, Button, Text } from '@mantine/core';
import { IntegrationCardItem } from '../list-integrations';
import { IntegrationLink } from '..';
import { DisconnectButton } from './disconnect-button';
import { useIntegrationsContext } from '../integrations.context';
import { IntegrationCardLayout } from './integration-card.layout';
import { PropsWithChildren } from 'react';
import { isIntegrationLinkConnected } from '@utilities/integrations';

type ConnectedIntegrationProps = {
  integration: IntegrationCardItem;
  link: IntegrationLink;
};

export const ConnectedIntegration = ({
  integration,
  link,
}: ConnectedIntegrationProps) => {
  const { classes } = useStyles();
  const { disconnectIntegration, isDisconnecting } = useIntegrationsContext();

  return link.isReady ? (
    <Box>
      <Box className={classes.cardDescriptionRow} mb="xl">
        <Text className={classes.cardDescription}>
          Your {integration.title} integration is configured and ready to use.
        </Text>
      </Box>

      <Box>
        <DisconnectButton
          onClick={() => disconnectIntegration(integration, link)}
          loading={isDisconnecting}
        >
          Disconnect
        </DisconnectButton>
      </Box>
    </Box>
  ) : (
    <Box className={classes.cardDescriptionRow} mb="xl">
      <Text className={classes.cardDescription}>
        Your {integration.title} integration is being setup. This process may
        take up to an hour.
      </Text>
    </Box>
  );
};

type UnconnectedIntegrationProps = {
  integration: IntegrationCardItem;
};

export const UnconnectedIntegration = ({
  integration,
}: UnconnectedIntegrationProps) => {
  const { classes } = useStyles();
  const { connectIntegration, connectDisabled } = useIntegrationsContext();
  const platform = integration.platform;

  return (
    <Box>
      <Box className={classes.cardDescriptionRow} mb="xl">
        <Text className={classes.cardDescription}>{integration.content}</Text>
      </Box>

      {platform ? (
        <Box>
          <Button
            variant="outline"
            onClick={() => connectIntegration(platform)}
            disabled={connectDisabled}
          >
            Connect
          </Button>
        </Box>
      ) : (
        <Box>
          <Button variant="outline" disabled>
            Coming soon!
          </Button>
        </Box>
      )}
    </Box>
  );
};

type IntegrationCardProps = PropsWithChildren<{
  integration: IntegrationCardItem;
  link?: IntegrationLink;
  withNavigation?: boolean;
  backTo?: string;
}>;

export const IntegrationCard = ({
  children,
  integration,
  link,
  withNavigation = false,
  backTo = '..',
}: IntegrationCardProps) => {
  return (
    <IntegrationCardLayout
      integration={integration}
      link={link}
      withNavigation={withNavigation}
      backTo={backTo}
    >
      {children ? (
        children
      ) : isIntegrationLinkConnected(link) ? (
        <ConnectedIntegration integration={integration} link={link} />
      ) : (
        <UnconnectedIntegration integration={integration} />
      )}
    </IntegrationCardLayout>
  );
};
