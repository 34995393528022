import { useRecoilState, useSetRecoilState } from 'recoil';
import { UserInfoState } from 'states/user/user-info';
import SettingsSection from '../settings-section';
import PlainTextAddress from 'components/address/plain-text-address';
import flexbaseClient from 'services/flexbase-client';
import { LoadingState } from 'areas/onboarding/onboarding-form.state';
import { showNotification } from '@mantine/notifications';
import { FaExclamationCircle } from 'react-icons/fa';
import useEditModals from 'components/modal/edit-modal-hook';
import { formatUSPhoneNumber } from 'utilities/formatters/format-phone-number';
import ImageSettingsSection from '../image-settings-section';

const UserSettingsList = () => {
  const [user, setUser] = useRecoilState(UserInfoState);
  const setLoading = useSetRecoilState(LoadingState);
  const editModals = useEditModals();

  const onUpload = async (image: File) => {
    setLoading(true);

    const response = await flexbaseClient.updatePersonPicture(user.id, image);

    if (response) {
      const imageUrl = URL.createObjectURL(new Blob([image]));
      setUser((prev) => ({ ...prev, picUrl: imageUrl }));

      showNotification({
        color: 'flexbase-teal',
        title: 'Success!',
        message: 'Your profile photo is updated',
      });
    } else {
      showNotification({
        title: 'Failure',
        message: 'Unable to upload profile picture',
        color: 'red',
        icon: <FaExclamationCircle />,
      });
    }

    setLoading(false);
  };

  return (
    <div>
      <ImageSettingsSection
        showDivider={true}
        onFileUpload={onUpload}
        imageUrl={user.picUrl || ''}
      />
      <SettingsSection
        field="Full name"
        value={`${user.firstName} ${user.lastName}`}
        editable={false}
        onEditClick={editModals.openEditNameModal}
        showDivider={true}
      />
      <SettingsSection
        field={'Title'}
        value={user.jobTitle}
        editable={true}
        onEditClick={editModals.openEditTitleModal}
        showDivider={true}
      />
      <SettingsSection
        field={'Email'}
        value={user.email}
        editable={false}
        showDivider={true}
      />
      <SettingsSection
        field={'Phone Number'}
        value={formatUSPhoneNumber(user.cellPhone)}
        editable={false}
        showDivider={true}
      />
      <SettingsSection
        field={'Mailing Address'}
        value={<PlainTextAddress address={user.address} />}
        editable={true}
        onEditClick={editModals.openEditUserAddressModal}
        showDivider={false}
      />
    </div>
  );
};

export default UserSettingsList;
