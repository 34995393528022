import { ReactNode } from 'react';
import { Box, Flex, Group, Text } from '@mantine/core';
import { useSecurityCenterStyles } from '../security-center.styles';

export const FactorBox = ({
  onFactorBoxClick,
  title,
  subtitle,
  icon,
  tag,
  disabled,
}: {
  onFactorBoxClick: () => void;
  title: string;
  subtitle: string;
  icon: ReactNode;
  tag?: ReactNode;
  disabled?: boolean;
}) => {
  const { classes, cx } = useSecurityCenterStyles();
  return (
    <Flex
      align="center"
      onClick={onFactorBoxClick}
      className={cx(
        classes.factorSelectBox,
        disabled && classes.factorSelectBoxDisabled,
      )}
    >
      <Group spacing="sm" c="neutral.5" sx={{ flexWrap: 'nowrap' }}>
        {icon}
        <Box>
          <Group spacing="xs">
            <Text fw={900} lts="normal" c="primarySecondarySuccess.9">
              {title}
            </Text>
            {tag}
          </Group>
          <Text fz={14} c="neutral.6">
            {subtitle}
          </Text>
        </Box>
      </Group>
    </Flex>
  );
};
