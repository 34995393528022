import { useGetUserAccounts } from '@queries/use-user-accounts';
import { PlatformAccountsInfo } from '@services/platform/models/identity.model';
import { useMultiAccountFeatureFlag } from '@utilities/feature-flags';
import { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'states/application/product-onboarding';

type UseMultiAccountReturnType = {
  allAccounts: PlatformAccountsInfo[];
  businessId: string;
  currentAccount: PlatformAccountsInfo | undefined;
  isSuccess: boolean;
  filteredAccounts: PlatformAccountsInfo[] | undefined;
  setBusinessId: React.Dispatch<React.SetStateAction<string>>;
};

export const useMultiAccount = (): UseMultiAccountReturnType => {
  const { company } = useRecoilValue(ApplicationState);
  const { data: accounts, isSuccess } = useGetUserAccounts();
  const enabled = useMultiAccountFeatureFlag();

  const [businessId, setBusinessId] = useState(company.id);

  const allAccounts: PlatformAccountsInfo[] =
    enabled && accounts?.data ? accounts.data : [];

  const { currentAccount, filteredAccounts } = useMemo(() => {
    return {
      currentAccount: accounts?.data?.find(
        (item) => item.businessId === businessId,
      ),
      filteredAccounts:
        (enabled &&
          accounts?.data?.filter((item) => item.businessId !== businessId)) ||
        [],
    };
  }, [accounts?.data, businessId]);

  return {
    allAccounts,
    businessId,
    currentAccount,
    isSuccess,
    filteredAccounts,
    setBusinessId,
  };
};
