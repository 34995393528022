import { Box, Button, Center, Stack, Text } from '@mantine/core';
import FlexPatternFormat from '../../payments/components/common/flex-pattern-format';
import { usePlatformPersonContext } from '../../../providers/platform-person.context';
import { useState } from 'react';
import {
  formatPhoneForApi,
  formatUSPhoneNumber,
} from '@utilities/formatters/format-phone-number';
import { validateUSPhoneNumber } from '@utilities/validators/validate-phone-number';
import { useFactorSetupMultistepFormContext } from '../pages/factor-setup/factor-setup-multi-step-form.context';
import { IsFlexHttpError } from '@services/platform/models/authorize.models';
import { useAddFactorsContext } from '../../../providers/add-factors.context';
import { VerificationCodeInput } from '../../onboarding-v2/steps/verify-code/verify-code';
import { notifications } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import { useSecurityCenterStyles } from '../security-center.styles';

export const ConfigureSmsFactor = () => {
  const { classes } = useSecurityCenterStyles();
  const navigate = useNavigate();
  const { registerFactor, verifyFactor, resendOtpSmsVerification } =
    useAddFactorsContext();
  const { person } = usePlatformPersonContext();
  const { setState } = useFactorSetupMultistepFormContext();

  const [phoneNumber, setPhoneNumber] = useState(
    person.phone?.value
      ? formatUSPhoneNumber(person.phone?.value.replace('+1', ''))
      : '',
  );
  const [methodId, setMethodId] = useState('');

  const [phoneInputError, setPhoneInputError] = useState('');
  const [registerFactorLoading, setRegisterFactorLoading] = useState(false);

  const [codeVerificationError, setCodeVerificationError] = useState('');
  const [codeVerificationLoading, setCodeVerificationLoading] = useState(false);

  const handleSendCodeClick = async () => {
    setState({ error: null });
    setPhoneInputError('');
    if (validateUSPhoneNumber(phoneNumber)) {
      setRegisterFactorLoading(true);

      const formattedForApi = formatPhoneForApi(phoneNumber);
      const formattedForPlatform = `+1${formattedForApi}`;

      try {
        const { methodId: factorMethodId } = await registerFactor({
          factorType: 'phone',
          value: formattedForPlatform,
        });
        setMethodId(factorMethodId);
        notifications.show({
          color: 'green',
          message: `Successfully sent an SMS code to phone number ending in **${phoneNumber.slice(
            -4,
          )}`,
          autoClose: 8000,
        });
      } catch (e) {
        if (IsFlexHttpError(e) && e.statusCode === 409) {
          setPhoneInputError(
            'You have already registered and verified this number. You may safely exit this process with the "Cancel" button (top right).',
          );
        } else {
          setPhoneInputError(
            'We encountered an error when registering this phone number as an authentication factor.',
          );
        }
      } finally {
        setRegisterFactorLoading(false);
      }
    } else {
      setPhoneInputError('You must enter a valid 10-digit US phone number.');
    }
  };

  const handleCodeSubmit = async (code: string) => {
    setCodeVerificationError('');

    if (!methodId) {
      setCodeVerificationError(
        'You must click "Send Code" to send an SMS code to your preferred mobile number prior to verification.',
      );
    } else {
      setCodeVerificationLoading(true);
      try {
        await verifyFactor(methodId, 'otp', code);
        navigate('/', { replace: true });
        notifications.show({
          color: 'green',
          message: `Successfully registered the phone number ending in **${phoneNumber.slice(
            -4,
          )} as an authentication factor. You will be prompted to verify a code sent to this number the next time you log in.`,
          autoClose: 10000,
        });
      } catch {
        setCodeVerificationError('You entered an invalid code.');
      } finally {
        setCodeVerificationLoading(false);
      }
    }
  };

  const handleResendCodeClick = async () => {
    try {
      await resendOtpSmsVerification({
        value: `+1${formatPhoneForApi(phoneNumber)}`,
        factorType: 'phone',
      });
      notifications.show({
        color: 'green',
        message: `A code was resent to the phone number ending in **${phoneNumber.slice(
          -4,
        )}.`,
        autoClose: 5000,
      });
    } catch {
      setCodeVerificationError('Could not re-send code');
    }
  };

  return (
    <>
      <Stack spacing="xl" className={classes.formSectionBox}>
        <Box>
          <Text fz={20}>Step 1</Text>
          <Text>
            Confirm the phone number for your preferred mobile device. Mobile
            carrier rates may apply.
          </Text>
        </Box>
        <FlexPatternFormat
          format="(###) ###-####"
          label="Phone number"
          w="100%"
          value={phoneNumber}
          onValueChange={(e) => {
            setPhoneInputError('');
            setPhoneNumber(e.formattedValue);
          }}
          error={phoneInputError}
        />
        <Center>
          <Button onClick={handleSendCodeClick} loading={registerFactorLoading}>
            Send Code
          </Button>
        </Center>
      </Stack>
      <Stack spacing="xl" className={classes.formSectionBox}>
        <Box>
          <Text fz={20}>Step 2</Text>
          <Text>Enter the six-digit code sent to the number above.</Text>
        </Box>
        <VerificationCodeInput
          onCodeSubmit={handleCodeSubmit}
          onCodeChange={() => setCodeVerificationError('')}
          onResendCodeClick={handleResendCodeClick}
          error={codeVerificationError}
          showSpinner={codeVerificationLoading}
          w="100%"
        />
      </Stack>
    </>
  );
};
