import { CreditCard } from '@services/flexbase/card.model';
import { useCreditCardsModalFilters } from '../use-credit-cards-filters';
import { useMemo } from 'react';
import { uniq } from 'underscore';
import { MultiSelect } from '@mantine/core';

type Props = { cards: CreditCard[] };

export const CardNameFilter = ({ cards }: Props) => {
  const { addFilter, removeFilter, getFilterByKey } =
    useCreditCardsModalFilters();

  const cardNames = useMemo(() => {
    return uniq(cards.map((d) => d.cardName).filter((name) => !!name));
  }, [cards]);

  return (
    <MultiSelect
      data={cardNames}
      placeholder="Card name"
      searchable
      nothingFound="Nothing found"
      value={getFilterByKey('name')?.filterValue || []}
      onChange={(val) => {
        if (val.length > 0) {
          addFilter('name', {
            key: 'name',
            filterValue: val,
            fn: (row) => val.includes(row.cardName || ''),
            label: `Card name: ${val.join(', ')}`,
            showChip: true,
          });
        } else {
          removeFilter('name');
        }
      }}
    />
  );
};
