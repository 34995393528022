import { UseFormReturnType } from '@mantine/form';
import { useImperativeHandle } from 'react';
import {
  BeneficiaryRequirementsFormRef,
  FieldRecord,
  InternationalPaymentRecipientFormRef,
  InternationalRecipientForm,
} from '../util/types';

type BeneficiaryRequirementsForm = UseFormReturnType<
  FieldRecord,
  (values: FieldRecord) => FieldRecord
>;

type Form = InternationalRecipientForm | BeneficiaryRequirementsForm;

type Ref =
  | React.ForwardedRef<InternationalPaymentRecipientFormRef>
  | React.ForwardedRef<BeneficiaryRequirementsFormRef>;

export const useSubmitByRef = (form: Form, ref: Ref) => {
  const submit = () => {
    const validationResult = form.validate();

    if (validationResult.hasErrors) {
      return null;
    } else {
      return form.values;
    }
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      return submit();
    },
  }));
};
