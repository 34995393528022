import { MantineTheme } from '@mantine/core';
import { StyleSheet, View } from '@react-pdf/renderer';

type Props = {
  theme: MantineTheme;
};

const HeaderPDF = ({ theme }: Props) => {
  const styles = stylesPDF({
    primaryDark: theme.colors.primarySecondarySuccess[8],
    secondaryDark: theme.colors.primarySecondarySuccess[2],
    tertiary: theme.colors.tertiary[2],
  });

  return (
    <View style={styles.topColors}>
      <View style={styles.topPrimaryColor}></View>
      <View style={styles.topSecondaryColor}></View>
      <View style={styles.topTertiaryColor}></View>
    </View>
  );
};

export default HeaderPDF;

type Colors = {
  primaryDark?: string;
  secondaryDark?: string;
  tertiary?: string;
  neutral?: string;
  textColor?: string;
  borderRadius?: number;
};

const stylesPDF = ({ tertiary, primaryDark, secondaryDark }: Colors) => {
  return StyleSheet.create({
    topColors: {
      width: '100%',
      height: '6px',
      marginBottom: 20,
      flexDirection: 'row',
    },

    topPrimaryColor: {
      width: '69%',
      height: '6px',
      backgroundColor: primaryDark,
    },

    topSecondaryColor: {
      width: '15.5%',
      height: '6px',
      backgroundColor: secondaryDark,
    },

    topTertiaryColor: {
      width: '15.5%',
      height: '6px',
      backgroundColor: tertiary,
    },
  });
};
