import { platformClient } from '@services/platform/platform-client';
import { useQuery } from '@tanstack/react-query';

export const useGetUserAccounts = () => {
  return useQuery({
    queryKey: ['userAccounts'],
    queryFn: async () => {
      return await platformClient.getUserAccounts();
    },
    meta: {
      errorMessage: 'Unable to retrieve your accounts',
    },
  });
};
