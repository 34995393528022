import { ReserveTransactions } from '@services/flexbase/banking.model';

export const getFilteredReserveTransactions = (
  transactions: ReserveTransactions[],
  after: string,
  before: string,
) => {
  const mappedTransactions = transactions.map((transaction) => {
    return {
      id: transaction.bankingTransactionId,
      createdAt: transaction.createdAt,
      summary: 'Received ACH',
      direction: 'Credit',
      amount: transaction.amount,
    };
  });

  const fromDate = new Date(Date.parse(after));
  const toDate = new Date(Date.parse(before));

  if (!after || !before) {
    return mappedTransactions;
  }

  return mappedTransactions.filter((transaction) => {
    const transactionDate = new Date(transaction.createdAt);
    return transactionDate >= fromDate && transactionDate <= toDate;
  });
};
