import { Button, Stack, Text, useMantineTheme } from '@mantine/core';
import { Wallet2 } from 'assets/svg';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { IsAdmin } from 'states/application/product-onboarding';

type SpendPlansTabEmptyStateProps = {
  isActiveTab: boolean;
};

export const SpendPlansTabEmptyState = ({
  isActiveTab,
}: SpendPlansTabEmptyStateProps) => {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const isAdmin = useRecoilValue(IsAdmin);

  const handleCreateSpendPlanClick = () => {
    navigate('/spend-plans/new');
  };

  return (
    // TODO: this height calc is not great, find a better way
    <Stack
      bg="#fff"
      mih={'calc(100vh - 185px)'}
      align="center"
      justify="center"
      spacing={24}
      p={24}
      sx={{
        borderColor: theme.fn.themeColor('neutral', 3),
        borderStyle: 'solid',
        borderRadius: theme.radius.xs,
        borderWidth: 1,
      }}
    >
      <Wallet2 />
      {isAdmin ? (
        <>
          <Stack spacing={0} align="center">
            {isActiveTab && (
              <Text
                fz={20}
                fw={500}
                color={theme.fn.themeColor('primarySecondarySuccess', 8)}
              >
                Create your first spend plan
              </Text>
            )}

            <Text fz={14} color={theme.fn.themeColor('neutral', 7)}>
              {isActiveTab
                ? 'Manage your spend and expense on Flex through spend plans'
                : 'Your expired spend plans will show here'}
            </Text>
          </Stack>
          {isActiveTab && (
            <Button
              onClick={handleCreateSpendPlanClick}
              variant="outline"
              leftIcon="+"
            >
              Create New Spend Plan
            </Button>
          )}
        </>
      ) : (
        <Text
          fz={20}
          fw={500}
          color={theme.fn.themeColor('primarySecondarySuccess', 8)}
        >
          You haven&apos;t been added to any spend plans yet.
        </Text>
      )}
    </Stack>
  );
};
