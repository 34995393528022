import useModal from 'components/modal/modal-hook';
import IncomingPayment from '../components/incoming-payment';
import PaymentsRequestTable from '../components/payments-request-table/payments-request-table';
import { Box } from '@mantine/core';

const IncomingPayments = () => {
  const { openTransparentModal } = useModal();

  const openSendPaymentModal = () => {
    openTransparentModal(<IncomingPayment />);
  };

  return (
    <Box style={{ marginTop: '1rem' }}>
      <PaymentsRequestTable {...{ openSendPaymentModal }} />
    </Box>
  );
};

export default IncomingPayments;
