import { Document, Page, PDFViewer, Text, View } from '@react-pdf/renderer';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { useStyles } from './confirmation-receipt.styles';
import { useEffect, useState } from 'react';
import { Loader, useMantineTheme } from '@mantine/core';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ApplicationState } from '../../../states/application/product-onboarding';
import HeaderPDF from 'components/pdf-viewer/header';
import {
  cardPayment,
  sourceCardPaymentAccount,
} from 'areas/payments/components/send-payment/payment.states';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import type {
  PlaidAccount,
  DepositAccount,
} from 'areas/banking/move-funds/move-funds.model';
import FlexLogo from '../../../components/pdf-viewer/flex-logo';

// all acceptable Payment Account types
type PaymentAccount = PlaidAccount | DepositAccount;

// derive a masked account number from any acceptable Payment Account
const getAccountMask = (account: PaymentAccount) => {
  if (account.plaidOrDeposit === 'plaid') {
    return getPaddedAccountMask(account.last4 ?? account.account, 4);
  }

  return getPaddedAccountMask(account.accountNumber, 4);
};

// derive an account name from the Payment Account
const getName = (account: PaymentAccount) => {
  if (account.plaidOrDeposit === 'plaid') {
    return account.bankName;
  }

  return account.nickName ?? account.name;
};

// derive a processing time from the Payment Account
const getProcessingMessage = (account: PaymentAccount) => {
  if (account.plaidOrDeposit === 'deposit') {
    return 'Payment will successfully be posted on your account in 3-5 days depending on your external banking institution.';
  }

  return 'Processing of your payment may take up to 4 business days, after which your credit balance will be updated.';
};

const ConfirmationReceipt = () => {
  const { paymentId } = useParams();
  const theme = useMantineTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [payment, setPayment] = useRecoilState(cardPayment);
  const paymentAccount = useRecoilValue(sourceCardPaymentAccount);

  const styles = useStyles({
    primaryDark: theme.colors.primarySecondarySuccess[8],
    neutral: theme.colors.neutral[2],
    textColor: theme.colors.neutral[7],
    borderRadius: 10,
  });

  const { company } = useRecoilValue(ApplicationState);

  const getPayment = async (_paymentId: string) => {
    setIsLoading(true);
    try {
      const { payment: paymentResponse } =
        await flexbaseOnboardingClient.getPaymentById(_paymentId);
      setPayment(paymentResponse);
    } catch (e) {
      console.error('confirmation-receipt-pdf.tsx', e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (paymentId) {
      getPayment(paymentId);
    }
  }, [paymentId]);

  if (isLoading) {
    return (
      <div
        style={{
          minHeight: '92.5vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loader color={theme.fn.primaryColor()} />
      </div>
    );
  }

  return (
    <PDFViewer style={{ width: '100%', height: '100vh' }}>
      <Document
        title={`Flex Credit Payment Receipt ${DateTime.fromSQL(
          payment?.createdAt || '',
        ).toFormat('MM-yy')}`}
      >
        <Page wrap size="A4">
          <View fixed>
            <HeaderPDF {...{ theme }} />
          </View>
          <View style={{ padding: '0px 25px' }}>
            <View style={styles.headerRow}>
              <View style={styles.containerHeader}>
                <View>
                  <Text
                    style={{
                      ...styles.textHeader,
                      fontStyle: 'bold',
                    }}
                  >
                    Flex Credit Card
                  </Text>
                  <Text style={styles.textHeader}>{company?.companyName}</Text>
                  <Text style={styles.textHeader}>
                    {company?.address.line1}, {company?.address.line2}
                  </Text>
                  <Text style={styles.textHeader}>
                    {company?.address.city}, {company?.address.state} -{' '}
                    {company?.address.postalCode}
                  </Text>
                </View>
              </View>
              <View>
                <View>
                  <FlexLogo {...{ theme }} />
                </View>
                <Text
                  style={{
                    ...styles.text,
                    fontSize: '12px',
                    color: theme.colors.neutral[6],
                  }}
                >
                  {`Time initiated: ${
                    payment?.createdAt
                      ? DateTime.fromSQL(payment.createdAt).toFormat('ff')
                      : ''
                  }`}
                </Text>
                <Text
                  style={{
                    ...styles.text,
                    fontSize: '18px',
                  }}
                >
                  Flex Credit Payment Receipt
                </Text>
              </View>
            </View>
            <View style={styles.cardReceiptContainer}>
              <Text style={styles.text}>
                Payment towards your credit balance
              </Text>
              <Text
                style={{
                  ...styles.text,
                  fontSize: '36px',
                  marginTop: 10,
                }}
              >
                {formatCurrency(
                  payment?.amount ? Math.abs(+payment.amount) : 0,
                )}
              </Text>
            </View>
            {paymentAccount && (
              <View style={styles.cardSentFromContainer}>
                <Text
                  style={{
                    ...styles.text,
                    fontStyle: 'normal',
                    color: theme.colors.primarySecondarySuccess[2],
                  }}
                >
                  Sent from
                </Text>
                <Text
                  style={{
                    ...styles.text,
                    marginTop: 10,
                    color: theme.colors.neutral[6],
                  }}
                >
                  Account
                </Text>
                <Text style={{ ...styles.text, marginTop: 2 }}>
                  {`${getName(paymentAccount)} / ${
                    paymentAccount.accountType
                  } ${getAccountMask(paymentAccount)}`}
                </Text>
                <Text
                  style={{
                    ...styles.text,
                    fontSize: '9px',
                    color: theme.colors.neutral[6],
                    marginTop: 10,
                  }}
                >
                  {getProcessingMessage(paymentAccount)}
                </Text>
              </View>
            )}
          </View>

          <View style={styles.footerContainer} fixed>
            <View>
              <Text>Flexbase Technologies, Inc. </Text>
              <Text>390 NE 191st St #8019 Miami, FL 33179</Text>
            </View>
            <View>
              <Text style={{ alignSelf: 'flex-end' }}>Need help?</Text>
              <Text>support@flex.one or call 415-840-8721</Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default ConfirmationReceipt;
