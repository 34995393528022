import { useRecoilValue } from 'recoil';
import { Image, Divider, Button, Radio, Group } from '@mantine/core';

import { useStyles } from '../cancel-debit-card-styles';
import { CardByUser } from 'services/flexbase/banking.model';
import { UserIdState } from 'areas/onboarding/onboarding-form.state';
import TwoFactorAuthSection from '../../../two-factor/two-factor-auth-section';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';

type Props = {
  card: CardByUser;
  loading: boolean;
  needsAuth: boolean;
  onBack: () => void;
  issueNewCard: string;
  nextStep: () => void;
  setIssueNewCard: (x: string) => void;
};

const ConfirmCancellation = ({
  card,
  loading,
  needsAuth,
  issueNewCard,
  onBack,
  nextStep,
  setIssueNewCard,
}: Props) => {
  const { classes } = useStyles();
  const userId = useRecoilValue(UserIdState);

  return (
    <div>
      <span className={classes.title}>
        Are you sure you want to cancel this card?
      </span>
      <p>This cannot be undone.</p>

      <Divider my={20} />
      <div className={classes.debitCardInfoContent}>
        <Image mr={20} width={100} src="/images/back-debit-card.png" />
        <div>
          <span>{card.holder}</span>
          <p>Physical debit card {getPaddedAccountMask(card.cardNumber, 2)}</p>
        </div>
      </div>
      <Divider my={20} />

      <div>
        <Radio.Group
          mt={20}
          value={issueNewCard}
          onChange={setIssueNewCard}
          label={
            card.userId === userId
              ? 'Do you want a new card issued?'
              : `Do you want to issue a new card to ${card.holder}?`
          }
          description="This will cancel the current card and issue a new one."
          classNames={{
            label: classes.radioLabel,
            description: classes.radioDescription,
          }}
        >
          <Group mt={12}>
            <Radio value="yes" label="Yes" />
            <Radio value="no" label="No" />
          </Group>
        </Radio.Group>
      </div>

      {needsAuth && <TwoFactorAuthSection onSuccess={nextStep} />}

      <div className={classes.buttonsContainer}>
        <Button mr={12} variant="outline" onClick={onBack}>
          Go back
        </Button>
        <Button {...{ loading }} variant="light" onClick={nextStep}>
          Cancel card
        </Button>
      </div>
    </div>
  );
};

export default ConfirmCancellation;
