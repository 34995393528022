import { ApplicationConfigBuilder } from './product-onboarding.models';

const configBuilder = new ApplicationConfigBuilder(['CREDIT', 'BANKING'])
  .forRequirementArray('requiredCredit')
  .forRequirementArray('requiredBanking');
configBuilder
  .addRoute('change-password', 1, false)
  .withRequiredField('user.changePassword');
configBuilder
  .addRoute('verify-phone', 2, false)
  .withRequiredField('user.phone')
  .withRequiredField('user.cellPhone');
configBuilder
  .addRoute('business-type', 3, true)
  .withRequiredField('company.legalStructure');
configBuilder
  .addRoute('user-address', 4, false)
  .withRequiredField('user.address');
configBuilder
  .addRoute('verify-identity', 5, false)
  .withRequiredField('user.taxId')
  .withRequiredField('user.birthDate')
  .withRequiredField('user.ficoPullSigned')
  .withRequiredField('user.patriotActSigned');
configBuilder
  .addRoute('job-title', 6, false)
  .withRequiredField('user.jobTitle');
configBuilder
  .addRoute('business-activity', 7, true)
  .withRequiredField('company.companyName')
  .withRequiredField('company.website')
  .withRequiredField('company.annualRevenue')
  .withRequiredField('company.businessPurpose')
  .withRequiredField('company.category')
  .withRequiredField('company.monthlyExpenditure')
  .withRequiredField('company.businessVertical');
configBuilder
  .addRoute('verify-business', 8, true)
  .withRequiredField('company.taxId')
  .withRequiredField('company.formationDate')
  .withRequiredField('company.phone')
  .withRequiredField('company.address');
configBuilder
  .addRoute('business-owners', 9, true)
  .withRequiredField('company.owners');
configBuilder
  .addRoute('control-person', 10, true)
  .withRequiredField('company.controlPerson');
configBuilder
  .addRoute('verify-bank', 11, true)
  .withRequiredField('user.plaidConnection')
  .withRequiredField('company.financialInstitutions');
configBuilder
  .addRoute('summary', 12, false)
  .withRequiredField('user.termsOfServiceSigned')
  .withRequiredField('user.creditTermsOfServiceSigned')
  .withRequiredField('user.bankingTermsOfServiceSigned')
  .withRequiredField('user.personalGuarantySigned');

export const AllProductsApplicationConfig = configBuilder.build();
