import '@mercoa/react/dist/style.css';
import { useMediaQuery } from '@mantine/hooks';
import { EntityInboxEmail } from '@mercoa/react';
import { Box, Group, Divider, TextInput } from '@mantine/core';
import { SearchIcon } from 'assets/svg';
import BillPayActionButton from './action-button';
import { FilterData } from '@common/filter/filters';
import { useBillPayStyles } from '../billing.styles';
import { InvoiceTableData } from '@services/flexbase/billing.model';
import { useRecoilValue } from 'recoil';
import { IsAdmin } from 'states/application/product-onboarding';

type BillPayHeaderProps = {
  mercoaToken: string;
  searchValue: string;
  removeFilter: (key: string) => void;
  addFilter: (key: string, filter: FilterData<InvoiceTableData>) => void;
};

const BillPayHeader = ({
  mercoaToken,
  searchValue,
  addFilter,
  removeFilter,
}: BillPayHeaderProps) => {
  const { classes, theme } = useBillPayStyles();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const isAdmin = useRecoilValue(IsAdmin);

  const handleSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }

    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (invoice) => {
        const normalizedFilterText = value.toLowerCase().trim();

        const strValues = Object.values(invoice).filter(
          (v) => !!v && typeof v === 'string',
        ) as string[];

        return strValues
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  return (
    <Group mb="xl" position="apart">
      <TextInput
        value={searchValue}
        placeholder="Search bills"
        w={useMobileView ? '100%' : 368}
        icon={<SearchIcon width={20} height={20} />}
        onChange={(e) => handleSearchChange(e.target.value)}
      />

      <Group>
        <Box className={classes.emailContainer}>
          <EntityInboxEmail layout="left" />
        </Box>

        {!useMobileView && (
          <Divider orientation="vertical" color={theme.colors.neutral[3]} />
        )}
        {isAdmin && (
          <BillPayActionButton
            fullWidth={!!useMobileView}
            {...{ mercoaToken }}
          />
        )}
      </Group>
    </Group>
  );
};

export default BillPayHeader;
