import { isArray, isEmpty, isObject } from 'underscore';

type FormatterFn = (value: any) => any;
type Formatter<T> = { field: T; formatterFn: FormatterFn };

function clean(element: any): boolean {
  if (typeof element === 'boolean' || typeof element === 'number') {
    return true;
  }

  if (typeof element === 'string') {
    return element.length > 0;
  }

  return element != null && !isEmpty(element);
}

function removeEmpty<T>(obj: T): T {
  return isObject(obj)
    ? Object.entries(obj)
        .filter(([, value]) => clean(value))
        .reduce((acc, [key, value]) => {
          if (isArray(value)) {
            return { ...acc, [key]: value.map((v) => removeEmpty(v)) };
          }

          if (isObject(value) && !isEmpty(value)) {
            return { ...acc, [key]: removeEmpty(value) };
          }

          if (clean(value)) {
            return { ...acc, [key]: value };
          }

          return { ...acc };
        }, {} as T)
    : obj;
}

export function stripAndFormat<T>(
  intermediate: T,
  formatters?: Formatter<keyof T>[],
): T {
  const unformatted = removeEmpty(intermediate);

  formatters?.forEach(({ field, formatterFn }) => {
    const formattedValue = formatterFn(unformatted[field]);
    if (formattedValue) {
      unformatted[field] = formattedValue;
    } else {
      delete unformatted[field];
    }
  });

  return unformatted;
}
