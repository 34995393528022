import { SimpleSegmentSelectedFilter } from '@common/filter/simple-segmented.filter';
import { useCreditTransactionModalFilters } from '../use-credit-transaction-filters';

const STATUS_MULTISELECT_OPTIONS = [
  {
    label: 'Pending',
    filterCriteria: ['pending', 'initiated'],
    key: 'pending',
  },
  {
    label: 'Settled',
    filterCriteria: ['settled', 'succeeded'],
    key: 'settled',
  },
  { label: 'Declined', filterCriteria: 'declined', key: 'declined' },
  {
    label: 'Failed',
    filterCriteria: ['canceled', 'failed', 'chargeback'],
    key: 'failed',
  },
  {
    label: 'Disputed',
    filterCriteria: ['disputed'],
    key: 'disputed',
  },
];

export const CreditStatusFilter = () => {
  const creditTransactionFilters = useCreditTransactionModalFilters();

  return (
    <SimpleSegmentSelectedFilter
      filterHook={creditTransactionFilters}
      filterKey="status"
      options={STATUS_MULTISELECT_OPTIONS}
      label="Status"
      includesValue={(row) => row.status}
    />
  );
};
