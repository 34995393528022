import { Notification, NotificationCategory } from '../notifications';
import { capitalizeOnlyFirstLetter } from 'utilities/formatters/format-strings';
import { useStyles } from '../notifications.styles';

type Props = {
  notificationData: NotificationCategory[];
  generateNotificationControl: (notification: Notification) => JSX.Element;
  filteredCategories: string[];
};

const TabInfo = ({
  notificationData,
  generateNotificationControl,
  filteredCategories,
}: Props) => {
  const { classes } = useStyles();
  return (
    <div>
      {notificationData
        .filter((category) => {
          return filteredCategories.some((option) =>
            category.name.includes(option),
          );
        })
        .map((category) => (
          <div key={category.name} className={classes.category}>
            <div className={classes.categoryHeader}>
              <div className={classes.categoryName}>
                {capitalizeOnlyFirstLetter(category.name)}
              </div>
              <div className={classes.categoryScope}>{category.scope}</div>
            </div>
            <div>
              <div>
                {category.notifications.map((notification) => (
                  <div key={notification.eventName}>
                    {generateNotificationControl(notification)}
                    {notification.subNotifications &&
                      notification.subNotifications.length > 0 && (
                        <div>
                          {notification.subNotifications?.map(
                            (subNotification) =>
                              generateNotificationControl(subNotification),
                          )}
                        </div>
                      )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default TabInfo;
