// convert a number to currency with short codes
export const convertToCurrency = (value: number, currencyType = 'en') => {
  const formatter = Intl.NumberFormat(currencyType);
  return formatter.format(value);
};

// shorten labels to a specific length and add ...
export const shortenLabelName = (value: string, n: number) => {
  value = value
    .split('_')
    .map((v) => {
      return v.charAt(0).toUpperCase() + v.slice(1);
    })
    .join(' ');
  return value.length > n ? value.slice(0, n - 1) + '...' : value;
};
