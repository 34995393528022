import { useEffect } from 'react';

export function useKeyPress(eventKey: string, onKeyPress: () => void) {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === eventKey) {
        onKeyPress();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [eventKey, onKeyPress]);
}
