import { QueryClient, QueryKey } from '@tanstack/react-query';

/**
 * This function will find all queries matching the query key you pass in, and then
 * update them with the update function.
 * @param queryClient - The React Query QueryClient
 * @param queryKey - A key that you want to match. Can be a partial key: ["my_key"] instead of ["my_key", "123", "complex"]
 * @param updater - A function applied to every matching query to set the query data. As input it receives the old data and returns the new data
 * @param queryKeyMatcher An optional argument that is used on the result of the queryKey search to further filter the results.
 */
export function updateQueryData<TData>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  updater: (data: TData | undefined) => TData | undefined,
  queryKeyMatcher?: (key: QueryKey) => boolean,
) {
  const result = queryClient.getQueriesData({
    queryKey,
  });

  const queriesToUpdate = queryKeyMatcher
    ? result.filter((key) => queryKeyMatcher(key))
    : result;

  queriesToUpdate.forEach(([key]) => {
    queryClient.setQueryData<TData>(key, updater);
  });
}
