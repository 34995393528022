import { Text } from '@mantine/core';
import { useDetailsStyles } from './details-styles';
import DetailsHeader from '../details-header/details-header';
import { useGetTenat } from '@queries/use-tenant';
import { formatCurrency } from 'utilities/formatters/format-currency';

type Props = {
  createdAt: string;
  total: string;
  type: string;
  status: string;
  closeModal: () => void;
};

const InterestDetails = ({
  total,
  createdAt,
  closeModal,
  status,
  type,
}: Props) => {
  const { classes } = useDetailsStyles();

  const { data } = useGetTenat();

  const x = data?.aprPct;
  const y: number = x ? +x : 0;

  const aprPct = (Math.round(y * 100) / 100).toFixed(2);

  return (
    <div className={classes.baseContainer}>
      <DetailsHeader
        closeModal={closeModal}
        {...{ createdAt, total, type, status }}
      />
      <div className={classes.section}>
        <div className={classes.infoRow}>
          <div>
            <Text className={classes.infoTitle}>Interest rate</Text>
            <Text className={classes.infoValue}>{aprPct} %</Text>
          </div>
          <div>
            <Text className={classes.infoTitle}>Amount due</Text>
            <Text className={classes.infoValue}>{formatCurrency(total)}</Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterestDetails;
