import { DateTime } from 'luxon';
import {
  AuthenticationTokenModel,
  IsSuccessResponse,
  PlatformToken,
} from '@services/platform/models/authorize.models';
import jwt_decode from 'jwt-decode';
import { platformAuthClient } from '@services/platform/platform-auth-client';
import {
  retrieveTokenFromLocalStorage,
  storeToken,
} from '@utilities/auth/store-token';

let refreshExecuted = false;
let refreshPromise: Promise<boolean> | null = null;

const refreshAndStoreToken = async (
  token?: AuthenticationTokenModel,
  ignoreExpiration?: boolean,
): Promise<boolean> => {
  if (!token) {
    return false;
  }

  const decodedToken = jwt_decode<PlatformToken>(token.access_token);

  const expirationTime = DateTime.fromSeconds(decodedToken.exp!);
  const diff = expirationTime.diffNow('seconds').seconds;
  const shouldRefresh = ignoreExpiration || diff < 180;

  if (shouldRefresh && !refreshExecuted) {
    try {
      refreshExecuted = true;

      const refreshToken = token.refresh_token ?? token.access_token;

      const refreshTokenResponse =
        await platformAuthClient.requestTokenByRefresh(refreshToken);

      if (
        IsSuccessResponse(refreshTokenResponse) &&
        refreshTokenResponse.body
      ) {
        const responseBody = refreshTokenResponse.body;
        storeToken(responseBody);
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(`Error refreshing token: ${e}`);
      return false;
    } finally {
      refreshExecuted = false;
      refreshPromise = null;
    }
  } else {
    return true;
  }
};

export const refreshAndStoreTokenFromUser = async (
  ignoreExpiration?: boolean,
) => {
  const fullToken = retrieveTokenFromLocalStorage();

  if (!fullToken) {
    return false;
  }

  try {
    if (refreshExecuted) {
      return await refreshPromise;
    }

    refreshPromise = (async () => {
      return await refreshAndStoreToken(fullToken, ignoreExpiration);
    })();

    return await refreshPromise;
  } catch (e) {
    console.error(`Error refreshing token: ${e}`);
    return false;
  }
};
