import { useIntlPaymentsFeatureFlag } from '@utilities/feature-flags';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'states/application/product-onboarding';

export const useHasIntlWires = () => {
  const { productStatus } = useRecoilValue(ApplicationState);
  const intlPaymentsFeatureFlagIsOn = useIntlPaymentsFeatureFlag();

  /** internationalPayments.appStatus could be 'approved' but
   * internationalPayments.status could be 'incomplete' if some
   * new requirements need to be provided
   */
  const isApprovedApplicationForIntlWires =
    productStatus.internationalPayments.appStatus === 'approved';
  const hasIntlWiresProduct =
    productStatus.internationalPayments.status === 'approved';
  const hasIntlWires =
    intlPaymentsFeatureFlagIsOn &&
    isApprovedApplicationForIntlWires &&
    hasIntlWiresProduct;

  return hasIntlWires;
};
