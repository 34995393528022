import { createFormContext } from '@mantine/form';
import { useEffect } from 'react';

type TitleFormValues = {
  title: string;
};

export const [FormProvider, useTitleFormContext, useTitleForm] =
  createFormContext<TitleFormValues>();

type ContextProps = {
  children: React.ReactNode;
  currentTitle: string;
};

export function TitleFormProvider({ children, currentTitle }: ContextProps) {
  const form = useTitleForm({
    initialValues: {
      title: '',
    },
    transformValues: (values) => ({
      title: values.title.trim(),
    }),
  });

  useEffect(() => {
    form.setValues({ title: currentTitle });
  }, [currentTitle]);

  return <FormProvider form={form}>{children}</FormProvider>;
}
