import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  actionBtn: {
    backgroundColor: '#fff',
    color: theme.fn.themeColor(theme.primaryColor, 8),
    borderColor: theme.fn.themeColor('neutral', 3),
    display: 'inline-flex',
    gap: theme.spacing.xs,

    '&[data-disabled]': {
      backgroundColor: theme.fn.themeColor('neutral', 0),
      color: theme.fn.themeColor('neutral', 5),
    },
  },
}));
