import { useState } from 'react';
import {
  Divider,
  Image,
  createStyles,
  Flex,
  Button,
  Text,
} from '@mantine/core';
import useModal from '@common/modal/modal-hook';
import { showNotification } from '@mantine/notifications';
import { CardByUser } from '@services/flexbase/banking.model';
import { useUpdateDebitCardStatus } from '@queries/use-debit-cards';
import TwoFactorAuthSection from '../../two-factor/two-factor-auth-section';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';

type Props = {
  card: CardByUser;
  setCardStatus: (x: string) => void;
};

const CancelVirtualCard = ({ card, setCardStatus }: Props) => {
  const { classes, theme } = useStyles();
  const { closeAllModals } = useModal();
  const [needsAuth, setNeedsAuth] = useState(false);
  const { mutate, isPending } = useUpdateDebitCardStatus();

  const updateCardStatus = () => {
    mutate(
      {
        cardId: card.id,
        status: 'cancelled',
      },
      {
        onSuccess: (data) => {
          const cardUpdated = data.card;
          closeAllModals();
          setCardStatus(cardUpdated.status);
          showNotification({
            color: 'flexbase-teal',
            message: `The card has been cancelled`,
          });
        },
        onError: (error) => {
          console.error('Error updating card status', error);
          const errorMessages = [
            'a Customer Token is required',
            'Bearer token is invalid or expired',
          ];
          const { error: errorMsg = '' } = error
            ? JSON.parse(error.message)
            : {};
          if (errorMessages.some((message) => errorMsg.includes(message))) {
            setNeedsAuth(true);
          } else {
            showNotification({
              title: 'Error',
              message: `Unable to cancel the card`,
              color: 'red',
            });
          }
        },
      },
    );
  };

  return (
    <>
      <Text size={14}>This cannot be undone.</Text>
      <Divider my={20} />
      <div className={classes.debitCardInfoContent}>
        <Image mr={20} width={100} src="/images/back-debit-card.png" />
        <div>
          <Text color={theme.fn.primaryColor()}>{card.holder}</Text>
          <Text color="neutral.6">
            Virtual debit card {getPaddedAccountMask(card.cardNumber, 2)}
          </Text>
        </div>
      </div>
      {needsAuth && <TwoFactorAuthSection onSuccess={updateCardStatus} />}
      <Divider my={20} />

      <Flex gap="lg" justify="flex-end">
        <Button variant="outline" onClick={closeAllModals}>
          Go back
        </Button>
        <Button
          variant="filled"
          loading={isPending}
          disabled={needsAuth}
          onClick={updateCardStatus}
        >
          Cancel card
        </Button>
      </Flex>
    </>
  );
};

export default CancelVirtualCard;

export const useStyles = createStyles(() => ({
  debitCardInfoContent: {
    display: 'flex',
    alignItems: 'center',
  },
}));
