import { useQuery } from '@tanstack/react-query';
import { platformClient } from '@services/platform/platform-client';

const QUERY_KEY = 'prefill';

const personPrefillQueryFn = async (
  accountId?: string,
  personId?: string,
  dateOfBirth?: string,
) => {
  if (dateOfBirth && accountId && personId) {
    return await platformClient.prefillPerson(accountId, personId, dateOfBirth);
  }
};

export const usePersonPrefill = (
  accountId?: string,
  personId?: string,
  dateOfBirth?: string,
) => {
  return useQuery({
    queryKey: [QUERY_KEY, 'person-prefill', accountId, personId, dateOfBirth],
    enabled: !!dateOfBirth && !!accountId && !!dateOfBirth,
    queryFn: () => personPrefillQueryFn(accountId, personId, dateOfBirth),
    staleTime: Infinity, // It is dangerous to run this query more than once, since it has the side effect of automatically updating values with prefilled data. So if someone edits, and then this query runs again, their edits are wiped out.
    retry: false, // failure is terminal for person prefill.
    refetchOnWindowFocus: false,
  });
};

export const useBusinessPrefill = (
  accountId?: string,
  personId?: string,
  personTaxId?: string,
) => {
  return useQuery({
    queryKey: [QUERY_KEY, 'business-prefill', accountId, personId, personTaxId], // Don't really need taxId as part of the query key, but eslint complains if it isn't.
    enabled: !!personTaxId && !!accountId && !!personId,
    queryFn: async () => {
      if (personTaxId && accountId && personId) {
        return await platformClient.getBusinessPrefill(accountId, personId);
      }
    },
    staleTime: Infinity, // this one doesn't have any side effects, but Prove calls cost money so
  });
};
