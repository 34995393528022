import { PropsWithChildren } from 'react';
import { Box, Button, Flex, Stack, rem } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { DisconnectButton } from './disconnect-button';
import { useIntegrationSettingsMobile } from '../utils/use-integration-settings-mobile';

type LayoutProps<T> = PropsWithChildren<{
  form: UseFormReturnType<T>;
  saving?: boolean;
  disconnecting?: boolean;
  platformLabel: string;
  onSave: () => void;
  onDisconnect: () => void;
}>;

export const IntegrationsSettingsLayout = function <T = any>({
  children,
  form,
  platformLabel,
  saving = false,
  disconnecting = false,
  onSave,
  onDisconnect,
}: LayoutProps<T>) {
  const isMobile = useIntegrationSettingsMobile();

  return (
    <Box>
      <Stack spacing="2rem" mb="2rem">
        {children}
      </Stack>

      <Flex
        sx={(theme) => ({
          [theme.fn.smallerThan('sm')]: {
            alignItems: 'end',
            flexDirection: 'column-reverse',
            gap: rem(24),
          },
          flexDirection: 'row',
          justifyContent: 'space-between',
        })}
      >
        <DisconnectButton
          disabled={saving || disconnecting}
          loading={disconnecting}
          onClick={onDisconnect}
        >
          {isMobile ? `Disconnect` : `Disconnect from ${platformLabel}`}
        </DisconnectButton>

        <Button
          disabled={!form.isDirty()}
          loading={saving || disconnecting}
          onClick={onSave}
        >
          Save preference
        </Button>
      </Flex>
    </Box>
  );
};
