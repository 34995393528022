import { createContext, ReactNode, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type RouteSection = 'auth' | 'application' | 'main';
type RouteSectionContextReturnType = {
  showRoutesFor: RouteSection;
  setShowRoutesFor: (section: RouteSection) => void;
  referrer: string | null;
  setReferrer: (referrer: string | null) => void;
  setSectionAndNavigate: (section: RouteSection, url: string) => void;
};
const RouteSectionContext = createContext<RouteSectionContextReturnType>({
  showRoutesFor: 'auth',
  referrer: null,
} as RouteSectionContextReturnType);

type RouteProviderProps = { children: ReactNode };
export const RouteSectionProvider = ({ children }: RouteProviderProps) => {
  const navigate = useNavigate();
  const [showRoutesFor, setShowRoutesFor] = useState<
    'auth' | 'application' | 'main'
  >('auth');
  const [referrer, setReferrer] = useState<string | null>('');

  const setSectionAndNavigate = (section: RouteSection, url: string) => {
    setShowRoutesFor(section);
    navigate(url);
  };

  return (
    <RouteSectionContext.Provider
      value={{
        showRoutesFor,
        setShowRoutesFor,
        referrer,
        setReferrer,
        setSectionAndNavigate,
      }}
    >
      {children}
    </RouteSectionContext.Provider>
  );
};

export const useRouteSectionContext = () => {
  return useContext(RouteSectionContext);
};
