import { useRecoilValue } from 'recoil';
import { useQueries } from '@tanstack/react-query';
import { flexbaseBankingClient } from '@services/flexbase-client';
import { useGetPaymentInfo } from '@queries/use-payments';
import { ApplicationState } from 'states/application/product-onboarding';
import { fetchConversionRate } from '@queries/use-international-payments';
import {
  Counterparty,
  DepositAccount,
  IntlPayment,
} from '@services/flexbase/banking.model';
import {
  ParsedBeneficiary,
  parseBeneficiary,
} from '@utilities/custom-hooks/use-beneficiaries';

const DEPOSIT_ACCOUNT_QUERY_KEY = 'bankingDepositAccount';
const COUNTERPARTY_QUERY_KEY = 'counterpartyInfo';
const INTL_PAYMENT_QUERY_KEY = 'internationalPaymentInfo';
const BENEFICIARY_QUERY_KEY = 'beneficiaryInfo';
const CURRENCY_RATE_QUERY_KEY = 'currencyRate';

export type AccInfo = {
  name: string;
  bank?: string;
  routingNumber: string;
  accountNumber: string;
};

const usePaymentReceipt = (paymentId: string) => {
  const { company } = useRecoilValue(ApplicationState);

  const {
    data: paymentInfo,
    isError: isPaymentInfoError,
    isLoading: isLoadingPaymentInfo,
  } = useGetPaymentInfo(paymentId || '');

  const domesticPaymentData = useQueries({
    queries: [
      {
        queryKey: [DEPOSIT_ACCOUNT_QUERY_KEY, paymentInfo?.depositIdTo],
        queryFn: async () => {
          const resp = await flexbaseBankingClient.getDepositAccount(
            paymentInfo?.depositIdTo || '',
          );
          return resp.account;
        },
        enabled: !!paymentInfo?.depositIdTo,
      },
      {
        queryKey: [COUNTERPARTY_QUERY_KEY, paymentInfo?.counterpartyId],
        queryFn: async () => {
          const resp = await flexbaseBankingClient.getCounterParty(
            paymentInfo?.counterpartyId || '',
          );
          return resp.counterparty;
        },
        enabled: !!paymentInfo?.counterpartyId,
      },
      {
        queryKey: [INTL_PAYMENT_QUERY_KEY, paymentInfo?.internationalPaymentId],
        queryFn: async () => {
          const resp = await flexbaseBankingClient.getInternationalPaymentInfo(
            paymentInfo?.internationalPaymentId || '',
          );
          return resp.payment;
        },
        enabled: !!paymentInfo?.internationalPaymentId,
      },
    ],
    combine: (queriesResults) => {
      return {
        data: queriesResults.map((result) => result.data),
        isError: queriesResults.map((result) => result.isError),
        isLoading: queriesResults.map((result) => result.isLoading),
      };
    },
  });

  const depositAccountData = domesticPaymentData.data[0] as DepositAccount;
  const counterpartyData = domesticPaymentData.data[1] as Counterparty;
  const intlPayment = domesticPaymentData.data[2] as IntlPayment;
  const getPlaidAccountInfo = (plaidAccountId: string) => {
    const plaidAccount =
      company.financialInstitutions.find((acc) => acc.id === plaidAccountId) ??
      null;
    return {
      name: paymentInfo?.payCtrParty || '',
      bank: plaidAccount?.bankName || '',
      routingNumber: plaidAccount?.routing || '',
      accountNumber: plaidAccount?.account || '',
    };
  };

  const intlPaymentData = useQueries({
    queries: [
      {
        queryKey: [BENEFICIARY_QUERY_KEY, company.id],
        queryFn: async () => {
          const resp = await flexbaseBankingClient.getBeneficiaries({
            companyId: company.id,
            status: 'Approved',
          });
          const beneficiaries = resp.beneficiaries.map(parseBeneficiary);
          return beneficiaries.find((b) => b.id === intlPayment?.beneficiaryId);
        },
        enabled: !!intlPayment?.beneficiaryId,
      },
      {
        queryKey: [
          CURRENCY_RATE_QUERY_KEY,
          intlPayment?.ccResponse?.currency,
          intlPayment?.amount,
        ],
        queryFn: () =>
          fetchConversionRate({
            toCurrency: intlPayment?.ccResponse?.currency,
            amount: parseFloat(intlPayment?.amount),
          }),
        enabled: !!intlPayment?.ccResponse?.currency && !!intlPayment?.amount,
      },
    ],
    combine: (queriesResults) => {
      return {
        data: queriesResults.map((result) => result.data),
        isError: queriesResults.map((result) => result.isError),
        isLoading: queriesResults.map((result) => result.isLoading),
      };
    },
  });

  const beneficiary = intlPaymentData.data[0] as ParsedBeneficiary;
  const currencyConversionRateData = intlPaymentData.data[1] as {
    rate: number;
    amount: string;
  };

  const isLoading =
    isLoadingPaymentInfo ||
    [...domesticPaymentData.isLoading, ...intlPaymentData.isLoading].some(
      (loading) => loading,
    );
  const isError =
    isPaymentInfoError ||
    [...domesticPaymentData.isError, ...intlPaymentData.isError].some(
      (error) => error,
    );

  const getRecipient = () => {
    // internal transfer flex to flex
    if (paymentInfo?.type === 'book' && paymentInfo?.depositIdTo) {
      return depositAccountData as AccInfo;
      // moving money to external party
    } else if (paymentInfo?.counterpartyId) {
      return counterpartyData as AccInfo;
      // moving money to Plaid account
    } else if (paymentInfo?.type === 'ach' && paymentInfo?.plaidTokenId) {
      return getPlaidAccountInfo(paymentInfo.plaidTokenId);
    } else {
      return null;
    }
  };

  const recipient = getRecipient();

  return {
    recipient,
    paymentInfo,
    intlPayment,
    beneficiary,
    currencyConversionRateData,
    isError,
    isLoading,
  };
};

export default usePaymentReceipt;
