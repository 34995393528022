import { Box, Text, Group } from '@mantine/core';
import BillCard from './bill-card';
import { Bill, filterBills, totalAmountBills, totalCountBills } from './utils';

const BillsToPay = ({ bills }: { bills: Bill[] }) => {
  const billsFilteredByDueDates = {
    overdue: {
      totalAmount: totalAmountBills(filterBills(bills, 'overdue')),
      totalCount: totalCountBills(filterBills(bills, 'overdue')),
    },
    dueInTheNext7Days: {
      totalAmount: totalAmountBills(filterBills(bills, 'dueInTheNext7Days')),
      totalCount: totalCountBills(filterBills(bills, 'dueInTheNext7Days')),
    },
    dueInMoreThan7Days: {
      totalAmount: totalAmountBills(filterBills(bills, 'dueInMoreThan7Days')),
      totalCount: totalCountBills(filterBills(bills, 'dueInMoreThan7Days')),
    },
  };

  const upcomingBillsCount =
    billsFilteredByDueDates.dueInTheNext7Days.totalCount +
    billsFilteredByDueDates.dueInMoreThan7Days.totalCount +
    billsFilteredByDueDates.overdue.totalCount;

  return (
    <Box c="primarySecondarySuccess.8">
      <Text>Bills to pay ({upcomingBillsCount})</Text>

      <Group grow mt="xl">
        <BillCard
          headingText="Overdue"
          textAmountColor="critical.6"
          backgroundColor="critical.0"
          totalCount={billsFilteredByDueDates.overdue.totalCount}
          totalAmount={billsFilteredByDueDates.overdue.totalAmount}
        />
        <BillCard
          headingText="Due in 7 days"
          textAmountColor="tertiary.6"
          backgroundColor="tertiary.0"
          totalCount={billsFilteredByDueDates.dueInTheNext7Days.totalCount}
          totalAmount={billsFilteredByDueDates.dueInTheNext7Days.totalAmount}
        />
        <BillCard
          headingText="Due in 7+ days"
          textAmountColor="neutral.7"
          totalCount={billsFilteredByDueDates.dueInMoreThan7Days.totalCount}
          totalAmount={billsFilteredByDueDates.dueInMoreThan7Days.totalAmount}
        />
      </Group>
    </Box>
  );
};

export default BillsToPay;
