import { useCreditTransactionModalFilters } from '../use-credit-transaction-filters';

import { DateRangeFilter } from '@common/filter/date-range.filter';

export const CreditDateRangeFilter = () => {
  const creditTransactionFilters = useCreditTransactionModalFilters();
  return (
    <DateRangeFilter
      filterHook={creditTransactionFilters}
      filterFn={() => true}
    />
  );
};
