import { LoginFormState, useLoginFormContext } from './login-form.context';
import {
  ComponentStep,
  MultiStepFormContextReturnType,
} from '../../providers/multi-step-form-provider';
import { useAlternateAuth } from '@utilities/feature-flags';
import {
  IsSuccessResponse,
  PlatformResponse,
} from '@services/platform/models/authorize.models';
import { platformAuthClient } from '@services/platform/platform-auth-client';
import {
  AuthenticationFactor,
  AuthenticationFactorMethodTypes,
} from '@flexbase-eng/types/dist/identity';
import { storeToken } from '@utilities/auth/store-token';
import { createContext, ReactNode, useContext } from 'react';
import { useHandleLoginSuccess } from './use-handle-login-success';
import {
  KEY_REMEMBER_USER,
  KEY_USER_EMAIL_STORAGE,
} from '../../states/auth/auth-token';

type UseLoginContextReturnType = MultiStepFormContextReturnType<
  LoginFormState,
  ComponentStep<LoginFormState>
> & {
  loginWithPassword: (
    email: string,
    password: string,
    rememberUser: boolean,
  ) => Promise<void>;
  verifyCode: (code: string) => Promise<void>;
  setSelectedFactor: (factor: AuthenticationFactor) => void;
  resendSmsCode: () => Promise<void>;
};

const LoginContext = createContext<UseLoginContextReturnType | null>(null);

export const LoginContextProvider = ({ children }: { children: ReactNode }) => {
  const loginFormContext = useLoginFormContext();
  const { isEnabledForUser } = useAlternateAuth();
  const { handleLoginSuccess } = useHandleLoginSuccess();

  const loginApi = async (
    email: string,
    password: string,
    rememberUser: boolean,
  ) => {
    loginFormContext.setState({
      loading: true,
      error: null,
      email,
      password,
      rememberUser,
    });

    const loginResult = await platformAuthClient.requestTokenByApi(
      email,
      password,
    );

    if (IsSuccessResponse(loginResult) && loginResult.body) {
      const body = loginResult.body;
      if (body.token_type === 'Challenge') {
        loginFormContext.goToStep('2FA', {
          selectedFactor: {
            method: AuthenticationFactorMethodTypes.Sms,
            value: body.challenge_phone_number_last_four,
          },
          loading: false,
          error: null,
        });
      } else {
        handleLoginSuccess(body);
      }
    } else {
      loginFormContext.setState({
        error:
          loginResult.wwwAuthenticate?.error === 'invalid_credentials'
            ? 'You entered invalid credentials. If you forgot your password, click the "Forgot Password" link above.'
            : 'Incorrect credentials.',
        loading: false,
      });
    }
  };

  const loginRobAuth = async (
    email: string,
    password: string,
    rememberUser: boolean,
  ) => {
    loginFormContext.resetState(); // Reset all state in-case they backed out to here and change their login. Don't want to retain factors from another login.
    loginFormContext.setState({
      loading: true,
      error: null,
      email,
      password,
      rememberUser,
    });

    try {
      const authenticationToken = await handlePlatformApiCall(
        platformAuthClient.requestTokenByPassword(email, password),
        (r) =>
          r.wwwAuthenticate?.error === 'invalid_credentials'
            ? 'You entered invalid credentials. If you forgot your password, click the "Forgot Password" link above.'
            : 'Incorrect credentials.',
      );
      if (rememberUser) {
        localStorage?.setItem(KEY_REMEMBER_USER, 'true');
        localStorage?.setItem(KEY_USER_EMAIL_STORAGE, email);
      } else {
        // Just in case this somehow does not get cleared (IE, if token / session expires and they get logged out automatically)
        localStorage?.setItem(KEY_REMEMBER_USER, 'false');
        localStorage?.removeItem(KEY_USER_EMAIL_STORAGE);
      }
      storeToken(authenticationToken);

      const factors = await handlePlatformApiCall(
        platformAuthClient.getFactors(),
        'An error occurred while retrieving your list of authentication factors.',
      );

      const availableFactors = factors.possession.filter(
        (f) =>
          f.verified && (f.method === 'sms' || f.method === 'authenticator'),
      );

      const pkce = await platformAuthClient.generatePKCE();
      loginFormContext.setState({
        pkce,
        factorList: availableFactors.length > 1 ? availableFactors : [],
      });

      if (availableFactors.length === 0) {
        await handleLoginSuccess(authenticationToken, {
          factors: availableFactors,
        });
      } else if (availableFactors.length > 1) {
        loginFormContext.goToStep('choose-factor', {
          loading: false,
          factorList: availableFactors,
        });
      } else {
        const onlyFactor = availableFactors[0];
        await handlePlatformApiCall(
          platformAuthClient.issueChallenge({
            type: 'otp',
            methodId: onlyFactor.methodId!,
            codeChallenge: pkce.codeChallenge,
          }),
          `An error occurred while trying to issue an SMS code to ${onlyFactor.value}`,
        );
        loginFormContext.goToStep('2FA', {
          loading: false,
          selectedFactor: onlyFactor,
        });
      }
    } catch {
      console.error('Login error');
    }
  };

  const verifyCodeApi = async (
    code: string,
    email: string,
    password: string,
  ) => {
    loginFormContext.setState({ error: null, loading: true });
    const result = await platformAuthClient.requestTokenByApi(
      email,
      password,
      code,
    );

    if (IsSuccessResponse(result) && result.body) {
      handleLoginSuccess(result.body);
    } else {
      loginFormContext.setState({
        error: 'Invalid code. Please try again or re-send the code',
        loading: false,
      });
    }
  };

  const loginWithPassword = async (
    email: string,
    password: string,
    rememberUser: boolean,
  ) => {
    // Clear any previous token
    localStorage?.removeItem('fb_full_token');

    const alternateAuthEnabled = await isEnabledForUser(email);

    if (alternateAuthEnabled) {
      await loginRobAuth(email, password, rememberUser);
    } else {
      await loginApi(email, password, rememberUser);
    }
  };

  const verifyCodePlatform = async (code: string) => {
    loginFormContext.setState({ loading: true, error: null });

    const pkce = loginFormContext.state.pkce;
    const selectedFactor = loginFormContext.state.selectedFactor;

    if (pkce && selectedFactor) {
      const tokenResponse = await platformAuthClient.requestTokenByCode({
        code,
        grantType: selectedFactor?.method === 'sms' ? 'otp' : 'totp',
        methodId: selectedFactor.methodId!,
        codeVerifier: pkce.codeVerifier,
      });

      if (IsSuccessResponse(tokenResponse) && tokenResponse.body) {
        await handleLoginSuccess(tokenResponse.body);
      } else {
        loginFormContext.setState({
          error: 'Unable to verify code',
          loading: false,
        });
      }
    } else {
      loginFormContext.setState({
        error: 'Unable to verify code',
        loading: false,
      });
    }
  };

  const verifyCode = async (code: string) => {
    const { email, password } = loginFormContext.state;
    const alternateAuthEnabled = await isEnabledForUser(email);

    if (alternateAuthEnabled) {
      await verifyCodePlatform(code);
    } else {
      await verifyCodeApi(code, email, password);
    }
  };

  const resendSmsCode = async () => {
    loginFormContext.setState({ loading: true });

    const factor = loginFormContext.state.selectedFactor;
    const pkce = loginFormContext.state.pkce;

    if (factor && pkce) {
      const challengeResponse = await platformAuthClient.issueChallenge({
        type: 'otp',
        codeChallenge: pkce.codeChallenge,
        methodId: factor.methodId!,
      });
      if (IsSuccessResponse(challengeResponse)) {
        loginFormContext.setState({
          loading: false,
          otpStatus: 're-sent',
        });
      } else {
        loginFormContext.setState({
          loading: false,
          error: `Unable to issue a challenge to ${factor.value}`,
        });
      }
    } else {
      loginFormContext.setState({
        loading: false,
        error: 'An unexpected error occurred. Please login again and retry.',
      });
    }
  };

  const setSelectedFactor = async (factor: AuthenticationFactor) => {
    loginFormContext.setState({
      selectedFactor: factor,
      loading: true,
      error: null,
    });

    const pkce =
      loginFormContext.state.pkce ?? (await platformAuthClient.generatePKCE());

    // If the factor is SMS, generate a challenge.
    if (factor.method === 'sms') {
      const challengeResponse = await platformAuthClient.issueChallenge({
        type: 'otp',
        codeChallenge: pkce.codeChallenge,
        methodId: factor.methodId ?? '', // This won't be undef. Thanks types pack
      });

      if (!IsSuccessResponse(challengeResponse)) {
        loginFormContext.setState({
          loading: false,
          error: 'Unable to issue a challenge to your selected factor',
        });
      } else {
        loginFormContext.goToStep('2FA', {
          loading: false,
        });
      }
    } else {
      loginFormContext.goToStep('2FA', { loading: false });
    }
  };

  type ErrorMessageHandler<T> = (response: PlatformResponse<T>) => string;

  async function handlePlatformApiCall<T>(
    call: Promise<PlatformResponse<T>>,
    errorMessage: string | ErrorMessageHandler<T>,
  ): Promise<T> {
    const result = await call;

    if (!result.rawResponse.ok) {
      loginFormContext.setState({
        loading: false,
        error:
          typeof errorMessage === 'string'
            ? errorMessage
            : errorMessage(result),
      });
      throw new Error('Platform Identity Authorize call failure');
    }

    return result.body!;
  }

  return (
    <LoginContext.Provider
      value={{
        ...loginFormContext,
        loginWithPassword,
        verifyCode,
        setSelectedFactor,
        resendSmsCode,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export const useLoginContext = () => {
  const context = useContext(LoginContext);

  if (context === null) {
    throw new Error(
      'useLoginContext must be used within a LoginContextProvider',
    );
  }

  return context;
};
