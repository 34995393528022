import { createMultiStepFormRouteContext } from '../../../../providers/multi-step-form-provider';

export type PrefillFormState = {
  dateOfBirthProvided: boolean;
  phoneVerification: { phoneNumber: string; methodId: string } | null;
  phoneVerified: boolean;
  loading: boolean;
  error: string | null;
  prefilled: boolean;
  businessPrefillAvailable: boolean;
};

export const [PrefillProvider, usePrefillMultistepFormContext] =
  createMultiStepFormRouteContext<PrefillFormState>();
