import { Avatar, Box, Text } from '@mantine/core';
import { VscChromeClose } from 'react-icons/vsc';
import { useStyles } from './styles';
import { TeamMembersTableRow } from '../team-members';
import { CheckBlackWithoutCircle } from 'assets/svg';
import {
  AdminFullAllow,
  AdminLimitedAllow,
  BookkeeperAllow,
  EmployeeAllow,
} from './roles-allow';

// Here we're creating a 'faux-hierarchy' where we are going down the list of role types and denoting them from highest privledge to lowest
const getRoleAndLabel = (roles: string[]) => {
  if (roles.includes('ADMIN') && roles.includes('COMPTROLLER')) {
    return { role: 'COMPTROLLER', label: 'Admin - Full' };
  } else if (roles.includes('ADMIN')) {
    return { role: 'ADMIN', label: 'Admin - Limited' };
  } else if (roles.includes('ACCOUNTANT')) {
    return { role: 'ACCOUNTANT', label: 'Bookkeeper' };
  } else if (roles.includes('EMPLOYEE')) {
    return { role: 'EMPLOYEE', label: 'EMPLOYEE' };
  } else {
    return { role: roles[0] || '', label: roles[0] || '' };
  }
};

type Props = {
  closeModal: () => void;
  user: TeamMembersTableRow;
};

const RoleDetails = ({ user, closeModal }: Props) => {
  const { classes } = useStyles();

  const rolePermissionsMap = {
    ADMIN: AdminLimitedAllow,
    EMPLOYEE: EmployeeAllow,
    ACCOUNTANT: BookkeeperAllow,
    COMPTROLLER: AdminFullAllow,
  };

  const { role, label } = getRoleAndLabel(user.roles);

  const selectedRole: {
    id: number;
    description: string;
  }[] = rolePermissionsMap[role as keyof typeof rolePermissionsMap] || [];

  return (
    <div className={classes.baseContainer}>
      <div className={classes.headerRow}>
        <div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div>
              <Avatar radius="md" className={classes.avatar}>
                {user.initials}
              </Avatar>
            </div>
            <div style={{ flexDirection: 'column', display: 'flex' }}>
              <div className={classes.containerUserName}>{user.name}</div>
              <div className={classes.containerRole}>
                <Text>{label}</Text>
              </div>
            </div>
          </div>
        </div>
        <div>
          <button className={classes.closeButton} onClick={closeModal}>
            <VscChromeClose size={34} color="#FFFF" />
          </button>
        </div>
      </div>
      <Box p={'2rem'}>
        <Text size="md">
          The {label.toLowerCase()} account allows {user.name} to:
        </Text>
        <Box mt="2rem">
          {selectedRole.map((item) => (
            <Box
              key={item.id}
              mt="0.8rem"
              display="flex"
              style={{ alignItems: 'center' }}
            >
              <CheckBlackWithoutCircle width={15} />{' '}
              <Text ml="0.5rem">{item.description}</Text>
            </Box>
          ))}
        </Box>
      </Box>
    </div>
  );
};

export default RoleDetails;
