import { createStyles } from '@mantine/core';
import { spinCounterClockwise } from '../utils/keyframes';

type StyleProps = {
  flagMenuHeight: '115px' | '235px';
};

export const useStyles = createStyles(
  (theme, { flagMenuHeight }: StyleProps) => ({
    headerRow2: {
      marginBottom: '24px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      '@media(max-width: 767px)': {
        marginTop: '20px',
      },
    },
    flagButtonTop: {
      borderRadius: '8px',
      width: '150px',
      height: '28px',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '15px',
      borderColor: theme.fn.primaryColor(),
      color: theme.fn.primaryColor(),
      cursor: 'default',
      '&:hover': {
        background: '#ffffff',
      },
    },
    infoTitleFlex: {
      display: 'flex',
      flexDirection: 'row',
      gap: '8px',
    },
    sectionTitle: {
      marginTop: '26px',
      marginBottom: ' 20px',
      display: 'flex',
      alignItems: 'center',
    },
    dropZone: {
      width: '100%',
      height: '183px',
      padding: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px dashed black',
      borderRadius: '16px',
      backgroundColor: theme.fn.themeColor('neutral', 2),
      '&:hover': {
        backgroundColor: 'rgba(57, 160, 255, 0.04)',
      },
      position: 'relative',
      '> div:first-of-type': {
        position: 'absolute',
        zIndex: 1,
        display: 'flex',
        top: '60px',
        '> button': {
          height: '30px',
          width: '30px',
          display: 'flex',
          alignItems: 'center',
          borderRadius: '5px',
          background: '#eeeeeecc',
          mixBlendMode: 'normal',
          zIndex: 99,
          margin: '3px',
          cursor: 'pointer !important',
          border: 'none',
        },
      },
    },
    dropZonePlaceholder: {
      marginTop: '14px',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '130%',
      display: 'inline-block',
      alignItems: 'center',
      textAlign: 'center',
      color: '#757575',
      '> span': {
        display: 'inline',
        color: theme.fn.primaryColor(),
      },
    },
    roundIcons: {
      height: '30px',
      width: '30px',
      borderRadius: '50%',
      background: '#6B6B6BAA',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      padding: 5,
      svg: {
        color: 'white',
      },
    },
    containerMemoInput: {
      opacity: 0.8,
      borderRadius: theme.defaultRadius,
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
      color: '#5F5F5F',
      marginBottom: '15px',
    },
    memoInput: {
      padding: 8,
      backgroundColor: theme.fn.themeColor('neutral', 2),
      border: `1px solid ${theme.fn.themeColor('neutral', 3)}`,
    },
    memoButtons: {
      overflow: 'hidden',
      transition: 'max-height 0.15s ease-out',
      display: 'flex',
      justifyContent: 'right',
      '> *': {
        marginLeft: '20px',
      },
      marginBottom: '20px',
    },
    switch: {
      marginRight: '15px',
      '> input': {
        width: '52px',
        height: '30px',
        ':before': {
          width: '24px',
          height: '24px',
        },
      },
    },
    billableInfo: {
      width: '20px',
      height: '20px',
      color: '#5F5F5F',
      marginLeft: '15px',
      marginTop: '3px',
    },
    billableTitle: {
      marginTop: '26px',
      marginBottom: '9px',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px',
      color: '#4B4B4B',
    },
    billableInput: {
      marginBottom: '26px',
      '&:last-of-type': {
        marginBottom: '45px',
      },
    },
    actionButtons: {
      paddingLeft: 20,
      paddingBottom: 20,
      backgroundColor: theme.fn.themeColor('neutral', 2),
    },
    flagButtonButtom: {
      width: '184px',
      height: '40px',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '17px',
      backgroundColor: 'black',
      color: '#FFFF',
      '&:hover': {
        background: '#FF574533',
        color: 'black',
      },
      'svg:last-of-type': {
        fontSize: '18px',
        marginLeft: '5px',
      },
    },
    flagMenu: {
      borderRadius: theme.defaultRadius,
      height: '275px',
      width: '352px !important',
      padding: '15px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      '@media(max-width: 767px)': {
        width: '200px !important',
      },
    },
    flagMenuItem: {
      height: flagMenuHeight,
      borderRadius: '8px',
      '&:hover': {
        background: 'rgba(255, 87, 69, 0.08)',
      },
      '&:last-of-type': {
        cursor: 'not-allowed',
        '&:hover': {
          background: '#ffffff',
        },
      },
    },
    flagMenuText: {
      fontWeight: 500,
      fontSize: '14px',
      color: '#5F5F5F',
    },
    approve: {
      borderRadius: '8px',
      background: '#27C281',
      width: '184px',
      height: '40px',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
      textAlign: 'center',
      color: '#FFFFFF',
      '&:hover': {
        background: '#27C281',
      },
    },
    disputeMenu: {
      padding: '0 10px',
    },
    disputeTitle: {
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '24px',
      color: '#5F5F5F',
      marginBottom: '15px',
    },
    disputeText: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '19px',
      color: '#5F5F5F',
      marginBottom: '20px',
    },
    disputeButtons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    syncIcon: {
      color: theme.fn.themeColor('neutral', 3),
      marginLeft: theme.spacing.xs,
    },
    spin: {
      animation: `${spinCounterClockwise} 1000ms linear infinite`,
    },
  }),
);
