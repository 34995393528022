import FlagIcon from '@common/icons/flag-icon';
import { Box, Select, SelectProps, createStyles } from '@mantine/core';
import { forwardRef } from 'react';
import {
  getCurrencyFlag,
  supportedCurrenciesData,
} from '../international-payments/util';
import {
  AvailableCurrencies,
  CurrencySelectProps,
} from '../international-payments/util/types';

type SelectItemProps = CurrencySelectProps & {
  onSelect: (value: string) => void;
  classes: Record<string, string>;
};

const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ label, value, code, onSelect, classes, ...others }, ref) => (
    <div ref={ref} {...others}>
      <Box
        className={classes.container}
        onClick={() => {
          onSelect(value);
        }}
      >
        <Box>{label}</Box>
        <Box>
          <FlagIcon flagNationCode={code} />
        </Box>
      </Box>
    </div>
  ),
);

SelectItem.displayName = 'SelectItem';

type Props = {
  value: AvailableCurrencies;
} & Partial<SelectProps>;

const CurrencySelect = ({ ...props }: Partial<Props>) => {
  const { classes } = useStyles();
  return (
    <Select
      {...props}
      data={supportedCurrenciesData}
      label="Currency"
      placeholder="Choose a currency"
      searchable
      id="currency-dropdown"
      rightSection={
        <FlagIcon
          width="1rem"
          height="1rem"
          flagNationCode={getCurrencyFlag(props.value!)}
        />
      }
      itemComponent={(itemProps) => (
        <SelectItem {...itemProps} classes={classes} />
      )}
    />
  );
};

export default CurrencySelect;

const useStyles = createStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}));
