import { EditIcon } from 'assets/svg';
import {
  ComponentStep,
  StateUpdater,
  createMultiStepFormDynamicContext,
} from 'providers/multi-step-form-provider';
import {
  Fragment,
  MouseEventHandler,
  MutableRefObject,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { WizardLabelsConfig, WizardStepConfig } from './wizard.types';
import {
  Box,
  Button,
  Group,
  Progress,
  Space,
  Stack,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { WizardStep } from './components/wizard-step';
import { useWizardStyles } from './wizard.styles';
import FlexIconLink from '@common/icons/flex-icon-link';
import { useWizardMobile } from './utils/use-wizard-mobile';
import { isFunction } from 'underscore';
import { useScrollIntoView } from '@mantine/hooks';
import { Easings, PREVIEW_STACK_PY } from './wizard.constants';

type WizardState<TFormState> = {
  labels: WizardLabelsConfig;
  showProgressBar: boolean;
  previewRef: MutableRefObject<null>;
  previewStepsRef: MutableRefObject<Record<string, any>>;
  onStepChangeRef: MutableRefObject<
    (
      from: WizardStepConfig<TFormState>,
      to: WizardStepConfig<TFormState>,
    ) => boolean
  >;
  onCancelRef: MutableRefObject<(() => void) | undefined>;
  scrollToPreview: (id: string) => void;
  form: TFormState;
};

export type WizardFormStep<TFormState> = ComponentStep<
  WizardState<TFormState>
> & {
  metadata: ComponentStep<WizardState<TFormState>>['metadata'] & {
    previewElement?: ReactNode;
    disableScrollToPreview?: boolean;
    condition?: boolean;
    entireStep: WizardStepConfig<TFormState>;
  };
};

function wizardStepConfigToFormStep<TFormState>(
  step: WizardStepConfig<TFormState>,
): WizardFormStep<TFormState> {
  return {
    element: step.element,
    condition:
      step.condition &&
      ((wizardState) => step.condition?.(wizardState.form) ?? true),
    metadata: {
      id: step.id,
      title: step.title,
      previewElement: step.previewElement,
      disableScrollToPreview: step.options?.disableScrollToPreview || false,
      entireStep: step,
    },
  };
}

function formStepToWizardStepConfig<TFormState>(
  config?: WizardFormStep<TFormState> | null,
): WizardStepConfig<TFormState> | undefined {
  if (!config) {
    return;
  }

  return {
    id: config.metadata.id,
    title: config.metadata.title,
    element: config.element,
    condition: config.metadata.entireStep.condition,
    previewElement: config.metadata.previewElement,
    options:
      typeof config.metadata.disableScrollToPreview === 'undefined'
        ? undefined
        : {
            disableScrollToPreview: config.metadata.disableScrollToPreview,
          },
  };
}

/**
 * Creates a Wizard component and useWizard hook to render a Wizard view. 🧙
 *
 * The Wizard renders two panels side by side for the step content, and preview content.
 *
 * Pass in a step config and an initial state and the wizard handles the state control
 *
 * Use Wizard.Step to use the default step layout with a Back and Next button at the bottom, or use your layouts.
 *
 * Use the useWizard hook to get access to navigation functions and state functions.
 *
 * @example
 * type MyState = {
 *   name: string;
 *   age: number;
 * };
 *
 * const [
 *   MyWizard,
 *   useMyWizard,
 * ] = createWizard<MyState>();
 *
 * const StepOne = () => {
 *   const { goToNextStep, goToPreviousStep } = useMyWizard();
 *
 *   return <MyWizard.Step
 *     onBack={() => goToPreviousStep()}
 *     onNext={() => goToNextStep()}>
 *     <TextInput />
 *   </MyWizard.Step>;
 * };
 *
 * const MyPage = () => {
 *   const navigate = useNavigate();
 *
 *   return <MyWizard
 *     steps={[
 *       {
 *         id: 'step-1',
 *         title: 'Step One',
 *         element: <StepOne />,
 *         previewElement: <> ... </>,
 *       },
 *       {
 *         ...
 *       },
 *     ]}
 *     initialState={{ name: '', age: 0 }}
 *     onCancel={() => navigate.to('/')}
 *   />;
 * };
 */
export function createWizard<TFormState>() {
  const [FormProvider, useFormContext] = createMultiStepFormDynamicContext<
    WizardState<TFormState>,
    WizardFormStep<TFormState>
  >();

  function useWizardContext() {
    const {
      state: wizard,
      setState: setWizardState,
      ...rest
    } = useFormContext();

    return {
      wizard,
      setWizardState,
      ...rest,
    };
  }

  type WizardProviderProps = PropsWithChildren<{
    steps: WizardStepConfig<TFormState>[];
    initialState: TFormState;
    labels: WizardLabelsConfig;
    progressBar: boolean;
    onCancel?: () => void;
  }>;

  function WizardProvider({
    children,
    steps,
    initialState,
    labels,
    progressBar: showProgressBar,
    onCancel,
  }: WizardProviderProps) {
    const onStepChangeRef = useRef<() => boolean>(() => true);
    const onCancelRef = useRef<(() => void) | undefined>(onCancel);
    const previewStepsRef = useRef<Record<string, any>>({});

    onCancelRef.current = onCancel;

    const { scrollableRef, targetRef, scrollIntoView } = useScrollIntoView({
      duration: 350,
      easing: Easings.InOutCubic,
      offset: PREVIEW_STACK_PY,
    });

    const wizardSteps: WizardFormStep<TFormState>[] = useMemo(
      () => steps.map(wizardStepConfigToFormStep),
      [steps],
    );

    const scrollToPreview = (id: string) => {
      // don't need to scroll if only 1 step
      if (steps.length > 1 && previewStepsRef.current[id]) {
        targetRef.current = previewStepsRef.current[id];

        scrollIntoView();
      }
    };

    const wizardValue: WizardState<TFormState> = {
      labels,
      showProgressBar,
      previewRef: scrollableRef,
      previewStepsRef,
      onStepChangeRef,
      onCancelRef,
      scrollToPreview,
      form: initialState,
    };

    return (
      <FormProvider steps={wizardSteps} initialState={wizardValue}>
        {children}
      </FormProvider>
    );
  }

  type WizardPreviewSectionProps = {
    title?: ReactNode;
    step: WizardFormStep<TFormState>;
    onClick: MouseEventHandler<HTMLDivElement>;
  };

  function WizardPreviewSection({ step, onClick }: WizardPreviewSectionProps) {
    const { classes, cx } = useWizardStyles();
    const { visitedSteps, currentStep } = useWizardContext();

    const hasVisited = visitedSteps[step.metadata.id];
    const isPreviewForCurrentStep =
      currentStep?.metadata.id === step.metadata.id;
    const className = hasVisited
      ? cx(classes.previewSection, classes.previewSectionVisited)
      : cx(classes.previewSection);
    const editIconSize = 16;

    const enableClickToEdit = hasVisited && !isPreviewForCurrentStep;

    const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
      if (enableClickToEdit) {
        onClick(e);
      }
    };

    return (
      <Stack
        spacing={4}
        py={PREVIEW_STACK_PY}
        className={className}
        onClick={handleClick}
      >
        {enableClickToEdit ? (
          <Stack align="center" className="edit" sx={{ zIndex: 20 }}>
            <EditIcon height={editIconSize} width={editIconSize} />
          </Stack>
        ) : (
          <Space w={editIconSize} />
        )}
        <Box sx={{ zIndex: 10 }}>{step.metadata.previewElement}</Box>
      </Stack>
    );
  }

  function WizardPreview() {
    const { wizard, stepList, visitedSteps, currentStep, goToStep } =
      useWizardContext();

    const [previewSteps, lastStep] = useMemo(() => {
      if (!stepList) {
        return [[], undefined];
      }

      const stepsWithPreviews = [
        ...stepList.filter((step) => step.metadata.previewElement),
      ];
      const lastStepWithPreview = stepsWithPreviews.pop();

      return [stepsWithPreviews, lastStepWithPreview];
    }, [stepList]);

    const getRefForStep = (step: WizardFormStep<TFormState>) => {
      return (refNode: HTMLDivElement | null) => {
        wizard.previewStepsRef.current[step.metadata.id] = refNode;
      };
    };

    const handlePreviewStepClick = (step: WizardFormStep<TFormState>) => {
      const { id } = step.metadata;
      const hasVisited = visitedSteps[id];

      const fromStep = formStepToWizardStepConfig(currentStep);
      const toStep = formStepToWizardStepConfig(step);

      if (
        hasVisited &&
        fromStep &&
        toStep &&
        wizard.onStepChangeRef.current(fromStep, toStep)
      ) {
        goToStep(id, undefined, { fromPreview: true });
      }
    };

    return (
      <Stack h="100%" px={20} py={PREVIEW_STACK_PY} spacing={0}>
        {previewSteps.map((step) => {
          return (
            <Box key={step.metadata.id} ref={getRefForStep(step)}>
              <WizardPreviewSection
                step={step}
                onClick={() => handlePreviewStepClick(step)}
              />
            </Box>
          );
        })}

        {lastStep ? (
          <Box
            ref={getRefForStep(lastStep)}
            mih={`calc(100% + ${rem(PREVIEW_STACK_PY)})`}
          >
            <WizardPreviewSection
              step={lastStep}
              onClick={() => handlePreviewStepClick(lastStep)}
            />
          </Box>
        ) : null}
      </Stack>
    );
  }

  function WizardLayout() {
    const theme = useMantineTheme();
    const isMobile = useWizardMobile();
    const [isInitialMount, setIsInitialMount] = useState(true);
    const { classes } = useWizardStyles();

    const { wizard, currentStep, stepList, progress } = useWizardContext();

    const currentStepId = currentStep?.metadata.id;
    const hasPreviewSteps = stepList.some(
      (step) => !!step.metadata.previewElement,
    );

    const handleCancel = () => {
      wizard.onCancelRef.current?.();
    };

    useEffect(() => {
      if (currentStepId) {
        // don't scroll on initial mount
        if (isInitialMount) {
          setIsInitialMount(false);
          return;
        }

        if (currentStep.metadata.disableScrollToPreview) {
          return;
        }

        wizard.scrollToPreview(currentStepId);
      }
    }, [currentStepId]);

    return (
      <Stack spacing={0} className={classes.container}>
        <Box className={classes.header}>
          <Group className={classes.headerBody}>
            <Box className={classes.headerContent}>
              <FlexIconLink height={30} />
            </Box>

            <Box className={classes.headerActions}>
              <Button variant="subtle" onClick={handleCancel}>
                Cancel
              </Button>
            </Box>
          </Group>

          <Progress
            value={wizard.showProgressBar ? progress : 0}
            size={1}
            radius={0}
            color={theme.fn.primaryColor()}
            bg={theme.fn.rgba(theme.fn.themeColor('neutral', 9), 0.12)}
          />
        </Box>

        <Group className={classes.body}>
          <Box
            className={classes.contentPanel}
            style={{
              maxWidth: hasPreviewSteps ? '400px' : '100%',
            }}
          >
            {currentStep?.element}
          </Box>

          {isMobile || !hasPreviewSteps ? null : (
            <Box ref={wizard.previewRef} className={classes.previewPanel}>
              <Box className={classes.previewPanelInner}>
                <WizardPreview />
              </Box>
            </Box>
          )}
        </Group>
      </Stack>
    );
  }

  type WizardProps = PropsWithChildren<{
    initialState: TFormState;
    steps: WizardStepConfig<TFormState>[];
    labels?: Partial<WizardLabelsConfig>;

    /**
     * Whether or not to display the progress bar.
     * Default: true
     */
    progressBar?: boolean;

    /**
     * Wraps the wizard content so that any components in the wrapper have access to the wizard context.
     *
     * @example
     * <Wizard
     *   wrapper={({ children }) => {
     *     return <MyProvider> // MyProvider can now use useWizardContext()
     *       {children}
     *     </MyProvider>;
     *   }}
     * />
     */
    wrapper?: (props: PropsWithChildren) => ReactNode;
    onCancel?: () => void;
  }>;

  function Wizard({
    steps,
    initialState,
    labels,
    progressBar = true,
    onCancel,
    wrapper: WrapperComponent = Fragment,
  }: WizardProps) {
    const wizardLabels = {
      back: 'Back',
      next: 'Continue',
      ...labels,
    };

    return (
      <WizardProvider
        steps={steps}
        labels={wizardLabels}
        initialState={initialState}
        progressBar={progressBar}
        onCancel={onCancel}
      >
        <WrapperComponent>
          <WizardLayout />
        </WrapperComponent>
      </WizardProvider>
    );
  }

  type WizardStepProps = PropsWithChildren<{
    labels?: Partial<WizardLabelsConfig>;
    hideBack?: boolean;
    hideNext?: boolean;
    backLoading?: boolean;
    nextLoading?: boolean;
    onBack?: () => void;
    onNext?: () => void;
  }>;

  Wizard.Step = function Step({
    children,
    labels,
    hideBack,
    hideNext,
    backLoading,
    nextLoading,
    onBack,
    onNext,
  }: WizardStepProps) {
    const {
      wizard,
      currentStep,
      hasPreviousStep,
      goToPreviousStep,
      goToNextStep,
    } = useWizardContext();

    const handleBack = hasPreviousStep
      ? () => {
          onBack ? onBack() : goToPreviousStep();
        }
      : undefined;

    const handleNext = () => {
      onNext ? onNext() : goToNextStep();
    };

    return (
      <WizardStep
        title={currentStep?.metadata.title}
        labels={{
          ...wizard.labels,
          ...labels,
        }}
        hideBack={hideBack || !hasPreviousStep}
        hideNext={hideNext}
        backLoading={backLoading}
        nextLoading={nextLoading}
        onBack={handleBack}
        onNext={handleNext}
      >
        {children}
      </WizardStep>
    );
  };

  Wizard.Preview = WizardPreview;

  function useWizard() {
    const isMobile = useWizardMobile();
    const {
      wizard,
      stepList,
      currentStep,
      currentStepIndex,
      navigationContext,
      setWizardState,
      goToStep,
      goToNextStep,
      goToPreviousStep,
      getLastVisitedStep,
      ...rest
    } = useWizardContext();

    function applyFormUpdate(
      targetFn: typeof setWizardState,
      updater?: StateUpdater<TFormState>,
    ) {
      if (!updater) {
        targetFn((prev) => prev);
        return;
      }

      targetFn((prev) => {
        const updatedForm = isFunction(updater) ? updater(prev.form) : updater;

        return {
          ...prev,
          form: {
            ...prev.form,
            ...updatedForm,
          },
        };
      });
    }

    const wizardGoToStep = (id: string, updater?: StateUpdater<TFormState>) => {
      const fromStep = formStepToWizardStepConfig(currentStep);
      const toStep = formStepToWizardStepConfig(
        stepList.find((step) => step.metadata.id === id),
      );

      if (
        fromStep &&
        toStep &&
        wizard.onStepChangeRef.current(fromStep, toStep)
      ) {
        applyFormUpdate((_updater) => goToStep(id, _updater), updater);
      }
    };

    const wizardGoToNextStep = (updater?: StateUpdater<TFormState>) => {
      const fromStep = formStepToWizardStepConfig(currentStep);
      const nextStep = stepList[currentStepIndex + 1];
      const toStep = formStepToWizardStepConfig(nextStep);

      if (
        fromStep &&
        toStep &&
        wizard.onStepChangeRef.current(fromStep, toStep)
      ) {
        applyFormUpdate(goToNextStep, updater);
      }
    };

    const wizardGoToPreviousStep = (updater?: StateUpdater<TFormState>) => {
      const fromStep = formStepToWizardStepConfig(currentStep);
      const nextStep = stepList[currentStepIndex - 1];
      const toStep = formStepToWizardStepConfig(nextStep);

      if (
        fromStep &&
        toStep &&
        wizard.onStepChangeRef.current(fromStep, toStep)
      ) {
        applyFormUpdate(goToPreviousStep, updater);
      }
    };

    const wizardGetLastVisitedStep = () => {
      return formStepToWizardStepConfig<TFormState>(getLastVisitedStep());
    };

    const wizardScrollToPreviewTop = () => {
      wizard.scrollToPreview(stepList[0].metadata.id);
    };

    const setFormState = (updater: StateUpdater<TFormState>) => {
      applyFormUpdate(setWizardState, updater);
    };

    const onStepChange = (
      fn: (
        from: WizardStepConfig<TFormState>,
        to: WizardStepConfig<TFormState>,
      ) => boolean,
    ) => {
      wizard.onStepChangeRef.current = fn;
    };

    return {
      ...rest,
      state: wizard.form,
      isMobile,
      currentStep,
      navigatedFromPreview:
        !!currentStep &&
        !!navigationContext[currentStep.metadata.id]?.fromPreview,
      scrollToPreviewStep: wizard.scrollToPreview,
      scrollToPreviewTop: wizardScrollToPreviewTop,
      goToStep: wizardGoToStep,
      goToNextStep: wizardGoToNextStep,
      goToPreviousStep: wizardGoToPreviousStep,
      getLastVisitedStep: wizardGetLastVisitedStep,
      setState: setFormState,

      /**
       * Function to run before navigating to a different step
       */
      onStepChange,
    };
  }

  return [Wizard, useWizard] as const;
}
