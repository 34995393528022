import { useReducer } from 'react';
import { Transactions } from '@flexbase-eng/types/dist/accounting';

export const { Actions, reducer } = (() => {
  type SyncToAccountChange = {
    /**
     * Payload to be sent in a sync request.
     */
    data: Transactions;

    /**
     * Whether an individual tx is in syncing a change.
     */
    status?: 'loading';
  };

  enum ActionTypes {
    REMOVE,
    REMOVE_AND_RESET_ALL_STATUS,
    PUT_STATE,
    UPDATE_ACCOUNT,
    UPDATE_STATUS,
    RESET_STATUS_ALL,
  }

  type Action =
    | {
        type: ActionTypes.REMOVE;
        payload: { transactionId: string };
      }
    | {
        type: ActionTypes.REMOVE_AND_RESET_ALL_STATUS;
        payload: { transactionIds: string[] };
      }
    | {
        type: ActionTypes.PUT_STATE;
        payload: {
          transactionId: string;
          data: Transactions;
          status?: 'loading';
        };
      }
    | {
        type: ActionTypes.UPDATE_ACCOUNT;
        payload: { transactionId: string; accountId: string };
      }
    | {
        type: ActionTypes.UPDATE_STATUS;
        payload: { transactionIds: string[]; status: 'loading' | undefined };
      }
    | {
        type: ActionTypes.RESET_STATUS_ALL;
      };
  type State = Partial<Record<string, SyncToAccountChange>>;

  const syncStateReducer = (state: State, action: Action) => {
    const next = { ...state };

    switch (action.type) {
      case ActionTypes.REMOVE:
        delete next[action.payload.transactionId];

        return next;
      case ActionTypes.REMOVE_AND_RESET_ALL_STATUS:
        action.payload.transactionIds.forEach((txId) => {
          delete next[txId];
        });

        Object.values(next).forEach((item) => {
          if (item?.status) {
            item.status = undefined;
          }
        });

        return next;
      case ActionTypes.PUT_STATE: {
        const existing = next[action.payload.transactionId];

        next[action.payload.transactionId] = {
          data: action.payload.data || existing?.data,
          status: action.payload.status || existing?.status,
        };

        return next;
      }
      case ActionTypes.UPDATE_ACCOUNT: {
        const fromAccountId =
          state[action.payload.transactionId]?.data?.accountId;
        const toAccountId = action.payload.accountId;

        if (fromAccountId === toAccountId) {
          return state;
        }

        next[action.payload.transactionId] = {
          data: {
            transactionId: action.payload.transactionId,
            accountId: action.payload.accountId,
          },
        };

        return next;
      }
      case ActionTypes.UPDATE_STATUS:
        action.payload.transactionIds.forEach((txId) => {
          const item = next[txId];

          if (item) {
            item.status = action.payload.status;
          }
        });

        return next;

      case ActionTypes.RESET_STATUS_ALL:
        Object.values(next).forEach((item) => {
          if (item) {
            item.status = undefined;
          }
        });

        return next;
      default:
        return state;
    }
  };

  const SyncStateActions = {
    remove: (transactionId: string) => ({
      type: ActionTypes.REMOVE,
      payload: { transactionId },
    }),
    removeAndResetAllStatus: (transactionIds: string[]) => ({
      type: ActionTypes.REMOVE_AND_RESET_ALL_STATUS,
      payload: { transactionIds },
    }),
    resetStatusAll: () => ({
      type: ActionTypes.RESET_STATUS_ALL,
    }),
    putState: (
      transactionId,
      state: {
        data: Transactions;
        status?: 'loading' | undefined;
      },
    ) => ({
      type: ActionTypes.PUT_STATE,
      payload: { transactionId, data: state.data, status: state.status },
    }),
    updateAccount: (transactionId: string, accountId: string) => ({
      type: ActionTypes.UPDATE_ACCOUNT,
      payload: { transactionId, accountId },
    }),
    updateStatus: (
      transactionIds: string[],
      status: 'loading' | undefined,
    ) => ({
      type: ActionTypes.UPDATE_STATUS,
      payload: { transactionIds, status },
    }),
  } satisfies Record<string, (...args: any[]) => Action>;

  return {
    Actions: SyncStateActions,
    reducer: syncStateReducer,
  };
})();

/**
 * Reducer to keep track of local changes when users update the integration
 * accounts on transactions.
 */
export const useSyncedToAccountReducer = () => {
  return useReducer(reducer, {});
};
