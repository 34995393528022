import { Box, Flex, Text, rem } from '@mantine/core';
import { formatCurrency } from '@utilities/formatters/format-currency';

type BillCardProps = {
  totalCount: number;
  totalAmount: number;
  headingText: string;
  textAmountColor: string;
  backgroundColor?: string;
};

const BillCard = ({
  totalCount,
  totalAmount,
  headingText,
  backgroundColor,
  textAmountColor,
}: BillCardProps) => {
  const billsCount = (count: number) => {
    if (count === 1) {
      return '1 bill';
    }
    return `${count} bills`;
  };

  return (
    <Flex
      p="md"
      mih={rem(144)}
      direction="column"
      bg={backgroundColor}
      justify="space-between"
      sx={(theme) => ({
        borderRadius: rem(4),
        border: !backgroundColor
          ? `1px solid ${theme.fn.themeColor('neutral', 3)}`
          : 'unset',
      })}
    >
      <Box>
        <Text size="sm">{headingText} total</Text>
        <Text size="xl" c={textAmountColor}>
          {formatCurrency(totalAmount)}
        </Text>
      </Box>
      <Text c="neutral.7">{billsCount(totalCount)}</Text>
    </Flex>
  );
};

export default BillCard;
