import { Badge, createStyles } from '@mantine/core';
import { ArrowDown, ArrowUp } from 'assets/svg';
import { ReactNode } from 'react';

type Change = 'increase' | 'decrease';
type Tone = 'critical' | 'neutral' | 'positive';

type Props = {
  tone: Tone;
  change: Change;
  children?: ReactNode;
};

const directionIcon = (change: Change) => {
  if (change === 'increase') {
    return <ArrowUp />;
  }
  return <ArrowDown />;
};

export const TagDelta = ({ tone, change, children }: Props) => {
  const { classes } = useStyles(tone);

  return (
    <Badge
      pl={0}
      size="lg"
      radius="xl"
      leftSection={directionIcon(change)}
      classNames={{ ...classes }}
    >
      {children}
    </Badge>
  );
};

const useStyles = createStyles((theme, tone: Tone) => ({
  root: {
    gap: 4,
    padding: '4px 8px',
    alignItems: 'center',
    borderRadius: 999,
    backgroundColor: theme.fn.themeColor(tone.toString(), 0),
  },
  leftSection: {
    marginLeft: 4,
    justifyContent: 'center',
    marginRight: 0,
    display: 'flex',
    color: theme.fn.themeColor(tone.toString(), 3),
  },
  inner: {
    color: theme.fn.themeColor(tone.toString(), 3),
  },
}));
