import {
  Avatar,
  Button,
  ChevronIcon,
  Flex,
  Group,
  Loader,
  Select,
  Text,
  Input,
  UnstyledButton,
} from '@mantine/core';
import { ReactNode, forwardRef, useRef, useState } from 'react';
import { formatInitials } from 'utilities/formatters/format-strings';
import PaymentStep from '../payment-step';
import { CloseIcon, PlusSignIcon } from 'assets/svg';
import { Recipient } from 'states/recipient/recipient';

type ItemProps = {
  label: string;
} & React.ComponentPropsWithoutRef<'div'>;

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, ...others }: ItemProps, ref) => {
    return (
      <div
        ref={ref}
        {...others}
        data-testid={`frequently-paid-${label.toLowerCase()}`}
      >
        <Group noWrap>
          <Avatar>{formatInitials(label)}</Avatar>
          <Text size="sm">{label}</Text>
        </Group>
      </div>
    );
  },
);
SelectItem.displayName = 'SelectItem';

const CreateLabelButton = ({
  value,
  onClick,
}: {
  value: string;
  onClick: () => void;
}) => (
  <Button
    variant="outline"
    c="primarySecondarySuccess.4"
    leftIcon={<PlusSignIcon width={10} />}
    onClick={onClick}
    sx={(theme) => ({
      borderColor: theme.fn.themeColor('primarySecondarySuccess', 4),
    })}
  >
    {value ? `Pay someone new: ${value}` : 'Pay someone new'}
  </Button>
);

const getSelectOptions = (accounts: Recipient[]) => {
  const mostFrequentlyPaid = accounts
    .sort((a, b) => b.paymentsCount - a.paymentsCount)
    .slice(0, 3);

  const createGroupData = (data: Recipient[], group?: string) =>
    data.map((item) => ({ value: item.id, label: item.name, group }));

  const frequentlyPaidGroupData = createGroupData(
    mostFrequentlyPaid,
    'Most frequently paid',
  );

  const mostFrequentlyPaidIds = new Set(
    mostFrequentlyPaid.map((item) => item.id),
  );

  const otherAccounts = createGroupData(
    accounts.filter((account) => !mostFrequentlyPaidIds.has(account.id)),
  );

  return [...frequentlyPaidGroupData, ...otherAccounts];
};

type Props = {
  onSelect: (name: string, id?: string) => void;
  onAddRecipient: () => void;
  recipients: Recipient[];
  footer?: ReactNode;
  isLoadingRecipients: boolean;
};

const PaymentRecipient = ({
  onSelect,
  onAddRecipient,
  recipients,
  footer,
  isLoadingRecipients,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const selectOptions = getSelectOptions(recipients);

  const [newRecipient, setNewRecipient] = useState<string | undefined>();
  const [currentValue, setCurrentValue] = useState<string | undefined>();
  const [displayCreateRecipButton, setDisplayCreateRecipButton] =
    useState(false);

  const resetValues = () => {
    setCurrentValue(undefined);
    setNewRecipient(undefined);
  };

  const handleCreateNewRecipient = (name: string) => {
    if (!name) {
      resetValues();
      setDisplayCreateRecipButton(true);
      return null;
    }
    onSelect(name);
    onAddRecipient();
    return name;
  };

  const handleCloseButton = () => {
    resetValues();
    setDisplayCreateRecipButton(false);
  };

  const handleClickLabelButton = (value: string) => {
    if (!value) {
      inputRef.current?.focus();
    } else {
      handleCreateNewRecipient(value);
      resetValues();
    }
  };

  const isRecipientOnList = (val: string) => {
    return !!selectOptions.find((item) =>
      item.label.toLowerCase().includes(val.trim().toLowerCase()),
    );
  };

  const handleInputChange = (val: string) => {
    if (!val) {
      setCurrentValue(newRecipient);
    } else {
      setNewRecipient(val);
      setDisplayCreateRecipButton(isRecipientOnList(val));
    }
  };

  const handleSelectChange = (val: string) => {
    if (val) {
      setNewRecipient(val);
      setDisplayCreateRecipButton(false);
    }
    setCurrentValue(val);
  };

  const onMatchingValues = (val: string) => {
    handleInputChange(val);
    if (val && !displayCreateRecipButton) {
      handleSelectChange(val);
    }
  };

  return (
    <PaymentStep
      titleText="Who are you paying?"
      showNextButton={false}
      showBackButton={false}
      footer={footer}
    >
      {displayCreateRecipButton ? (
        <>
          <Input
            ref={inputRef}
            value={newRecipient}
            rightSection={
              isLoadingRecipients ? <Loader size="xs" /> : <ChevronIcon />
            }
            autoFocus
            rightSectionWidth={30}
            styles={{
              input: {
                borderTop: 'none',
                borderLeft: 'none',
                borderRight: 'none',
                borderBottomWidth: '2px',
              },
            }}
            onChange={(e) => onMatchingValues(e.target.value)}
          />
          <Flex
            p="md"
            mt="xs"
            align="center"
            justify="space-between"
            sx={(theme) => ({
              boxShadow: theme.shadows.sm,
              borderRadius: theme.defaultRadius,
              border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
            })}
          >
            <CreateLabelButton
              value={newRecipient as string}
              onClick={() => handleClickLabelButton(newRecipient as string)}
            />
            <UnstyledButton onClick={handleCloseButton}>
              <CloseIcon width={15} height={15} />
            </UnstyledButton>
          </Flex>
        </>
      ) : (
        <Select
          data={selectOptions}
          itemComponent={SelectItem}
          placeholder="Enter the recipient's legal name"
          searchable={!displayCreateRecipButton}
          creatable
          autoFocus
          initiallyOpened
          getCreateLabel={(value) => (
            <CreateLabelButton
              value={value}
              onClick={() => handleClickLabelButton(value)}
            />
          )}
          onCreate={handleCreateNewRecipient}
          data-testid={'name'}
          maxDropdownHeight={440}
          filterDataOnExactSearchMatch
          onChange={(val) => {
            const selectedItem = selectOptions.find((opt) => opt.value === val);
            if (selectedItem) {
              onSelect(selectedItem.label, selectedItem.value);
            }
          }}
          onSearchChange={(val: string) => onMatchingValues(val)}
          rightSection={
            isLoadingRecipients ? <Loader size="xs" /> : <ChevronIcon />
          }
          searchValue={
            currentValue && currentValue.length === 1 ? undefined : currentValue
          }
          shouldCreate={() => true}
          rightSectionWidth={30}
          styles={{
            input: {
              borderTop: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              borderBottomWidth: '2px',
            },
          }}
        />
      )}
    </PaymentStep>
  );
};

export default PaymentRecipient;
