import { Text } from '@mantine/core';
import { useDetailsStyles } from './details-styles';
import DetailsHeader from '../details-header/details-header';
import { formatDatetime } from 'utilities/formatters/format-datetime';

type Props = {
  createdAt: string;
  total: string;
  type: string;
  status: string;
  feeName: string;
  closeModal: () => void;
};

const FeeDetails = ({
  total,
  createdAt,
  closeModal,
  status,
  type,
  feeName,
}: Props) => {
  const { classes } = useDetailsStyles();

  return (
    <div className={classes.baseContainer}>
      <DetailsHeader
        closeModal={closeModal}
        {...{ createdAt, total, type, status }}
      />
      <div className={classes.section}>
        <div className={classes.infoRow}>
          <div>
            <Text className={classes.infoTitle}>Fee name</Text>
            <Text className={classes.infoValue}>{feeName}</Text>
          </div>
          <div>
            <Text className={classes.infoTitle}>Status</Text>
            <Text className={classes.infoValue}>{status}</Text>
          </div>
        </div>
        <div className={classes.infoRow}>
          <div>
            <Text className={classes.infoTitle}>Date</Text>
            <Text className={classes.infoValue}>
              {formatDatetime(createdAt)}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeeDetails;
