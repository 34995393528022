import { ReactNode, useState } from 'react';
import { Box, Collapse, Text } from '@mantine/core';
import { useSelfServiceStyles } from '../self-service-dashboard.styles';
import { ChevronDownIconNew } from '../../../assets/svg';

type Props = {
  children: ReactNode;
  title: string;
  subtitle: ReactNode;
};

export const ActionItemCard = ({ children, title, subtitle }: Props) => {
  const { classes, cx } = useSelfServiceStyles();
  const [open, setOpen] = useState(true);

  return (
    <Box className={classes.actionItemCard}>
      <Box
        className={classes.actionItemText}
        onClick={() => setOpen((p) => !p)}
      >
        <Box>
          <Text fz={{ base: 20, sm: 24 }} lh="32px" lts={-0.0456}>
            {title}
          </Text>
          <Text fz={14} lh="19px" mt="sm">
            {subtitle}
          </Text>
        </Box>
        <ChevronDownIconNew
          className={cx(classes.actionItemIcon, open && classes.rotateIcon)}
        />
      </Box>
      <Collapse in={open} transitionDuration={600}>
        <Box mt="xl">{children}</Box>
      </Collapse>
    </Box>
  );
};
