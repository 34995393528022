import { ExpenseLink } from '@flexbase-eng/types/dist/accounting';
import { Box, Button, Divider, Stack, Text } from '@mantine/core';
import { DisconnectButton } from './disconnect-button';
import { IntuitBankFeeds } from './intuit-bank-feeds';
import { IntegrationCardItem } from '../list-integrations';
import {
  isQuickbooksExpenses,
  useCreateRutterConnection,
  useIntegrationLinks,
} from '@utilities/integrations';
import { useUpdateAccountingSettings } from '@queries/use-integrations';
import { notifications } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

type IntuitBankFeedSettingsProps = {
  integration: IntegrationCardItem;
  link: ExpenseLink;
};

export const IntuitBankFeedSettings = ({
  link,
}: IntuitBankFeedSettingsProps) => {
  const navigate = useNavigate();
  const { accountingLinks } = useIntegrationLinks();
  const { openRutterLink, isExchangingToken, isRutterConnecting } =
    useCreateRutterConnection();
  const { mutate: saveAccountingSettings, isPending: isSavingSettings } =
    useUpdateAccountingSettings();

  useEffect(() => {
    const qboLink = accountingLinks.find(isQuickbooksExpenses);

    if (qboLink) {
      navigate(`/settings/integrations/accounting/${qboLink.connectionId}`, {
        replace: true,
      });
    }
  }, [accountingLinks]);

  const handleEnableQbo = () => {
    openRutterLink('QUICKBOOKS');
  };

  const handleDisconnect = () => {
    saveAccountingSettings(
      {
        connectionId: link.connectionId,
        request: {
          bankFeeds: {
            enabled: false,
          },
        },
      },
      {
        onSuccess: () => {
          navigate('/settings/integrations');
        },
        onError: () => {
          notifications.show({
            color: 'critical.2',
            message:
              'An unexpected error occurred while disconnecting. Please try again later.',
          });
        },
      },
    );
  };

  return (
    <Stack c="primarySecondarySuccess.8">
      <IntuitBankFeeds link={link} strongTitle />

      <Divider />

      <Text fw="bold">Get the full integration</Text>

      <Text>
        Enable the full integration to track bill pay and credit expenses. Setup
        takes a couple minutes.
      </Text>

      <Box>
        <Button
          variant="outline"
          onClick={handleEnableQbo}
          loading={isExchangingToken || isRutterConnecting}
        >
          Enable full integration
        </Button>
      </Box>

      <Box>
        <DisconnectButton
          onClick={handleDisconnect}
          loading={isSavingSettings}
          mt="sm"
        >
          Disconnect
        </DisconnectButton>
      </Box>
    </Stack>
  );
};
