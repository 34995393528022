import { Avatar, Box, Menu } from '@mantine/core';
import { useStyles } from './vendors.styles';
import VendorHeader from './vendors-header';
import { Counterparties, useMercoaSession } from '@mercoa/react';
import { FlexbaseTable } from '@common/table';
import { getInitialsOfNames } from '@utilities/extensions/object';
import { useMediaQuery } from '@mantine/hooks';
import { TableColumn } from 'react-data-table-component';
import { formatCurrency } from '@utilities/formatters/format-currency';
import { RowAction, SkeletonLoading } from '@flexbase-eng/web-components';
import { EyeOpenIcon, TrashIcon } from 'assets/svg';
import useModal from '@common/modal/modal-hook';
import DeleteVendor from './delete-vendor/delete-vendor';
import { useBillingVendorsFilters } from './use-billing-vendors-filters';
import { CounterpartyResponse } from '@mercoa/javascript/api';
import { useEffect, useMemo } from 'react';
import { useRecipientDetailsFeatureFlag } from '@utilities/feature-flags';
import { useNavigate } from 'react-router-dom';

type Props = {
  counterparties: CounterpartyResponse[];
  resultsPerPage: number;
  setResultsPerPage: (value: number) => void;
  dataLoaded: boolean;
  count: number;
};

export type VendorTableData = {
  name: string;
  status: string;
  unpaidBills: string;
  balance: string;
  foreignId: string;
};

const enrichCounterparties = (
  counterparties: CounterpartyResponse[],
): VendorTableData[] => {
  return counterparties.map((counterparty) => {
    const unpaidBillsAndBalance = counterparty.invoiceMetrics?.statuses
      .filter((e) =>
        ['DRAFT', 'NEW', 'APPROVED', 'SCHEDULED'].includes(e.status),
      )
      .reduce(
        (acc, e) => {
          acc.unpaidBills += e.totalCount;
          acc.balance += e.totalAmount;
          return acc;
        },
        { unpaidBills: 0, balance: 0 },
      );

    const unpaidBills = unpaidBillsAndBalance?.unpaidBills ?? 0;
    const balance = unpaidBillsAndBalance?.balance ?? 0;

    return {
      name: counterparty.name,
      status: counterparty.status,
      unpaidBills: unpaidBills.toString(),
      balance: balance.toString(),
      foreignId: counterparty.foreignId ?? '',
    };
  });
};

const Vendors = () => {
  const { classes, theme } = useStyles();
  const { refresh } = useMercoaSession();
  const { openCenterModalUnstyled, closeAllModals } = useModal();
  const { activeFiltersArray } = useBillingVendorsFilters();
  const recipientDetailsFeatureFlagIsOn = useRecipientDetailsFeatureFlag();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const navigate = useNavigate();

  const handleRowClicked = (foreignId: string) => {
    if (recipientDetailsFeatureFlagIsOn) {
      navigate(`/recipient-details/${foreignId}/payments`, {
        state: location.pathname,
      });
    }
  };

  const columns: TableColumn<VendorTableData>[] = [
    {
      name: 'Vendor',
      cell: (row) => (
        <Box className={classes.containerVendor}>
          <Avatar radius="xl" mr={15} w={45} h={45}>
            {getInitialsOfNames(row.name)}
          </Avatar>
          {row.name}
        </Box>
      ),
      sortable: true,
      selector: (row) => row.name,
      grow: useMobileView ? 2 : 1.5,
    },
    {
      name: 'Unpaid bills',
      selector: (row) => row.unpaidBills,
      sortable: true,
    },
    {
      name: 'Open balance',
      selector: (row) => parseFloat(row.balance),
      format: (row) => formatCurrency(row.balance),
      sortable: true,
    },
    {
      name: '',
      cell: (row) => (
        <Menu position="bottom-end">
          <Menu.Target>
            <Box w={'1.8rem'}>
              <RowAction className={classes.rowActionIcon} />
            </Box>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              onClick={() => handleRowClicked(row.foreignId)}
              className={classes.viewVendorOption}
              icon={<EyeOpenIcon width={18} />}
            >
              View vendor
            </Menu.Item>
            <Menu.Item
              className={classes.deleteVendorOption}
              icon={<TrashIcon />}
              onClick={() =>
                openCenterModalUnstyled(
                  <DeleteVendor
                    closeModal={closeAllModals}
                    recipientId={row.foreignId}
                    recipientName={row.name}
                    refresh={refresh}
                  />,
                  'md',
                )
              }
            >
              Delete vendor
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      ),
      right: true,
    },
  ];

  const customTable = ({
    counterparties,
    dataLoaded,
    setResultsPerPage,
    count,
  }: Props) => {
    useEffect(() => {
      setResultsPerPage(count);
    }, [count]);

    const enrichedAndFilteredData = useMemo(() => {
      if (!dataLoaded) return [];

      const enrichedCounterparties = enrichCounterparties(counterparties);
      return enrichedCounterparties.filter((t) =>
        activeFiltersArray.every((f) => f.fn(t)),
      );
    }, [counterparties, dataLoaded, activeFiltersArray]);
    if (!dataLoaded || (count > 0 && enrichedAndFilteredData.length === 0)) {
      return <SkeletonLoading />;
    }

    return (
      <FlexbaseTable
        onRowClicked={(row) => handleRowClicked(row.foreignId)}
        columns={columns}
        data={enrichedAndFilteredData}
      />
    );
  };

  return (
    <Box className={classes.card}>
      <VendorHeader />
      <Counterparties type="payee">
        {({
          counterparties,
          dataLoaded,
          resultsPerPage,
          setResultsPerPage,
          count,
        }) => (
          <>
            {customTable({
              counterparties,
              resultsPerPage,
              dataLoaded,
              setResultsPerPage,
              count,
            })}
          </>
        )}
      </Counterparties>
    </Box>
  );
};

export default Vendors;
