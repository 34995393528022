import { GiPauseButton } from 'react-icons/gi';
import { IoIosArrowDown } from 'react-icons/io';
import { BsFillPlayFill } from 'react-icons/bs';
import { Button, Group, Menu, Tooltip } from '@mantine/core';
import { useRecoilValue } from 'recoil';
import { UserIdState } from 'areas/onboarding/onboarding-form.state';
import { IsAdmin } from '../../../../states/application/product-onboarding';
import { CreditCard } from '@services/flexbase/card.model';

type CardAction = 'freeze' | 'thaw' | 'cancel' | 'edit' | 'activate';

type Props = {
  card: CreditCard;
  onCardActionClick: (action: CardAction) => void;
  showLoadingDots: boolean;
};

const CreditCardActions = ({
  onCardActionClick,
  card,
  showLoadingDots,
}: Props) => {
  const userId = useRecoilValue(UserIdState);
  const isAdmin = useRecoilValue(IsAdmin);

  const disableActionButton =
    (card.status === 'issued' || card.status === 'requested') &&
    card.userId !== userId;
  let actionButtonLabel: string;
  let actionButtonAction: CardAction;

  switch (card.status) {
    case 'issued':
    case 'requested':
      actionButtonLabel = 'Activate';
      actionButtonAction = 'activate';
      break;
    case 'suspended':
      actionButtonLabel = 'Unfreeze';
      actionButtonAction = 'thaw';
      break;
    default:
      actionButtonLabel = 'Freeze';
      actionButtonAction = 'freeze';
      break;
  }

  return (
    <Group sx={{ justifyContent: 'space-between' }}>
      {(isAdmin || userId === card.userId) && (
        <Tooltip
          withArrow
          disabled={!disableActionButton}
          label="The owner of this card is the only one who can activate it."
        >
          <Button
            onClick={() => onCardActionClick(actionButtonAction)}
            loading={showLoadingDots}
            disabled={disableActionButton}
            leftIcon={
              card.status === 'active' ? <GiPauseButton /> : <BsFillPlayFill />
            }
            data-testid="update-card-status-freeze-unfreeze"
            sx={{
              '&[data-disabled]': { pointerEvents: 'unset' },
            }}
          >
            {actionButtonLabel}
          </Button>
        </Tooltip>
      )}
      {isAdmin && (
        <Menu>
          <Menu.Target>
            <Button
              variant="subtle"
              c="primarySecondarySuccess.8"
              rightIcon={<IoIosArrowDown />}
            >
              More
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              onClick={() => onCardActionClick('edit')}
              data-testid="edit-card"
            >
              Edit card
            </Menu.Item>
            <Menu.Item
              onClick={() => onCardActionClick('cancel')}
              data-testid="cancel-card"
            >
              Cancel card
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      )}
    </Group>
  );
};

export default CreditCardActions;
