import { TextInput, TextInputProps } from '@mantine/core';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

export type FlexNumberInputProps = Omit<
  TextInputProps,
  'type' | 'value' | 'defaultValue'
> &
  NumericFormatProps;

const FlexNumberInput = (props: FlexNumberInputProps) => {
  return <NumericFormat {...props} customInput={TextInput} />;
};

export default FlexNumberInput;
