import CardForm from './card-form';
import FullScreenModalContainer from 'components/modal/full-screen-modal-container';
import { useStyles } from '../styles';
import {
  SpendPlanAdminView,
  SpendPlanView,
} from '@flexbase-eng/types/dist/accounting';

type Props = {
  card?: any;
  closeModal: () => void;
  addCompanyCard: () => void;
  spendPlan?: SpendPlanAdminView | SpendPlanView;
  method?: 'credit' | 'spendPlan';
};

const IssueCardHeader = ({
  card,
  closeModal,
  addCompanyCard,
  spendPlan,
  method = 'credit',
}: Props) => {
  const { classes } = useStyles();

  return (
    <FullScreenModalContainer closeModal={closeModal}>
      <div className={classes.issueCardContainer} data-testid="issue-card">
        <CardForm
          {...{ closeModal, card, addCompanyCard, spendPlan, method }}
        />
      </div>
    </FullScreenModalContainer>
  );
};

export default IssueCardHeader;
