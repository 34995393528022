import OnboardingErrorBoundary from '../components/onboarding-error-boundary.component';
import { LoadingOverlay } from '@mantine/core';
import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { FacebookPixel } from 'services/analytics/pixel';

const ProductApplicationErrorBoundary = () => {
  const location = useLocation();
  useEffect(() => {
    FacebookPixel.pageView();
  }, [location]);

  return (
    <OnboardingErrorBoundary>
      <React.Suspense fallback={<LoadingOverlay visible={true} />}>
        <Outlet />
      </React.Suspense>
    </OnboardingErrorBoundary>
  );
};

export default ProductApplicationErrorBoundary;
