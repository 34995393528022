import { OptedProduct } from '../../../states/application/product-onboarding';

export type CreateAccountResponse = {
  accountId: string;
  personId: string;
};

export type CreateAccountRequest = {
  userName: string;
  password?: string;
  referral?: Referral;
  promoCode?: string;
  name?: string;
  client_id?: string;
};

export type Referral = {
  referrerId: string;
  personId?: string;
  businessId?: string;
};

export type AuthorizeTokenRequest = {
  username: string;
  password: string;
  grant_type:
    | 'client_credentials'
    | 'password'
    | 'refresh_token'
    | 'link'
    | 'otp'
    | 'totp'
    | 'api';
  scope?: string;
  client_id?: string;
};

export type AuthorizeTokenResponse = {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token?: string;
  scope: string;
  challenge_phone_number_last_four: string;
};

type PlatformErrorMessage = {
  statusCode: number;
  message: string;
  errors?: Record<string, string[] | string>;
};

const _defaultErrorMessage: PlatformErrorMessage = {
  statusCode: 999,
  message: 'Unknown error',
};

export function ParsePlatformError(error: any): PlatformErrorMessage {
  if (error.message) {
    try {
      return JSON.parse(error.message);
    } catch (e) {
      return _defaultErrorMessage;
    }
  }

  return _defaultErrorMessage;
}

export type PlatformAddress = {
  id: string;
  isPrimary: boolean;
  verificationDate: string;
  verificationMethod: string;
  active: boolean;
  type: string;
  street: string[];
  locality: string;
  region: string;
  postalCode: string;
  country: string;
  createdAt: string;
  lastModifiedAt: string;
  lastModifiedBy: string;
};

export type UpdatePlatformAddressRequest = Omit<
  PlatformAddress,
  | 'id'
  | 'createdAt'
  | 'lastModifiedAt'
  | 'lastModifiedBy'
  | 'verificationDate'
  | 'verificationMethod'
>;

export type PlatformEmail = {
  id: string;
  value: string;
  type: string;
  isPrimary: boolean;
  verificationDate: string;
  verificationMethod: string;
  active: boolean;
  createdAt: string;
  lastModifiedAt: string;
  lastModifiedBy: string;
};

export type PlatformPerson = {
  id: string;
  accountId: string;
  active: boolean;
  givenName: string;
  familyName: string;
  displayName: string;
  userType: string;
  preferredLanguage: string;
  locale: string;
  timezone: string;
  dateOfBirth: string;
  nationality: string;
  taxId: string;
  lastLogin: string;
  phone: PlatformPhone;
  address: PlatformAddress;
  email: PlatformEmail;
};

export type PlatformInviteResponse = {
  accountId: string;
  person: PlatformInvitePerson;
  authenticationId: string;
};

export type PlatformInvitePerson = {
  createdAt: string;
  lastModifiedAt: string;
  lastModifiedBy: string;
  sysPeriod: string;
  id: string;
  accountId: string;
  active: boolean;
};

export type PlatformInvite = {
  accountId: string;
  personId: string;
  inviterId: string;
  accepted: boolean;
  invitationType: string;
  createdAt: string;
  lastModifiedAt: string;
  lastModifiedBy: string;
};

export type ListPlatformInvitationsResponse = {
  metadata: Metadata;
  data: PlatformInvite[];
};

export type PlatformPhone = {
  id: string;
  value: string;
  type: string;
  isPrimary: boolean;
  verificationDate: string;
  verificationMethod: string;
  active: boolean;
  createdAt: string;
  lastModifiedAt: string;
  lastModifiedBy: string;
};

export type UpdatePlatformPersonRequest = Partial<
  Omit<PlatformPerson, 'id' | 'phone' | 'active'>
>;

export type UpdatePlatformPersonResponse = Omit<PlatformPerson, 'phone'>;

export type CreatePlatformPersonPhoneRequest = Pick<
  PlatformPhone,
  'value' | 'type' | 'isPrimary' | 'active'
>;

export type ListApplicationsResponse =
  PlatformListResponse<PlatformApplication>;

export type PlatformMetadata = {
  total: number;
  page: number;
  pageSize: number;
  sort: string;
  nextToken: string;
};

export type PlatformListResponse<T> = {
  metadata: PlatformMetadata;
  data: T[];
};

export type PlatformApplication = {
  applicationId: string;
  accountId: string;
  product: string;
  status: string;
  createdAt: string;
  submittedBy: string;
  submittedAt: string;
  reviewer: string;
};

export type PlatformProduct = {
  name: string;
  phase: string;
  applicationStatus: string;
  applicationId: string;
  incomplete: string[];
};

export type ListPlatformProductsResponse = PlatformProduct[];

export type PlatformBusiness = {
  id: string;
  accountId: string;
  active: boolean;
  name: string;
  dba: string;
  taxId: string;
  formationDate: string;
  formationLocation: string;
  type: string;
  website: string;
  industry: string;
  sicCode: string;
  naicsCode: string;
  duns: string;
  mcc: string;
};

export type PlatformBusinessOwner = {
  personId: string;
  businessId: string;
  percentage: number;
  createdAt: string;
  lastModifiedAt: string;
  lastModifiedBy: string;
};

export type PlatformAccountsInfo = {
  id: string;
  accountId: string;
  name: string;
  tenantId: string;
  active: boolean;
  businessId: string;
  createdAt: string;
};

export type CrupdatePlatformBusinessRequest = Partial<
  Omit<PlatformBusiness, 'id' | 'active' | 'name' | 'accountId'>
> & {
  name: string;
  active: boolean;
};

type Metadata = {
  total: number;
  page: number;
  pageSize: number;
  sort: string;
  nextToken: string;
};

export type ListBusinessesResponse = {
  metadata: Metadata;
  data: PlatformBusiness[];
};

export type UserAccountsResponse = {
  metadata: Metadata;
  data: PlatformAccountsInfo[];
};

export type PromoCode = {
  id: string;
  value: string;
  description: string;
  products: OptedProduct[];
  validDateStart: string;
  validDateEnd: string;
};

export type PersonPrefillResponse = {
  prefilled: boolean;
  prefilledValues: {
    givenName: string;
    familyName: string;
    taxId: string;
    dateOfBirth: string;
  };
  addressSelection: {
    street: [string, string | undefined];
    locality: string;
    postalCode: string;
    region: string;
    country: string;
  }[];
};

export type PrefillBusinessResponse = {
  businesses: PrefillBusiness[];
};

export type PrefillBusiness = {
  business: PrefillBusinessInformation;
  addresses: PrefillBusinessAddress[];
  owners: PrefillBusinessOwner[];
};

export type PrefillBusinessInformation = {
  name: string;
  dba: string;
  taxId: string;
  formationDate: string;
  formationLocation: string;
};

export type PrefillBusinessAddress = {
  street: string;
  locality: string;
  region: string;
  postalCode: string;
};

export type PrefillBusinessOwner = {
  givenName: string;
  familyName: string;
  title: string;
};
