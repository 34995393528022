import { DateRangeFilter } from '@common/filter/date-range.filter';
import { DateRangeFilterType } from '@common/filter/filters';
import { DateTime, Interval } from 'luxon';
import { useSideModalFilters } from './use-side-modal-filters';
import { useDetailSummaryDateFilters } from './use-detail-summary-date-filters';

const filterFn = (
  startDate: DateTime<boolean>,
  endDate: DateTime<boolean>,
  item: any,
) => {
  const dateRange = Interval.fromDateTimes(startDate, endDate);
  return dateRange.contains(DateTime.fromSQL(item.createdAt));
};

export const DetailSummaryDateRangeFilter = () => {
  const primaryPageFilter = useDetailSummaryDateFilters();
  const sideModalFilter = useSideModalFilters();

  //This custom config allows us to use one date ranger to control 2 different filters
  const onDateRangeChange = (dateRange: DateRangeFilterType) => {
    const option = dateRange.option;
    const start = dateRange.startDate;
    const end = dateRange.endDate;

    if (start && end) {
      primaryPageFilter.addFilter('date', {
        key: 'date',
        fn: (item) => filterFn(start, end, item),
        label: `${start.toLocaleString(
          DateTime.DATE_SHORT,
        )} - ${end.toLocaleString(DateTime.DATE_SHORT)}`,
        filterValue: { startDate: start, endDate: end, option },
        showChip: true,
      });
      sideModalFilter.addFilter('date', {
        key: 'date',
        fn: (item) => filterFn(start, end, item),
        label: `${start.toLocaleString(
          DateTime.DATE_SHORT,
        )} - ${end.toLocaleString(DateTime.DATE_SHORT)}`,
        filterValue: { startDate: start, endDate: end, option },
        showChip: true,
      });
    } else if (!start && !end) {
      sideModalFilter.removeFilter('date');
      primaryPageFilter.removeFilter('date');
    }
  };

  return (
    <DateRangeFilter
      filterHook={primaryPageFilter}
      filterFn={filterFn}
      customConfiguration={{ onDateRangeChange }}
    />
  );
};
