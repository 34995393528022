import { ReactNode, useState } from 'react';
import {
  Alert,
  Button,
  createStyles,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';

import { ChevronDownIcon, RedAlertIcon } from 'assets/svg';

type AuthContentProps = {
  title: string;
  screen: string;
  errorMsg: string | ReactNode;
  children: ReactNode;
  onGoback: () => void;
  onSubmit: () => void;
  onResendCode?: () => void;
  submitButtonLabel: string;
  lastFour?: string;
};

const AuthContent = ({
  title,
  screen,
  onGoback,
  children,
  errorMsg,
  onResendCode,
  submitButtonLabel,
  lastFour,
}: AuthContentProps) => {
  const { classes } = useStyles();
  const [resend, setResend] = useState(false);
  const theme = useMantineTheme();
  const [showBuild, setShowBuild] = useState({
    count: 0,
    show: false,
  });

  const showBuildOnClick = () => {
    if (showBuild.count === 4) {
      setShowBuild({
        count: 5,
        show: true,
      });
    } else if (showBuild.count > 4) {
      setShowBuild({
        count: 1,
        show: false,
      });
    } else {
      setShowBuild({ ...showBuild, count: showBuild.count + 1 });
    }
  };

  return (
    <div>
      <div onClick={onGoback} className={classes.goBackSection}>
        <ChevronDownIcon />
        Back
      </div>

      <Title onClick={showBuildOnClick} className={classes.title}>
        {title}
      </Title>

      <Stack my={30} spacing="xl">
        {lastFour && (
          <Text className={classes.textLine}>
            A 6 digit verification code has been {resend ? 're-sent' : 'sent'}{' '}
            to the phone number ending in {lastFour}
          </Text>
        )}
        {children}
      </Stack>

      {errorMsg && (
        <Alert
          icon={<RedAlertIcon />}
          className={classes.alert}
          my="lg"
          withCloseButton={false}
        >
          {errorMsg}
        </Alert>
      )}
      <Button
        fullWidth
        type="submit"
        variant="light"
        id="auth-submit"
        data-testid="button-auth-submit"
      >
        {submitButtonLabel}
      </Button>

      {screen === '2FA' && onResendCode && (
        <Text
          onClick={() => {
            onResendCode();
            setResend(true);
          }}
          className={classes.reSendCode}
        >
          Re-send code
        </Text>
      )}

      {showBuild.show && (
        <Text
          mt={15}
          color={theme.colors.primarySecondarySuccess[2]}
          inherit
          component="span"
        >
          Build ID: {import.meta.env.VITE_APP_GITHUB_SHA || 'LOCAL'}
        </Text>
      )}
    </div>
  );
};

export default AuthContent;

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: '48px',
    fontWeight: 400,
    lineHeight: 'normal',
    fontFamily: 'Redaction',
    color: theme.fn.themeColor('neutral', 0),
    '@media (max-width: 768px)': {
      fontSize: '32px',
    },
  },
  goBackSection: {
    gap: 10,
    display: 'flex',
    cursor: 'pointer',
    marginBottom: '20px',
    alignItems: 'center',
    color: theme.fn.themeColor('neutral', 0),
    '&:hover': {
      textDecoration: 'underline',
    },
    svg: {
      width: '20px',
      height: '20px',
      transform: 'rotate(90deg)',
      '@media (max-width: 768px)': {
        width: '15px',
        height: '15px',
      },
    },
  },
  reSendCode: {
    fontSize: '14px',
    cursor: 'pointer',
    marginTop: '10px',
    color: theme.fn.themeColor('neutral', 0),
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  alert: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19px',
    padding: theme.spacing.sm,
    color: theme.colors.neutral[9],
    borderRadius: theme.defaultRadius,
    background: theme.fn.themeColor('critical', 0),
    border: `1px solid ${theme.fn.themeColor('critical', 2)}`,
  },
  textLine: {
    color: theme.fn.themeColor('neutral', 0),
  },
}));
