import { Box, Select } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import { DatePickerInput as _DatePickerInput } from '@mantine/dates';
import { DateTime } from 'luxon';
import { DateRangeFilterType } from '@common/filter/filters';

// workaround for existing bug in @mantine v6: https://github.com/mantinedev/mantine/issues/5401
const DatePickerInput = _DatePickerInput as any;

type Props = {
  dateRange: DateRangeFilterType;
  setDateRange: (dateRange: DateRangeFilterType) => void;
};

type MantineDatePickerValues = [Date | null, Date | null];

export const DateRangeSelection = ({ dateRange, setDateRange }: Props) => {
  const [datePickerValue, setDatePickerValue] =
    useState<MantineDatePickerValues>([
      dateRange.startDate?.toJSDate() || null,
      dateRange.endDate?.toJSDate() || null,
    ]);
  const startDatePickerRef = useRef<HTMLButtonElement>(null);
  const endDatePickerRef = useRef<HTMLButtonElement>(null);

  // This resets the datePickerValue if the date range changes outside of this component
  useEffect(() => {
    // the `|| null` is here because undefined !== null and will lead to this triggering when it shouldn't
    if (
      (dateRange.startDate?.toJSDate().getTime() || null) !==
        datePickerValue[0]?.getTime() ||
      (dateRange.endDate?.toJSDate().getTime() || null) !==
        datePickerValue[1]?.getTime()
    ) {
      setDatePickerValue([
        dateRange.startDate?.toJSDate() || null,
        dateRange.endDate?.toJSDate() || null,
      ]);
    }
  }, [dateRange]);

  const onSelectDate = (value: string | null) => {
    setDatePickerValue([null, null]);
    if (!value || value === 'All time') {
      setDateRange({ option: 'All time', startDate: null, endDate: null });
    } else {
      if (value !== 'Custom') {
        setDatePickerValue([null, null]);
      }

      if (!value || value === 'All time') {
        setDateRange({ option: 'All time', startDate: null, endDate: null });
      } else if (value !== 'Custom') {
        let endDate = DateTime.now();
        let startDate: DateTime;
        switch (value) {
          case 'Last week':
            startDate = endDate.minus({ weeks: 1 });
            break;
          case 'Last 30 days':
            startDate = endDate.minus({ days: 30 });
            break;
          case 'Last month':
            endDate = DateTime.now().minus({ months: 1 }).endOf('month');
            startDate = endDate.startOf('month');
            break;
          case 'This month':
            startDate = endDate.startOf('month');
            break;
        }
        setDateRange({ option: value, startDate: startDate!, endDate });
        setDatePickerValue([startDate!.toJSDate(), endDate.toJSDate()]);
      } else if (value === 'Custom') {
        startDatePickerRef?.current?.click();
      }
    }
  };

  const onDatePicked = (values: MantineDatePickerValues) => {
    setDatePickerValue(values);
    if (values[0] && values[1]) {
      const startDate = DateTime.fromJSDate(values[0]);
      const endDate = DateTime.fromJSDate(values[1]);
      setDateRange({ option: 'Custom', startDate, endDate });
    } else if (values.every((v) => !v)) {
      // If both values are null, this indicates the input was cleared
      setDateRange({ option: 'All time', startDate: null, endDate: null });
    }
  };

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        gap: theme.spacing.md,
        flexWrap: 'nowrap',
        [theme.fn.smallerThan('sm')]: {
          flexWrap: 'wrap',
        },
      })}
    >
      <Select
        data={[
          'All time',
          'Last week',
          'Last 30 days',
          'Last month',
          'This month',
          'Custom',
        ]}
        value={dateRange.option}
        onChange={(value) => onSelectDate(value)}
        w={150}
      />
      <DatePickerInput
        w={150}
        placeholder="Start date"
        value={datePickerValue[0]}
        onChange={(d: Date) => {
          onDatePicked([d, datePickerValue[1]]);
          if (d && !datePickerValue[1]) {
            endDatePickerRef.current?.click();
          }
        }}
        ref={startDatePickerRef}
      />
      <DatePickerInput
        w={150}
        placeholder="End date"
        minDate={datePickerValue[0] || undefined}
        value={datePickerValue[1]}
        onChange={(d: Date) => onDatePicked([datePickerValue[0], d])}
        ref={endDatePickerRef}
      />
    </Box>
  );
};
