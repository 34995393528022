import { SpendPlanMemberFields } from '../create/steps/team-members/team-members-step.context';

type DeriveAssignedValueArgs = {
  member: SpendPlanMemberFields;
  planLimit: number;
};

export function deriveCurrentAssigned({
  member,
  planLimit,
}: DeriveAssignedValueArgs): number {
  if (member.fullFundsAccess) {
    return planLimit;
  }

  return member.currentAssigned;
}

type DeriveCurrentAssignedErrorArgs = {
  member: SpendPlanMemberFields;
  planLimit: number;
};

export function deriveCurrentAssignedError({
  member,
  planLimit,
}: DeriveCurrentAssignedErrorArgs) {
  const value = deriveCurrentAssigned({ member, planLimit });

  return value > planLimit;
}

export function deriveFutureAssigned(args: DeriveAssignedValueArgs): number {
  const { member } = args;
  if (member.repeatFundsAccess) {
    return deriveCurrentAssigned(args);
  }

  return member.assigned;
}

type DeriveFutureAssignedErrorArgs = DeriveCurrentAssignedErrorArgs & {
  planFutureLimit: number;
};

export function deriveFutureAssignedError({
  member,
  planLimit,
  planFutureLimit,
}: DeriveFutureAssignedErrorArgs) {
  const value = deriveFutureAssigned({ member, planLimit });

  return value > planFutureLimit;
}
