import { ApplicationStatus } from '../../../states/application/product-onboarding.models';
import { StatusBox } from './status-box';
import { Button, Group } from '@mantine/core';
import { useLogout } from '@services/logout/logout-hook';
import { useRouteSectionContext } from '@common/routes/route-context';

type Props = {
  productStatus: ApplicationStatus;
  hasOutstandingOwners: boolean;
  hasPromoCode: boolean;
};
export const CreditStatusBox = ({
  productStatus,
  hasOutstandingOwners,
  hasPromoCode,
}: Props) => {
  const logout = useLogout();
  const { setShowRoutesFor } = useRouteSectionContext();
  const creditStatus = productStatus.credit.status;
  let description =
    'We are processing your application and will let you know our decision within 5 business days.';

  if (
    creditStatus === 'unqualified' &&
    productStatus.credit.reason &&
    !hasPromoCode
  ) {
    const reason = productStatus.credit.reason.toLowerCase();
    if (reason.includes('company state') || reason.includes('user state')) {
      description = `We currently don't offer this credit card in your state. We will get in touch when that changes!`;
    } else if (reason.includes('user fico')) {
      description = `Unfortunately, we are unable to approve you for a business credit card. We will be emailing you a letter explaining in detail the decline reason(s).`;
    } else if (reason.includes('company type')) {
      description = `We currently don't offer this credit card to sole proprietorships. We will be in touch if that changes!`;
    }
  } else if (creditStatus === 'pending' && hasOutstandingOwners) {
    description =
      'All added beneficial owners must login and enter their information before we can process your credit application.';
  }

  return (
    <StatusBox title="Flex Credit" description={description}>
      <Group>
        {creditStatus === 'pending' && (
          <Button onClick={() => setShowRoutesFor('main')}>
            View Dashboard
          </Button>
        )}
        <Button
          variant="outline"
          bg="neutral.0"
          sx={(theme) => ({
            '&:not([data-disabled])': theme.fn.hover({
              backgroundColor: theme.fn.themeColor('neutral', 0),
            }),
          })}
          onClick={() => logout()}
        >
          Close Application
        </Button>
      </Group>
    </StatusBox>
  );
};

/**
 * These are all of the reasons that a credit app can be unqualified.
 *  reason: `The Company State ${company.state}, is not one we are licensed to operate in.`,
 *  reason: `The User State ${user.state}, is not one we are licensed to operate in.`,
 *  reason: `The User FICO of ${fico.score}, is below the ejection limit for even Level 1.`,
 *  reason: `The Company Type of ${company.additionalInfo?.companyType} is not supported for CREDIT at this time.`,
 *  reason: `The User's DOB: ${user.dob} is not valid, or under the legal limit for loan applications.`,
 *
 */
