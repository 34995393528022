import { hideNotification, showNotification } from '@mantine/notifications';
import {
  NotificationAlert,
  NotificationAlertProps,
} from './components/notification.alert';

type ShowNotificationAlertOptions = Pick<
  NotificationAlertProps,
  'title' | 'color' | 'icon' | 'action'
> & {
  id: string;
  message: NotificationAlertProps['children'];
  autoClose?: boolean;
};

/**
 * Hook with methods to show a notification styled as an Alert, with an optional action button.
 */
export const useNotificationAlert = () => {
  const showNotificationAlert = (options: ShowNotificationAlertOptions) => {
    showNotification({
      id: options.id,
      message: (
        <NotificationAlert
          title={options.title}
          color={options.color}
          icon={options.icon}
          action={options.action}
          onClose={() => hideNotification(options.id)}
        >
          {options.message}
        </NotificationAlert>
      ),
      autoClose: options.autoClose,
      withBorder: false,
      withCloseButton: false,
      styles: {
        root: {
          '&::before': {
            display: 'none',
          },
          padding: 0,
        },
        body: {
          margin: 0,
        },
        title: {
          display: 'none',
        },
      },
    });

    return {
      id: options.id,
      close: () => hideNotification(options.id),
    };
  };

  return {
    showNotificationAlert,
  };
};
