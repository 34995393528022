import { useState } from 'react';
import GetPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import { createStyles } from '@mantine/core';
import { EyeClosedIcon, EyeOpenIcon } from 'assets/svg';

export const AccountMaskCell = ({
  accountNumber,
}: {
  accountNumber: string;
}) => {
  const { classes } = useStyles();
  const [hideAccount, setHideAccount] = useState(true);

  return (
    <div
      className={classes.inlineCell}
      onClick={() => setHideAccount((p) => !p)}
      style={{ cursor: 'pointer' }}
    >
      <div className={classes.contentText}>
        {hideAccount ? GetPaddedAccountMask(accountNumber, 5) : accountNumber}
      </div>
      {hideAccount ? (
        <EyeOpenIcon color="#85868D" width={15} height={15} />
      ) : (
        <EyeClosedIcon color="#85868D" width={15} height={15} />
      )}
    </div>
  );
};

export const useStyles = createStyles(() => ({
  inlineCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
    flexShrink: 0,
  },
  contentText: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '21px',
    color: '#5f5f5f',
  },
}));

export default AccountMaskCell;
