import { Button } from '@mantine/core';
import { ButtonProps } from '@mantine/core/lib/Button/Button';

type Props = { form: string } & Omit<ButtonProps, 'type'>;

export const MultiStepFormSubmitButton = ({
  children,
  form,
  ...buttonProps
}: Props) => {
  return (
    <Button form={form} type="submit" {...buttonProps}>
      {children}
    </Button>
  );
};
