import { useRecoilValue } from 'recoil';
import useEditModals from 'components/modal/edit-modal-hook';
import SettingsSection from './settings-section';
import PlainTextAddress from 'components/address/plain-text-address';
import {
  CompanySelector,
  MainOwnerSelector,
} from 'states/application/product-onboarding';
import { useHasEmployeeRole } from './util/hooks';

const CompanySettingsList = () => {
  const company = useRecoilValue(CompanySelector);
  const editModals = useEditModals();
  const mainOwner = useRecoilValue(MainOwnerSelector);
  const hasEmployeeRole = useHasEmployeeRole();

  return (
    <div>
      <SettingsSection
        field={'Business legal name'}
        value={company.companyName}
        editable={false}
        showDivider={true}
      />

      <SettingsSection
        field={'Business website'}
        value={company.website}
        editable={!hasEmployeeRole}
        onEditClick={editModals.openEditCompanyWebsiteModal}
        showDivider={true}
      />
      {company.taxId && (
        <SettingsSection
          field={'Business EIN'}
          value={company.taxId}
          editable={false}
          showDivider={true}
        />
      )}

      {company.address && (
        <SettingsSection
          field={'Business address'}
          value={
            <PlainTextAddress
              address={{
                line1: company.address.line1,
                line2: company.address.line2 || '',
                city: company.address.city,
                state: company.address.state,
                postalCode: company.address.postalCode,
                country: company.address.country,
              }}
            />
          }
          editable={false}
          onEditClick={editModals.openEditCompanyAddressModal}
          showDivider={true}
        />
      )}

      {mainOwner.firstName && (
        <SettingsSection
          field="Business owner's name"
          value={`${mainOwner.firstName} ${mainOwner.lastName}`}
          editable={false}
          showDivider={true}
        />
      )}

      {mainOwner.jobTitle && (
        <SettingsSection
          field="Business owner's title"
          value={mainOwner.jobTitle}
          editable={false}
          showDivider={true}
        />
      )}
    </div>
  );
};

export default CompanySettingsList;
