import { RightContentModal } from '@common/modal/right-content.modal';
import { Box, Flex, Group, Text } from '@mantine/core';
import { FiCalendar } from 'react-icons/fi';
import { DateTime } from 'luxon';
import { formatCurrency } from '@utilities/formatters/format-currency';

type Props = {
  projectedDueAmounts: {
    earlyPayDate: string;
    projectedDue: number;
  }[];
};

export const EarlyPayScheduleDrawer = ({ projectedDueAmounts }: Props) => {
  return (
    <RightContentModal>
      <RightContentModal.SingleTitleHeader
        title="Early pay schedule"
        subtitle="Upcoming automatic early payments"
      />
      <RightContentModal.Body>
        <RightContentModal.TitleValueLine
          title={<Text fz={20}>Upcoming payments</Text>}
          value={
            <Text fz={14}>Amounts estimated based on credit activity</Text>
          }
        />
        <Box mt="md">
          {projectedDueAmounts.map((p) => (
            <Box key={p.earlyPayDate}>
              <Group w="100%">
                <Flex
                  c="promote.5"
                  bg="promote.0"
                  h="2rem"
                  w="2rem"
                  align="center"
                  justify="center"
                  sx={{ borderRadius: '4px' }}
                >
                  <FiCalendar />
                </Flex>
                <Box>
                  <Text fz={16}>
                    {DateTime.fromISO(p.earlyPayDate).toLocaleString({
                      month: 'short',
                      day: 'numeric',
                    })}
                  </Text>
                  <Text fz={12} lh="1">
                    in{' '}
                    {Math.floor(
                      DateTime.fromISO(p.earlyPayDate).diffNow('days').days,
                    )}{' '}
                    days
                  </Text>
                </Box>
                <Text ml="auto" fz={20} fw={500}>
                  {formatCurrency(p.projectedDue)}
                </Text>
              </Group>
              <RightContentModal.Divider mx={0} my={24} />
            </Box>
          ))}
        </Box>
      </RightContentModal.Body>
    </RightContentModal>
  );
};
