import {
  Alert,
  Box,
  Group,
  LoadingOverlay,
  SelectItem,
  Stack,
} from '@mantine/core';
import { useGetUsers } from '@queries/use-users';
import { Employees } from 'states/onboarding/onboarding-info';
import { useCreateSpendPlanContext } from '../../create-spend-plan.context';
import { useSpendPlanMobile } from '../../../utils/use-spend-plan-mobile';
import { useTeamMemberStepStyles } from './team-members-step.style';
import { noop } from 'underscore';
import {
  CreateSpendPlanWizard,
  useCreateSpendPlanWizard,
} from '../../create-spend-plan.wizard';
import { TeamMembersTableCompact } from './components/team-members-table';
import { TeamMemberSelect } from './components/team-members-select';
import { CrownIcon } from 'assets/svg';
import { SpendPlanReviewStep } from '../review/review-step';

const STEP_ID = 'team-members-step';

function userWithId(u: Employees): u is Employees & { id: string } {
  return !!u.id;
}

export const SpendPlansTeamMembersStep = () => {
  const isMobile = useSpendPlanMobile();
  const { teamMembersForm: form, getInvalidSteps } =
    useCreateSpendPlanContext();
  const {
    goToNextStep,
    goToStep,
    getLastVisitedStep,
    state,
    navigatedFromPreview,
  } = useCreateSpendPlanWizard();
  const { classes } = useTeamMemberStepStyles();
  const {
    data: users,
    isPending: isUsersPending,
    isError: isUsersError,
  } = useGetUsers();

  const membersError = form.getInputProps('members').error;

  const isFromReviewPreview =
    navigatedFromPreview &&
    getLastVisitedStep()?.id === SpendPlanReviewStep.stepId;

  const sortedMembers = state.members.toSorted((a, b) => {
    const aVal = a.isManager ? 1 : 0;
    const bVal = b.isManager ? 1 : 0;

    return bVal - aVal;
  });

  const handleNext = form.onSubmit((formValues) => {
    const invalidSteps = getInvalidSteps().filter((s) => s !== STEP_ID);

    if (invalidSteps.length) {
      return goToStep(invalidSteps[0], formValues);
    }

    if (isFromReviewPreview) {
      return goToStep(SpendPlanReviewStep.stepId, formValues);
    }

    goToNextStep(formValues);
  });

  if (isUsersError) {
    return (
      <CreateSpendPlanWizard.Step onNext={noop}>
        Something went wrong
      </CreateSpendPlanWizard.Step>
    );
  }

  if (isUsersPending) {
    return <LoadingOverlay visible />;
  }

  const membersSelectItems = users
    .filter((user) => {
      return !state.members.some((member) => user.id === member.id);
    })
    .filter(userWithId)
    .map<SelectItem>((user) => {
      const { id, firstName, lastName } = user;

      return {
        label: `${firstName} ${lastName}`,
        value: id,
      };
    });

  const handleAddMembers = (selectedUsers: Employees[], isManager: boolean) => {
    selectedUsers = selectedUsers.filter(Boolean);

    if (!selectedUsers.length) {
      return;
    }

    form.setFieldValue('members', [
      ...state.members,
      ...selectedUsers.map((user) => {
        return {
          id: user?.id || '',
          firstName: user?.firstName || '',
          lastName: user?.lastName || '',
          email: user?.email || '',
          isManager,
          fullFundsAccess: false,
          currentAssigned: 0,
          repeatFundsAccess: false,
          assigned: 0,
        };
      }),
    ]);
  };

  const createMemberSelectHandler = (isManager: boolean) => {
    return (id: string | null) => {
      const selectedUsers = users.filter((u) => u.id === id);

      handleAddMembers(selectedUsers, isManager);
    };
  };

  const createAddAllHandler = (isManager: boolean) => {
    return () => {
      const selectedUsers = users.filter((u) => {
        return membersSelectItems.find((item) => item.value === u.id);
      });

      handleAddMembers(selectedUsers, isManager);
    };
  };

  return (
    <CreateSpendPlanWizard.Step
      onNext={handleNext}
      hideBack={isFromReviewPreview}
    >
      <Stack spacing={24}>
        {membersError ? (
          <Alert color="failure" withCloseButton={false}>
            {membersError}
          </Alert>
        ) : null}

        <TeamMemberSelect
          label={
            <Group spacing="xxs" align="center" noWrap>
              Manager(s)
              <CrownIcon height={20} width={20} style={{ flexShrink: 0 }} />
            </Group>
          }
          description="Manager(s) can edit spend plans and approve additional fund requests."
          data={membersSelectItems}
          error={form.getInputProps('managers').error}
          onChange={createMemberSelectHandler(true)}
          onAddAll={createAddAllHandler(true)}
        />

        <TeamMemberSelect
          label="Member(s)"
          description="Members can spend money from this spend plan"
          data={membersSelectItems}
          onChange={createMemberSelectHandler(false)}
          onAddAll={createAddAllHandler(false)}
        />

        {isMobile && sortedMembers.length ? (
          <Box className={classes.mobileMembersPanel}>
            <TeamMembersTableCompact />
          </Box>
        ) : null}
      </Stack>
    </CreateSpendPlanWizard.Step>
  );
};

SpendPlansTeamMembersStep.stepId = STEP_ID;
