import { Box, Group, Radio, Select, Text, TextInput } from '@mantine/core';
import { forwardRef } from 'react';
import CurrencySelect from '../components/currency-select';
import { useSubmitByRef } from './hooks/use-submit-by-ref';
import { countrySelectOptions, isInternationalWire } from './util';
import {
  InternationalPayMethod,
  InternationalPaymentRecipientFormRef,
  InternationalRecipientForm,
} from './util/types';

type Props = {
  method: InternationalPayMethod;
  form: InternationalRecipientForm;
  isNewRecipient: boolean;
};

const InternationalPaymentAccountForm = forwardRef<
  InternationalPaymentRecipientFormRef,
  Props
>(({ method, form, isNewRecipient }, ref) => {
  useSubmitByRef(form, ref);

  return (
    <Box my="1rem">
      {isInternationalWire(method) && (
        <CurrencySelect mt={'md'} {...form.getInputProps('currency')} />
      )}

      <Select
        mt={'md'}
        label="Country of recipient bank"
        placeholder="Enter country of the recipient's bank"
        data={countrySelectOptions}
        searchable
        {...form.getInputProps('countryOfRecipientsBank')}
      />

      <TextInput
        mt={'md'}
        label={'Account nickname'}
        placeholder={'Enter account nickname'}
        c={'neutral.8'}
        {...form.getInputProps('nickName')}
      />

      <TextInput
        mt={'md'}
        label={'Account holder'}
        placeholder={'Enter account holder'}
        c={'neutral.8'}
        {...form.getInputProps('accountHolder')}
      />

      <Text size="md" mt="2rem" fw="600">
        Recipient information
      </Text>
      {isNewRecipient && (
        <Radio.Group
          mt={'md'}
          label="Recipient type"
          {...form.getInputProps('recipientType')}
        >
          <Group mt={'md'}>
            <Radio value="individual" label="Person" />
            <Radio value="company" label="Business" />
          </Group>
        </Radio.Group>
      )}

      <Select
        mt={'md'}
        label="Recipient country"
        placeholder="Enter country of the recipient"
        data={countrySelectOptions}
        searchable
        {...form.getInputProps('countryOfRecipient')}
      />
    </Box>
  );
});

InternationalPaymentAccountForm.displayName = 'InternationalPaymentAccountForm';

export default InternationalPaymentAccountForm;
