import { DateTime } from 'luxon';

export const validateUSBirthDate = (value: string): boolean => {
  const dt = DateTime.fromFormat(value, 'MM/dd/yyyy');
  if (dt.isValid) {
    const { years } = dt.diffNow('years');
    const absoluteYears = Math.abs(years);
    return absoluteYears > 18 && absoluteYears < 107;
  }

  return false;
};

export const validateBirthDateInSpecifiedFormat = (
  format: string,
  value?: string,
): boolean => {
  if (!value) {
    return false;
  }

  const dt = DateTime.fromFormat(value, format);
  if (dt.isValid) {
    const { years } = dt.diffNow('years');
    const absoluteYears = Math.abs(years);
    return absoluteYears > 18 && absoluteYears < 107;
  }

  return false;
};

export const validateUSFormationDate = (value: string): boolean => {
  const dt = DateTime.fromFormat(value, 'MM/dd/yyyy');

  if (dt.isValid) {
    return dt < DateTime.utc();
  }

  return false;
};
