import {
  _ApplicationState,
  OnboardingApplicationConfig,
  OnboardingRouteNames,
} from '../../states/application/product-onboarding.models';
import {
  ApplicationState,
  BankingOnlyModalDismissedState,
  getProductOnboardingStatus,
  OptedProduct,
  ProductCurrentStep,
  ProductNavStack,
  ProductOnboardingBtnLoaderState,
} from '../../states/application/product-onboarding';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import useShowBankingModal from './banking-modal-hook';
import { ProductOnboardingRoutes } from './onboarding.constants';
import { OnboardingCompany } from '../../states/onboarding/onboarding-info';
import { stripAndFormat } from '@utilities/formatters/api-request-formatters';
import { formatDateForApi } from '@utilities/formatters/format-date-input';
import { formatPhoneForApi } from '@utilities/formatters/format-phone-number';
import { flexbaseOnboardingClient } from '@services/flexbase-client';
import { BUSINESS_ANNUAL_REVENUE } from '../../states/business/constants';

type UseApplicationFlowContextReturnType = {
  config: OnboardingApplicationConfig;
  navigateToNextProductStep: () => void;
  goBack: () => void;
  applyingForProducts: OptedProduct[];
  progress: number;
  createOrUpdateCompany: (data: Partial<OnboardingCompany>) => Promise<{
    success: boolean;
    error: string;
    company: Partial<OnboardingCompany>;
  }>;
  refreshProductOnboardingStatus: (
    includeFullOwners?: boolean,
  ) => Promise<_ApplicationState>;
  getDeclineScreen: (
    applyingForProducts: OptedProduct[],
    _applicationStatus: _ApplicationState,
  ) => Promise<string>;
  navigateToEndScreen: () => void;
};

type ApplicationFlowProviderProps = { config: OnboardingApplicationConfig };

const ApplicationFlowContext =
  createContext<UseApplicationFlowContextReturnType>(
    {} as UseApplicationFlowContextReturnType,
  );

export const ApplicationFlowProvider = ({
  config: currentConfig,
}: ApplicationFlowProviderProps) => {
  const setLoading = useSetRecoilState(ProductOnboardingBtnLoaderState);
  const navigate = useNavigate();
  const { step } = useParams();
  const [navStack, setNavStack] = useRecoilState(ProductNavStack);
  const setCurrentStep = useSetRecoilState(ProductCurrentStep);
  const [productOnboardingStatus, setProductOnboardingStatus] =
    useRecoilState(ApplicationState);

  const continueWithBankingOnly = useRecoilValue(
    BankingOnlyModalDismissedState,
  );
  const bankingModal = useShowBankingModal();

  const applyingForProducts = useMemo(() => {
    return currentConfig.products;
  }, [currentConfig]);

  // I'm not sure if this would be better as a memo. The arrays aren't that big, so likely the useMemo memory footprint will be larger than running this calculation every render.
  const progress = !step
    ? 0
    : (currentConfig.routes.indexOf(step as OnboardingRouteNames) + 1) *
      (100 / currentConfig.routes.length);
  const refreshProductOnboardingStatus = async (includeFullOwners = false) => {
    const newStatus = await getProductOnboardingStatus(includeFullOwners);
    setProductOnboardingStatus(newStatus);
    return newStatus;
  };

  const navigateToNextProductStep = useCallback(
    async (
      isTransientStep = false,
      isContinueWithBankingOnly = continueWithBankingOnly,
    ) => {
      setLoading(true);
      try {
        const newStatus = await refreshProductOnboardingStatus();
        const declinedStep = await getDeclineScreen(
          currentConfig.products,
          newStatus,
        );
        const displayModal = showBankingModal(
          newStatus,
          isContinueWithBankingOnly,
        );

        if (declinedStep) {
          navigate(`${ProductOnboardingRoutes.END}/${declinedStep}`);
        } else if (navStack.length > 0) {
          const pop = navStack[0];
          setNavStack((prev) => [...prev.slice(1)]);
          navigate(pop);
        } else {
          const nextStep = isTransientStep
            ? currentConfig.getNextRouteFromCurrentRoute(
                step as OnboardingRouteNames,
              )
            : currentConfig.getNextRouteFromStatus(newStatus);

          if (displayModal) {
            const { credit } = newStatus.productStatus;
            const unqualifiedReason = credit.reason?.includes('S-Prop')
              ? 'sole-prop'
              : 'unserved-state';
            const redirectTo =
              unqualifiedReason === 'sole-prop'
                ? 'business-type'
                : 'verify-business';
            navigate(redirectTo);
            bankingModal(unqualifiedReason, navigateToNextProductStep);
          } else {
            navigate(nextStep);
            setCurrentStep(nextStep);
          }
        }
        window.scroll(0, 0);
      } catch (e) {
        navigate(`error`);
      } finally {
        setLoading(false);
      }
    },
    [step, navStack, currentConfig],
  );

  const goBack = useCallback(() => {
    setNavStack((prev) => [step!, ...prev]);
    const prevStep = currentConfig.getPreviousRoute(
      step as OnboardingRouteNames,
      productOnboardingStatus.userIsApplicant,
    );
    navigate(prevStep);
    window.scroll(0, 0);
  }, [step, productOnboardingStatus, currentConfig]);

  const createOrUpdateCompany = useRecoilCallback(
    ({ snapshot }) =>
      async (
        data: Partial<OnboardingCompany>,
      ): Promise<{
        success: boolean;
        error: string;
        company: Partial<OnboardingCompany>;
      }> => {
        setLoading(true);
        const { company } = await snapshot.getPromise(ApplicationState);

        const formattedData = stripAndFormat({ id: company.id, ...data }, [
          { field: 'formationDate', formatterFn: formatDateForApi },
          { field: 'phone', formatterFn: formatPhoneForApi },
        ]);
        try {
          let companyPromise: Promise<OnboardingCompany>;
          let optionalFields = {};
          // This is a terrible hack to be able to explicitly set website to null for banking.
          // Need to figure out a better way to set null values and not have them stripped
          // from the request by the formatter above. (The API expects `null` to indicate that this company
          // does not have a website)
          if (data.website || data.website === null) {
            optionalFields = { website: data.website };
          }

          if (formattedData.id) {
            companyPromise = flexbaseOnboardingClient.updateCompany({
              ...formattedData,
              ...optionalFields,
            });
          } else {
            companyPromise = flexbaseOnboardingClient.createCompany({
              ...formattedData,
              ...optionalFields,
            });
          }
          const co = await companyPromise;
          setLoading(false);
          return { success: true, error: '', company: co };
        } catch (error) {
          console.error('useProductOnboarding::createOrUpdateCompany', error);
          setLoading(false);
          const errorMessage = error?.message?.toLowerCase();
          // Maybe just match on 'already'? That may not be specific enough..
          if (
            errorMessage?.includes('already exists') ||
            errorMessage?.includes('already in use')
          ) {
            return { success: false, error: 'ein_conflict', company: {} };
          }
          return {
            success: false,
            error: error?.message || 'api_error',
            company: {},
          };
        }
      },
    [],
  );

  /**
   * Check the application for any reasons to short-circuit/decline.
   * @returns string - An empty string which indicates no declines, or a string that matches a declined end state route
   */
  // TODO: See if we can remove this. It may be handled by the API now (status dropping to unqualified), and we can handle routing based on that
  const getDeclineScreen = async (
    _applyingForProducts: OptedProduct[],
    _applicationStatus: _ApplicationState,
  ): Promise<string> => {
    if (
      _applicationStatus.user.promoCode ||
      !_applyingForProducts.every((p) => p === 'CREDIT') ||
      !_applicationStatus.user.roles.includes('ADMIN') ||
      !!_applicationStatus.productStatus.credit.creditLimit ||
      _applicationStatus.company.onboardedBy !== _applicationStatus.user.id
    ) {
      return '';
    }

    const revenue = _applicationStatus.company.annualRevenue;

    if (!revenue || BUSINESS_ANNUAL_REVENUE.indexOf(revenue) > 3) {
      return '';
    }

    try {
      const ficoScore = await flexbaseOnboardingClient.getFicoScore(
        productOnboardingStatus.user.id,
      );

      if (
        !ficoScore.score &&
        (!ficoScore.issues.length ||
          ficoScore.issues.some(
            (issue) =>
              issue.toLowerCase().includes('freeze') ||
              issue.toLowerCase().includes('frozen'),
          ))
      ) {
        return '';
      }
      return ficoScore.score < 700 ? 'declined-fs' : '';
    } catch (e) {
      return ''; // Error indicates frozen. (Apparently not anymore but I'm leaving this here..)
    }
  };

  const showBankingModal = (
    _applicationStatus: _ApplicationState,
    _continueWithBankingOnly: boolean,
  ) => {
    const { credit } = _applicationStatus.productStatus;
    const hasPromoCode = !!_applicationStatus.user.promoCode;
    const isSoleProprietor = credit.reason?.includes('S-Prop');
    const operatesInAnUnlicensedState =
      credit.reason?.includes('Company State');

    return (
      applyingForProducts.includes('CREDIT') &&
      // just for avoid strange cases where the company had completed onboarding at one point a long time ago
      !credit.creditLimit &&
      (isSoleProprietor || operatesInAnUnlicensedState) &&
      !_continueWithBankingOnly &&
      !hasPromoCode
    );
  };

  const navigateToEndScreen = () => {
    const endRoute = currentConfig.endRoute || 'end';
    navigate(endRoute);
  };

  return (
    <ApplicationFlowContext.Provider
      value={{
        config: currentConfig,
        applyingForProducts,
        goBack,
        navigateToNextProductStep,
        progress,
        createOrUpdateCompany,
        refreshProductOnboardingStatus,
        getDeclineScreen,
        navigateToEndScreen,
      }}
    >
      <Outlet />
    </ApplicationFlowContext.Provider>
  );
};

export const useApplicationFlowContext = () => {
  return useContext(ApplicationFlowContext);
};
