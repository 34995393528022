import { useQuery } from '@tanstack/react-query';
import { flexbaseBankingClient } from '@services/flexbase-client';

export const useGetChargeCardAccounts = () => {
  return useQuery({
    queryKey: ['chargeCardAccounts'],
    queryFn: async () => {
      return await flexbaseBankingClient.getChargeCardAccounts();
    },
    meta: {
      errorMessage: 'Unable to retrieve charge card accounts at this time.',
    },
  });
};
