import { useState } from 'react';
import OnboardingStep from '../components/onboarding-step';
import OnboardingRadioSelect from '../components/onboarding-radio-select';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'states/application/product-onboarding';
import TagManager from 'react-gtm-module';
import { useApplicationFlowContext } from '../onboarding-hooks';

const TreasuryAllocation = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { createOrUpdateCompany, goBack, navigateToNextProductStep } =
    useApplicationFlowContext();
  const { company } = useRecoilValue(ApplicationState);
  // This is intentionally doing === on a boolean since we need a different value
  // if undefined
  const [reinvestTreasury, setReinvestTreasury] = useState(
    company.reinvestTreasuryInterest
      ? 'reinvest'
      : !company.reinvestTreasuryInterest
      ? 'disburse'
      : '',
  );

  const onNext = async () => {
    setLoading(true);
    if (!reinvestTreasury) {
      setError('Select an option to continue');
    } else {
      const result = await createOrUpdateCompany({
        reinvestTreasuryInterest: reinvestTreasury === 'reinvest',
      });
      if (result.success) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'treasuryAllocationSubmitted',
          },
        });
        navigateToNextProductStep();
      } else {
        setError('An error occurred while updating your data');
        setLoading(false);
      }
    }
  };

  return (
    <OnboardingStep
      title="Treasury Allocation"
      subtitle="How do you want to handle dividends from your Flex Treasury account?"
      stepId="treasury-allocation"
      onNextClick={onNext}
      onBackClick={() => goBack()}
      error={error}
      showContinueSpinner={loading}
    >
      <OnboardingRadioSelect
        options={[
          { label: 'Reinvest in Flex Treasury', value: 'reinvest' },
          { label: 'Withdraw to external account', value: 'disburse' },
        ]}
        onChange={(selectedOption) => setReinvestTreasury(selectedOption.value)}
        selected={reinvestTreasury}
      />
    </OnboardingStep>
  );
};

export default TreasuryAllocation;
