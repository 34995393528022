import { useStyles } from '../styles';
import { Button, Text } from '@mantine/core';
import { MoveFundsGraphic } from 'assets/svg';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { UserInfoState } from 'states/user/user-info';

type Props = {
  navLink?: string;
  section?: string;
};

const MoveFundsWidget = ({ navLink = '/accounts/move-funds' }: Props) => {
  const { classes, cx } = useStyles();
  const user = useRecoilValue(UserInfoState);
  const navigate = useNavigate();
  return (
    <div className={classes.card}>
      <div className={cx(classes.flexGroup, classes.fullHeight)}>
        <div className={classes.moveFundsStack}>
          <Text size={16} color="#5F5F5F" weight={500}>
            Transfer Funds
          </Text>
          <Text size={14} color="#757575" weight={400}>
            Quickly and easily move your funds between accounts.
          </Text>
          <Button
            onClick={() => {
              navigate(navLink);
            }}
            mt="auto"
            w={156}
            p={0}
            disabled={!user.roles.includes('ADMIN')}
          >
            Move your funds
          </Button>
        </div>
        <MoveFundsGraphic className={classes.moveFundsGraphic} />
      </div>
    </div>
  );
};

export default MoveFundsWidget;
