import { createStyles } from '@mantine/core';

export const useRegistrationStyles = createStyles((theme) => ({
  page: {
    width: '100%',
    backgroundColor: theme.fn.themeColor('primarySecondarySuccess', 8),
    display: 'grid',
    gridTemplateColumns: 'minmax(510px, 50%) 1fr',
    gridTemplateRows: 'minmax(100vh, 3fr) auto',
    color: theme.fn.themeColor('neutral', 0),
    [theme.fn.smallerThan('md')]: {
      gridTemplateColumns: '1fr',
      img: {
        display: 'none',
      },
    },
    [theme.fn.smallerThan('xs')]: {
      gridTemplateRows: '1fr',
      minHeight: '100vh',
    },
  },
  leftContent: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '2.75rem 1fr',
    padding: '3rem',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(/images/page-top-frame.png)`,
    [theme.fn.smallerThan('xs')]: {
      padding: theme.spacing.md,
      rowGap: theme.spacing.lg,
    },
  },
  link: {
    textDecoration: 'underline',
    color: theme.fn.themeColor('neutral', 0),
  },
  formContainer: {
    alignSelf: 'center',
    justifySelf: 'center',
    width: 456,
    [theme.fn.smallerThan('xs')]: {
      width: '100%',
    },
    paddingTop: theme.spacing.md,
  },
  rightContent: {
    backgroundImage: 'url(/images/woman-on-computer.webp)',
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center bottom',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },
  centerGraphics: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'fit-content',
    marginTop: '5%',
  },
  fakeNotificationGraphic: {
    borderRadius: '14px',
    backgroundColor: '#FFF',
    padding: '24px 32px',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.lg,
    width: 'fit-content',
  },
  fakeNotificationContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
    borderRadius: '100%',
    height: 55,
    width: 55,
  },
  fakeNotificationIcon: { height: '38px', width: '38px' },
  fakeNotificationFakeArrow: {
    width: 0,
    height: 0,
    borderLeft: '16px solid transparent',
    borderRight: '16px solid transparent',
    borderTop: '24px solid white',
  },
  footer: {
    color: theme.fn.themeColor('neutral', 0),
    gridRow: 2,
    gridColumn: '1/3',
    borderTop: `1px solid rgba(255, 255, 255, 0.20)`, // Color on the Figma, not a variable
    height: 'fit-content',
  },
}));
