import { Box, Group, Tooltip, useMantineTheme } from '@mantine/core';
import { Arrow, EyeOpenIcon, TrashIconNew } from 'assets/svg';
import useModal from 'components/modal/modal-hook';
import { createSearchParams, useNavigate } from 'react-router-dom';
import ViewRecipientModal from './view-recipient';
import DeleteVendor from 'areas/billing/vendors/delete-vendor/delete-vendor';
import { Recipient } from 'states/recipient/recipient';

const RecipientActions = ({
  refetch,
  recipient,
}: {
  refetch: () => void;
  recipient: Recipient;
}) => {
  const theme = useMantineTheme();
  const { openModal, openCenterModalUnstyled, closeAllModals } = useModal();
  const navigate = useNavigate();

  const handleRowClick = (row: Recipient) => {
    openModal(row.name, <ViewRecipientModal recipient={row} />);
  };

  const handleNavigateToSendPaymentFlow = (currentRecipient: Recipient) => {
    navigate({
      pathname: '/payments/outgoing/recipient',
      search: createSearchParams({
        recipient: currentRecipient.name,
        recipientId: currentRecipient.id,
      }).toString(),
    });
  };

  return (
    <Group
      w="80%"
      position="apart"
      c="primarySecondarySuccess.8"
      style={{ flexWrap: 'nowrap', gap: '0.5rem' }}
    >
      <Tooltip label="View recipient details" position="bottom">
        <Box>
          <EyeOpenIcon onClick={() => handleRowClick(recipient)} />
        </Box>
      </Tooltip>
      <Tooltip label="Send a payment" position="bottom">
        <Box>
          <Arrow onClick={() => handleNavigateToSendPaymentFlow(recipient)} />
        </Box>
      </Tooltip>
      <Tooltip label="Remove recipient" position="bottom">
        <Box>
          <TrashIconNew
            color={theme.colors.critical[6]}
            onClick={() =>
              openCenterModalUnstyled(
                <DeleteVendor
                  closeModal={closeAllModals}
                  recipientId={recipient.id}
                  recipientName={recipient.name}
                  refresh={refetch}
                />,
                'md',
              )
            }
          />
        </Box>
      </Tooltip>
    </Group>
  );
};

export default RecipientActions;
