import { createWizard } from '@common/wizard/create-wizard';
import type { PaymentMethodStepValue } from './add-recipient';
import type { RecipientDetailsStepFormValues } from './steps/recipient-details/recipient-details-step';

export type AddRecipientState = {
  paymentMethod: PaymentMethodStepValue;
  recipientDetailsFormValues: RecipientDetailsStepFormValues;
  showIntlPaymentStep: boolean;
};

export const [AddRecipientWizard, useAddRecipientWizard] =
  createWizard<AddRecipientState>();
