import { Box, Button, Group, TextInput, useMantineTheme } from '@mantine/core';
import {
  ArrowIcon,
  DollarCircleIcon,
  PlusSignIcon,
  SearchIcon,
  StarIcon,
  TeamsIcon,
} from 'assets/svg';
import NewAccountModal from './new-account.modal';
import { useMediaQuery } from '@mantine/hooks';
import { useBankingAccountsFilters } from './use-banking-filters';
import { useEffect, useState } from 'react';
import { ExpandingFilterBar } from '@common/filter/expanding-filter-bar';
import { useRecoilValue } from 'recoil';
import { BankingAccountsStatusFilter } from './filters/banking-accounts-status-filter';
import { BankingAccountsBalanceFilter } from './filters/banking-accounts-balance-filter';
import { BankingAccountsNameFilter } from './filters/banking-accounts-nickname-filter';
import { useGetUser } from '@queries/use-users';
import { UserIdState } from 'areas/onboarding/onboarding-form.state';
import { isTruthyString } from 'utilities/validators/validate-string';

const BankingAccountsHeader = () => {
  const theme = useMantineTheme();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const [showFilters, setShowFilters] = useState(true);
  const [addModal, setAddModal] = useState(false);
  const [roles, setRoles] = useState<string[]>([]);
  const { removeFilter, activeFiltersArray, getFilterByKey, addFilter } =
    useBankingAccountsFilters();
  const userId = useRecoilValue(UserIdState);
  const { data, isSuccess } = useGetUser(userId);

  const onSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }

    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (transaction) => {
        const normalizedFilterText = value.toLowerCase().trim();
        return Object.values(transaction)
          .filter(isTruthyString)
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  const filters = [
    {
      key: 'status',
      header: 'Status',
      icon: <StarIcon />,
      component: <BankingAccountsStatusFilter />,
    },
    {
      key: 'balance',
      header: 'Balance',
      icon: <DollarCircleIcon />,
      component: <BankingAccountsBalanceFilter />,
    },
    {
      key: 'nickName',
      header: 'Account',
      icon: <TeamsIcon />,
      component: <BankingAccountsNameFilter />,
    },
  ];

  useEffect(() => {
    if (isSuccess && data?.roles) {
      setRoles(data.roles || []);
    }
  }, [data, isSuccess]);

  return (
    <>
      <Group position="apart" mb={useMobileView ? 0 : '1rem'}>
        <TextInput
          w={useMobileView ? '100%' : 368}
          placeholder="Search accounts"
          onChange={(e) => onSearchChange(e.target.value)}
          icon={<SearchIcon width={20} height={20} />}
          value={getFilterByKey('search')?.filterValue || ''}
        />
        <Box
          w={useMobileView ? '100%' : 'auto'}
          sx={() => ({
            display: useMobileView ? 'block' : 'flex',
            gap: theme.spacing.md,
          })}
        >
          <Button
            variant="outline"
            rightIcon={<ArrowIcon />}
            onClick={() => setShowFilters((prev) => !prev)}
            styles={{
              rightIcon: { rotate: !showFilters ? '180deg' : '' },
            }}
            fullWidth={useMobileView}
          >
            {`${showFilters ? 'Collapse' : 'Expand'} filters`}
          </Button>

          <Button
            leftIcon={<PlusSignIcon width={20} />}
            variant="light"
            onClick={() => {
              setAddModal(true);
            }}
            mt={useMobileView ? '1rem' : 0}
            mb={useMobileView ? '1rem' : 0}
            fullWidth={useMobileView}
            disabled={!roles.includes('ADMIN')}
          >
            Open Flex account
          </Button>
          <NewAccountModal addModal={addModal} setAddModal={setAddModal} />
        </Box>
      </Group>
      <ExpandingFilterBar
        showFilters={showFilters}
        filters={filters}
        activeFilters={activeFiltersArray}
        onRemoveActiveFilterClick={removeFilter}
      />
    </>
  );
};

export default BankingAccountsHeader;
