import { createStyles, rem } from '@mantine/core';

export const useBillPayStyles = createStyles((theme) => ({
  badge: {
    minWidth: rem(100),
    textAlign: 'center',
    padding: theme.spacing.xs,
    borderRadius: theme.radius.sm,
    '@media(max-width: 767px)': {
      width: '100%',
    },
  },
  card: {
    position: 'relative',
    padding: theme.spacing.xl,
    borderRadius: theme.defaultRadius,
    color: theme.fn.themeColor('neutral', 1),
    backgroundColor: theme.fn.themeColor('neutral', 0),
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
  },
  emailContainer: {
    button: {
      border: 'none',
      cursor: 'pointer',
      backgroundColor: 'unset',
      color: theme.fn.themeColor('neutral', 7),
      svg: {
        stroke: theme.fn.themeColor('neutral', 6),
      },
    },
  },
  emailContainerEmptyState: {
    borderRadius: theme.spacing.xs,
    padding: `${theme.spacing.sm} ${theme.spacing.md}`,
    backgroundColor: theme.fn.themeColor('neutral', 2),
    button: {
      border: 'none',
      cursor: 'pointer',
      backgroundColor: 'unset',
      fontSize: theme.spacing.sm,
      color: theme.fn.themeColor('neutral', 7),
      svg: {
        stroke: theme.fn.themeColor('neutral', 6),
      },
    },
  },
}));
