import { useMantineTheme } from '@mantine/core';
import { PreviewRow } from '../../components/preview-row';
import { useMediaQuery } from '@mantine/hooks';
import {
  TeamMembersTableCompact,
  TeamMembersTableFull,
} from './components/team-members-table';

export const SpendPlanTeamMembersStepPreview = () => {
  const theme = useMantineTheme();
  const isCompact = useMediaQuery(
    `(max-width: ${theme.breakpoints.lg})`,
    false,
    { getInitialValueInEffect: false },
  );

  return (
    <PreviewRow>
      {isCompact ? <TeamMembersTableCompact /> : <TeamMembersTableFull />}
    </PreviewRow>
  );
};
