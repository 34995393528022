import { Flex, Group, Radio, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { validateBirthDateInSpecifiedFormat } from '@utilities/validators/validate-dates';
import {
  ConditionalFieldValidator,
  RequiredFieldValidator,
} from '@utilities/validators/validate-required';
import FlexPatternFormat from 'areas/payments/components/common/flex-pattern-format';
import { IndividualOrCompany } from 'areas/payments/components/send-payment/international-payments/util/types';
import {
  AddRecipientWizard,
  useAddRecipientWizard,
} from '../../add-recipient-wizard';

export type RecipientDetailsStepFormValues = {
  name: string;
  firstName?: string;
  lastName?: string;
  dob?: string;
  type: IndividualOrCompany;
};

const isIndividual = (type: IndividualOrCompany) => type === 'individual';

const useRecipientDetailsForm = (
  initialValues: RecipientDetailsStepFormValues,
) => {
  return useForm<RecipientDetailsStepFormValues>({
    initialValues,
    validate: {
      name: (value, formValues) =>
        ConditionalFieldValidator(!isIndividual(formValues.type))(value),
      firstName: (value, formValues) =>
        ConditionalFieldValidator(isIndividual(formValues.type))(value),
      lastName: (value, formValues) =>
        ConditionalFieldValidator(isIndividual(formValues.type))(value),
      dob: (value, formValues) => {
        if (isIndividual(formValues.type)) {
          return validateBirthDateInSpecifiedFormat('yyyy-MM-dd', value)
            ? null
            : 'Please input a valid date of birth.';
        }
      },
      type: RequiredFieldValidator(),
    },
  });
};

export const RecipientDetailsStep = () => {
  const { goToNextStep, setState, state } = useAddRecipientWizard();
  const form = useRecipientDetailsForm(state.recipientDetailsFormValues);

  // TODO: In forthcoming work, handle case where user is updating an existing recipient, derived from recipientId in url params
  const handleNext = () => {
    if (isIndividual(form.values.type)) {
      // Ensure recipient name consistency
      form.setFieldValue(
        'name',
        `${form.values.firstName} ${form.values.lastName}`,
      );
    }

    const validationResult = form.validate();
    if (validationResult.hasErrors) {
      return;
    } else {
      setState((prev) => ({
        ...prev,
        recipientDetailsFormValues: form.values,
      }));
      goToNextStep();
    }
  };

  const handleRecipientTypeOnchange = (value: IndividualOrCompany) => {
    form.reset();
    form.setFieldValue('type', value);
  };

  return (
    <AddRecipientWizard.Step onNext={handleNext} hideBack>
      <Radio.Group
        mt={20}
        label="Recipient type"
        {...form.getInputProps('type')}
        onChange={handleRecipientTypeOnchange}
      >
        <Group mt={10}>
          <Radio value="individual" label="Person" />
          <Radio value="company" label="Business" />
        </Group>
      </Radio.Group>

      {isIndividual(form.values.type) ? (
        <>
          <Flex gap={'md'}>
            <TextInput
              mt={10}
              label="First name"
              placeholder="First name"
              {...form.getInputProps('firstName')}
            />
            <TextInput
              mt={10}
              label="Last name"
              placeholder="Last name"
              {...form.getInputProps('lastName')}
            />
          </Flex>
          <FlexPatternFormat
            mt={10}
            label="Date of birth"
            placeholder="Date of birth"
            description="Format: YYYY-MM-DD"
            c={'neutral.8'}
            format="####-##-##"
            mask="_"
            allowEmptyFormatting
            {...form.getInputProps('dob')}
          />
        </>
      ) : (
        <TextInput
          mt={10}
          label="Business name"
          placeholder="Business name"
          {...form.getInputProps('name')}
        />
      )}
    </AddRecipientWizard.Step>
  );
};

RecipientDetailsStep.stepId = 'recipient-details-step';
