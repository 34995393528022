import { Modal } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';
import { TablePayment } from './payments-rows';
import PaymentDetails from '../payment-details-modal/payment-details';

const PaymentDetailsModal = (openPayment: TablePayment) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <Modal
      opened={!!openPayment.id}
      onClose={() => navigate('..', { relative: 'path' })}
      styles={{
        header: { display: 'none' },
        inner: { width: isMobile ? '100vw' : '642px', right: '0px' },
        body: { padding: 0 },
      }}
      transitionProps={{ transition: 'fade' }}
      closeOnClickOutside
      fullScreen
    >
      <PaymentDetails
        row={openPayment}
        onClose={() => navigate('..', { relative: 'path' })}
      />
    </Modal>
  );
};

export default PaymentDetailsModal;
