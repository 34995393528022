import { atom } from 'recoil';
import { AutoPay } from 'states/onboarding/onboarding-info';

export type CompanySimplifiedOwner = {
  id: string;
  firstName: string;
  lastName: string;
  percent: number;
  title: string;
  email: string;
};

export type UserCompany = {
  id: string;
  name: string;
  initials: string;
  creditLimit: string;
  autopay: Partial<AutoPay>;
  address: string;
  addressLine2?: string;
  city: string;
  postalCode: string;
  state: string;
  country?: string;
  ein: string;
  websiteUrl: string;
  mainOwner: CompanySimplifiedOwner; // This will be the user if the user is an owner or the largest percent owner if not
  owners: CompanySimplifiedOwner[];
  optedProducts: string[];
  phone: string;
  formationDate?: string;
  formationState?: string;
  taxId?: string;
};

export const UserCompanyState = atom<UserCompany>({
  key: 'user-company',
  default: {
    initials: '',
    name: '',
    creditLimit: '0',
    id: '',
    autopay: {},
    address: '',
    addressLine2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    ein: '',
    websiteUrl: '',
    mainOwner: {
      firstName: '',
      lastName: '',
      percent: 0,
      title: '',
      id: '',
      email: '',
    },
    owners: [],
    optedProducts: [],
    phone: '',
  },
});
