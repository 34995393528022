import { flexbaseOnboardingClient } from '@services/flexbase-client';
import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { NotificationChannels } from 'states/user/preferences';

const getUserPreferencesQueryOptions = () =>
  queryOptions({
    queryKey: ['user_preferences'],
    queryFn: async () =>
      (await flexbaseOnboardingClient.getUserPreference()).preferences,
  });

export const useUserPreferences = () => {
  return useQuery(getUserPreferencesQueryOptions());
};

type NotificationEventMap = Record<string, NotificationChannels[]>;
type NotificationGroupMap = Record<string, NotificationEventMap>;

type UpdateUserPreferencesArgs = {
  userId: string;
  payload: {
    preferences: {
      notifications: NotificationGroupMap;
    };
  };
};

export const useUpdateUserPreferences = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ userId, payload }: UpdateUserPreferencesArgs) => {
      return await flexbaseOnboardingClient.updateUserPreference(
        userId,
        payload,
      );
    },
    onMutate: ({ payload }) => {
      queryClient.setQueryData(
        getUserPreferencesQueryOptions().queryKey,
        (data) => {
          if (!data) {
            return data;
          }

          const optimisticUpdate = Object.entries(data.notifications).reduce(
            (acc, [group, eventMap]) => {
              acc[group] = {
                ...eventMap,
                ...payload.preferences.notifications[group],
              };

              return acc;
            },
            {
              ...data.notifications,
            },
          );

          return {
            ...data,
            notifications: optimisticUpdate,
          };
        },
      );
    },
    onSettled: () => {
      // on error this will essentially rollback the optimistic cache update
      queryClient.invalidateQueries(getUserPreferencesQueryOptions());
    },
  });
};
