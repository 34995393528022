import { ExpenseLink } from '@flexbase-eng/types/dist/accounting';
import {
  Box,
  Button,
  CopyButton,
  Group,
  List,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useGenerateBankFeedOtp } from '@queries/use-integrations';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { FiArrowUpRight, FiCopy } from 'react-icons/fi';

type IntuitBankFeedsProps = {
  link: ExpenseLink;
  strongTitle?: boolean;
};

export const IntuitBankFeeds = ({
  link,
  strongTitle = false,
}: IntuitBankFeedsProps) => {
  const { mutate: generateBankFeedOtp } = useGenerateBankFeedOtp();
  const [feedOtp, setFeedOtp] = useState<{
    code: string;
    expirationString: string;
  }>();

  const handleGoToBankFeed = () => {
    const bankFeedUrl = `${
      import.meta.env.VITE_APP_QUICKBOOKS_ONLINE_URL
    }/app/banking`;
    window.open(bankFeedUrl, '_blank', 'noopener,noreferrer');
  };

  const handleGenerateOtp = async () => {
    generateBankFeedOtp(
      {
        connectionId: link.connectionId,
      },
      {
        onSuccess: (res) => {
          const formattedDate = DateTime.fromISO(res.expiresAt).toLocaleString(
            DateTime.DATETIME_MED,
          );

          setFeedOtp({
            code: res.otp,
            expirationString: formattedDate,
          });
        },
        onError: () => {
          notifications.show({
            color: 'critical.2',
            message: 'An unexpected error occurred. Please try again later.',
          });
        },
      },
    );
  };

  return (
    <Stack
      sx={(t) => ({
        color: t.fn.themeColor('primarySecondarySuccess', 8),
      })}
    >
      <Text fw={strongTitle ? 'bold' : undefined}>
        To complete your QBO integration to Flex follow the steps below:
      </Text>

      <List type="ordered" spacing="md" withPadding={false}>
        <List.Item>
          <Text>Navigate to your QBO bank feed.</Text>

          <Button
            variant="outline"
            mt="xs"
            onClick={handleGoToBankFeed}
            rightIcon={<FiArrowUpRight />}
          >
            Go to Bank Feed
          </Button>
        </List.Item>

        <List.Item>
          Click &apos;
          <Text fw="bold" span>
            Link account
          </Text>
          &apos;
        </List.Item>

        <List.Item>
          Search for &apos;
          <Text fw="bold" span>
            Flex
          </Text>
          &apos;
        </List.Item>

        <List.Item>
          <Text>Enter OTP generated in Flex</Text>

          {feedOtp ? (
            <>
              <Box my="sm">
                <Group>
                  <Text sx={{ flexBasis: '20%' }}>OTP:</Text>

                  <Box>
                    <Text span>{feedOtp.code}</Text>

                    <CopyButton value={feedOtp.code}>
                      {({ copy, copied }) => (
                        <Tooltip label="Copied" opened={copied}>
                          <Text onClick={copy} span ml="xs">
                            <FiCopy />
                          </Text>
                        </Tooltip>
                      )}
                    </CopyButton>
                  </Box>
                </Group>

                <Group>
                  <Text sx={{ flexBasis: '20%' }}>Expires:</Text>

                  <Text>{feedOtp.expirationString}</Text>
                </Group>
              </Box>

              <Button variant="outline" mt="xs" onClick={handleGenerateOtp}>
                Regenerate OTP
              </Button>
            </>
          ) : (
            <Button variant="outline" mt="xs" onClick={handleGenerateOtp}>
              Generate one time passcode (OTP)
            </Button>
          )}
        </List.Item>

        <List.Item>Select accounts to connect to Flex</List.Item>
      </List>
    </Stack>
  );
};
