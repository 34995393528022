import { useForm } from '@mantine/form';
import { useEffect } from 'react';
import { Button, Text, TextInput, useMantineTheme } from '@mantine/core';

import { useStyles } from '../styles';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { useRecoilValue } from 'recoil';
import {
  FlexAccountInfo,
  TreasuryManagementState,
} from '../treasury-management.state';
import AccountSelection from 'areas/payments/components/account-selection';
import { TransferForm } from './move-funds-treasury';
import FlexAlert from '@common/alerts/flex-alert';

type Props = {
  closeModal: () => void;
  transferForm: TransferForm;
  setStep: (x: number) => void;
  setTransferForm: (x: any) => void;
};

const TransferDetails = ({
  setStep,
  closeModal,
  transferForm,
  setTransferForm,
}: Props) => {
  const theme = useMantineTheme();
  const { classes, cx } = useStyles();
  const { flexTreasuryAccounts, primaryAccount, activities } = useRecoilValue(
    TreasuryManagementState,
  );

  const admAccount: FlexAccountInfo = {
    plaidOrDeposit: 'admDeposit',
    account: flexTreasuryAccounts[0].accountNumber,
    name: flexTreasuryAccounts[0].name,
    id: '',
    routing: '',
    accountType: '',
    availableBalance: 0,
    uninsuredCapital: 0,
    accountDiversificationNumber: 0,
    subName: '',
    accountNumber: flexTreasuryAccounts[0].accountNumber,
  } as FlexAccountInfo;

  const admPrimaryAccount: FlexAccountInfo = {
    plaidOrDeposit: 'admDeposit',
    account: primaryAccount!.accountNumber,
    name: primaryAccount!.accountName,
    logoUrl: primaryAccount!.logoUrl,
    id: '',
    routing: '',
    accountType: '',
    availableBalance: 0,
    uninsuredCapital: 0,
    accountDiversificationNumber: 0,
    subName: '',
    accountNumber: flexTreasuryAccounts[0].accountNumber,
  } as FlexAccountInfo;

  const form = useForm({
    initialValues: {
      ...transferForm,
    },
  });

  const setSource = (account: FlexAccountInfo) => {
    form.setFieldValue('sourceAccount', account);
    form.setFieldValue(
      'destinationAccount',
      [admAccount, admPrimaryAccount].find(
        (a) => a.accountNumber !== account.accountNumber,
      ),
    );
    form.setFieldValue(
      'activity',
      account.accountNumber === admAccount.accountNumber
        ? 'withdrawal'
        : 'deposit',
    );
    setTransferForm({ ...form.values });
  };

  const setDestination = (account: FlexAccountInfo) => {
    form.setFieldValue('destinationAccount', account);
    form.setFieldValue(
      'sourceAccount',
      [admAccount, admPrimaryAccount].find(
        (a) => a.accountNumber !== account.accountNumber,
      ),
    );
    form.setFieldValue(
      'activity',
      account.accountNumber === admAccount.accountNumber
        ? 'deposit'
        : 'withdrawal',
    );
    setTransferForm({ ...form.values });
  };

  const onInputChange = (input: string) => {
    let currentValue = input.replace(/[^\d]/g, '');

    if (currentValue.length < 2) {
      currentValue = `0.0${currentValue}`;
    } else if (currentValue.length === 2) {
      currentValue = `0.${currentValue}`;
    } else {
      currentValue =
        currentValue.slice(0, currentValue.length - 2) +
        '.' +
        currentValue.slice(-2);
    }

    form.setFieldValue('amount', currentValue);
  };

  const onNextStep = () => {
    setStep(1);
    setTransferForm({
      ...form.values,
    });
  };

  useEffect(() => {
    if (!form.values.sourceAccount) {
      form.setFieldValue('sourceAccount', admAccount);
      form.setFieldValue('destinationAccount', admPrimaryAccount);
      setTransferForm({ ...form.values });
    }
  }, []);

  return (
    <div className={cx(classes.card, classes.modalCard)}>
      <Text className={classes.title}>Transfer Money</Text>
      <Text className={classes.subtitle}>
        This transfer can only take place between your primary account and
        treasury account.
      </Text>
      {activities.find((a) => a.status === 'pending') && (
        <FlexAlert
          type="info"
          message="You have pending transfers."
          link={{ text: 'View', url: '/accounts/activities' }}
          style={{ marginTop: '16px', marginBottom: 0 }}
        />
      )}
      <TextInput
        variant="unstyled"
        value={formatCurrency(form.values.amount || '0')}
        onChange={(v) => onInputChange(v.currentTarget.value)}
        classNames={classes}
        styles={{
          input: {
            fontSize: '48px',
            textAlign: 'center',
            height: 'fit-content',
            borderRadius: '0px !important',
            border: 'none',
            borderBottom: `3px solid ${theme.fn.primaryColor()} !important`,
            '&:focus': {
              borderBottom: `3px solid ${theme.fn.primaryColor()}`,
            },
            color: form.values.amount === '' ? '#979797' : '#000',
          },
        }}
      />
      {primaryAccount && form.values.sourceAccount && (
        <div className={classes.accountSelection}>
          <AccountSelection
            label="Transferring from"
            currentAccount={form.values.sourceAccount}
            accounts={[admAccount, admPrimaryAccount]}
            onAccountChange={(c) => {
              setSource(c as FlexAccountInfo);
            }}
          />
          <AccountSelection
            label="Transferring to"
            currentAccount={form.values.destinationAccount!}
            accounts={[admAccount, admPrimaryAccount]}
            onAccountChange={(c) => {
              setDestination(c as FlexAccountInfo);
            }}
          />
        </div>
      )}
      {form.values.amount && (
        <div className={classes.transferEstimate}>
          {form.values.activity === 'withdrawal' ? (
            <Text>This withdrawal will complete in 1-2 days</Text>
          ) : (
            <Text>This deposit will complete in 4-5 days</Text>
          )}
        </div>
      )}
      <Button
        disabled={!form.values.amount}
        mb={15}
        onClick={() => {
          onNextStep();
        }}
        fullWidth
      >
        Transfer Funds
      </Button>
      <Button variant="outline" fullWidth onClick={closeModal}>
        Go back
      </Button>
    </div>
  );
};

export default TransferDetails;
