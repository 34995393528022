import {
  PersonPrefillResponse,
  PrefillBusinessResponse,
} from '@services/platform/models/identity.model';
import { createContext, ReactNode, useContext } from 'react';
import { useBusinessPrefill, usePersonPrefill } from '@queries/use-prefill';
import { useGetMe } from '@queries/use-get-me';

type PlatformPrefillContextReturnType = {
  personPrefillData?: PersonPrefillResponse;
  personPrefillLoading: boolean;
  personPrefillError: boolean;
  businessPrefillData?: PrefillBusinessResponse;
  businessPrefillLoading: boolean;
  businessPrefillError: boolean;
};

const PlatformPrefillContext =
  createContext<PlatformPrefillContextReturnType | null>(null);

// This MUST be used under PlatformPersonProvider!
export const PlatformPrefillProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { data: person } = useGetMe();
  const {
    data: personPrefillData,
    isPending: personPrefillLoading,
    isError: personPrefillError,
  } = usePersonPrefill(person?.accountId, person?.id, person?.dateOfBirth);

  const {
    data: businessPrefillData,
    isPending: businessPrefillLoading,
    isError: businessPrefillError,
  } = useBusinessPrefill(person?.accountId, person?.id, person?.taxId);

  return (
    <PlatformPrefillContext.Provider
      value={{
        personPrefillData,
        personPrefillLoading,
        personPrefillError,
        businessPrefillData,
        businessPrefillLoading,
        businessPrefillError,
      }}
    >
      {children}
    </PlatformPrefillContext.Provider>
  );
};

export const usePlatformPrefillContext =
  (): PlatformPrefillContextReturnType => {
    const context = useContext(PlatformPrefillContext);

    if (context === null) {
      throw new Error('PlatformPrefillContext must be used in provider');
    }

    return context;
  };
