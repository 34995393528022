import { useCreditCardsModalFilters } from '../use-credit-cards-filters';
import { filterDirectionToLabel } from '@common/filter/amount-filter';
import { QuantityFilter } from '@common/filter/quantity-filter';
import { formatCurrency } from '@flexbase-eng/web-components';

function compareMtdSpendAmount(
  compareAmount: number,
  compareDirection: string,
  mtdSpends?: number,
) {
  if (!mtdSpends && mtdSpends !== 0) return false;

  const amountNum = Math.abs(mtdSpends);
  const filterNum = compareAmount;
  switch (compareDirection) {
    case 'greaterThan':
      return amountNum > filterNum;
    case 'lessThan':
      return amountNum < filterNum;
    case 'equal':
    default:
      return amountNum === filterNum;
  }
}
export const CardSpentAmountsFilter = () => {
  const { addFilter, getFilterByKey } = useCreditCardsModalFilters();

  return (
    <QuantityFilter
      value={
        getFilterByKey('amount')?.filterValue || {
          amount: '',
          direction: 'equal',
        }
      }
      onChange={(val) =>
        addFilter('amount', {
          label: `Monthly usage - ${filterDirectionToLabel(
            val.direction,
          )} ${formatCurrency(val.amount)}`,
          key: 'amount',
          fn: (row) =>
            compareMtdSpendAmount(
              +val.amount,
              val.direction,
              row.monthToDateSpends?.mtdSpend,
            ),
          filterValue: val,
          showChip: true,
        })
      }
    />
  );
};
