import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { flexbaseOnboardingClient } from '@services/flexbase-client';
import {
  cardIsCreditCard,
  CreditCard,
  IssueCardRequest,
  UpdateCardRequest,
  UpdateCardStatusRequest,
} from '@services/flexbase/card.model';
import { updateQueryData } from '@queries/helpers';
import { platformClient } from '@services/platform/platform-client';
import { showNotification } from '@mantine/notifications';
import {
  PatchExpensesCategoryToCardRequest,
  PostExpensesCategoryToCardRequest,
} from '@flexbase-eng/types/dist/accounting';

const QUERY_KEY = 'credit_cards_query';

/**
 * This query automatically filters debit cards since it is a credit card query.
 * @param full
 * @param searchTerm
 * @param status
 */
export const useCreditCards = (full = true, searchTerm = '', status = '') => {
  return useQuery({
    queryKey: [QUERY_KEY, full, searchTerm, status],
    queryFn: async () => {
      const result = await flexbaseOnboardingClient.getCompanyCards({
        full,
        searchTerm,
        status,
      });

      if (!result.success) {
        throw new Error(
          'Unable to retrieve cards but the error was squashed in legacy API client',
        );
      }

      return result.cards.filter((c) => cardIsCreditCard(c)) as CreditCard[];
    },
    meta: {
      errorMessage: 'Unable to retrieve credit cards at this time.',
    },
  });
};

type UpdateMutation = { id: string; card: Partial<UpdateCardRequest> };
type CardMutationContext = { oldCard?: CreditCard };

function onMutateSuccess(queryClient: QueryClient, data: CreditCard) {
  updateQueryData<CreditCard[]>(queryClient, [QUERY_KEY], (prev) => {
    return prev?.map((card) =>
      card.id === data.id ? { ...card, ...data } : card,
    );
  });
}

function onMutateError(
  queryClient: QueryClient,
  context?: CardMutationContext,
) {
  if (context?.oldCard) {
    updateQueryData<CreditCard[]>(queryClient, [QUERY_KEY], (prev) => {
      return prev?.map((card) => {
        // we need to do this check again because typescript
        if (context?.oldCard && card.id === context.oldCard.id) {
          return { ...context.oldCard };
        }
        return card;
      });
    });
  }
}

async function updateCreditCard({ id, card }: UpdateMutation) {
  return await flexbaseOnboardingClient.updateCreditCard(id, card);
}

export const useUpdateCreditCardMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateCreditCard,
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: [QUERY_KEY] });

      const context: { oldCard?: CreditCard } = { oldCard: undefined };

      updateQueryData<CreditCard[]>(queryClient, [QUERY_KEY], (prev) => {
        return prev?.map((card) => {
          if (card.id === variables.id) {
            context.oldCard = card;
            return { ...card, ...variables.card };
          }

          return card;
        });
      });

      return context;
    },
    onSuccess: (data) => onMutateSuccess(queryClient, data.card),
    onError: (_error, _variables, context) =>
      onMutateError(queryClient, context),
  });
};

async function updateCardStatus(args: UpdateCardStatusRequest) {
  const result = await flexbaseOnboardingClient.updateCreditCardStatus(args);
  return result.card as CreditCard;
}

export const useUpdateCreditCardStatusMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateCardStatus,
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: [QUERY_KEY] });
      const context: { oldCard?: CreditCard } = { oldCard: undefined };
      updateQueryData<CreditCard[]>(queryClient, [QUERY_KEY], (prev) => {
        return prev?.map((card) => {
          if (card.id === variables.cardId) {
            context.oldCard = card;
            return { ...card, status: variables.status };
          }
          return card;
        });
      });

      return context;
    },
    onSuccess: (data) => onMutateSuccess(queryClient, data),
    onError: (_error, _variables, context) =>
      onMutateError(queryClient, context),
  });
};

type IssueCardMutationArgs = { userId: string; card: IssueCardRequest };

export const useIssueCreditCardMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ userId, card }: IssueCardMutationArgs) => {
      return await flexbaseOnboardingClient.issueCard(userId, card);
    },
    onSuccess: (data) => {
      const card = data.card;

      if (cardIsCreditCard(card)) {
        updateQueryData<CreditCard[]>(queryClient, [QUERY_KEY], (prev) => {
          return prev?.toSpliced(0, 0, card);
        });
      }
    },
  });
};

const CATEGORIES_CARDS_KEY = 'cards_categories';

async function getCardsAndCategories(connectionId: string) {
  return await platformClient.getCardsAndCategories(connectionId);
}

type UseAcccountingCategoryProps = {
  connectionId: string;
};

export function useCardsAndCategories(props: UseAcccountingCategoryProps) {
  const { connectionId } = props;
  return useQuery({
    enabled: !!connectionId,
    queryKey: [CATEGORIES_CARDS_KEY, connectionId],
    queryFn: () => getCardsAndCategories(connectionId),
  });
}

const CARD_CATEGORY_KEY = 'card_category';

async function getCardCategory(connectionId: string, cardId: string) {
  return await platformClient.getCardCategory(connectionId, cardId);
}

type UseCardCategoryProps = {
  connectionId: string;
  cardId: string;
};

export function useCardCategory(props: UseCardCategoryProps) {
  const { connectionId, cardId } = props;
  return useQuery({
    enabled: !!connectionId,
    queryKey: [CARD_CATEGORY_KEY, connectionId, cardId],
    queryFn: () => getCardCategory(connectionId, cardId),
  });
}

async function updateCardCategory(params: {
  connectionId: string;
  categoryId: string;
  cardId: string;
}): Promise<PatchExpensesCategoryToCardRequest> {
  return platformClient.updateCardCategory(
    params.connectionId,
    params.categoryId,
    params.cardId,
  );
}

export function useUpdateCardCategory() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateCardCategory,
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [CATEGORIES_CARDS_KEY, variables.connectionId],
      });
      queryClient.invalidateQueries({
        queryKey: [CARD_CATEGORY_KEY, variables.connectionId, variables.cardId],
      });
    },
  });
}

async function createCardCategory(params: {
  connectionId: string;
  categoryId: string;
  cardName: string;
  userId: string;
}): Promise<PostExpensesCategoryToCardRequest> {
  return platformClient.createCategoryCard(
    params.connectionId,
    params.categoryId,
    params.cardName,
    params.userId,
  );
}

export function useCreateCardCategory() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createCardCategory,
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [CATEGORIES_CARDS_KEY, variables.connectionId],
      });
    },
    onError: () => {
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Unable to create card category',
      });
    },
  });
}
