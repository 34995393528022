import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { flexbaseOnboardingClient } from '@services/flexbase-client';
import {
  CompanyDocument,
  UploadProps,
} from '@services/flexbase/flexbase-onboarding-client';

const QUERY_KEY = 'get_company_documents';

export const useCompanyDocuments = () => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      const response = await flexbaseOnboardingClient.getDocuments();
      return response.companyDocs;
    },
  });
};

export const useUploadCompanyDocumentMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (doc: UploadProps) => {
      return await flexbaseOnboardingClient.uploadDocument(doc);
    },
    onSuccess: (response) => {
      queryClient.setQueryData<CompanyDocument[]>([QUERY_KEY], (data) => {
        if (data) {
          return [
            {
              ...response.companyDoc,
              metadata: response.docMetadata,
            },
            ...data,
          ];
        }
      });
    },
  });
};
