import { Button, Center, Image, Text } from '@mantine/core';
import { ReactNode } from 'react';
import { useEndScreenStyles } from './use-end-screen-styles';

type Props = {
  title: string;
  description: ReactNode;
  graphic: string;
  primaryButtonText: ReactNode;
  secondaryButtonText?: string;
  onPrimaryButtonClick: () => void;
  onSecondaryButtonClick?: () => void;
  id?: string;
  showPrimaryLoader?: boolean;
  showSecondaryLoader?: boolean;
  additionalFormElement?: ReactNode;
};

const OnboardingEndScreen = ({
  title,
  description,
  graphic,
  primaryButtonText,
  secondaryButtonText,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  id,
  showPrimaryLoader,
  showSecondaryLoader,
  additionalFormElement,
}: Props) => {
  const { classes } = useEndScreenStyles();
  return (
    <Center mt={200}>
      <div className={classes.container} id={id}>
        <Image
          className={classes.graphic}
          src={graphic}
          height={'100%'}
          width={'550px'}
        />
        <div className={classes.titleDescriptionContainer}>
          <Text className={classes.title}>{title}</Text>
          <Text className={classes.description}>{description}</Text>
          {additionalFormElement}
          {primaryButtonText && (
            <Button
              mt={20}
              id={`button-primary-${id || 'end-screen'}`}
              data-testid={`button-primary-${id || 'end-screen'}`}
              onClick={onPrimaryButtonClick}
              loading={showPrimaryLoader}
            >
              {primaryButtonText}
            </Button>
          )}
          {secondaryButtonText && (
            <Button
              onClick={onSecondaryButtonClick}
              id={`button-secondary-${id || 'end-screen'}`}
              data-testid={`button-secondary-${id || 'end-screen'}`}
              variant="outline"
              loading={showSecondaryLoader}
            >
              {secondaryButtonText}
            </Button>
          )}
        </div>
      </div>
    </Center>
  );
};

export default OnboardingEndScreen;
