import { createStyles } from '@mantine/core';

type Props = { status: string; backgroundColor?: string };

const StatusCell = ({ status, backgroundColor = '#EEEEF3' }: Props) => {
  const { classes } = useStyles();

  return (
    <div
      className={classes.pill}
      style={{
        backgroundColor,
      }}
    >
      {status}
    </div>
  );
};

const useStyles = createStyles({
  pill: {
    padding: '4px 16px',
    fontWeight: 500,
    color: 'black',
    textAlign: 'center',
    borderRadius: '100px',
    maxWidth: '125px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export default StatusCell;
