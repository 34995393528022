import { useEffect, useRef, useState } from 'react';
import { ActionStatus } from './expense-management';
import { useStyles } from './expense-management.styles';
import { SyncIcon } from '@flexbase-eng/web-components';
import { CheckCircleGreen, ErrorCircle } from 'assets/svg';

type ExpenseSyncIconProps = {
  status: ActionStatus | null;
};

export const ExpenseSyncIcon = ({ status }: ExpenseSyncIconProps) => {
  const ANIMATE_TIMING = 200;
  const lastStatusRef = useRef<ActionStatus | null>(null);
  const [animating, setAnimating] = useState(false);
  const { classes } = useStyles({ timing: { syncIcon: ANIMATE_TIMING } });

  useEffect(() => {
    const fromLoading = lastStatusRef.current === ActionStatus.loading;
    const fromQueued = lastStatusRef.current === ActionStatus.inProgress;
    const toComplete = status === ActionStatus.complete;
    let tid: number;

    if ((fromLoading || fromQueued) && toComplete) {
      setAnimating(true);
      tid = window.setTimeout(() => setAnimating(false), 1000);
    }

    lastStatusRef.current = status;

    return () => {
      if (tid) {
        clearTimeout(tid);
      }

      setAnimating(false);
    };
  }, [status]);

  // loading means UI request is in flight
  // loading overrides other icons
  if (status === ActionStatus.loading) {
    return <SyncIcon fill="currentColor" className={classes.spin} />;
  }

  // incomplete means user has made a change but has not synced
  if (status === ActionStatus.incomplete) {
    return <SyncIcon fill="currentColor" />;
  }

  // backend could not sync properly
  if (status === ActionStatus.failure) {
    return <ErrorCircle height={16} width={16} fill="currentColor" />;
  }

  // sync is processing on the backend
  if (status === ActionStatus.inProgress) {
    return <SyncIcon fill="currentColor" className={classes.spin} />;
  }

  return <CheckCircleGreen className={animating ? classes.syncIcon : ''} />;
};
