import { Anchor } from '@mantine/core';
import { FlexIcon } from 'assets/svg';

type Props = React.SVGAttributes<SVGSVGElement>;

const FlexIconLink = ({ ...props }: Props) => {
  return (
    <Anchor href="/" color="inherit">
      <FlexIcon {...props} />
    </Anchor>
  );
};

export default FlexIconLink;
