import { useState } from 'react';
import { Box, Loader, Text } from '@mantine/core';

type Props = {
  iframeUrl: string;
  status: string;
  error: string;
  loading: boolean;
};
export const CardFrame = ({ iframeUrl, status, error, loading }: Props) => {
  const [iframeLoading, setIframeLoading] = useState(true);

  const hasError = !!error;
  const isLoading = iframeLoading || loading;

  const isNotActivated = status === 'issued' || status === 'requested';

  const showCardStatus = !isLoading && !hasError;
  const showIframe = !loading && !error;
  const showLoader = isLoading && !hasError;
  const showActivationText = isNotActivated && showCardStatus;

  let mappedStatus: string;

  switch (status) {
    case 'requested':
      mappedStatus = 'Shipping';
      break;
    case 'issued': {
      mappedStatus = 'Not Activated'; // Old code had this as "pending" but seems confusing to me
      break;
    }
    case 'suspended':
      mappedStatus = 'Frozen';
      break;
    default:
      mappedStatus = status.charAt(0).toUpperCase() + status.slice(1);
  }

  return (
    <Box
      sx={(theme) => ({
        position: 'relative', // Necessary to correctly center the loader
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: `calc(813px * 0.536285)`,
        minHeight: `calc(513px * 0.536285)`,
        width: `calc(813px * 0.536285)`,
        height: `calc(513px * 0.536285)`,
        backgroundColor: theme.fn.themeColor('primarySecondarySuccess', 8),
        borderRadius: 16,
        ...(status === 'suspended' && { opacity: 0.5 }),
        ...(showActivationText && {
          backgroundImage: `url("/images/CardBack2x.png")`,
          backgroundSize: 'cover',
        }),
        '@media(max-width: 767px)': {
          minWidth: `calc(813px * 0.3936)`,
          minHeight: `calc(513px * 0.3936)`,
          width: `calc(813px * 0.3936)`,
          height: `calc(513px * 0.3936)`,
        },
      })}
    >
      {showLoader && <Loader color="neutral.0" sx={{ position: 'absolute' }} />}
      {showCardStatus && (
        <Box
          sx={(theme) => ({
            backgroundColor: theme.fn.themeColor('neutral', 1),
            color: theme.fn.themeColor('neutral', 8),
            padding: '0 8px',
            borderRadius: '100px',
            position: 'absolute',
            zIndex: 100,
            top: '54px',
            right: '44px',
            '@media(max-width:767px)': {
              top: '38px',
              right: '28px',
            },
          })}
        >
          <Text fz={14} fw={500}>
            {mappedStatus}
          </Text>
        </Box>
      )}
      {showActivationText && (
        <Box
          sx={(theme) => ({
            padding: theme.spacing.md,
            borderRadius: 16,
            backgroundColor: theme.fn.themeColor('neutral', 1),
            fontSize: 20,
            '@media(max-width: 767px)': {
              fontSize: 16,
            },
          })}
        >
          <Text c="primarySecondarySuccess.8" fw={500}>
            Activate card to view details
          </Text>
        </Box>
      )}
      {showIframe && (
        <iframe
          id="iframe"
          src={iframeUrl}
          allow="clipboard-write"
          onLoad={() => {
            setIframeLoading(false);
          }}
          style={{ display: isNotActivated ? 'none' : '' }}
        ></iframe>
      )}
      {hasError && <Text c="neutral.0">{error}</Text>}
    </Box>
  );
};
