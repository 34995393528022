import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  card: {
    padding: '1.5rem',
    marginTop: '1.25rem',
    borderRadius: theme.defaultRadius,
    backgroundColor: '#fff',
    border: `0.063rem solid ${theme.fn.themeColor('neutral', 1)}`,
  },
  dropZonePlaceholderContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  dropZonePlaceholder: {
    fontSize: '0.875rem',
    color: '#757575',
  },
  dropZone: {
    width: '100%',
    height: '5rem',
    padding: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '0.063rem dashed #E2E7F0',
    borderRadius: '1rem',
    '&:hover': {
      backgroundColor: 'rgba(57, 160, 255, 0.04)',
    },
  },
  dropZonePlaceholderImage: {
    marginRight: '1rem',
  },
}));
