import { Flex, Grid, createStyles } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

type StylesProps = {
  borderBottomSolid: boolean;
};

const useStyles = createStyles((theme, { borderBottomSolid }: StylesProps) => ({
  container: {
    padding: 12,
    backgroundColor: 'white',
    borderRadius: theme.defaultRadius,
    maxWidth: '1365px',
    margin: 'auto',
  },
  header: {
    borderBottom: borderBottomSolid ? 'solid' : 'none',
    borderBottomColor: theme.colors.flexbaseGrey[0],
    borderBottomWidth: 0.5,
    padding: '16px',
  },
}));

type Props = {
  leftHeaderContent: React.ReactNode;
  rightHeaderContent: React.ReactNode;
  table: React.ReactNode;
  borderBottomSolid?: boolean;
};

const TabTableLayout = ({
  leftHeaderContent,
  rightHeaderContent,
  table,
  borderBottomSolid = true,
}: Props) => {
  const { classes } = useStyles({ borderBottomSolid });
  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Grid gutter={'xs'} justify="space-between" mb={isMobile ? 10 : 24}>
          <Grid.Col span={isMobile ? 12 : 6}>{leftHeaderContent}</Grid.Col>
          <Grid.Col span={isMobile ? 12 : 6}>
            <Flex justify={isMobile ? 'flex-start' : 'flex-end'}>
              {rightHeaderContent}
            </Flex>
          </Grid.Col>
        </Grid>
      </div>
      {table}
    </div>
  );
};

export default TabTableLayout;
