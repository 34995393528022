export function formatZip(value: string): string {
  if (!value) {
    return '';
  }

  const zip = value.replace(/\D/g, '');

  if (value.endsWith('-')) {
    return `${zip}-`;
  }

  if (zip.length > 5) {
    return `${zip.substring(0, 5)}-${zip.slice(5, 9)}`;
  }

  return zip;
}
