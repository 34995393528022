import { FinancialInstitution } from '../../states/onboarding/onboarding-info';
// import { useStyles } from '@common/account-box/styles';
import getPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';
import { Box, Loader, Text, UnstyledButton } from '@mantine/core';

type Props = {
  account: FinancialInstitution;
  isPrimaryAccount: boolean;
  onSetAsPrimaryClick: (id: string) => void;
  showLoading?: boolean;
};

export const PlaidLinkAccountBox = ({
  account,
  isPrimaryAccount,
  onSetAsPrimaryClick,
  showLoading,
}: Props) => {
  return (
    <Box
      sx={(theme) => ({
        height: '80px',
        border: '1px solid #E6E7E9',
        borderRadius: theme.defaultRadius,
        padding: '8px 16px',
        gap: '16px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.fn.themeColor('neutral', 0),
        ...(isPrimaryAccount && {
          border: `1px solid ${theme.fn.primaryColor()}`,
        }),
      })}
    >
      <Box
        sx={{
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          width: '100%',
        }}
        c="neutral.7"
      >
        <Text lh="17px">
          {account.bankName ? `${account.bankName} / ` : ''}
          {account.accountName}
        </Text>
        <Text lh="17px">{getPaddedAccountMask(account.account, 4)}</Text>
      </Box>
      {showLoading && <Loader size="xs" />}
      {isPrimaryAccount ? (
        <Text
          ml="auto"
          fz={12}
          fw={500}
          px="0.875rem"
          c="primarySecondarySuccess.8"
          id={`text-primary-account-${account.id}`}
        >
          Primary
        </Text>
      ) : (
        <UnstyledButton
          variant="subtle"
          fz={12}
          w={100}
          ml={'auto'}
          c="something.2"
          onClick={() => onSetAsPrimaryClick(account.id)}
          id={`button-set-primary-account-${account.id}`}
        >
          Make Primary
        </UnstyledButton>
      )}
    </Box>
  );
};
