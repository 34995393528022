import { FlexbaseTable } from '@common/table';
import { ChecksTable } from '../check-deposits';
import useModal from '@common/modal/modal-hook';
import BadgeStatus from './check-deposit-status-badge';
import { TableColumn } from 'react-data-table-component';
import { getMonthDayYear } from '@utilities/dates/dates';
import { formatCurrency } from '@utilities/formatters/format-currency';
import CheckDepositDetails from './check-deposit-details-modal/check-deposit-details';

type CheckDepositsProps = {
  isLoading: boolean;
  checkDeposits: ChecksTable[];
};

const CheckDepositsTable = ({
  checkDeposits,
  isLoading,
}: CheckDepositsProps) => {
  const { openRightModal } = useModal();

  const columns: TableColumn<ChecksTable>[] = [
    {
      name: 'Date created',
      selector: (row) => row.createdAt,
      format: (row) => getMonthDayYear(row?.createdAt),
      sortable: true,
    },
    {
      name: 'To/From',
      selector: (row) => row.counterparty,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: (row) => row.amount,
      cell: (row) => formatCurrency(row.amount),
      sortable: true,
    },
    {
      name: 'Status',
      sortable: true,
      selector: (row) => row.status,
      cell: (row) => <BadgeStatus status={row.status} />,
    },
  ];

  const handleRowClicked = (row: ChecksTable) => {
    openRightModal(<CheckDepositDetails id={row.id} />);
  };

  return (
    <FlexbaseTable
      columns={columns}
      data={checkDeposits}
      isFetchingData={isLoading}
      onRowClicked={handleRowClicked}
      pagination={checkDeposits.length > 5}
    />
  );
};

export default CheckDepositsTable;
