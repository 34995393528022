import { Anchor, Group } from '@mantine/core';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { Link } from 'react-router-dom';
import FlexbaseMainLayout from '../main';

type Props = {
  children: React.ReactNode;
  linkTitle: string;
  linkPath: string;
};

const DetailPageLayout = ({ children, linkTitle, linkPath }: Props) => {
  return (
    <FlexbaseMainLayout>
      <Anchor
        mt="2rem"
        c="neutral.7"
        display="inline-block"
        component={Link}
        to={linkPath}
      >
        <Group spacing="xxs">
          <MdOutlineKeyboardArrowLeft />
          {linkTitle}
        </Group>
      </Anchor>
      {children}
    </FlexbaseMainLayout>
  );
};

export default DetailPageLayout;
