import { atom } from 'recoil';
import {
  ActiveFiltersReturnType,
  createUseFiltersHook,
  FilterFnMap,
} from '@common/filter/filters';
import { Category } from '@flexbase-eng/types/dist/accounting';

const MappingFilterState = atom<FilterFnMap<Category>>({
  key: 'mapping_filter_state',
  default: {},
});

export function useMappingFilters(): ActiveFiltersReturnType<Category> {
  return createUseFiltersHook<Category>(MappingFilterState);
}
