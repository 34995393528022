import { createMultiStepFormDynamicContext } from '../../../../providers/multi-step-form-provider';

export type FactorSetupFormState = {
  loading: boolean;
  error: string | null;
  flow: 'otp' | 'totp' | null;
};

export const [FactorSetupFormProvider, useFactorSetupMultistepFormContext] =
  createMultiStepFormDynamicContext<FactorSetupFormState>();
