import {
  Box,
  Text,
  Group,
  Select,
  ActionIcon,
  useMantineTheme,
} from '@mantine/core';
import GetPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';
import { forwardRef, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { ParsedAccount } from './types';
import { ChevronDownIcon } from 'assets/svg';

type ClearButtonProps = {
  color: string;
  onClick: () => void;
};

// Mantine's clear button isn't working for some reason, so we're rolling our own
const ClearButton = ({ onClick, color }: ClearButtonProps) => (
  <ActionIcon
    onClick={onClick}
    sx={() => ({
      '&:hover': {
        backgroundColor: 'transparent',
      },
    })}
  >
    <IoClose color={color} />
  </ActionIcon>
);

type ItemProps = {
  label: string;
  description: string;
} & React.ComponentPropsWithoutRef<'div'>;

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, description, ...others }: ItemProps, ref) => (
    <Box ref={ref} {...others}>
      <Group noWrap>
        <Box>
          <Text size="sm">{label}</Text>
          <Text size="xs" opacity={0.65}>
            {description}
          </Text>
        </Box>
      </Group>
    </Box>
  ),
);
SelectItem.displayName = 'SelectItem';

const getDropdownOptions = (accounts: ParsedAccount[]) => {
  const allOptions = [
    {
      label: 'All accounts',
      value: 'all',
      description: '',
      group: '',
    },
  ];

  return allOptions.concat(
    accounts.map(({ id, nickName, type, accountNumber, routingNumber }) => ({
      label:
        nickName ??
        `No nickname - ${GetPaddedAccountMask(accountNumber ?? '', 2)}`,
      value: id,
      description: routingNumber
        ? `Acct ${GetPaddedAccountMask(
            accountNumber ?? '',
            2,
          )} Routing ${GetPaddedAccountMask(routingNumber, 2)}`
        : `Acct ${GetPaddedAccountMask(accountNumber ?? '', 2)}`,
      group: type,
    })),
  );
};

type Props = {
  textColor?: string;
  defaultValue?: string;
  accounts: ParsedAccount[];
  onChange: (value: string) => void;
};

const AccountsDropdown = ({
  accounts,
  textColor,
  defaultValue = 'all',
  onChange,
}: Props) => {
  const theme = useMantineTheme();
  const [dropdownValue, setDropdownValue] = useState(defaultValue);
  const data = getDropdownOptions(accounts);

  const handleChange = (val: string) => {
    if (!val) {
      return;
    } else {
      setDropdownValue(val);
      onChange(val);
    }
  };

  return (
    <Select
      itemComponent={SelectItem}
      data={data}
      maxDropdownHeight={400}
      searchable
      clearable
      value={dropdownValue}
      onChange={handleChange}
      rightSection={
        dropdownValue !== 'all' ? (
          <ClearButton
            color={theme.fn.themeColor('neutral', 4)}
            onClick={() => handleChange('all')}
          />
        ) : (
          <ChevronDownIcon color={theme.fn.themeColor('neutral', 4)} />
        )
      }
      styles={{
        input: {
          border: 'none',
          cursor: 'pointer',
          textAlign: 'right',
          background: 'unset',
          color: textColor ?? 'initial',
        },
      }}
      rightSectionProps={{ style: { pointerEvents: 'all' } }}
      nothingFound="No accounts found"
      filter={(value, item) =>
        item.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item.description.toLowerCase().includes(value.toLowerCase().trim())
      }
    />
  );
};

export default AccountsDropdown;
