import { createStyles } from '@mantine/core';
import { ReactNode } from 'react';
import OnboardingButtonGroup from 'areas/onboarding-v2/components/onboarding-button-group';
import {
  isInternationalWire,
  isInternationalWireUSD,
} from './send-payment/international-payments/util';

type Props = {
  children: ReactNode;
  titleText?: string;
  subtitle?: ReactNode;
  balloonText?: string;
  showBackButton?: boolean;
  showNextButton?: boolean;
  onBackClick?: () => void;
  onNextClick?: () => void;
  nextButtonLabel?: string;
  nextButtonFormId?: string;
  nextButtonType?: 'button' | 'submit';
  backButtonLabel?: string;
  footer?: ReactNode;
  loading?: boolean;
};

const PaymentStep = ({
  children,
  balloonText,
  showBackButton = true,
  showNextButton = true,
  onNextClick,
  onBackClick,
  titleText,
  nextButtonLabel,
  nextButtonFormId,
  nextButtonType,
  backButtonLabel,
  subtitle,
  footer,
  loading,
}: Props) => {
  const { classes } = useStyles();

  const onNext = () => {
    if (onNextClick) {
      onNextClick();
    }
  };

  const onBack = () => {
    if (onBackClick) {
      onBackClick();
    }
  };

  return (
    <div className={classes.container}>
      {balloonText && (
        <div className={classes.recipientBalloon}>{balloonText}</div>
      )}
      {titleText && <div className={classes.title}>{titleText}</div>}
      {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
      <div className={classes.inputContainer}>{children}</div>
      <div className={classes.buttonContainer}>
        <OnboardingButtonGroup
          loading={loading}
          showNextButton={showNextButton}
          showBackButton={showBackButton}
          onNextClick={() => onNext()}
          onBackClick={() => onBack()}
          nextButtonLabel={nextButtonLabel}
          nextButtonFormId={nextButtonFormId}
          nextButtonType={nextButtonType}
          backButtonLabel={backButtonLabel}
        />
        {footer ? footer : null}
      </div>
    </div>
  );
};

export const getPrettyMethod = (method: string) => {
  if (method === 'ach') {
    return 'ACH';
  }
  if (method === 'wire') {
    return 'Domestic Wire';
  }
  if (method === 'book') {
    return 'Internal transfer';
  }
  if (isInternationalWireUSD(method)) {
    return 'International wire - USD';
  }
  if (isInternationalWire(method)) {
    return 'International wire - Foreign exchange';
  }
};

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    minHeight: '520px',
    display: 'flex',
    flexDirection: 'column',
  },
  recipientBalloon: {
    backgroundColor: theme.fn.themeColor('neutral', 2),
    borderRadius: theme.defaultRadius,
    marginBottom: '29px',
    height: '48px',
    minHeight: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.fn.primaryColor(),
    fontSize: '14px',
    fontWeight: 500,
  },
  title: {
    fontFamily: 'PP Neue Montreal',
    fontWeight: 400,
    fontSize: 20,
    lineHeight: '34px',
    '@media(max-width: 767px)': {
      fontSize: 24,
    },
  },
  subtitle: {
    fontFamily: 'PP Neue Montreal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '21px',
    marginTop: '10px',
  },
  inputContainer: {
    marginTop: '0.4rem',
  },
  buttonContainer: {
    marginTop: 'auto',
  },
}));

export default PaymentStep;
