import { useState } from 'react';
import { useForm } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import {
  Grid,
  Center,
  Loader,
  Progress,
  UnstyledButton,
  useMantineTheme,
  Text,
  Button,
} from '@mantine/core';

import { CloseIcon } from 'assets/svg';
import { useStyles } from '../../styles';
import DebitCardForm from './debit-card-form';
import { useGetUsers } from '@queries/use-users';
import FlexIconLink from '@common/icons/flex-icon-link';
import { useGetDepositAccounts } from '@queries/use-deposit-accounts';
import ModalSuccess from '../../components/modal-success/modal-success';
import { DepositAccount } from 'areas/banking/move-funds/move-funds.model';
import useModal from '@common/modal/modal-hook';
import GenericError from 'areas/banking/utils/generic-error';

export enum IssueDebitCardSteps {
  ISSUE_CARD,
  SUCCESS,
}

type Props = {
  appType: string;
};

const IssueDebitCard = ({ appType }: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const { closeAllModals } = useModal();
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [step, setStep] = useState(IssueDebitCardSteps.ISSUE_CARD);
  const {
    data: employeesData,
    isLoading: isLoadingEmployees,
    isError: isErrorEmployees,
    refetch: refetchEmployees,
  } = useGetUsers();
  const {
    data: depositAccountsData,
    isLoading: isLoadingDepositAccounts,
    isError: isErrorAccounts,
    refetch: refetchAccounts,
  } = useGetDepositAccounts();

  const bankingAccounts =
    (depositAccountsData?.accounts
      .filter((a) => a.status === 'Open')
      .sort((a, b) => b.balance - a.balance)
      .map((a) => ({ ...a, plaidOrDeposit: 'deposit' })) as DepositAccount[]) ||
    [];

  const employees =
    employeesData
      ?.filter(
        (user) =>
          user.roles?.includes('ADMIN') &&
          user.completedOnboarding &&
          user.status === 'active',
      )
      .map((employee) => ({
        value: employee.id!,
        label: `${employee.firstName} ${employee.lastName}`,
      })) || [];

  const form = useForm({
    initialValues: {
      userId: '',
      cardName: '',
      purchaseLimit: null,
      limitType: 'unlimited',
      cardType:
        appType === 'businessApplication'
          ? 'businessDebitCard'
          : 'individualDebitCard',
    },
    validate: {
      userId: (value) => (!value ? 'Choose whose will this card is for' : null),
    },
  });

  const cardUser = employees.find(
    (employee) => employee.value === form.values.userId,
  );

  let currentStep: any;
  switch (step) {
    case IssueDebitCardSteps.ISSUE_CARD:
      currentStep = (
        <DebitCardForm
          {...{
            form,
            setStep,
            appType,
            employees,
            bankingAccounts,
          }}
        />
      );
      break;

    case IssueDebitCardSteps.SUCCESS:
      currentStep = (
        <div>
          <ModalSuccess
            backTo="debit cards"
            onClick={() => {
              setStep(IssueDebitCardSteps.ISSUE_CARD);
              form.setValues({
                userId: '',
                cardType: '',
                cardName: '',
                purchaseLimit: null,
                limitType: 'unlimited',
              });
            }}
            closeModal={closeAllModals}
            textToStart="Issue another debit card"
            title={`You’ve created a new debit card for ${cardUser?.label}!`}
          />
        </div>
      );
      break;
  }

  if (isLoadingEmployees || isLoadingDepositAccounts) {
    return (
      <Center style={{ height: '100vh' }}>
        <Loader color={theme.fn.primaryColor()} size={50} />
      </Center>
    );
  }

  if (useMobileView) {
    return (
      <div className={classes.modalFormContent}>
        <UnstyledButton className="close-button" onClick={closeAllModals}>
          <CloseIcon color="#979797" />
        </UnstyledButton>
        {step < IssueDebitCardSteps.SUCCESS && (
          <Progress radius="sm" value={((step + 1) * 100) / 3} />
        )}
        <div className="content">{currentStep}</div>
      </div>
    );
  }

  const isError = isErrorEmployees || isErrorAccounts;
  if (isError) {
    return (
      <GenericError>
        <Text align="center">An unhandled error occurred.</Text>
        <Button
          variant="light"
          onClick={() => {
            refetchEmployees();
            refetchAccounts();
          }}
          mt={20}
        >
          Please try again
        </Button>
      </GenericError>
    );
  }

  return (
    <Grid className={classes.modalFormContent}>
      <Grid.Col span={3}>
        <FlexIconLink width={90} />
      </Grid.Col>

      <Grid.Col className="content" span={6}>
        {currentStep}
      </Grid.Col>

      <Grid.Col span={3} className="close">
        <UnstyledButton onClick={closeAllModals}>
          <CloseIcon width={34} color="#979797" />
        </UnstyledButton>
      </Grid.Col>
    </Grid>
  );
};

export default IssueDebitCard;
