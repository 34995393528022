import { Badge } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useMercoaSession } from '@mercoa/react';
import { NavTabContainer } from '@common/tabs/nav-tab-container';

const BillingPage = () => {
  const mercoaSession = useMercoaSession();
  const [invoicesThatNeedApprovalCount, setInvoicesThatNeedApprovalCount] =
    useState<number | null>(null);

  const getCount = () => {
    mercoaSession.client?.entity.invoice
      .find(mercoaSession.entity?.id ?? '', {
        status: 'NEW',
      })
      .then((resp) => {
        if (resp) {
          setInvoicesThatNeedApprovalCount(resp.count);
        }
      });
  };

  useEffect(() => {
    if (mercoaSession.entity && !invoicesThatNeedApprovalCount) {
      getCount();
    }
  }, [mercoaSession.entity]);

  const BILLING_TABS = [
    { label: 'Bill activity', route: '/bill-pay/dashboard' },
    { label: 'Unpaid bills', route: '/bill-pay/unpaid' },
    {
      label: 'Action required',
      route: '/bill-pay/pending-approval',
      count: (
        <Badge
          styles={(theme) => ({
            inner: { color: theme.fn.themeColor('neutral', 0) },
            root: {
              display: !invoicesThatNeedApprovalCount ? 'none' : 'initial',
            },
          })}
          bg="primarySecondarySuccess.3"
        >
          {invoicesThatNeedApprovalCount}
        </Badge>
      ),
    },
    { label: 'Scheduled bills', route: '/bill-pay/scheduled' },
    { label: 'Paid bills', route: '/bill-pay/paid' },
    { label: 'Vendors & recipients', route: '/bill-pay/vendors' },
  ];

  const mercoaToken = mercoaSession.token ?? '';

  return (
    <NavTabContainer tabs={BILLING_TABS}>
      <Outlet context={{ mercoaToken }} />
    </NavTabContainer>
  );
};

export default BillingPage;
