import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  payableFormHeader: {
    gridColumn: '1/-1',
    h2: {
      color: theme.colors.primarySecondarySuccess[8],
      fontSize: 'inherit',
      fontWeight: 500,
      margin: 0,
    },
  },
  payableCounterpartySearch: {
    gridColumn: '1/-1',
    label: {
      color: theme.colors.primarySecondarySuccess[8],
    },
    '[type=submit]': {
      backgroundColor: theme.colors.primarySecondarySuccess[2],
      color: theme.colors.contentBackground[2],
      borderWidth: 0,
      padding: 'auto',
      cursor: 'pointer',
      ':hover': {
        background: theme.colors.primarySecondarySuccess[3],
        color: theme.colors.contentBackground[2],
      },
    },
  },
  payableOverview: {
    gridColumn: '1/-1',
    label: {
      color: theme.colors.primarySecondarySuccess[8],
    },
  },
  payableItems: {
    gridColumn: '1/-1',
    'label, h2, h3, span, svg': {
      color: theme.colors.primarySecondarySuccess[8],
    },
    '.mercoa-grid-cols-2': {
      div: {
        margin: '0 0.094rem',
      },
    },
    button: {
      backgroundColor: 'initial',
      color: theme.colors.promote[9],
      cursor: 'pointer',
      borderWidth: 0,
      ':hover': {
        backgroundColor: 'initial',
        color: theme.colors.promote[9],
        cursor: 'pointer',
      },
    },
  },
  payablePaymentSource: {
    gridColumn: '1/-1',
    h2: {
      color: theme.colors.primarySecondarySuccess[8],
    },
  },
  payablePaymentDestination: {
    gridColumn: '1/-1',
    h2: {
      color: theme.colors.primarySecondarySuccess[8],
    },
  },
  payableApprovers: {
    gridColumn: '1/-1',
    'h2, label': {
      color: theme.colors.primarySecondarySuccess[8],
    },
  },
  invoiceComments: {
    gridColumn: '1/-1',
    span: {
      color: theme.colors.primarySecondarySuccess[8],
    },
    button: {
      backgroundColor: 'initial',
      color: theme.colors.promote[9],
      cursor: 'pointer',
      borderWidth: 0,
    },
  },

  nextButton: {
    cursor: 'pointer',
    ':disabled': {
      opacity: 1,
      color: theme.colors.promote[6],
      background: theme.colors.flexbaseGrey[3],
    },
  },
  deleteOrCancelInvoiceButton: {
    color: theme.colors.tertiary[6],
  },
  container: {
    padding: '1rem',
    backgroundColor: '#fff',
    width: '80%',
    '@media(max-width: 767px)': {
      width: '100%',
      div: {
        display: 'block',
      },
    },
    label: {
      fontSize: '0.875rem',
    },
    a: {
      color: theme.colors.contentBackground[2],
      textDecoration: 'inherit',
      fontSize: '0.875rem',
      '[type=button]': {
        margin: 0,
        borderRadius: 0,
        cursor: 'pointer',
        display: 'ruby',
        svg: {
          width: '1.188rem',
        },
      },
    },
    table: {
      textIndent: 0,
      borderColor: 'inherit',
      borderCollapse: 'collapse',
      th: {
        fontSize: '0.75rem',
        fontWeight: 500,
      },
    },
    p: {
      margin: 0,
    },
    textarea: {
      padding: '0.5rem',
    },
    svg: {
      verticalAlign: 'middle',
      display: 'block',
    },
    'menu, ol, ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
    '[type=text]:focus, input:where(:not([type])):focus, select:focus, textarea:focus':
      {
        outline: '2px solid #0000',
        '--tw-ring-color': 'black',
      },
    '[name=amount]': {
      paddingRight: '5.625rem',
    },
    '*': {
      '--tw-ring-offset-width': '0px',
      '--tw-ring-offset-color': '#fff',
    },
    '*:after, *:before': {
      border: '0 solid #e5e7eb',
    },
  },
}));
