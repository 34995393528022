import { ReactNode, Suspense } from 'react';
import { LoadingOverlay } from '@mantine/core';
import SkeletonLoading from './skeleton-loading';
import AppErrorBoundary from 'components/error/app-error-boundary';

type Props = { children: ReactNode; loadingIndicator?: 'spinner' | 'skeleton' };

/**
 * Use this component to wrap a component that relies on the async Recoil feature.
 * @param children
 * @param loadingIndicator 'spinner' for loading overlay spinner (default), 'skeleton' for table skeleton loader.
 * @constructor
 */
const Loadable = ({ children, loadingIndicator = 'spinner' }: Props) => {
  const LoadingIndicator = () => {
    switch (loadingIndicator) {
      case 'skeleton':
        return <SkeletonLoading />;
      case 'spinner':
        return <LoadingOverlay visible={true} />;
    }
  };

  return (
    <AppErrorBoundary>
      <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>
    </AppErrorBoundary>
  );
};

export default Loadable;
