import { Center, Container, createStyles, rem } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { ReactNode } from 'react';
import { IconContext } from 'react-icons';
import { IoMdClose } from 'react-icons/io';
import FlexIconLink from '@common/icons/flex-icon-link';

const useStyles = createStyles((theme) => ({
  escapeIcon: {
    height: '60px',
    width: '60px',
    color: '#979797',
    cursor: 'pointer',
  },
  escapeIconSm: {
    height: 32,
    width: 32,
    color: '#979797',
    cursor: 'pointer',
  },
  fakeModal: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 50,
    backgroundColor: theme.colors.neutral[2],
    alignItems: 'flex-start', // Otherwise the height for the content goes funky
    position: 'fixed',
    overflowX: 'auto',
  },
  innerContent: {
    backgroundColor: '#FFFFFF',
    minHeight: 521,
    width: 420,
    marginTop: '2.5vh',
    padding: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
  },
  smallModal: {
    padding: '5vmin',
    display: 'block',
    flexDirection: 'column',
    maxWidth: '100vw',
    overflow: 'auto',
    height: '100vh',
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
    backgroundColor: theme.colors.neutral[2],
  },
  smallContent: {
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
    width: '100%',
    marginTop: 16,
    padding: 32,
    minHeight: 521,
    '@media(max-width: 767px)': {
      minHeight: 'auto',
      paddingBottom: 100,
    },
  },
  icon: {
    width: rem(80),
    '@media(max-width: 767px)': {
      width: rem(60),
    },
  },
}));

type Props = {
  children?: ReactNode;
  unrestrictedContent?: boolean;
  closeModal: () => void;
};

const FullScreenModalContainer = ({
  children,
  unrestrictedContent,
  closeModal,
}: Props) => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const { classes } = useStyles();

  return isMobile ? (
    <div className={classes.smallModal}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Center>
          <FlexIconLink className={classes.icon} />
        </Center>
        <IconContext.Provider value={{ size: '34px' }}>
          <div className={classes.escapeIconSm} onClick={() => closeModal()}>
            <IoMdClose />
          </div>
        </IconContext.Provider>
      </div>
      {unrestrictedContent ? (
        children
      ) : (
        <Container className={classes.smallContent}>{children}</Container>
      )}
    </div>
  ) : (
    <div className={classes.fakeModal}>
      <Center>
        <FlexIconLink className={classes.icon} />
      </Center>

      {unrestrictedContent ? (
        children
      ) : (
        <div className={classes.innerContent}>{children}</div>
      )}

      <IconContext.Provider value={{ size: '34px' }}>
        <div className={classes.escapeIcon} onClick={() => closeModal()}>
          <IoMdClose />
        </div>
      </IconContext.Provider>
    </div>
  );
};

export default FullScreenModalContainer;
