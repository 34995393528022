import { Box, BoxProps, createStyles, Loader, rem, Stack } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Fragment, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { TermsOfServiceDoc } from 'services/flexbase/flexbase-onboarding-client';
import { CompanySelector } from 'states/application/product-onboarding';

const interestDisclaimer =
  '*0% interest applies if the full balance is paid within the 60-day grace period. Interest applies when the full balance is not paid within the 60-day grace period. See your Flex Commercial Credit Cardholder Agreement for details. ';

const annualPercentageYieldDisclaimer = `** See your Flex Cashback Terms and Conditions for details.`;

const flexCashBackDisclaimer = `***Annual Percentage Yield (APY) of up to 4% is accurate as of 09/15/23. This is a variable rate and may change after the account is opened. See your Flex Deposit Account Terms and Conditions for details. `;

const creditDisclaimer =
  'The Flex Commercial Credit Card (“Flex Credit Card”) is issued by Patriot Bank, N.A., pursuant to licenses from Mastercard® International Incorporated. The Flex Credit Card can be used everywhere Mastercard is accepted. Mastercard is a registered trademark of Mastercard International. Flex provides the credit for the Flex Credit Card. The Flex Credit Card is not available yet in the following states: CA, ND, SD, VT, and NV.';

const bankingDisclaimer =
  'Flexbase Technologies, Inc. is a financial technology company and is not a bank. Banking services provided by Thread Bank; Member FDIC. The Flexbase Technologies, Inc. Visa® Debit Card (“Flex Debit Card” / “Flex Banking”) is issued by Thread Bank pursuant to a license from Visa U.S.A. Inc. and may be used everywhere Visa debit cards are accepted.';

const flexDisclaimer =
  '©2023 Flexbase Technologies, Inc., d/b/a Flex, “Flex" and the Flex logo are registered trademarks. Flex products may not be available to all customers. See the Flex Terms of Service for details. Terms are subject to change.';

const useStyles = createStyles((theme) => ({
  footer: {
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === 'dark'
        ? theme.colors.dark[5]
        : theme.colors.neutral[3]
    }`,
    padding: '20px 40px 20px 40px',
    lineHeight: 1,
    '@media(max-width: 767px)': {
      marginTop: theme.spacing.md,
    },
  },

  inner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: theme.spacing.xs,
    paddingBottom: theme.spacing.xs,
    [theme.fn.smallerThan(1280)]: {
      padding: 0,
    },
    '@media(max-width: 767px)': {
      flexDirection: 'row',
      padding: 0,
      marginBottom: 15,
    },
  },

  disclaimer: {
    fontSize: '10px',
    width: '100%',
  },

  contact: {
    fontSize: '10px',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginRight: theme.spacing.md,
    '@media(max-width: 767px)': {
      marginTop: 8,
    },
  },

  disclosure: {
    fontSize: '10px',
    fontWeight: 500,
    marginRight: 66,
  },
  disclosureContainer: {
    maxWidth: 500,
    '@media(max-width: 767px)': {
      maxWidth: 200,
    },
  },
}));

type ClassNames = {
  footer: string;
  footerInner: string;
  footerDisclaimer: string;
  footerDisclaimer2: string;
  footerContact: string;
  footerDiscloure: string;
  footerDisclosureContainer: string;
};

type Props = {
  disclaimer2?: boolean;
  footerMarginTop?: string;
  classNames?: Partial<ClassNames>;
} & Omit<BoxProps, 'classNames'>;

export const FlexbaseFooter = ({
  disclaimer2 = false,
  footerMarginTop,
  classNames,
  ...boxProps
}: Props) => {
  const { classes, cx } = useStyles();
  const [loading, setLoading] = useState(false);
  const [termsDocuments, setTermsDocuments] = useState<TermsOfServiceDoc[]>([]);
  const isMobile = useMediaQuery('(max-width: 767px)');
  const { optedProducts } = useRecoilValue(CompanySelector);

  const getTermsOfServiceDocs = async () => {
    setLoading(true);
    try {
      const flexbaseDocs = await flexbaseOnboardingClient.getTermsOfServiceDocs(
        'flexbase',
        false,
      );

      const footerTerms = flexbaseDocs.filter(
        (item) =>
          item.label === 'Privacy Policy' ||
          item.label === 'Terms and Conditions',
      );

      setTermsDocuments(footerTerms);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTermsOfServiceDocs();
  }, []);

  const supportContact = (
    <Stack
      className={cx(classes.contact, classNames?.footerContact)}
      align="flex-start"
      spacing="sm"
    >
      <div style={{ marginRight: 66 }}>support@flex.one</div>
      <div>(415) 840-8721 ext. 571</div>
    </Stack>
  );

  const disclosures = loading ? (
    <Loader
      size="sm"
      variant="dots"
      sx={(theme) => ({ color: theme.colors.neutral[0] })}
    />
  ) : (
    <Box
      sx={() => ({
        display: 'flex',
        flexDirection: 'row',
      })}
    >
      {termsDocuments.map((doc, i) => {
        return (
          <div key={doc.tosId + i}>
            <a
              href={doc.url}
              target="_blank"
              rel="noreferrer"
              className={cx(classes.disclosure, classNames?.footerDiscloure)}
            >
              {doc.label}
            </a>
          </div>
        );
      })}
    </Box>
  );
  return (
    <Box
      mt={footerMarginTop}
      className={cx(classes.footer, classNames?.footer)}
      {...boxProps}
    >
      <div>
        {!isMobile ? (
          <Box className={cx(classes.inner, classNames?.footerInner)}>
            {disclosures}
            {supportContact}
          </Box>
        ) : (
          <Box
            className={cx(classes.inner, classNames?.footerInner)}
            style={{ paddingTop: 0 }}
          >
            <div>
              {disclosures}
              {supportContact}
            </div>
          </Box>
        )}
        <Box className={cx(classes.inner, classNames?.footerInner)}>
          <Stack spacing="xs">
            {optedProducts?.map((p, i) => {
              if (p === 'CREDIT') {
                return (
                  <div key={p + i} className={classes.disclaimer}>
                    {creditDisclaimer}
                  </div>
                );
              }
              if (p === 'BANKING') {
                return (
                  <Fragment key={p + i}>
                    <div className={classes.disclaimer}>
                      {bankingDisclaimer}
                    </div>
                    <div className={classes.disclaimer}>{flexDisclaimer}</div>
                  </Fragment>
                );
              }
            })}
          </Stack>
          {!optedProducts?.length && (
            <Stack spacing="xs">
              {!disclaimer2 && (
                <>
                  <div className={classes.disclaimer}>{interestDisclaimer}</div>
                  <div className={classes.disclaimer}>
                    {annualPercentageYieldDisclaimer}
                  </div>
                  <div className={classes.disclaimer}>
                    {flexCashBackDisclaimer}
                  </div>
                </>
              )}

              <div className={classes.disclaimer}>{bankingDisclaimer}</div>
              <div className={classes.disclaimer}>{creditDisclaimer}</div>
              <div className={classes.disclaimer}>{flexDisclaimer}</div>
            </Stack>
          )}
        </Box>
      </div>
    </Box>
  );
};
