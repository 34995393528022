import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  headerRow1: {
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 35,
    backgroundColor: theme.fn.themeColor('primarySecondarySuccess', 8),
    '> div:last-of-type': {
      marginRight: '-11px',
    },
    flexWrap: 'nowrap',
  },
  title: {
    height: '2.75rem',
    color: theme.fn.themeColor('neutral', 0),
    fontFamily: 'PP Neue Montreal',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '2rem',
    '@media(max-width: 767px)': {
      fontSize: '1.43rem',
    },
  },
  closeButton: {
    display: 'flex',
    width: '2.12rem',
    boxSizing: 'content-box',
    flexDirection: 'column',
    color: '#979797',
    cursor: 'pointer',
    borderRadius: '8px',
    alignItems: 'center',
    backgroundColor: 'unset',
    border: '1px solid transparent',
    '&:focus': {
      outline: 'none',
    },
    svg: {
      width: '2.12rem',
      height: '2.12rem',
    },
    '@media(max-width: 767px)': {
      display: 'flow',
      svg: {
        width: '1.62rem',
        height: '1.62rem',
      },
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2rem',
    marginBottom: '2rem',
    background: theme.fn.themeColor('neutral', 2),
    '@media(max-width: 767px)': {
      justifyContent: 'normal',
    },
  },
  card: {
    padding: '1rem',
    backgroundColor: theme.colors.contentBackground[2],
    maxWidth: '35rem',
    minWidth: '35rem',
    button: {
      cursor: 'pointer',
      borderRadius: 0,
    },
    'menu, ol, ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
    svg: {
      display: 'block',
    },
    '@media(max-width: 767px)': {
      maxWidth: '100%',
      minWidth: '100%',
    },
    '*': {
      '--tw-ring-offset-width': '0px',
      '--tw-ring-offset-color': '#fff',
    },
    '*,:after,:before': {
      border: '0 solid #e5e7eb',
    },
  },
}));
