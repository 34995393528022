import { Button, Text } from '@mantine/core';
import { useConfirmAndReviewStyles } from './review-and-pay.styles';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import type {
  DepositAccount,
  PlaidAccount,
} from 'areas/banking/move-funds/move-funds.model';
import AccountIcon from 'areas/payments/components/acount-icon';
import AccountBox from 'components/account-box/account-box';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { capitalizeOnlyFirstLetter } from 'utilities/formatters/format-strings';

// all acceptable Payment Account types
type PaymentAccount = PlaidAccount | DepositAccount;

// derive an account name from the Payment Account
const getName = (account: PaymentAccount) => {
  switch (account.plaidOrDeposit) {
    case 'deposit': {
      return account.nickName || account.name;
    }
    case 'plaid': {
      return (account.bankName || account.accountName) ?? 'Plaid Account';
    }
  }
};

// derive a processing time from the Payment Account
const getProcessingMessage = (account: PaymentAccount) => {
  if (account.plaidOrDeposit === 'deposit') {
    return 'Payments to Flex from Flex Bank accounts will be processed immediately.';
  }

  return 'Payments to Flex initiated after 11:59 p.m. UTC will be processed the next business day.';
};

const accountSubheaderText = (account: PaymentAccount) => {
  switch (account.plaidOrDeposit) {
    case 'deposit': {
      return `${formatCurrency(
        account.balance / 100,
      )} / ${capitalizeOnlyFirstLetter(
        account.accountType,
      )} ${getPaddedAccountMask(account.accountNumber, 4)}`;
    }
    case 'plaid': {
      return `${capitalizeOnlyFirstLetter(
        account.accountType || '',
      )} ${getPaddedAccountMask(account.last4 ?? account.account, 4)}`;
    }
  }
};

type Props = {
  paymentAmount: string;
  paymentAccount: PaymentAccount;
  onConfirmClick: () => void;
  onGoBackClick: () => void;
};

const CardPaymentReview = ({
  paymentAmount,
  paymentAccount,
  onConfirmClick,
  onGoBackClick,
}: Props) => {
  const { classes } = useConfirmAndReviewStyles();

  return (
    <>
      <Text className={classes.subtitle}>
        Once confirmed, your payment will be applied to your Flex card.
      </Text>

      <div>
        <Text className={classes.inputTitle}>Payment Amount</Text>
        <Text
          className={classes.paymentAmtBold}
          data-testid="text-payment-amount"
        >
          {paymentAmount}
        </Text>
      </div>

      <div>
        <Text className={classes.inputTitle}>Pay From:</Text>
        <AccountBox
          headerText={getName(paymentAccount)}
          subheaderText={accountSubheaderText(paymentAccount) || ''}
          showArrow={false}
          isListItem={false}
          showBorder
          icon={
            <AccountIcon
              externalName={
                paymentAccount.plaidOrDeposit === 'plaid'
                  ? getName(paymentAccount)
                  : null
              }
            />
          }
        />
      </div>
      <Text className={classes.utc}>
        {getProcessingMessage(paymentAccount)}
      </Text>
      <Button
        onClick={() => onConfirmClick()}
        size="lg"
        data-testid="confirm-payment-button"
      >
        Confirm Payment
      </Button>
      <Button
        variant="outline"
        onClick={() => onGoBackClick()}
        size="lg"
        data-testid="go-back-button"
      >
        Go back
      </Button>
      <Text className={classes.authorize}>
        By clicking Confirm Payment, I authorize Flex to initiate the
        transaction detailed above. Flex is powered by Flexbase Technologies,
        Inc
      </Text>
    </>
  );
};

export default CardPaymentReview;
