import TagManager from 'react-gtm-module';
import { getEnvironment } from 'utilities/url/window-helpers';
type Gtag = (
  command: 'get',
  targetId: string,
  paramName: string,
  callback: (id: string) => void,
) => void;

declare const gtag: Gtag;

const pageView = (route: string) => {
  const dataLayerArgs = {
    dataLayer: {
      event: 'pageview',
      page: route,
    },
  };
  TagManager.dataLayer(dataLayerArgs);
};

const gtagGet = async (fieldName: string) => {
  let field_name = '';
  const hostEnv = getEnvironment(window.location.host);
  const gtmId = hostEnv === 'production' ? 'G-XY9ZSZF213' : 'G-PSFB66YCQ6';
  const getField = await new Promise((resolve, reject) => {
    try {
      gtag('get', gtmId, fieldName, (field: string) => {
        resolve(field); // Resolve the promise with the field value
      });
    } catch (error) {
      console.error(`error getting ${fieldName}`, error);
      reject(''); // Reject the promise and return an empty string
    }
  });

  field_name = getField as string;

  return field_name;
};

const getTagReady = () => {
  return new Promise<void>((resolve, reject) => {
    const timeStart = Date.now();
    const TIMEOUT = 3000;

    const _isLoaded = function () {
      if (Date.now() - timeStart > TIMEOUT) {
        reject('Gtag not loaded');
        return;
      }
      if (typeof gtag !== 'undefined') {
        resolve();
        return;
      } else {
        setTimeout(_isLoaded, 500);
      }
    };

    _isLoaded();
  });
};

export const ga4Helpers = {
  pageView,
  gtagGet,
  getTagReady,
};
