import { Box, Button, Group, useMantineTheme } from '@mantine/core';
import { PropsWithChildren } from 'react';
import { StepActions } from './bulk-categorize-transactions.models';

type StepProps = PropsWithChildren<{
  actions: StepActions;
}>;

export const BulkCategorizeTransactionsStep = ({
  children,
  actions,
}: StepProps) => {
  const theme = useMantineTheme();

  return (
    <>
      <Box py={theme.spacing.md}>{children}</Box>

      <Group position="right" pt={theme.spacing.md}>
        {actions.map((action) => {
          return (
            <Button
              key={action.key}
              onClick={action.onClick}
              loading={action.loading}
              variant={action.variant}
              disabled={action.disabled}
            >
              {action.label}
            </Button>
          );
        })}
      </Group>
    </>
  );
};
