import { createStyles } from '@mantine/core';
import { hasFlag } from 'country-flag-icons';
import Flags from 'country-flag-icons/react/3x2';

type Props = {
  flagNationCode: string;
  width?: string;
  height?: string;
};

const useFlagStyles = createStyles((_, { width, height }: Props) => ({
  flag: {
    width: width ?? '1rem',
    height: height ?? '1rem',
  },
}));

const FlagIcon = ({ flagNationCode, width, height }: Props) => {
  const { classes } = useFlagStyles({ width, height } as Props);

  if (!hasFlag(flagNationCode)) {
    return null;
  }

  const Flag = Flags[flagNationCode as keyof typeof Flags];
  return <Flag className={classes.flag} />;
};

export default FlagIcon;
