const miscellaneousLinks = [
  {
    label: 'Youtube',
    url: 'https://www.youtube.com/@FlexSuperApp',
  },
  {
    label: 'Instagram',
    url: 'https://www.instagram.com/flexsuperapp',
  },
  {
    label: 'LinkedIn',
    url: 'https://www.linkedin.com/company/flex-super-app',
  },
  {
    label: 'Facebook',
    url: 'https://www.facebook.com/flexsuperapp/',
  },
  {
    label: 'Contact',
    url: 'https://www.flex.one/contact',
  },
  {
    label: 'Careers',
    url: 'https://jobs.lever.co/Flex/',
  },
  {
    label: 'Privacy Policy',
    url: 'https://www.flex.one/privacy',
  },
  {
    label: 'Support',
    url: 'https://support.flex.one/hc/en-us',
  },
];

export default miscellaneousLinks;
