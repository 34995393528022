import { OptedProduct } from '../../../../states/application/product-onboarding';
import { CreditShield, ProtectSSN } from '../../../../assets/svg';

export const getVerifyIdentityAccordionListItems = (
  userIsApplicant: boolean,
  applyingForProducts: OptedProduct[],
) => {
  return [
    {
      image: <ProtectSSN />,
      label: 'Protect SSN/ITIN',
      description: 'We protect your SSN and ITIN',
      text: `Your encrypted personal data is only used for ID verification${
        userIsApplicant ? ' and credit checks.' : '.'
      }`,
    },

    {
      image: <CreditShield />,
      label: userIsApplicant ? 'Credit Impact' : 'Identity',
      description: userIsApplicant
        ? 'No credit impact'
        : 'Identity Verification',
      text:
        userIsApplicant && applyingForProducts.includes('CREDIT')
          ? 'Flex uses your SSN to verify your identity and pre-qualify you for credit. We do a soft pull on your credit report which does not impact your credit score. Feel free to contact support with concerns.'
          : 'Flex uses your SSN to verify your identity.',
    },
  ];
};
