import { useCreditCardsModalFilters } from '../use-credit-cards-filters';
import { useMemo } from 'react';
import { uniq } from 'underscore';
import { MultiSelect } from '@mantine/core';
import getPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';
import { CreditCard } from '@services/flexbase/card.model';

type Props = { cards: CreditCard[] };
export const CardNumberFilter = ({ cards }: Props) => {
  const { addFilter, removeFilter, getFilterByKey } =
    useCreditCardsModalFilters();

  const cardLast4s = useMemo(() => {
    return uniq(
      cards.map((d) => ({
        label: getPaddedAccountMask(d.last4, 4),
        value: d.last4,
      })),
    );
  }, [cards]);

  return (
    <MultiSelect
      data={cardLast4s}
      placeholder="Card numbers"
      searchable
      nothingFound="Nothing found"
      value={getFilterByKey('last4')?.filterValue || []}
      onChange={(val) => {
        if (val.length > 0) {
          addFilter('last4', {
            key: 'last4',
            filterValue: val,
            fn: (row) => val.includes(row.last4),
            label: `Card numbers: ${val.join(', ')}`,
            showChip: true,
          });
        } else {
          removeFilter('last4');
        }
      }}
    />
  );
};
