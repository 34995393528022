import { createStyles, MantineTheme } from '@mantine/core';

/*
Base styles to use for the core @mantine components.
*/
export const useStyles = createStyles({
  mainContainer: {
    minHeight: '100vh',
    paddingLeft: 32,
    paddingRight: 32,
    '@media(max-width: 767px)': {
      padding: 0,
      minHeight: `calc(100vh - 76px)`, // Header height + padding on appshell
      height: `max(100vh - 76px, 100%)`, // so height: 100% works
    },
  },
});

// Note: we use react-data-table for tables with the formatting used in
// customStyles: https://react-data-table-component.netlify.app/?path=/docs/api-custom-styles--page
export const baseTableStyles = (theme?: MantineTheme) => {
  return {
    table: {
      style: {
        borderTop: `1px solid ${theme?.colors.neutral[1]}`,
        color: theme?.colors.neutral[7],
        fontSize: '14px',
        lineHeight: '21px',
        width: '100%',
        button: {
          border: 0,
          display: 'flex',
          cursor: 'pointer',
          backgroundColor: 'unset',
        },
        '@media(max-width: 767px)': {
          width: 'max-content',
        },
      },
    },
    cells: {
      style: {
        color: theme?.colors.neutral[7],
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '21px',
        alignItems: 'center',
      },
    },
    headCells: {
      style: {
        color: theme?.fn.primaryColor(),
        fontWeight: 500,
        display: 'flex',
      },
    },
    rows: {
      style: {
        fontSize: '14px',
        fontWeight: 400,
        color: theme?.colors.neutral[7],
        lineHeight: '21px',
        minHeight: '68px',
        '&:hover': {
          background: theme?.colors.neutral[2],
          cursor: 'pointer',
        },
      },
      stripedStyle: {
        backgroundColor: 'unset',
      },
    },
    highlightOnHoverStyle: {
      backgroundColor: theme?.colors.neutral[2],
      span: {
        color: theme?.colors.neutral[2],
      },
      svg: {
        fill: theme?.colors.neutral[2],
      },
    },
    pagination: {
      style: {
        '@media(max-width: 767px)': {
          fontSize: '12px',
          paddingTop: '12px',
          '> span:first-of-type': {
            width: '288px',
            margin: '0px 10px',
          },
          '> span': {
            width: '393px',
            margin: '0px 1px',
          },
        },
      },
      pageButtonsStyle: {
        '@media(max-width: 767px)': {
          height: '40px',
          width: '25px',
        },
      },
    },
  };
};

export const liteTableStyles = (primaryColor?: string) => {
  return {
    table: {
      style: {
        borderTop: '1px solid #DEE2E6',
        color: '#5F5F5F',
        fontSize: '14px',
        lineHeight: '21px',
        width: '100%',
      },
    },
    cells: {
      style: {
        color: '#5F5F5F',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '21px',
        alignItems: 'center',
      },
    },
    headCells: {
      style: {
        color: 'black',
        fontWeight: 400,
        display: 'flex',
      },
    },
    rows: {
      style: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#5F5F5F',
        lineHeight: '21px',
        minHeight: '68px',
        '&:hover': {
          background: 'rgba(255, 87, 69, 0.08);',
          cursor: 'pointer',
        },
      },
      stripedStyle: {
        backgroundColor: 'rgba(230, 231, 233, 0.12)',
      },
    },
    highlightOnHoverStyle: {
      backgroundColor: '#FFEEEC',
      span: {
        color: primaryColor,
      },
    },
  };
};
