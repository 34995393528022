import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { flexbaseOnboardingClient } from '@services/flexbase-client';
import { platformClient } from '@services/platform/platform-client';

const QUERY_KEY = 'credit_transactions_query';

type ApiTrueFalse = 'true' | 'false';
type GetCreditTransactionParams = {
  after: string;
  before: string;
  sort: 'desc' | 'asc';
  noInterest: ApiTrueFalse;
  inclReversed: ApiTrueFalse;
  inclExpired: ApiTrueFalse;
  includeChargeCard: ApiTrueFalse;
  inclDisputed: ApiTrueFalse;
};

type GetCreditTransactionsProps = {
  companyId: string;
  accountId?: string;
  personId?: string;
  params?: Partial<GetCreditTransactionParams>;
  spendPlansEnabled?: boolean;
};

async function getCreditTransactions(props: GetCreditTransactionsProps) {
  const result = await flexbaseOnboardingClient.getCompanyTransactions(
    `${props.companyId}`,
    {
      includeChargeCard: 'true',
      inclReversed: 'true',
      inclExpired: 'true',
      inclDisputed: 'true',
      sort: 'desc',
      ...props.params,
    },
  );

  const transactionsIds = result?.transactions.map((tx) => tx.id) ?? [];
  const spendNameMap: Record<string, string> = {};

  if (
    transactionsIds.length > 0 &&
    props.accountId &&
    props.spendPlansEnabled
  ) {
    try {
      const spendPlanNameTransactions =
        await platformClient.getSpendPlanNameTransactions(
          props.accountId,
          transactionsIds,
        );

      spendPlanNameTransactions.forEach((item) => {
        if (item.spendPlanId && item.spendPlanName) {
          spendNameMap[item.invoiceId] = item.spendPlanName;
        }
      });
    } catch (e) {
      // swallow error
      console.error('Unable to fetch spend plans for transactions', e);
    }
  }

  const newTransactions = result.transactions.map((transaction) => {
    if (spendNameMap[transaction.id]) {
      transaction.spendName = spendNameMap[transaction.id];
    }
    return transaction;
  });
  return {
    ...result,
    transactions: newTransactions,
  };
}

function linkInvoice({
  accountId,
  spendPlanId,
  invoiceId,
}: {
  accountId: string;
  spendPlanId: string;
  invoiceId: string;
}) {
  return platformClient.linkInvoiceToSpendPlan(
    accountId,
    spendPlanId,
    invoiceId,
  );
}

export function useCreditTransactions(props: GetCreditTransactionsProps) {
  return useQuery({
    queryKey: [
      QUERY_KEY,
      props.companyId,
      props.params,
      props.accountId,
      props.personId,
      props.spendPlansEnabled,
    ],
    queryFn: () =>
      getCreditTransactions({
        companyId: props.companyId,
        params: props.params,
        accountId: props.accountId,
        personId: props.personId,
        spendPlansEnabled: props.spendPlansEnabled,
      }),
    meta: {
      errorMessage: 'Failed to retrieve transactions',
    },
    enabled: !!props.companyId,
  });
}

export const uselinkInvoiceToSpendPlan = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: linkInvoice,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY],
      });
    },
  });
};
