import { Box, Tooltip, useMantineTheme } from '@mantine/core';
import { ReactElement } from 'react';
import { IconType } from 'react-icons';
import { useStyles } from './expense-management.styles';
import { MemoIcon, ReceiptIcon } from 'assets/svg';
import { ExpenseSyncIcon } from './expense-icon.sync';

export enum ActionStatus {
  complete,
  incomplete,
  default,
  disabled,
  loading,
  inProgress,
  failure,
}

type Action = {
  name: string;
  icon?: ReactElement<IconType>;
  action?: () => void;
  status?: ActionStatus | null;
  clickDisabled?: boolean;
  pointerDisabled?: boolean;
  tooltip?: string;
  tooltipDisabled?: boolean;
  color: string;
  hoverColor: string;
  show?: boolean;
};

type Props = {
  receiptAction?: () => void;
  receiptActionStatus?: ActionStatus | null;
  memoAction?: () => void;
  memoActionStatus?: ActionStatus | null;
  syncAction?: () => void;
  syncActionStatus?: ActionStatus | null;
  syncActionTooltip?: string;
  type: 'credit-transactions' | 'spend-plans';
};

const ExpenseManagement = ({
  receiptAction,
  receiptActionStatus = null,
  memoAction,
  memoActionStatus = null,
  syncAction,
  syncActionStatus = null,
  syncActionTooltip,
  type,
}: Props) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();

  const completeColor = theme.fn.themeColor('primarySecondarySuccess', 2);
  const neutralColor = theme.fn.themeColor('neutral', 3);
  const negativeColor = theme.fn.themeColor('critical', 2);

  const syncActionColor = (() => {
    switch (syncActionStatus) {
      case ActionStatus.complete:
      case ActionStatus.loading:
      case ActionStatus.incomplete:
        return completeColor;
      case ActionStatus.failure:
        return negativeColor;
      default:
        return neutralColor;
    }
  })();

  const actions: Action[] = [
    {
      name: 'Upload receipt',
      icon: <ReceiptIcon />,
      action: receiptAction,
      status: receiptActionStatus,
      tooltipDisabled: true,
      tooltip:
        receiptActionStatus === ActionStatus.complete
          ? 'Receipt Uploaded'
          : 'Upload Receipt',
      color:
        receiptActionStatus === ActionStatus.complete
          ? completeColor
          : neutralColor,
      hoverColor: completeColor,
      show: type === 'credit-transactions',
    },
    {
      name: 'Add Memo',
      icon: <MemoIcon />,
      action: memoAction,
      status: memoActionStatus,
      tooltipDisabled: true,
      tooltip:
        memoActionStatus === ActionStatus.complete ? 'Memo Added' : 'Add Memo',
      color:
        memoActionStatus === ActionStatus.complete
          ? completeColor
          : neutralColor,
      hoverColor: completeColor,
      show: type === 'credit-transactions',
    },
    {
      name: 'Sync',
      icon: <ExpenseSyncIcon status={syncActionStatus} />,
      action: syncAction,
      clickDisabled: syncActionStatus !== ActionStatus.incomplete,
      status: syncActionStatus,
      tooltip: syncActionTooltip,
      color: syncActionColor,
      hoverColor: syncActionColor,
      show: type === 'credit-transactions' || type === 'spend-plans',
    },
  ];

  return (
    <div className={classes.baseContainer}>
      {actions.map((action) => {
        if (action.show) {
          if (action.status === ActionStatus.disabled) {
            return (
              <Box
                className={classes.button}
                sx={() => ({
                  color: action.color,
                  cursor: 'default',
                })}
                key={action.name.replaceAll(' ', '')}
              >
                {action.icon}
              </Box>
            );
          }

          return (
            <Tooltip
              key={action.name.replaceAll(' ', '')}
              label={action.tooltip}
              disabled={action.tooltipDisabled || !action.tooltip}
            >
              <Box
                className={classes.button}
                sx={(currentTheme) =>
                  action.clickDisabled
                    ? {
                        color: action.color,
                        cursor: 'default',
                      }
                    : {
                        color: action.color,
                        ...currentTheme.fn.hover({
                          color: action.hoverColor,
                          transform: 'scale(1.5)',
                        }),
                        '&:active': {
                          transform: 'scale(1.4)',
                        },
                      }
                }
                onClick={action.clickDisabled ? undefined : action.action}
              >
                {action.icon}
              </Box>
            </Tooltip>
          );
        }
      })}
    </div>
  );
};

export default ExpenseManagement;
