import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'space-between',
    color: '#fff',
    padding: '45px',
    backgroundColor: theme.fn.primaryColor(),
    '@media(max-width: 767px)': {
      padding: '20px',
    },
  },
  rowContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  footerItem: {
    marginBottom: '1.25rem',
  },
  padding: {
    padding: '0px 50px',
    '@media(max-width: 767px)': {
      padding: '0px 20px',
    },
  },
  cursor: {
    cursor: 'pointer',
  },
  cardDetails: {
    paddingBottom: 50,
    minHeight: '100vh',
    backgroundColor: theme.colors.neutral[2],
    '@media(max-width: 767px)': {
      paddingBottom: 20,
      width: '100vw',
    },
  },
  transactionsAction: {
    gap: 5,
    fontSize: 14,
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      color: theme.colors.primarySecondarySuccess[2],
      svg: {
        fill: theme.colors.primarySecondarySuccess[2],
      },
    },
    // '@media(max-width: 767px)': {
    //   justifyContent: 'flex-end',
    // },
  },
  debitCardInfoContent: {
    display: 'flex',
    alignItems: 'center',
    span: {
      color: theme.fn.primaryColor(),
    },
    p: {
      margin: '0px',
      color: theme.fn.themeColor('neutral', 6),
    },
  },
  twoFactorContainer: {
    margin: '20px 50px',
    '@media(max-width: 767px)': {
      margin: '0px',
    },
  },
  divider: {
    borderColor: theme.colors.flexbaseGrey[0],
    marginTop: '1.25rem',
    marginBottom: '1.25rem',
  },
}));
