import { Box, Tooltip, useMantineTheme } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';

export const RoutingNumberCell = ({ value }: { value: string }) => {
  const clipboard = useClipboard();
  const theme = useMantineTheme();

  return (
    <Tooltip
      label={clipboard.copied ? 'Copied' : 'Click to copy'}
      withArrow
      data-testid="routing-tooltip-icon"
    >
      <Box
        sx={{ cursor: 'pointer', color: theme.colors.blackish[0] }}
        onClick={() => clipboard.copy(value)}
      >
        {value}
      </Box>
    </Tooltip>
  );
};
